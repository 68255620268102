import { put, select, getContext } from 'redux-saga/effects';
import { includes, values } from 'lodash';
import * as actions from 'Store/actions';
import { LOCALES, SERVICE_NAMES } from 'Constants';

export function* handleSetLocale({ payload: locale }) {
    if (!includes(values(LOCALES), locale)) {
        return;
    }

    const isDisabledServerSyncLanguage = yield select((state) => state.isDisabledServerSyncLanguage);

    if (isDisabledServerSyncLanguage) {
        return;
    }

    // Upd i18next state
    const languageService = yield getContext(SERVICE_NAMES.LANGUAGE);
    yield languageService.setCurrentLang(locale);

    // save to srv function subscribed to this action
    yield put(actions.setLocaleAndSendIt(locale));
}

export function* handleGotLocale({ payload: locale }) {
    if (!includes(values(LOCALES), locale)) {
        return;
    }

    const { currentLocale, isDisabledServerSyncLanguage } = yield select((state) => ({
        currentLocale: state.currentLocale,
        isDisabledServerSyncLanguage: state.isDisabledServerSyncLanguage
    }));

    if (isDisabledServerSyncLanguage) {
        return;
    }

    if (currentLocale === locale) {
        return;
    }

    // Upd i18next state
    const languageService = yield getContext(SERVICE_NAMES.LANGUAGE);
    yield languageService.setCurrentLang(locale);

    yield put(actions.setLocaleInStore(locale));
}
