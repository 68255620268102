import { takeEvery, fork } from 'redux-saga/effects';
import * as actions from 'Store/actions';
import {
    handleAddSymbolToFavorites,
    handleRemoveSymbolFromFavorites,
    saveFavoritesToLocalStorage
} from './favorites.saga';
import {
    closeChartTabById,
    updateChartTabName,
    createNewChartTab,
    selectChartTab,
    updateCurrentChartTabTimeframe,
    selectOrCreateChartTab,
    saveChartTabsToLocalStorage,
    updateChartTabsOnSetSymbolList
} from './chartTabs.saga';
import { handleSetLocale, handleGotLocale } from './locale.saga';
import { handleSetTheme, handleGotTheme } from './theme.saga';
import { handleSetTradingFormMode } from './tradingFormMode.saga';
import initialSettingsSaga from './initialSettings.saga';

export default function* appSaga() {
    yield fork(initialSettingsSaga);

    yield takeEvery(actions.addSymbolToFavorites, handleAddSymbolToFavorites);
    yield takeEvery(actions.removeSymbolFromFavorites, handleRemoveSymbolFromFavorites);
    yield takeEvery(actions.setFavoritesInStore, saveFavoritesToLocalStorage);
    yield takeEvery(actions.setFavoritesAndSendIt, saveFavoritesToLocalStorage);

    yield takeEvery(actions.closeChartTabById, closeChartTabById);
    yield takeEvery(actions.updateChartTabName, updateChartTabName);
    yield takeEvery(actions.createNewChartTab, createNewChartTab);
    yield takeEvery(actions.selectChartTab, selectChartTab);
    yield takeEvery(actions.updateCurrentChartTabTimeframe, updateCurrentChartTabTimeframe);
    yield takeEvery(actions.selectOrCreateChartTab, selectOrCreateChartTab);
    yield takeEvery(actions.setChartTabs, saveChartTabsToLocalStorage);
    yield takeEvery(actions.market.setSymbolList, updateChartTabsOnSetSymbolList);

    yield takeEvery(actions.gotLocale, handleGotLocale);
    yield takeEvery(actions.setLocale, handleSetLocale);

    yield takeEvery(actions.gotTheme, handleGotTheme);
    yield takeEvery(actions.setTheme, handleSetTheme);

    yield takeEvery(actions.setTradingFormMode, handleSetTradingFormMode);
}
