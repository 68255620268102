import { memo } from 'react';
import PropTypes from 'prop-types';
import ICONS from './svg-sprite-icons';

export { NAMES as ICON_NAMES } from './svg-sprite-icons';

const getIconSize = ({ viewBox }) => viewBox.split(' ').slice(2);

const SvgIcon = ({ name, className = '', width, height }) => {
    const icon = ICONS[name];
    const [iconWidth, iconHeight] = getIconSize(icon);

    return (
        <svg
            className={`svg-icon svg-icon--${name} ${className}`}
            width={width || iconWidth}
            height={height || iconHeight}
        >
            <use xlinkHref={icon} />
        </svg>
    );
};

SvgIcon.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
};

export default memo(SvgIcon);
