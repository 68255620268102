export const KEY_CODES = {
    TAB: 9,
    ENTER: 13,
    UP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    BACKSPACE: 8,
    DELETE: 46,
    SPACE: 32,
    ESC: 27
};
