import { put, select, getContext } from 'redux-saga/effects';
import { get, isEmpty, last, sortBy, reverse, takeRight, difference } from 'lodash';
import moment from 'moment';
import * as actions from 'Store/actions';
import { NEWS_REQUESTS, SERVICE_NAMES } from 'Constants';
import { isEmptyDataStatus, isLoadingDataStatus } from 'Utils/statusFilters';

const DEFAULT_NEWS_LIST_LIMIT = 20;
const LOAD_MORE_NEWS_LIMIT = 10;

function* requestNews(params) {
    try {
        const newsService = yield getContext(SERVICE_NAMES.NEWS);
        const { newsIds, newsById } = yield select(({ news: { allIds: newsIds, byId: newsById } }) => ({
            newsIds,
            newsById
        }));

        const oldestNewsId = last(newsIds);
        const currentLastTimestampTo = get(newsById, `${oldestNewsId}.timestamp.millisecondsFromEpoch`);

        yield put(actions.news.getNewsPending());
        const response = yield newsService.request(NEWS_REQUESTS.NEWS_REQUEST, params);
        const responseNews = get(response, 'newsList.news', []);

        if (isEmpty(responseNews)) {
            yield put(actions.news.newsIsEmpty());
            return;
        }

        const preparedNews = reverse(sortBy(responseNews, 'timestamp.millisecondsFromEpoch'));
        const lastTimestampTo = get(preparedNews[preparedNews.length - 1], 'timestamp.millisecondsFromEpoch');

        const currentNewsIdsTail = takeRight(newsIds, LOAD_MORE_NEWS_LIMIT);
        const newsIdsTail = takeRight(
            preparedNews.map((n) => n.id),
            LOAD_MORE_NEWS_LIMIT
        );
        const hasNoNewNews = !isEmpty(currentNewsIdsTail) && isEmpty(difference(currentNewsIdsTail, newsIdsTail));

        if (lastTimestampTo > currentLastTimestampTo || hasNoNewNews) {
            yield put(actions.news.newsIsEmpty());
            return;
        }

        yield put(actions.news.getNewsSuccess(preparedNews));
    } catch (err) {
        yield put(actions.news.getNewsFailure(err.message));
    }
}

export function* loadNews() {
    try {
        yield put(actions.news.resetNews());
        yield requestNews({ limit: DEFAULT_NEWS_LIST_LIMIT, withoutBody: true });
    } catch (err) {
        yield put(actions.news.getNewsFailure(err.message));
    }
}

export function* loadMoreNews() {
    try {
        const { loadStatus, newsIds, newsById } = yield select(
            ({ loadingStatuses: { news: loadStatus }, news: { allIds: newsIds, byId: newsById } }) => ({
                loadStatus,
                newsIds,
                newsById
            })
        );

        if (isLoadingDataStatus(loadStatus) || isEmptyDataStatus(loadStatus)) {
            return;
        }

        const oldestNewsId = last(newsIds);
        const lastTimestampTo = get(
            newsById,
            `${oldestNewsId}.timestamp.millisecondsFromEpoch`,
            moment().add(1, 'day').valueOf()
        );

        yield requestNews({
            limit: LOAD_MORE_NEWS_LIMIT,
            timestampTo: {
                millisecondsFromEpoch: lastTimestampTo
            },
            withoutBody: true
        });
    } catch (err) {
        yield put(actions.news.getNewsFailure(err.message));
    }
}
