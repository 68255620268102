const HTTP_PROTOCOL = 'https://';
const WEBSOCKET_PROTOCOL = 'wss://';

export const SERVICE_NAMES = {
    AUTH: 'AUTH',
    GRECAPCHA: 'GRECAPCHA',
    MARKET: 'MARKET',
    TRADE: 'TRADE',
    NEWS: 'NEWS',
    TECHNIC: 'TECHNIC',
    STORAGE: 'STORAGE',
    LANGUAGE: 'LANGUAGE',
    THEME: 'THEME'
};

export const POST_REQUEST_OPTIONS = {
    method: 'POST',
    mode: 'cors',
    credentials: 'include'
};

export const RESPONSE_STATUSES = {
    SUCCESS: 200,
    UNAUTHORIZED: 401,
    BAD_REQUEST: 400,
    TOO_MANY_REQUESTS: 429,
    FORBIDDEN: 403
};

export const AUTH_ACTION_TYPES = {
    LOGIN: 'login',
    LOGOUT: 'logout',
    REFRESH: 'refresh',
    CHECK: 'check'
};

export const getAuthRoutes = (apiUrl) => {
    const urlPart = `${HTTP_PROTOCOL}${apiUrl}/auth`;
    const routes = {
        [AUTH_ACTION_TYPES.LOGIN]: `${urlPart}/login`,
        [AUTH_ACTION_TYPES.LOGOUT]: `${urlPart}/logout`,
        [AUTH_ACTION_TYPES.REFRESH]: `${urlPart}/refresh`,
        [AUTH_ACTION_TYPES.CHECK]: `${urlPart}/check`
    };
    return (type) => routes[type];
};

export const getWebSocketRoutes = (apiUrl) => {
    const urlPart = `${WEBSOCKET_PROTOCOL}${apiUrl}`;
    const routes = {
        [SERVICE_NAMES.MARKET]: `${urlPart}/market`,
        [SERVICE_NAMES.TRADE]: `${urlPart}/trade`,
        [SERVICE_NAMES.NEWS]: `${urlPart}/news`,
        [SERVICE_NAMES.TECHNIC]: `${urlPart}/technic`
    };
    return (type) => routes[type];
};
