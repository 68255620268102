import { put, getContext } from 'redux-saga/effects';
import { get } from 'lodash';
import { TRADE_REQUESTS, SERVICE_NAMES, DEFAULT_CURRENCY } from 'Constants';
import { prepareInputUint64Value } from 'Utils/uint64';
import * as actions from 'Store/actions';

const prepareTotalAmountsData = (data) => ({
    balance: prepareInputUint64Value(get(data, 'balance', 0)),
    commission: prepareInputUint64Value(get(data, 'commission', 0)),
    reservedMargin: prepareInputUint64Value(get(data, 'reservedMargin', 0)),
    credit: prepareInputUint64Value(get(data, 'credit', 0)),
    bonus: prepareInputUint64Value(get(data, 'bonus', 0)),
    currency: get(data, 'currency', DEFAULT_CURRENCY)
});

// TOTAL AMOUNTS REQUEST
export function* requestTotalAmounts() {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.getTotalAmountsPending());
        yield tradeService.request(TRADE_REQUESTS.GET_TOTAL_AMOUNTS, {});
    } catch (err) {
        yield put(actions.trade.getTotalAmountsFailure(err.message));
    }
}

// TOTAL AMOUNTS MESSAGE HANDLER
export function* handleTotalAmounts({ body }) {
    try {
        const data = prepareTotalAmountsData(body);
        yield put(actions.trade.getTotalAmountsSuccess(data));
    } catch (err) {
        yield put(actions.trade.getTotalAmountsFailure(err.message));
    }
}
