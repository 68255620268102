import { createReducer, combineReducers } from '@reduxjs/toolkit';
import initialState from '../initialState';
import * as actions from '../actions';
import { CONNECTION_STATUSES } from 'Constants';

const connectionsStatuses = combineReducers({
    market: createReducer(initialState.connectionsStatuses.market, {
        [actions.market.WSConnectionConnecting]: () => CONNECTION_STATUSES.CONNECTING,
        [actions.market.WSConnectionOpen]: () => CONNECTION_STATUSES.OPEN,
        [actions.market.WSConnectionOnError]: () => CONNECTION_STATUSES.ON_ERROR,
        [actions.market.WSConnectionClose]: () => CONNECTION_STATUSES.CLOSE,
        [actions.auth.requestLoginPending]: () => initialState.connectionsStatuses.market
    }),
    trade: createReducer(initialState.connectionsStatuses.trade, {
        [actions.trade.WSConnectionConnecting]: () => CONNECTION_STATUSES.CONNECTING,
        [actions.trade.WSConnectionOpen]: () => CONNECTION_STATUSES.OPEN,
        [actions.trade.WSConnectionOnError]: () => CONNECTION_STATUSES.ON_ERROR,
        [actions.trade.WSConnectionClose]: () => CONNECTION_STATUSES.CLOSE,
        [actions.auth.requestLoginPending]: () => initialState.connectionsStatuses.trade
    }),
    news: createReducer(initialState.connectionsStatuses.news, {
        [actions.news.WSConnectionConnecting]: () => CONNECTION_STATUSES.CONNECTING,
        [actions.news.WSConnectionOpen]: () => CONNECTION_STATUSES.OPEN,
        [actions.news.WSConnectionOnError]: () => CONNECTION_STATUSES.ON_ERROR,
        [actions.news.WSConnectionClose]: () => CONNECTION_STATUSES.CLOSE,
        [actions.auth.requestLoginPending]: () => initialState.connectionsStatuses.news
    }),
    technic: createReducer(initialState.connectionsStatuses.technic, {
        [actions.technic.WSConnectionConnecting]: () => CONNECTION_STATUSES.CONNECTING,
        [actions.technic.WSConnectionOpen]: () => CONNECTION_STATUSES.OPEN,
        [actions.technic.WSConnectionOnError]: () => CONNECTION_STATUSES.ON_ERROR,
        [actions.technic.WSConnectionClose]: () => CONNECTION_STATUSES.CLOSE,
        [actions.auth.requestLoginPending]: () => initialState.connectionsStatuses.technic
    })
});

export default connectionsStatuses;
