import { createReducer } from '@reduxjs/toolkit';
import { keyBy, includes, concat, uniq, get, pick } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';

const orderFieldIncludedIntoExecution = [
    'symbol',
    'clientId',
    'reasonCode',
    'price',
    'volume',
    'filledVolume',
    'averagePrice',
    'side',
    'orderStatus'
];

const sortOrdersIdsByOrdersRequestTime = (allIds, byId) =>
    allIds.sort((id1, id2) => get(byId, `${id2}.requestTime`, 0) - get(byId, `${id1}.requestTime`, 0));

const orders = createReducer(initialState.orders, {
    [actions.trade.resetOrders]: () => initialState.orders,
    [actions.trade.getOrderListSuccess]: (state, { payload: orders }) => {
        const newAllIds = orders.map((o) => o.id);
        const newById = keyBy(orders, (o) => o.id);
        const byId = { ...state.byId, ...newById };
        const allIds = sortOrdersIdsByOrdersRequestTime(uniq(concat(state.allIds, newAllIds)), byId);
        return { byId, allIds };
    },
    [actions.trade.gotOrder]: ({ byId, allIds }, { payload }) => {
        const { id } = payload;
        const newOrder = { ...byId[id], ...payload };
        const newById = { ...byId, [id]: newOrder };
        const unsortedIds = includes(allIds, id) ? allIds : [id, ...allIds];
        const newAllIds = sortOrdersIdsByOrdersRequestTime(unsortedIds, newById);
        return { byId: newById, allIds: newAllIds };
    },
    [actions.trade.gotOrderExecution]: ({ byId, allIds }, { payload: { orderId, ...restExecutionData } }) => {
        const orderUpdates = {
            ...pick(restExecutionData, orderFieldIncludedIntoExecution),
            profitLoss: restExecutionData.orderProfitLoss,
            commission: restExecutionData.orderCommission,
            insuranceFee: restExecutionData.orderInsuranceFee,
            insurancePayout: restExecutionData.orderInsurancePayout,
            swap: restExecutionData.orderSwap
        };

        const newOrder = { ...byId[orderId], ...orderUpdates, id: orderId };
        const newById = { ...byId, [orderId]: newOrder };
        const unsortedIds = includes(allIds, orderId) ? allIds : [orderId, ...allIds];
        const newAllIds = sortOrdersIdsByOrdersRequestTime(unsortedIds, newById);
        return { byId: newById, allIds: newAllIds };
    },
    [actions.trade.cancelOrderPending]: ({ byId, allIds }, { payload: orderId }) => {
        const order = { ...byId[orderId], isCancelPending: true };
        return {
            byId: { ...byId, [orderId]: order },
            allIds
        };
    },
    [actions.trade.cancelOrderSuccess]: ({ byId, allIds }, { payload }) => {
        const { orderId, orderStatus } = payload;
        const order = { ...byId[orderId], orderStatus, isCancelPending: false };
        return {
            byId: { ...byId, [orderId]: order },
            allIds
        };
    },
    [actions.trade.cancelOrderFailure]: ({ byId, allIds }, { payload: orderId }) => {
        const order = { ...byId[orderId], isCancelPending: false };
        return {
            byId: { ...byId, [orderId]: order },
            allIds
        };
    }
});

export default orders;
