import { createReducer } from '@reduxjs/toolkit';
import { keyBy, includes } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';

const currencies = createReducer(initialState.currencies, {
    [actions.trade.getCurrencyListSuccess]: (state, { payload: currencies }) => {
        const allIds = currencies.map((c) => c.code);
        const byId = keyBy(currencies, 'code');

        return { byId, allIds };
    },
    [actions.trade.gotCurrency]: ({ allIds, byId }, { payload: currency }) => {
        const newAllIds = includes(allIds, currency.code) ? allIds : [...allIds, currency.code];
        const newById = { ...byId, [currency.code]: currency };

        return { byId: newById, allIds: newAllIds };
    },
    [actions.trade.WSConnectionClose]: () => initialState.currencies
});

export default currencies;
