import { keys, isNil } from 'lodash';
import roundByNumOfDecPlaces from 'Utils/roundByNumOfDecPlaces';
import getCurrencyConversion from 'Utils/getCurrencyConversion';
import { MARKET_TYPES, TRADE_TYPES } from 'Constants';

const forexCalcType = (volume, currentPrice, openPrice, profitDigits) =>
    roundByNumOfDecPlaces(volume * currentPrice, profitDigits) -
    roundByNumOfDecPlaces(volume * openPrice, profitDigits);

const cfdCalcType = (volume, currentPrice, openPrice, profitDigits) =>
    roundByNumOfDecPlaces(volume * (currentPrice - openPrice), profitDigits);

const processByCalcType = {
    [MARKET_TYPES.CALCULATION_TYPE.FOREX]: forexCalcType,
    [MARKET_TYPES.CALCULATION_TYPE.CFD]: cfdCalcType,
    [MARKET_TYPES.CALCULATION_TYPE.CFD_LEVERAGE]: cfdCalcType
};

const calculateProfitLoss = (position, quotes, symbols, currencies, depositCurrency) => {
    try {
        const { volume: positionVolume, side, price: openPrice, symbol: symbolName } = position;
        const symbolNames = keys(symbols);

        if (!symbolName) {
            throw new Error('Symbol object for symbol: %o, not found, stop calculation PL', symbolName);
        }

        const { calculationType, profitCurrency, convertationSymbolPostfix } = symbols[symbolName];
        const quote = quotes[symbolName];

        if (!quote) {
            throw new Error('Quote object for symbol: %o, not found, stop calculation PL', symbolName);
        }

        if (isNil(calculationType)) {
            throw new Error('Calculation type for symbol: %o, not found, stop calculation PL', symbolName);
        }

        if (isNil(profitCurrency)) {
            throw new Error('Profit currency for symbol: %o, not found, stop calculation PL', symbolName);
        }

        const { getRatio } = getCurrencyConversion(
            symbolNames,
            profitCurrency,
            depositCurrency,
            convertationSymbolPostfix
        );

        const profitCurrencyDigits = currencies[profitCurrency]?.digits || 8;
        const depositCurrencyDigits = currencies[depositCurrency]?.digits || 2;

        //  buy: use quote.bid for sell currency
        //  sell: use quote.ask for buy currency
        const currentPrice = side === TRADE_TYPES.SIDE.BUY ? quote.bidPrice : quote.askPrice;

        // buy: profit = (currentPrice - openPrice) * volume (because will sell currency)
        // sell: profit = (openPrice - currentPrice) * volume (because will buy currency)
        const directionRatio = side === TRADE_TYPES.SIDE.BUY ? 1 : -1;

        const profit =
            processByCalcType[calculationType](positionVolume, currentPrice, openPrice, profitCurrencyDigits) *
            directionRatio;

        // conversion use full profit
        const conversionRatio = getRatio(quotes, profit >= 0);

        const profitLoss = profit * conversionRatio;

        const result = roundByNumOfDecPlaces(profitLoss, depositCurrencyDigits);

        return { result, error: null };
    } catch (err) {
        return { result: null, error: err.message };
    }
};

export default calculateProfitLoss;
