import { put, call, select, all, take, fork, takeLatest, getContext } from 'redux-saga/effects';
import { isArray, isString, isEmpty, union, difference, isEqual, intersection, get } from 'lodash';
import * as actions from 'Store/actions';
import isArrayOfStrings from 'Utils/isArrayOfStrings';
import logger from 'Utils/logger';
import { LOCAL_STORAGE_KEYS, SERVICE_NAMES } from 'Constants';

const logError = logger('Error:Favorites:');

export {
    handleAddSymbolToFavorites,
    handleRemoveSymbolFromFavorites,
    favoritesInitialSettings,
    saveFavoritesToLocalStorage
};

function* favoritesInitialSettings() {
    yield fork(restoreFavoritesFromLocalStorage);
    yield fork(watchFavoritesInitData);
}

function* restoreFavoritesFromLocalStorage() {
    const storageService = yield getContext(SERVICE_NAMES.STORAGE);

    if (yield call([storageService, storageService.has], LOCAL_STORAGE_KEYS.FAVORITES)) {
        const favorites = yield call([storageService, storageService.get], LOCAL_STORAGE_KEYS.FAVORITES);

        if (isArray(favorites)) {
            yield put(actions.setFavoritesInStore(favorites));
            return;
        }
    }

    // default
    yield call([storageService, storageService.set], LOCAL_STORAGE_KEYS.FAVORITES, []);
}

function* watchFavoritesInitData() {
    const [{ payload: favorites }, { payload: symbolList }] = yield all([
        take(actions.gotFavorites),
        take(actions.market.setSymbolList)
    ]);

    const tradableSymbols = get(symbolList, 'tradableIds', []);

    yield handleFavoritesBySymbolList(favorites, tradableSymbols);
    yield fork(runInternalFavoritesWatchers);
}

function* runInternalFavoritesWatchers() {
    yield takeLatest(actions.gotFavorites, handleGotFavorites);
    yield takeLatest(actions.market.setSymbolList, updateFavoritesOnSetSymbolList);
}

// WORKERS

// Filter favorites by tradable symbol list, tradable symbol is symbol, that is tradable for current trading mode
function* handleFavoritesBySymbolList(favorites, tradableSymbols) {
    const favoritesDiff = difference(favorites, tradableSymbols);

    if (isEmpty(favoritesDiff)) {
        return;
    }

    const newFavorites = intersection(favorites, tradableSymbols);
    if (isEqual(favorites, newFavorites)) {
        return;
    }

    yield put(actions.setFavoritesAndSendIt(newFavorites)); // as internal change for update on server
}

function* updateFavoritesOnSetSymbolList({ payload: { tradableIds } }) {
    const favorites = yield select((state) => state.favorites);
    yield handleFavoritesBySymbolList(favorites, tradableIds);
}

function* handleGotFavorites({ payload: values }) {
    const { favorites, symbols } = yield select((state) => ({
        favorites: state.favorites,
        symbols: state.symbols.tradableIds
    }));

    const newFavorites = intersection(values, symbols);

    if (isEqual(favorites, newFavorites)) {
        return;
    }

    yield put(actions.setFavoritesInStore(values));
}

function* saveFavoritesToLocalStorage({ payload: favorites }) {
    const storageService = yield getContext(SERVICE_NAMES.STORAGE);

    if (!isArrayOfStrings(favorites)) {
        return;
    }

    yield call([storageService, storageService.set], LOCAL_STORAGE_KEYS.FAVORITES, favorites);
}

/**
 * Sagas for work with favorites
 */

function* handleAddSymbolToFavorites({ payload }) {
    try {
        if (!isArray(payload) && !isString(payload)) {
            logError('Favorites: update method parameter is not array or sting');
            return;
        }

        if (isEmpty(payload)) {
            logError('Favorites: update method parameter is empty');
            return;
        }

        const currentValues = yield select((state) => state.favorites);

        const incomingValue = isString(payload) ? [payload] : payload;
        const values = union(incomingValue, currentValues).sort((a, b) => a - b);

        yield put(actions.setFavoritesAndSendIt(values));
    } catch (err) {
        throw new Error(`favorites saga error: ${err.message}`);
    }
}

function* handleRemoveSymbolFromFavorites({ payload }) {
    try {
        if (!isArray(payload) && !isString(payload)) {
            logError('Favorites: remove method parameter is not array or sting');
            return;
        }

        if (isEmpty(payload)) {
            logError('Favorites: remove method parameter is empty');
            return;
        }

        const currentValues = yield select((state) => state.favorites);

        const incomingValue = isString(payload) ? [payload] : payload;
        const values = difference(currentValues, incomingValue);

        yield put(actions.setFavoritesAndSendIt(values));
    } catch (err) {
        throw new Error(`favorites saga error: ${err.message}`);
    }
}
