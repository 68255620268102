import { put, select, getContext } from 'redux-saga/effects';
import { isUndefined, get, last, isEmpty } from 'lodash';
import moment from 'moment';
import { TRADE_REQUESTS, TRADE_TYPES, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';
import { prepareInputUint64Value } from 'Utils/uint64';
import { isEmptyDataStatus, isLoadingDataStatus } from 'Utils/statusFilters';

const DEFAULT_BALANCES_LIMIT = 40;
const LOAD_MORE_BALANCES_LIMIT = 20;

const prepareBalancesData = (data) => {
    const amount = prepareInputUint64Value(get(data, 'amount', 0));

    return {
        ...data,
        ...(isUndefined(data.type) && { type: TRADE_TYPES.BALANCE_TYPE.BALANCE }),
        amount
    };
};

function* requestBalances(params) {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.getBalancesPending());
        const response = yield tradeService.request(TRADE_REQUESTS.GET_BALANCES_BY_DATE, params);
        const responseBalances = get(response, 'body.balances', []);

        if (isEmpty(responseBalances)) {
            yield put(actions.trade.balancesIsEmpty());
            return;
        }

        const preparedBalances = responseBalances.map(prepareBalancesData);

        yield put(actions.trade.getBalancesSuccess(preparedBalances));
    } catch (err) {
        yield put(actions.trade.getBalancesFailure(err.message));
    }
}

export function* loadBalances() {
    try {
        yield put(actions.trade.resetBalances());

        const toTime = moment().endOf('day').valueOf();

        yield requestBalances({ toTime, limit: DEFAULT_BALANCES_LIMIT });
    } catch (err) {
        yield put(actions.trade.getBalancesFailure(err.message));
    }
}

export function* loadMoreBalances() {
    try {
        const { loadStatus, balancesIds, balancesById } = yield select(
            ({ loadingStatuses: { balances: loadStatus }, balances: { allIds: balancesIds, byId: balancesById } }) => ({
                loadStatus,
                balancesIds,
                balancesById
            })
        );

        if (isLoadingDataStatus(loadStatus) || isEmptyDataStatus(loadStatus)) {
            return;
        }

        const oldestBalanceId = last(balancesIds);
        const toTime = get(balancesById, `${oldestBalanceId}.time`, moment().endOf('day').valueOf());

        yield requestBalances({ limit: LOAD_MORE_BALANCES_LIMIT, toTime });
    } catch (err) {
        yield put(actions.trade.getBalancesFailure(err.message));
    }
}

export function* handleBalance({ body }) {
    try {
        if (isEmpty(body)) {
            return;
        }

        const preparedData = prepareBalancesData(body);
        yield put(actions.trade.gotBalance(preparedData));
    } catch (err) {
        yield put(actions.trade.getBalancesFailure(err.message));
    }
}
