import { createAction } from '@reduxjs/toolkit';

// Market WS Connection
export const WSConnectionConnecting = createAction('MARKET_WS_CONNECTION_CONNECTING');
export const WSConnectionOpen = createAction('MARKET_WS_CONNECTION_OPEN');
export const WSConnectionOnError = createAction('MARKET_WS_CONNECTION_ON_ERROR');
export const WSConnectionClose = createAction('MARKET_WS_CONNECTION_CLOSE');
export const WSConnectionProblem = createAction('MARKET_WS_CONNECTION_PROBLEM');
export const WSConnectionNormal = createAction('MARKET_WS_CONNECTION_NORMAL');

export const requestPending = createAction('MARKET_WS_REQUEST_PENDING');
export const requestSuccess = createAction('MARKET_WS_REQUEST_SUCCESS');
export const requestFailure = createAction('MARKET_WS_REQUEST_FAILURE');

export const setSymbolList = createAction('SET_SYMBOL_LIST');
export const updateSymbolList = createAction('UPDATE_SYMBOL_LIST');
export const setSymbolGroups = createAction('SET_SYMBOL_GROUPS');

export const subscribeToSymbolQuote = createAction('SUBSCRIBE_TO_SYMBOL_QUOTE');
export const unsubscribeFromSymbolQuote = createAction('UNSUBSCRIBE_FROM_SYMBOL_QUOTE');
export const symbolQuoteSubscriptionError = createAction('SYMBOL_QUOTE_SUBSCRIPTION_ERROR');
export const gotSymbolQuote = createAction('GOT_SYMBOL_QUOTE');

export const subscribeToChartBar = createAction('SUBSCRIBE_TO_CHART_BAR');
export const unsubscribeFromChartBar = createAction('UNSUBSCRIBE_FROM_CHART_BAR');
export const resetCurrentChartBars = createAction('CHART_BARS_RESET');
export const loadMoreChartBars = createAction('LOAD_MORE_CHART_BARS');
export const getChartBarListPending = createAction('GET_CHART_BARS_LIST_PENDING');
export const getChartBarListSuccess = createAction('GET_CHART_BARS_LIST_SUCCESS');
export const getChartBarListFailure = createAction('GET_CHART_BARS_LIST_FAILURE');
export const chartBarsIsEmpty = createAction('CHART_BARS_IS_EMPTY');
export const chartBarUpdate = createAction('CHART_BAR_INCREMENTAL_UPDATE');
export const chartBarError = createAction('CHART_BAR_ERROR');

export const incQuotesSubscriptionCount = createAction('INC_QUOTES_SUBSCRIPTION_COUNT');
export const decQuotesSubscriptionCount = createAction('DEC_QUOTES_SUBSCRIPTION_COUNT');
