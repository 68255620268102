import { put, select, call, getContext } from 'redux-saga/effects';
import { findIndex, find, uniqueId, get, isUndefined, isEmpty, isNil } from 'lodash';
import * as actions from 'Store/actions';
import { MARKET_TYPES, LOCAL_STORAGE_KEYS, SERVICE_NAMES } from 'Constants';
import initialAppState from 'Store/initialState';

const DEFAULT_TIMEFRAME = MARKET_TYPES.PERIOD_TYPE.H1;

const customUniqId = (() => {
    const timestamp = Date.now();
    return () => uniqueId(timestamp);
})();

export {
    chartTabsInitialSettings,
    saveChartTabsToLocalStorage,
    updateChartTabsOnSetSymbolList,
    closeChartTabById,
    updateChartTabName,
    createNewChartTab,
    selectChartTab,
    updateCurrentChartTabTimeframe,
    selectOrCreateChartTab
};

/**
 * Sagas to save to localStorage and restore chart tabs settings
 */

function* chartTabsInitialSettings() {
    const storageService = yield getContext(SERVICE_NAMES.STORAGE);

    if (yield call([storageService, storageService.has], LOCAL_STORAGE_KEYS.CHART_TABS)) {
        const chartTabs = yield call([storageService, storageService.get], LOCAL_STORAGE_KEYS.CHART_TABS);

        if (!isNil(chartTabs)) {
            yield put(actions.setChartTabs(chartTabs));
            return;
        }
    }

    // default
    yield put(actions.setChartTabs(initialAppState.chartTabs));
}

function* saveChartTabsToLocalStorage({ payload: chartTabs }) {
    const storageService = yield getContext(SERVICE_NAMES.STORAGE);

    if (isNil(chartTabs)) {
        return;
    }

    yield call([storageService, storageService.set], LOCAL_STORAGE_KEYS.CHART_TABS, chartTabs);
}

// Filter chart tabs by tradable symbol list, tradable symbol is symbol, that is tradable for current trading mode
function* updateChartTabsOnSetSymbolList({ payload: { tradableIds } }) {
    const { currentTabId, tabs } = yield select((state) => state.chartTabs);

    if (isEmpty(currentTabId) || isEmpty(tabs)) {
        return;
    }

    const tradableSymbols = new Set(tradableIds);
    const filteredTabs = tabs.filter((tab) => tradableSymbols.has(tab.name));

    if (isEmpty(filteredTabs)) {
        yield put(actions.setChartTabs(initialAppState.chartTabs));
        return;
    }

    const currentTabIdIndex = filteredTabs.findIndex((tab) => tab.id === currentTabId);
    const actualTabId = currentTabIdIndex === -1 ? filteredTabs[0].id : currentTabId;

    yield put(actions.setChartTabs({ currentTabId: actualTabId, tabs: filteredTabs }));
}

/**
 * Sagas to work with chart widget tabs.
 * Each saga selects a chartTabs' part of the redux-store, transforms it and puts the result into the redux-store.
 *
 * ! Important: there is an action 'setChartTabs' listener that stores the payload in the localStorage
 *              so it is the only action that allows you to synchronize the localStorage with the redux-store.
 */

function* closeChartTabById({ payload: tabId }) {
    const tabs = yield select((state) => state.chartTabs.tabs);

    const tabIndex = findIndex(tabs, (item) => item.id === tabId);
    const filteredTabs = [...tabs.slice(0, tabIndex), ...tabs.slice(tabIndex + 1)];
    const newCurrentTab = filteredTabs[tabIndex] || filteredTabs[tabIndex - 1];
    const newCurrentTabId = get(newCurrentTab, 'id', '');

    yield put(
        actions.setChartTabs({
            currentTabId: newCurrentTabId,
            tabs: filteredTabs
        })
    );
}

function* updateChartTabName({ payload: { tabId, tabName } }) {
    const tabs = yield select((state) => state.chartTabs.tabs);

    const activeTabIndex = tabs.findIndex((tab) => tab.id === tabId);
    const activeTab = tabs[activeTabIndex];

    if (activeTab.name === tabName) {
        return;
    }

    const updatedTabId = customUniqId();
    const activeTabClone = { ...activeTab };
    activeTabClone.id = updatedTabId;
    activeTabClone.name = tabName;

    const updatedTabs = [...tabs.slice(0, activeTabIndex), activeTabClone, ...tabs.slice(activeTabIndex + 1)];

    yield put(
        actions.setChartTabs({
            currentTabId: updatedTabId,
            tabs: updatedTabs
        })
    );
}

function* createNewChartTab({ payload: tabName }) {
    const tabs = yield select((state) => state.chartTabs.tabs);

    const newTabId = customUniqId();
    const newTab = {
        name: tabName,
        id: newTabId,
        timeframe: DEFAULT_TIMEFRAME
    };
    const clonedTabs = [...tabs];
    clonedTabs.push(newTab);

    yield put(
        actions.setChartTabs({
            currentTabId: newTabId,
            tabs: clonedTabs
        })
    );
}

function* selectChartTab({ payload: tabId }) {
    const tabs = yield select((state) => state.chartTabs.tabs);

    yield put(
        actions.setChartTabs({
            currentTabId: tabId,
            tabs
        })
    );
}

function* updateCurrentChartTabTimeframe({ payload: timeframe }) {
    const { tabs, currentTabId } = yield select((state) => state.chartTabs);

    const updatedTabId = customUniqId();

    const updatedTabs = tabs.map((tab) => {
        if (tab.id !== currentTabId) {
            return tab;
        }

        const cloneTab = { ...tab };
        cloneTab.timeframe = timeframe;
        cloneTab.id = updatedTabId;
        return cloneTab;
    });

    yield put(
        actions.setChartTabs({
            currentTabId: updatedTabId,
            tabs: updatedTabs
        })
    );
}

function* selectOrCreateChartTab({ payload: tabName }) {
    const { tabs, currentTabId } = yield select((state) => state.chartTabs);

    if (isEmpty(tabs)) {
        yield createNewChartTab({ payload: tabName });
        return;
    }

    const currentTab = find(tabs, (t) => t.id === currentTabId);

    if (currentTab.name === tabName) {
        return;
    }

    const tabWithTabName = find(tabs, (t) => t.name === tabName);

    if (isUndefined(tabWithTabName)) {
        yield createNewChartTab({ payload: tabName });
        return;
    }

    yield put(
        actions.setChartTabs({
            currentTabId: tabWithTabName.id,
            tabs
        })
    );
}
