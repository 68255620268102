import { createSlice } from '@reduxjs/toolkit';
import { SYMBOL_WIDGET_MODES } from 'Constants';

const initialState = {
    mode: SYMBOL_WIDGET_MODES.LIST,
    symbolName: null,
    symbolGroup: null,
    positionKey: null,
    orderId: null
};

const symbolWidget = createSlice({
    name: 'symbolWidget',
    initialState,
    reducers: {
        setDetailsMode(state, { payload: symbolName }) {
            state.mode = SYMBOL_WIDGET_MODES.DETAILS;
            state.symbolName = symbolName;
        },
        setListMode(state, { payload: symbolGroup }) {
            state.mode = SYMBOL_WIDGET_MODES.LIST;

            if (symbolGroup) {
                state.symbolGroup = symbolGroup;
            }
        },
        setEditPositionMode(state, { payload: positionKey }) {
            state.mode = SYMBOL_WIDGET_MODES.EDIT_POSITION;
            state.positionKey = positionKey;
        },
        setEditOrderMode(state, { payload: orderId }) {
            state.mode = SYMBOL_WIDGET_MODES.EDIT_POSITION;
            state.orderId = orderId;
        }
    }
});

export default symbolWidget;
