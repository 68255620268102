import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LOCALES, LOCALE_NAMESPACES, ENV_VARIABLES } from 'Constants';
import moment from 'moment';
import 'moment/locale/ru';

const LOAD_PATH = `${process.env.PUBLIC_URL}/static/media/i18n/{{lng}}/{{ns}}.json`;

const OPTIONS = {
    load: 'languageOnly',
    fallbackLng: LOCALES.EN,
    ns: [LOCALE_NAMESPACES.COMMON],
    defaultNS: [LOCALE_NAMESPACES.COMMON],
    fallbackNS: [LOCALE_NAMESPACES.COMMON],
    debug: process.env.NODE_ENV !== ENV_VARIABLES.PRODUCTION,
    interpolation: {
        escapeValue: false // not needed for react as it escapes by default
    },
    backend: {
        loadPath: LOAD_PATH,
        requestOptions: {
            cache: 'no-cache'
        }
    },
    react: {
        bindI18n: 'languageChanged'
    }
};

const updateRootTagLang = () => {
    document.documentElement.setAttribute('lang', i18n.language);
    document.documentElement.setAttribute('dir', i18n.dir());
};

const getCurrentLang = () => {
    const [locale] = i18n.language.split('-');
    return locale;
};

const changeLanguage = (lang) =>
    i18n.changeLanguage(lang).then(() => {
        updateRootTagLang();
    });

const setCurrentLang = (lang) => {
    const prevLang = getCurrentLang();

    return changeLanguage(lang).catch((err) => {
        console.warn(err.message);
        return changeLanguage(prevLang).then(() => getCurrentLang());
    });
};

const initI18n = () => {
    return new Promise((resolve, reject) => {
        i18n.use(Backend)
            .use(LanguageDetector)
            .use(initReactI18next)
            .init(OPTIONS, (err) => {
                if (err) {
                    console.error(`Something went wrong. Error: ${err}`);
                    return reject(err);
                }

                updateRootTagLang();
                resolve(getCurrentLang());
            });

        i18n.on('languageChanged', (lng) => {
            moment.locale(lng);
        });
    });
};

export const languageService = {
    getCurrentLang,
    setCurrentLang,
    init: initI18n
};

export default i18n;
