import { createAction } from '@reduxjs/toolkit';
import { SERVICE_NAMES } from 'Constants';

export const requestLoginSuccess = createAction('AUTH_REQUEST_LOGIN_SUCCESS');
export const requestLoginFailure = createAction('AUTH_REQUEST_LOGIN_FAILURE');

export const requestLogoutPending = createAction('AUTH_REQUEST_LOGOUT_PENDING');
export const requestLogoutSuccess = createAction('AUTH_REQUEST_LOGOUT_SUCCESS');
export const requestLogoutFailure = createAction('AUTH_REQUEST_LOGOUT_FAILURE');

export const requestRefreshPending = createAction('AUTH_REQUEST_REFRESH_PENDING');
export const requestRefreshSuccess = createAction('AUTH_REQUEST_REFRESH_SUCCESS');
export const requestRefreshFailure = createAction('AUTH_REQUEST_REFRESH_FAILURE');

export const requestCheckPending = createAction('AUTH_REQUEST_CHECK_PENDING');
export const requestCheckSuccess = createAction('AUTH_REQUEST_CHECK_SUCCESS');
export const requestCheckFailure = createAction('AUTH_REQUEST_CHECK_FAILURE');

// -----------------------------------------------------------------------------
// THUNKS
// -----------------------------------------------------------------------------

export const requestLogin = (params) => async (dispatch, getState, services) => {
    try {
        const response = await services[SERVICE_NAMES.AUTH].login(params);

        return { error: null, result: response };
    } catch (error) {
        return { error };
    }
};

export const requestLogout = (...args) => async (dispatch, getState, services) => {
    dispatch(requestLogoutPending());
    try {
        const response = await services[SERVICE_NAMES.AUTH].logout();

        if (response.status !== 200) {
            throw new Error(`Unexpected logout response status: ${response.status}`);
        }

        dispatch(requestLogoutSuccess(response));
        return response;
    } catch (err) {
        dispatch(requestLogoutFailure(err.message));
        window.location.reload();
    }
};

export const requestRefresh = () => async (dispatch, getState, services) => {
    dispatch(requestRefreshPending());
    try {
        const response = await services[SERVICE_NAMES.AUTH].refresh();
        dispatch(requestRefreshSuccess(response));
        return response;
    } catch (err) {
        dispatch(requestRefreshFailure(err.message));
        throw err;
    }
};

export const requestCheck = () => async (dispatch, getState, services) => {
    dispatch(requestCheckPending());
    try {
        const response = await services[SERVICE_NAMES.AUTH].check();
        dispatch(requestCheckSuccess(response));
        return response.isAuthenticated;
    } catch (err) {
        dispatch(requestCheckFailure(err.message));
        throw err;
    }
};
