import { select, put, call, takeEvery, fork, delay, getContext } from 'redux-saga/effects';
import { values, isEmpty } from 'lodash';
import * as actions from 'Store/actions';
import { EVENTS, SERVICE_NAMES } from 'Constants';
import { isInitDataStatus } from 'Utils/statusFilters';
import runWhenRetriedConditionSagaNotThrow from '../../utils/runWhenRetriedConditionSagaNotThrow';
import { handleBet, handleResult } from '../bets.saga';
import getEventChannelCreator from 'Store/sagas/utils/getEventChannelCreator';
import { updateBetsProfitLoss } from '../bets.saga';
import logger from 'Utils/logger';

const UPDATE_BETS_PROFIT_LOSS_DELAY = 800;

const log = logger('BetsWorkerSaga:');
const logError = logger('Error:BetsWorkerSaga:');

// WRITE HERE CHANNELS FOR DISPATCH WS SPECIFIC (for bet mode) EVENTS TO HANDLERS
export default function* betTradeWorker() {
    log('Start main bets saga');
    const tradeService = yield getContext(SERVICE_NAMES.TRADE);
    const createEventChannel = getEventChannelCreator(tradeService, EVENTS.TRADE.CLOSE);
    const betChannel = yield call(createEventChannel, EVENTS.TRADE.BET);
    const resultChannel = yield call(createEventChannel, EVENTS.TRADE.RESULT);

    yield takeEvery(betChannel, handleBet);
    yield takeEvery(resultChannel, handleResult);

    yield fork(runProfitLossUpdate);

    // WRITE HERE SPECIFIC (for bet mode) GET INIT DATA SAGAS
    yield runWhenRetriedConditionSagaNotThrow(0, 1000, checkInitialValues, requestActiveBets);
}

function* requestActiveBets() {
    const { loadingStatus } = yield select(({ loadingStatuses }) => ({
        loadingStatus: loadingStatuses.bets
    }));

    log('Active bets request status: %s', loadingStatus);
    if (isInitDataStatus(loadingStatus)) {
        log('Try active bets request');
        yield put(actions.trade.loadActiveBets());
    }
}

function* checkInitialValues() {
    const { customerInfo, symbolList } = yield select(({ customerInfo, symbols }) => ({
        customerInfo,
        symbolList: values(symbols.byId)
    }));

    if (isEmpty(customerInfo) || isEmpty(symbolList)) {
        logError(
            'One of necessary data for active bets request is empty: customerInfo: %o, symbolList: %o',
            customerInfo,
            symbolList
        );
        throw new Error('Not enough values');
    }
}

function* runProfitLossUpdate() {
    log('Start profit loss update process');
    try {
        while (true) {
            yield updateBetsProfitLoss();
            yield delay(UPDATE_BETS_PROFIT_LOSS_DELAY);
        }
    } catch (err) {
        logError('Profit loss update process error: %O', err);
        // TODO fix this propblem compexly
        yield call(console.warn, err);
        yield fork(runProfitLossUpdate);
    }
}
