import { put, getContext } from 'redux-saga/effects';
import { get } from 'lodash';
import { TRADE_REQUESTS, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';

const prepareCustomerInfoData = (data) => ({
    ...data,
    leverage: get(data, 'leverage', 0),
    lastInsuranceConditionId: get(data, 'lastInsuranceConditionId', 0)
});

export function* requestCustomerInfo() {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.getCustomerInfoPending());
        yield tradeService.request(TRADE_REQUESTS.GET_CUSTOMER_INFO, {});
    } catch (err) {
        yield put(actions.trade.getCustomerInfoFailure(err.message));
    }
}

export function* handleCustomerInfo({ body }) {
    try {
        yield put(actions.trade.getCustomerInfoSuccess(prepareCustomerInfoData(body)));
    } catch (err) {
        yield put(actions.trade.getCustomerInfoFailure(err.message));
    }
}
