import { createReducer } from '@reduxjs/toolkit';
import { includes } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';
import { TRADING_MODES, OPERATIONS_TABS, TRADE_TYPES } from 'Constants';

const TABS = {
    [TRADING_MODES.MARGIN]: [
        OPERATIONS_TABS.POSITIONS,
        OPERATIONS_TABS.ORDERS,
        OPERATIONS_TABS.HISTORY,
        OPERATIONS_TABS.EXECUTIONS,
        OPERATIONS_TABS.BALANCES,
        OPERATIONS_TABS.NEWS
    ],
    [TRADING_MODES.BETS]: [
        OPERATIONS_TABS.BETS,
        OPERATIONS_TABS.BETS_HISTORY,
        OPERATIONS_TABS.BALANCES,
        OPERATIONS_TABS.NEWS
    ]
};

const OPERATIONS = {
    ADD: 'add',
    REMOVE: 'remove'
};

const compose = (...functions) => (arg) => functions.reduce((currentArg, currentFunc) => currentFunc(currentArg), arg);

const handleChangedTabItems = (tabName, itemId, operation, condition = true) => (changedTabItems) => {
    if (!condition) {
        return changedTabItems;
    }

    if (!changedTabItems.hasOwnProperty(tabName)) {
        return changedTabItems;
    }

    if (operation === OPERATIONS.ADD) {
        if (includes(changedTabItems[tabName], itemId)) {
            return changedTabItems;
        }

        return {
            ...changedTabItems,
            [tabName]: [...changedTabItems[tabName], itemId]
        };
    }

    if (operation === OPERATIONS.REMOVE) {
        if (!includes(changedTabItems[tabName], itemId)) {
            return changedTabItems;
        }

        return {
            ...changedTabItems,
            [tabName]: changedTabItems[tabName].filter((el) => el !== itemId)
        };
    }

    return changedTabItems;
};

const addActiveIndicatorForTab = (tabName, itemId, state) => {
    const { changedTabItems } = state;

    return {
        ...state,
        changedTabItems: handleChangedTabItems(tabName, itemId, OPERATIONS.ADD)(changedTabItems)
    };
};

const toHistoryOrderStatuses = new Set([
    TRADE_TYPES.ORDER_STATUS.FILLED,
    TRADE_TYPES.ORDER_STATUS.CANCELED,
    TRADE_TYPES.ORDER_STATUS.EXPIRED,
    TRADE_TYPES.ORDER_STATUS.REJECTED
]);

const toActiveOrderStatuses = new Set([
    TRADE_TYPES.ORDER_STATUS.PENDING_NEW,
    TRADE_TYPES.ORDER_STATUS.NEW,
    TRADE_TYPES.ORDER_STATUS.PARTIALLY_FILLED
]);

const fromActiveOrderStatuses = new Set([
    TRADE_TYPES.ORDER_STATUS.FILLED,
    TRADE_TYPES.ORDER_STATUS.CANCELED,
    TRADE_TYPES.ORDER_STATUS.EXPIRED
]);

const toHistoryBetStatuses = new Set([TRADE_TYPES.BET_STATUS.CLOSED, TRADE_TYPES.BET_STATUS.CANCELED]);
const toActiveBetStatuses = new Set([TRADE_TYPES.BET_STATUS.PLACED, TRADE_TYPES.BET_STATUS.OPENED]);
const fromActiveBetStatuses = new Set([TRADE_TYPES.BET_STATUS.CLOSED, TRADE_TYPES.BET_STATUS.CANCELED]);

const orderAndOrderExecutionReducer = (state, { payload }) => ({
    ...state,
    changedTabItems: compose(
        handleChangedTabItems(
            OPERATIONS_TABS.HISTORY,
            payload.orderId,
            OPERATIONS.ADD,
            toHistoryOrderStatuses.has(payload.orderStatus)
        ),
        handleChangedTabItems(
            OPERATIONS_TABS.ORDERS,
            payload.orderId,
            OPERATIONS.ADD,
            toActiveOrderStatuses.has(payload.orderStatus)
        ),
        handleChangedTabItems(
            OPERATIONS_TABS.ORDERS,
            payload.orderId,
            OPERATIONS.REMOVE,
            fromActiveOrderStatuses.has(payload.orderStatus)
        )
    )(state.changedTabItems)
});

const operationsTabs = createReducer(initialState.operationsTabs, {
    [actions.setOperationsTabsByTradingMode]: (state, { payload: mode }) => ({
        currentTabName: TABS[mode][0],
        tabs: TABS[mode],
        changedTabItems: TABS[mode].reduce((acc, tab) => ({ ...acc, [tab]: [] }), {})
    }),

    [actions.selectOperationsTab]: (state, { payload: newTabName }) => ({
        ...state,
        currentTabName: newTabName,
        changedTabItems: { ...state.changedTabItems, [newTabName]: [] }
    }),

    [actions.deactivateIndicatorForTab]: (state, { payload: tabName }) => ({
        ...state,
        changedTabItems: { ...state.changedTabItems, [tabName]: [] }
    }),

    [actions.trade.addPosition]: (state, { payload }) =>
        addActiveIndicatorForTab(OPERATIONS_TABS.POSITIONS, payload.positionKey, state),
    [actions.trade.updatePosition]: (state, { payload }) =>
        addActiveIndicatorForTab(OPERATIONS_TABS.POSITIONS, payload.positionKey, state),
    [actions.trade.gotBalance]: (state, { payload }) =>
        addActiveIndicatorForTab(OPERATIONS_TABS.BALANCES, payload.id, state),

    [actions.trade.gotOrder]: orderAndOrderExecutionReducer,
    [actions.trade.gotOrderExecution]: (state, action) => {
        if (action.payload?.filledVolume) {
        }
        const changedState = action.payload?.filledVolume
            ? addActiveIndicatorForTab(OPERATIONS_TABS.EXECUTIONS, action.payload?.executionId, state)
            : state;

        return orderAndOrderExecutionReducer(changedState, action);
    },
    [actions.trade.gotBet]: (state, { payload }) => ({
        ...state,
        changedTabItems: compose(
            handleChangedTabItems(
                OPERATIONS_TABS.BETS_HISTORY,
                payload.betId,
                OPERATIONS.ADD,
                toHistoryBetStatuses.has(payload.status)
            ),
            handleChangedTabItems(
                OPERATIONS_TABS.BETS,
                payload.betId,
                OPERATIONS.ADD,
                toActiveBetStatuses.has(payload.status)
            ),
            handleChangedTabItems(
                OPERATIONS_TABS.BETS,
                payload.betId,
                OPERATIONS.REMOVE,
                fromActiveBetStatuses.has(payload.status)
            )
        )(state.changedTabItems)
    })
});

export default operationsTabs;
