import { createReducer } from '@reduxjs/toolkit';
import { keyBy, uniq, concat, get } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';

const sortBalancesIdsByBalancesTime = (allIds, byId) =>
    allIds.sort((id1, id2) => get(byId, `${id2}.time`, 0) - get(byId, `${id1}.time`, 0));

const balances = createReducer(initialState.balances, {
    [actions.trade.resetBalances]: () => initialState.balances,
    [actions.trade.getBalancesSuccess]: ({ allIds, byId }, { payload: balances }) => {
        const newAllIds = balances.map((b) => b.id);
        const newById = keyBy(balances, (b) => b.id);

        const preparedById = { ...byId, ...newById };
        const preparedIds = sortBalancesIdsByBalancesTime(uniq(concat(allIds, newAllIds)), preparedById);

        return { byId: preparedById, allIds: preparedIds };
    },
    [actions.trade.gotBalance]: ({ allIds, byId }, { payload: balance }) => {
        return {
            byId: { ...byId, [balance.id]: balance },
            allIds: uniq(concat(allIds, balance.id)).sort((a, b) => b - a)
        };
    }
});

export default balances;
