import { createAction } from '@reduxjs/toolkit';

// News WS Connection
export const WSConnectionConnecting = createAction('NEWS_WS_CONNECTION_CONNECTING');
export const WSConnectionOpen = createAction('NEWS_WS_CONNECTION_OPEN');
export const WSConnectionOnError = createAction('NEWS_WS_CONNECTION_ON_ERROR');
export const WSConnectionClose = createAction('NEWS_WS_CONNECTION_CLOSE');

export const subscribeToNews = createAction('SUBSCRIBE_TO_NEWS');
export const newsSubscribePending = createAction('NEWS_SUBSCRIBE_PENDING');
export const newsSubscribeSuccess = createAction('NEWS_SUBSCRIBE_SUCCESS');
export const newsSubscribeFailure = createAction('NEWS_SUBSCRIBE_FAILURE');

export const unsubscribeFromNews = createAction('UNSUBSCRIBE_FROM_NEWS');
export const newsUnsubscribePending = createAction('NEWS_UNSUBSCRIBE_PENDING');
export const newsUnsubscribeSuccess = createAction('NEWS_UNSUBSCRIBE_SUCCESS');
export const newsUnsubscribeFailure = createAction('NEWS_UNSUBSCRIBE_FAILURE');

export const getNewsItem = createAction('GET_NEWS_ITEM');
export const getNewsItemPending = createAction('GET_NEWS_ITEM_PENDING');
export const getNewsItemSuccess = createAction('GET_NEWS_ITEM_SUCCESS');
export const getNewsItemFailure = createAction('GET_NEWS_ITEM_FAILURE');

export const loadNews = createAction('LOAD_NEWS');
export const loadMoreNews = createAction('LOAD_MORE_NEWS');
export const newsIsEmpty = createAction('NEWS_IS_EMPTY');
export const getNewsPending = createAction('GET_NEWS_PENDING');
export const getNewsSuccess = createAction('GET_NEWS_SUCCESS');
export const getNewsFailure = createAction('GET_NEWS_FAILURE');
export const gotNews = createAction('GOT_NEWS_PAGE');
export const resetNews = createAction('RESET_NEWS');
