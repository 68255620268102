/**
 * @param {Object} symbols - store.symbols.byId object like { symbolName1: symbolObject1, symbolName2: symbolObject2 ...  }
 * @param {string} symbolName - symbol name/id symbols.byId[symbolName]
 * @param {string} parameterName - symbols.byId[symbolName][parameterName]
 * @param {any} defaultValue
 *
 *  instead _.get(symbols, `${symbolName}.digits`, 8);
 *  use getSymbolParameterValue(symbols, symbolName, 'digits', 8);
 *  because symbolName can contain '.', like AUDCAD.oz
 */
const getSymbolParameterValue = (symbols, symbolName, parameterName, defaultValue = null) => {
    if (!symbols || !symbolName || !parameterName) {
        return defaultValue;
    }

    const symbol = symbols[symbolName];
    if (!symbol || !symbol.hasOwnProperty || !symbol.hasOwnProperty(parameterName)) {
        return defaultValue;
    }

    return symbol[parameterName];
};

export default getSymbolParameterValue;
