import { select, put } from 'redux-saga/effects';
import { difference, values, isEmpty } from 'lodash';
import * as actions from 'Store/actions';
import logger from 'Utils/logger';
import { isActiveBetStatus } from 'Utils/statusFilters';

const log = logger('Saga:BetsSubscribeToQuote:');
const logError = logger('Error:Saga:BetsSubscribeToQuote:');

const getActiveBetsSymbols = (bets) =>
    bets.filter(({ status }) => isActiveBetStatus(status)).map(({ symbol }) => symbol);

export function* subscribeToSymbolQuoteOnGotBet(bet) {
    try {
        const currentBet = yield select((state) => state.bets.byId[bet.betId]);
        const isActiveNewBetStatus = isActiveBetStatus(bet.status);

        if (!bet.symbol) {
            throw new Error(`There is no any symbol for incoming bet (betId: ${bet.betId})`);
        }

        log('Got bet: symbol: %o, is active status %o', bet.symbol, isActiveNewBetStatus);

        if (!currentBet) {
            log('No current bet with id: %o (symbol: %o)', bet.betId, bet.symbol);

            if (isActiveNewBetStatus) {
                yield put(actions.market.subscribeToSymbolQuote(bet.symbol));
                log('New bet: subscribe to %o', bet.symbol);
            }
            return;
        }

        const isActiveCurrentBetStatus = isActiveBetStatus(currentBet.status);
        log('Bet current status is active: %o, new status %o', isActiveCurrentBetStatus, isActiveNewBetStatus);

        if (isActiveCurrentBetStatus && !isActiveNewBetStatus) {
            yield put(actions.market.unsubscribeFromSymbolQuote(bet.symbol));
            log('Unsubscribe from %o', bet.symbol);
            return;
        }

        if (!isActiveCurrentBetStatus && isActiveNewBetStatus) {
            yield put(actions.market.subscribeToSymbolQuote(bet.symbol));
            log('Bet status from finishet to active. So strange case. But, subscribe to %o', bet.symbol);
            return;
        }
    } catch (err) {
        logError('subscribeToSymbolQuoteOnGotBet, error: %o', err.message);
    }
}

export function* subscribeToSymbolQuoteOnGotActiveBetList(bets) {
    try {
        const currentBets = yield select((state) => state.bets.byId);
        const currentActiveBetsSymbols = getActiveBetsSymbols(values(currentBets));
        const incomingActiveBetsSymbols = getActiveBetsSymbols(bets);

        log('Got active bets, length: %o, open bets symbols: %o', bets.length, incomingActiveBetsSymbols);
        log('Current bets length: %o, open bets symbols: %o', currentBets.length, currentActiveBetsSymbols);

        const uniqSymbolsToSubscribe = new Set(difference(incomingActiveBetsSymbols, currentActiveBetsSymbols));
        log('Unique symbol to subscribe: %o', uniqSymbolsToSubscribe);

        const uniqSymbolsToUnsubscribe = new Set(difference(currentActiveBetsSymbols, incomingActiveBetsSymbols));
        log('Unique symbol to unsubscribe: %o', uniqSymbolsToUnsubscribe);

        const symbolsToSubscribe = incomingActiveBetsSymbols.filter((s) => uniqSymbolsToSubscribe.has(s));
        log('Full symbol list to subscribe: %o', symbolsToSubscribe);

        const symbolsToUnsubscribe = currentActiveBetsSymbols.filter((s) => uniqSymbolsToUnsubscribe.has(s));
        log('Full symbol list to unsubscribe: %o', symbolsToUnsubscribe);

        if (!isEmpty(symbolsToSubscribe)) {
            yield put(actions.market.subscribeToSymbolQuote(symbolsToSubscribe));
        }

        if (!isEmpty(symbolsToUnsubscribe)) {
            yield put(actions.market.unsubscribeFromSymbolQuote(symbolsToUnsubscribe));
        }
    } catch (err) {
        logError('subscribeToSymbolQuoteOnGotBetList, error: %o', err.message);
    }
}

export function* unSubscribeFromSymbolQuoteOnResetActiveBets() {
    try {
        const currentBets = yield select((state) => state.bets.byId);
        const currentActiveBetsSymbols = getActiveBetsSymbols(values(currentBets));

        log('Symbols for unsubscribe: %o', currentActiveBetsSymbols);

        if (!isEmpty(currentActiveBetsSymbols)) {
            yield put(actions.market.unsubscribeFromSymbolQuote(currentActiveBetsSymbols));
        }
    } catch (err) {
        logError('subscribeToSymbolQuoteOnGotBetList, error: %o', err.message);
    }
}
