import { toast } from 'react-toastify';
import * as actions from 'Store/actions';
import i18n from 'App/i18n';
import { TRADE_TYPES } from 'Constants';
import { TRANSLATION_KEYS } from './constants';

const toastActionHandlers = {
    [actions.trade.gotMarginTradingResult]: ({ payload }) => {
        if (!payload?.body?.code) {
            return;
        }

        const message = payload?.body?.text;
        const errorMessage = message
            ? i18n.t(TRANSLATION_KEYS.SERVER_MESSAGE, { message })
            : i18n.t(TRANSLATION_KEYS.INCORRECT_ORDER_DATA);

        const id = payload?.id || 0;
        toast.error(errorMessage, { toastId: id });
    },
    [actions.trade.gotOrderExecution]: ({ payload }) => {
        const orderId = payload?.orderId || 0;

        const orderStatus = payload?.orderStatus || TRADE_TYPES.ORDER_STATUS.NEW;

        if (orderStatus === TRADE_TYPES.ORDER_STATUS.NEW) {
            const toastId = `created_${orderId}`;

            const message = i18n.t(TRANSLATION_KEYS.ORDER_HAS_BEEN_CREATED, { orderId });

            toast.success(message, { toastId });
            return;
        }

        const status = i18n.t(TRANSLATION_KEYS.ORDER_STATUS[orderStatus]);
        const message = i18n.t(TRANSLATION_KEYS.ORDER_STATUS_HAS_BEEN_CHANGED, { status, orderId });

        const toastId = `changed_${orderId}`;

        toast.info(message, { toastId });
        // it doesn't work: toast.isActive(orderId)
        toast.update(toastId, { render: message });
    },
    [actions.trade.updatePositionRequestSuccess]: ({ payload }) => {
        const toastId = `position_changed_${payload?.positionKey}`;
        const message = i18n.t(TRANSLATION_KEYS.POSITION_HAS_BEEN_CHANGED, payload);
        toast.info(message, { toastId });
        // it doesn't work: toast.isActive(orderId)
        toast.update(toastId, { render: message });
    },
    [actions.trade.gotBetsResult]: ({ payload }) => {
        if (payload?.body?.code !== 1) {
            return;
        }

        const message = payload?.body?.text;
        const errorMessage = message
            ? i18n.t(TRANSLATION_KEYS.SERVER_MESSAGE, { message })
            : i18n.t(TRANSLATION_KEYS.INCORRECT_BET_DATA);

        const id = payload?.id || 0;
        toast.error(errorMessage, { toastId: id });
    }
};

const toastMiddleware = (store) => (next) => (action) => {
    next(action);
    toastActionHandlers[action.type] && toastActionHandlers[action.type](action, store);
};

export default toastMiddleware;
