import { takeEvery, all } from 'redux-saga/effects';
import * as actions from 'Store/actions';
import { favoritesInitialSettings } from './favorites.saga';
import { chartTabsInitialSettings } from './chartTabs.saga';
import { themeInitialSettings } from './theme.saga';
import { tradingFormModeInitialSettings } from './tradingFormMode.saga';

export default function* initialSettingsSaga() {
    yield takeEvery(actions.setIsAuthenticated, runInitialSettingsOnAuth);
    yield all([themeInitialSettings()]);
}

function* runInitialSettingsOnAuth({ payload }) {
    if (payload !== true) {
        return;
    }
    // These settings are not copied from the application state before user logout,
    // therefore they are set after user login
    yield all([chartTabsInitialSettings(), favoritesInitialSettings(), tradingFormModeInitialSettings()]);
}
