import { createReducer, combineReducers } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';
import { LOADING_STATUSES } from 'Constants';

const loadingStatuses = combineReducers({
    balances: createReducer(initialState.loadingStatuses.balances, {
        [actions.trade.getBalancesPending]: () => LOADING_STATUSES.PENDING,
        [actions.trade.getBalancesSuccess]: () => LOADING_STATUSES.SUCCESS,
        [actions.trade.getBalancesFailure]: () => LOADING_STATUSES.FAILURE,
        [actions.trade.balancesIsEmpty]: () => LOADING_STATUSES.EMPTY,
        [actions.trade.WSConnectionClose]: () => LOADING_STATUSES.INIT
    }),
    orders: createReducer(initialState.loadingStatuses.orders, {
        [actions.trade.getOrderListPending]: () => LOADING_STATUSES.PENDING,
        [actions.trade.getOrderListSuccess]: () => LOADING_STATUSES.SUCCESS,
        [actions.trade.getOrderListFailure]: () => LOADING_STATUSES.FAILURE,
        [actions.trade.ordersIsEmpty]: () => LOADING_STATUSES.EMPTY,
        [actions.trade.WSConnectionClose]: () => LOADING_STATUSES.INIT
    }),
    orderExecutions: createReducer(initialState.loadingStatuses.orderExecutions, {
        [actions.trade.getOrderExecutionListPending]: () => LOADING_STATUSES.PENDING,
        [actions.trade.getOrderExecutionListSuccess]: () => LOADING_STATUSES.SUCCESS,
        [actions.trade.getOrderExecutionListFailure]: () => LOADING_STATUSES.FAILURE,
        [actions.trade.orderExecutionsIsEmpty]: () => LOADING_STATUSES.EMPTY,
        [actions.trade.WSConnectionClose]: () => LOADING_STATUSES.INIT
    }),
    positions: createReducer(initialState.loadingStatuses.positions, {
        [actions.trade.getPositionListPending]: () => LOADING_STATUSES.PENDING,
        [actions.trade.getPositionListSuccess]: () => LOADING_STATUSES.SUCCESS,
        [actions.trade.getPositionListFailure]: () => LOADING_STATUSES.FAILURE,
        [actions.trade.WSConnectionClose]: () => LOADING_STATUSES.INIT
    }),
    news: createReducer(initialState.loadingStatuses.news, {
        [actions.news.getNewsPending]: () => LOADING_STATUSES.PENDING,
        [actions.news.getNewsSuccess]: () => LOADING_STATUSES.SUCCESS,
        [actions.news.getNewsFailure]: () => LOADING_STATUSES.FAILURE,
        [actions.news.newsIsEmpty]: () => LOADING_STATUSES.EMPTY,
        [actions.news.WSConnectionClose]: () => LOADING_STATUSES.INIT
    }),
    newsItem: createReducer(initialState.loadingStatuses.newsItem, {
        [actions.news.getNewsItemPending]: () => LOADING_STATUSES.PENDING,
        [actions.news.getNewsItemSuccess]: () => LOADING_STATUSES.SUCCESS,
        [actions.news.getNewsItemFailure]: () => LOADING_STATUSES.FAILURE,
        [actions.news.newsItemIsEmpty]: () => LOADING_STATUSES.EMPTY,
        [actions.news.WSConnectionClose]: () => LOADING_STATUSES.INIT
    }),
    bets: createReducer(initialState.loadingStatuses.bets, {
        [actions.trade.getBetListPending]: () => LOADING_STATUSES.PENDING,
        [actions.trade.getBetListSuccess]: () => LOADING_STATUSES.SUCCESS,
        [actions.trade.getBetListFailure]: () => LOADING_STATUSES.FAILURE,
        [actions.oneOfMarketOrTradeWSConnectionsClose]: () => LOADING_STATUSES.INIT
    }),
    finishedBets: createReducer(initialState.loadingStatuses.finishedBets, {
        [actions.trade.getFinishedBetListPending]: () => LOADING_STATUSES.PENDING,
        [actions.trade.getFinishedBetListSuccess]: () => LOADING_STATUSES.SUCCESS,
        [actions.trade.getFinishedBetListFailure]: () => LOADING_STATUSES.FAILURE,
        [actions.trade.finishedBetsIsEmpty]: () => LOADING_STATUSES.EMPTY,
        [actions.oneOfMarketOrTradeWSConnectionsClose]: () => LOADING_STATUSES.INIT
    }),
    chartBars: createReducer(initialState.loadingStatuses.chartBars, {
        [actions.market.getChartBarListPending]: () => LOADING_STATUSES.PENDING,
        [actions.market.getChartBarListSuccess]: () => LOADING_STATUSES.SUCCESS,
        [actions.market.getChartBarListFailure]: () => LOADING_STATUSES.FAILURE,
        [actions.market.chartBarsIsEmpty]: () => LOADING_STATUSES.EMPTY,
        [actions.market.WSConnectionClose]: () => LOADING_STATUSES.INIT
    }),
    storeSettings: createReducer(initialState.loadingStatuses.storeSettings, {
        [actions.technic.storeSettingsPending]: () => LOADING_STATUSES.PENDING,
        [actions.technic.storeSettingsSuccess]: () => LOADING_STATUSES.SUCCESS,
        [actions.technic.storeSettingsFailure]: () => LOADING_STATUSES.FAILURE,
        [actions.technic.WSConnectionClose]: () => LOADING_STATUSES.INIT
    }),
    commission: createReducer(initialState.loadingStatuses.commission, {
        [actions.trade.getCommissionPending]: () => LOADING_STATUSES.PENDING,
        [actions.trade.getCommissionSuccess]: () => LOADING_STATUSES.SUCCESS,
        [actions.trade.getCommissionFailure]: () => LOADING_STATUSES.FAILURE,
        [actions.trade.WSConnectionClose]: () => LOADING_STATUSES.INIT
    })
});

export default loadingStatuses;
