import { LOADING_STATUSES, CONNECTION_STATUSES, TRADE_TYPES } from 'Constants';

// Connection
export const isOpenConnectionStatus = (status) => status === CONNECTION_STATUSES.OPEN;

// Data loading
export const isInitDataStatus = (status) => status === LOADING_STATUSES.INIT;
export const isLoadingDataStatus = (status) => status === LOADING_STATUSES.PENDING;
export const isErrorDataStatus = (status) => status === LOADING_STATUSES.FAILURE;
export const isEmptyDataStatus = (status) => status === LOADING_STATUSES.EMPTY;

// Bets
export const isOpenBetStatus = (status) => status === TRADE_TYPES.BET_STATUS.OPENED;

export const isFinishedBetStatus = (status) =>
    status === TRADE_TYPES.BET_STATUS.CLOSED || status === TRADE_TYPES.BET_STATUS.CANCELED;

export const isActiveBetStatus = (status) =>
    status === TRADE_TYPES.BET_STATUS.PLACED || status === TRADE_TYPES.BET_STATUS.OPENED;
