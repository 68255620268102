import { call, takeEvery, select, fork, put, cancel, getContext } from 'redux-saga/effects';
import { get, includes, values, merge } from 'lodash';
import { EVENTS, TECHNIC_REQUESTS, LOCALES, TECHNIC_TYPES, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';
import getEventChannelCreator from 'Store/sagas/utils/getEventChannelCreator';
import { isOpenConnectionStatus } from 'Utils/statusFilters';
import logger from 'Utils/logger';

const logError = logger('Error:TechnicSettingsSaga:');

export { getSnapshotAndSubscribeToSettings, handleSettings, storeFavorites, storeLocale, storeTheme };

function* getSnapshotAndSubscribeToSettings() {
    let subscriptionTask;
    try {
        const technicService = yield getContext(SERVICE_NAMES.TECHNIC);
        yield getInitSettings(technicService);
        subscriptionTask = yield fork(subscribeToSettings, technicService);
    } catch (err) {
        logError('getSnapshotAndSubscribeToSettings: %s', err.message);

        if (!!subscriptionTask) {
            yield cancel(subscriptionTask); // TODO: maybe retry???
        }
    }
}

function* getInitSettings(technicService) {
    const response = yield technicService.request(TECHNIC_REQUESTS.GET_SETTINGS, {});
    yield handleSettings(response);
}

function* subscribeToSettings(technicService) {
    const createEventChannel = getEventChannelCreator(technicService, EVENTS.TECHNIC.CLOSE);
    const settingsChannel = yield call(createEventChannel, EVENTS.TECHNIC.SETTINGS);
    yield takeEvery(settingsChannel, handleSettings);
}

function* handleSettings(response) {
    const favorites = get(response, 'body.favoriteSymbol', []);
    const theme = get(response, 'body.webTerminal.theme', TECHNIC_TYPES.THEME.LIGHT);
    const language = get(response, 'body.webTerminal.language', null);

    yield put(actions.gotFavorites(favorites));
    yield put(actions.gotTheme(theme));

    if (language) {
        yield put(actions.gotLocale(language));
    }
}

function* storeFavorites({ payload: favorites }) {
    const canStore = yield canStoreToSrv();
    if (!canStore) {
        return;
    }

    const currentSettings = yield getCurrentSettings();
    const settings = merge(currentSettings, { favoriteSymbol: favorites });
    yield storeSettingsRequest(settings);
}

function* storeLocale({ payload: currentLocale }) {
    const canStore = yield canStoreToSrv();
    const isDisabledServerSyncLanguage = yield select((state) => state.isDisabledServerSyncLanguage);

    if (!canStore || isDisabledServerSyncLanguage) {
        return;
    }

    const currentSettings = yield getCurrentSettings();
    const settings = merge(currentSettings, { webTerminal: { language: currentLocale } });
    yield storeSettingsRequest(settings);
}

function* storeTheme({ payload: theme }) {
    const canStore = yield canStoreToSrv();
    if (!canStore) {
        return;
    }

    const currentSettings = yield getCurrentSettings();
    const settings = merge(currentSettings, { webTerminal: { theme } });
    yield storeSettingsRequest(settings);
}

function* storeSettingsRequest(settings) {
    try {
        const technicService = yield getContext(SERVICE_NAMES.TECHNIC);
        yield technicService.request(TECHNIC_REQUESTS.STORE_SETTINGS, { settings });
    } catch (err) {
        logError('storeSettingsRequest: %O', err);
    }
}

function* canStoreToSrv() {
    const { isAuthenticated, isOpenConnection } = yield select((state) => ({
        isAuthenticated: state.isAuthenticated,
        isOpenConnection: isOpenConnectionStatus(state.connectionsStatuses.technic)
    }));

    return isAuthenticated && isOpenConnection;
}

function* getCurrentSettings() {
    const { favoriteSymbol, theme, language } = yield select(({ favorites, theme, currentLocale }) => ({
        favoriteSymbol: favorites,
        theme,
        language: includes(values(LOCALES), currentLocale) ? currentLocale : LOCALES.EN
    }));

    return { favoriteSymbol, webTerminal: { theme, language } };
}
