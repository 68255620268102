import { createReducer } from '@reduxjs/toolkit';
import { keyBy, includes } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';

const insuranceConditions = createReducer(initialState.insuranceConditions, {
    [actions.trade.getInsuranceConditionListSuccess]: (state, { payload: conditions }) => {
        const allIds = conditions.map((c) => c.insuranceConditionId);
        const byId = keyBy(conditions, 'insuranceConditionId');

        return { byId, allIds };
    },
    [actions.trade.gotInsuranceCondition]: ({ allIds, byId }, { payload: condition }) => {
        const newAllIds = includes(allIds, condition.insuranceConditionId)
            ? allIds
            : [...allIds, condition.insuranceConditionId];

        const newById = { ...byId, [condition.insuranceConditionId]: condition };

        return { byId: newById, allIds: newAllIds };
    },
    [actions.trade.WSConnectionClose]: () => initialState.insuranceConditions
});

export default insuranceConditions;
