/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.news = (function() {

    /**
     * Namespace news.
     * @exports news
     * @namespace
     */
    var news = {};

    /**
     * ProtocolVersion enum.
     * @name news.ProtocolVersion
     * @enum {number}
     * @property {number} __PLACEHOLDER1=0 __PLACEHOLDER1 value
     * @property {number} __PLACEHOLDER2=0 __PLACEHOLDER2 value
     * @property {number} MAJOR=1 MAJOR value
     * @property {number} MINOR=1 MINOR value
     * @property {number} PATCH=0 PATCH value
     */
    news.ProtocolVersion = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "__PLACEHOLDER1"] = 0;
        values["__PLACEHOLDER2"] = 0;
        values[valuesById[1] = "MAJOR"] = 1;
        values["MINOR"] = 1;
        values["PATCH"] = 0;
        return values;
    })();

    news.DateTime = (function() {

        /**
         * Properties of a DateTime.
         * @memberof news
         * @interface IDateTime
         * @property {number|Long|null} [millisecondsFromEpoch] DateTime millisecondsFromEpoch
         */

        /**
         * Constructs a new DateTime.
         * @memberof news
         * @classdesc Represents a DateTime.
         * @implements IDateTime
         * @constructor
         * @param {news.IDateTime=} [properties] Properties to set
         */
        function DateTime(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DateTime millisecondsFromEpoch.
         * @member {number|Long} millisecondsFromEpoch
         * @memberof news.DateTime
         * @instance
         */
        DateTime.prototype.millisecondsFromEpoch = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new DateTime instance using the specified properties.
         * @function create
         * @memberof news.DateTime
         * @static
         * @param {news.IDateTime=} [properties] Properties to set
         * @returns {news.DateTime} DateTime instance
         */
        DateTime.create = function create(properties) {
            return new DateTime(properties);
        };

        /**
         * Encodes the specified DateTime message. Does not implicitly {@link news.DateTime.verify|verify} messages.
         * @function encode
         * @memberof news.DateTime
         * @static
         * @param {news.IDateTime} message DateTime message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateTime.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.millisecondsFromEpoch != null && Object.hasOwnProperty.call(message, "millisecondsFromEpoch"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.millisecondsFromEpoch);
            return writer;
        };

        /**
         * Decodes a DateTime message from the specified reader or buffer.
         * @function decode
         * @memberof news.DateTime
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {news.DateTime} DateTime
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateTime.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.news.DateTime();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.millisecondsFromEpoch = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Verifies a DateTime message.
         * @function verify
         * @memberof news.DateTime
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DateTime.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.millisecondsFromEpoch != null && message.hasOwnProperty("millisecondsFromEpoch"))
                if (!$util.isInteger(message.millisecondsFromEpoch) && !(message.millisecondsFromEpoch && $util.isInteger(message.millisecondsFromEpoch.low) && $util.isInteger(message.millisecondsFromEpoch.high)))
                    return "millisecondsFromEpoch: integer|Long expected";
            return null;
        };

        /**
         * Creates a DateTime message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof news.DateTime
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {news.DateTime} DateTime
         */
        DateTime.fromObject = function fromObject(object) {
            if (object instanceof $root.news.DateTime)
                return object;
            var message = new $root.news.DateTime();
            if (object.millisecondsFromEpoch != null)
                if ($util.Long)
                    (message.millisecondsFromEpoch = $util.Long.fromValue(object.millisecondsFromEpoch)).unsigned = false;
                else if (typeof object.millisecondsFromEpoch === "string")
                    message.millisecondsFromEpoch = parseInt(object.millisecondsFromEpoch, 10);
                else if (typeof object.millisecondsFromEpoch === "number")
                    message.millisecondsFromEpoch = object.millisecondsFromEpoch;
                else if (typeof object.millisecondsFromEpoch === "object")
                    message.millisecondsFromEpoch = new $util.LongBits(object.millisecondsFromEpoch.low >>> 0, object.millisecondsFromEpoch.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a DateTime message. Also converts values to other types if specified.
         * @function toObject
         * @memberof news.DateTime
         * @static
         * @param {news.DateTime} message DateTime
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DateTime.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.millisecondsFromEpoch = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.millisecondsFromEpoch = options.longs === String ? "0" : 0;
            if (message.millisecondsFromEpoch != null && message.hasOwnProperty("millisecondsFromEpoch"))
                if (typeof message.millisecondsFromEpoch === "number")
                    object.millisecondsFromEpoch = options.longs === String ? String(message.millisecondsFromEpoch) : message.millisecondsFromEpoch;
                else
                    object.millisecondsFromEpoch = options.longs === String ? $util.Long.prototype.toString.call(message.millisecondsFromEpoch) : options.longs === Number ? new $util.LongBits(message.millisecondsFromEpoch.low >>> 0, message.millisecondsFromEpoch.high >>> 0).toNumber() : message.millisecondsFromEpoch;
            return object;
        };

        /**
         * Converts this DateTime to JSON.
         * @function toJSON
         * @memberof news.DateTime
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DateTime.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DateTime;
    })();

    news.News = (function() {

        /**
         * Properties of a News.
         * @memberof news
         * @interface INews
         * @property {number|Long|null} [id] News id
         * @property {string|null} [npName] News npName
         * @property {string|null} [category] News category
         * @property {news.IDateTime|null} [timestamp] News timestamp
         * @property {string|null} [title] News title
         * @property {string|null} [body] News body
         * @property {string|null} [language] News language
         * @property {Array.<string>|null} [ticker] News ticker
         * @property {Array.<string>|null} [tag] News tag
         */

        /**
         * Constructs a new News.
         * @memberof news
         * @classdesc Represents a News.
         * @implements INews
         * @constructor
         * @param {news.INews=} [properties] Properties to set
         */
        function News(properties) {
            this.ticker = [];
            this.tag = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * News id.
         * @member {number|Long} id
         * @memberof news.News
         * @instance
         */
        News.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * News npName.
         * @member {string} npName
         * @memberof news.News
         * @instance
         */
        News.prototype.npName = "";

        /**
         * News category.
         * @member {string} category
         * @memberof news.News
         * @instance
         */
        News.prototype.category = "";

        /**
         * News timestamp.
         * @member {news.IDateTime|null|undefined} timestamp
         * @memberof news.News
         * @instance
         */
        News.prototype.timestamp = null;

        /**
         * News title.
         * @member {string} title
         * @memberof news.News
         * @instance
         */
        News.prototype.title = "";

        /**
         * News body.
         * @member {string} body
         * @memberof news.News
         * @instance
         */
        News.prototype.body = "";

        /**
         * News language.
         * @member {string} language
         * @memberof news.News
         * @instance
         */
        News.prototype.language = "";

        /**
         * News ticker.
         * @member {Array.<string>} ticker
         * @memberof news.News
         * @instance
         */
        News.prototype.ticker = $util.emptyArray;

        /**
         * News tag.
         * @member {Array.<string>} tag
         * @memberof news.News
         * @instance
         */
        News.prototype.tag = $util.emptyArray;

        /**
         * Creates a new News instance using the specified properties.
         * @function create
         * @memberof news.News
         * @static
         * @param {news.INews=} [properties] Properties to set
         * @returns {news.News} News instance
         */
        News.create = function create(properties) {
            return new News(properties);
        };

        /**
         * Encodes the specified News message. Does not implicitly {@link news.News.verify|verify} messages.
         * @function encode
         * @memberof news.News
         * @static
         * @param {news.INews} message News message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        News.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
            if (message.npName != null && Object.hasOwnProperty.call(message, "npName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.npName);
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.category);
            if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                $root.news.DateTime.encode(message.timestamp, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.title);
            if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.body);
            if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.language);
            if (message.ticker != null && message.ticker.length)
                for (var i = 0; i < message.ticker.length; ++i)
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.ticker[i]);
            if (message.tag != null && message.tag.length)
                for (var i = 0; i < message.tag.length; ++i)
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.tag[i]);
            return writer;
        };

        /**
         * Decodes a News message from the specified reader or buffer.
         * @function decode
         * @memberof news.News
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {news.News} News
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        News.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.news.News();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int64();
                    break;
                case 2:
                    message.npName = reader.string();
                    break;
                case 3:
                    message.category = reader.string();
                    break;
                case 4:
                    message.timestamp = $root.news.DateTime.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.title = reader.string();
                    break;
                case 6:
                    message.body = reader.string();
                    break;
                case 7:
                    message.language = reader.string();
                    break;
                case 8:
                    if (!(message.ticker && message.ticker.length))
                        message.ticker = [];
                    message.ticker.push(reader.string());
                    break;
                case 9:
                    if (!(message.tag && message.tag.length))
                        message.tag = [];
                    message.tag.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Verifies a News message.
         * @function verify
         * @memberof news.News
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        News.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            if (message.npName != null && message.hasOwnProperty("npName"))
                if (!$util.isString(message.npName))
                    return "npName: string expected";
            if (message.category != null && message.hasOwnProperty("category"))
                if (!$util.isString(message.category))
                    return "category: string expected";
            if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                var error = $root.news.DateTime.verify(message.timestamp);
                if (error)
                    return "timestamp." + error;
            }
            if (message.title != null && message.hasOwnProperty("title"))
                if (!$util.isString(message.title))
                    return "title: string expected";
            if (message.body != null && message.hasOwnProperty("body"))
                if (!$util.isString(message.body))
                    return "body: string expected";
            if (message.language != null && message.hasOwnProperty("language"))
                if (!$util.isString(message.language))
                    return "language: string expected";
            if (message.ticker != null && message.hasOwnProperty("ticker")) {
                if (!Array.isArray(message.ticker))
                    return "ticker: array expected";
                for (var i = 0; i < message.ticker.length; ++i)
                    if (!$util.isString(message.ticker[i]))
                        return "ticker: string[] expected";
            }
            if (message.tag != null && message.hasOwnProperty("tag")) {
                if (!Array.isArray(message.tag))
                    return "tag: array expected";
                for (var i = 0; i < message.tag.length; ++i)
                    if (!$util.isString(message.tag[i]))
                        return "tag: string[] expected";
            }
            return null;
        };

        /**
         * Creates a News message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof news.News
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {news.News} News
         */
        News.fromObject = function fromObject(object) {
            if (object instanceof $root.news.News)
                return object;
            var message = new $root.news.News();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
            if (object.npName != null)
                message.npName = String(object.npName);
            if (object.category != null)
                message.category = String(object.category);
            if (object.timestamp != null) {
                if (typeof object.timestamp !== "object")
                    throw TypeError(".news.News.timestamp: object expected");
                message.timestamp = $root.news.DateTime.fromObject(object.timestamp);
            }
            if (object.title != null)
                message.title = String(object.title);
            if (object.body != null)
                message.body = String(object.body);
            if (object.language != null)
                message.language = String(object.language);
            if (object.ticker) {
                if (!Array.isArray(object.ticker))
                    throw TypeError(".news.News.ticker: array expected");
                message.ticker = [];
                for (var i = 0; i < object.ticker.length; ++i)
                    message.ticker[i] = String(object.ticker[i]);
            }
            if (object.tag) {
                if (!Array.isArray(object.tag))
                    throw TypeError(".news.News.tag: array expected");
                message.tag = [];
                for (var i = 0; i < object.tag.length; ++i)
                    message.tag[i] = String(object.tag[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a News message. Also converts values to other types if specified.
         * @function toObject
         * @memberof news.News
         * @static
         * @param {news.News} message News
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        News.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.ticker = [];
                object.tag = [];
            }
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
                object.npName = "";
                object.category = "";
                object.timestamp = null;
                object.title = "";
                object.body = "";
                object.language = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
            if (message.npName != null && message.hasOwnProperty("npName"))
                object.npName = message.npName;
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = message.category;
            if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                object.timestamp = $root.news.DateTime.toObject(message.timestamp, options);
            if (message.title != null && message.hasOwnProperty("title"))
                object.title = message.title;
            if (message.body != null && message.hasOwnProperty("body"))
                object.body = message.body;
            if (message.language != null && message.hasOwnProperty("language"))
                object.language = message.language;
            if (message.ticker && message.ticker.length) {
                object.ticker = [];
                for (var j = 0; j < message.ticker.length; ++j)
                    object.ticker[j] = message.ticker[j];
            }
            if (message.tag && message.tag.length) {
                object.tag = [];
                for (var j = 0; j < message.tag.length; ++j)
                    object.tag[j] = message.tag[j];
            }
            return object;
        };

        /**
         * Converts this News to JSON.
         * @function toJSON
         * @memberof news.News
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        News.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return News;
    })();

    news.NewsList = (function() {

        /**
         * Properties of a NewsList.
         * @memberof news
         * @interface INewsList
         * @property {Array.<news.INews>|null} [news] NewsList news
         * @property {number|Long|null} [totalNews] NewsList totalNews
         */

        /**
         * Constructs a new NewsList.
         * @memberof news
         * @classdesc Represents a NewsList.
         * @implements INewsList
         * @constructor
         * @param {news.INewsList=} [properties] Properties to set
         */
        function NewsList(properties) {
            this.news = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NewsList news.
         * @member {Array.<news.INews>} news
         * @memberof news.NewsList
         * @instance
         */
        NewsList.prototype.news = $util.emptyArray;

        /**
         * NewsList totalNews.
         * @member {number|Long} totalNews
         * @memberof news.NewsList
         * @instance
         */
        NewsList.prototype.totalNews = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new NewsList instance using the specified properties.
         * @function create
         * @memberof news.NewsList
         * @static
         * @param {news.INewsList=} [properties] Properties to set
         * @returns {news.NewsList} NewsList instance
         */
        NewsList.create = function create(properties) {
            return new NewsList(properties);
        };

        /**
         * Encodes the specified NewsList message. Does not implicitly {@link news.NewsList.verify|verify} messages.
         * @function encode
         * @memberof news.NewsList
         * @static
         * @param {news.INewsList} message NewsList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NewsList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.news != null && message.news.length)
                for (var i = 0; i < message.news.length; ++i)
                    $root.news.News.encode(message.news[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.totalNews != null && Object.hasOwnProperty.call(message, "totalNews"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.totalNews);
            return writer;
        };

        /**
         * Decodes a NewsList message from the specified reader or buffer.
         * @function decode
         * @memberof news.NewsList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {news.NewsList} NewsList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NewsList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.news.NewsList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.news && message.news.length))
                        message.news = [];
                    message.news.push($root.news.News.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.totalNews = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Verifies a NewsList message.
         * @function verify
         * @memberof news.NewsList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NewsList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.news != null && message.hasOwnProperty("news")) {
                if (!Array.isArray(message.news))
                    return "news: array expected";
                for (var i = 0; i < message.news.length; ++i) {
                    var error = $root.news.News.verify(message.news[i]);
                    if (error)
                        return "news." + error;
                }
            }
            if (message.totalNews != null && message.hasOwnProperty("totalNews"))
                if (!$util.isInteger(message.totalNews) && !(message.totalNews && $util.isInteger(message.totalNews.low) && $util.isInteger(message.totalNews.high)))
                    return "totalNews: integer|Long expected";
            return null;
        };

        /**
         * Creates a NewsList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof news.NewsList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {news.NewsList} NewsList
         */
        NewsList.fromObject = function fromObject(object) {
            if (object instanceof $root.news.NewsList)
                return object;
            var message = new $root.news.NewsList();
            if (object.news) {
                if (!Array.isArray(object.news))
                    throw TypeError(".news.NewsList.news: array expected");
                message.news = [];
                for (var i = 0; i < object.news.length; ++i) {
                    if (typeof object.news[i] !== "object")
                        throw TypeError(".news.NewsList.news: object expected");
                    message.news[i] = $root.news.News.fromObject(object.news[i]);
                }
            }
            if (object.totalNews != null)
                if ($util.Long)
                    (message.totalNews = $util.Long.fromValue(object.totalNews)).unsigned = true;
                else if (typeof object.totalNews === "string")
                    message.totalNews = parseInt(object.totalNews, 10);
                else if (typeof object.totalNews === "number")
                    message.totalNews = object.totalNews;
                else if (typeof object.totalNews === "object")
                    message.totalNews = new $util.LongBits(object.totalNews.low >>> 0, object.totalNews.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from a NewsList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof news.NewsList
         * @static
         * @param {news.NewsList} message NewsList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NewsList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.news = [];
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.totalNews = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalNews = options.longs === String ? "0" : 0;
            if (message.news && message.news.length) {
                object.news = [];
                for (var j = 0; j < message.news.length; ++j)
                    object.news[j] = $root.news.News.toObject(message.news[j], options);
            }
            if (message.totalNews != null && message.hasOwnProperty("totalNews"))
                if (typeof message.totalNews === "number")
                    object.totalNews = options.longs === String ? String(message.totalNews) : message.totalNews;
                else
                    object.totalNews = options.longs === String ? $util.Long.prototype.toString.call(message.totalNews) : options.longs === Number ? new $util.LongBits(message.totalNews.low >>> 0, message.totalNews.high >>> 0).toNumber(true) : message.totalNews;
            return object;
        };

        /**
         * Converts this NewsList to JSON.
         * @function toJSON
         * @memberof news.NewsList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NewsList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NewsList;
    })();

    news.Result = (function() {

        /**
         * Properties of a Result.
         * @memberof news
         * @interface IResult
         * @property {news.Result.Code|null} [code] Result code
         * @property {string|null} [description] Result description
         */

        /**
         * Constructs a new Result.
         * @memberof news
         * @classdesc Represents a Result.
         * @implements IResult
         * @constructor
         * @param {news.IResult=} [properties] Properties to set
         */
        function Result(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Result code.
         * @member {news.Result.Code} code
         * @memberof news.Result
         * @instance
         */
        Result.prototype.code = 0;

        /**
         * Result description.
         * @member {string} description
         * @memberof news.Result
         * @instance
         */
        Result.prototype.description = "";

        /**
         * Creates a new Result instance using the specified properties.
         * @function create
         * @memberof news.Result
         * @static
         * @param {news.IResult=} [properties] Properties to set
         * @returns {news.Result} Result instance
         */
        Result.create = function create(properties) {
            return new Result(properties);
        };

        /**
         * Encodes the specified Result message. Does not implicitly {@link news.Result.verify|verify} messages.
         * @function encode
         * @memberof news.Result
         * @static
         * @param {news.IResult} message Result message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Result.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            return writer;
        };

        /**
         * Decodes a Result message from the specified reader or buffer.
         * @function decode
         * @memberof news.Result
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {news.Result} Result
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Result.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.news.Result();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.code = reader.int32();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Verifies a Result message.
         * @function verify
         * @memberof news.Result
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Result.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                switch (message.code) {
                default:
                    return "code: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };

        /**
         * Creates a Result message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof news.Result
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {news.Result} Result
         */
        Result.fromObject = function fromObject(object) {
            if (object instanceof $root.news.Result)
                return object;
            var message = new $root.news.Result();
            switch (object.code) {
            case "SUCCESS":
            case 0:
                message.code = 0;
                break;
            case "INVALID_MESSAGE":
            case 1:
                message.code = 1;
                break;
            case "COMMON_ERROR":
            case 2:
                message.code = 2;
                break;
            }
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };

        /**
         * Creates a plain object from a Result message. Also converts values to other types if specified.
         * @function toObject
         * @memberof news.Result
         * @static
         * @param {news.Result} message Result
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Result.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.code = options.enums === String ? "SUCCESS" : 0;
                object.description = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = options.enums === String ? $root.news.Result.Code[message.code] : message.code;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this Result to JSON.
         * @function toJSON
         * @memberof news.Result
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Result.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Code enum.
         * @name news.Result.Code
         * @enum {number}
         * @property {number} SUCCESS=0 SUCCESS value
         * @property {number} INVALID_MESSAGE=1 INVALID_MESSAGE value
         * @property {number} COMMON_ERROR=2 COMMON_ERROR value
         */
        Result.Code = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SUCCESS"] = 0;
            values[valuesById[1] = "INVALID_MESSAGE"] = 1;
            values[valuesById[2] = "COMMON_ERROR"] = 2;
            return values;
        })();

        return Result;
    })();

    news.NewsSubscribe = (function() {

        /**
         * Properties of a NewsSubscribe.
         * @memberof news
         * @interface INewsSubscribe
         * @property {Array.<string>|null} [npName] NewsSubscribe npName
         * @property {Array.<string>|null} [category] NewsSubscribe category
         */

        /**
         * Constructs a new NewsSubscribe.
         * @memberof news
         * @classdesc Represents a NewsSubscribe.
         * @implements INewsSubscribe
         * @constructor
         * @param {news.INewsSubscribe=} [properties] Properties to set
         */
        function NewsSubscribe(properties) {
            this.npName = [];
            this.category = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NewsSubscribe npName.
         * @member {Array.<string>} npName
         * @memberof news.NewsSubscribe
         * @instance
         */
        NewsSubscribe.prototype.npName = $util.emptyArray;

        /**
         * NewsSubscribe category.
         * @member {Array.<string>} category
         * @memberof news.NewsSubscribe
         * @instance
         */
        NewsSubscribe.prototype.category = $util.emptyArray;

        /**
         * Creates a new NewsSubscribe instance using the specified properties.
         * @function create
         * @memberof news.NewsSubscribe
         * @static
         * @param {news.INewsSubscribe=} [properties] Properties to set
         * @returns {news.NewsSubscribe} NewsSubscribe instance
         */
        NewsSubscribe.create = function create(properties) {
            return new NewsSubscribe(properties);
        };

        /**
         * Encodes the specified NewsSubscribe message. Does not implicitly {@link news.NewsSubscribe.verify|verify} messages.
         * @function encode
         * @memberof news.NewsSubscribe
         * @static
         * @param {news.INewsSubscribe} message NewsSubscribe message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NewsSubscribe.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.npName != null && message.npName.length)
                for (var i = 0; i < message.npName.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.npName[i]);
            if (message.category != null && message.category.length)
                for (var i = 0; i < message.category.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.category[i]);
            return writer;
        };

        /**
         * Decodes a NewsSubscribe message from the specified reader or buffer.
         * @function decode
         * @memberof news.NewsSubscribe
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {news.NewsSubscribe} NewsSubscribe
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NewsSubscribe.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.news.NewsSubscribe();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.npName && message.npName.length))
                        message.npName = [];
                    message.npName.push(reader.string());
                    break;
                case 2:
                    if (!(message.category && message.category.length))
                        message.category = [];
                    message.category.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Verifies a NewsSubscribe message.
         * @function verify
         * @memberof news.NewsSubscribe
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NewsSubscribe.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.npName != null && message.hasOwnProperty("npName")) {
                if (!Array.isArray(message.npName))
                    return "npName: array expected";
                for (var i = 0; i < message.npName.length; ++i)
                    if (!$util.isString(message.npName[i]))
                        return "npName: string[] expected";
            }
            if (message.category != null && message.hasOwnProperty("category")) {
                if (!Array.isArray(message.category))
                    return "category: array expected";
                for (var i = 0; i < message.category.length; ++i)
                    if (!$util.isString(message.category[i]))
                        return "category: string[] expected";
            }
            return null;
        };

        /**
         * Creates a NewsSubscribe message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof news.NewsSubscribe
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {news.NewsSubscribe} NewsSubscribe
         */
        NewsSubscribe.fromObject = function fromObject(object) {
            if (object instanceof $root.news.NewsSubscribe)
                return object;
            var message = new $root.news.NewsSubscribe();
            if (object.npName) {
                if (!Array.isArray(object.npName))
                    throw TypeError(".news.NewsSubscribe.npName: array expected");
                message.npName = [];
                for (var i = 0; i < object.npName.length; ++i)
                    message.npName[i] = String(object.npName[i]);
            }
            if (object.category) {
                if (!Array.isArray(object.category))
                    throw TypeError(".news.NewsSubscribe.category: array expected");
                message.category = [];
                for (var i = 0; i < object.category.length; ++i)
                    message.category[i] = String(object.category[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a NewsSubscribe message. Also converts values to other types if specified.
         * @function toObject
         * @memberof news.NewsSubscribe
         * @static
         * @param {news.NewsSubscribe} message NewsSubscribe
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NewsSubscribe.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.npName = [];
                object.category = [];
            }
            if (message.npName && message.npName.length) {
                object.npName = [];
                for (var j = 0; j < message.npName.length; ++j)
                    object.npName[j] = message.npName[j];
            }
            if (message.category && message.category.length) {
                object.category = [];
                for (var j = 0; j < message.category.length; ++j)
                    object.category[j] = message.category[j];
            }
            return object;
        };

        /**
         * Converts this NewsSubscribe to JSON.
         * @function toJSON
         * @memberof news.NewsSubscribe
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NewsSubscribe.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NewsSubscribe;
    })();

    news.NewsRequest = (function() {

        /**
         * Properties of a NewsRequest.
         * @memberof news
         * @interface INewsRequest
         * @property {number|Long|null} [offset] NewsRequest offset
         * @property {number|Long|null} [limit] NewsRequest limit
         * @property {news.IDateTime|null} [timestampFrom] NewsRequest timestampFrom
         * @property {news.IDateTime|null} [timestampTo] NewsRequest timestampTo
         * @property {Array.<string>|null} [npName] NewsRequest npName
         * @property {Array.<string>|null} [category] NewsRequest category
         * @property {Array.<string>|null} [ticker] NewsRequest ticker
         * @property {Array.<string>|null} [tag] NewsRequest tag
         * @property {boolean|null} [withoutBody] NewsRequest withoutBody
         */

        /**
         * Constructs a new NewsRequest.
         * @memberof news
         * @classdesc Represents a NewsRequest.
         * @implements INewsRequest
         * @constructor
         * @param {news.INewsRequest=} [properties] Properties to set
         */
        function NewsRequest(properties) {
            this.npName = [];
            this.category = [];
            this.ticker = [];
            this.tag = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NewsRequest offset.
         * @member {number|Long} offset
         * @memberof news.NewsRequest
         * @instance
         */
        NewsRequest.prototype.offset = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * NewsRequest limit.
         * @member {number|Long} limit
         * @memberof news.NewsRequest
         * @instance
         */
        NewsRequest.prototype.limit = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * NewsRequest timestampFrom.
         * @member {news.IDateTime|null|undefined} timestampFrom
         * @memberof news.NewsRequest
         * @instance
         */
        NewsRequest.prototype.timestampFrom = null;

        /**
         * NewsRequest timestampTo.
         * @member {news.IDateTime|null|undefined} timestampTo
         * @memberof news.NewsRequest
         * @instance
         */
        NewsRequest.prototype.timestampTo = null;

        /**
         * NewsRequest npName.
         * @member {Array.<string>} npName
         * @memberof news.NewsRequest
         * @instance
         */
        NewsRequest.prototype.npName = $util.emptyArray;

        /**
         * NewsRequest category.
         * @member {Array.<string>} category
         * @memberof news.NewsRequest
         * @instance
         */
        NewsRequest.prototype.category = $util.emptyArray;

        /**
         * NewsRequest ticker.
         * @member {Array.<string>} ticker
         * @memberof news.NewsRequest
         * @instance
         */
        NewsRequest.prototype.ticker = $util.emptyArray;

        /**
         * NewsRequest tag.
         * @member {Array.<string>} tag
         * @memberof news.NewsRequest
         * @instance
         */
        NewsRequest.prototype.tag = $util.emptyArray;

        /**
         * NewsRequest withoutBody.
         * @member {boolean} withoutBody
         * @memberof news.NewsRequest
         * @instance
         */
        NewsRequest.prototype.withoutBody = false;

        /**
         * Creates a new NewsRequest instance using the specified properties.
         * @function create
         * @memberof news.NewsRequest
         * @static
         * @param {news.INewsRequest=} [properties] Properties to set
         * @returns {news.NewsRequest} NewsRequest instance
         */
        NewsRequest.create = function create(properties) {
            return new NewsRequest(properties);
        };

        /**
         * Encodes the specified NewsRequest message. Does not implicitly {@link news.NewsRequest.verify|verify} messages.
         * @function encode
         * @memberof news.NewsRequest
         * @static
         * @param {news.INewsRequest} message NewsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NewsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.offset);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.limit);
            if (message.timestampFrom != null && Object.hasOwnProperty.call(message, "timestampFrom"))
                $root.news.DateTime.encode(message.timestampFrom, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.timestampTo != null && Object.hasOwnProperty.call(message, "timestampTo"))
                $root.news.DateTime.encode(message.timestampTo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.npName != null && message.npName.length)
                for (var i = 0; i < message.npName.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.npName[i]);
            if (message.category != null && message.category.length)
                for (var i = 0; i < message.category.length; ++i)
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.category[i]);
            if (message.ticker != null && message.ticker.length)
                for (var i = 0; i < message.ticker.length; ++i)
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.ticker[i]);
            if (message.tag != null && message.tag.length)
                for (var i = 0; i < message.tag.length; ++i)
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.tag[i]);
            if (message.withoutBody != null && Object.hasOwnProperty.call(message, "withoutBody"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.withoutBody);
            return writer;
        };

        /**
         * Decodes a NewsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof news.NewsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {news.NewsRequest} NewsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NewsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.news.NewsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.offset = reader.uint64();
                    break;
                case 2:
                    message.limit = reader.uint64();
                    break;
                case 3:
                    message.timestampFrom = $root.news.DateTime.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.timestampTo = $root.news.DateTime.decode(reader, reader.uint32());
                    break;
                case 5:
                    if (!(message.npName && message.npName.length))
                        message.npName = [];
                    message.npName.push(reader.string());
                    break;
                case 6:
                    if (!(message.category && message.category.length))
                        message.category = [];
                    message.category.push(reader.string());
                    break;
                case 7:
                    if (!(message.ticker && message.ticker.length))
                        message.ticker = [];
                    message.ticker.push(reader.string());
                    break;
                case 8:
                    if (!(message.tag && message.tag.length))
                        message.tag = [];
                    message.tag.push(reader.string());
                    break;
                case 9:
                    message.withoutBody = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Verifies a NewsRequest message.
         * @function verify
         * @memberof news.NewsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NewsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset) && !(message.offset && $util.isInteger(message.offset.low) && $util.isInteger(message.offset.high)))
                    return "offset: integer|Long expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit) && !(message.limit && $util.isInteger(message.limit.low) && $util.isInteger(message.limit.high)))
                    return "limit: integer|Long expected";
            if (message.timestampFrom != null && message.hasOwnProperty("timestampFrom")) {
                var error = $root.news.DateTime.verify(message.timestampFrom);
                if (error)
                    return "timestampFrom." + error;
            }
            if (message.timestampTo != null && message.hasOwnProperty("timestampTo")) {
                var error = $root.news.DateTime.verify(message.timestampTo);
                if (error)
                    return "timestampTo." + error;
            }
            if (message.npName != null && message.hasOwnProperty("npName")) {
                if (!Array.isArray(message.npName))
                    return "npName: array expected";
                for (var i = 0; i < message.npName.length; ++i)
                    if (!$util.isString(message.npName[i]))
                        return "npName: string[] expected";
            }
            if (message.category != null && message.hasOwnProperty("category")) {
                if (!Array.isArray(message.category))
                    return "category: array expected";
                for (var i = 0; i < message.category.length; ++i)
                    if (!$util.isString(message.category[i]))
                        return "category: string[] expected";
            }
            if (message.ticker != null && message.hasOwnProperty("ticker")) {
                if (!Array.isArray(message.ticker))
                    return "ticker: array expected";
                for (var i = 0; i < message.ticker.length; ++i)
                    if (!$util.isString(message.ticker[i]))
                        return "ticker: string[] expected";
            }
            if (message.tag != null && message.hasOwnProperty("tag")) {
                if (!Array.isArray(message.tag))
                    return "tag: array expected";
                for (var i = 0; i < message.tag.length; ++i)
                    if (!$util.isString(message.tag[i]))
                        return "tag: string[] expected";
            }
            if (message.withoutBody != null && message.hasOwnProperty("withoutBody"))
                if (typeof message.withoutBody !== "boolean")
                    return "withoutBody: boolean expected";
            return null;
        };

        /**
         * Creates a NewsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof news.NewsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {news.NewsRequest} NewsRequest
         */
        NewsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.news.NewsRequest)
                return object;
            var message = new $root.news.NewsRequest();
            if (object.offset != null)
                if ($util.Long)
                    (message.offset = $util.Long.fromValue(object.offset)).unsigned = true;
                else if (typeof object.offset === "string")
                    message.offset = parseInt(object.offset, 10);
                else if (typeof object.offset === "number")
                    message.offset = object.offset;
                else if (typeof object.offset === "object")
                    message.offset = new $util.LongBits(object.offset.low >>> 0, object.offset.high >>> 0).toNumber(true);
            if (object.limit != null)
                if ($util.Long)
                    (message.limit = $util.Long.fromValue(object.limit)).unsigned = true;
                else if (typeof object.limit === "string")
                    message.limit = parseInt(object.limit, 10);
                else if (typeof object.limit === "number")
                    message.limit = object.limit;
                else if (typeof object.limit === "object")
                    message.limit = new $util.LongBits(object.limit.low >>> 0, object.limit.high >>> 0).toNumber(true);
            if (object.timestampFrom != null) {
                if (typeof object.timestampFrom !== "object")
                    throw TypeError(".news.NewsRequest.timestampFrom: object expected");
                message.timestampFrom = $root.news.DateTime.fromObject(object.timestampFrom);
            }
            if (object.timestampTo != null) {
                if (typeof object.timestampTo !== "object")
                    throw TypeError(".news.NewsRequest.timestampTo: object expected");
                message.timestampTo = $root.news.DateTime.fromObject(object.timestampTo);
            }
            if (object.npName) {
                if (!Array.isArray(object.npName))
                    throw TypeError(".news.NewsRequest.npName: array expected");
                message.npName = [];
                for (var i = 0; i < object.npName.length; ++i)
                    message.npName[i] = String(object.npName[i]);
            }
            if (object.category) {
                if (!Array.isArray(object.category))
                    throw TypeError(".news.NewsRequest.category: array expected");
                message.category = [];
                for (var i = 0; i < object.category.length; ++i)
                    message.category[i] = String(object.category[i]);
            }
            if (object.ticker) {
                if (!Array.isArray(object.ticker))
                    throw TypeError(".news.NewsRequest.ticker: array expected");
                message.ticker = [];
                for (var i = 0; i < object.ticker.length; ++i)
                    message.ticker[i] = String(object.ticker[i]);
            }
            if (object.tag) {
                if (!Array.isArray(object.tag))
                    throw TypeError(".news.NewsRequest.tag: array expected");
                message.tag = [];
                for (var i = 0; i < object.tag.length; ++i)
                    message.tag[i] = String(object.tag[i]);
            }
            if (object.withoutBody != null)
                message.withoutBody = Boolean(object.withoutBody);
            return message;
        };

        /**
         * Creates a plain object from a NewsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof news.NewsRequest
         * @static
         * @param {news.NewsRequest} message NewsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NewsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.npName = [];
                object.category = [];
                object.ticker = [];
                object.tag = [];
            }
            if (options.defaults) {
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.offset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.offset = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.limit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.limit = options.longs === String ? "0" : 0;
                object.timestampFrom = null;
                object.timestampTo = null;
                object.withoutBody = false;
            }
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (typeof message.offset === "number")
                    object.offset = options.longs === String ? String(message.offset) : message.offset;
                else
                    object.offset = options.longs === String ? $util.Long.prototype.toString.call(message.offset) : options.longs === Number ? new $util.LongBits(message.offset.low >>> 0, message.offset.high >>> 0).toNumber(true) : message.offset;
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (typeof message.limit === "number")
                    object.limit = options.longs === String ? String(message.limit) : message.limit;
                else
                    object.limit = options.longs === String ? $util.Long.prototype.toString.call(message.limit) : options.longs === Number ? new $util.LongBits(message.limit.low >>> 0, message.limit.high >>> 0).toNumber(true) : message.limit;
            if (message.timestampFrom != null && message.hasOwnProperty("timestampFrom"))
                object.timestampFrom = $root.news.DateTime.toObject(message.timestampFrom, options);
            if (message.timestampTo != null && message.hasOwnProperty("timestampTo"))
                object.timestampTo = $root.news.DateTime.toObject(message.timestampTo, options);
            if (message.npName && message.npName.length) {
                object.npName = [];
                for (var j = 0; j < message.npName.length; ++j)
                    object.npName[j] = message.npName[j];
            }
            if (message.category && message.category.length) {
                object.category = [];
                for (var j = 0; j < message.category.length; ++j)
                    object.category[j] = message.category[j];
            }
            if (message.ticker && message.ticker.length) {
                object.ticker = [];
                for (var j = 0; j < message.ticker.length; ++j)
                    object.ticker[j] = message.ticker[j];
            }
            if (message.tag && message.tag.length) {
                object.tag = [];
                for (var j = 0; j < message.tag.length; ++j)
                    object.tag[j] = message.tag[j];
            }
            if (message.withoutBody != null && message.hasOwnProperty("withoutBody"))
                object.withoutBody = message.withoutBody;
            return object;
        };

        /**
         * Converts this NewsRequest to JSON.
         * @function toJSON
         * @memberof news.NewsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NewsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NewsRequest;
    })();

    news.GetNews = (function() {

        /**
         * Properties of a GetNews.
         * @memberof news
         * @interface IGetNews
         * @property {number|Long|null} [id] GetNews id
         */

        /**
         * Constructs a new GetNews.
         * @memberof news
         * @classdesc Represents a GetNews.
         * @implements IGetNews
         * @constructor
         * @param {news.IGetNews=} [properties] Properties to set
         */
        function GetNews(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetNews id.
         * @member {number|Long} id
         * @memberof news.GetNews
         * @instance
         */
        GetNews.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new GetNews instance using the specified properties.
         * @function create
         * @memberof news.GetNews
         * @static
         * @param {news.IGetNews=} [properties] Properties to set
         * @returns {news.GetNews} GetNews instance
         */
        GetNews.create = function create(properties) {
            return new GetNews(properties);
        };

        /**
         * Encodes the specified GetNews message. Does not implicitly {@link news.GetNews.verify|verify} messages.
         * @function encode
         * @memberof news.GetNews
         * @static
         * @param {news.IGetNews} message GetNews message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetNews.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
            return writer;
        };

        /**
         * Decodes a GetNews message from the specified reader or buffer.
         * @function decode
         * @memberof news.GetNews
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {news.GetNews} GetNews
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetNews.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.news.GetNews();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Verifies a GetNews message.
         * @function verify
         * @memberof news.GetNews
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetNews.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            return null;
        };

        /**
         * Creates a GetNews message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof news.GetNews
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {news.GetNews} GetNews
         */
        GetNews.fromObject = function fromObject(object) {
            if (object instanceof $root.news.GetNews)
                return object;
            var message = new $root.news.GetNews();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a GetNews message. Also converts values to other types if specified.
         * @function toObject
         * @memberof news.GetNews
         * @static
         * @param {news.GetNews} message GetNews
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetNews.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
            return object;
        };

        /**
         * Converts this GetNews to JSON.
         * @function toJSON
         * @memberof news.GetNews
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetNews.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetNews;
    })();

    news.Subscribe = (function() {

        /**
         * Properties of a Subscribe.
         * @memberof news
         * @interface ISubscribe
         * @property {number|Long|null} [subscriptionId] Subscribe subscriptionId
         * @property {news.INewsSubscribe|null} [newsSubscribe] Subscribe newsSubscribe
         */

        /**
         * Constructs a new Subscribe.
         * @memberof news
         * @classdesc Represents a Subscribe.
         * @implements ISubscribe
         * @constructor
         * @param {news.ISubscribe=} [properties] Properties to set
         */
        function Subscribe(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Subscribe subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof news.Subscribe
         * @instance
         */
        Subscribe.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Subscribe newsSubscribe.
         * @member {news.INewsSubscribe|null|undefined} newsSubscribe
         * @memberof news.Subscribe
         * @instance
         */
        Subscribe.prototype.newsSubscribe = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Subscribe subscribtion.
         * @member {"newsSubscribe"|undefined} subscribtion
         * @memberof news.Subscribe
         * @instance
         */
        Object.defineProperty(Subscribe.prototype, "subscribtion", {
            get: $util.oneOfGetter($oneOfFields = ["newsSubscribe"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Subscribe instance using the specified properties.
         * @function create
         * @memberof news.Subscribe
         * @static
         * @param {news.ISubscribe=} [properties] Properties to set
         * @returns {news.Subscribe} Subscribe instance
         */
        Subscribe.create = function create(properties) {
            return new Subscribe(properties);
        };

        /**
         * Encodes the specified Subscribe message. Does not implicitly {@link news.Subscribe.verify|verify} messages.
         * @function encode
         * @memberof news.Subscribe
         * @static
         * @param {news.ISubscribe} message Subscribe message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Subscribe.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.subscriptionId != null && Object.hasOwnProperty.call(message, "subscriptionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subscriptionId);
            if (message.newsSubscribe != null && Object.hasOwnProperty.call(message, "newsSubscribe"))
                $root.news.NewsSubscribe.encode(message.newsSubscribe, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Subscribe message from the specified reader or buffer.
         * @function decode
         * @memberof news.Subscribe
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {news.Subscribe} Subscribe
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Subscribe.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.news.Subscribe();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.subscriptionId = reader.uint64();
                    break;
                case 2:
                    message.newsSubscribe = $root.news.NewsSubscribe.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Verifies a Subscribe message.
         * @function verify
         * @memberof news.Subscribe
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Subscribe.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                    return "subscriptionId: integer|Long expected";
            if (message.newsSubscribe != null && message.hasOwnProperty("newsSubscribe")) {
                properties.subscribtion = 1;
                {
                    var error = $root.news.NewsSubscribe.verify(message.newsSubscribe);
                    if (error)
                        return "newsSubscribe." + error;
                }
            }
            return null;
        };

        /**
         * Creates a Subscribe message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof news.Subscribe
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {news.Subscribe} Subscribe
         */
        Subscribe.fromObject = function fromObject(object) {
            if (object instanceof $root.news.Subscribe)
                return object;
            var message = new $root.news.Subscribe();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = true;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber(true);
            if (object.newsSubscribe != null) {
                if (typeof object.newsSubscribe !== "object")
                    throw TypeError(".news.Subscribe.newsSubscribe: object expected");
                message.newsSubscribe = $root.news.NewsSubscribe.fromObject(object.newsSubscribe);
            }
            return message;
        };

        /**
         * Creates a plain object from a Subscribe message. Also converts values to other types if specified.
         * @function toObject
         * @memberof news.Subscribe
         * @static
         * @param {news.Subscribe} message Subscribe
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Subscribe.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber(true) : message.subscriptionId;
            if (message.newsSubscribe != null && message.hasOwnProperty("newsSubscribe")) {
                object.newsSubscribe = $root.news.NewsSubscribe.toObject(message.newsSubscribe, options);
                if (options.oneofs)
                    object.subscribtion = "newsSubscribe";
            }
            return object;
        };

        /**
         * Converts this Subscribe to JSON.
         * @function toJSON
         * @memberof news.Subscribe
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Subscribe.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Subscribe;
    })();

    news.Unsubscribe = (function() {

        /**
         * Properties of an Unsubscribe.
         * @memberof news
         * @interface IUnsubscribe
         * @property {number|Long|null} [subscriptionId] Unsubscribe subscriptionId
         */

        /**
         * Constructs a new Unsubscribe.
         * @memberof news
         * @classdesc Represents an Unsubscribe.
         * @implements IUnsubscribe
         * @constructor
         * @param {news.IUnsubscribe=} [properties] Properties to set
         */
        function Unsubscribe(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Unsubscribe subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof news.Unsubscribe
         * @instance
         */
        Unsubscribe.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * Creates a new Unsubscribe instance using the specified properties.
         * @function create
         * @memberof news.Unsubscribe
         * @static
         * @param {news.IUnsubscribe=} [properties] Properties to set
         * @returns {news.Unsubscribe} Unsubscribe instance
         */
        Unsubscribe.create = function create(properties) {
            return new Unsubscribe(properties);
        };

        /**
         * Encodes the specified Unsubscribe message. Does not implicitly {@link news.Unsubscribe.verify|verify} messages.
         * @function encode
         * @memberof news.Unsubscribe
         * @static
         * @param {news.IUnsubscribe} message Unsubscribe message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Unsubscribe.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.subscriptionId != null && Object.hasOwnProperty.call(message, "subscriptionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subscriptionId);
            return writer;
        };

        /**
         * Decodes an Unsubscribe message from the specified reader or buffer.
         * @function decode
         * @memberof news.Unsubscribe
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {news.Unsubscribe} Unsubscribe
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Unsubscribe.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.news.Unsubscribe();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.subscriptionId = reader.uint64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Verifies an Unsubscribe message.
         * @function verify
         * @memberof news.Unsubscribe
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Unsubscribe.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                    return "subscriptionId: integer|Long expected";
            return null;
        };

        /**
         * Creates an Unsubscribe message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof news.Unsubscribe
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {news.Unsubscribe} Unsubscribe
         */
        Unsubscribe.fromObject = function fromObject(object) {
            if (object instanceof $root.news.Unsubscribe)
                return object;
            var message = new $root.news.Unsubscribe();
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = true;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber(true);
            return message;
        };

        /**
         * Creates a plain object from an Unsubscribe message. Also converts values to other types if specified.
         * @function toObject
         * @memberof news.Unsubscribe
         * @static
         * @param {news.Unsubscribe} message Unsubscribe
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Unsubscribe.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.subscriptionId = options.longs === String ? "0" : 0;
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber(true) : message.subscriptionId;
            return object;
        };

        /**
         * Converts this Unsubscribe to JSON.
         * @function toJSON
         * @memberof news.Unsubscribe
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Unsubscribe.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Unsubscribe;
    })();

    news.ClientRequest = (function() {

        /**
         * Properties of a ClientRequest.
         * @memberof news
         * @interface IClientRequest
         * @property {number|Long|null} [id] ClientRequest id
         * @property {news.ISubscribe|null} [subscribe] ClientRequest subscribe
         * @property {news.IUnsubscribe|null} [unsubscribe] ClientRequest unsubscribe
         * @property {news.INewsRequest|null} [newsRequest] ClientRequest newsRequest
         * @property {news.IGetNews|null} [getNews] ClientRequest getNews
         */

        /**
         * Constructs a new ClientRequest.
         * @memberof news
         * @classdesc Represents a ClientRequest.
         * @implements IClientRequest
         * @constructor
         * @param {news.IClientRequest=} [properties] Properties to set
         */
        function ClientRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ClientRequest id.
         * @member {number|Long} id
         * @memberof news.ClientRequest
         * @instance
         */
        ClientRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ClientRequest subscribe.
         * @member {news.ISubscribe|null|undefined} subscribe
         * @memberof news.ClientRequest
         * @instance
         */
        ClientRequest.prototype.subscribe = null;

        /**
         * ClientRequest unsubscribe.
         * @member {news.IUnsubscribe|null|undefined} unsubscribe
         * @memberof news.ClientRequest
         * @instance
         */
        ClientRequest.prototype.unsubscribe = null;

        /**
         * ClientRequest newsRequest.
         * @member {news.INewsRequest|null|undefined} newsRequest
         * @memberof news.ClientRequest
         * @instance
         */
        ClientRequest.prototype.newsRequest = null;

        /**
         * ClientRequest getNews.
         * @member {news.IGetNews|null|undefined} getNews
         * @memberof news.ClientRequest
         * @instance
         */
        ClientRequest.prototype.getNews = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ClientRequest request.
         * @member {"subscribe"|"unsubscribe"|"newsRequest"|"getNews"|undefined} request
         * @memberof news.ClientRequest
         * @instance
         */
        Object.defineProperty(ClientRequest.prototype, "request", {
            get: $util.oneOfGetter($oneOfFields = ["subscribe", "unsubscribe", "newsRequest", "getNews"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ClientRequest instance using the specified properties.
         * @function create
         * @memberof news.ClientRequest
         * @static
         * @param {news.IClientRequest=} [properties] Properties to set
         * @returns {news.ClientRequest} ClientRequest instance
         */
        ClientRequest.create = function create(properties) {
            return new ClientRequest(properties);
        };

        /**
         * Encodes the specified ClientRequest message. Does not implicitly {@link news.ClientRequest.verify|verify} messages.
         * @function encode
         * @memberof news.ClientRequest
         * @static
         * @param {news.IClientRequest} message ClientRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ClientRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
            if (message.subscribe != null && Object.hasOwnProperty.call(message, "subscribe"))
                $root.news.Subscribe.encode(message.subscribe, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.unsubscribe != null && Object.hasOwnProperty.call(message, "unsubscribe"))
                $root.news.Unsubscribe.encode(message.unsubscribe, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.newsRequest != null && Object.hasOwnProperty.call(message, "newsRequest"))
                $root.news.NewsRequest.encode(message.newsRequest, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.getNews != null && Object.hasOwnProperty.call(message, "getNews"))
                $root.news.GetNews.encode(message.getNews, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ClientRequest message from the specified reader or buffer.
         * @function decode
         * @memberof news.ClientRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {news.ClientRequest} ClientRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ClientRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.news.ClientRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.uint64();
                    break;
                case 10:
                    message.subscribe = $root.news.Subscribe.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.unsubscribe = $root.news.Unsubscribe.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.newsRequest = $root.news.NewsRequest.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.getNews = $root.news.GetNews.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Verifies a ClientRequest message.
         * @function verify
         * @memberof news.ClientRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ClientRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                    return "id: integer|Long expected";
            if (message.subscribe != null && message.hasOwnProperty("subscribe")) {
                properties.request = 1;
                {
                    var error = $root.news.Subscribe.verify(message.subscribe);
                    if (error)
                        return "subscribe." + error;
                }
            }
            if (message.unsubscribe != null && message.hasOwnProperty("unsubscribe")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    var error = $root.news.Unsubscribe.verify(message.unsubscribe);
                    if (error)
                        return "unsubscribe." + error;
                }
            }
            if (message.newsRequest != null && message.hasOwnProperty("newsRequest")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    var error = $root.news.NewsRequest.verify(message.newsRequest);
                    if (error)
                        return "newsRequest." + error;
                }
            }
            if (message.getNews != null && message.hasOwnProperty("getNews")) {
                if (properties.request === 1)
                    return "request: multiple values";
                properties.request = 1;
                {
                    var error = $root.news.GetNews.verify(message.getNews);
                    if (error)
                        return "getNews." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ClientRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof news.ClientRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {news.ClientRequest} ClientRequest
         */
        ClientRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.news.ClientRequest)
                return object;
            var message = new $root.news.ClientRequest();
            if (object.id != null)
                if ($util.Long)
                    (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                else if (typeof object.id === "string")
                    message.id = parseInt(object.id, 10);
                else if (typeof object.id === "number")
                    message.id = object.id;
                else if (typeof object.id === "object")
                    message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
            if (object.subscribe != null) {
                if (typeof object.subscribe !== "object")
                    throw TypeError(".news.ClientRequest.subscribe: object expected");
                message.subscribe = $root.news.Subscribe.fromObject(object.subscribe);
            }
            if (object.unsubscribe != null) {
                if (typeof object.unsubscribe !== "object")
                    throw TypeError(".news.ClientRequest.unsubscribe: object expected");
                message.unsubscribe = $root.news.Unsubscribe.fromObject(object.unsubscribe);
            }
            if (object.newsRequest != null) {
                if (typeof object.newsRequest !== "object")
                    throw TypeError(".news.ClientRequest.newsRequest: object expected");
                message.newsRequest = $root.news.NewsRequest.fromObject(object.newsRequest);
            }
            if (object.getNews != null) {
                if (typeof object.getNews !== "object")
                    throw TypeError(".news.ClientRequest.getNews: object expected");
                message.getNews = $root.news.GetNews.fromObject(object.getNews);
            }
            return message;
        };

        /**
         * Creates a plain object from a ClientRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof news.ClientRequest
         * @static
         * @param {news.ClientRequest} message ClientRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ClientRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if ($util.Long) {
                    var long = new $util.Long(0, 0, true);
                    object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.id = options.longs === String ? "0" : 0;
            if (message.id != null && message.hasOwnProperty("id"))
                if (typeof message.id === "number")
                    object.id = options.longs === String ? String(message.id) : message.id;
                else
                    object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
            if (message.subscribe != null && message.hasOwnProperty("subscribe")) {
                object.subscribe = $root.news.Subscribe.toObject(message.subscribe, options);
                if (options.oneofs)
                    object.request = "subscribe";
            }
            if (message.unsubscribe != null && message.hasOwnProperty("unsubscribe")) {
                object.unsubscribe = $root.news.Unsubscribe.toObject(message.unsubscribe, options);
                if (options.oneofs)
                    object.request = "unsubscribe";
            }
            if (message.newsRequest != null && message.hasOwnProperty("newsRequest")) {
                object.newsRequest = $root.news.NewsRequest.toObject(message.newsRequest, options);
                if (options.oneofs)
                    object.request = "newsRequest";
            }
            if (message.getNews != null && message.hasOwnProperty("getNews")) {
                object.getNews = $root.news.GetNews.toObject(message.getNews, options);
                if (options.oneofs)
                    object.request = "getNews";
            }
            return object;
        };

        /**
         * Converts this ClientRequest to JSON.
         * @function toJSON
         * @memberof news.ClientRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ClientRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ClientRequest;
    })();

    news.ServerResponse = (function() {

        /**
         * Properties of a ServerResponse.
         * @memberof news
         * @interface IServerResponse
         * @property {number|Long|null} [requestId] ServerResponse requestId
         * @property {number|Long|null} [subscriptionId] ServerResponse subscriptionId
         * @property {news.IResult|null} [result] ServerResponse result
         * @property {news.INews|null} [news] ServerResponse news
         * @property {news.INewsList|null} [newsList] ServerResponse newsList
         */

        /**
         * Constructs a new ServerResponse.
         * @memberof news
         * @classdesc Represents a ServerResponse.
         * @implements IServerResponse
         * @constructor
         * @param {news.IServerResponse=} [properties] Properties to set
         */
        function ServerResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServerResponse requestId.
         * @member {number|Long} requestId
         * @memberof news.ServerResponse
         * @instance
         */
        ServerResponse.prototype.requestId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ServerResponse subscriptionId.
         * @member {number|Long} subscriptionId
         * @memberof news.ServerResponse
         * @instance
         */
        ServerResponse.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

        /**
         * ServerResponse result.
         * @member {news.IResult|null|undefined} result
         * @memberof news.ServerResponse
         * @instance
         */
        ServerResponse.prototype.result = null;

        /**
         * ServerResponse news.
         * @member {news.INews|null|undefined} news
         * @memberof news.ServerResponse
         * @instance
         */
        ServerResponse.prototype.news = null;

        /**
         * ServerResponse newsList.
         * @member {news.INewsList|null|undefined} newsList
         * @memberof news.ServerResponse
         * @instance
         */
        ServerResponse.prototype.newsList = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ServerResponse responseId.
         * @member {"requestId"|"subscriptionId"|undefined} responseId
         * @memberof news.ServerResponse
         * @instance
         */
        Object.defineProperty(ServerResponse.prototype, "responseId", {
            get: $util.oneOfGetter($oneOfFields = ["requestId", "subscriptionId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * ServerResponse response.
         * @member {"result"|"news"|"newsList"|undefined} response
         * @memberof news.ServerResponse
         * @instance
         */
        Object.defineProperty(ServerResponse.prototype, "response", {
            get: $util.oneOfGetter($oneOfFields = ["result", "news", "newsList"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ServerResponse instance using the specified properties.
         * @function create
         * @memberof news.ServerResponse
         * @static
         * @param {news.IServerResponse=} [properties] Properties to set
         * @returns {news.ServerResponse} ServerResponse instance
         */
        ServerResponse.create = function create(properties) {
            return new ServerResponse(properties);
        };

        /**
         * Encodes the specified ServerResponse message. Does not implicitly {@link news.ServerResponse.verify|verify} messages.
         * @function encode
         * @memberof news.ServerResponse
         * @static
         * @param {news.IServerResponse} message ServerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.requestId);
            if (message.subscriptionId != null && Object.hasOwnProperty.call(message, "subscriptionId"))
                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.subscriptionId);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                $root.news.Result.encode(message.result, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.news != null && Object.hasOwnProperty.call(message, "news"))
                $root.news.News.encode(message.news, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.newsList != null && Object.hasOwnProperty.call(message, "newsList"))
                $root.news.NewsList.encode(message.newsList, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ServerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof news.ServerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {news.ServerResponse} ServerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.news.ServerResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requestId = reader.uint64();
                    break;
                case 2:
                    message.subscriptionId = reader.uint64();
                    break;
                case 10:
                    message.result = $root.news.Result.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.news = $root.news.News.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.newsList = $root.news.NewsList.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Verifies a ServerResponse message.
         * @function verify
         * @memberof news.ServerResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServerResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.requestId != null && message.hasOwnProperty("requestId")) {
                properties.responseId = 1;
                if (!$util.isInteger(message.requestId) && !(message.requestId && $util.isInteger(message.requestId.low) && $util.isInteger(message.requestId.high)))
                    return "requestId: integer|Long expected";
            }
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId")) {
                if (properties.responseId === 1)
                    return "responseId: multiple values";
                properties.responseId = 1;
                if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                    return "subscriptionId: integer|Long expected";
            }
            if (message.result != null && message.hasOwnProperty("result")) {
                properties.response = 1;
                {
                    var error = $root.news.Result.verify(message.result);
                    if (error)
                        return "result." + error;
                }
            }
            if (message.news != null && message.hasOwnProperty("news")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    var error = $root.news.News.verify(message.news);
                    if (error)
                        return "news." + error;
                }
            }
            if (message.newsList != null && message.hasOwnProperty("newsList")) {
                if (properties.response === 1)
                    return "response: multiple values";
                properties.response = 1;
                {
                    var error = $root.news.NewsList.verify(message.newsList);
                    if (error)
                        return "newsList." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ServerResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof news.ServerResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {news.ServerResponse} ServerResponse
         */
        ServerResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.news.ServerResponse)
                return object;
            var message = new $root.news.ServerResponse();
            if (object.requestId != null)
                if ($util.Long)
                    (message.requestId = $util.Long.fromValue(object.requestId)).unsigned = true;
                else if (typeof object.requestId === "string")
                    message.requestId = parseInt(object.requestId, 10);
                else if (typeof object.requestId === "number")
                    message.requestId = object.requestId;
                else if (typeof object.requestId === "object")
                    message.requestId = new $util.LongBits(object.requestId.low >>> 0, object.requestId.high >>> 0).toNumber(true);
            if (object.subscriptionId != null)
                if ($util.Long)
                    (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = true;
                else if (typeof object.subscriptionId === "string")
                    message.subscriptionId = parseInt(object.subscriptionId, 10);
                else if (typeof object.subscriptionId === "number")
                    message.subscriptionId = object.subscriptionId;
                else if (typeof object.subscriptionId === "object")
                    message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber(true);
            if (object.result != null) {
                if (typeof object.result !== "object")
                    throw TypeError(".news.ServerResponse.result: object expected");
                message.result = $root.news.Result.fromObject(object.result);
            }
            if (object.news != null) {
                if (typeof object.news !== "object")
                    throw TypeError(".news.ServerResponse.news: object expected");
                message.news = $root.news.News.fromObject(object.news);
            }
            if (object.newsList != null) {
                if (typeof object.newsList !== "object")
                    throw TypeError(".news.ServerResponse.newsList: object expected");
                message.newsList = $root.news.NewsList.fromObject(object.newsList);
            }
            return message;
        };

        /**
         * Creates a plain object from a ServerResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof news.ServerResponse
         * @static
         * @param {news.ServerResponse} message ServerResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServerResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (message.requestId != null && message.hasOwnProperty("requestId")) {
                if (typeof message.requestId === "number")
                    object.requestId = options.longs === String ? String(message.requestId) : message.requestId;
                else
                    object.requestId = options.longs === String ? $util.Long.prototype.toString.call(message.requestId) : options.longs === Number ? new $util.LongBits(message.requestId.low >>> 0, message.requestId.high >>> 0).toNumber(true) : message.requestId;
                if (options.oneofs)
                    object.responseId = "requestId";
            }
            if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId")) {
                if (typeof message.subscriptionId === "number")
                    object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                else
                    object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber(true) : message.subscriptionId;
                if (options.oneofs)
                    object.responseId = "subscriptionId";
            }
            if (message.result != null && message.hasOwnProperty("result")) {
                object.result = $root.news.Result.toObject(message.result, options);
                if (options.oneofs)
                    object.response = "result";
            }
            if (message.news != null && message.hasOwnProperty("news")) {
                object.news = $root.news.News.toObject(message.news, options);
                if (options.oneofs)
                    object.response = "news";
            }
            if (message.newsList != null && message.hasOwnProperty("newsList")) {
                object.newsList = $root.news.NewsList.toObject(message.newsList, options);
                if (options.oneofs)
                    object.response = "newsList";
            }
            return object;
        };

        /**
         * Converts this ServerResponse to JSON.
         * @function toJSON
         * @memberof news.ServerResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServerResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ServerResponse;
    })();

    return news;
})();

module.exports = $root;
