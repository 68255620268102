import { put, getContext } from 'redux-saga/effects';
import { get } from 'lodash';
import * as actions from 'Store/actions';
import { NEWS_REQUESTS, SERVICE_NAMES } from 'Constants';

export function* getNews({ payload: id }) {
    try {
        const newsService = yield getContext(SERVICE_NAMES.NEWS);
        yield put(actions.news.getNewsItemPending());
        const response = yield newsService.request(NEWS_REQUESTS.GET_NEWS, { id });
        const responseNews = get(response, 'news', {});

        yield put(actions.news.getNewsItemSuccess(responseNews));
    } catch (err) {
        yield put(actions.news.getNewsItemFailure(err.message));
    }
}
