import { call, takeEvery, takeLatest, fork, take, cancel, delay, getContext } from 'redux-saga/effects';
import { EVENTS, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';
import symbolListSubscription from './symbolList.saga';
import symbolQuoteSubscription from './symbolQuoteSubscription.saga';
import { getSnapshotAndSubscribeToChartBar } from 'Store/sagas/market/chartBar.saga';
import WSEventsToActionsDispatch from 'Store/sagas/utils/WSEventsToActionsDispatch.saga';
import getEventChannelCreator from 'Store/sagas/utils/getEventChannelCreator';
import { serverHealthCheck } from 'Store/sagas/health';
import serverTimePeriodicUpdate from './serverTime.saga';
import logger from 'Utils/logger';

const log = logger('MarketMainSaga:');
const logError = logger('Error:MarketMainSaga:');

const eventsToActionsMap = [
    { event: EVENTS.MARKET.OPEN, actionCreator: actions.market.WSConnectionOpen },
    { event: EVENTS.MARKET.CLOSE, actionCreator: actions.market.WSConnectionClose },
    { event: EVENTS.MARKET.ERROR, actionCreator: actions.market.WSConnectionOnError },
    { event: EVENTS.MARKET.PROBLEM, actionCreator: actions.market.WSConnectionProblem },
    { event: EVENTS.MARKET.NORMAL, actionCreator: actions.market.WSConnectionNormal }
];

export default function* marketSaga() {
    const marketService = yield getContext(SERVICE_NAMES.MARKET);
    const createDefaultEventChannel = getEventChannelCreator(marketService);

    yield fork(WSEventsToActionsDispatch, createDefaultEventChannel, eventsToActionsMap);

    yield takeEvery(actions.setIsAuthenticated, openWSConnection, marketService);
    yield takeLatest(actions.auth.requestLogoutPending, closeWSConnection, marketService);

    yield takeLatest(actions.market.WSConnectionOpen, runMarketTasks, marketService);
    yield takeLatest(actions.market.WSConnectionClose, serverHealthCheck);

    // WRITE HERE DISPATCHING ACTIONS TO HANDLERS
    yield takeLatest(actions.market.subscribeToChartBar, getSnapshotAndSubscribeToChartBar);
}

function* openWSConnection(marketService, { payload }) {
    if (payload !== true) {
        return;
    }

    if (marketService.isOpening || marketService.isOpened) {
        return;
    }

    try {
        yield call([marketService, marketService.connect]);
    } catch (err) {
        logError('runMarketService error: %O', err);
        // TODO add logic to up on error
        yield call([marketService, marketService.disconnect], 1000, 'Market service error');
        yield delay(3000);
        yield call([marketService, marketService.connect]);
    }
}

function* closeWSConnection(marketService) {
    try {
        yield call([marketService, marketService.disconnect], 1000, 'Market service error');
    } catch (err) {
        logError('Close Market WS connection error: %O', err);
    }
}

function* runMarketTasks(marketService) {
    log('Market WS: OPEN. Run tasks');

    try {
        const symbolListSubscriptionTask = yield fork(symbolListSubscription);
        const symbolQuoteSubscriptionTask = yield fork(symbolQuoteSubscription);
        const serverTimePeriodicUpdateTask = yield fork(serverTimePeriodicUpdate);

        yield fork(function* () {
            yield take(actions.market.WSConnectionClose);
            log('Market WS: CLOSE. Stop tasks');

            yield cancel(symbolQuoteSubscriptionTask);
            yield cancel(symbolListSubscriptionTask);
            yield cancel(serverTimePeriodicUpdateTask);
        });
    } catch (err) {
        logError('runMarketTasks error: %O', err);
        // TODO add logic to up on error
        yield call([marketService, marketService.disconnect], 1000, 'Market service error');
        yield delay(3000);
        yield call([marketService, marketService.connect]);
    }
}
