import { call, put, getContext } from 'redux-saga/effects';
import { isNil, values } from 'lodash';
import { LOCAL_STORAGE_KEYS, TRADING_FORM_MODES, TRADING_FORM_MODE_DEFAULT, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';

const availableValues = new Set(values(TRADING_FORM_MODES));

export { tradingFormModeInitialSettings, handleSetTradingFormMode };

// Use on app start
function* tradingFormModeInitialSettings() {
    const storageService = yield getContext(SERVICE_NAMES.STORAGE);

    if (yield call([storageService, storageService.has], LOCAL_STORAGE_KEYS.TRADING_FORM_MODE)) {
        const currentTradingFormMode = yield call(
            [storageService, storageService.get],
            LOCAL_STORAGE_KEYS.TRADING_FORM_MODE
        );

        if (isNil(currentTradingFormMode)) {
            // default
            yield setTradingFormMode(TRADING_FORM_MODE_DEFAULT);
            return;
        }

        if (availableValues.has(currentTradingFormMode)) {
            yield setTradingFormMode(currentTradingFormMode);
            return;
        }
    }

    // default
    yield setTradingFormMode(TRADING_FORM_MODE_DEFAULT);
}

function* setTradingFormMode(newTradingFormMode) {
    yield put(actions.setTradingFormMode(newTradingFormMode));
}

// Use as handler for setTradingModeAction to save value in to storage
function* handleSetTradingFormMode({ payload: newTradingFormMode }) {
    const storageService = yield getContext(SERVICE_NAMES.STORAGE);
    yield call([storageService, storageService.set], LOCAL_STORAGE_KEYS.TRADING_FORM_MODE, newTradingFormMode);
}
