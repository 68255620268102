import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import { isNil, get } from 'lodash';
import initialState from '../initialState';
import { isActiveBetStatus } from 'Utils/statusFilters';

const customerInfo = createReducer(initialState.customerInfo, {
    [actions.trade.getCustomerInfoSuccess]: (state, { payload }) => {
        return payload;
    },
    [actions.trade.gotBet]: (state, { payload: bet }) => {
        const status = get(bet, 'status');

        if (isNil(status) || !isActiveBetStatus(status)) {
            return state;
        }

        const lastInsuranceConditionId = get(bet, 'insuranceConditionId');

        if (isNil(lastInsuranceConditionId)) {
            return state;
        }

        return { ...state, lastInsuranceConditionId };
    }
});

export default customerInfo;
