import { put, select, getContext } from 'redux-saga/effects';
import * as actions from 'Store/actions';
import { RESPONSE_STATUSES, SERVICE_NAMES } from 'Constants';
import logger from 'Utils/logger';

const log = logger('Health:');

export function* serverHealthCheck() {
    log('Try to check server health via auth check');

    const authService = yield getContext(SERVICE_NAMES.AUTH);
    const isAuthenticatedNow = yield select((store) => store.isAuthenticated);

    if (!isAuthenticatedNow) {
        log('User is not auth now, stop checking');
        return;
    }

    try {
        const { isAuthenticated, status } = yield authService.check();
        log('Server auth check result %O', { isAuthenticated, status });

        if (status === RESPONSE_STATUSES.SUCCESS && isAuthenticated) {
            return;
        }

        const isAuthenticatedNow = yield select((store) => store.isAuthenticated);

        if (isAuthenticatedNow) {
            yield put(actions.auth.requestLogout());
        }
    } catch (err) {
        log('Check server health failure: %O', err);
    }
}
