import { createAction } from '@reduxjs/toolkit';

// News WS Connection
export const WSConnectionConnecting = createAction('TECHNIC_WS_CONNECTION_CONNECTING');
export const WSConnectionOpen = createAction('TECHNIC_WS_CONNECTION_OPEN');
export const WSConnectionOnError = createAction('TECHNIC_WS_CONNECTION_ON_ERROR');
export const WSConnectionClose = createAction('TECHNIC_WS_CONNECTION_CLOSE');
export const WSConnectionProblem = createAction('TECHNIC_WS_CONNECTION_PROBLEM');
export const WSConnectionNormal = createAction('TECHNIC_WS_CONNECTION_NORMAL');

export const storeSettingsPending = createAction('STORE_SETTINGS_REQUEST_PENDING');
export const storeSettingsSuccess = createAction('STORE_SETTINGS_REQUEST_SUCCESS');
export const storeSettingsFailure = createAction('STORE_SETTINGS_REQUEST_FAILURE');
