import { put, call, fork, take, cancel, select, delay, getContext } from 'redux-saga/effects';
import { get, isEmpty, min, isUndefined } from 'lodash';
import { EVENTS, MARKET_REQUESTS, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';
import { prepareInputUint64Value } from 'Utils/uint64';
import { isEmptyDataStatus, isLoadingDataStatus } from 'Utils/statusFilters';
import roundByNumOfDecPlaces from 'Utils/roundByNumOfDecPlaces';
import logger from 'Utils/logger';
import getEventChannelCreator from 'Store/sagas/utils/getEventChannelCreator';
import getSymbolParameterValue from 'Utils/getSymbolParameterValue';

const logError = logger('Error:Saga:Market:ChartBars:');

const DEFAULT_BARS_LIST_LIMIT = 100;
const LOAD_MORE_BARS_LIMIT = 50;
const UPDATE_BAR_DELAY = 800;
const DEFAULT_SYMBOLS_DIGITS = 8;

const prepareBarListData = (chartBar, digits) => chartBar.map((item) => prepareBarData(item, digits));

const prepareBarData = (data, digits) => {
    const openTime = get(data, 'openTime');

    return {
        openTime,
        date: new Date(openTime),
        open: prepareInputValueWithRound(get(data, 'openPrice', 0), digits),
        close: prepareInputValueWithRound(get(data, 'closePrice', 0), digits),
        high: prepareInputValueWithRound(get(data, 'highestPrice', 0), digits),
        low: prepareInputValueWithRound(get(data, 'lowestPrice', 0), digits),
        volume: get(data, 'tickVolume', 0)
    };
};

const prepareInputValueWithRound = (value, digits) => roundByNumOfDecPlaces(prepareInputUint64Value(value), digits);

export function* getSnapshotAndSubscribeToChartBar({ payload }) {
    let subscriptionTask;
    try {
        const { symbol, periodType } = payload;
        const symbols = yield select((state) => state.symbols.byId);

        if (isEmpty(symbols)) {
            return;
        }

        const symbolDigits = getSymbolParameterValue(symbols, symbol, 'digits', DEFAULT_SYMBOLS_DIGITS);

        yield put(actions.market.resetCurrentChartBars());
        yield put(actions.market.getChartBarListPending());
        yield loadChartBarInitList(symbol, periodType, symbolDigits);
        subscriptionTask = yield fork(subscribeToChartBar, symbol, periodType, symbolDigits);
    } catch (err) {
        yield put(
            actions.market.chartBarError({
                symbol: payload.symbol,
                periodType: payload.periodType,
                error: err.message
            })
        );

        if (!!subscriptionTask) {
            yield cancel(subscriptionTask); // TODO: maybe retry???
        }
    }
}

function* loadChartBarInitList(symbol, periodType, digits) {
    const marketService = yield getContext(SERVICE_NAMES.MARKET);
    yield put(actions.market.getChartBarListPending());

    const response = yield requestChartBarList(marketService, {
        limit: DEFAULT_BARS_LIST_LIMIT,
        symbol,
        periodType
    });

    const responseChartBarList = get(response, 'body.chartBar', []);

    if (isEmpty(responseChartBarList)) {
        yield put(actions.market.chartBarsIsEmpty());
        return;
    }

    const chartBars = prepareBarListData(responseChartBarList, digits);
    yield put(actions.market.getChartBarListSuccess({ chartBars, symbol }));
}

function* subscribeToChartBar(symbol, periodType, symbolDigits) {
    try {
        const marketService = yield getContext(SERVICE_NAMES.MARKET);

        const barValueContainer = {
            get value() {
                return this._value;
            },

            set value(bar) {
                this._value = bar;
            }
        };

        const createEventChannel = getEventChannelCreator(marketService, EVENTS.MARKET.CLOSE);
        const chartBarChannel = yield call(createEventChannel, EVENTS.MARKET.CHART_BAR);
        const { subscriptionId } = yield chartBarSubscribeRequest(marketService, { symbol, periodType });
        const watchChartBarTask = yield fork(
            watchChartBar,
            chartBarChannel,
            subscriptionId,
            symbolDigits,
            barValueContainer
        );
        const updateChartBarTask = yield fork(updateChartBar, barValueContainer);
        const watchLoadMoreRequestsTask = yield fork(watchLoadMoreRequests, symbol, periodType, symbolDigits);

        while (true) {
            const { payload } = yield take(actions.market.unsubscribeFromChartBar);

            if (payload === symbol) {
                yield cancel(watchChartBarTask);
                yield cancel(updateChartBarTask);
                yield cancel(watchLoadMoreRequestsTask);
                yield chartBarUnsubscribeRequest(marketService, subscriptionId);
                return;
            }
        }
    } catch (error) {
        logError('subscribeToChartBar saga error: %o', error.message);
    }
}

function* watchChartBar(chartBarChannel, subscriptionId, symbolDigits, barValueContainer) {
    while (true) {
        const { chartBar, subscriptionId: currentId } = yield take(chartBarChannel);
        if (subscriptionId === currentId) {
            barValueContainer.value = prepareBarData(chartBar, symbolDigits);
        }
    }
}

function* updateChartBar(barValueContainer) {
    while (true) {
        if (!isUndefined(barValueContainer.value)) {
            yield put(actions.market.chartBarUpdate(barValueContainer.value));
        }
        yield delay(UPDATE_BAR_DELAY);
    }
}

function* watchLoadMoreRequests(symbol, periodType, symbolDigits) {
    while (true) {
        const {
            payload: { limit, symbol: requestedSymbol, timeframe: requestedPeriodType }
        } = yield take(actions.market.loadMoreChartBars);

        if (requestedSymbol === symbol && requestedPeriodType === periodType) {
            yield loadMoreChartBars(symbol, periodType, symbolDigits, limit);
        } else {
            logError(
                'loadMoreChartBars action with %o %o, but %o, %o expected',
                requestedSymbol,
                requestedPeriodType,
                symbol,
                periodType
            );
        }
    }
}

function* loadMoreChartBars(symbol, periodType, digits, limit = LOAD_MORE_BARS_LIMIT) {
    try {
        const marketService = yield getContext(SERVICE_NAMES.MARKET);
        const { loadStatus, chartBarsIds } = yield select(
            ({ loadingStatuses: { chartBars: loadStatus }, chartBars: { allIds: chartBarsIds } }) => ({
                loadStatus,
                chartBarsIds
            })
        );

        if (isLoadingDataStatus(loadStatus) || isEmptyDataStatus(loadStatus)) {
            return;
        }

        const timeTo = min(chartBarsIds) || 0;

        const response = yield requestChartBarList(marketService, {
            limit,
            symbol,
            periodType,
            timeTo
        });

        const responseChartBarList = get(response, 'body.chartBar', []);

        if (isEmpty(responseChartBarList)) {
            yield put(actions.market.chartBarsIsEmpty());
            return;
        }

        const chartBars = prepareBarListData(responseChartBarList, digits);
        yield put(actions.market.getChartBarListSuccess({ chartBars }));
    } catch (err) {
        yield put(actions.market.getChartBarListFailure(err.message));
    }
}

const requestChartBarList = (marketService, options) =>
    marketService.request(MARKET_REQUESTS.CHART_BAR_LIST_REQUEST, options);

const chartBarSubscribeRequest = (marketService, options) =>
    marketService.requestSubscribe(MARKET_REQUESTS.CHART_BAR_REQUEST, options);

const chartBarUnsubscribeRequest = (marketService, id) =>
    marketService.requestUnsubscribe(MARKET_REQUESTS.CHART_BAR_REQUEST, id);
