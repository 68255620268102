import { EVENTS, ENV_VARIABLES } from 'Constants';
import { assign, isNull, get, isNil } from 'lodash';
import logger from 'Utils/logger';
import checkIsEqualObjStructure from 'Utils/checkIsEqualObjStructure';
import EventEmitter from 'Utils/EventEmitter';
import isEmptyString from 'Utils/isEmptyString';

const CLOSE_NORMAL_CODE = 1000;

const ARRAY_BUFFER = 'arraybuffer';

const HEALTH_STATUSES = {
    INIT: 'init',
    NORMAL: 'normal',
    PROBLEM: 'problem'
};

const fixIdIfLong = (data) => {
    if (process.env.NODE_ENV !== ENV_VARIABLES.TEST) {
        return data;
    }

    if (data.id instanceof Object) {
        data.id = Number(data.id);
    }

    if (data.hasOwnProperty('subscriptionRequest') && data.subscriptionRequest.subscriptionId instanceof Object) {
        data.subscriptionRequest.subscriptionId = Number(data.subscriptionRequest.subscriptionId);
    }

    if (data.hasOwnProperty('subscriptionResult') && data.subscriptionResult.subscriptionId instanceof Object) {
        data.subscriptionResult.subscriptionId = Number(data.subscriptionResult.subscriptionId);
    }

    return data;
};

const defaultOptions = {
    WebSocketClass: WebSocket,
    name: '',
    url: '',
    protocolVersion: '',
    protocolEncoder: null,
    protocolDecoder: null,

    /**
     * The number of milliseconds to delay before attempting to reconnect.
     **/
    reconnectInterval: 1000,

    /**
     * The maximum number of milliseconds to delay a reconnection attempt.
     **/
    maxReconnectInterval: 30000,

    /**
     * The rate of increase of the reconnect delay. Allows reconnect attempts to back off when problems persist.
     **/
    reconnectDecay: 1.5,

    /**
     * The maximum number of reconnection attempts to make. No attempts if zero. Unlimited if null.
     **/
    maxReconnectAttempts: null,

    /**
     * If the protocol provides the ability to perform health requests, you can enable this option
     **/
    shouldCheckHealthStatus: false,

    /**
     * If canCheckHealthStatus is set to true, you must set the checkHealthRequestType, e.g. 'ping'
     **/
    checkHealthRequestType: null,

    /**
     * If canCheckHealthStatus is set to true, you must set the checkHealthRequestData, e.g. empty object: {}
     **/
    checkHealthRequestData: null,

    /**
     * The maximum timeout in milliseconds to wait for a server to respond to a health request.
     **/
    checkHealthWaitTimeOut: 3000,

    /**
     * The waiting time in milliseconds before resending a health request.
     **/
    checkHealthRepeatTimeout: 5000,

    /**
     * Event that will be emmited when a connection problem is detected.
     **/
    checkHealthEventOnProblem: null,

    /**
     * The event that will be sent when the connection problem disappears.
     **/
    checkHealthEventOnNormal: null,
    /**
     * Check ws-server-point if necessary before establishing ws connection
     **/
    shouldUseWSCutout: false
};

class WebSocketService extends EventEmitter {
    constructor(customOptions = {}) {
        super();
        this._options = assign({}, defaultOptions, customOptions);
        const { WebSocketClass, name, url, protocolVersion, protocolEncoder, protocolDecoder } = this._options;

        this._name = name.toUpperCase();
        this._protocolVersion = protocolVersion;
        this._serviceName = `${name}_SERVICE`;
        this._url = url;
        this._encoder = protocolEncoder;
        this._decoder = protocolDecoder;

        this._log = logger(`WS:${this._name}:`);
        this._logError = logger(`Error:WS:${this._name}:`);

        if (isNil(WebSocketClass)) {
            throw new Error('WebSocket is not supported by the browser');
        }

        this._WebSocketClass = WebSocketClass;

        this._promisesMap = new Map();
        this._lastRequestId = 0;
        this._reconnectAttempts = 0;
        this._protocol = null;
        this._ws = null;
        this._isReconnect = false;
        this._forcedClose = false;
        this._timedOut = false;
        this._timeoutId = null;
        this._binaryType = ARRAY_BUFFER;

        this._resetHealthStatus();
    }

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
     * this._WebSocketClass === window.WebSocket
     */
    get isOpening() {
        return Boolean(this._ws && this._ws.readyState === this._WebSocketClass.CONNECTING);
    }

    get isOpened() {
        return Boolean(this._ws && this._ws.readyState === this._WebSocketClass.OPEN);
    }

    get isClosing() {
        return Boolean(this._ws && this._ws.readyState === this._WebSocketClass.CLOSING);
    }

    get isClosed() {
        return Boolean(!this._ws || this._ws.readyState === this._WebSocketClass.CLOSED);
    }

    toString() {
        return this._name;
    }

    _checkHealthStatus = () => {
        const {
            shouldCheckHealthStatus,
            checkHealthRequestType,
            checkHealthRequestData,
            checkHealthWaitTimeOut,
            checkHealthRepeatTimeout,
            checkHealthEventOnProblem,
            checkHealthEventOnNormal
        } = this._options;

        if (!shouldCheckHealthStatus || !this.isOpened) {
            return;
        }

        if (
            !checkHealthRequestType ||
            !checkHealthRequestData ||
            !checkHealthEventOnProblem ||
            !checkHealthEventOnNormal
        ) {
            this._logError('Not enough parameters _checkHealthStatus');
            return;
        }

        return Promise.race([
            this.request(checkHealthRequestType, checkHealthRequestData),
            new Promise((resolve, reject) =>
                setTimeout(() => reject(new Error(`Timed out: ${checkHealthWaitTimeOut} ms`)), checkHealthWaitTimeOut)
            )
        ])
            .then(() => {
                if (this._wsHealthStatus !== HEALTH_STATUSES.NORMAL) {
                    this._wsHealthStatus = HEALTH_STATUSES.NORMAL;
                    this.emit(checkHealthEventOnNormal, true);
                }
            })
            .catch((err) => {
                // console.warn(this._name, err);

                if (this._wsHealthStatus !== HEALTH_STATUSES.PROBLEM) {
                    this._wsHealthStatus = HEALTH_STATUSES.PROBLEM;
                    this.emit(checkHealthEventOnProblem, true);
                }
            })
            .finally(() => {
                if (!this.isOpened) {
                    return;
                }
                setTimeout(() => this._checkHealthStatus(), checkHealthRepeatTimeout);
            });
    };

    _resetHealthStatus = () => {
        if (!this._options.shouldCheckHealthStatus) {
            return;
        }

        this._wsHealthStatus = HEALTH_STATUSES.INIT;
    };

    _getMessageLogName = (message) => {
        const messageBodyName = message.body;
        const responseName = get(message, `${messageBodyName}.response`);
        return responseName || messageBodyName;
    };

    _getRequestLogName = (request) => {
        const requestBodyName = request.body;
        const requestName = get(request, `${requestBodyName}.request`);
        const entityName = get(request, `${requestBodyName}.entity`);
        return requestName || entityName || requestBodyName;
    };

    _isValidRequest = (request) => {
        const verifiedMessage = this._encoder.verify(request);

        if (!isNull(verifiedMessage)) {
            this._logError('INVALID_REQUEST_MESSAGE:#%o %O', request.id, verifiedMessage);
            return false;
        }

        return true;
    };

    _isValidInputData = (rawData, preparedData) => {
        const wrongKeys = checkIsEqualObjStructure(rawData, preparedData);

        if (!isNull(wrongKeys)) {
            this._logError('INVALID_REQUEST_INPUT_DATA:#%o %O', this._serviceName, wrongKeys);
            return false;
        }

        return true;
    };

    _calcTimeout = () => {
        const { reconnectInterval, maxReconnectInterval, reconnectDecay } = this._options;
        const timeout = reconnectInterval * Math.pow(reconnectDecay, this._reconnectAttempts);
        return timeout > maxReconnectInterval ? maxReconnectInterval : timeout;
    };

    _cancelTimeout = () => {
        clearTimeout(this._timeoutId);
        this._timeoutId = null;
    };

    _canReconnect = () => {
        const { maxReconnectAttempts } = this._options;

        const hasNegativeReason = this._forcedClose || maxReconnectAttempts === 0 || this._isReconnect; // || this._timedOut;
        const hasPositiveReason = isNull(maxReconnectAttempts) || this._reconnectAttempts <= maxReconnectAttempts;

        const canReconnect = !hasNegativeReason && hasPositiveReason;

        this._log(
            'Reconnect reasons: %O, can reconnect? %o',
            {
                ForcedClose: this._forcedClose,
                isReconnect: this._isReconnect,
                timedOut: this._timedOut,
                MaxReconnectAttempts: maxReconnectAttempts,
                reconnectAttempts: this._reconnectAttempts
            },
            canReconnect
        );

        return canReconnect;
    };

    _reconnect = () => {
        if (!this._canReconnect()) {
            this.emit(EVENTS[this._name].RECONNECT_NOT_ALLOWED);
            this._rejectAllPromises(new Error(`${this._name} WS Connection has been closed`));
            return;
        }

        this._timeoutId = setTimeout(() => {
            this._reconnectAttempts++;
            this._isReconnect = true;
            this.emit(EVENTS[this._name].RECONNECTING);

            this._log('Trying to reconnect. Reconnect attempts %o.', this._reconnectAttempts);

            this._open();
        }, this._calcTimeout());
    };

    _onopen = () => {
        this._cancelTimeout();

        this._log('Connection is opened: %o', this._url);

        this._protocol = this._ws.protocol;
        this._reconnectAttempts = 0;

        this.emit(EVENTS[this._name].OPEN, { isReconnect: this._isReconnect });
        this._isReconnect = false;

        this._resetHealthStatus();
        this._checkHealthStatus();
    };

    _onclose = (evt) => {
        this._cancelTimeout();
        this._isReconnect = false;
        this._ws = null;

        const { code, reason, wasClean } = evt;
        this.emit(EVENTS[this._name].CLOSE, { code, reason, wasClean });

        this._log('Connection is closed. Code: %o. Reason: %o. wasClean: %o.', code, reason, wasClean);

        this._reconnect();
    };

    _onmessage = (evt) => {
        try {
            const message = this._decodeIncomingData(evt.data);
            const messageKey = message.body;

            this._log('MESSAGE:#%o:%o %O', message.id, this._getMessageLogName(message), message[messageKey]);

            const messageHandled = this._decoder.toObject(message);

            this.emit(EVENTS[this._name].MESSAGE, { id: messageHandled.id, body: messageHandled[messageKey] });
            this.emit(messageKey, { id: messageHandled.id, body: messageHandled[messageKey] });

            const currentMessagePromise = this._promisesMap.get(message.id);

            if (!currentMessagePromise) {
                // this._log('Has no promise for id: %s, %O', message.id, this._promisesMap);
                return;
            }

            if (message.error) {
                currentMessagePromise.reject(new Error(message.error.text));
            } else {
                currentMessagePromise.resolve({ id: messageHandled.id, body: messageHandled[messageKey] });
            }

            this._promisesMap.delete(message.id);
        } catch (err) {
            this._onerror(err);
        }
    };

    _decodeIncomingData = (data) => {
        const message = this._decoder.decode(new Uint8Array(data));
        return fixIdIfLong(message);
    };

    _onerror = (err) => {
        this._logError('Error occurred. %O', err);
        this.emit(EVENTS[this._name].ERROR, err);
        this._rejectAllPromises(err);
    };

    // return only resolvable promise
    _wsCutout = () =>
        this._options.shouldUseWSCutout
            ? fetch(this._url.replace('wss://', 'https://'), {}).catch((err) => {})
            : Promise.resolve();

    _open = async () => {
        try {
            if (this.isOpened || this.isOpening) {
                this._logError('The connection is already open (or opening), but trying to open again!');
                return;
            }

            await this._wsCutout();

            if (isEmptyString(this._protocolVersion)) {
                this._ws = new this._WebSocketClass(this._url);
            } else {
                this._ws = new this._WebSocketClass(this._url, this._protocolVersion);
            }

            this._ws.binaryType = this._binaryType;

            this._ws.onopen = this._onopen;
            this._ws.onclose = this._onclose;
            this._ws.onmessage = this._onmessage;
            this._ws.onerror = this._onerror;
            this.emit(EVENTS[this._name].CONNECTING);

            this._log(
                'Attempting to connect. %o. The current reconnect attempt number: %o',
                this._url,
                this._reconnectAttempts
            );
        } catch (err) {
            this._onerror(err);
        }
    };

    _send = (data) => {
        if (this.isOpened) {
            this._ws.send(data);
            return;
        }

        throw new Error(`${this._serviceName}:INVALID_STATE_ERR: Pausing to reconnect WebSocket`);
    };

    _sendWithPromise = (request) => {
        return new Promise((resolve, reject) => {
            if (!this.isOpened) {
                this._logError('Connection not open!');
                throw new Error(`${this._serviceName}: Connection not open!`);
            }

            this._promisesMap.set(request.id, { resolve, reject });

            this._send(this._encoder.encode(request).finish());
        });
    };

    _rejectAllPromises = (reason) => {
        this._promisesMap.forEach(({ reject }) => reject(reason));
        this._promisesMap.clear();
    };

    request = (method, data) => {
        const rawData = {
            id: ++this._lastRequestId,
            [method]: data
        };

        const preparedData = fixIdIfLong(this._encoder.fromObject(rawData));

        if (!this._isValidInputData(rawData, preparedData) || !this._isValidRequest(preparedData)) {
            throw new Error(`${this._serviceName}: Request content is not valid`);
        }

        this._log('REQUEST:#%o:%o %O', preparedData.id, this._getRequestLogName(preparedData), preparedData);
        return this._sendWithPromise(preparedData);
    };

    _close = (...args) => {
        this._log('Run service close with arguments: %o', args);

        const [code = CLOSE_NORMAL_CODE, reason] = args;

        if (code === CLOSE_NORMAL_CODE) {
            this._forcedClose = true;
        }

        if (this.isClosed || this.isClosing) {
            this._logError('The connection is already closed (or closing), but trying to close again!');
            return;
        }

        this._ws.close(code, reason);
        this._rejectAllPromises(new Error(`${this._name} WS Connection has been closed`));
    };

    /**
     * Just public aliases (for now)
     **/
    connect = this._open;
    disconnect = this._close;
}

export default WebSocketService;
