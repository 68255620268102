import { takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from 'Store/actions';
import {
    placeBetRequest,
    loadActiveBets,
    cancelBet,
    closeBet,
    loadFinishedBets,
    loadMoreFinishedBets
} from '../bets.saga';

import { requestBetCommission, requestCloseBetCommission } from '../commission.saga';

import logger from 'Utils/logger';

const log = logger('BetsActionListenersSaga:');

// RUN ON APP START ONLY, AS PART OF MAIN TRADE SAGA
export default function* betTradeListeners() {
    log('Start bets specific listeners saga');

    yield takeLatest(actions.trade.loadActiveBets, loadActiveBets);
    yield takeLatest(actions.trade.loadFinishedBets, loadFinishedBets);
    yield takeEvery(actions.trade.loadMoreFinishedBets, loadMoreFinishedBets);

    yield takeEvery(actions.trade.placeBetRequest, placeBetRequest);
    yield takeEvery(actions.trade.cancelBet, cancelBet);
    yield takeEvery(actions.trade.closeBet, closeBet);

    yield takeEvery(actions.trade.getCommission, requestBetCommission);
    yield takeEvery(actions.trade.getCloseBetCommission, requestCloseBetCommission);
}
