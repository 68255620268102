import { createReducer } from '@reduxjs/toolkit';
import { keyBy, omit } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';

const positions = createReducer(initialState.positions, {
    [actions.trade.getPositionListSuccess]: (state, { payload: positions }) => {
        const allKeys = positions.map((p) => p.positionKey).sort((a, b) => b - a);
        const byKey = keyBy(positions, (p) => p.positionKey);
        return { byKey, allKeys };
    },
    [actions.trade.removePosition]: ({ byKey, allKeys }, { payload: positionKey }) => {
        return {
            byKey: omit(byKey, positionKey),
            allKeys: allKeys.filter((id) => id !== positionKey)
        };
    },
    [actions.trade.updatePosition]: ({ byKey, allKeys }, { payload: position }) => {
        const { positionKey } = position;
        return {
            byKey: { ...byKey, [positionKey]: { ...byKey[positionKey], ...position } },
            allKeys
        };
    },
    [actions.trade.addPosition]: ({ byKey, allKeys }, { payload: position }) => {
        const { positionKey } = position;
        return {
            byKey: { ...byKey, [positionKey]: position },
            allKeys: [...allKeys, positionKey].sort((a, b) => b - a)
        };
    },
    [actions.trade.setPositionProfitLoss]: ({ byKey, allKeys }, { payload }) => {
        return {
            byKey: { ...byKey, ...payload },
            allKeys
        };
    }
});

export default positions;
