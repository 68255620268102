import defaultsDeep from 'lodash/defaultsDeep';
import isNull from 'lodash/isNull';
import isFunction from 'lodash/isFunction';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import { RESPONSE_STATUSES, AUTH_ACTION_TYPES, POST_REQUEST_OPTIONS, getAuthRoutes } from 'Constants';

import base64Unicode from 'Utils/base64Unicode';

const noop = () => null;
const EMPTY_STRING = '';

const AUTH_REQUEST_OPTIONS = {
    ...POST_REQUEST_OPTIONS
};

const getRequestOptionsWithAuthorizationHeader = (login, password) => {
    const options = cloneDeep(AUTH_REQUEST_OPTIONS);
    set(options, 'headers.Authorization', `Basic ${base64Unicode.encode(`${login}:${password}`)}`);
    return options;
};

const getRequestOptionsWithTokenAuthorizationHeader = (token) => {
    const options = cloneDeep(AUTH_REQUEST_OPTIONS);
    set(options, 'headers.Authorization', `Bearer ${token}`);
    return options;
};

const isSuccess = (responseStatus) => {
    const { SUCCESS } = RESPONSE_STATUSES;

    return responseStatus === SUCCESS;
};

const getMaxAgeHeaderValue = (response) => {
    const { headers } = response;
    const maxAge = headers.get('Max-Age');

    return !isNull(maxAge)
        ? // Initially maxAge is in seconds
          // Convert to milliseconds if not null
          parseInt(maxAge, 10) * 1000
        : maxAge;
};

const handleResponseErrors = (response) => {
    const { SUCCESS, UNAUTHORIZED, TOO_MANY_REQUESTS, FORBIDDEN } = RESPONSE_STATUSES;
    const { status, statusText } = response;

    if ([SUCCESS, UNAUTHORIZED, TOO_MANY_REQUESTS, FORBIDDEN].includes(status)) {
        return response;
    }

    const errorMessage = `type: AuthError, status: ${status}, statusText: ${statusText}`;

    throw new Error(errorMessage);
};

const defaults = {
    authApiDomain: EMPTY_STRING,
    token: null,
    name: 'AuthService',
    logger: () => noop,
    fetch: (...args) => fetch(...args)
};

class AuthService {
    constructor(options) {
        const config = defaultsDeep(options, defaults);

        this._authApiDomain = config.authApiDomain;
        this._name = config.name;

        this._fetch = config.fetch;
        this._log = config.logger(`Service:${this._name}`);
        this._logError = config.logger(`Error:Service:${this._name}`);

        this._getRoute = getAuthRoutes(this._authApiDomain);
    }

    login({ login, password, isForced = false, addGrecaptchaToUrl }) {
        const loginOptions = getRequestOptionsWithAuthorizationHeader(login, password);

        const loginUrl = this._getRoute(AUTH_ACTION_TYPES.LOGIN);
        const loginForcedUrl = this._getRoute(AUTH_ACTION_TYPES.LOGIN_FORCED);

        let url = isForced ? loginForcedUrl : loginUrl;

        if (isFunction(addGrecaptchaToUrl)) {
            url = addGrecaptchaToUrl(url);
        }

        if (!url) {
            throw new Error('Login URL is invalid');
        }

        this._log('Request login: url: options: %o', url, loginOptions);

        return this._fetch(url, loginOptions)
            .then(handleResponseErrors)
            .then((response) => {
                const { status } = response;
                const maxAge = getMaxAgeHeaderValue(response);

                return response.text().then((body) => ({
                    status,
                    maxAge,
                    body
                }));
            })
            .then((response) => {
                const isAuthenticated = isSuccess(response.status);

                this._log('Got login response: isAuthenticated: %o', isAuthenticated);

                const info = {
                    action: AUTH_ACTION_TYPES.LOGIN,
                    status: response.status,
                    maxAge: response.maxAge,
                    isAuthenticated
                };

                if (response.status === RESPONSE_STATUSES.TOO_MANY_REQUESTS) {
                    info.retryAfter = parseInt(response.body, 10);
                }

                return info;
            })
            .catch((err) => {
                this._logError('Login request error: %o', err.message);
                throw new Error(err);
            });
    }

    loginByToken({ token }) {
        const loginOptions = getRequestOptionsWithTokenAuthorizationHeader(token);

        let url = this._getRoute(AUTH_ACTION_TYPES.LOGIN);

        if (!url) {
            throw new Error('Login URL is invalid');
        }

        this._log('Request login: url: options: %o', url, loginOptions);

        return this._fetch(url, loginOptions)
            .then(handleResponseErrors)
            .then((response) => {
                const { status } = response;
                const maxAge = getMaxAgeHeaderValue(response);

                return response.text().then((body) => ({
                    status,
                    maxAge,
                    body
                }));
            })
            .then((response) => {
                const isAuthenticated = isSuccess(response.status);

                this._log('Got login response: isAuthenticated: %o', isAuthenticated);

                const info = {
                    action: AUTH_ACTION_TYPES.LOGIN,
                    status: response.status,
                    maxAge: response.maxAge,
                    isAuthenticated
                };

                if (response.status === RESPONSE_STATUSES.TOO_MANY_REQUESTS) {
                    info.retryAfter = parseInt(response.body, 10);
                }

                return info;
            })
            .catch((err) => {
                this._logError('Login request error: %o', err.message);
                throw new Error(err);
            });
    }

    // The server will always respond with 200 (Success)
    // even if the cookie is expired and the status is 401 (Unauthorized)
    // except for 400 (Bad Request)
    logout() {
        const logoutUrl = this._getRoute(AUTH_ACTION_TYPES.LOGOUT);

        if (!logoutUrl) {
            throw new Error('Logout URL is invalid');
        }

        this._log('Request logout');

        return this._fetch(logoutUrl, AUTH_REQUEST_OPTIONS)
            .then(handleResponseErrors)
            .then((response) => {
                const isAuthenticated = !isSuccess(response.status);

                this._log('Got logout response: isAuthenticated: %o', isAuthenticated);

                const info = {
                    action: AUTH_ACTION_TYPES.LOGOUT,
                    status: response.status,
                    isAuthenticated
                };

                return info;
            })
            .catch((err) => {
                this._logError('Logout request error: %o', err.message);
                throw new Error(err);
            });
    }

    refresh() {
        const refreshUrl = this._getRoute(AUTH_ACTION_TYPES.REFRESH);

        if (!refreshUrl) {
            throw new Error('Refresh URL is invalid');
        }

        this._log('Request refresh');

        return this._fetch(refreshUrl, AUTH_REQUEST_OPTIONS)
            .then(handleResponseErrors)
            .then((response) => {
                const isAuthenticated = isSuccess(response.status);
                const maxAge = getMaxAgeHeaderValue(response);

                this._log('Got refresh response: isAuthenticated: %o, maxAge: %o', isAuthenticated, maxAge);

                const info = {
                    action: AUTH_ACTION_TYPES.REFRESH,
                    status: response.status,
                    maxAge,
                    isAuthenticated
                };

                return info;
            })
            .catch((err) => {
                this._logError('Refresh request error: %o', err.message);
                throw new Error(err);
            });
    }

    check() {
        const checkUrl = this._getRoute(AUTH_ACTION_TYPES.CHECK);

        if (!checkUrl) {
            throw new Error('Check URL is invalid');
        }

        this._log('Request check auth');

        return this._fetch(checkUrl, AUTH_REQUEST_OPTIONS)
            .then(handleResponseErrors)
            .then((response) => {
                const isAuthenticated = isSuccess(response.status);

                this._log('Got check auth response: isAuthenticated: %o', isAuthenticated);

                const info = {
                    action: AUTH_ACTION_TYPES.CHECK,
                    status: response.status,
                    isAuthenticated
                };

                return info;
            })
            .catch((err) => {
                this._logError('Check auth request error: %o', err.message);
                throw new Error(err);
            });
    }
}

export default AuthService;
