import { isArray, isString, find, isUndefined } from 'lodash';

const isArrayOfStrings = (values) => {
    if (!isArray(values)) {
        return false;
    }

    return isUndefined(find(values, (v) => !isString(v)));
};

export default isArrayOfStrings;
