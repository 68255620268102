import { isUndefined } from 'lodash';

const roundByNumOfDecPlaces = (value, digits) => {
    if (isUndefined(value) || isUndefined(digits)) {
        throw new Error('Both parameters "value" and "digits" of round() function are required.');
    }

    if (parseFloat(value) !== value) {
        throw new Error('Parameter "value" must be float');
    }

    if (parseInt(digits) !== digits) {
        throw new Error('Parameter "digits" must be integer');
    }

    if (digits < 0) {
        throw new Error('Parameter "digits" must be positive');
    }

    return Math.round(value * `1e${digits}`) / `1e${digits}`;
};

export default roundByNumOfDecPlaces;
