import { call, delay, retry } from 'redux-saga/effects';

function* customRetry(timeout, fn, ...args) {
    while (true) {
        try {
            const result = yield call(fn, ...args);
            return result;
        } catch (err) {
            yield delay(timeout);
        }
    }
}

/**
 *
 * @param {number} attempts: count of tries, tries === 0 => unlimit tries;
 * @param {integer} timeout: delay milliseconds
 * @param {generator} fn: fn for try to run
 * @param  {...any} args: args of nf
 */

function* main(tries, timeout, fn, ...args) {
    if (tries === 0) {
        return yield customRetry(timeout, fn, ...args);
    }
    return yield retry(tries, timeout, fn, ...args);
}

export default main;
