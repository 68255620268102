import { takeLatest, call, takeEvery, all, fork, take, delay, cancel, getContext, select } from 'redux-saga/effects';
import { EVENTS, SERVICE_NAMES, TRADING_MODES } from 'Constants';
import * as actions from 'Store/actions';
import { requestCustomerInfo, handleCustomerInfo } from './customer.saga';
import { requestTotalAmounts, handleTotalAmounts } from './totalAmounts.saga';
import { loadBalances, loadMoreBalances, handleBalance } from './balances.saga';
import {
    requestInsuranceConditionList,
    handleInsuranceConditionList,
    handleInsuranceCondition
} from './insurance.saga';
import { requestCurrencyList, handleCurrencyList, handleCurrency } from './currency.saga';
import getEventChannelCreator from 'Store/sagas/utils/getEventChannelCreator';
import WSEventsToActionsDispatch from 'Store/sagas/utils/WSEventsToActionsDispatch.saga';
import betTradeListeners from './actionsListeners/betTradeListeners.saga';
import marginTradeListeners from './actionsListeners/marginTradeListeners.saga';
import betTradeWorker from './onWsOpenWorkers/betTradeWorker.saga';
import marginTradeWorker from './onWsOpenWorkers/marginTradeWorker.saga';
import { serverHealthCheck } from 'Store/sagas/health';
import logger from 'Utils/logger';

const log = logger('TradeMainSaga:');
const logError = logger('Error:TradeMainSaga:');

const eventsToActionsMap = [
    { event: EVENTS.TRADE.OPEN, actionCreator: actions.trade.WSConnectionOpen },
    { event: EVENTS.TRADE.CLOSE, actionCreator: actions.trade.WSConnectionClose },
    { event: EVENTS.TRADE.ERROR, actionCreator: actions.trade.WSConnectionOnError },
    { event: EVENTS.TRADE.PROBLEM, actionCreator: actions.trade.WSConnectionProblem },
    { event: EVENTS.TRADE.NORMAL, actionCreator: actions.trade.WSConnectionNormal }
];

const listenersSagasByModes = {
    [TRADING_MODES.BETS]: betTradeListeners,
    [TRADING_MODES.MARGIN]: marginTradeListeners
};

const onWSOpenWorkersByModes = {
    [TRADING_MODES.BETS]: betTradeWorker,
    [TRADING_MODES.MARGIN]: marginTradeWorker
};

// MAIN
export default function* tradeSaga() {
    const tradeService = yield getContext(SERVICE_NAMES.TRADE);
    const createDefaultEventChannel = getEventChannelCreator(tradeService);
    yield fork(WSEventsToActionsDispatch, createDefaultEventChannel, eventsToActionsMap);

    yield takeEvery(actions.setIsAuthenticated, openWSConnection, tradeService);
    yield takeEvery(actions.auth.requestLogoutPending, closeWSConnection, tradeService);

    yield takeLatest(actions.bothMarketAndTradeWSConnectionsOpen, runTradeTasks, tradeService);
    yield takeLatest(actions.trade.WSConnectionClose, serverHealthCheck);

    yield takeEvery(actions.trade.loadBalances, loadBalances);
    yield takeEvery(actions.trade.loadMoreBalances, loadMoreBalances);
}

function* openWSConnection(tradeService, { payload }) {
    if (payload !== true) {
        return;
    }

    if (tradeService.isOpening || tradeService.isOpened) {
        return;
    }

    try {
        yield call([tradeService, tradeService.connect]);
    } catch (err) {
        logError('Open trade connection error: %O', err);
        // TODO add logic to up on error
        yield call([tradeService, tradeService.disconnect], 1000, 'Trade service error');
        yield delay(3000);
        yield call([tradeService, tradeService.connect]);
    }
}

function* closeWSConnection(tradeService) {
    try {
        yield call([tradeService, tradeService.disconnect], 1000, 'Trade service error');
    } catch (err) {
        logError('Close Trade WS connection error: %O', err);
    }
}

function* runTradeTasks(tradeService) {
    const tradingMode = yield select((state) => state.tradingMode);

    log('Trade and Market WS: OPEN. Run tasks. Mode: %o', tradingMode);

    try {
        const commonWSListenersTask = yield fork(commonWSListeners, tradeService);

        const getInitDataTask = yield fork(function* () {
            yield requestCustomerInfo();
            yield requestTotalAmounts();
            yield requestInsuranceConditionList();
            yield requestCurrencyList();
        });

        const onWsOpenSagaByTradingModeTask = yield fork(onWSOpenWorkersByModes[tradingMode]);
        const listenersTradingModeTask = yield fork(listenersSagasByModes[tradingMode]);

        yield fork(function* () {
            yield take(actions.oneOfMarketOrTradeWSConnectionsClose);
            log('Trade or Market WS: CLOSE. Stop tasks');

            yield cancel(commonWSListenersTask);
            yield cancel(getInitDataTask);
            yield cancel(onWsOpenSagaByTradingModeTask);
            yield cancel(listenersTradingModeTask);
        });
    } catch (err) {
        logError('runTradeTasks error: %O', err);
        // TODO add logic to up on error
        yield call([tradeService, tradeService.disconnect], 1000, 'Trade service error');
        yield delay(3000);
        yield call([tradeService, tradeService.connect]);
    }
}

// WRITE HERE CHANNELS FOR DISPATCH COMMON WS EVENTS TO HANDLERS
function* commonWSListeners(tradeService) {
    // event listeners have been removed from socket handlers in the EVENTS.TRADE.CLOSE event
    const createEventChannelWithClosing = getEventChannelCreator(tradeService, EVENTS.TRADE.CLOSE);

    const [
        customerInfoChannel,
        totalAmountsChannel,
        insuranceConditionListChannel,
        insuranceConditionChannel,
        currencyListChannel,
        currencyChannel,
        balanceChannel
    ] = yield all([
        call(createEventChannelWithClosing, EVENTS.TRADE.CUSTOMER_INFO),
        call(createEventChannelWithClosing, EVENTS.TRADE.TOTAL_AMOUNTS),
        call(createEventChannelWithClosing, EVENTS.TRADE.INSURANCE_CONDITION_LIST),
        call(createEventChannelWithClosing, EVENTS.TRADE.INSURANCE_CONDITION),
        call(createEventChannelWithClosing, EVENTS.TRADE.CURRENCY_LIST),
        call(createEventChannelWithClosing, EVENTS.TRADE.CURRENCY),
        call(createEventChannelWithClosing, EVENTS.TRADE.BALANCE)
    ]);

    yield takeEvery(customerInfoChannel, handleCustomerInfo);
    yield takeEvery(totalAmountsChannel, handleTotalAmounts);
    yield takeEvery(insuranceConditionListChannel, handleInsuranceConditionList);
    yield takeEvery(insuranceConditionChannel, handleInsuranceCondition);
    yield takeEvery(currencyListChannel, handleCurrencyList);
    yield takeEvery(currencyChannel, handleCurrency);
    yield takeEvery(balanceChannel, handleBalance);
}
