import WebSocketService from './WebSocketService';
import { feedbox } from 'protocol';
import { TRADE_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL, TRADE_REQUESTS, EVENTS } from 'Constants';

const SERVICE_NAME = 'TRADE';

class TradeWebSocketService extends WebSocketService {
    constructor(customOptions = {}) {
        super({
            url: '',
            name: SERVICE_NAME,
            protocolVersion: TRADE_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL,
            protocolEncoder: feedbox.trade_api.TradeDataRequest,
            protocolDecoder: feedbox.trade_api.TradeData,
            shouldCheckHealthStatus: true,
            checkHealthRequestType: TRADE_REQUESTS.PING,
            checkHealthRequestData: {},
            checkHealthEventOnProblem: EVENTS.TRADE.PROBLEM,
            checkHealthEventOnNormal: EVENTS.TRADE.NORMAL,
            shouldUseWSCutout: true,
            ...customOptions
        });
    }

    send = (method, data) => {
        const rawData = {
            id: ++this._lastRequestId,
            [method]: data
        };

        const preparedData = this._encoder.fromObject(rawData);

        if (!this._isValidInputData(rawData, preparedData) || !this._isValidRequest(preparedData)) {
            throw new Error(`${this._serviceName}: Send content is not valid`);
        }

        if (!this.isOpened) {
            this._logError('Connection not open!');
            throw new Error(`${this._serviceName}: Connection not open!`);
        }

        this._log('SEND:#%o:%o %O', preparedData.id, this._getRequestLogName(preparedData), preparedData);
        this._send(this._encoder.encode(preparedData).finish());
    };

    get lastRequestId() {
        return this._lastRequestId;
    }
}

export default TradeWebSocketService;
