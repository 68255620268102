import { createReducer } from '@reduxjs/toolkit';
import initialState from '../initialState';
import * as actions from '../actions';
import { ERRORS } from 'Constants';

const appError = createReducer(initialState.appError, {
    [actions.setAppError]: (state, { payload }) => payload,
    [actions.resetAppError]: () => initialState.appError,
    [actions.auth.requestLoginFailure]: () => ERRORS.SERVER
});

export default appError;
