import { put, select, getContext } from 'redux-saga/effects';
import { get, isEmpty, isUndefined } from 'lodash';
import { TRADE_REQUESTS, TRADE_TYPES, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';
import { orderExecutionsSelectors } from 'Store/selectors';
import { prepareInputUint64Value } from 'Utils/uint64';
import { isEmptyDataStatus, isLoadingDataStatus } from 'Utils/statusFilters';

const DEFAULT_EXECUTION_LIST_LIMIT = 40;
const LOAD_MORE_EXECUTIONS_LIMIT = 20;

export const prepareOrderExecutionData = (data) => ({
    ...data,
    executionId: Number(get(data, 'executionId', 0)),
    executionType: get(data, 'executionType', TRADE_TYPES.EXECUTION_TYPE.NEW),
    reasonCode: Number(get(data, 'reasonCode', TRADE_TYPES.ORDER_REASON_CODE.NONE)),

    ...(!isUndefined(data.price) && { price: prepareInputUint64Value(get(data, 'price', 0)) }),
    ...(!isUndefined(data.volume) && { volume: prepareInputUint64Value(get(data, 'volume', 0)) }),
    ...(!isUndefined(data.filledVolume) && { filledVolume: prepareInputUint64Value(get(data, 'filledVolume', 0)) }),
    ...(!isUndefined(data.averagePrice) && { averagePrice: prepareInputUint64Value(get(data, 'averagePrice', 0)) }),

    side: get(data, 'side', TRADE_TYPES.SIDE.BUY),

    profitLoss: prepareInputUint64Value(get(data, 'profitLoss', 0)),
    commission: prepareInputUint64Value(get(data, 'commission', 0)),
    insuranceFee: prepareInputUint64Value(get(data, 'insuranceFee', 0)),
    insurancePayout: prepareInputUint64Value(get(data, 'insurancePayout', 0)),
    swap: prepareInputUint64Value(get(data, 'swap', 0)),

    orderId: Number(get(data, 'orderId', 0)),
    orderStatus: get(data, 'orderStatus', TRADE_TYPES.ORDER_STATUS.NEW),

    orderProfitLoss: prepareInputUint64Value(get(data, 'orderProfitLoss', 0)),
    orderCommission: prepareInputUint64Value(get(data, 'orderCommission', 0)),
    orderInsuranceFee: prepareInputUint64Value(get(data, 'orderInsuranceFee', 0)),
    orderInsurancePayout: prepareInputUint64Value(get(data, 'orderInsurancePayout', 0)),
    orderSwap: prepareInputUint64Value(get(data, 'orderSwap', 0))
});

export function* handleOrderExecution({ body }) {
    const orderExecution = prepareOrderExecutionData(body);
    yield put(actions.trade.gotOrderExecution(orderExecution));
}

function* requestExecutions(params) {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.getOrderExecutionListPending());
        const response = yield tradeService.request(TRADE_REQUESTS.GET_ORDER_EXECUTION_LIST, params);
        const responseExecutions = get(response, 'body.orderExecution', []) || [];
        const executions = responseExecutions.map(prepareOrderExecutionData);
        return executions;
    } catch (err) {
        yield put(actions.trade.getOrderExecutionListFailure(err.message));
    }
}

export function* loadOrderExecutions() {
    try {
        yield put(actions.trade.resetOrderExecutions());

        const executions = yield requestExecutions({ limit: DEFAULT_EXECUTION_LIST_LIMIT });

        if (isEmpty(executions)) {
            yield put(actions.trade.orderExecutionsIsEmpty());
            return;
        }

        yield put(actions.trade.getOrderExecutionListSuccess(executions));
    } catch (err) {
        yield put(actions.trade.getOrderExecutionListFailure(err.message));
    }
}

export function* loadMoreOrderExecutions() {
    try {
        const loadingStatus = yield select(orderExecutionsSelectors.loadingStatus);
        const minValidTime = yield select(orderExecutionsSelectors.minValidTime);

        if (isLoadingDataStatus(loadingStatus) || isEmptyDataStatus(loadingStatus)) {
            return;
        }

        if (!minValidTime) {
            return;
        }

        const executions = yield requestExecutions({
            limit: LOAD_MORE_EXECUTIONS_LIMIT,
            byTime: { toTime: minValidTime }
        });

        if (isEmpty(executions)) {
            yield put(actions.trade.orderExecutionsIsEmpty());
            return;
        }

        yield put(actions.trade.getOrderExecutionListSuccess(executions));
    } catch (err) {
        yield put(actions.trade.getOrderExecutionListFailure(err.message));
    }
}
