import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const quotes = createReducer(initialState.quotes, {
    [actions.market.gotSymbolQuote]: (state, { payload: { symbol, quote } }) => {
        state[symbol] = quote;
        return state;
    }
});

export default quotes;
