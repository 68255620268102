import { put, call, select, getContext } from 'redux-saga/effects';
import { isNil, get, isEmpty, values, isUndefined, isNull } from 'lodash';
import moment from 'moment';
import { TRADE_REQUESTS, TRADE_TYPES, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';
import { prepareOutputUint64Value, prepareInputUint64Value } from 'Utils/uint64';
import {
    subscribeToSymbolQuoteOnGotBet,
    subscribeToSymbolQuoteOnGotActiveBetList,
    unSubscribeFromSymbolQuoteOnResetActiveBets
} from './betsQuoteSubscription.saga';
import logger from 'Utils/logger';
import { isLoadingDataStatus, isEmptyDataStatus, isOpenBetStatus } from 'Utils/statusFilters';
import roundByNumOfDecPlaces from 'Utils/roundByNumOfDecPlaces';

const logError = logger('Error:Saga:Bets:');

const DEFAULT_BET_LIST_LIMIT = 40;
const LOAD_MORE_BETS_LIMIT = 20;

const compose = (...functions) => (arg) => functions.reduce((currentArg, currentFunc) => currentFunc(currentArg), arg);

const addPriceIfDefine = (price) => (object) =>
    !isNil(price) ? { ...object, price: prepareOutputUint64Value(price) } : object;

const addInsuranceConditionIdIfDefine = (insuranceConditionId) => (object) =>
    !isNil(insuranceConditionId) ? { ...object, insuranceConditionId } : object;

const addSlTpIfDefine = (limit, isLimitByVolume, key) => (object) => {
    if (isNil(limit)) {
        return object;
    }

    if (isNil(isLimitByVolume)) {
        throw new Error("Can't add limit to placeBetRequest if isLimitByVolume is undefined");
    }

    if (isLimitByVolume === true) {
        return { ...object, [key]: { byVolume: prepareOutputUint64Value(limit) } };
    }

    return { ...object, [key]: { byPrice: prepareOutputUint64Value(limit) } };
};

const prepareBetData = (data) => ({
    ...data,
    side: get(data, 'side', TRADE_TYPES.SIDE.BUY),
    status: get(data, 'status', TRADE_TYPES.BET_STATUS.PLACED),
    volume: prepareInputUint64Value(get(data, 'volume', 0)),
    commission: prepareInputUint64Value(get(data, 'commission', 0)),
    swap: prepareInputUint64Value(get(data, 'swap', 0)),
    profitLoss: prepareInputUint64Value(get(data, 'profitLoss', 0)),
    insuranceFee: prepareInputUint64Value(get(data, 'insuranceFee', 0)),
    insurancePayout: prepareInputUint64Value(get(data, 'insurancePayout', 0)),
    ...(!isUndefined(data.betId) && { betId: Number(data.betId) }),
    ...(!isUndefined(data.openTime) && { openTime: Number(data.openTime) }),
    ...(!isUndefined(data.placeTime) && { placeTime: Number(data.placeTime) }),
    ...(!isUndefined(data.placePrice) && { placePrice: prepareInputUint64Value(data.placePrice) }),
    ...(!isUndefined(data.openPrice) && { openPrice: prepareInputUint64Value(data.openPrice) }),
    ...(!isUndefined(data.closePrice) && { closePrice: prepareInputUint64Value(data.closePrice) }),
    sl: {
        ...(!isUndefined(data.sl.byPrice) && { byPrice: prepareInputUint64Value(data.sl.byPrice) }),
        ...(!isUndefined(data.sl.byVolume) && { byVolume: prepareInputUint64Value(data.sl.byVolume) })
    },
    tp: {
        ...(!isUndefined(data.tp.byPrice) && { byPrice: prepareInputUint64Value(data.tp.byPrice) }),
        ...(!isUndefined(data.tp.byVolume) && { byVolume: prepareInputUint64Value(data.tp.byVolume) })
    },
    ...(!isUndefined(data.insuranceConditionId) && { insuranceConditionId: Number(data.insuranceConditionId) })
});

export function* placeBetRequest({ payload }) {
    try {
        const {
            symbol,
            side,
            volume,
            multiplier,
            price,
            profitLimit,
            isProfitLimitByVolume,
            lossLimit,
            isLossLimitByVolume,
            insuranceConditionId
        } = payload;

        const clientBetId = payload.clientBetId || new Date().getTime();

        const requestData = {
            clientBetId,
            symbol,
            side,
            volume: prepareOutputUint64Value(volume),
            multiplier
        };

        const resultRequestData = compose(
            addPriceIfDefine(price),
            addSlTpIfDefine(profitLimit, isProfitLimitByVolume, 'tp'),
            addSlTpIfDefine(lossLimit, isLossLimitByVolume, 'sl'),
            addInsuranceConditionIdIfDefine(insuranceConditionId)
        )(requestData);

        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield call([tradeService, tradeService.send], TRADE_REQUESTS.PLACE_BET_REQUEST, resultRequestData);
        yield put(actions.trade.placeBetRequestWasSent(resultRequestData));
    } catch (err) {
        yield put(actions.trade.placeBetRequestFailure(err.message));
    }
}

export function* handleBet({ body }) {
    try {
        const bet = prepareBetData(body);
        yield subscribeToSymbolQuoteOnGotBet(bet);
        yield put(actions.trade.gotBet(bet));
    } catch (err) {
        yield put(actions.trade.handleBetFailure(err.message));
    }
}

function* requestBets(params) {
    const tradeService = yield getContext(SERVICE_NAMES.TRADE);
    const response = yield tradeService.request(TRADE_REQUESTS.GET_BET_LIST, params);
    const responseBets = get(response, 'body.bet', []) || [];
    const bets = responseBets.map(prepareBetData);
    return bets;
}

export function* loadActiveBets() {
    try {
        yield unSubscribeFromSymbolQuoteOnResetActiveBets();
        yield put(actions.trade.resetActiveBets());

        yield put(actions.trade.getBetListPending());

        const bets = yield requestBets({ byDate: { statusFilter: TRADE_TYPES.BET_STATUS_FILTER.UNFINISHED } });
        yield subscribeToSymbolQuoteOnGotActiveBetList(bets);

        yield put(actions.trade.getBetListSuccess(bets));
    } catch (err) {
        logError(err);
        yield put(actions.trade.getBetListFailure(err.message));
    }
}

export function* loadFinishedBets() {
    try {
        yield put(actions.trade.resetFinishedBets());

        yield put(actions.trade.getFinishedBetListPending());

        const bets = yield requestBets({
            byDate: {
                limit: DEFAULT_BET_LIST_LIMIT,
                statusFilter: TRADE_TYPES.BET_STATUS_FILTER.FINISHED
            }
        });

        if (isEmpty(bets)) {
            yield put(actions.trade.finishedBetsIsEmpty());
            return;
        }

        yield put(actions.trade.getFinishedBetListSuccess(bets));
    } catch (err) {
        logError(err);
        yield put(actions.trade.getFinishedBetListFailure(err.message));
    }
}

export function* loadMoreFinishedBets() {
    try {
        const { loadingStatus, finishedBets } = yield select(
            ({ loadingStatuses: { finishedBets: loadingStatus }, finishedBets: { byId: finishedBets } }) => ({
                loadingStatus,
                finishedBets
            })
        );

        if (isLoadingDataStatus(loadingStatus) || isEmptyDataStatus(loadingStatus)) {
            return;
        }

        const oldestBetTime = values(finishedBets).reduce((oldestPlaceTime, bet) => {
            const currentPlaceTime = get(bet, 'placeTime');
            if (!!currentPlaceTime && currentPlaceTime < oldestPlaceTime) {
                return currentPlaceTime;
            }
            return oldestPlaceTime;
        }, moment().add(1, 'day').valueOf());

        yield put(actions.trade.getFinishedBetListPending());

        const bets = yield requestBets({
            byDate: {
                limit: LOAD_MORE_BETS_LIMIT,
                toTime: oldestBetTime - 1,
                statusFilter: TRADE_TYPES.BET_STATUS_FILTER.FINISHED
            }
        });

        if (isEmpty(bets)) {
            yield put(actions.trade.finishedBetsIsEmpty());
            return;
        }

        yield put(actions.trade.getFinishedBetListSuccess(bets));
    } catch (err) {
        logError(err);
        yield put(actions.trade.getFinishedBetListFailure(err.message));
    }
}

export function* closeBet({ payload: betId }) {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.closeBetPending());

        yield tradeService.request(TRADE_REQUESTS.BET_CLOSE, { betId });

        yield put(actions.trade.closeBetSuccess(betId));
    } catch (err) {
        logError(err);
        yield put(actions.trade.closeBetFailure(err.message));
    }
}

export function* cancelBet({ payload: betId }) {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.cancelBetPending());

        yield tradeService.request(TRADE_REQUESTS.BET_CANCEL, { betId });

        yield put(actions.trade.cancelBetSuccess(betId));
    } catch (err) {
        logError(err);
        yield put(actions.trade.cancelBetFailure(err.message));
    }
}

export function* updateBetsProfitLoss() {
    try {
        const { betsById, quotes } = yield select(({ bets, quotes }) => ({ betsById: bets.byId, quotes }));

        const betsPartsForUpdate = values(betsById)
            .filter((bet) => isOpenBetStatus(bet.status))
            .map(({ symbol: symbolName, betId, side, openPrice, volume, multiplier }) => {
                if (isUndefined(openPrice) || openPrice === 0) {
                    return null;
                }

                const quote = quotes[symbolName];

                if (isUndefined(quote)) {
                    return null;
                }

                //  buy: use quote.bid for sell currency
                //  sell: use quote.ask for buy currency
                const currentPrice = side === TRADE_TYPES.SIDE.BUY ? quote.bidPrice : quote.askPrice;

                // buy: profit ~ (currentPrice - openPrice)...
                // sell: profit ~ (openPrice - currentPrice)...
                const directionRatio = side === TRADE_TYPES.SIDE.BUY ? 1 : -1;

                let profitLoss = volume * multiplier * directionRatio * ((currentPrice - openPrice) / openPrice) || 0; // -0 -> 0

                return { betId, profitLoss: roundByNumOfDecPlaces(profitLoss, 2) };
            })
            .filter((v) => !isNull(v));

        if (!isEmpty(betsPartsForUpdate)) {
            yield put(actions.trade.setOpenBetsProfitLoss(betsPartsForUpdate));
        }
    } catch (err) {
        logError('updateOpenBetProfitLoss error: %o', err.message);
    }
}

export function* handleResult({ id, body }) {
    yield put(actions.trade.gotBetsResult({ id, body }));
}
