import { keys, isObject, flattenDeep } from 'lodash';

const checkIsEqualObjKeys = (compared, standard, ancestryPath) => {
    const comparedKeys = keys(compared);
    const standardKeys = keys(standard);
    return comparedKeys
        .map((key) => {
            const keyPath = [ancestryPath, key].join('.');
            if (isObject(compared[key]) && isObject(standard[key])) {
                return checkIsEqualObjKeys(compared[key], standard[key], keyPath);
            }

            if (standardKeys.includes(key) && !isObject(compared[key])) {
                return null;
            }
            return keyPath;
        })
        .filter((k) => k);
};

/**
 * Return true if compared object key set is equal to standard object key set (at all levels of nesting)
 * Otherwise return array of wrong keys, e.g. ['MarketDataRequest.wrong', 'MarketDataRequest.quoteRequest.wrong']
 */
const checkIsEqualObjStructure = (compared, standard) => {
    const result = flattenDeep(checkIsEqualObjKeys(compared, standard, standard.constructor.name));
    if (result.length === 0) {
        return null;
    }
    return result;
};

export default checkIsEqualObjStructure;
