import { put, call, select, getContext } from 'redux-saga/effects';
import { get, isEmpty, values, isUndefined, find, pick } from 'lodash';
import moment from 'moment';
import { TRADE_REQUESTS, TRADE_TYPES, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';
import { prepareInputUint64Value, prepareOutputUint64Value } from 'Utils/uint64';
import { isEmptyDataStatus, isLoadingDataStatus } from 'Utils/statusFilters';

const DEFAULT_ORDER_LIST_LIMIT = 40;
const LOAD_MORE_ORDERS_LIMIT = 20;

const rejectOrderStatuses = new Set([
    TRADE_TYPES.ORDER_STATUS.CANCELED,
    TRADE_TYPES.ORDER_STATUS.REJECTED,
    TRADE_TYPES.ORDER_STATUS.EXPIRED
]);

const prepareOrderData = (data) => ({
    ...data,
    id: Number(get(data, 'id', 0)),
    side: get(data, 'side', TRADE_TYPES.SIDE.BUY),
    volume: prepareInputUint64Value(get(data, 'volume', 0)),
    price: prepareInputUint64Value(get(data, 'price', 0)),
    stopPrice: prepareInputUint64Value(get(data, 'stopPrice', 0)),
    slPrice: prepareInputUint64Value(get(data, 'slPrice', 0)),
    tpPrice: prepareInputUint64Value(get(data, 'tpPrice', 0)),
    orderStatus: get(data, 'orderStatus', TRADE_TYPES.ORDER_STATUS.NEW),
    filledVolume: prepareInputUint64Value(get(data, 'filledVolume', 0)),
    averagePrice: prepareInputUint64Value(get(data, 'averagePrice', 0)),
    reasonCode: get(data, 'reasonCode', TRADE_TYPES.ORDER_REASON_CODE.NONE),
    profitLoss: prepareInputUint64Value(get(data, 'profitLoss', 0)),
    type: get(data, 'type', TRADE_TYPES.ORDER_TYPE.MARKET),
    timeInForce: get(data, 'timeInForce', TRADE_TYPES.ORDER_TIME_IN_FORCE.FOK),
    commission: prepareInputUint64Value(get(data, 'commission', 0)),
    swap: prepareInputUint64Value(get(data, 'swap', 0)),
    insuranceFee: prepareInputUint64Value(get(data, 'insuranceFee', 0)),
    insurancePayout: prepareInputUint64Value(get(data, 'insurancePayout', 0)),
    insuranceConditionId: Number(get(data, 'insuranceConditionId', 0)), // default
    ...(!isUndefined(data.requestTime) && { requestTime: Number(data.requestTime) }),
    ...(!isUndefined(data.expirationDatetime) && { expirationDatetime: Number(data.expirationDatetime) })
});

function* requestOrders(params) {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.getOrderListPending());
        const response = yield tradeService.request(TRADE_REQUESTS.GET_ORDER_LIST, params);
        const responseOrders = get(response, 'body.order', []) || [];
        const orders = responseOrders.map(prepareOrderData);
        return orders;
    } catch (err) {
        yield put(actions.trade.getOrderListFailure(err.message));
    }
}

export function* loadActiveOrders() {
    try {
        yield put(actions.trade.resetOrders());
        const orders = yield requestOrders({ statusType: TRADE_TYPES.ORDER_STATUS_TYPE.UNFINISHED });
        yield put(actions.trade.getOrderListSuccess(orders));
    } catch (err) {
        yield put(actions.trade.getOrderListFailure(err.message));
    }
}

export function* loadFinishedOrders() {
    try {
        yield put(actions.trade.resetOrders());

        const orders = yield requestOrders({
            limit: DEFAULT_ORDER_LIST_LIMIT,
            statusType: TRADE_TYPES.ORDER_STATUS_TYPE.FINISHED
        });

        if (isEmpty(orders)) {
            yield put(actions.trade.ordersIsEmpty());
            return;
        }

        yield put(actions.trade.getOrderListSuccess(orders));
    } catch (err) {
        yield put(actions.trade.getOrderListFailure(err.message));
    }
}

export function* loadMoreFinishedOrders() {
    try {
        const { loadStatus, ordersById } = yield select(
            ({ loadingStatuses: { orders: loadStatus }, orders: { allIds: ordersIds, byId: ordersById } }) => ({
                loadStatus,
                ordersIds,
                ordersById
            })
        );

        if (isLoadingDataStatus(loadStatus) || isEmptyDataStatus(loadStatus)) {
            return;
        }

        const toTime = values(ordersById).reduce((oldestRequestTime, order) => {
            const currentRequestTime = get(order, 'requestTime');
            if (!!currentRequestTime && currentRequestTime < oldestRequestTime) {
                return currentRequestTime;
            }
            return oldestRequestTime;
        }, moment().add(1, 'day').valueOf());

        const orders = yield requestOrders({
            limit: LOAD_MORE_ORDERS_LIMIT,
            byTime: { toTime },
            statusType: TRADE_TYPES.ORDER_STATUS_TYPE.FINISHED
        });

        if (isEmpty(orders)) {
            yield put(actions.trade.ordersIsEmpty());
            return;
        }

        yield put(actions.trade.getOrderListSuccess(orders));
    } catch (err) {
        yield put(actions.trade.getOrderListFailure(err.message));
    }
}

export function* sendOrderCancel({ payload: orderId }) {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.cancelOrderPending(orderId));
        const { body } = yield tradeService.request(TRADE_REQUESTS.ORDER_CANCEL, { orderId });
        yield put(actions.trade.cancelOrderSuccess({ ...body, orderId }));
    } catch (err) {
        yield put(actions.trade.cancelOrderFailure(orderId));
    }
}

export function* sendOrderRequest({ payload }) {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        const {
            symbol,
            positionId = 0,
            side,
            volumeInLots,
            type,
            insuranceConditionId = 0,
            slPrice = 0,
            tpPrice = 0,
            timeInForce = TRADE_TYPES.ORDER_TIME_IN_FORCE.FOK,
            price = 0,
            stopPrice = 0,
            expirationDatetime = 0
        } = payload;

        const symbols = yield select(({ symbols: { byId: symbols } }) => symbols);

        const clientId = payload.clientId || new Date().getTime();
        const { lotSize } = symbols[symbol];

        if (!lotSize) {
            throw new Error(`Can't find lotSize for symbol: ${symbol}`);
        }

        const order = {
            clientId,
            symbol,
            positionId,
            side,
            volume: prepareOutputUint64Value(volumeInLots * lotSize),
            slPrice: prepareOutputUint64Value(slPrice),
            tpPrice: prepareOutputUint64Value(tpPrice),
            type,
            timeInForce,
            insuranceConditionId,
            price: prepareOutputUint64Value(price),
            stopPrice: prepareOutputUint64Value(stopPrice),
            expirationDatetime
        };

        yield call([tradeService, tradeService.send], TRADE_REQUESTS.ORDER_REQUEST, order);

        yield put(
            actions.trade.orderRequestWasSent(
                pick(['clientId', 'symbol', 'positionId', 'side', 'volume', 'type'], order)
            )
        );
    } catch (err) {
        yield put(actions.trade.orderRequestFailure(err.message));
    }
}

export function* handleOrder({ body }) {
    const order = prepareOrderData(body);
    yield put(actions.trade.gotOrder(order));
    yield removecOnClosingFromPositionWithSameClosingClientIdIfNeed(order);
}

function* removecOnClosingFromPositionWithSameClosingClientIdIfNeed({ clientId, orderStatus }) {
    if (!rejectOrderStatuses.has(orderStatus)) {
        return;
    }

    const positions = yield select(({ positions: { byKey: positions } }) => positions);
    const position = find(values(positions), (p) => p.closingClientId === clientId);

    if (position && position.onClosing) {
        yield put(
            actions.trade.updatePosition({
                ...position,
                onClosing: false,
                closingClientId: null
            })
        );
    }
}

export function* handleResult({ id, body }) {
    yield put(actions.trade.gotMarginTradingResult({ id, body }));
}
