import { createReducer } from '@reduxjs/toolkit';
import { keyBy, keys } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';

const symbols = createReducer(initialState.symbols, {
    [actions.market.setSymbolList]: (state, { payload: { symbols, tradableIds } }) => {
        const allIds = symbols.map((s) => s.symbol);
        const byId = keyBy(symbols, (s) => s.symbol);
        return { byId, allIds, tradableIds };
    },
    [actions.market.updateSymbolList]: (state, { payload: updates }) => {
        const { byId } = state;
        const updatedById = keys(updates).reduce(
            (acc, symbol) => ({ ...acc, [symbol]: { ...byId[symbol], ...updates[symbol] } }),
            {}
        );

        return { ...state, byId: { ...byId, ...updatedById } };
    },
    [actions.market.WSConnectionClose]: () => initialState.symbols
});

export default symbols;
