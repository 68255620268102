import { isUndefined } from 'lodash';
import WebSocketService from './WebSocketService';
import { feedbox } from 'protocol';
import {
    MARKET_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL,
    MARKET_REQUESTS,
    MARKET_RESULTS,
    MARKET_ACTIONS,
    MARKET_TYPES,
    EVENTS
} from 'Constants';

const SERVICE_NAME = 'MARKET';

class MarketWebSocketService extends WebSocketService {
    constructor(customOptions = {}) {
        super({
            url: '',
            name: SERVICE_NAME,
            protocolVersion: MARKET_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL,
            protocolEncoder: feedbox.market_api.MarketDataRequest,
            protocolDecoder: feedbox.market_api.MarketData,
            shouldCheckHealthStatus: true,
            checkHealthRequestType: MARKET_REQUESTS.PING,
            checkHealthRequestData: {},
            checkHealthEventOnProblem: EVENTS.MARKET.PROBLEM,
            checkHealthEventOnNormal: EVENTS.MARKET.NORMAL,
            shouldUseWSCutout: true,
            ...customOptions
        });

        this._lastSubscribeRequestId = 0;
    }

    requestSubscribe = (method, data) => {
        const subscriptionId = ++this._lastSubscribeRequestId;

        const rawData = {
            subscriptionId,
            [method]: data,
            action: MARKET_ACTIONS.SUBSCRIBE
        };

        this._log('SUBSCRIBE REQUEST:#%o, %o:', rawData.subscriptionId, method);

        return this.request(MARKET_REQUESTS.SUBSCRIPTION_REQUEST, rawData).then((response) => ({
            ...response,
            subscriptionId
        }));
    };

    requestUnsubscribe = (method, subscriptionId) => {
        const rawData = {
            subscriptionId,
            [method]: {},
            action: MARKET_ACTIONS.UNSUBSCRIBE
        };

        this._log('UNSUBSCRIBE REQUEST:#%o, %o:', subscriptionId, method);

        return this.request(MARKET_REQUESTS.SUBSCRIPTION_REQUEST, rawData);
    };

    _onmessage = (evt) => {
        try {
            const message = this._decodeIncomingData(evt.data);
            const messageKey = message.body;

            if (messageKey === MARKET_RESULTS.SUBSCRIPTION_RESULT) {
                this._handleMessageBySubscription(message);
                return;
            }

            this._log('MESSAGE:#%o:%o %O', message.id, this._getMessageLogName(message), message[messageKey]);

            const currentMessagePromise = this._promisesMap.get(message.id);
            this._promisesMap.delete(message.id);

            const messageHandled = this._decoder.toObject(message);

            this.emit(messageKey, { id: messageHandled.id, body: messageHandled[messageKey] });

            if (!currentMessagePromise) {
                return;
            }

            if (
                messageKey === MARKET_RESULTS.RESULT &&
                !isUndefined(messageHandled[messageKey].code) &&
                messageHandled[messageKey].code !== MARKET_TYPES.RESULT_CODES.SUCCESS
            ) {
                currentMessagePromise.reject(new Error(messageHandled[messageKey].text));
                return;
            }

            currentMessagePromise.resolve({ id: messageHandled.id, body: messageHandled[messageKey] });
        } catch (err) {
            this._onerror(err);
        }
    };

    _handleMessageBySubscription = (message) => {
        const { body: messageKey } = message[MARKET_RESULTS.SUBSCRIPTION_RESULT];

        const messageHandled = this._decoder.toObject(message);
        const body = messageHandled[MARKET_RESULTS.SUBSCRIPTION_RESULT];
        this.emit(messageKey, body);
    };
}

export default MarketWebSocketService;
