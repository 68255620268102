import { defaultsDeep, isNil, isString } from 'lodash';
import isEmptyString from 'Utils/isEmptyString';
import logger from 'Utils/logger';

const isValidKey = (key) => isString(key) && !isEmptyString(key.trim());

const defaults = {
    name: 'Storage',
    logger: logger,
    localStorage: localStorage
};

class StorageService {
    constructor(options) {
        const config = defaultsDeep(options, defaults);

        this._name = config.name;
        this._log = config.logger(`Service:${this._name}`);
        this._logError = config.logger(`Error:Service:${this._name}`);
        this._localStorage = config.localStorage;
    }

    has(key) {
        if (!isValidKey(key)) {
            this._logError(`Key: "%o" is not valid. Can't check if it exists. Return false`, key);
            return false;
        }

        const rawData = this._localStorage.getItem(key);

        return !isNil(rawData);
    }

    get(key) {
        if (!this.has(key)) {
            return null;
        }

        const rawData = this._localStorage.getItem(key);

        try {
            return JSON.parse(rawData);
        } catch (err) {
            this._logError(err);
            this.remove(key);
            return null;
        }
    }

    set(key, value) {
        if (!isValidKey(key)) {
            this._logError(`Key: "%o" is not valid. Can't set value: %o. Return`);
            return;
        }

        try {
            const serialized = JSON.stringify(value);
            this._localStorage.setItem(key, serialized);
        } catch (err) {
            this._logError(err);
        }
    }

    remove = (key) => {
        if (!isValidKey(key)) {
            this._logError(`Key: "%o" is not valid. Can't remove it. Return`);
            return;
        }

        if (!this.has(key)) {
            return;
        }

        this._localStorage.removeItem(key);
    };
}

export default StorageService;
