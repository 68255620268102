import * as actions from 'Store/actions';
import { CONNECTION_STATUSES } from 'Constants';

const actionHandlers = {
    [actions.trade.WSConnectionOpen]: (store, next) => {
        const state = store.getState();
        if (state.connectionsStatuses.market === CONNECTION_STATUSES.OPEN) {
            next(actions.bothMarketAndTradeWSConnectionsOpen());
        }
    },
    [actions.market.WSConnectionOpen]: (store, next) => {
        const state = store.getState();
        if (state.connectionsStatuses.trade === CONNECTION_STATUSES.OPEN) {
            next(actions.bothMarketAndTradeWSConnectionsOpen());
        }
    },
    [actions.trade.WSConnectionClose]: (store, next) => next(actions.oneOfMarketOrTradeWSConnectionsClose()),
    [actions.market.WSConnectionClose]: (store, next) => next(actions.oneOfMarketOrTradeWSConnectionsClose())
};

const bothMarketAndTradingServiceStatusesMiddleware = (store) => (next) => (action) => {
    next(action);
    actionHandlers[action.type] && actionHandlers[action.type](store, next);
};

export default bothMarketAndTradingServiceStatusesMiddleware;
