import { TRADE_TYPES } from 'Constants';

export const TRANSLATION_KEYS = {
    SERVER_MESSAGE: 'app:Toast.serverMessage',
    INCORRECT_ORDER_DATA: 'app:Toast.incorrectOrderData',
    INCORRECT_BET_DATA: 'app:Toast.incorrectBetData',
    ORDER_HAS_BEEN_CREATED: 'app:Toast.orderHasBeenCreated',
    ORDER_STATUS_HAS_BEEN_CHANGED: 'app:Toast.orderStatusHasBeenChanged',
    ORDER_STATUS: {
        [TRADE_TYPES.ORDER_STATUS.NEW]: 'app:OperationsWidget.table.tableCell.orders.status.new',
        [TRADE_TYPES.ORDER_STATUS.PARTIALLY_FILLED]:
            'app:OperationsWidget.table.tableCell.orders.status.partiallyFilled',
        [TRADE_TYPES.ORDER_STATUS.FILLED]: 'app:OperationsWidget.table.tableCell.orders.status.filled',
        [TRADE_TYPES.ORDER_STATUS.CANCELED]: 'app:OperationsWidget.table.tableCell.orders.status.canceled',
        [TRADE_TYPES.ORDER_STATUS.REJECTED]: 'app:OperationsWidget.table.tableCell.orders.status.rejected',
        [TRADE_TYPES.ORDER_STATUS.EXPIRED]: 'app:OperationsWidget.table.tableCell.orders.status.expired',
        [TRADE_TYPES.ORDER_STATUS.PENDING_NEW]: 'app:OperationsWidget.table.tableCell.orders.status.pendingNew'
    },
    POSITION_HAS_BEEN_CHANGED: 'app:Toast.positionHasBeenChanged'
};
