import { feedbox } from 'protocol';
import { news } from 'news-protocol';

export const INT64_PROTOCOL_EXPONENT = 1e8;

const PROTOCOL_SECTIONS = {
    market: feedbox.market_api,
    trade: feedbox.trade_api,
    news: news,
    technic: feedbox.technic_api
};

const getProtocolVersion = (section) => {
    const version = section.ProtocolVersion;

    return {
        major: version.MAJOR,
        minor: version.MINOR,
        patch: version.PATCH
    };
};

const toStringProtocolVersion = (protocolVersionObj) => {
    const { major, minor, patch } = protocolVersionObj;
    return `${major}.${minor}.${patch}`;
};

export const MARKET_PROTOCOL_VERSION_OBJECT = getProtocolVersion(PROTOCOL_SECTIONS.market);
export const TRADE_PROTOCOL_VERSION_OBJECT = getProtocolVersion(PROTOCOL_SECTIONS.trade);
export const NEWS_PROTOCOL_VERSION_OBJECT = getProtocolVersion(PROTOCOL_SECTIONS.news);
export const TECHNIC_PROTOCOL_VERSION_OBJECT = getProtocolVersion(PROTOCOL_SECTIONS.technic);

export const MARKET_VERSION_STRING = toStringProtocolVersion(MARKET_PROTOCOL_VERSION_OBJECT);
export const TRADE_VERSION_STRING = toStringProtocolVersion(TRADE_PROTOCOL_VERSION_OBJECT);
export const NEWS_VERSION_STRING = toStringProtocolVersion(NEWS_PROTOCOL_VERSION_OBJECT);
export const TECHNIC_VERSION_STRING = toStringProtocolVersion(TECHNIC_PROTOCOL_VERSION_OBJECT);

export const MARKET_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL = `feedbox-market-${MARKET_VERSION_STRING}`;
export const TRADE_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL = `feedbox-trade-${TRADE_VERSION_STRING}`;
export const NEWS_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL = `feedbox-news-${NEWS_VERSION_STRING}`;
export const TECHNIC_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL = `feedbox-technic-${TECHNIC_VERSION_STRING}`;

export const MARKET_REQUESTS = {
    SUBSCRIPTION_REQUEST: 'subscriptionRequest',
    CHART_BAR_LIST_REQUEST: 'chartBarListRequest',
    QUOTE_REQUEST: 'quoteRequest',
    ORDER_BOOK_REQUEST: 'orderBookRequest',
    CHART_BAR_REQUEST: 'chartBarRequest',
    SYMBOL_LIST_REQUEST: 'symbolListRequest',
    PING: 'ping',
    GET_SERVER_TIME: 'getServerTime'
};

export const MARKET_RESULTS = {
    RESULT: 'result',
    SUBSCRIPTION_RESULT: 'subscriptionResult',
    CHART_BAR_LIST: 'chartBarList'
};

export const MARKET_TYPES = {
    QUOTE_TYPE: {
        BEST: feedbox.market_api.QuoteRequest.QuoteType.BEST,
        VWAP: feedbox.market_api.QuoteRequest.QuoteType.VWAP
    },
    PERIOD_TYPE: {
        M1: feedbox.market_api.ChartBarPeriodType.M1,
        M5: feedbox.market_api.ChartBarPeriodType.M5,
        M15: feedbox.market_api.ChartBarPeriodType.M15,
        M30: feedbox.market_api.ChartBarPeriodType.M30,
        // hour
        H1: feedbox.market_api.ChartBarPeriodType.H1,
        H4: feedbox.market_api.ChartBarPeriodType.H4,
        // day
        D1: feedbox.market_api.ChartBarPeriodType.D1,
        // week
        W1: feedbox.market_api.ChartBarPeriodType.W1,
        // month
        MN: feedbox.market_api.ChartBarPeriodType.MN
    },
    CALCULATION_TYPE: {
        UNKNOWN: feedbox.market_api.Symbol.CalculationType.UNKNOWN,
        FOREX: feedbox.market_api.Symbol.CalculationType.FOREX,
        CFD: feedbox.market_api.Symbol.CalculationType.CFD,
        CFD_LEVERAGE: feedbox.market_api.Symbol.CalculationType.CFD_LEVERAGE
    },
    RESULT_CODES: {
        SUCCESS: feedbox.market_api.Result.Code.SUCCESS,
        COMMON_ERROR: feedbox.market_api.Result.Code.COMMON_ERROR,
        DUPLICATE_REQUEST_ID: feedbox.market_api.Result.Code.DUPLICATE_REQUEST_ID,
        DUPLICATE_SUBSCRIPTION_ID: feedbox.market_api.Result.Code.DUPLICATE_SUBSCRIPTION_ID,
        FIELD_IS_NOT_SET: feedbox.market_api.Result.Code.FIELD_IS_NOT_SET,
        INVALID_FIELD_VALUE: feedbox.market_api.Result.CodeINVALID_FIELD_VALUE
    },
    SYMBOL_TRADING_TYPE: {
        MARGIN_TRADING: feedbox.market_api.Symbol.TradingType.MARGIN_TRADING,
        CURRENCY_EXCHANGE: feedbox.market_api.Symbol.TradingType.CURRENCY_EXCHANGE,
        BET_TRADING: feedbox.market_api.Symbol.TradingType.BET_TRADING
    }
};

export const MARKET_ACTIONS = {
    SUBSCRIBE: feedbox.market_api.SubscriptionRequest.Action.SUBSCRIBE,
    UNSUBSCRIBE: feedbox.market_api.SubscriptionRequest.Action.UNSUBSCRIBE
};

export const TRADE_REQUESTS = {
    ORDER_REQUEST: 'orderRequest',
    ORDER_CANCEL: 'orderCancel',
    GET_ORDER_LIST: 'getOrderList',
    GET_ORDER_EXECUTION_LIST: 'getOrderExecutionList',
    GET_POSITION_LIST: 'getPositionList',
    GET_CUSTOMER_INFO: 'getCustomerInfo',
    GET_BALANCES_BY_DATE: 'getBalancesByDate',
    GET_TOTAL_AMOUNTS: 'getTotalAmounts',
    PLACE_BET_REQUEST: 'placeBet',
    GET_BET_LIST: 'getBetList',
    BET_CANCEL: 'cancelBet',
    BET_CLOSE: 'closeBet',
    PING: 'ping',
    GET_SERVER_TIME: 'getServerTime',
    GET_COMMISSION: 'getCommission',
    GET_BET_COMMISSION: 'getBetCommission',
    GET_CLOSE_BET_COMMISSION: 'getCloseBetCommission',
    UPDATE_POSITION: 'updatePosition',
    GET_CURRENCY_LIST: 'getCurrencyList',
    GET_INSURANCE_CONDITION_LIST: 'getInsuranceConditionList'
};

export const TRADE_TYPES = {
    SIDE: {
        BUY: feedbox.trade_api.Side.BUY,
        SELL: feedbox.trade_api.Side.SELL
    },
    ORDER_STATUS: {
        NEW: feedbox.trade_api.OrderStatus.O_NEW,
        PARTIALLY_FILLED: feedbox.trade_api.OrderStatus.O_PARTIALLY_FILLED,
        FILLED: feedbox.trade_api.OrderStatus.O_FILLED,
        CANCELED: feedbox.trade_api.OrderStatus.O_CANCELED,
        REJECTED: feedbox.trade_api.OrderStatus.O_REJECTED,
        EXPIRED: feedbox.trade_api.OrderStatus.O_EXPIRED,
        PENDING_NEW: feedbox.trade_api.OrderStatus.O_PENDING_NEW
    },
    ORDER_REASON_CODE: {
        NONE: feedbox.trade_api.OrderReasonCode.ORC_NONE,
        SERVER_ERROR: feedbox.trade_api.OrderReasonCode.ORC_SERVER_ERROR,
        NON_TRADING_TIME: feedbox.trade_api.OrderReasonCode.ORC_NON_TRADING_TIME,
        ORDER_EXPIRED: feedbox.trade_api.OrderReasonCode.ORC_ORDER_EXPIRED,
        NOT_ENOUGH_MARGIN: feedbox.trade_api.OrderReasonCode.ORC_NOT_ENOUGH_MARGIN,
        NOT_ENOUGH_DEPTH: feedbox.trade_api.OrderReasonCode.ORC_NOT_ENOUGH_DEPTH,
        INVALID_PRICE: feedbox.trade_api.OrderReasonCode.ORC_INVALID_PRICE,
        INVALID_STOP_PRICE: feedbox.trade_api.OrderReasonCode.ORC_INVALID_STOP_PRICE,
        INVALID_VOLUME: feedbox.trade_api.OrderReasonCode.ORC_INVALID_VOLUME,
        REJECT: feedbox.trade_api.OrderReasonCode.ORC_REJECT,
        NO_QUOTES: feedbox.trade_api.OrderReasonCode.ORC_NO_QUOTES,
        INVALID_SYMBOL: feedbox.trade_api.OrderReasonCode.ORC_INVALID_SYMBOL,
        CURRENCY_CONVERSION_ERROR: feedbox.trade_api.OrderReasonCode.ORC_CURRENCY_CONVERSION_ERROR,
        INVALID_EXPIRATION_DATE: feedbox.trade_api.OrderReasonCode.ORC_INVALID_EXPIRATION_DATE,
        INVALID_REQUEST: feedbox.trade_api.OrderReasonCode.ORC_INVALID_REQUEST,
        CANCEL: feedbox.trade_api.OrderReasonCode.ORC_CANCEL
    },
    ORDER_TYPE: {
        MARKET: feedbox.trade_api.OrderType.O_MARKET,
        LIMIT: feedbox.trade_api.OrderType.O_LIMIT,
        STOP: feedbox.trade_api.OrderType.O_STOP,
        STOP_LIMIT: feedbox.trade_api.OrderType.O_STOP_LIMIT
    },
    ORDER_STATUS_TYPE: {
        ALL: feedbox.trade_api.GetOrderList.OrderStatusType.ALL,
        FINISHED: feedbox.trade_api.GetOrderList.OrderStatusType.FINISHED,
        UNFINISHED: feedbox.trade_api.GetOrderList.OrderStatusType.UNFINISHED
    },
    ORDER_TIME_IN_FORCE: {
        FOK: feedbox.trade_api.OrderTimeInForce.FOK,
        DAY: feedbox.trade_api.OrderTimeInForce.DAY,
        GTD: feedbox.trade_api.OrderTimeInForce.GTD,
        GTC: feedbox.trade_api.OrderTimeInForce.GTC,
        IOC: feedbox.trade_api.OrderTimeInForce.IOC
    },
    EXECUTION_TYPE: {
        NEW: feedbox.trade_api.OrderExecution.ExecutionType.E_NEW,
        CONFIRM: feedbox.trade_api.OrderExecution.ExecutionType.E_CONFIRM,
        REJECT: feedbox.trade_api.OrderExecution.ExecutionType.E_REJECT
    },
    BALANCE_TYPE: {
        BALANCE: feedbox.trade_api.Balance.BalanceType.BALANCE,
        COMMISSION_FROM_DEALS: feedbox.trade_api.Balance.BalanceType.COMMISSION_FROM_DEALS,
        COMMISSION_FROM_ADMIN: feedbox.trade_api.Balance.BalanceType.COMMISSION_FROM_ADMIN,
        TRADE_RESULT: feedbox.trade_api.Balance.BalanceType.TRADE_RESULT,
        CREDIT: feedbox.trade_api.Balance.BalanceType.CREDIT,
        DIVIDEND: feedbox.trade_api.Balance.BalanceType.DIVIDEND,
        SWAP: feedbox.trade_api.Balance.BalanceType.SWAP,
        INSURANCE_FEE: feedbox.trade_api.Balance.BalanceType.INSURANCE_FEE,
        INSURANCE_PAYOUT: feedbox.trade_api.Balance.BalanceType.INSURANCE_PAYOUT,
        BONUS: feedbox.trade_api.Balance.BalanceType.BONUS
    },
    BET_STATUS: {
        PLACED: feedbox.trade_api.Bet.Status.PLACED,
        OPENED: feedbox.trade_api.Bet.Status.OPENED,
        CLOSED: feedbox.trade_api.Bet.Status.CLOSED,
        CANCELED: feedbox.trade_api.Bet.Status.CANCELED
    },
    BET_STATUS_FILTER: {
        ALL: feedbox.trade_api.GetBetList.ByDate.StatusFilter.ALL,
        FINISHED: feedbox.trade_api.GetBetList.ByDate.StatusFilter.FINISHED,
        UNFINISHED: feedbox.trade_api.GetBetList.ByDate.StatusFilter.UNFINISHED
    },
    COMMISSION_PERIOD_TYPE: {
        NONE: feedbox.trade_api.CommissionConfig.PeriodType.NONE,
        DAILY: feedbox.trade_api.CommissionConfig.PeriodType.DAILY,
        MONTHLY: feedbox.trade_api.CommissionConfig.PeriodType.MONTHLY,
        INSTANT: feedbox.trade_api.CommissionConfig.PeriodType.INSTANT
    },
    COMMISSION_CHARGE_CONDITION: {
        OPENING_OR_CLOSING_POSITION: feedbox.trade_api.CommissionConfig.ChargeCondition.OPENING_OR_CLOSING_POSITION,
        OPENING_POSITION: feedbox.trade_api.CommissionConfig.ChargeCondition.OPENING_POSITION,
        CLOSING_POSITION: feedbox.trade_api.CommissionConfig.ChargeCondition.CLOSING_POSITION
    }
};

export const NEWS_REQUESTS = {
    SUBSCRIBE: 'subscribe',
    UNSUBSCRIBE: 'unsubscribe',
    NEWS_REQUEST: 'newsRequest',
    GET_NEWS: 'getNews'
};

export const NEWS_TYPES = {
    RESULT_CODES: {
        SUCCESS: news.Result.Code.SUCCESS,
        INVALID_MESSAGE: news.Result.Code.INVALID_MESSAGE,
        COMMON_ERROR: news.Result.Code.COMMON_ERROR
    }
};

export const TECHNIC_REQUESTS = {
    GET_SETTINGS: 'getSettings',
    STORE_SETTINGS: 'storeSettings',
    PING: 'ping'
};

export const TECHNIC_TYPES = {
    THEME: {
        LIGHT: feedbox.technic_api.Settings.WebTerminal.WebTerminalTheme.LIGHT,
        DARK: feedbox.technic_api.Settings.WebTerminal.WebTerminalTheme.DARK
    }
};
