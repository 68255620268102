import { put, getContext } from 'redux-saga/effects';
import { get, isEmpty } from 'lodash';
import { TRADE_REQUESTS, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';

const prepareInsuranceConditionData = (data) => ({
    ...data,
    insuranceConditionId: Number(data.insuranceConditionId),
    deleted: Boolean(data.deleted)
});

export function* requestInsuranceConditionList() {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.getInsuranceConditionListPending());
        yield tradeService.request(TRADE_REQUESTS.GET_INSURANCE_CONDITION_LIST, {});
    } catch (err) {
        yield put(actions.trade.getInsuranceConditionListFailure(err.message));
    }
}

export function* handleInsuranceConditionList({ body }) {
    try {
        const insuranceConditions = get(body, 'insuranceCondition', []).map(prepareInsuranceConditionData);
        yield put(actions.trade.getInsuranceConditionListSuccess(insuranceConditions));
    } catch (err) {
        yield put(actions.trade.getInsuranceConditionListFailure(err.message));
    }
}

export function* handleInsuranceCondition({ body }) {
    try {
        if (isEmpty(body)) {
            return;
        }

        yield put(actions.trade.gotInsuranceCondition(prepareInsuranceConditionData(body)));
    } catch (err) {
        yield put(actions.trade.getInsuranceConditionListFailure(err.message));
    }
}
