import { createReducer } from '@reduxjs/toolkit';
import { keyBy, uniq, concat } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';

const news = createReducer(initialState.news, {
    [actions.news.resetNews]: () => initialState.news,
    [actions.news.getNewsSuccess]: ({ allIds, byId }, { payload: news }) => {
        const newAllIds = news.map((b) => b.id);
        const newById = keyBy(news, (b) => b.id);
        const joinedByIds = { ...byId, ...newById };
        const joinedAllIds = uniq(concat(allIds, newAllIds));
        return { byId: joinedByIds, allIds: joinedAllIds };
    },
    [actions.news.gotNews]: ({ allIds, byId }, { payload: message }) => {
        return {
            byId: { ...byId, [message.id]: message },
            allIds: uniq(concat(message.id, allIds))
        };
    },
    [actions.news.getNewsItemSuccess]: ({ allIds, byId }, { payload: news }) => {
        return {
            byId: { ...byId, [news.id]: news },
            allIds
        };
    }
});

export default news;
