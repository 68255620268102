import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import set from 'lodash/set';

import elementClosest from 'element-closest';
import '../scss/main.scss';
import 'Utils/siteNamespace';

import { languageService } from './i18n';
import initTabbingControl from 'Utils/initTabbingControl';
import AuthService from 'Services/auth/AuthService';
import MarketWebSocketService from 'Services/websocket/MarketWebSocketService';
import TradeWebSocketService from 'Services/websocket/TradeWebSocketService';
import NewsWebSocketService from 'Services/websocket/NewsWebSocketService';
import TechnicWebSocketService from 'Services/websocket/TechnicWebSocketService';
import StorageService from 'Services/storage/StorageService';
import ThemeService from 'Services/theme/ThemeService';
import AppRoot from './AppRoot';
import AppError from 'Components/AppError/UnauthenticatedAppError';
import initStore from 'Store';
import { setAppError, auth } from 'Store/actions';
import logger, { init as initLogger } from 'Utils/logger';
import getTradingMode from 'Utils/getTradingMode';
import { ERRORS, getWebSocketRoutes, SERVICE_NAMES } from 'Constants';
import { getLanguageSettings, getThemeSettings, requestToken, getIsEmbeddedSetting } from './embeddedHelpers';

elementClosest(window);

initTabbingControl();

initLogger();

const log = logger('Init');

const appRootTag = document.getElementById('app-root');

const handleAuthCheck = (Component) => {
    ReactDOM.render(Component, appRootTag);
};

const loadConfig = () =>
    fetch('/config.json', { method: 'GET', mode: 'cors', credentials: 'include', cache: 'no-cache' }).then((response) =>
        response.json()
    );

const CatchErrorRoot = ({ store }) => {
    return (
        <Provider store={store}>
            <AppError name={ERRORS.SERVER} />
        </Provider>
    );
};

let store;

Promise.all([loadConfig(), requestToken(), getLanguageSettings(languageService)])
    .then(([config, oauthToken, languageSettings]) => {
        const routes = getWebSocketRoutes(config.API_DOMAIN);

        const themeSettings = getThemeSettings();
        const isEmbeddedSetting = getIsEmbeddedSetting();

        const currentTradingMode = getTradingMode(config);

        const authService = new AuthService({ authApiDomain: config.API_DOMAIN, logger });
        const themeService = new ThemeService({ logger });

        const services = {
            [SERVICE_NAMES.AUTH]: authService,
            [SERVICE_NAMES.MARKET]: new MarketWebSocketService({ url: routes(SERVICE_NAMES.MARKET) }),
            [SERVICE_NAMES.TRADE]: new TradeWebSocketService({ url: routes(SERVICE_NAMES.TRADE) }),
            [SERVICE_NAMES.NEWS]: new NewsWebSocketService({ url: routes(SERVICE_NAMES.NEWS) }),
            [SERVICE_NAMES.TECHNIC]: new TechnicWebSocketService({ url: routes(SERVICE_NAMES.TECHNIC) }),
            [SERVICE_NAMES.STORAGE]: new StorageService(),
            [SERVICE_NAMES.LANGUAGE]: languageService,
            [SERVICE_NAMES.THEME]: themeService
        };

        const preloadedState = {
            tradingMode: currentTradingMode,
            recaptchaKey: config.GOOGLE_RECAPTCHA_KEY,
            currentLocale: languageSettings.locale,
            isDisabledServerSyncLanguage: languageSettings.hasEmbeddedLanguageParam,
            isEmbedded: isEmbeddedSetting
        };

        if (themeSettings.hasEmbeddedThemeParam) {
            themeService.setCurrentTheme(themeSettings.theme);

            set(preloadedState, 'theme', themeSettings.theme);
            set(preloadedState, 'isDisabledServerSyncTheme', themeSettings.hasEmbeddedThemeParam);
        }

        store = initStore(services, preloadedState);

        if (oauthToken) {
            log('Try to login by token');
            return authService
                .loginByToken({ token: oauthToken })
                .then(({ isAuthenticated }) => {
                    store.dispatch(auth.requestLoginSuccess({ isAuthenticated }));

                    return isAuthenticated;
                })
                .catch((err) => {
                    log('Login by token error: %o', err);
                    return false;
                });
        }

        return false;
    })
    .then((hasAuthenticatedYet) => {
        if (hasAuthenticatedYet) {
            return true;
        }

        log('Start to check is the app authenticated');
        return store.dispatch(auth.requestRefresh());
    })
    .then(() => {
        log('Check - DONE');
        handleAuthCheck(<AppRoot store={store} />);
    })
    .catch((err) => {
        const catchLocalStore = store ? store : { dispatch: () => {}, getState: () => ({}), subscribe: () => {} };
        catchLocalStore.dispatch(setAppError(ERRORS.SERVER));
        handleAuthCheck(<CatchErrorRoot store={catchLocalStore} />);
        log('Got error: %o', err);
    });
