import { combineReducers, createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';
import isAuthenticated from './isAuthenticated';
import connectionsStatuses from './connectionsStatuses';
import isMainMenuOpened from './isMainMenuOpened';
import theme from './theme';
import isProVersion from './isProVersion';
import loadingStatuses from './loadingStatuses';
import appError from './appError';
import symbols from './symbols';
import symbolGroups from './symbolGroups';
import quotes from './quotes';
import chartBars from './chartBars';
import chartTabs from './chartTabs';
import customerInfo from './customerInfo';
import orders from './orders';
import orderExecutions from './orderExecutions';
import positions from './positions';
import totalAmounts from './totalAmounts';
import balances from './balances';
import news from './news';
import bets from './bets';
import finishedBets from './finishedBets';
import favorites from './favorites';
import currentLocale from './currentLocale';
import operationsTabs from './operationsTabs';
import timeCorrection from './timeCorrection';
import connectionsHealth from './connectionsHealth';
import commission from './commission';
import tradingFormMode from './tradingFormMode';
import insuranceConditions from './insuranceConditions';
import currencies from './currencies';
import symbolWidget from '../slices/symbolWidget';

const appReducer = combineReducers({
    tradingMode: createReducer(initialState.tradingMode, {}), // immutable configuration parameter
    recaptchaKey: createReducer(initialState.recaptchaKey, {}), // immutable configuration parameter
    isDisabledServerSyncLanguage: createReducer(initialState.isDisabledServerSyncLanguage, {}), // immutable configuration parameter
    isDisabledServerSyncTheme: createReducer(initialState.isDisabledServerSyncTheme, {}), // immutable configuration parameter
    isEmbedded: createReducer(initialState.isEmbedded, {}), // immutable configuration parameter
    isAuthenticated,
    isMainMenuOpened,
    isProVersion,
    appError,
    theme,
    currentLocale,
    tradingFormMode,
    timeCorrection,
    ui: combineReducers({
        symbolWidget: symbolWidget.reducer
    }),
    connectionsStatuses,
    connectionsHealth,
    loadingStatuses,
    symbols,
    symbolGroups,
    favorites,
    quotes,
    chartBars,
    chartTabs,
    customerInfo,
    orders,
    orderExecutions,
    positions,
    totalAmounts,
    currencies,
    balances,
    news,
    bets,
    finishedBets,
    operationsTabs,
    commission,
    insuranceConditions
});

/**
 * Root reducer for resetting the store
 * Credits to Dan Abramov: https://stackoverflow.com/a/35641992
 **/
const rootReducer = (state, action) => {
    if (action.type === actions.auth.requestLogoutSuccess.toString()) {
        const { theme, tradingMode, recaptchaKey, currentLocale } = state;
        state = {
            theme,
            currentLocale,
            tradingMode,
            recaptchaKey
        };
    }

    return appReducer(state, action);
};

export default rootReducer;
