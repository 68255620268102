import { put, getContext, select } from 'redux-saga/effects';
import { get, isUndefined, pick, set, isEmpty } from 'lodash';
import * as actions from 'Store/actions';
import { TRADE_TYPES, SERVICE_NAMES, TRADE_REQUESTS, COMMISSION_REQUEST_TYPE } from 'Constants';
import { prepareInputUint64Value, prepareOutputUint64Value } from 'Utils/uint64';
import roundByNumOfDecPlaces from 'Utils/roundByNumOfDecPlaces';

const prepareCommissionData = (data) => {
    const amount = prepareInputUint64Value(get(data, 'amount', 0));

    const commissionConfig = get(data, 'commissionConfig', {});

    if (isEmpty(commissionConfig)) {
        return { amount, isEmpty: true };
    }

    const periodType = get(data, 'commissionConfig.periodType', TRADE_TYPES.COMMISSION_PERIOD_TYPE.NONE);

    const chargeCondition = get(
        data,
        'commissionConfig.chargeCondition',
        TRADE_TYPES.COMMISSION_CHARGE_CONDITION.OPENING_OR_CLOSING_POSITION
    );

    const config = {
        periodType,
        chargeCondition,
        // one of commissionIn
        ...(!isUndefined(data.commissionConfig.percent) && {
            percent: data.commissionConfig.percent
        }),
        ...(!isUndefined(data.commissionConfig.point) && {
            point: data.commissionConfig.point
        }),
        ...(!isUndefined(data.commissionConfig.baseCurrency) && {
            baseCurrency: prepareInputUint64Value(data.commissionConfig.baseCurrency)
        }),
        ...(!isUndefined(data.commissionConfig.usd) && { usd: prepareInputUint64Value(data.commissionConfig.usd) }),
        // one of commissionFor
        ...(!isUndefined(data.commissionConfig.lot) && { lot: prepareInputUint64Value(data.commissionConfig.lot) }),
        ...(!isUndefined(data.commissionConfig.execution) && {
            execution: data.commissionConfig.execution
        }),
        ...(!isUndefined(data.commissionConfig.order) && {
            order: data.commissionConfig.order
        }),
        ...(!isUndefined(data.commissionConfig.usdVolume) && {
            usdVolume: prepareInputUint64Value(data.commissionConfig.usdVolume)
        }),
        ...(!isUndefined(data.commissionConfig.baseCurrencyVolume) && {
            baseCurrencyVolume: prepareInputUint64Value(data.commissionConfig.baseCurrencyVolume)
        })
    };

    return {
        amount,
        commissionConfig: config,
        isEmpty: false
    };
};

export function* requestCommission({ payload }) {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.getCommissionPending());
        const symbols = yield select(({ symbols: { byId: symbols } }) => symbols);

        const { symbol, side, volumeInLots, commissionRequestType, commissionRequestId } = payload;
        const positionId = get(payload, 'positionId', 0);

        const { lotSize } = symbols[symbol];

        if (!lotSize) {
            throw new Error(`Can't find lotsize for symbol: ${symbol}`);
        }

        const volume = prepareOutputUint64Value(volumeInLots * lotSize);

        const { body } = yield tradeService.request(TRADE_REQUESTS.GET_COMMISSION, {
            symbol,
            side,
            volume,
            positionId
        });

        const preparedData = prepareCommissionData(body);
        set(preparedData, 'amount', Math.abs(roundByNumOfDecPlaces(preparedData.amount, 2)));
        set(preparedData, 'commissionRequestId', commissionRequestId);
        set(preparedData, 'commissionRequestType', commissionRequestType);

        yield put(actions.trade.getCommissionSuccess(preparedData));
    } catch (err) {
        yield put(actions.trade.getCommissionFailure(err.message));
    }
}

export function* requestBetCommission({ payload }) {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.getCommissionPending());

        const data = pick(payload, ['symbol', 'side', 'volume', 'multiplier']);
        set(data, 'volume', prepareOutputUint64Value(data.volume));

        const { body } = yield tradeService.request(TRADE_REQUESTS.GET_BET_COMMISSION, data);

        const preparedData = prepareCommissionData(body);
        set(preparedData, 'amount', Math.abs(roundByNumOfDecPlaces(preparedData.amount, 2)));
        set(preparedData, 'commissionRequestId', payload.symbol);
        set(preparedData, 'commissionRequestType', payload.commissionRequestType);

        yield put(actions.trade.getCommissionSuccess(preparedData));
    } catch (err) {
        yield put(actions.trade.getCommissionFailure(err.message));
    }
}

export function* requestCloseBetCommission({ payload: betId }) {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.getCommissionPending());

        const { body } = yield tradeService.request(TRADE_REQUESTS.GET_CLOSE_BET_COMMISSION, { betId });

        const preparedData = prepareCommissionData(body);
        set(preparedData, 'amount', Math.abs(roundByNumOfDecPlaces(preparedData.amount, 2)));
        set(preparedData, 'commissionRequestId', betId);
        set(preparedData, 'commissionRequestType', COMMISSION_REQUEST_TYPE.BET_CLOSE);

        yield put(actions.trade.getCommissionSuccess(preparedData));
    } catch (err) {
        yield put(actions.trade.getCommissionFailure(err.message));
    }
}
