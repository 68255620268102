export const OPERATIONS_TABS = {
    POSITIONS: 'positions',
    ORDERS: 'orders',
    HISTORY: 'history',
    EXECUTIONS: 'executions',
    BALANCES: 'balances',
    NEWS: 'news',
    BETS: 'bets',
    BETS_HISTORY: 'betsHistory',
    NOTIFICATIONS: 'notifications'
};
