import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const theme = createReducer(initialState.theme, {
    [actions.setThemeInStore]: (state, { payload }) => payload,
    [actions.setThemeAndSendIt]: (state, { payload }) => payload
});

export default theme;
