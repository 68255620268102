import { createAction } from '@reduxjs/toolkit';
import * as authActions from './auth';
import * as marketActions from './market';
import * as tradeActions from './trade';
import * as newsActions from './news';
import * as technicActions from './technic';

import symbolWidget from '../slices/symbolWidget';

export const auth = authActions;
export const market = marketActions;
export const trade = tradeActions;
export const news = newsActions;
export const technic = technicActions;

export const ui = {
    symbolWidget: symbolWidget.actions
};

export const setIsAuthenticated = createAction('SET_IS_AUTHENTICATED');
export const setIsMainMenuOpened = createAction('SET_IS_MAIN_MENU_OPENED');
export const setIsProVersion = createAction('SET_IS_PRO_VERSION');
export const setAppError = createAction('SET_APP_ERROR');
export const resetAppError = createAction('RESET_APP_ERROR');
export const setTimeCorrection = createAction('SET_TIME_CORRECTION');

// Actions for trading form mode
export const setTradingFormMode = createAction('SET_TRADING_FORM_MODE');

// Actions for theme
export const setTheme = createAction('SET_THEME');
export const setThemeInStore = createAction('SET_THEME_IN_STORE');
export const gotTheme = createAction('GOT_THEME');
export const setThemeAndSendIt = createAction('SET_THEME_AND_SEND_IT');

// Actions for locales
export const setLocale = createAction('SET_LOCALE');
export const setLocaleInStore = createAction('SET_LOCALE_IN_STORE');
export const gotLocale = createAction('GOT_LOCALE');
export const setLocaleAndSendIt = createAction('SET_LOCALE_AND_SEND_IT');

// Actions for favorites
export const addSymbolToFavorites = createAction('ADD_SYMBOL_TO_FAVORITES');
export const removeSymbolFromFavorites = createAction('REMOVE_SYMBOL_FROM_FAVORITES');
export const setFavoritesInStore = createAction('SET_FAVORITES_IN_STORE');
export const gotFavorites = createAction('GOT_FAVORITES');
export const setFavoritesAndSendIt = createAction('SET_FAVORITES_AND_SEND_IT');

// Actions for chartTabs
export const setChartTabs = createAction('SET_CHART_TABS');
export const closeChartTabById = createAction('CLOSE_CHART_TAB');
export const updateChartTabName = createAction('UPDATE_CHART_TAB_NAME');
export const createNewChartTab = createAction('CREATE_CHART_TAB');
export const selectChartTab = createAction('SELECT_CHART_TAB');
export const updateCurrentChartTabTimeframe = createAction('UPDATE_CHART_TAB_TIMEFRAME');
export const selectOrCreateChartTab = createAction('SELECT_OR_CREATE_CHART_TAB');

// Actions for operationsTabs
export const setOperationsTabsByTradingMode = createAction('SET_OPERATIONS_TAB_BY_TRADING_MODE');
export const selectOperationsTab = createAction('SELECT_OPERATIONS_TAB');
export const deactivateIndicatorForTab = createAction('DEACTIVATE_OPERATIONS_TAB_INDICATOR');

// A special actions for join ws events
export const bothMarketAndTradeWSConnectionsOpen = createAction('BOTH_MARKET_AND_TRADE_WS_CONNECTION_OPEN');
export const oneOfMarketOrTradeWSConnectionsClose = createAction('ONE_OF_MARKET_OR_TRADE_WS_CONNECTION_CLOSE');

// A special action used in the root reducer
export const resetStore = createAction('RESET_STORE');
