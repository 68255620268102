import { call, takeEvery, takeLatest, fork, delay, getContext } from 'redux-saga/effects';
import { EVENTS, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';
import { loadNews, loadMoreNews } from './loadNews.saga';
import { handleSubscribeToNews } from './subscribeNews.saga';
import { getNews } from './getNews.saga';
import getEventChannelCreator from 'Store/sagas/utils/getEventChannelCreator';
import WSEventsToActionsDispatch from 'Store/sagas/utils/WSEventsToActionsDispatch.saga';
import { serverHealthCheck } from 'Store/sagas/health';
import logger from 'Utils/logger';

const logError = logger('Error:NewsMainSaga:');

const eventsToActionsMap = [
    { event: EVENTS.NEWS.OPEN, actionCreator: actions.news.WSConnectionOpen },
    { event: EVENTS.NEWS.CLOSE, actionCreator: actions.news.WSConnectionClose },
    { event: EVENTS.NEWS.ERROR, actionCreator: actions.news.WSConnectionOnError }
];

// MAIN WATCHER
export default function* newsSaga() {
    const newsService = yield getContext(SERVICE_NAMES.NEWS);
    const createDefaultEventChannel = getEventChannelCreator(newsService);

    yield fork(WSEventsToActionsDispatch, createDefaultEventChannel, eventsToActionsMap);

    yield takeEvery(actions.setIsAuthenticated, openWSConnectionOnAuth, newsService);
    yield takeEvery(actions.auth.requestLogoutPending, closeWSConnection, newsService);

    yield takeLatest(actions.news.WSConnectionClose, serverHealthCheck);

    yield takeLatest(actions.news.loadNews, loadNews);
    yield takeEvery(actions.news.loadMoreNews, loadMoreNews);

    yield takeEvery(actions.news.subscribeToNews, handleSubscribeToNews);
    yield takeEvery(actions.news.getNewsItem, getNews);
}

// WORKERS
function* openWSConnectionOnAuth(newsService, { payload }) {
    if (payload !== true) {
        return;
    }

    if (newsService.isOpening || newsService.isOpened) {
        return;
    }

    try {
        yield call([newsService, newsService.connect]);
    } catch (err) {
        logError('Open news connection error: %O', err);
        // TODO add logic to up on error
        yield call([newsService, newsService.disconnect], 1000, 'News service error');
        yield delay(3000);
        yield call([newsService, newsService.connect]);
    }
}

function* closeWSConnection(newsService) {
    try {
        yield call([newsService, newsService.disconnect], 1000, 'News service error');
    } catch (err) {
        logError('Close News WS connection error: %O', err);
    }
}
