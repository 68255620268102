import { put, select, getContext, call } from 'redux-saga/effects';
import {
    difference,
    includes,
    flattenDeep,
    values,
    keys,
    get,
    pick,
    isNull,
    isEmpty,
    isUndefined,
    uniq,
    isNil
} from 'lodash';
import { TRADE_REQUESTS, TRADE_TYPES, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';
import getCurrencyConversion from 'Utils/getCurrencyConversion';
import { prepareInputUint64Value, prepareOutputUint64Value } from 'Utils/uint64';
import calculateProfitLoss from 'Utils/calculateProfitLoss';
import logger from 'Utils/logger';

const logError = logger('Error:Saga:Positions:');

const getPositionKey = (position) => {
    const positionId = Number(get(position, 'positionId', 0));
    return `${position.symbol}_${positionId}`;
};

const preparePositionData = (data) => {
    const positionKey = getPositionKey(data);

    return {
        ...data,
        volume: prepareInputUint64Value(get(data, 'volume', 0)),
        price: prepareInputUint64Value(get(data, 'price', 0)),
        side: get(data, 'side', TRADE_TYPES.SIDE.BUY),
        swap: prepareInputUint64Value(get(data, 'swap', 0)),
        slPrice: prepareInputUint64Value(get(data, 'slPrice', 0)),
        tpPrice: prepareInputUint64Value(get(data, 'tpPrice', 0)),
        ...(!isUndefined(data.insuranceConditionId) && { insuranceConditionId: Number(data.insuranceConditionId) }),
        ...(!isUndefined(data.insurancePayoutPercent) && {
            insurancePayoutPercent: Number(data.insurancePayoutPercent)
        }),
        positionId: Number(get(data, 'positionId', 0)),
        positionKey
    };
};

const reversSideMap = {
    [TRADE_TYPES.SIDE.BUY]: TRADE_TYPES.SIDE.SELL,
    [TRADE_TYPES.SIDE.SELL]: TRADE_TYPES.SIDE.BUY
};

export function* updatePositionProfitLoss() {
    try {
        const { positions, symbols, quotes, customerInfo, currencies } = yield select(
            ({
                positions: { byKey: positions },
                symbols: { byId: symbols },
                quotes,
                customerInfo,
                currencies: { byId: currencies }
            }) => ({
                positions,
                symbols,
                quotes,
                customerInfo,
                currencies
            })
        );

        if (isEmpty(positions)) {
            return;
        }

        const { depositCurrency } = customerInfo;

        const positionsWithProfitLoss = keys(positions).reduce((updatedPositions, positionKey) => {
            const position = positions[positionKey];

            const { result, error } = calculateProfitLoss(position, quotes, symbols, currencies, depositCurrency);

            if (error) {
                logError(error.message);
                return updatedPositions;
            }

            updatedPositions[positionKey] = {
                ...position,
                profitLoss: result
            };

            return updatedPositions;
        }, {});

        yield put(actions.trade.setPositionProfitLoss(positionsWithProfitLoss));
    } catch (err) {
        logError('getPositionProfitLoss error: %o', err.message);
    }
}

export function* requestPositionList() {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);
        yield put(actions.trade.getPositionListPending());
        yield tradeService.request(TRADE_REQUESTS.GET_POSITION_LIST, {});
    } catch (err) {
        yield put(actions.trade.getPositionListFailure(err.message));
    }
}

export function* handlePositionList(data) {
    const { positions, symbols, symbolIds, depositCurrency } = yield select(
        ({
            positions: { byKey: positions },
            symbols: { byId: symbols, allIds: symbolIds },
            customerInfo: { depositCurrency }
        }) => ({
            positions,
            symbols,
            symbolIds,
            depositCurrency
        })
    );

    try {
        const position = data.body.position || []; // if empty, position === undefined ?!?
        const positionsIncoming = position.filter((p) => !isUndefined(p.volume) && Number(p.volume) !== 0);

        const currentSymbolsWithSubscriptions = values(positions).map((p) => p.symbol);
        const incomingSymbolsForSubscriptions = positionsIncoming.map((p) => p.symbol);

        const symbolsForSubscribe = difference(incomingSymbolsForSubscriptions, currentSymbolsWithSubscriptions);

        const commonCurrencyConversionSymbolsForSubscribe = symbolsForSubscribe.reduce((acc, symbolId) => {
            const symbol = symbols[symbolId];

            if (!symbol) {
                return acc;
            }

            const { profitCurrency, convertationSymbolPostfix } = symbol;

            const { requiredSymbols: currencyConversionSymbols } = getCurrencyConversion(
                symbolIds,
                profitCurrency,
                depositCurrency,
                convertationSymbolPostfix
            );

            return { ...acc, [symbolId]: currencyConversionSymbols.filter((s) => s !== symbolId) };
        }, {});

        const newPositions = positionsIncoming.map(preparePositionData).map((p) => {
            const currencyConversionSymbols = commonCurrencyConversionSymbolsForSubscribe[p.symbol] || [];
            return { ...p, currencyConversionSymbols: [...currencyConversionSymbols] };
        });

        const symbolsForSubscribeActions = flattenDeep([
            symbolsForSubscribe,
            values(commonCurrencyConversionSymbolsForSubscribe)
        ]);

        const symbolsForUnsubscribe = difference(currentSymbolsWithSubscriptions, incomingSymbolsForSubscriptions);

        const commonCurrencyConversionSymbolsForUnsubscribe = symbolsForUnsubscribe
            .map((s) => positions[s] && positions[s].currencyConversionSymbols)
            .filter((v) => v);

        const symbolsForUnsubscribeActions = flattenDeep([
            symbolsForUnsubscribe,
            commonCurrencyConversionSymbolsForUnsubscribe
        ]);

        if (!isEmpty(symbolsForSubscribeActions)) {
            yield put(actions.market.subscribeToSymbolQuote(symbolsForSubscribeActions));
        }

        if (!isEmpty(symbolsForUnsubscribeActions)) {
            yield put(actions.market.unsubscribeFromSymbolQuote(symbolsForUnsubscribeActions));
        }

        yield put(actions.trade.getPositionListSuccess(newPositions));
    } catch (err) {
        logError(err);
        yield put(actions.trade.getPositionListFailure(err.message));
    }
}

export function* closePosition({ payload: positionKey }) {
    const { positions, symbols, customerInfo } = yield select(
        ({ positions: { byKey: positions }, symbols: { byId: symbols }, customerInfo }) => ({
            positions,
            symbols,
            customerInfo
        })
    );

    const position = positions[positionKey];

    if (isNil(position)) {
        return;
    }

    const { symbol, positionId, side = null, volume = null } = pick(position, [
        'symbol',
        'positionId',
        'side',
        'volume'
    ]);

    const positionSymbol = symbols[symbol];
    const lotSize = positionSymbol ? positionSymbol.lotSize : null;

    if (isNull(side) || isNull(volume) || isNil(lotSize) || lotSize === 0) {
        return;
    }

    const volumeInLots = volume / lotSize;
    const type = TRADE_TYPES.ORDER_TYPE.MARKET;

    const clientId = `${
        customerInfo && customerInfo.name && customerInfo.name.replace(' ', '')
    }_${new Date().getTime()}`;

    yield put(
        actions.trade.orderRequest({ symbol, positionId, side: reversSideMap[side], volumeInLots, type, clientId })
    );
    yield put(actions.trade.updatePosition({ positionKey, onClosing: true, closingClientId: clientId }));
}

function* removePosition(positionKey) {
    const positions = yield select(({ positions: { byKey } }) => byKey);

    const position = positions[positionKey];

    if (isNil(position)) {
        return;
    }

    const { currencyConversionSymbols = [], symbol } = position;
    const symbolsToUnsubscribe = uniq([...currencyConversionSymbols, symbol]);

    yield put(actions.market.unsubscribeFromSymbolQuote(symbolsToUnsubscribe));
    yield put(actions.trade.removePosition(positionKey));
}

function* addPosition(position) {
    const { symbols, symbolIds, depositCurrency } = yield select(
        ({ symbols: { byId: symbols, allIds: symbolIds }, quotes, customerInfo: { depositCurrency } }) => ({
            symbols,
            symbolIds,
            quotes,
            depositCurrency
        })
    );

    const symbol = symbols[position.symbol];

    if (!symbol) {
        return;
    }

    const { profitCurrency, convertationSymbolPostfix } = symbol;

    const { requiredSymbols } = getCurrencyConversion(
        symbolIds,
        profitCurrency,
        depositCurrency,
        convertationSymbolPostfix
    );

    const currencyConversionSymbols = requiredSymbols.filter((s) => s !== position.symbol);

    const symbolsToSubscribe = [position.symbol, ...currencyConversionSymbols];
    yield put(actions.market.subscribeToSymbolQuote(symbolsToSubscribe));

    yield put(actions.trade.addPosition({ ...position, currencyConversionSymbols: [...currencyConversionSymbols] }));
}

export function* handlePosition(data) {
    yield put(actions.trade.handlePositionStart());

    const positionKeys = yield select(({ positions: { allKeys } }) => allKeys);

    try {
        const { body } = data;
        const position = preparePositionData(body);

        if (!includes(positionKeys, position.positionKey) && position.volume !== 0) {
            yield addPosition(position);
            return;
        }

        if (position.volume === 0) {
            yield removePosition(position.positionKey);
            return;
        }

        yield put(actions.trade.updatePosition(position));
    } catch (err) {
        yield put(actions.trade.handlePositionFailure(err.message));
    }
}

export function* requestPositionUpdate({ payload }) {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);

        const { symbol, positionId = 0, slPrice = 0, tpPrice = 0 } = payload;

        const positionsKeys = yield select(({ positions: { allKeys: positionsKeys } }) => positionsKeys);
        const positionKey = getPositionKey({ symbol, positionId });

        if (!includes(positionsKeys, positionKey)) {
            throw new Error('No such position key');
        }

        const data = {
            symbol,
            positionId,
            slPrice: prepareOutputUint64Value(slPrice),
            tpPrice: prepareOutputUint64Value(tpPrice)
        };

        yield call([tradeService, tradeService.request], TRADE_REQUESTS.UPDATE_POSITION, data);

        const position = { ...payload, positionKey };

        yield put(actions.trade.updatePosition(position));
        yield put(actions.trade.updatePositionRequestSuccess(position));
    } catch (err) {
        yield put(actions.trade.updatePositionRequestFailure(err.message));
    }
}
