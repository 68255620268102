import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const favorites = createReducer(initialState.favorites, {
    [actions.setFavoritesInStore]: (state, { payload }) => payload,
    [actions.setFavoritesAndSendIt]: (state, { payload }) => payload,
    [actions.market.WSConnectionClose]: () => initialState.favorites
});

export default favorites;
