const AUTH = {
    ERROR: 'AUTH:ERROR',
    LOGIN_REQUEST: 'AUTH:LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH:LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH:LOGIN_FAILURE',
    LOGOUT_REQUEST: 'AUTH:LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'AUTH:LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'AUTH:LOGOUT_FAILURE',
    REFRESH_REQUEST: 'AUTH:REFRESH_REQUEST',
    REFRESH_SUCCESS: 'AUTH:REFRESH_SUCCESS',
    REFRESH_FAILURE: 'AUTH:REFRESH_FAILURE',
    CHECK_REQUEST: 'AUTH:CHECK_REQUEST',
    CHECK_SUCCESS: 'AUTH:CHECK_SUCCESS',
    CHECK_FAILURE: 'AUTH:CHECK_FAILURE'
};

const MARKET = {
    OPEN: 'MARKET:OPEN',
    CLOSE: 'MARKET:CLOSE',
    CONNECTING: 'MARKET:CONNECTING',
    RECONNECTING: 'MARKET:RECONNECTING',
    RECONNECT_NOT_ALLOWED: 'MARKET:RECONNECT_NOT_ALLOWED',
    MESSAGE: 'MARKET:MESSAGE',
    TIMEOUT: 'MARKET:TIMEOUT',
    ERROR: 'MARKET:ERROR',
    PROBLEM: 'MARKET:PROBLEM',
    NORMAL: 'MARKET:NORMAL',
    SYMBOL_LIST: 'symbolList',
    QUOTES: 'quotes',
    CHART_BAR: 'chartBar',
    SERVER_TIME: 'serverTime'
};

const TRADE = {
    OPEN: 'TRADE:OPEN',
    CLOSE: 'TRADE:CLOSE',
    CONNECTING: 'TRADE:CONNECTING',
    RECONNECTING: 'TRADE:RECONNECTING',
    RECONNECT_NOT_ALLOWED: 'TRADE:RECONNECT_NOT_ALLOWED',
    TIMEOUT: 'TRADE:TIMEOUT',
    MESSAGE: 'TRADE:MESSAGE',
    ERROR: 'TRADE:ERROR',
    PROBLEM: 'TRADE:PROBLEM',
    NORMAL: 'TRADE:NORMAL',
    ORDER: 'order',
    RESULT: 'result',
    ORDER_REQUEST_RESULT: 'orderRequestResult',
    ORDER_EXECUTION: 'orderExecution',
    ORDER_CANCEL_RESULT: 'orderCancelResult',
    ORDER_LIST: 'orderList',
    ORDER_EXECUTION_LIST: 'orderExecutionList',
    POSITION_LIST: 'positionList',
    POSITION: 'position',
    CUSTOMER_INFO: 'customerInfo',
    TOTAL_AMOUNTS: 'totalAmounts',
    BALANCES: 'balances',
    BALANCE: 'balance',
    BET: 'bet',
    PLACE_BET_RESULT: 'placeBetResult',
    BET_LIST: 'betList',
    SERVER_TIME: 'serverTime',
    COMMISSION: 'commission',
    CURRENCY: 'currency',
    CURRENCY_LIST: 'currencyList',
    INSURANCE_CONDITION: 'insuranceCondition',
    INSURANCE_CONDITION_LIST: 'insuranceConditionList'
};

const NEWS = {
    OPEN: 'NEWS:OPEN',
    CLOSE: 'NEWS:CLOSE',
    CONNECTING: 'NEWS:CONNECTING',
    RECONNECTING: 'NEWS:RECONNECTING',
    RECONNECT_NOT_ALLOWED: 'NEWS:RECONNECT_NOT_ALLOWED',
    MESSAGE: 'NEWS:MESSAGE',
    TIMEOUT: 'NEWS:TIMEOUT',
    ERROR: 'NEWS:ERROR',
    RESULT: 'result',
    NEWS: 'news',
    NEWS_LIST: 'newsList'
};

const TECHNIC = {
    OPEN: 'TECHNIC:OPEN',
    CLOSE: 'TECHNIC:CLOSE',
    CONNECTING: 'TECHNIC:CONNECTING',
    RECONNECTING: 'TECHNIC:RECONNECTING',
    RECONNECT_NOT_ALLOWED: 'TECHNIC:RECONNECT_NOT_ALLOWED',
    MESSAGE: 'TECHNIC:MESSAGE',
    TIMEOUT: 'TECHNIC:TIMEOUT',
    ERROR: 'TECHNIC:ERROR',
    PROBLEM: 'TECHNIC:PROBLEM',
    NORMAL: 'TECHNIC:NORMAL',
    RESULT: 'result',
    SETTINGS: 'settings'
};

const STORE = {
    RESET: 'STORE:RESET'
};

const WEB_SOCKET = {
    OPEN: 'open',
    CLOSE: 'close',
    CONNECTING: 'connecting',
    MESSAGE: 'message',
    ERROR: 'error'
};

const BROWSER = {
    MOUSEDOWN: 'mousedown',
    KEYDOWN: 'keydown'
};

export const EVENTS = {
    AUTH,
    MARKET,
    TRADE,
    NEWS,
    TECHNIC,
    STORE,
    WEB_SOCKET,
    BROWSER
};
