import { isString, includes, isUndefined, flatten } from 'lodash';
import isEmptyString from 'Utils/isEmptyString';
import isArrayOfStrings from 'Utils/isArrayOfStrings';
import roundByNumOfDecPlaces from 'Utils/roundByNumOfDecPlaces';
import { TRANSITIONAL_CURRENCY } from 'Constants';

const DIGITS = 8;

/**
 * bidAskFactor is boolean value that shows what we need to use in calculation: bid or ask price
 *
 * On direct ratio: price = bidAskFactor ? bid : ask
 * On reverse ratio: price = bidAskFactor ? ask : bid
 *
 * bidAskFactor is equal expression (profit >= 0) (for profit conversion)
 */

const getDirectRatio = (symbol) => (quotes, bidAskFactor) => {
    const quote = quotes[symbol];

    if (!quote) {
        return 0;
    }

    if (isUndefined(bidAskFactor)) {
        throw new Error('Conversion function expect second argument: bidAskFactor');
    }

    const price = bidAskFactor ? quote.bidPrice : quote.askPrice;

    return !isUndefined(price) ? roundByNumOfDecPlaces(price, DIGITS) : 0;
};

const getReverseRatio = (symbol) => (quotes, bidAskFactor) => {
    const quote = quotes[symbol];

    if (!quote) {
        return 0;
    }

    if (isUndefined(bidAskFactor)) {
        throw new Error('Conversion function expect second argument: bidAskFactor');
    }

    const price = bidAskFactor ? quote.askPrice : quote.bidPrice;

    return !isUndefined(price) ? roundByNumOfDecPlaces(1 / price, DIGITS) : 0;
};

const composeGetRatio = (getRatio1, getRatio2) => (quotes, bidAskFactor) =>
    roundByNumOfDecPlaces(getRatio1(quotes, bidAskFactor) * getRatio2(quotes, bidAskFactor), DIGITS);

/**
 * @param  {array of string} symbols ["EURUSD", "EURCAD", "AUDCAD" ...]
 * @param  {string} currencyFrom currency name, "USD", "EUR", "CAD" ...
 * @param  {string} currencyTo currency name, "USD", "EUR", "CAD" ...
 * @param  {string} symbolSuffix=''
 * @param  {boolean} shouldTryWithTransitionalCurrency
 * @return {object} { getRatio: function | null, requiredSymbols: [] }
 */
const getCurrencyConversion = (
    symbols,
    currencyFrom,
    currencyTo,
    symbolSuffixRaw,
    shouldTryWithTransitionalCurrency = true
) => {
    if (!isArrayOfStrings(symbols)) {
        throw new Error('CurrencyConversionRatio: "symbols" (first) argument must be array of string');
    }
    if (!isString(currencyFrom) || isEmptyString(currencyFrom)) {
        throw new Error('CurrencyConversionRatio: "currencyFrom" (third) argument must be string');
    }

    if (!isString(currencyTo) || isEmptyString(currencyTo)) {
        throw new Error('CurrencyConversionRatio: "currencyTo" (fourth) argument must be string');
    }

    const symbolSuffix = isString(symbolSuffixRaw) ? symbolSuffixRaw : '';

    if (currencyFrom === currencyTo) {
        return { getRatio: () => 1, requiredSymbols: [] };
    }

    // Example:
    // CHFEUR = x -> 1*CHF = x*EUR;
    // Use bid price first

    const directSymbol = `${currencyFrom}${currencyTo}${symbolSuffix}`;
    if (includes(symbols, directSymbol)) {
        return {
            getRatio: getDirectRatio(directSymbol),
            requiredSymbols: [directSymbol]
        };
    }

    // CHF -> EUR
    // EURCHF = x -> 1*EUR = x*CHF -> 1*CHF = EUR/x;
    // Use 1 / ask price first

    const reverseSymbol = `${currencyTo}${currencyFrom}${symbolSuffix}`;
    if (includes(symbols, reverseSymbol)) {
        return {
            getRatio: getReverseRatio(reverseSymbol),
            requiredSymbols: [reverseSymbol]
        };
    }

    // CHFUSD = x -> 1*CHF = x*USD
    // USDEUR = y -> 1*USD = y*EUR
    // ->
    // 1*CHF = x*y*EUR

    if (shouldTryWithTransitionalCurrency) {
        for (const transitionalCurrency of TRANSITIONAL_CURRENCY) {
            const { getRatio: getRatio1, requiredSymbols: requiredSymbols1 } = getCurrencyConversion(
                symbols,
                currencyFrom,
                transitionalCurrency,
                symbolSuffix,
                false
            );
            const { getRatio: getRatio2, requiredSymbols: requiredSymbols2 } = getCurrencyConversion(
                symbols,
                transitionalCurrency,
                currencyTo,
                symbolSuffix,
                false
            );

            if (getRatio1 && getRatio2) {
                return {
                    getRatio: composeGetRatio(getRatio1, getRatio2),
                    requiredSymbols: flatten([requiredSymbols1, requiredSymbols2])
                };
            }
        }
    }

    if (!isEmptyString(symbolSuffix)) {
        return getCurrencyConversion(symbols, currencyFrom, currencyTo, '', shouldTryWithTransitionalCurrency);
    }

    return { getRatio: null, requiredSymbols: [] };
};

export default getCurrencyConversion;
