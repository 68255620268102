export const TRADING_MODES = {
    MARGIN: 'margin',
    BETS: 'bets'
};

export const TRADING_MODE_DEFAULT = TRADING_MODES.BETS;

export const TRADING_FORM_MODES = {
    QUICK: 'quick',
    PRO: 'pro'
};

export const TRADING_FORM_MODE_DEFAULT = TRADING_FORM_MODES.QUICK;

export const SYMBOL_WIDGET_MODES = {
    LIST: 'list',
    DETAILS: 'details',
    EDIT_ORDER: 'edit order',
    EDIT_POSITION: 'edit position'
};
