export const DEFAULT_CURRENCY = 'USD';

export const TRANSITIONAL_CURRENCY = ['USD', 'EUR'];

export const CURRENCY_SYMBOLS = {
    USD: '$',
    EUR: '€',
    RUB: '₽',
    DEFAULT: '$'
};
