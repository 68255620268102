import { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppPreloader from 'Components/Tools/AppPreloader';

const loadAuthenticatedApp = () => import('./AuthenticatedApp');
const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'));
const ToastContainer = lazy(() => import('./toasts/ToastContainer'));

const App = () => {
    const isAuthenticated = useSelector((state) => state.isAuthenticated);
    // pre-load the authenticated side in the background while the user's
    // filling out the login form.
    useEffect(() => {
        loadAuthenticatedApp();
    }, []);

    return (
        <Suspense fallback={<AppPreloader />}>
            {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
            <ToastContainer />
        </Suspense>
    );
};

export default App;
