/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.feedbox = (function() {

    /**
     * Namespace feedbox.
     * @exports feedbox
     * @namespace
     */
    var feedbox = {};

    feedbox.auth = (function() {

        /**
         * Namespace auth.
         * @memberof feedbox
         * @namespace
         */
        var auth = {};

        /**
         * ChannelType enum.
         * @name feedbox.auth.ChannelType
         * @enum {number}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} MARKET=1 MARKET value
         * @property {number} TRADE=2 TRADE value
         * @property {number} TECHNIC=3 TECHNIC value
         */
        auth.ChannelType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "MARKET"] = 1;
            values[valuesById[2] = "TRADE"] = 2;
            values[valuesById[3] = "TECHNIC"] = 3;
            return values;
        })();

        auth.ProtocolVersion = (function() {

            /**
             * Properties of a ProtocolVersion.
             * @memberof feedbox.auth
             * @interface IProtocolVersion
             * @property {number|null} [major] ProtocolVersion major
             * @property {number|null} [minor] ProtocolVersion minor
             * @property {number|null} [patch] ProtocolVersion patch
             */

            /**
             * Constructs a new ProtocolVersion.
             * @memberof feedbox.auth
             * @classdesc Represents a ProtocolVersion.
             * @implements IProtocolVersion
             * @constructor
             * @param {feedbox.auth.IProtocolVersion=} [properties] Properties to set
             */
            function ProtocolVersion(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ProtocolVersion major.
             * @member {number} major
             * @memberof feedbox.auth.ProtocolVersion
             * @instance
             */
            ProtocolVersion.prototype.major = 0;

            /**
             * ProtocolVersion minor.
             * @member {number} minor
             * @memberof feedbox.auth.ProtocolVersion
             * @instance
             */
            ProtocolVersion.prototype.minor = 0;

            /**
             * ProtocolVersion patch.
             * @member {number} patch
             * @memberof feedbox.auth.ProtocolVersion
             * @instance
             */
            ProtocolVersion.prototype.patch = 0;

            /**
             * Creates a new ProtocolVersion instance using the specified properties.
             * @function create
             * @memberof feedbox.auth.ProtocolVersion
             * @static
             * @param {feedbox.auth.IProtocolVersion=} [properties] Properties to set
             * @returns {feedbox.auth.ProtocolVersion} ProtocolVersion instance
             */
            ProtocolVersion.create = function create(properties) {
                return new ProtocolVersion(properties);
            };

            /**
             * Encodes the specified ProtocolVersion message. Does not implicitly {@link feedbox.auth.ProtocolVersion.verify|verify} messages.
             * @function encode
             * @memberof feedbox.auth.ProtocolVersion
             * @static
             * @param {feedbox.auth.IProtocolVersion} message ProtocolVersion message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProtocolVersion.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.major != null && Object.hasOwnProperty.call(message, "major"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.major);
                if (message.minor != null && Object.hasOwnProperty.call(message, "minor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.minor);
                if (message.patch != null && Object.hasOwnProperty.call(message, "patch"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.patch);
                return writer;
            };

            /**
             * Decodes a ProtocolVersion message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.auth.ProtocolVersion
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.auth.ProtocolVersion} ProtocolVersion
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProtocolVersion.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.auth.ProtocolVersion();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.major = reader.uint32();
                        break;
                    case 2:
                        message.minor = reader.uint32();
                        break;
                    case 3:
                        message.patch = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ProtocolVersion message.
             * @function verify
             * @memberof feedbox.auth.ProtocolVersion
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProtocolVersion.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.major != null && message.hasOwnProperty("major"))
                    if (!$util.isInteger(message.major))
                        return "major: integer expected";
                if (message.minor != null && message.hasOwnProperty("minor"))
                    if (!$util.isInteger(message.minor))
                        return "minor: integer expected";
                if (message.patch != null && message.hasOwnProperty("patch"))
                    if (!$util.isInteger(message.patch))
                        return "patch: integer expected";
                return null;
            };

            /**
             * Creates a ProtocolVersion message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.auth.ProtocolVersion
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.auth.ProtocolVersion} ProtocolVersion
             */
            ProtocolVersion.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.auth.ProtocolVersion)
                    return object;
                var message = new $root.feedbox.auth.ProtocolVersion();
                if (object.major != null)
                    message.major = object.major >>> 0;
                if (object.minor != null)
                    message.minor = object.minor >>> 0;
                if (object.patch != null)
                    message.patch = object.patch >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a ProtocolVersion message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.auth.ProtocolVersion
             * @static
             * @param {feedbox.auth.ProtocolVersion} message ProtocolVersion
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProtocolVersion.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.major = 0;
                    object.minor = 0;
                    object.patch = 0;
                }
                if (message.major != null && message.hasOwnProperty("major"))
                    object.major = message.major;
                if (message.minor != null && message.hasOwnProperty("minor"))
                    object.minor = message.minor;
                if (message.patch != null && message.hasOwnProperty("patch"))
                    object.patch = message.patch;
                return object;
            };

            /**
             * Converts this ProtocolVersion to JSON.
             * @function toJSON
             * @memberof feedbox.auth.ProtocolVersion
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProtocolVersion.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ProtocolVersion;
        })();

        auth.Login = (function() {

            /**
             * Properties of a Login.
             * @memberof feedbox.auth
             * @interface ILogin
             * @property {string|null} [password] Login password
             * @property {feedbox.auth.ChannelType|null} [channelType] Login channelType
             * @property {feedbox.auth.IProtocolVersion|null} [protocolVersion] Login protocolVersion
             * @property {number|null} [loginId] Login loginId
             */

            /**
             * Constructs a new Login.
             * @memberof feedbox.auth
             * @classdesc Represents a Login.
             * @implements ILogin
             * @constructor
             * @param {feedbox.auth.ILogin=} [properties] Properties to set
             */
            function Login(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Login password.
             * @member {string} password
             * @memberof feedbox.auth.Login
             * @instance
             */
            Login.prototype.password = "";

            /**
             * Login channelType.
             * @member {feedbox.auth.ChannelType} channelType
             * @memberof feedbox.auth.Login
             * @instance
             */
            Login.prototype.channelType = 0;

            /**
             * Login protocolVersion.
             * @member {feedbox.auth.IProtocolVersion|null|undefined} protocolVersion
             * @memberof feedbox.auth.Login
             * @instance
             */
            Login.prototype.protocolVersion = null;

            /**
             * Login loginId.
             * @member {number} loginId
             * @memberof feedbox.auth.Login
             * @instance
             */
            Login.prototype.loginId = 0;

            /**
             * Creates a new Login instance using the specified properties.
             * @function create
             * @memberof feedbox.auth.Login
             * @static
             * @param {feedbox.auth.ILogin=} [properties] Properties to set
             * @returns {feedbox.auth.Login} Login instance
             */
            Login.create = function create(properties) {
                return new Login(properties);
            };

            /**
             * Encodes the specified Login message. Does not implicitly {@link feedbox.auth.Login.verify|verify} messages.
             * @function encode
             * @memberof feedbox.auth.Login
             * @static
             * @param {feedbox.auth.ILogin} message Login message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Login.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                if (message.channelType != null && Object.hasOwnProperty.call(message, "channelType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.channelType);
                if (message.protocolVersion != null && Object.hasOwnProperty.call(message, "protocolVersion"))
                    $root.feedbox.auth.ProtocolVersion.encode(message.protocolVersion, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.loginId != null && Object.hasOwnProperty.call(message, "loginId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.loginId);
                return writer;
            };

            /**
             * Decodes a Login message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.auth.Login
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.auth.Login} Login
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Login.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.auth.Login();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.password = reader.string();
                        break;
                    case 3:
                        message.channelType = reader.int32();
                        break;
                    case 4:
                        message.protocolVersion = $root.feedbox.auth.ProtocolVersion.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.loginId = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Login message.
             * @function verify
             * @memberof feedbox.auth.Login
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Login.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.channelType != null && message.hasOwnProperty("channelType"))
                    switch (message.channelType) {
                    default:
                        return "channelType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.protocolVersion != null && message.hasOwnProperty("protocolVersion")) {
                    var error = $root.feedbox.auth.ProtocolVersion.verify(message.protocolVersion);
                    if (error)
                        return "protocolVersion." + error;
                }
                if (message.loginId != null && message.hasOwnProperty("loginId"))
                    if (!$util.isInteger(message.loginId))
                        return "loginId: integer expected";
                return null;
            };

            /**
             * Creates a Login message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.auth.Login
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.auth.Login} Login
             */
            Login.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.auth.Login)
                    return object;
                var message = new $root.feedbox.auth.Login();
                if (object.password != null)
                    message.password = String(object.password);
                switch (object.channelType) {
                case "UNKNOWN":
                case 0:
                    message.channelType = 0;
                    break;
                case "MARKET":
                case 1:
                    message.channelType = 1;
                    break;
                case "TRADE":
                case 2:
                    message.channelType = 2;
                    break;
                case "TECHNIC":
                case 3:
                    message.channelType = 3;
                    break;
                }
                if (object.protocolVersion != null) {
                    if (typeof object.protocolVersion !== "object")
                        throw TypeError(".feedbox.auth.Login.protocolVersion: object expected");
                    message.protocolVersion = $root.feedbox.auth.ProtocolVersion.fromObject(object.protocolVersion);
                }
                if (object.loginId != null)
                    message.loginId = object.loginId >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a Login message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.auth.Login
             * @static
             * @param {feedbox.auth.Login} message Login
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Login.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.password = "";
                    object.channelType = options.enums === String ? "UNKNOWN" : 0;
                    object.protocolVersion = null;
                    object.loginId = 0;
                }
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.channelType != null && message.hasOwnProperty("channelType"))
                    object.channelType = options.enums === String ? $root.feedbox.auth.ChannelType[message.channelType] : message.channelType;
                if (message.protocolVersion != null && message.hasOwnProperty("protocolVersion"))
                    object.protocolVersion = $root.feedbox.auth.ProtocolVersion.toObject(message.protocolVersion, options);
                if (message.loginId != null && message.hasOwnProperty("loginId"))
                    object.loginId = message.loginId;
                return object;
            };

            /**
             * Converts this Login to JSON.
             * @function toJSON
             * @memberof feedbox.auth.Login
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Login.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Login;
        })();

        auth.LoginResult = (function() {

            /**
             * Properties of a LoginResult.
             * @memberof feedbox.auth
             * @interface ILoginResult
             * @property {feedbox.auth.LoginResult.Code|null} [code] LoginResult code
             * @property {string|null} [description] LoginResult description
             */

            /**
             * Constructs a new LoginResult.
             * @memberof feedbox.auth
             * @classdesc Represents a LoginResult.
             * @implements ILoginResult
             * @constructor
             * @param {feedbox.auth.ILoginResult=} [properties] Properties to set
             */
            function LoginResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LoginResult code.
             * @member {feedbox.auth.LoginResult.Code} code
             * @memberof feedbox.auth.LoginResult
             * @instance
             */
            LoginResult.prototype.code = 0;

            /**
             * LoginResult description.
             * @member {string} description
             * @memberof feedbox.auth.LoginResult
             * @instance
             */
            LoginResult.prototype.description = "";

            /**
             * Creates a new LoginResult instance using the specified properties.
             * @function create
             * @memberof feedbox.auth.LoginResult
             * @static
             * @param {feedbox.auth.ILoginResult=} [properties] Properties to set
             * @returns {feedbox.auth.LoginResult} LoginResult instance
             */
            LoginResult.create = function create(properties) {
                return new LoginResult(properties);
            };

            /**
             * Encodes the specified LoginResult message. Does not implicitly {@link feedbox.auth.LoginResult.verify|verify} messages.
             * @function encode
             * @memberof feedbox.auth.LoginResult
             * @static
             * @param {feedbox.auth.ILoginResult} message LoginResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LoginResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                return writer;
            };

            /**
             * Decodes a LoginResult message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.auth.LoginResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.auth.LoginResult} LoginResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LoginResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.auth.LoginResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.description = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a LoginResult message.
             * @function verify
             * @memberof feedbox.auth.LoginResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LoginResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates a LoginResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.auth.LoginResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.auth.LoginResult} LoginResult
             */
            LoginResult.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.auth.LoginResult)
                    return object;
                var message = new $root.feedbox.auth.LoginResult();
                switch (object.code) {
                case "CONFIRM":
                case 0:
                    message.code = 0;
                    break;
                case "REJECT":
                case 1:
                    message.code = 1;
                    break;
                case "WRONG_CHANNEL":
                case 2:
                    message.code = 2;
                    break;
                case "INCOMPATIBLE_PROTOCOL":
                case 3:
                    message.code = 3;
                    break;
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from a LoginResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.auth.LoginResult
             * @static
             * @param {feedbox.auth.LoginResult} message LoginResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LoginResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "CONFIRM" : 0;
                    object.description = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.feedbox.auth.LoginResult.Code[message.code] : message.code;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this LoginResult to JSON.
             * @function toJSON
             * @memberof feedbox.auth.LoginResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LoginResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Code enum.
             * @name feedbox.auth.LoginResult.Code
             * @enum {number}
             * @property {number} CONFIRM=0 CONFIRM value
             * @property {number} REJECT=1 REJECT value
             * @property {number} WRONG_CHANNEL=2 WRONG_CHANNEL value
             * @property {number} INCOMPATIBLE_PROTOCOL=3 INCOMPATIBLE_PROTOCOL value
             */
            LoginResult.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CONFIRM"] = 0;
                values[valuesById[1] = "REJECT"] = 1;
                values[valuesById[2] = "WRONG_CHANNEL"] = 2;
                values[valuesById[3] = "INCOMPATIBLE_PROTOCOL"] = 3;
                return values;
            })();

            return LoginResult;
        })();

        return auth;
    })();

    feedbox.market_api = (function() {

        /**
         * Namespace market_api.
         * @memberof feedbox
         * @namespace
         */
        var market_api = {};

        /**
         * ProtocolVersion enum.
         * @name feedbox.market_api.ProtocolVersion
         * @enum {number}
         * @property {number} __PLACEHOLDER1=0 __PLACEHOLDER1 value
         * @property {number} __PLACEHOLDER2=0 __PLACEHOLDER2 value
         * @property {number} MAJOR=4 MAJOR value
         * @property {number} MINOR=0 MINOR value
         * @property {number} PATCH=0 PATCH value
         */
        market_api.ProtocolVersion = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "__PLACEHOLDER1"] = 0;
            values["__PLACEHOLDER2"] = 0;
            values[valuesById[4] = "MAJOR"] = 4;
            values["MINOR"] = 0;
            values["PATCH"] = 0;
            return values;
        })();

        market_api.QuoteRequest = (function() {

            /**
             * Properties of a QuoteRequest.
             * @memberof feedbox.market_api
             * @interface IQuoteRequest
             * @property {feedbox.market_api.QuoteRequest.QuoteType|null} [quoteType] QuoteRequest quoteType
             * @property {string|null} [symbol] QuoteRequest symbol
             * @property {string|null} [lpName] QuoteRequest lpName
             */

            /**
             * Constructs a new QuoteRequest.
             * @memberof feedbox.market_api
             * @classdesc Represents a QuoteRequest.
             * @implements IQuoteRequest
             * @constructor
             * @param {feedbox.market_api.IQuoteRequest=} [properties] Properties to set
             */
            function QuoteRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QuoteRequest quoteType.
             * @member {feedbox.market_api.QuoteRequest.QuoteType} quoteType
             * @memberof feedbox.market_api.QuoteRequest
             * @instance
             */
            QuoteRequest.prototype.quoteType = 0;

            /**
             * QuoteRequest symbol.
             * @member {string} symbol
             * @memberof feedbox.market_api.QuoteRequest
             * @instance
             */
            QuoteRequest.prototype.symbol = "";

            /**
             * QuoteRequest lpName.
             * @member {string} lpName
             * @memberof feedbox.market_api.QuoteRequest
             * @instance
             */
            QuoteRequest.prototype.lpName = "";

            /**
             * Creates a new QuoteRequest instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.QuoteRequest
             * @static
             * @param {feedbox.market_api.IQuoteRequest=} [properties] Properties to set
             * @returns {feedbox.market_api.QuoteRequest} QuoteRequest instance
             */
            QuoteRequest.create = function create(properties) {
                return new QuoteRequest(properties);
            };

            /**
             * Encodes the specified QuoteRequest message. Does not implicitly {@link feedbox.market_api.QuoteRequest.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.QuoteRequest
             * @static
             * @param {feedbox.market_api.IQuoteRequest} message QuoteRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuoteRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.quoteType != null && Object.hasOwnProperty.call(message, "quoteType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.quoteType);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.symbol);
                if (message.lpName != null && Object.hasOwnProperty.call(message, "lpName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.lpName);
                return writer;
            };

            /**
             * Decodes a QuoteRequest message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.QuoteRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.QuoteRequest} QuoteRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuoteRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.QuoteRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.quoteType = reader.int32();
                        break;
                    case 2:
                        message.symbol = reader.string();
                        break;
                    case 3:
                        message.lpName = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a QuoteRequest message.
             * @function verify
             * @memberof feedbox.market_api.QuoteRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QuoteRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.quoteType != null && message.hasOwnProperty("quoteType"))
                    switch (message.quoteType) {
                    default:
                        return "quoteType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.lpName != null && message.hasOwnProperty("lpName"))
                    if (!$util.isString(message.lpName))
                        return "lpName: string expected";
                return null;
            };

            /**
             * Creates a QuoteRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.QuoteRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.QuoteRequest} QuoteRequest
             */
            QuoteRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.QuoteRequest)
                    return object;
                var message = new $root.feedbox.market_api.QuoteRequest();
                switch (object.quoteType) {
                case "BEST":
                case 0:
                    message.quoteType = 0;
                    break;
                case "VWAP":
                case 1:
                    message.quoteType = 1;
                    break;
                }
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.lpName != null)
                    message.lpName = String(object.lpName);
                return message;
            };

            /**
             * Creates a plain object from a QuoteRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.QuoteRequest
             * @static
             * @param {feedbox.market_api.QuoteRequest} message QuoteRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QuoteRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.quoteType = options.enums === String ? "BEST" : 0;
                    object.symbol = "";
                    object.lpName = "";
                }
                if (message.quoteType != null && message.hasOwnProperty("quoteType"))
                    object.quoteType = options.enums === String ? $root.feedbox.market_api.QuoteRequest.QuoteType[message.quoteType] : message.quoteType;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.lpName != null && message.hasOwnProperty("lpName"))
                    object.lpName = message.lpName;
                return object;
            };

            /**
             * Converts this QuoteRequest to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.QuoteRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QuoteRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * QuoteType enum.
             * @name feedbox.market_api.QuoteRequest.QuoteType
             * @enum {number}
             * @property {number} BEST=0 BEST value
             * @property {number} VWAP=1 VWAP value
             */
            QuoteRequest.QuoteType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "BEST"] = 0;
                values[valuesById[1] = "VWAP"] = 1;
                return values;
            })();

            return QuoteRequest;
        })();

        market_api.Quotes = (function() {

            /**
             * Properties of a Quotes.
             * @memberof feedbox.market_api
             * @interface IQuotes
             * @property {number|Long|null} [bidPrice] Quotes bidPrice
             * @property {number|Long|null} [askPrice] Quotes askPrice
             * @property {number|Long|null} [time] Quotes time
             */

            /**
             * Constructs a new Quotes.
             * @memberof feedbox.market_api
             * @classdesc Represents a Quotes.
             * @implements IQuotes
             * @constructor
             * @param {feedbox.market_api.IQuotes=} [properties] Properties to set
             */
            function Quotes(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Quotes bidPrice.
             * @member {number|Long} bidPrice
             * @memberof feedbox.market_api.Quotes
             * @instance
             */
            Quotes.prototype.bidPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Quotes askPrice.
             * @member {number|Long} askPrice
             * @memberof feedbox.market_api.Quotes
             * @instance
             */
            Quotes.prototype.askPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Quotes time.
             * @member {number|Long} time
             * @memberof feedbox.market_api.Quotes
             * @instance
             */
            Quotes.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new Quotes instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.Quotes
             * @static
             * @param {feedbox.market_api.IQuotes=} [properties] Properties to set
             * @returns {feedbox.market_api.Quotes} Quotes instance
             */
            Quotes.create = function create(properties) {
                return new Quotes(properties);
            };

            /**
             * Encodes the specified Quotes message. Does not implicitly {@link feedbox.market_api.Quotes.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.Quotes
             * @static
             * @param {feedbox.market_api.IQuotes} message Quotes message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Quotes.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.bidPrice != null && Object.hasOwnProperty.call(message, "bidPrice"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.bidPrice);
                if (message.askPrice != null && Object.hasOwnProperty.call(message, "askPrice"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.askPrice);
                if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.time);
                return writer;
            };

            /**
             * Decodes a Quotes message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.Quotes
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.Quotes} Quotes
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Quotes.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.Quotes();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.bidPrice = reader.uint64();
                        break;
                    case 2:
                        message.askPrice = reader.uint64();
                        break;
                    case 3:
                        message.time = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Quotes message.
             * @function verify
             * @memberof feedbox.market_api.Quotes
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Quotes.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.bidPrice != null && message.hasOwnProperty("bidPrice"))
                    if (!$util.isInteger(message.bidPrice) && !(message.bidPrice && $util.isInteger(message.bidPrice.low) && $util.isInteger(message.bidPrice.high)))
                        return "bidPrice: integer|Long expected";
                if (message.askPrice != null && message.hasOwnProperty("askPrice"))
                    if (!$util.isInteger(message.askPrice) && !(message.askPrice && $util.isInteger(message.askPrice.low) && $util.isInteger(message.askPrice.high)))
                        return "askPrice: integer|Long expected";
                if (message.time != null && message.hasOwnProperty("time"))
                    if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                        return "time: integer|Long expected";
                return null;
            };

            /**
             * Creates a Quotes message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.Quotes
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.Quotes} Quotes
             */
            Quotes.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.Quotes)
                    return object;
                var message = new $root.feedbox.market_api.Quotes();
                if (object.bidPrice != null)
                    if ($util.Long)
                        (message.bidPrice = $util.Long.fromValue(object.bidPrice)).unsigned = true;
                    else if (typeof object.bidPrice === "string")
                        message.bidPrice = parseInt(object.bidPrice, 10);
                    else if (typeof object.bidPrice === "number")
                        message.bidPrice = object.bidPrice;
                    else if (typeof object.bidPrice === "object")
                        message.bidPrice = new $util.LongBits(object.bidPrice.low >>> 0, object.bidPrice.high >>> 0).toNumber(true);
                if (object.askPrice != null)
                    if ($util.Long)
                        (message.askPrice = $util.Long.fromValue(object.askPrice)).unsigned = true;
                    else if (typeof object.askPrice === "string")
                        message.askPrice = parseInt(object.askPrice, 10);
                    else if (typeof object.askPrice === "number")
                        message.askPrice = object.askPrice;
                    else if (typeof object.askPrice === "object")
                        message.askPrice = new $util.LongBits(object.askPrice.low >>> 0, object.askPrice.high >>> 0).toNumber(true);
                if (object.time != null)
                    if ($util.Long)
                        (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                    else if (typeof object.time === "string")
                        message.time = parseInt(object.time, 10);
                    else if (typeof object.time === "number")
                        message.time = object.time;
                    else if (typeof object.time === "object")
                        message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a Quotes message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.Quotes
             * @static
             * @param {feedbox.market_api.Quotes} message Quotes
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Quotes.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.bidPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.bidPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.askPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.askPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.time = options.longs === String ? "0" : 0;
                }
                if (message.bidPrice != null && message.hasOwnProperty("bidPrice"))
                    if (typeof message.bidPrice === "number")
                        object.bidPrice = options.longs === String ? String(message.bidPrice) : message.bidPrice;
                    else
                        object.bidPrice = options.longs === String ? $util.Long.prototype.toString.call(message.bidPrice) : options.longs === Number ? new $util.LongBits(message.bidPrice.low >>> 0, message.bidPrice.high >>> 0).toNumber(true) : message.bidPrice;
                if (message.askPrice != null && message.hasOwnProperty("askPrice"))
                    if (typeof message.askPrice === "number")
                        object.askPrice = options.longs === String ? String(message.askPrice) : message.askPrice;
                    else
                        object.askPrice = options.longs === String ? $util.Long.prototype.toString.call(message.askPrice) : options.longs === Number ? new $util.LongBits(message.askPrice.low >>> 0, message.askPrice.high >>> 0).toNumber(true) : message.askPrice;
                if (message.time != null && message.hasOwnProperty("time"))
                    if (typeof message.time === "number")
                        object.time = options.longs === String ? String(message.time) : message.time;
                    else
                        object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                return object;
            };

            /**
             * Converts this Quotes to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.Quotes
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Quotes.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Quotes;
        })();

        /**
         * ChartBarPeriodType enum.
         * @name feedbox.market_api.ChartBarPeriodType
         * @enum {number}
         * @property {number} M1=0 M1 value
         * @property {number} M5=1 M5 value
         * @property {number} M15=2 M15 value
         * @property {number} M30=3 M30 value
         * @property {number} H1=4 H1 value
         * @property {number} H4=5 H4 value
         * @property {number} D1=6 D1 value
         * @property {number} W1=7 W1 value
         * @property {number} MN=8 MN value
         */
        market_api.ChartBarPeriodType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "M1"] = 0;
            values[valuesById[1] = "M5"] = 1;
            values[valuesById[2] = "M15"] = 2;
            values[valuesById[3] = "M30"] = 3;
            values[valuesById[4] = "H1"] = 4;
            values[valuesById[5] = "H4"] = 5;
            values[valuesById[6] = "D1"] = 6;
            values[valuesById[7] = "W1"] = 7;
            values[valuesById[8] = "MN"] = 8;
            return values;
        })();

        market_api.ChartBarRequest = (function() {

            /**
             * Properties of a ChartBarRequest.
             * @memberof feedbox.market_api
             * @interface IChartBarRequest
             * @property {feedbox.market_api.ChartBarPeriodType|null} [periodType] ChartBarRequest periodType
             * @property {string|null} [symbol] ChartBarRequest symbol
             * @property {string|null} [lpName] ChartBarRequest lpName
             */

            /**
             * Constructs a new ChartBarRequest.
             * @memberof feedbox.market_api
             * @classdesc Represents a ChartBarRequest.
             * @implements IChartBarRequest
             * @constructor
             * @param {feedbox.market_api.IChartBarRequest=} [properties] Properties to set
             */
            function ChartBarRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChartBarRequest periodType.
             * @member {feedbox.market_api.ChartBarPeriodType} periodType
             * @memberof feedbox.market_api.ChartBarRequest
             * @instance
             */
            ChartBarRequest.prototype.periodType = 0;

            /**
             * ChartBarRequest symbol.
             * @member {string} symbol
             * @memberof feedbox.market_api.ChartBarRequest
             * @instance
             */
            ChartBarRequest.prototype.symbol = "";

            /**
             * ChartBarRequest lpName.
             * @member {string} lpName
             * @memberof feedbox.market_api.ChartBarRequest
             * @instance
             */
            ChartBarRequest.prototype.lpName = "";

            /**
             * Creates a new ChartBarRequest instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.ChartBarRequest
             * @static
             * @param {feedbox.market_api.IChartBarRequest=} [properties] Properties to set
             * @returns {feedbox.market_api.ChartBarRequest} ChartBarRequest instance
             */
            ChartBarRequest.create = function create(properties) {
                return new ChartBarRequest(properties);
            };

            /**
             * Encodes the specified ChartBarRequest message. Does not implicitly {@link feedbox.market_api.ChartBarRequest.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.ChartBarRequest
             * @static
             * @param {feedbox.market_api.IChartBarRequest} message ChartBarRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChartBarRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.periodType != null && Object.hasOwnProperty.call(message, "periodType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.periodType);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.symbol);
                if (message.lpName != null && Object.hasOwnProperty.call(message, "lpName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.lpName);
                return writer;
            };

            /**
             * Decodes a ChartBarRequest message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.ChartBarRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.ChartBarRequest} ChartBarRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChartBarRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.ChartBarRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.periodType = reader.int32();
                        break;
                    case 2:
                        message.symbol = reader.string();
                        break;
                    case 3:
                        message.lpName = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChartBarRequest message.
             * @function verify
             * @memberof feedbox.market_api.ChartBarRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChartBarRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.periodType != null && message.hasOwnProperty("periodType"))
                    switch (message.periodType) {
                    default:
                        return "periodType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        break;
                    }
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.lpName != null && message.hasOwnProperty("lpName"))
                    if (!$util.isString(message.lpName))
                        return "lpName: string expected";
                return null;
            };

            /**
             * Creates a ChartBarRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.ChartBarRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.ChartBarRequest} ChartBarRequest
             */
            ChartBarRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.ChartBarRequest)
                    return object;
                var message = new $root.feedbox.market_api.ChartBarRequest();
                switch (object.periodType) {
                case "M1":
                case 0:
                    message.periodType = 0;
                    break;
                case "M5":
                case 1:
                    message.periodType = 1;
                    break;
                case "M15":
                case 2:
                    message.periodType = 2;
                    break;
                case "M30":
                case 3:
                    message.periodType = 3;
                    break;
                case "H1":
                case 4:
                    message.periodType = 4;
                    break;
                case "H4":
                case 5:
                    message.periodType = 5;
                    break;
                case "D1":
                case 6:
                    message.periodType = 6;
                    break;
                case "W1":
                case 7:
                    message.periodType = 7;
                    break;
                case "MN":
                case 8:
                    message.periodType = 8;
                    break;
                }
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.lpName != null)
                    message.lpName = String(object.lpName);
                return message;
            };

            /**
             * Creates a plain object from a ChartBarRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.ChartBarRequest
             * @static
             * @param {feedbox.market_api.ChartBarRequest} message ChartBarRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChartBarRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.periodType = options.enums === String ? "M1" : 0;
                    object.symbol = "";
                    object.lpName = "";
                }
                if (message.periodType != null && message.hasOwnProperty("periodType"))
                    object.periodType = options.enums === String ? $root.feedbox.market_api.ChartBarPeriodType[message.periodType] : message.periodType;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.lpName != null && message.hasOwnProperty("lpName"))
                    object.lpName = message.lpName;
                return object;
            };

            /**
             * Converts this ChartBarRequest to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.ChartBarRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChartBarRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChartBarRequest;
        })();

        market_api.ChartBarListRequest = (function() {

            /**
             * Properties of a ChartBarListRequest.
             * @memberof feedbox.market_api
             * @interface IChartBarListRequest
             * @property {feedbox.market_api.ChartBarPeriodType|null} [periodType] ChartBarListRequest periodType
             * @property {number|Long|null} [timeTo] ChartBarListRequest timeTo
             * @property {number|null} [limit] ChartBarListRequest limit
             * @property {string|null} [symbol] ChartBarListRequest symbol
             * @property {string|null} [lpName] ChartBarListRequest lpName
             */

            /**
             * Constructs a new ChartBarListRequest.
             * @memberof feedbox.market_api
             * @classdesc Represents a ChartBarListRequest.
             * @implements IChartBarListRequest
             * @constructor
             * @param {feedbox.market_api.IChartBarListRequest=} [properties] Properties to set
             */
            function ChartBarListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChartBarListRequest periodType.
             * @member {feedbox.market_api.ChartBarPeriodType} periodType
             * @memberof feedbox.market_api.ChartBarListRequest
             * @instance
             */
            ChartBarListRequest.prototype.periodType = 0;

            /**
             * ChartBarListRequest timeTo.
             * @member {number|Long} timeTo
             * @memberof feedbox.market_api.ChartBarListRequest
             * @instance
             */
            ChartBarListRequest.prototype.timeTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * ChartBarListRequest limit.
             * @member {number} limit
             * @memberof feedbox.market_api.ChartBarListRequest
             * @instance
             */
            ChartBarListRequest.prototype.limit = 0;

            /**
             * ChartBarListRequest symbol.
             * @member {string} symbol
             * @memberof feedbox.market_api.ChartBarListRequest
             * @instance
             */
            ChartBarListRequest.prototype.symbol = "";

            /**
             * ChartBarListRequest lpName.
             * @member {string} lpName
             * @memberof feedbox.market_api.ChartBarListRequest
             * @instance
             */
            ChartBarListRequest.prototype.lpName = "";

            /**
             * Creates a new ChartBarListRequest instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.ChartBarListRequest
             * @static
             * @param {feedbox.market_api.IChartBarListRequest=} [properties] Properties to set
             * @returns {feedbox.market_api.ChartBarListRequest} ChartBarListRequest instance
             */
            ChartBarListRequest.create = function create(properties) {
                return new ChartBarListRequest(properties);
            };

            /**
             * Encodes the specified ChartBarListRequest message. Does not implicitly {@link feedbox.market_api.ChartBarListRequest.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.ChartBarListRequest
             * @static
             * @param {feedbox.market_api.IChartBarListRequest} message ChartBarListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChartBarListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.periodType != null && Object.hasOwnProperty.call(message, "periodType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.periodType);
                if (message.timeTo != null && Object.hasOwnProperty.call(message, "timeTo"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.timeTo);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.limit);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.symbol);
                if (message.lpName != null && Object.hasOwnProperty.call(message, "lpName"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.lpName);
                return writer;
            };

            /**
             * Decodes a ChartBarListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.ChartBarListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.ChartBarListRequest} ChartBarListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChartBarListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.ChartBarListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.periodType = reader.int32();
                        break;
                    case 2:
                        message.timeTo = reader.int64();
                        break;
                    case 3:
                        message.limit = reader.uint32();
                        break;
                    case 4:
                        message.symbol = reader.string();
                        break;
                    case 5:
                        message.lpName = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChartBarListRequest message.
             * @function verify
             * @memberof feedbox.market_api.ChartBarListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChartBarListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.periodType != null && message.hasOwnProperty("periodType"))
                    switch (message.periodType) {
                    default:
                        return "periodType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        break;
                    }
                if (message.timeTo != null && message.hasOwnProperty("timeTo"))
                    if (!$util.isInteger(message.timeTo) && !(message.timeTo && $util.isInteger(message.timeTo.low) && $util.isInteger(message.timeTo.high)))
                        return "timeTo: integer|Long expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.lpName != null && message.hasOwnProperty("lpName"))
                    if (!$util.isString(message.lpName))
                        return "lpName: string expected";
                return null;
            };

            /**
             * Creates a ChartBarListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.ChartBarListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.ChartBarListRequest} ChartBarListRequest
             */
            ChartBarListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.ChartBarListRequest)
                    return object;
                var message = new $root.feedbox.market_api.ChartBarListRequest();
                switch (object.periodType) {
                case "M1":
                case 0:
                    message.periodType = 0;
                    break;
                case "M5":
                case 1:
                    message.periodType = 1;
                    break;
                case "M15":
                case 2:
                    message.periodType = 2;
                    break;
                case "M30":
                case 3:
                    message.periodType = 3;
                    break;
                case "H1":
                case 4:
                    message.periodType = 4;
                    break;
                case "H4":
                case 5:
                    message.periodType = 5;
                    break;
                case "D1":
                case 6:
                    message.periodType = 6;
                    break;
                case "W1":
                case 7:
                    message.periodType = 7;
                    break;
                case "MN":
                case 8:
                    message.periodType = 8;
                    break;
                }
                if (object.timeTo != null)
                    if ($util.Long)
                        (message.timeTo = $util.Long.fromValue(object.timeTo)).unsigned = false;
                    else if (typeof object.timeTo === "string")
                        message.timeTo = parseInt(object.timeTo, 10);
                    else if (typeof object.timeTo === "number")
                        message.timeTo = object.timeTo;
                    else if (typeof object.timeTo === "object")
                        message.timeTo = new $util.LongBits(object.timeTo.low >>> 0, object.timeTo.high >>> 0).toNumber();
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.lpName != null)
                    message.lpName = String(object.lpName);
                return message;
            };

            /**
             * Creates a plain object from a ChartBarListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.ChartBarListRequest
             * @static
             * @param {feedbox.market_api.ChartBarListRequest} message ChartBarListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChartBarListRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.periodType = options.enums === String ? "M1" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.timeTo = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.timeTo = options.longs === String ? "0" : 0;
                    object.limit = 0;
                    object.symbol = "";
                    object.lpName = "";
                }
                if (message.periodType != null && message.hasOwnProperty("periodType"))
                    object.periodType = options.enums === String ? $root.feedbox.market_api.ChartBarPeriodType[message.periodType] : message.periodType;
                if (message.timeTo != null && message.hasOwnProperty("timeTo"))
                    if (typeof message.timeTo === "number")
                        object.timeTo = options.longs === String ? String(message.timeTo) : message.timeTo;
                    else
                        object.timeTo = options.longs === String ? $util.Long.prototype.toString.call(message.timeTo) : options.longs === Number ? new $util.LongBits(message.timeTo.low >>> 0, message.timeTo.high >>> 0).toNumber() : message.timeTo;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.lpName != null && message.hasOwnProperty("lpName"))
                    object.lpName = message.lpName;
                return object;
            };

            /**
             * Converts this ChartBarListRequest to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.ChartBarListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChartBarListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChartBarListRequest;
        })();

        market_api.ChartBar = (function() {

            /**
             * Properties of a ChartBar.
             * @memberof feedbox.market_api
             * @interface IChartBar
             * @property {number|Long|null} [openTime] ChartBar openTime
             * @property {number|Long|null} [openPrice] ChartBar openPrice
             * @property {number|Long|null} [closePrice] ChartBar closePrice
             * @property {number|Long|null} [highestPrice] ChartBar highestPrice
             * @property {number|Long|null} [lowestPrice] ChartBar lowestPrice
             * @property {number|Long|null} [tickVolume] ChartBar tickVolume
             */

            /**
             * Constructs a new ChartBar.
             * @memberof feedbox.market_api
             * @classdesc Represents a ChartBar.
             * @implements IChartBar
             * @constructor
             * @param {feedbox.market_api.IChartBar=} [properties] Properties to set
             */
            function ChartBar(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChartBar openTime.
             * @member {number|Long} openTime
             * @memberof feedbox.market_api.ChartBar
             * @instance
             */
            ChartBar.prototype.openTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * ChartBar openPrice.
             * @member {number|Long} openPrice
             * @memberof feedbox.market_api.ChartBar
             * @instance
             */
            ChartBar.prototype.openPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ChartBar closePrice.
             * @member {number|Long} closePrice
             * @memberof feedbox.market_api.ChartBar
             * @instance
             */
            ChartBar.prototype.closePrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ChartBar highestPrice.
             * @member {number|Long} highestPrice
             * @memberof feedbox.market_api.ChartBar
             * @instance
             */
            ChartBar.prototype.highestPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ChartBar lowestPrice.
             * @member {number|Long} lowestPrice
             * @memberof feedbox.market_api.ChartBar
             * @instance
             */
            ChartBar.prototype.lowestPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * ChartBar tickVolume.
             * @member {number|Long} tickVolume
             * @memberof feedbox.market_api.ChartBar
             * @instance
             */
            ChartBar.prototype.tickVolume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new ChartBar instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.ChartBar
             * @static
             * @param {feedbox.market_api.IChartBar=} [properties] Properties to set
             * @returns {feedbox.market_api.ChartBar} ChartBar instance
             */
            ChartBar.create = function create(properties) {
                return new ChartBar(properties);
            };

            /**
             * Encodes the specified ChartBar message. Does not implicitly {@link feedbox.market_api.ChartBar.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.ChartBar
             * @static
             * @param {feedbox.market_api.IChartBar} message ChartBar message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChartBar.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.openTime != null && Object.hasOwnProperty.call(message, "openTime"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.openTime);
                if (message.openPrice != null && Object.hasOwnProperty.call(message, "openPrice"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.openPrice);
                if (message.closePrice != null && Object.hasOwnProperty.call(message, "closePrice"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.closePrice);
                if (message.highestPrice != null && Object.hasOwnProperty.call(message, "highestPrice"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.highestPrice);
                if (message.lowestPrice != null && Object.hasOwnProperty.call(message, "lowestPrice"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.lowestPrice);
                if (message.tickVolume != null && Object.hasOwnProperty.call(message, "tickVolume"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.tickVolume);
                return writer;
            };

            /**
             * Decodes a ChartBar message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.ChartBar
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.ChartBar} ChartBar
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChartBar.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.ChartBar();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.openTime = reader.int64();
                        break;
                    case 2:
                        message.openPrice = reader.uint64();
                        break;
                    case 3:
                        message.closePrice = reader.uint64();
                        break;
                    case 4:
                        message.highestPrice = reader.uint64();
                        break;
                    case 5:
                        message.lowestPrice = reader.uint64();
                        break;
                    case 6:
                        message.tickVolume = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChartBar message.
             * @function verify
             * @memberof feedbox.market_api.ChartBar
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChartBar.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.openTime != null && message.hasOwnProperty("openTime"))
                    if (!$util.isInteger(message.openTime) && !(message.openTime && $util.isInteger(message.openTime.low) && $util.isInteger(message.openTime.high)))
                        return "openTime: integer|Long expected";
                if (message.openPrice != null && message.hasOwnProperty("openPrice"))
                    if (!$util.isInteger(message.openPrice) && !(message.openPrice && $util.isInteger(message.openPrice.low) && $util.isInteger(message.openPrice.high)))
                        return "openPrice: integer|Long expected";
                if (message.closePrice != null && message.hasOwnProperty("closePrice"))
                    if (!$util.isInteger(message.closePrice) && !(message.closePrice && $util.isInteger(message.closePrice.low) && $util.isInteger(message.closePrice.high)))
                        return "closePrice: integer|Long expected";
                if (message.highestPrice != null && message.hasOwnProperty("highestPrice"))
                    if (!$util.isInteger(message.highestPrice) && !(message.highestPrice && $util.isInteger(message.highestPrice.low) && $util.isInteger(message.highestPrice.high)))
                        return "highestPrice: integer|Long expected";
                if (message.lowestPrice != null && message.hasOwnProperty("lowestPrice"))
                    if (!$util.isInteger(message.lowestPrice) && !(message.lowestPrice && $util.isInteger(message.lowestPrice.low) && $util.isInteger(message.lowestPrice.high)))
                        return "lowestPrice: integer|Long expected";
                if (message.tickVolume != null && message.hasOwnProperty("tickVolume"))
                    if (!$util.isInteger(message.tickVolume) && !(message.tickVolume && $util.isInteger(message.tickVolume.low) && $util.isInteger(message.tickVolume.high)))
                        return "tickVolume: integer|Long expected";
                return null;
            };

            /**
             * Creates a ChartBar message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.ChartBar
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.ChartBar} ChartBar
             */
            ChartBar.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.ChartBar)
                    return object;
                var message = new $root.feedbox.market_api.ChartBar();
                if (object.openTime != null)
                    if ($util.Long)
                        (message.openTime = $util.Long.fromValue(object.openTime)).unsigned = false;
                    else if (typeof object.openTime === "string")
                        message.openTime = parseInt(object.openTime, 10);
                    else if (typeof object.openTime === "number")
                        message.openTime = object.openTime;
                    else if (typeof object.openTime === "object")
                        message.openTime = new $util.LongBits(object.openTime.low >>> 0, object.openTime.high >>> 0).toNumber();
                if (object.openPrice != null)
                    if ($util.Long)
                        (message.openPrice = $util.Long.fromValue(object.openPrice)).unsigned = true;
                    else if (typeof object.openPrice === "string")
                        message.openPrice = parseInt(object.openPrice, 10);
                    else if (typeof object.openPrice === "number")
                        message.openPrice = object.openPrice;
                    else if (typeof object.openPrice === "object")
                        message.openPrice = new $util.LongBits(object.openPrice.low >>> 0, object.openPrice.high >>> 0).toNumber(true);
                if (object.closePrice != null)
                    if ($util.Long)
                        (message.closePrice = $util.Long.fromValue(object.closePrice)).unsigned = true;
                    else if (typeof object.closePrice === "string")
                        message.closePrice = parseInt(object.closePrice, 10);
                    else if (typeof object.closePrice === "number")
                        message.closePrice = object.closePrice;
                    else if (typeof object.closePrice === "object")
                        message.closePrice = new $util.LongBits(object.closePrice.low >>> 0, object.closePrice.high >>> 0).toNumber(true);
                if (object.highestPrice != null)
                    if ($util.Long)
                        (message.highestPrice = $util.Long.fromValue(object.highestPrice)).unsigned = true;
                    else if (typeof object.highestPrice === "string")
                        message.highestPrice = parseInt(object.highestPrice, 10);
                    else if (typeof object.highestPrice === "number")
                        message.highestPrice = object.highestPrice;
                    else if (typeof object.highestPrice === "object")
                        message.highestPrice = new $util.LongBits(object.highestPrice.low >>> 0, object.highestPrice.high >>> 0).toNumber(true);
                if (object.lowestPrice != null)
                    if ($util.Long)
                        (message.lowestPrice = $util.Long.fromValue(object.lowestPrice)).unsigned = true;
                    else if (typeof object.lowestPrice === "string")
                        message.lowestPrice = parseInt(object.lowestPrice, 10);
                    else if (typeof object.lowestPrice === "number")
                        message.lowestPrice = object.lowestPrice;
                    else if (typeof object.lowestPrice === "object")
                        message.lowestPrice = new $util.LongBits(object.lowestPrice.low >>> 0, object.lowestPrice.high >>> 0).toNumber(true);
                if (object.tickVolume != null)
                    if ($util.Long)
                        (message.tickVolume = $util.Long.fromValue(object.tickVolume)).unsigned = true;
                    else if (typeof object.tickVolume === "string")
                        message.tickVolume = parseInt(object.tickVolume, 10);
                    else if (typeof object.tickVolume === "number")
                        message.tickVolume = object.tickVolume;
                    else if (typeof object.tickVolume === "object")
                        message.tickVolume = new $util.LongBits(object.tickVolume.low >>> 0, object.tickVolume.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a ChartBar message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.ChartBar
             * @static
             * @param {feedbox.market_api.ChartBar} message ChartBar
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChartBar.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.openTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.openTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.openPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.openPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.closePrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.closePrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.highestPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.highestPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.lowestPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lowestPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.tickVolume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tickVolume = options.longs === String ? "0" : 0;
                }
                if (message.openTime != null && message.hasOwnProperty("openTime"))
                    if (typeof message.openTime === "number")
                        object.openTime = options.longs === String ? String(message.openTime) : message.openTime;
                    else
                        object.openTime = options.longs === String ? $util.Long.prototype.toString.call(message.openTime) : options.longs === Number ? new $util.LongBits(message.openTime.low >>> 0, message.openTime.high >>> 0).toNumber() : message.openTime;
                if (message.openPrice != null && message.hasOwnProperty("openPrice"))
                    if (typeof message.openPrice === "number")
                        object.openPrice = options.longs === String ? String(message.openPrice) : message.openPrice;
                    else
                        object.openPrice = options.longs === String ? $util.Long.prototype.toString.call(message.openPrice) : options.longs === Number ? new $util.LongBits(message.openPrice.low >>> 0, message.openPrice.high >>> 0).toNumber(true) : message.openPrice;
                if (message.closePrice != null && message.hasOwnProperty("closePrice"))
                    if (typeof message.closePrice === "number")
                        object.closePrice = options.longs === String ? String(message.closePrice) : message.closePrice;
                    else
                        object.closePrice = options.longs === String ? $util.Long.prototype.toString.call(message.closePrice) : options.longs === Number ? new $util.LongBits(message.closePrice.low >>> 0, message.closePrice.high >>> 0).toNumber(true) : message.closePrice;
                if (message.highestPrice != null && message.hasOwnProperty("highestPrice"))
                    if (typeof message.highestPrice === "number")
                        object.highestPrice = options.longs === String ? String(message.highestPrice) : message.highestPrice;
                    else
                        object.highestPrice = options.longs === String ? $util.Long.prototype.toString.call(message.highestPrice) : options.longs === Number ? new $util.LongBits(message.highestPrice.low >>> 0, message.highestPrice.high >>> 0).toNumber(true) : message.highestPrice;
                if (message.lowestPrice != null && message.hasOwnProperty("lowestPrice"))
                    if (typeof message.lowestPrice === "number")
                        object.lowestPrice = options.longs === String ? String(message.lowestPrice) : message.lowestPrice;
                    else
                        object.lowestPrice = options.longs === String ? $util.Long.prototype.toString.call(message.lowestPrice) : options.longs === Number ? new $util.LongBits(message.lowestPrice.low >>> 0, message.lowestPrice.high >>> 0).toNumber(true) : message.lowestPrice;
                if (message.tickVolume != null && message.hasOwnProperty("tickVolume"))
                    if (typeof message.tickVolume === "number")
                        object.tickVolume = options.longs === String ? String(message.tickVolume) : message.tickVolume;
                    else
                        object.tickVolume = options.longs === String ? $util.Long.prototype.toString.call(message.tickVolume) : options.longs === Number ? new $util.LongBits(message.tickVolume.low >>> 0, message.tickVolume.high >>> 0).toNumber(true) : message.tickVolume;
                return object;
            };

            /**
             * Converts this ChartBar to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.ChartBar
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChartBar.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChartBar;
        })();

        market_api.ChartBarList = (function() {

            /**
             * Properties of a ChartBarList.
             * @memberof feedbox.market_api
             * @interface IChartBarList
             * @property {Array.<feedbox.market_api.IChartBar>|null} [chartBar] ChartBarList chartBar
             */

            /**
             * Constructs a new ChartBarList.
             * @memberof feedbox.market_api
             * @classdesc Represents a ChartBarList.
             * @implements IChartBarList
             * @constructor
             * @param {feedbox.market_api.IChartBarList=} [properties] Properties to set
             */
            function ChartBarList(properties) {
                this.chartBar = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ChartBarList chartBar.
             * @member {Array.<feedbox.market_api.IChartBar>} chartBar
             * @memberof feedbox.market_api.ChartBarList
             * @instance
             */
            ChartBarList.prototype.chartBar = $util.emptyArray;

            /**
             * Creates a new ChartBarList instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.ChartBarList
             * @static
             * @param {feedbox.market_api.IChartBarList=} [properties] Properties to set
             * @returns {feedbox.market_api.ChartBarList} ChartBarList instance
             */
            ChartBarList.create = function create(properties) {
                return new ChartBarList(properties);
            };

            /**
             * Encodes the specified ChartBarList message. Does not implicitly {@link feedbox.market_api.ChartBarList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.ChartBarList
             * @static
             * @param {feedbox.market_api.IChartBarList} message ChartBarList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChartBarList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.chartBar != null && message.chartBar.length)
                    for (var i = 0; i < message.chartBar.length; ++i)
                        $root.feedbox.market_api.ChartBar.encode(message.chartBar[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ChartBarList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.ChartBarList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.ChartBarList} ChartBarList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChartBarList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.ChartBarList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.chartBar && message.chartBar.length))
                            message.chartBar = [];
                        message.chartBar.push($root.feedbox.market_api.ChartBar.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ChartBarList message.
             * @function verify
             * @memberof feedbox.market_api.ChartBarList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChartBarList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.chartBar != null && message.hasOwnProperty("chartBar")) {
                    if (!Array.isArray(message.chartBar))
                        return "chartBar: array expected";
                    for (var i = 0; i < message.chartBar.length; ++i) {
                        var error = $root.feedbox.market_api.ChartBar.verify(message.chartBar[i]);
                        if (error)
                            return "chartBar." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ChartBarList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.ChartBarList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.ChartBarList} ChartBarList
             */
            ChartBarList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.ChartBarList)
                    return object;
                var message = new $root.feedbox.market_api.ChartBarList();
                if (object.chartBar) {
                    if (!Array.isArray(object.chartBar))
                        throw TypeError(".feedbox.market_api.ChartBarList.chartBar: array expected");
                    message.chartBar = [];
                    for (var i = 0; i < object.chartBar.length; ++i) {
                        if (typeof object.chartBar[i] !== "object")
                            throw TypeError(".feedbox.market_api.ChartBarList.chartBar: object expected");
                        message.chartBar[i] = $root.feedbox.market_api.ChartBar.fromObject(object.chartBar[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ChartBarList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.ChartBarList
             * @static
             * @param {feedbox.market_api.ChartBarList} message ChartBarList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChartBarList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.chartBar = [];
                if (message.chartBar && message.chartBar.length) {
                    object.chartBar = [];
                    for (var j = 0; j < message.chartBar.length; ++j)
                        object.chartBar[j] = $root.feedbox.market_api.ChartBar.toObject(message.chartBar[j], options);
                }
                return object;
            };

            /**
             * Converts this ChartBarList to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.ChartBarList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChartBarList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ChartBarList;
        })();

        market_api.OrderBookRequest = (function() {

            /**
             * Properties of an OrderBookRequest.
             * @memberof feedbox.market_api
             * @interface IOrderBookRequest
             * @property {number|null} [orderBookDepth] OrderBookRequest orderBookDepth
             * @property {string|null} [symbol] OrderBookRequest symbol
             * @property {string|null} [lpName] OrderBookRequest lpName
             */

            /**
             * Constructs a new OrderBookRequest.
             * @memberof feedbox.market_api
             * @classdesc Represents an OrderBookRequest.
             * @implements IOrderBookRequest
             * @constructor
             * @param {feedbox.market_api.IOrderBookRequest=} [properties] Properties to set
             */
            function OrderBookRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderBookRequest orderBookDepth.
             * @member {number} orderBookDepth
             * @memberof feedbox.market_api.OrderBookRequest
             * @instance
             */
            OrderBookRequest.prototype.orderBookDepth = 0;

            /**
             * OrderBookRequest symbol.
             * @member {string} symbol
             * @memberof feedbox.market_api.OrderBookRequest
             * @instance
             */
            OrderBookRequest.prototype.symbol = "";

            /**
             * OrderBookRequest lpName.
             * @member {string} lpName
             * @memberof feedbox.market_api.OrderBookRequest
             * @instance
             */
            OrderBookRequest.prototype.lpName = "";

            /**
             * Creates a new OrderBookRequest instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.OrderBookRequest
             * @static
             * @param {feedbox.market_api.IOrderBookRequest=} [properties] Properties to set
             * @returns {feedbox.market_api.OrderBookRequest} OrderBookRequest instance
             */
            OrderBookRequest.create = function create(properties) {
                return new OrderBookRequest(properties);
            };

            /**
             * Encodes the specified OrderBookRequest message. Does not implicitly {@link feedbox.market_api.OrderBookRequest.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.OrderBookRequest
             * @static
             * @param {feedbox.market_api.IOrderBookRequest} message OrderBookRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderBookRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderBookDepth != null && Object.hasOwnProperty.call(message, "orderBookDepth"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.orderBookDepth);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.symbol);
                if (message.lpName != null && Object.hasOwnProperty.call(message, "lpName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.lpName);
                return writer;
            };

            /**
             * Decodes an OrderBookRequest message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.OrderBookRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.OrderBookRequest} OrderBookRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderBookRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.OrderBookRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderBookDepth = reader.uint32();
                        break;
                    case 2:
                        message.symbol = reader.string();
                        break;
                    case 3:
                        message.lpName = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderBookRequest message.
             * @function verify
             * @memberof feedbox.market_api.OrderBookRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderBookRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderBookDepth != null && message.hasOwnProperty("orderBookDepth"))
                    if (!$util.isInteger(message.orderBookDepth))
                        return "orderBookDepth: integer expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.lpName != null && message.hasOwnProperty("lpName"))
                    if (!$util.isString(message.lpName))
                        return "lpName: string expected";
                return null;
            };

            /**
             * Creates an OrderBookRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.OrderBookRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.OrderBookRequest} OrderBookRequest
             */
            OrderBookRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.OrderBookRequest)
                    return object;
                var message = new $root.feedbox.market_api.OrderBookRequest();
                if (object.orderBookDepth != null)
                    message.orderBookDepth = object.orderBookDepth >>> 0;
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.lpName != null)
                    message.lpName = String(object.lpName);
                return message;
            };

            /**
             * Creates a plain object from an OrderBookRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.OrderBookRequest
             * @static
             * @param {feedbox.market_api.OrderBookRequest} message OrderBookRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderBookRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.orderBookDepth = 0;
                    object.symbol = "";
                    object.lpName = "";
                }
                if (message.orderBookDepth != null && message.hasOwnProperty("orderBookDepth"))
                    object.orderBookDepth = message.orderBookDepth;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.lpName != null && message.hasOwnProperty("lpName"))
                    object.lpName = message.lpName;
                return object;
            };

            /**
             * Converts this OrderBookRequest to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.OrderBookRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderBookRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderBookRequest;
        })();

        market_api.OrderBookUpdates = (function() {

            /**
             * Properties of an OrderBookUpdates.
             * @memberof feedbox.market_api
             * @interface IOrderBookUpdates
             * @property {Array.<feedbox.market_api.OrderBookUpdates.IUpdate>|null} [bidUpdate] OrderBookUpdates bidUpdate
             * @property {Array.<feedbox.market_api.OrderBookUpdates.IUpdate>|null} [askUpdate] OrderBookUpdates askUpdate
             * @property {number|Long|null} [time] OrderBookUpdates time
             * @property {boolean|null} [isSnapshot] OrderBookUpdates isSnapshot
             */

            /**
             * Constructs a new OrderBookUpdates.
             * @memberof feedbox.market_api
             * @classdesc Represents an OrderBookUpdates.
             * @implements IOrderBookUpdates
             * @constructor
             * @param {feedbox.market_api.IOrderBookUpdates=} [properties] Properties to set
             */
            function OrderBookUpdates(properties) {
                this.bidUpdate = [];
                this.askUpdate = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderBookUpdates bidUpdate.
             * @member {Array.<feedbox.market_api.OrderBookUpdates.IUpdate>} bidUpdate
             * @memberof feedbox.market_api.OrderBookUpdates
             * @instance
             */
            OrderBookUpdates.prototype.bidUpdate = $util.emptyArray;

            /**
             * OrderBookUpdates askUpdate.
             * @member {Array.<feedbox.market_api.OrderBookUpdates.IUpdate>} askUpdate
             * @memberof feedbox.market_api.OrderBookUpdates
             * @instance
             */
            OrderBookUpdates.prototype.askUpdate = $util.emptyArray;

            /**
             * OrderBookUpdates time.
             * @member {number|Long} time
             * @memberof feedbox.market_api.OrderBookUpdates
             * @instance
             */
            OrderBookUpdates.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderBookUpdates isSnapshot.
             * @member {boolean} isSnapshot
             * @memberof feedbox.market_api.OrderBookUpdates
             * @instance
             */
            OrderBookUpdates.prototype.isSnapshot = false;

            /**
             * Creates a new OrderBookUpdates instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.OrderBookUpdates
             * @static
             * @param {feedbox.market_api.IOrderBookUpdates=} [properties] Properties to set
             * @returns {feedbox.market_api.OrderBookUpdates} OrderBookUpdates instance
             */
            OrderBookUpdates.create = function create(properties) {
                return new OrderBookUpdates(properties);
            };

            /**
             * Encodes the specified OrderBookUpdates message. Does not implicitly {@link feedbox.market_api.OrderBookUpdates.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.OrderBookUpdates
             * @static
             * @param {feedbox.market_api.IOrderBookUpdates} message OrderBookUpdates message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderBookUpdates.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.bidUpdate != null && message.bidUpdate.length)
                    for (var i = 0; i < message.bidUpdate.length; ++i)
                        $root.feedbox.market_api.OrderBookUpdates.Update.encode(message.bidUpdate[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.askUpdate != null && message.askUpdate.length)
                    for (var i = 0; i < message.askUpdate.length; ++i)
                        $root.feedbox.market_api.OrderBookUpdates.Update.encode(message.askUpdate[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.time);
                if (message.isSnapshot != null && Object.hasOwnProperty.call(message, "isSnapshot"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isSnapshot);
                return writer;
            };

            /**
             * Decodes an OrderBookUpdates message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.OrderBookUpdates
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.OrderBookUpdates} OrderBookUpdates
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderBookUpdates.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.OrderBookUpdates();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.bidUpdate && message.bidUpdate.length))
                            message.bidUpdate = [];
                        message.bidUpdate.push($root.feedbox.market_api.OrderBookUpdates.Update.decode(reader, reader.uint32()));
                        break;
                    case 2:
                        if (!(message.askUpdate && message.askUpdate.length))
                            message.askUpdate = [];
                        message.askUpdate.push($root.feedbox.market_api.OrderBookUpdates.Update.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.time = reader.int64();
                        break;
                    case 4:
                        message.isSnapshot = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderBookUpdates message.
             * @function verify
             * @memberof feedbox.market_api.OrderBookUpdates
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderBookUpdates.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.bidUpdate != null && message.hasOwnProperty("bidUpdate")) {
                    if (!Array.isArray(message.bidUpdate))
                        return "bidUpdate: array expected";
                    for (var i = 0; i < message.bidUpdate.length; ++i) {
                        var error = $root.feedbox.market_api.OrderBookUpdates.Update.verify(message.bidUpdate[i]);
                        if (error)
                            return "bidUpdate." + error;
                    }
                }
                if (message.askUpdate != null && message.hasOwnProperty("askUpdate")) {
                    if (!Array.isArray(message.askUpdate))
                        return "askUpdate: array expected";
                    for (var i = 0; i < message.askUpdate.length; ++i) {
                        var error = $root.feedbox.market_api.OrderBookUpdates.Update.verify(message.askUpdate[i]);
                        if (error)
                            return "askUpdate." + error;
                    }
                }
                if (message.time != null && message.hasOwnProperty("time"))
                    if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                        return "time: integer|Long expected";
                if (message.isSnapshot != null && message.hasOwnProperty("isSnapshot"))
                    if (typeof message.isSnapshot !== "boolean")
                        return "isSnapshot: boolean expected";
                return null;
            };

            /**
             * Creates an OrderBookUpdates message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.OrderBookUpdates
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.OrderBookUpdates} OrderBookUpdates
             */
            OrderBookUpdates.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.OrderBookUpdates)
                    return object;
                var message = new $root.feedbox.market_api.OrderBookUpdates();
                if (object.bidUpdate) {
                    if (!Array.isArray(object.bidUpdate))
                        throw TypeError(".feedbox.market_api.OrderBookUpdates.bidUpdate: array expected");
                    message.bidUpdate = [];
                    for (var i = 0; i < object.bidUpdate.length; ++i) {
                        if (typeof object.bidUpdate[i] !== "object")
                            throw TypeError(".feedbox.market_api.OrderBookUpdates.bidUpdate: object expected");
                        message.bidUpdate[i] = $root.feedbox.market_api.OrderBookUpdates.Update.fromObject(object.bidUpdate[i]);
                    }
                }
                if (object.askUpdate) {
                    if (!Array.isArray(object.askUpdate))
                        throw TypeError(".feedbox.market_api.OrderBookUpdates.askUpdate: array expected");
                    message.askUpdate = [];
                    for (var i = 0; i < object.askUpdate.length; ++i) {
                        if (typeof object.askUpdate[i] !== "object")
                            throw TypeError(".feedbox.market_api.OrderBookUpdates.askUpdate: object expected");
                        message.askUpdate[i] = $root.feedbox.market_api.OrderBookUpdates.Update.fromObject(object.askUpdate[i]);
                    }
                }
                if (object.time != null)
                    if ($util.Long)
                        (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                    else if (typeof object.time === "string")
                        message.time = parseInt(object.time, 10);
                    else if (typeof object.time === "number")
                        message.time = object.time;
                    else if (typeof object.time === "object")
                        message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                if (object.isSnapshot != null)
                    message.isSnapshot = Boolean(object.isSnapshot);
                return message;
            };

            /**
             * Creates a plain object from an OrderBookUpdates message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.OrderBookUpdates
             * @static
             * @param {feedbox.market_api.OrderBookUpdates} message OrderBookUpdates
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderBookUpdates.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.bidUpdate = [];
                    object.askUpdate = [];
                }
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.time = options.longs === String ? "0" : 0;
                    object.isSnapshot = false;
                }
                if (message.bidUpdate && message.bidUpdate.length) {
                    object.bidUpdate = [];
                    for (var j = 0; j < message.bidUpdate.length; ++j)
                        object.bidUpdate[j] = $root.feedbox.market_api.OrderBookUpdates.Update.toObject(message.bidUpdate[j], options);
                }
                if (message.askUpdate && message.askUpdate.length) {
                    object.askUpdate = [];
                    for (var j = 0; j < message.askUpdate.length; ++j)
                        object.askUpdate[j] = $root.feedbox.market_api.OrderBookUpdates.Update.toObject(message.askUpdate[j], options);
                }
                if (message.time != null && message.hasOwnProperty("time"))
                    if (typeof message.time === "number")
                        object.time = options.longs === String ? String(message.time) : message.time;
                    else
                        object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                if (message.isSnapshot != null && message.hasOwnProperty("isSnapshot"))
                    object.isSnapshot = message.isSnapshot;
                return object;
            };

            /**
             * Converts this OrderBookUpdates to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.OrderBookUpdates
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderBookUpdates.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            OrderBookUpdates.Update = (function() {

                /**
                 * Properties of an Update.
                 * @memberof feedbox.market_api.OrderBookUpdates
                 * @interface IUpdate
                 * @property {feedbox.market_api.OrderBookUpdates.Update.Action|null} [action] Update action
                 * @property {feedbox.market_api.OrderBookUpdates.Update.IEntry|null} [entry] Update entry
                 */

                /**
                 * Constructs a new Update.
                 * @memberof feedbox.market_api.OrderBookUpdates
                 * @classdesc Represents an Update.
                 * @implements IUpdate
                 * @constructor
                 * @param {feedbox.market_api.OrderBookUpdates.IUpdate=} [properties] Properties to set
                 */
                function Update(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Update action.
                 * @member {feedbox.market_api.OrderBookUpdates.Update.Action} action
                 * @memberof feedbox.market_api.OrderBookUpdates.Update
                 * @instance
                 */
                Update.prototype.action = 0;

                /**
                 * Update entry.
                 * @member {feedbox.market_api.OrderBookUpdates.Update.IEntry|null|undefined} entry
                 * @memberof feedbox.market_api.OrderBookUpdates.Update
                 * @instance
                 */
                Update.prototype.entry = null;

                /**
                 * Creates a new Update instance using the specified properties.
                 * @function create
                 * @memberof feedbox.market_api.OrderBookUpdates.Update
                 * @static
                 * @param {feedbox.market_api.OrderBookUpdates.IUpdate=} [properties] Properties to set
                 * @returns {feedbox.market_api.OrderBookUpdates.Update} Update instance
                 */
                Update.create = function create(properties) {
                    return new Update(properties);
                };

                /**
                 * Encodes the specified Update message. Does not implicitly {@link feedbox.market_api.OrderBookUpdates.Update.verify|verify} messages.
                 * @function encode
                 * @memberof feedbox.market_api.OrderBookUpdates.Update
                 * @static
                 * @param {feedbox.market_api.OrderBookUpdates.IUpdate} message Update message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Update.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.action);
                    if (message.entry != null && Object.hasOwnProperty.call(message, "entry"))
                        $root.feedbox.market_api.OrderBookUpdates.Update.Entry.encode(message.entry, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes an Update message from the specified reader or buffer.
                 * @function decode
                 * @memberof feedbox.market_api.OrderBookUpdates.Update
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {feedbox.market_api.OrderBookUpdates.Update} Update
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Update.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.OrderBookUpdates.Update();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.action = reader.int32();
                            break;
                        case 2:
                            message.entry = $root.feedbox.market_api.OrderBookUpdates.Update.Entry.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an Update message.
                 * @function verify
                 * @memberof feedbox.market_api.OrderBookUpdates.Update
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Update.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.action != null && message.hasOwnProperty("action"))
                        switch (message.action) {
                        default:
                            return "action: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.entry != null && message.hasOwnProperty("entry")) {
                        var error = $root.feedbox.market_api.OrderBookUpdates.Update.Entry.verify(message.entry);
                        if (error)
                            return "entry." + error;
                    }
                    return null;
                };

                /**
                 * Creates an Update message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof feedbox.market_api.OrderBookUpdates.Update
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {feedbox.market_api.OrderBookUpdates.Update} Update
                 */
                Update.fromObject = function fromObject(object) {
                    if (object instanceof $root.feedbox.market_api.OrderBookUpdates.Update)
                        return object;
                    var message = new $root.feedbox.market_api.OrderBookUpdates.Update();
                    switch (object.action) {
                    case "INSERT":
                    case 0:
                        message.action = 0;
                        break;
                    case "UPDATE":
                    case 1:
                        message.action = 1;
                        break;
                    case "REMOVE":
                    case 2:
                        message.action = 2;
                        break;
                    }
                    if (object.entry != null) {
                        if (typeof object.entry !== "object")
                            throw TypeError(".feedbox.market_api.OrderBookUpdates.Update.entry: object expected");
                        message.entry = $root.feedbox.market_api.OrderBookUpdates.Update.Entry.fromObject(object.entry);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an Update message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof feedbox.market_api.OrderBookUpdates.Update
                 * @static
                 * @param {feedbox.market_api.OrderBookUpdates.Update} message Update
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Update.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.action = options.enums === String ? "INSERT" : 0;
                        object.entry = null;
                    }
                    if (message.action != null && message.hasOwnProperty("action"))
                        object.action = options.enums === String ? $root.feedbox.market_api.OrderBookUpdates.Update.Action[message.action] : message.action;
                    if (message.entry != null && message.hasOwnProperty("entry"))
                        object.entry = $root.feedbox.market_api.OrderBookUpdates.Update.Entry.toObject(message.entry, options);
                    return object;
                };

                /**
                 * Converts this Update to JSON.
                 * @function toJSON
                 * @memberof feedbox.market_api.OrderBookUpdates.Update
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Update.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Action enum.
                 * @name feedbox.market_api.OrderBookUpdates.Update.Action
                 * @enum {number}
                 * @property {number} INSERT=0 INSERT value
                 * @property {number} UPDATE=1 UPDATE value
                 * @property {number} REMOVE=2 REMOVE value
                 */
                Update.Action = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "INSERT"] = 0;
                    values[valuesById[1] = "UPDATE"] = 1;
                    values[valuesById[2] = "REMOVE"] = 2;
                    return values;
                })();

                Update.Entry = (function() {

                    /**
                     * Properties of an Entry.
                     * @memberof feedbox.market_api.OrderBookUpdates.Update
                     * @interface IEntry
                     * @property {string|null} [entryId] Entry entryId
                     * @property {number|Long|null} [price] Entry price
                     * @property {number|Long|null} [volume] Entry volume
                     */

                    /**
                     * Constructs a new Entry.
                     * @memberof feedbox.market_api.OrderBookUpdates.Update
                     * @classdesc Represents an Entry.
                     * @implements IEntry
                     * @constructor
                     * @param {feedbox.market_api.OrderBookUpdates.Update.IEntry=} [properties] Properties to set
                     */
                    function Entry(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Entry entryId.
                     * @member {string} entryId
                     * @memberof feedbox.market_api.OrderBookUpdates.Update.Entry
                     * @instance
                     */
                    Entry.prototype.entryId = "";

                    /**
                     * Entry price.
                     * @member {number|Long} price
                     * @memberof feedbox.market_api.OrderBookUpdates.Update.Entry
                     * @instance
                     */
                    Entry.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Entry volume.
                     * @member {number|Long} volume
                     * @memberof feedbox.market_api.OrderBookUpdates.Update.Entry
                     * @instance
                     */
                    Entry.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new Entry instance using the specified properties.
                     * @function create
                     * @memberof feedbox.market_api.OrderBookUpdates.Update.Entry
                     * @static
                     * @param {feedbox.market_api.OrderBookUpdates.Update.IEntry=} [properties] Properties to set
                     * @returns {feedbox.market_api.OrderBookUpdates.Update.Entry} Entry instance
                     */
                    Entry.create = function create(properties) {
                        return new Entry(properties);
                    };

                    /**
                     * Encodes the specified Entry message. Does not implicitly {@link feedbox.market_api.OrderBookUpdates.Update.Entry.verify|verify} messages.
                     * @function encode
                     * @memberof feedbox.market_api.OrderBookUpdates.Update.Entry
                     * @static
                     * @param {feedbox.market_api.OrderBookUpdates.Update.IEntry} message Entry message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Entry.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.entryId != null && Object.hasOwnProperty.call(message, "entryId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.entryId);
                        if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.price);
                        if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.volume);
                        return writer;
                    };

                    /**
                     * Decodes an Entry message from the specified reader or buffer.
                     * @function decode
                     * @memberof feedbox.market_api.OrderBookUpdates.Update.Entry
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {feedbox.market_api.OrderBookUpdates.Update.Entry} Entry
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Entry.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.OrderBookUpdates.Update.Entry();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.entryId = reader.string();
                                break;
                            case 2:
                                message.price = reader.uint64();
                                break;
                            case 3:
                                message.volume = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Verifies an Entry message.
                     * @function verify
                     * @memberof feedbox.market_api.OrderBookUpdates.Update.Entry
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Entry.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.entryId != null && message.hasOwnProperty("entryId"))
                            if (!$util.isString(message.entryId))
                                return "entryId: string expected";
                        if (message.price != null && message.hasOwnProperty("price"))
                            if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                                return "price: integer|Long expected";
                        if (message.volume != null && message.hasOwnProperty("volume"))
                            if (!$util.isInteger(message.volume) && !(message.volume && $util.isInteger(message.volume.low) && $util.isInteger(message.volume.high)))
                                return "volume: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates an Entry message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof feedbox.market_api.OrderBookUpdates.Update.Entry
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {feedbox.market_api.OrderBookUpdates.Update.Entry} Entry
                     */
                    Entry.fromObject = function fromObject(object) {
                        if (object instanceof $root.feedbox.market_api.OrderBookUpdates.Update.Entry)
                            return object;
                        var message = new $root.feedbox.market_api.OrderBookUpdates.Update.Entry();
                        if (object.entryId != null)
                            message.entryId = String(object.entryId);
                        if (object.price != null)
                            if ($util.Long)
                                (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                            else if (typeof object.price === "string")
                                message.price = parseInt(object.price, 10);
                            else if (typeof object.price === "number")
                                message.price = object.price;
                            else if (typeof object.price === "object")
                                message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                        if (object.volume != null)
                            if ($util.Long)
                                (message.volume = $util.Long.fromValue(object.volume)).unsigned = true;
                            else if (typeof object.volume === "string")
                                message.volume = parseInt(object.volume, 10);
                            else if (typeof object.volume === "number")
                                message.volume = object.volume;
                            else if (typeof object.volume === "object")
                                message.volume = new $util.LongBits(object.volume.low >>> 0, object.volume.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Entry message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof feedbox.market_api.OrderBookUpdates.Update.Entry
                     * @static
                     * @param {feedbox.market_api.OrderBookUpdates.Update.Entry} message Entry
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Entry.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.entryId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.price = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.volume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.volume = options.longs === String ? "0" : 0;
                        }
                        if (message.entryId != null && message.hasOwnProperty("entryId"))
                            object.entryId = message.entryId;
                        if (message.price != null && message.hasOwnProperty("price"))
                            if (typeof message.price === "number")
                                object.price = options.longs === String ? String(message.price) : message.price;
                            else
                                object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                        if (message.volume != null && message.hasOwnProperty("volume"))
                            if (typeof message.volume === "number")
                                object.volume = options.longs === String ? String(message.volume) : message.volume;
                            else
                                object.volume = options.longs === String ? $util.Long.prototype.toString.call(message.volume) : options.longs === Number ? new $util.LongBits(message.volume.low >>> 0, message.volume.high >>> 0).toNumber(true) : message.volume;
                        return object;
                    };

                    /**
                     * Converts this Entry to JSON.
                     * @function toJSON
                     * @memberof feedbox.market_api.OrderBookUpdates.Update.Entry
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Entry.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Entry;
                })();

                return Update;
            })();

            return OrderBookUpdates;
        })();

        market_api.SymbolListRequest = (function() {

            /**
             * Properties of a SymbolListRequest.
             * @memberof feedbox.market_api
             * @interface ISymbolListRequest
             */

            /**
             * Constructs a new SymbolListRequest.
             * @memberof feedbox.market_api
             * @classdesc Represents a SymbolListRequest.
             * @implements ISymbolListRequest
             * @constructor
             * @param {feedbox.market_api.ISymbolListRequest=} [properties] Properties to set
             */
            function SymbolListRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SymbolListRequest instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.SymbolListRequest
             * @static
             * @param {feedbox.market_api.ISymbolListRequest=} [properties] Properties to set
             * @returns {feedbox.market_api.SymbolListRequest} SymbolListRequest instance
             */
            SymbolListRequest.create = function create(properties) {
                return new SymbolListRequest(properties);
            };

            /**
             * Encodes the specified SymbolListRequest message. Does not implicitly {@link feedbox.market_api.SymbolListRequest.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.SymbolListRequest
             * @static
             * @param {feedbox.market_api.ISymbolListRequest} message SymbolListRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SymbolListRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a SymbolListRequest message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.SymbolListRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.SymbolListRequest} SymbolListRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SymbolListRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.SymbolListRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SymbolListRequest message.
             * @function verify
             * @memberof feedbox.market_api.SymbolListRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SymbolListRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SymbolListRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.SymbolListRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.SymbolListRequest} SymbolListRequest
             */
            SymbolListRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.SymbolListRequest)
                    return object;
                return new $root.feedbox.market_api.SymbolListRequest();
            };

            /**
             * Creates a plain object from a SymbolListRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.SymbolListRequest
             * @static
             * @param {feedbox.market_api.SymbolListRequest} message SymbolListRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SymbolListRequest.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SymbolListRequest to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.SymbolListRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SymbolListRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SymbolListRequest;
        })();

        market_api.Symbol = (function() {

            /**
             * Properties of a Symbol.
             * @memberof feedbox.market_api
             * @interface ISymbol
             * @property {string|null} [symbol] Symbol symbol
             * @property {string|null} [path] Symbol path
             * @property {number|Long|null} [lotSize] Symbol lotSize
             * @property {number|null} [digits] Symbol digits
             * @property {string|null} [profitCurrency] Symbol profitCurrency
             * @property {string|null} [marginCurrency] Symbol marginCurrency
             * @property {string|null} [baseCurrency] Symbol baseCurrency
             * @property {string|null} [convertationSymbolPostfix] Symbol convertationSymbolPostfix
             * @property {feedbox.market_api.Symbol.CalculationType|null} [calculationType] Symbol calculationType
             * @property {Array.<feedbox.market_api.Symbol.TradingType>|null} [tradingType] Symbol tradingType
             * @property {number|null} [buyCorrectionFactor] Symbol buyCorrectionFactor
             * @property {number|null} [sellCorrectionFactor] Symbol sellCorrectionFactor
             * @property {string|null} [description] Symbol description
             * @property {string|null} [timezoneName] Symbol timezoneName
             * @property {feedbox.market_api.Symbol.ITradingTime|null} [tradingTime] Symbol tradingTime
             * @property {number|Long|null} [volumeMin] Symbol volumeMin
             * @property {number|Long|null} [volumeStep] Symbol volumeStep
             * @property {number|Long|null} [volumeMax] Symbol volumeMax
             */

            /**
             * Constructs a new Symbol.
             * @memberof feedbox.market_api
             * @classdesc Represents a Symbol.
             * @implements ISymbol
             * @constructor
             * @param {feedbox.market_api.ISymbol=} [properties] Properties to set
             */
            function Symbol(properties) {
                this.tradingType = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Symbol symbol.
             * @member {string} symbol
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.symbol = "";

            /**
             * Symbol path.
             * @member {string} path
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.path = "";

            /**
             * Symbol lotSize.
             * @member {number|Long} lotSize
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.lotSize = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Symbol digits.
             * @member {number} digits
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.digits = 0;

            /**
             * Symbol profitCurrency.
             * @member {string} profitCurrency
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.profitCurrency = "";

            /**
             * Symbol marginCurrency.
             * @member {string} marginCurrency
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.marginCurrency = "";

            /**
             * Symbol baseCurrency.
             * @member {string} baseCurrency
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.baseCurrency = "";

            /**
             * Symbol convertationSymbolPostfix.
             * @member {string} convertationSymbolPostfix
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.convertationSymbolPostfix = "";

            /**
             * Symbol calculationType.
             * @member {feedbox.market_api.Symbol.CalculationType} calculationType
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.calculationType = 0;

            /**
             * Symbol tradingType.
             * @member {Array.<feedbox.market_api.Symbol.TradingType>} tradingType
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.tradingType = $util.emptyArray;

            /**
             * Symbol buyCorrectionFactor.
             * @member {number} buyCorrectionFactor
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.buyCorrectionFactor = 0;

            /**
             * Symbol sellCorrectionFactor.
             * @member {number} sellCorrectionFactor
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.sellCorrectionFactor = 0;

            /**
             * Symbol description.
             * @member {string} description
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.description = "";

            /**
             * Symbol timezoneName.
             * @member {string} timezoneName
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.timezoneName = "";

            /**
             * Symbol tradingTime.
             * @member {feedbox.market_api.Symbol.ITradingTime|null|undefined} tradingTime
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.tradingTime = null;

            /**
             * Symbol volumeMin.
             * @member {number|Long} volumeMin
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.volumeMin = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Symbol volumeStep.
             * @member {number|Long} volumeStep
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.volumeStep = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Symbol volumeMax.
             * @member {number|Long} volumeMax
             * @memberof feedbox.market_api.Symbol
             * @instance
             */
            Symbol.prototype.volumeMax = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new Symbol instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.Symbol
             * @static
             * @param {feedbox.market_api.ISymbol=} [properties] Properties to set
             * @returns {feedbox.market_api.Symbol} Symbol instance
             */
            Symbol.create = function create(properties) {
                return new Symbol(properties);
            };

            /**
             * Encodes the specified Symbol message. Does not implicitly {@link feedbox.market_api.Symbol.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.Symbol
             * @static
             * @param {feedbox.market_api.ISymbol} message Symbol message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Symbol.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbol);
                if (message.path != null && Object.hasOwnProperty.call(message, "path"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.path);
                if (message.lotSize != null && Object.hasOwnProperty.call(message, "lotSize"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.lotSize);
                if (message.digits != null && Object.hasOwnProperty.call(message, "digits"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.digits);
                if (message.profitCurrency != null && Object.hasOwnProperty.call(message, "profitCurrency"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.profitCurrency);
                if (message.calculationType != null && Object.hasOwnProperty.call(message, "calculationType"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.calculationType);
                if (message.buyCorrectionFactor != null && Object.hasOwnProperty.call(message, "buyCorrectionFactor"))
                    writer.uint32(/* id 8, wireType 1 =*/65).double(message.buyCorrectionFactor);
                if (message.sellCorrectionFactor != null && Object.hasOwnProperty.call(message, "sellCorrectionFactor"))
                    writer.uint32(/* id 9, wireType 1 =*/73).double(message.sellCorrectionFactor);
                if (message.marginCurrency != null && Object.hasOwnProperty.call(message, "marginCurrency"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.marginCurrency);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.description);
                if (message.timezoneName != null && Object.hasOwnProperty.call(message, "timezoneName"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.timezoneName);
                if (message.tradingTime != null && Object.hasOwnProperty.call(message, "tradingTime"))
                    $root.feedbox.market_api.Symbol.TradingTime.encode(message.tradingTime, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.baseCurrency != null && Object.hasOwnProperty.call(message, "baseCurrency"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.baseCurrency);
                if (message.convertationSymbolPostfix != null && Object.hasOwnProperty.call(message, "convertationSymbolPostfix"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.convertationSymbolPostfix);
                if (message.tradingType != null && message.tradingType.length) {
                    writer.uint32(/* id 16, wireType 2 =*/130).fork();
                    for (var i = 0; i < message.tradingType.length; ++i)
                        writer.int32(message.tradingType[i]);
                    writer.ldelim();
                }
                if (message.volumeMin != null && Object.hasOwnProperty.call(message, "volumeMin"))
                    writer.uint32(/* id 17, wireType 0 =*/136).uint64(message.volumeMin);
                if (message.volumeStep != null && Object.hasOwnProperty.call(message, "volumeStep"))
                    writer.uint32(/* id 18, wireType 0 =*/144).uint64(message.volumeStep);
                if (message.volumeMax != null && Object.hasOwnProperty.call(message, "volumeMax"))
                    writer.uint32(/* id 19, wireType 0 =*/152).uint64(message.volumeMax);
                return writer;
            };

            /**
             * Decodes a Symbol message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.Symbol
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.Symbol} Symbol
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Symbol.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.Symbol();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.symbol = reader.string();
                        break;
                    case 2:
                        message.path = reader.string();
                        break;
                    case 3:
                        message.lotSize = reader.uint64();
                        break;
                    case 4:
                        message.digits = reader.int32();
                        break;
                    case 6:
                        message.profitCurrency = reader.string();
                        break;
                    case 10:
                        message.marginCurrency = reader.string();
                        break;
                    case 14:
                        message.baseCurrency = reader.string();
                        break;
                    case 15:
                        message.convertationSymbolPostfix = reader.string();
                        break;
                    case 7:
                        message.calculationType = reader.int32();
                        break;
                    case 16:
                        if (!(message.tradingType && message.tradingType.length))
                            message.tradingType = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.tradingType.push(reader.int32());
                        } else
                            message.tradingType.push(reader.int32());
                        break;
                    case 8:
                        message.buyCorrectionFactor = reader.double();
                        break;
                    case 9:
                        message.sellCorrectionFactor = reader.double();
                        break;
                    case 11:
                        message.description = reader.string();
                        break;
                    case 12:
                        message.timezoneName = reader.string();
                        break;
                    case 13:
                        message.tradingTime = $root.feedbox.market_api.Symbol.TradingTime.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.volumeMin = reader.uint64();
                        break;
                    case 18:
                        message.volumeStep = reader.uint64();
                        break;
                    case 19:
                        message.volumeMax = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Symbol message.
             * @function verify
             * @memberof feedbox.market_api.Symbol
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Symbol.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.path != null && message.hasOwnProperty("path"))
                    if (!$util.isString(message.path))
                        return "path: string expected";
                if (message.lotSize != null && message.hasOwnProperty("lotSize"))
                    if (!$util.isInteger(message.lotSize) && !(message.lotSize && $util.isInteger(message.lotSize.low) && $util.isInteger(message.lotSize.high)))
                        return "lotSize: integer|Long expected";
                if (message.digits != null && message.hasOwnProperty("digits"))
                    if (!$util.isInteger(message.digits))
                        return "digits: integer expected";
                if (message.profitCurrency != null && message.hasOwnProperty("profitCurrency"))
                    if (!$util.isString(message.profitCurrency))
                        return "profitCurrency: string expected";
                if (message.marginCurrency != null && message.hasOwnProperty("marginCurrency"))
                    if (!$util.isString(message.marginCurrency))
                        return "marginCurrency: string expected";
                if (message.baseCurrency != null && message.hasOwnProperty("baseCurrency"))
                    if (!$util.isString(message.baseCurrency))
                        return "baseCurrency: string expected";
                if (message.convertationSymbolPostfix != null && message.hasOwnProperty("convertationSymbolPostfix"))
                    if (!$util.isString(message.convertationSymbolPostfix))
                        return "convertationSymbolPostfix: string expected";
                if (message.calculationType != null && message.hasOwnProperty("calculationType"))
                    switch (message.calculationType) {
                    default:
                        return "calculationType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.tradingType != null && message.hasOwnProperty("tradingType")) {
                    if (!Array.isArray(message.tradingType))
                        return "tradingType: array expected";
                    for (var i = 0; i < message.tradingType.length; ++i)
                        switch (message.tradingType[i]) {
                        default:
                            return "tradingType: enum value[] expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                }
                if (message.buyCorrectionFactor != null && message.hasOwnProperty("buyCorrectionFactor"))
                    if (typeof message.buyCorrectionFactor !== "number")
                        return "buyCorrectionFactor: number expected";
                if (message.sellCorrectionFactor != null && message.hasOwnProperty("sellCorrectionFactor"))
                    if (typeof message.sellCorrectionFactor !== "number")
                        return "sellCorrectionFactor: number expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.timezoneName != null && message.hasOwnProperty("timezoneName"))
                    if (!$util.isString(message.timezoneName))
                        return "timezoneName: string expected";
                if (message.tradingTime != null && message.hasOwnProperty("tradingTime")) {
                    var error = $root.feedbox.market_api.Symbol.TradingTime.verify(message.tradingTime);
                    if (error)
                        return "tradingTime." + error;
                }
                if (message.volumeMin != null && message.hasOwnProperty("volumeMin"))
                    if (!$util.isInteger(message.volumeMin) && !(message.volumeMin && $util.isInteger(message.volumeMin.low) && $util.isInteger(message.volumeMin.high)))
                        return "volumeMin: integer|Long expected";
                if (message.volumeStep != null && message.hasOwnProperty("volumeStep"))
                    if (!$util.isInteger(message.volumeStep) && !(message.volumeStep && $util.isInteger(message.volumeStep.low) && $util.isInteger(message.volumeStep.high)))
                        return "volumeStep: integer|Long expected";
                if (message.volumeMax != null && message.hasOwnProperty("volumeMax"))
                    if (!$util.isInteger(message.volumeMax) && !(message.volumeMax && $util.isInteger(message.volumeMax.low) && $util.isInteger(message.volumeMax.high)))
                        return "volumeMax: integer|Long expected";
                return null;
            };

            /**
             * Creates a Symbol message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.Symbol
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.Symbol} Symbol
             */
            Symbol.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.Symbol)
                    return object;
                var message = new $root.feedbox.market_api.Symbol();
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.path != null)
                    message.path = String(object.path);
                if (object.lotSize != null)
                    if ($util.Long)
                        (message.lotSize = $util.Long.fromValue(object.lotSize)).unsigned = true;
                    else if (typeof object.lotSize === "string")
                        message.lotSize = parseInt(object.lotSize, 10);
                    else if (typeof object.lotSize === "number")
                        message.lotSize = object.lotSize;
                    else if (typeof object.lotSize === "object")
                        message.lotSize = new $util.LongBits(object.lotSize.low >>> 0, object.lotSize.high >>> 0).toNumber(true);
                if (object.digits != null)
                    message.digits = object.digits | 0;
                if (object.profitCurrency != null)
                    message.profitCurrency = String(object.profitCurrency);
                if (object.marginCurrency != null)
                    message.marginCurrency = String(object.marginCurrency);
                if (object.baseCurrency != null)
                    message.baseCurrency = String(object.baseCurrency);
                if (object.convertationSymbolPostfix != null)
                    message.convertationSymbolPostfix = String(object.convertationSymbolPostfix);
                switch (object.calculationType) {
                case "UNKNOWN":
                case 0:
                    message.calculationType = 0;
                    break;
                case "FOREX":
                case 1:
                    message.calculationType = 1;
                    break;
                case "CFD":
                case 2:
                    message.calculationType = 2;
                    break;
                case "CFD_LEVERAGE":
                case 3:
                    message.calculationType = 3;
                    break;
                }
                if (object.tradingType) {
                    if (!Array.isArray(object.tradingType))
                        throw TypeError(".feedbox.market_api.Symbol.tradingType: array expected");
                    message.tradingType = [];
                    for (var i = 0; i < object.tradingType.length; ++i)
                        switch (object.tradingType[i]) {
                        default:
                        case "MARGIN_TRADING":
                        case 0:
                            message.tradingType[i] = 0;
                            break;
                        case "CURRENCY_EXCHANGE":
                        case 1:
                            message.tradingType[i] = 1;
                            break;
                        case "BET_TRADING":
                        case 2:
                            message.tradingType[i] = 2;
                            break;
                        }
                }
                if (object.buyCorrectionFactor != null)
                    message.buyCorrectionFactor = Number(object.buyCorrectionFactor);
                if (object.sellCorrectionFactor != null)
                    message.sellCorrectionFactor = Number(object.sellCorrectionFactor);
                if (object.description != null)
                    message.description = String(object.description);
                if (object.timezoneName != null)
                    message.timezoneName = String(object.timezoneName);
                if (object.tradingTime != null) {
                    if (typeof object.tradingTime !== "object")
                        throw TypeError(".feedbox.market_api.Symbol.tradingTime: object expected");
                    message.tradingTime = $root.feedbox.market_api.Symbol.TradingTime.fromObject(object.tradingTime);
                }
                if (object.volumeMin != null)
                    if ($util.Long)
                        (message.volumeMin = $util.Long.fromValue(object.volumeMin)).unsigned = true;
                    else if (typeof object.volumeMin === "string")
                        message.volumeMin = parseInt(object.volumeMin, 10);
                    else if (typeof object.volumeMin === "number")
                        message.volumeMin = object.volumeMin;
                    else if (typeof object.volumeMin === "object")
                        message.volumeMin = new $util.LongBits(object.volumeMin.low >>> 0, object.volumeMin.high >>> 0).toNumber(true);
                if (object.volumeStep != null)
                    if ($util.Long)
                        (message.volumeStep = $util.Long.fromValue(object.volumeStep)).unsigned = true;
                    else if (typeof object.volumeStep === "string")
                        message.volumeStep = parseInt(object.volumeStep, 10);
                    else if (typeof object.volumeStep === "number")
                        message.volumeStep = object.volumeStep;
                    else if (typeof object.volumeStep === "object")
                        message.volumeStep = new $util.LongBits(object.volumeStep.low >>> 0, object.volumeStep.high >>> 0).toNumber(true);
                if (object.volumeMax != null)
                    if ($util.Long)
                        (message.volumeMax = $util.Long.fromValue(object.volumeMax)).unsigned = true;
                    else if (typeof object.volumeMax === "string")
                        message.volumeMax = parseInt(object.volumeMax, 10);
                    else if (typeof object.volumeMax === "number")
                        message.volumeMax = object.volumeMax;
                    else if (typeof object.volumeMax === "object")
                        message.volumeMax = new $util.LongBits(object.volumeMax.low >>> 0, object.volumeMax.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a Symbol message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.Symbol
             * @static
             * @param {feedbox.market_api.Symbol} message Symbol
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Symbol.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.tradingType = [];
                if (options.defaults) {
                    object.symbol = "";
                    object.path = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.lotSize = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lotSize = options.longs === String ? "0" : 0;
                    object.digits = 0;
                    object.profitCurrency = "";
                    object.calculationType = options.enums === String ? "UNKNOWN" : 0;
                    object.buyCorrectionFactor = 0;
                    object.sellCorrectionFactor = 0;
                    object.marginCurrency = "";
                    object.description = "";
                    object.timezoneName = "";
                    object.tradingTime = null;
                    object.baseCurrency = "";
                    object.convertationSymbolPostfix = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.volumeMin = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.volumeMin = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.volumeStep = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.volumeStep = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.volumeMax = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.volumeMax = options.longs === String ? "0" : 0;
                }
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.path != null && message.hasOwnProperty("path"))
                    object.path = message.path;
                if (message.lotSize != null && message.hasOwnProperty("lotSize"))
                    if (typeof message.lotSize === "number")
                        object.lotSize = options.longs === String ? String(message.lotSize) : message.lotSize;
                    else
                        object.lotSize = options.longs === String ? $util.Long.prototype.toString.call(message.lotSize) : options.longs === Number ? new $util.LongBits(message.lotSize.low >>> 0, message.lotSize.high >>> 0).toNumber(true) : message.lotSize;
                if (message.digits != null && message.hasOwnProperty("digits"))
                    object.digits = message.digits;
                if (message.profitCurrency != null && message.hasOwnProperty("profitCurrency"))
                    object.profitCurrency = message.profitCurrency;
                if (message.calculationType != null && message.hasOwnProperty("calculationType"))
                    object.calculationType = options.enums === String ? $root.feedbox.market_api.Symbol.CalculationType[message.calculationType] : message.calculationType;
                if (message.buyCorrectionFactor != null && message.hasOwnProperty("buyCorrectionFactor"))
                    object.buyCorrectionFactor = options.json && !isFinite(message.buyCorrectionFactor) ? String(message.buyCorrectionFactor) : message.buyCorrectionFactor;
                if (message.sellCorrectionFactor != null && message.hasOwnProperty("sellCorrectionFactor"))
                    object.sellCorrectionFactor = options.json && !isFinite(message.sellCorrectionFactor) ? String(message.sellCorrectionFactor) : message.sellCorrectionFactor;
                if (message.marginCurrency != null && message.hasOwnProperty("marginCurrency"))
                    object.marginCurrency = message.marginCurrency;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.timezoneName != null && message.hasOwnProperty("timezoneName"))
                    object.timezoneName = message.timezoneName;
                if (message.tradingTime != null && message.hasOwnProperty("tradingTime"))
                    object.tradingTime = $root.feedbox.market_api.Symbol.TradingTime.toObject(message.tradingTime, options);
                if (message.baseCurrency != null && message.hasOwnProperty("baseCurrency"))
                    object.baseCurrency = message.baseCurrency;
                if (message.convertationSymbolPostfix != null && message.hasOwnProperty("convertationSymbolPostfix"))
                    object.convertationSymbolPostfix = message.convertationSymbolPostfix;
                if (message.tradingType && message.tradingType.length) {
                    object.tradingType = [];
                    for (var j = 0; j < message.tradingType.length; ++j)
                        object.tradingType[j] = options.enums === String ? $root.feedbox.market_api.Symbol.TradingType[message.tradingType[j]] : message.tradingType[j];
                }
                if (message.volumeMin != null && message.hasOwnProperty("volumeMin"))
                    if (typeof message.volumeMin === "number")
                        object.volumeMin = options.longs === String ? String(message.volumeMin) : message.volumeMin;
                    else
                        object.volumeMin = options.longs === String ? $util.Long.prototype.toString.call(message.volumeMin) : options.longs === Number ? new $util.LongBits(message.volumeMin.low >>> 0, message.volumeMin.high >>> 0).toNumber(true) : message.volumeMin;
                if (message.volumeStep != null && message.hasOwnProperty("volumeStep"))
                    if (typeof message.volumeStep === "number")
                        object.volumeStep = options.longs === String ? String(message.volumeStep) : message.volumeStep;
                    else
                        object.volumeStep = options.longs === String ? $util.Long.prototype.toString.call(message.volumeStep) : options.longs === Number ? new $util.LongBits(message.volumeStep.low >>> 0, message.volumeStep.high >>> 0).toNumber(true) : message.volumeStep;
                if (message.volumeMax != null && message.hasOwnProperty("volumeMax"))
                    if (typeof message.volumeMax === "number")
                        object.volumeMax = options.longs === String ? String(message.volumeMax) : message.volumeMax;
                    else
                        object.volumeMax = options.longs === String ? $util.Long.prototype.toString.call(message.volumeMax) : options.longs === Number ? new $util.LongBits(message.volumeMax.low >>> 0, message.volumeMax.high >>> 0).toNumber(true) : message.volumeMax;
                return object;
            };

            /**
             * Converts this Symbol to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.Symbol
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Symbol.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * CalculationType enum.
             * @name feedbox.market_api.Symbol.CalculationType
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} FOREX=1 FOREX value
             * @property {number} CFD=2 CFD value
             * @property {number} CFD_LEVERAGE=3 CFD_LEVERAGE value
             */
            Symbol.CalculationType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "FOREX"] = 1;
                values[valuesById[2] = "CFD"] = 2;
                values[valuesById[3] = "CFD_LEVERAGE"] = 3;
                return values;
            })();

            /**
             * TradingType enum.
             * @name feedbox.market_api.Symbol.TradingType
             * @enum {number}
             * @property {number} MARGIN_TRADING=0 MARGIN_TRADING value
             * @property {number} CURRENCY_EXCHANGE=1 CURRENCY_EXCHANGE value
             * @property {number} BET_TRADING=2 BET_TRADING value
             */
            Symbol.TradingType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "MARGIN_TRADING"] = 0;
                values[valuesById[1] = "CURRENCY_EXCHANGE"] = 1;
                values[valuesById[2] = "BET_TRADING"] = 2;
                return values;
            })();

            Symbol.TradingTime = (function() {

                /**
                 * Properties of a TradingTime.
                 * @memberof feedbox.market_api.Symbol
                 * @interface ITradingTime
                 * @property {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>|null} [monday] TradingTime monday
                 * @property {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>|null} [tuesday] TradingTime tuesday
                 * @property {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>|null} [wednesday] TradingTime wednesday
                 * @property {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>|null} [thursday] TradingTime thursday
                 * @property {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>|null} [friday] TradingTime friday
                 * @property {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>|null} [saturday] TradingTime saturday
                 * @property {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>|null} [sunday] TradingTime sunday
                 */

                /**
                 * Constructs a new TradingTime.
                 * @memberof feedbox.market_api.Symbol
                 * @classdesc Represents a TradingTime.
                 * @implements ITradingTime
                 * @constructor
                 * @param {feedbox.market_api.Symbol.ITradingTime=} [properties] Properties to set
                 */
                function TradingTime(properties) {
                    this.monday = [];
                    this.tuesday = [];
                    this.wednesday = [];
                    this.thursday = [];
                    this.friday = [];
                    this.saturday = [];
                    this.sunday = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TradingTime monday.
                 * @member {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>} monday
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @instance
                 */
                TradingTime.prototype.monday = $util.emptyArray;

                /**
                 * TradingTime tuesday.
                 * @member {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>} tuesday
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @instance
                 */
                TradingTime.prototype.tuesday = $util.emptyArray;

                /**
                 * TradingTime wednesday.
                 * @member {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>} wednesday
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @instance
                 */
                TradingTime.prototype.wednesday = $util.emptyArray;

                /**
                 * TradingTime thursday.
                 * @member {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>} thursday
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @instance
                 */
                TradingTime.prototype.thursday = $util.emptyArray;

                /**
                 * TradingTime friday.
                 * @member {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>} friday
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @instance
                 */
                TradingTime.prototype.friday = $util.emptyArray;

                /**
                 * TradingTime saturday.
                 * @member {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>} saturday
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @instance
                 */
                TradingTime.prototype.saturday = $util.emptyArray;

                /**
                 * TradingTime sunday.
                 * @member {Array.<feedbox.market_api.Symbol.TradingTime.ITimePeriod>} sunday
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @instance
                 */
                TradingTime.prototype.sunday = $util.emptyArray;

                /**
                 * Creates a new TradingTime instance using the specified properties.
                 * @function create
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @static
                 * @param {feedbox.market_api.Symbol.ITradingTime=} [properties] Properties to set
                 * @returns {feedbox.market_api.Symbol.TradingTime} TradingTime instance
                 */
                TradingTime.create = function create(properties) {
                    return new TradingTime(properties);
                };

                /**
                 * Encodes the specified TradingTime message. Does not implicitly {@link feedbox.market_api.Symbol.TradingTime.verify|verify} messages.
                 * @function encode
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @static
                 * @param {feedbox.market_api.Symbol.ITradingTime} message TradingTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TradingTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.monday != null && message.monday.length)
                        for (var i = 0; i < message.monday.length; ++i)
                            $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.encode(message.monday[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.tuesday != null && message.tuesday.length)
                        for (var i = 0; i < message.tuesday.length; ++i)
                            $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.encode(message.tuesday[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.wednesday != null && message.wednesday.length)
                        for (var i = 0; i < message.wednesday.length; ++i)
                            $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.encode(message.wednesday[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.thursday != null && message.thursday.length)
                        for (var i = 0; i < message.thursday.length; ++i)
                            $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.encode(message.thursday[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.friday != null && message.friday.length)
                        for (var i = 0; i < message.friday.length; ++i)
                            $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.encode(message.friday[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.saturday != null && message.saturday.length)
                        for (var i = 0; i < message.saturday.length; ++i)
                            $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.encode(message.saturday[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.sunday != null && message.sunday.length)
                        for (var i = 0; i < message.sunday.length; ++i)
                            $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.encode(message.sunday[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };

                /**
                 * Decodes a TradingTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {feedbox.market_api.Symbol.TradingTime} TradingTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TradingTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.Symbol.TradingTime();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.monday && message.monday.length))
                                message.monday = [];
                            message.monday.push($root.feedbox.market_api.Symbol.TradingTime.TimePeriod.decode(reader, reader.uint32()));
                            break;
                        case 2:
                            if (!(message.tuesday && message.tuesday.length))
                                message.tuesday = [];
                            message.tuesday.push($root.feedbox.market_api.Symbol.TradingTime.TimePeriod.decode(reader, reader.uint32()));
                            break;
                        case 3:
                            if (!(message.wednesday && message.wednesday.length))
                                message.wednesday = [];
                            message.wednesday.push($root.feedbox.market_api.Symbol.TradingTime.TimePeriod.decode(reader, reader.uint32()));
                            break;
                        case 4:
                            if (!(message.thursday && message.thursday.length))
                                message.thursday = [];
                            message.thursday.push($root.feedbox.market_api.Symbol.TradingTime.TimePeriod.decode(reader, reader.uint32()));
                            break;
                        case 5:
                            if (!(message.friday && message.friday.length))
                                message.friday = [];
                            message.friday.push($root.feedbox.market_api.Symbol.TradingTime.TimePeriod.decode(reader, reader.uint32()));
                            break;
                        case 6:
                            if (!(message.saturday && message.saturday.length))
                                message.saturday = [];
                            message.saturday.push($root.feedbox.market_api.Symbol.TradingTime.TimePeriod.decode(reader, reader.uint32()));
                            break;
                        case 7:
                            if (!(message.sunday && message.sunday.length))
                                message.sunday = [];
                            message.sunday.push($root.feedbox.market_api.Symbol.TradingTime.TimePeriod.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a TradingTime message.
                 * @function verify
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TradingTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.monday != null && message.hasOwnProperty("monday")) {
                        if (!Array.isArray(message.monday))
                            return "monday: array expected";
                        for (var i = 0; i < message.monday.length; ++i) {
                            var error = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.verify(message.monday[i]);
                            if (error)
                                return "monday." + error;
                        }
                    }
                    if (message.tuesday != null && message.hasOwnProperty("tuesday")) {
                        if (!Array.isArray(message.tuesday))
                            return "tuesday: array expected";
                        for (var i = 0; i < message.tuesday.length; ++i) {
                            var error = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.verify(message.tuesday[i]);
                            if (error)
                                return "tuesday." + error;
                        }
                    }
                    if (message.wednesday != null && message.hasOwnProperty("wednesday")) {
                        if (!Array.isArray(message.wednesday))
                            return "wednesday: array expected";
                        for (var i = 0; i < message.wednesday.length; ++i) {
                            var error = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.verify(message.wednesday[i]);
                            if (error)
                                return "wednesday." + error;
                        }
                    }
                    if (message.thursday != null && message.hasOwnProperty("thursday")) {
                        if (!Array.isArray(message.thursday))
                            return "thursday: array expected";
                        for (var i = 0; i < message.thursday.length; ++i) {
                            var error = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.verify(message.thursday[i]);
                            if (error)
                                return "thursday." + error;
                        }
                    }
                    if (message.friday != null && message.hasOwnProperty("friday")) {
                        if (!Array.isArray(message.friday))
                            return "friday: array expected";
                        for (var i = 0; i < message.friday.length; ++i) {
                            var error = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.verify(message.friday[i]);
                            if (error)
                                return "friday." + error;
                        }
                    }
                    if (message.saturday != null && message.hasOwnProperty("saturday")) {
                        if (!Array.isArray(message.saturday))
                            return "saturday: array expected";
                        for (var i = 0; i < message.saturday.length; ++i) {
                            var error = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.verify(message.saturday[i]);
                            if (error)
                                return "saturday." + error;
                        }
                    }
                    if (message.sunday != null && message.hasOwnProperty("sunday")) {
                        if (!Array.isArray(message.sunday))
                            return "sunday: array expected";
                        for (var i = 0; i < message.sunday.length; ++i) {
                            var error = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.verify(message.sunday[i]);
                            if (error)
                                return "sunday." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a TradingTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {feedbox.market_api.Symbol.TradingTime} TradingTime
                 */
                TradingTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.feedbox.market_api.Symbol.TradingTime)
                        return object;
                    var message = new $root.feedbox.market_api.Symbol.TradingTime();
                    if (object.monday) {
                        if (!Array.isArray(object.monday))
                            throw TypeError(".feedbox.market_api.Symbol.TradingTime.monday: array expected");
                        message.monday = [];
                        for (var i = 0; i < object.monday.length; ++i) {
                            if (typeof object.monday[i] !== "object")
                                throw TypeError(".feedbox.market_api.Symbol.TradingTime.monday: object expected");
                            message.monday[i] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.fromObject(object.monday[i]);
                        }
                    }
                    if (object.tuesday) {
                        if (!Array.isArray(object.tuesday))
                            throw TypeError(".feedbox.market_api.Symbol.TradingTime.tuesday: array expected");
                        message.tuesday = [];
                        for (var i = 0; i < object.tuesday.length; ++i) {
                            if (typeof object.tuesday[i] !== "object")
                                throw TypeError(".feedbox.market_api.Symbol.TradingTime.tuesday: object expected");
                            message.tuesday[i] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.fromObject(object.tuesday[i]);
                        }
                    }
                    if (object.wednesday) {
                        if (!Array.isArray(object.wednesday))
                            throw TypeError(".feedbox.market_api.Symbol.TradingTime.wednesday: array expected");
                        message.wednesday = [];
                        for (var i = 0; i < object.wednesday.length; ++i) {
                            if (typeof object.wednesday[i] !== "object")
                                throw TypeError(".feedbox.market_api.Symbol.TradingTime.wednesday: object expected");
                            message.wednesday[i] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.fromObject(object.wednesday[i]);
                        }
                    }
                    if (object.thursday) {
                        if (!Array.isArray(object.thursday))
                            throw TypeError(".feedbox.market_api.Symbol.TradingTime.thursday: array expected");
                        message.thursday = [];
                        for (var i = 0; i < object.thursday.length; ++i) {
                            if (typeof object.thursday[i] !== "object")
                                throw TypeError(".feedbox.market_api.Symbol.TradingTime.thursday: object expected");
                            message.thursday[i] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.fromObject(object.thursday[i]);
                        }
                    }
                    if (object.friday) {
                        if (!Array.isArray(object.friday))
                            throw TypeError(".feedbox.market_api.Symbol.TradingTime.friday: array expected");
                        message.friday = [];
                        for (var i = 0; i < object.friday.length; ++i) {
                            if (typeof object.friday[i] !== "object")
                                throw TypeError(".feedbox.market_api.Symbol.TradingTime.friday: object expected");
                            message.friday[i] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.fromObject(object.friday[i]);
                        }
                    }
                    if (object.saturday) {
                        if (!Array.isArray(object.saturday))
                            throw TypeError(".feedbox.market_api.Symbol.TradingTime.saturday: array expected");
                        message.saturday = [];
                        for (var i = 0; i < object.saturday.length; ++i) {
                            if (typeof object.saturday[i] !== "object")
                                throw TypeError(".feedbox.market_api.Symbol.TradingTime.saturday: object expected");
                            message.saturday[i] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.fromObject(object.saturday[i]);
                        }
                    }
                    if (object.sunday) {
                        if (!Array.isArray(object.sunday))
                            throw TypeError(".feedbox.market_api.Symbol.TradingTime.sunday: array expected");
                        message.sunday = [];
                        for (var i = 0; i < object.sunday.length; ++i) {
                            if (typeof object.sunday[i] !== "object")
                                throw TypeError(".feedbox.market_api.Symbol.TradingTime.sunday: object expected");
                            message.sunday[i] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.fromObject(object.sunday[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TradingTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @static
                 * @param {feedbox.market_api.Symbol.TradingTime} message TradingTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TradingTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.monday = [];
                        object.tuesday = [];
                        object.wednesday = [];
                        object.thursday = [];
                        object.friday = [];
                        object.saturday = [];
                        object.sunday = [];
                    }
                    if (message.monday && message.monday.length) {
                        object.monday = [];
                        for (var j = 0; j < message.monday.length; ++j)
                            object.monday[j] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.toObject(message.monday[j], options);
                    }
                    if (message.tuesday && message.tuesday.length) {
                        object.tuesday = [];
                        for (var j = 0; j < message.tuesday.length; ++j)
                            object.tuesday[j] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.toObject(message.tuesday[j], options);
                    }
                    if (message.wednesday && message.wednesday.length) {
                        object.wednesday = [];
                        for (var j = 0; j < message.wednesday.length; ++j)
                            object.wednesday[j] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.toObject(message.wednesday[j], options);
                    }
                    if (message.thursday && message.thursday.length) {
                        object.thursday = [];
                        for (var j = 0; j < message.thursday.length; ++j)
                            object.thursday[j] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.toObject(message.thursday[j], options);
                    }
                    if (message.friday && message.friday.length) {
                        object.friday = [];
                        for (var j = 0; j < message.friday.length; ++j)
                            object.friday[j] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.toObject(message.friday[j], options);
                    }
                    if (message.saturday && message.saturday.length) {
                        object.saturday = [];
                        for (var j = 0; j < message.saturday.length; ++j)
                            object.saturday[j] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.toObject(message.saturday[j], options);
                    }
                    if (message.sunday && message.sunday.length) {
                        object.sunday = [];
                        for (var j = 0; j < message.sunday.length; ++j)
                            object.sunday[j] = $root.feedbox.market_api.Symbol.TradingTime.TimePeriod.toObject(message.sunday[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this TradingTime to JSON.
                 * @function toJSON
                 * @memberof feedbox.market_api.Symbol.TradingTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TradingTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                TradingTime.TimePeriod = (function() {

                    /**
                     * Properties of a TimePeriod.
                     * @memberof feedbox.market_api.Symbol.TradingTime
                     * @interface ITimePeriod
                     * @property {number|null} [from] TimePeriod from
                     * @property {number|null} [to] TimePeriod to
                     */

                    /**
                     * Constructs a new TimePeriod.
                     * @memberof feedbox.market_api.Symbol.TradingTime
                     * @classdesc Represents a TimePeriod.
                     * @implements ITimePeriod
                     * @constructor
                     * @param {feedbox.market_api.Symbol.TradingTime.ITimePeriod=} [properties] Properties to set
                     */
                    function TimePeriod(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TimePeriod from.
                     * @member {number} from
                     * @memberof feedbox.market_api.Symbol.TradingTime.TimePeriod
                     * @instance
                     */
                    TimePeriod.prototype.from = 0;

                    /**
                     * TimePeriod to.
                     * @member {number} to
                     * @memberof feedbox.market_api.Symbol.TradingTime.TimePeriod
                     * @instance
                     */
                    TimePeriod.prototype.to = 0;

                    /**
                     * Creates a new TimePeriod instance using the specified properties.
                     * @function create
                     * @memberof feedbox.market_api.Symbol.TradingTime.TimePeriod
                     * @static
                     * @param {feedbox.market_api.Symbol.TradingTime.ITimePeriod=} [properties] Properties to set
                     * @returns {feedbox.market_api.Symbol.TradingTime.TimePeriod} TimePeriod instance
                     */
                    TimePeriod.create = function create(properties) {
                        return new TimePeriod(properties);
                    };

                    /**
                     * Encodes the specified TimePeriod message. Does not implicitly {@link feedbox.market_api.Symbol.TradingTime.TimePeriod.verify|verify} messages.
                     * @function encode
                     * @memberof feedbox.market_api.Symbol.TradingTime.TimePeriod
                     * @static
                     * @param {feedbox.market_api.Symbol.TradingTime.ITimePeriod} message TimePeriod message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TimePeriod.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.from);
                        if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.to);
                        return writer;
                    };

                    /**
                     * Decodes a TimePeriod message from the specified reader or buffer.
                     * @function decode
                     * @memberof feedbox.market_api.Symbol.TradingTime.TimePeriod
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {feedbox.market_api.Symbol.TradingTime.TimePeriod} TimePeriod
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TimePeriod.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.Symbol.TradingTime.TimePeriod();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.from = reader.uint32();
                                break;
                            case 2:
                                message.to = reader.uint32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Verifies a TimePeriod message.
                     * @function verify
                     * @memberof feedbox.market_api.Symbol.TradingTime.TimePeriod
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TimePeriod.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.from != null && message.hasOwnProperty("from"))
                            if (!$util.isInteger(message.from))
                                return "from: integer expected";
                        if (message.to != null && message.hasOwnProperty("to"))
                            if (!$util.isInteger(message.to))
                                return "to: integer expected";
                        return null;
                    };

                    /**
                     * Creates a TimePeriod message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof feedbox.market_api.Symbol.TradingTime.TimePeriod
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {feedbox.market_api.Symbol.TradingTime.TimePeriod} TimePeriod
                     */
                    TimePeriod.fromObject = function fromObject(object) {
                        if (object instanceof $root.feedbox.market_api.Symbol.TradingTime.TimePeriod)
                            return object;
                        var message = new $root.feedbox.market_api.Symbol.TradingTime.TimePeriod();
                        if (object.from != null)
                            message.from = object.from >>> 0;
                        if (object.to != null)
                            message.to = object.to >>> 0;
                        return message;
                    };

                    /**
                     * Creates a plain object from a TimePeriod message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof feedbox.market_api.Symbol.TradingTime.TimePeriod
                     * @static
                     * @param {feedbox.market_api.Symbol.TradingTime.TimePeriod} message TimePeriod
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TimePeriod.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.from = 0;
                            object.to = 0;
                        }
                        if (message.from != null && message.hasOwnProperty("from"))
                            object.from = message.from;
                        if (message.to != null && message.hasOwnProperty("to"))
                            object.to = message.to;
                        return object;
                    };

                    /**
                     * Converts this TimePeriod to JSON.
                     * @function toJSON
                     * @memberof feedbox.market_api.Symbol.TradingTime.TimePeriod
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TimePeriod.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return TimePeriod;
                })();

                return TradingTime;
            })();

            return Symbol;
        })();

        market_api.SymbolList = (function() {

            /**
             * Properties of a SymbolList.
             * @memberof feedbox.market_api
             * @interface ISymbolList
             * @property {Array.<feedbox.market_api.ISymbol>|null} [symbol] SymbolList symbol
             */

            /**
             * Constructs a new SymbolList.
             * @memberof feedbox.market_api
             * @classdesc Represents a SymbolList.
             * @implements ISymbolList
             * @constructor
             * @param {feedbox.market_api.ISymbolList=} [properties] Properties to set
             */
            function SymbolList(properties) {
                this.symbol = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SymbolList symbol.
             * @member {Array.<feedbox.market_api.ISymbol>} symbol
             * @memberof feedbox.market_api.SymbolList
             * @instance
             */
            SymbolList.prototype.symbol = $util.emptyArray;

            /**
             * Creates a new SymbolList instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.SymbolList
             * @static
             * @param {feedbox.market_api.ISymbolList=} [properties] Properties to set
             * @returns {feedbox.market_api.SymbolList} SymbolList instance
             */
            SymbolList.create = function create(properties) {
                return new SymbolList(properties);
            };

            /**
             * Encodes the specified SymbolList message. Does not implicitly {@link feedbox.market_api.SymbolList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.SymbolList
             * @static
             * @param {feedbox.market_api.ISymbolList} message SymbolList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SymbolList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.symbol != null && message.symbol.length)
                    for (var i = 0; i < message.symbol.length; ++i)
                        $root.feedbox.market_api.Symbol.encode(message.symbol[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SymbolList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.SymbolList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.SymbolList} SymbolList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SymbolList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.SymbolList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.symbol && message.symbol.length))
                            message.symbol = [];
                        message.symbol.push($root.feedbox.market_api.Symbol.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SymbolList message.
             * @function verify
             * @memberof feedbox.market_api.SymbolList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SymbolList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.symbol != null && message.hasOwnProperty("symbol")) {
                    if (!Array.isArray(message.symbol))
                        return "symbol: array expected";
                    for (var i = 0; i < message.symbol.length; ++i) {
                        var error = $root.feedbox.market_api.Symbol.verify(message.symbol[i]);
                        if (error)
                            return "symbol." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SymbolList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.SymbolList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.SymbolList} SymbolList
             */
            SymbolList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.SymbolList)
                    return object;
                var message = new $root.feedbox.market_api.SymbolList();
                if (object.symbol) {
                    if (!Array.isArray(object.symbol))
                        throw TypeError(".feedbox.market_api.SymbolList.symbol: array expected");
                    message.symbol = [];
                    for (var i = 0; i < object.symbol.length; ++i) {
                        if (typeof object.symbol[i] !== "object")
                            throw TypeError(".feedbox.market_api.SymbolList.symbol: object expected");
                        message.symbol[i] = $root.feedbox.market_api.Symbol.fromObject(object.symbol[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SymbolList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.SymbolList
             * @static
             * @param {feedbox.market_api.SymbolList} message SymbolList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SymbolList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.symbol = [];
                if (message.symbol && message.symbol.length) {
                    object.symbol = [];
                    for (var j = 0; j < message.symbol.length; ++j)
                        object.symbol[j] = $root.feedbox.market_api.Symbol.toObject(message.symbol[j], options);
                }
                return object;
            };

            /**
             * Converts this SymbolList to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.SymbolList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SymbolList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SymbolList;
        })();

        market_api.Result = (function() {

            /**
             * Properties of a Result.
             * @memberof feedbox.market_api
             * @interface IResult
             * @property {feedbox.market_api.Result.Code|null} [code] Result code
             * @property {string|null} [text] Result text
             */

            /**
             * Constructs a new Result.
             * @memberof feedbox.market_api
             * @classdesc Represents a Result.
             * @implements IResult
             * @constructor
             * @param {feedbox.market_api.IResult=} [properties] Properties to set
             */
            function Result(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Result code.
             * @member {feedbox.market_api.Result.Code} code
             * @memberof feedbox.market_api.Result
             * @instance
             */
            Result.prototype.code = 0;

            /**
             * Result text.
             * @member {string} text
             * @memberof feedbox.market_api.Result
             * @instance
             */
            Result.prototype.text = "";

            /**
             * Creates a new Result instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.Result
             * @static
             * @param {feedbox.market_api.IResult=} [properties] Properties to set
             * @returns {feedbox.market_api.Result} Result instance
             */
            Result.create = function create(properties) {
                return new Result(properties);
            };

            /**
             * Encodes the specified Result message. Does not implicitly {@link feedbox.market_api.Result.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.Result
             * @static
             * @param {feedbox.market_api.IResult} message Result message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Result.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
                return writer;
            };

            /**
             * Decodes a Result message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.Result
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.Result} Result
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Result.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.Result();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.text = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Result message.
             * @function verify
             * @memberof feedbox.market_api.Result
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Result.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 5:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                return null;
            };

            /**
             * Creates a Result message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.Result
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.Result} Result
             */
            Result.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.Result)
                    return object;
                var message = new $root.feedbox.market_api.Result();
                switch (object.code) {
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "COMMON_ERROR":
                case 1:
                    message.code = 1;
                    break;
                case "DUPLICATE_REQUEST_ID":
                case 2:
                    message.code = 2;
                    break;
                case "DUPLICATE_SUBSCRIPTION_ID":
                case 5:
                    message.code = 5;
                    break;
                case "FIELD_IS_NOT_SET":
                case 3:
                    message.code = 3;
                    break;
                case "INVALID_FIELD_VALUE":
                case 4:
                    message.code = 4;
                    break;
                }
                if (object.text != null)
                    message.text = String(object.text);
                return message;
            };

            /**
             * Creates a plain object from a Result message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.Result
             * @static
             * @param {feedbox.market_api.Result} message Result
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Result.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    object.text = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.feedbox.market_api.Result.Code[message.code] : message.code;
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                return object;
            };

            /**
             * Converts this Result to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.Result
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Result.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Code enum.
             * @name feedbox.market_api.Result.Code
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} COMMON_ERROR=1 COMMON_ERROR value
             * @property {number} DUPLICATE_REQUEST_ID=2 DUPLICATE_REQUEST_ID value
             * @property {number} DUPLICATE_SUBSCRIPTION_ID=5 DUPLICATE_SUBSCRIPTION_ID value
             * @property {number} FIELD_IS_NOT_SET=3 FIELD_IS_NOT_SET value
             * @property {number} INVALID_FIELD_VALUE=4 INVALID_FIELD_VALUE value
             */
            Result.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "COMMON_ERROR"] = 1;
                values[valuesById[2] = "DUPLICATE_REQUEST_ID"] = 2;
                values[valuesById[5] = "DUPLICATE_SUBSCRIPTION_ID"] = 5;
                values[valuesById[3] = "FIELD_IS_NOT_SET"] = 3;
                values[valuesById[4] = "INVALID_FIELD_VALUE"] = 4;
                return values;
            })();

            return Result;
        })();

        market_api.SubscriptionRequest = (function() {

            /**
             * Properties of a SubscriptionRequest.
             * @memberof feedbox.market_api
             * @interface ISubscriptionRequest
             * @property {number|Long|null} [subscriptionId] SubscriptionRequest subscriptionId
             * @property {feedbox.market_api.SubscriptionRequest.Action|null} [action] SubscriptionRequest action
             * @property {feedbox.market_api.IQuoteRequest|null} [quoteRequest] SubscriptionRequest quoteRequest
             * @property {feedbox.market_api.IOrderBookRequest|null} [orderBookRequest] SubscriptionRequest orderBookRequest
             * @property {feedbox.market_api.IChartBarRequest|null} [chartBarRequest] SubscriptionRequest chartBarRequest
             * @property {feedbox.market_api.ISymbolListRequest|null} [symbolListRequest] SubscriptionRequest symbolListRequest
             */

            /**
             * Constructs a new SubscriptionRequest.
             * @memberof feedbox.market_api
             * @classdesc Represents a SubscriptionRequest.
             * @implements ISubscriptionRequest
             * @constructor
             * @param {feedbox.market_api.ISubscriptionRequest=} [properties] Properties to set
             */
            function SubscriptionRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubscriptionRequest subscriptionId.
             * @member {number|Long} subscriptionId
             * @memberof feedbox.market_api.SubscriptionRequest
             * @instance
             */
            SubscriptionRequest.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubscriptionRequest action.
             * @member {feedbox.market_api.SubscriptionRequest.Action} action
             * @memberof feedbox.market_api.SubscriptionRequest
             * @instance
             */
            SubscriptionRequest.prototype.action = 0;

            /**
             * SubscriptionRequest quoteRequest.
             * @member {feedbox.market_api.IQuoteRequest|null|undefined} quoteRequest
             * @memberof feedbox.market_api.SubscriptionRequest
             * @instance
             */
            SubscriptionRequest.prototype.quoteRequest = null;

            /**
             * SubscriptionRequest orderBookRequest.
             * @member {feedbox.market_api.IOrderBookRequest|null|undefined} orderBookRequest
             * @memberof feedbox.market_api.SubscriptionRequest
             * @instance
             */
            SubscriptionRequest.prototype.orderBookRequest = null;

            /**
             * SubscriptionRequest chartBarRequest.
             * @member {feedbox.market_api.IChartBarRequest|null|undefined} chartBarRequest
             * @memberof feedbox.market_api.SubscriptionRequest
             * @instance
             */
            SubscriptionRequest.prototype.chartBarRequest = null;

            /**
             * SubscriptionRequest symbolListRequest.
             * @member {feedbox.market_api.ISymbolListRequest|null|undefined} symbolListRequest
             * @memberof feedbox.market_api.SubscriptionRequest
             * @instance
             */
            SubscriptionRequest.prototype.symbolListRequest = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * SubscriptionRequest body.
             * @member {"quoteRequest"|"orderBookRequest"|"chartBarRequest"|"symbolListRequest"|undefined} body
             * @memberof feedbox.market_api.SubscriptionRequest
             * @instance
             */
            Object.defineProperty(SubscriptionRequest.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["quoteRequest", "orderBookRequest", "chartBarRequest", "symbolListRequest"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new SubscriptionRequest instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.SubscriptionRequest
             * @static
             * @param {feedbox.market_api.ISubscriptionRequest=} [properties] Properties to set
             * @returns {feedbox.market_api.SubscriptionRequest} SubscriptionRequest instance
             */
            SubscriptionRequest.create = function create(properties) {
                return new SubscriptionRequest(properties);
            };

            /**
             * Encodes the specified SubscriptionRequest message. Does not implicitly {@link feedbox.market_api.SubscriptionRequest.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.SubscriptionRequest
             * @static
             * @param {feedbox.market_api.ISubscriptionRequest} message SubscriptionRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubscriptionRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subscriptionId != null && Object.hasOwnProperty.call(message, "subscriptionId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subscriptionId);
                if (message.action != null && Object.hasOwnProperty.call(message, "action"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.action);
                if (message.quoteRequest != null && Object.hasOwnProperty.call(message, "quoteRequest"))
                    $root.feedbox.market_api.QuoteRequest.encode(message.quoteRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.orderBookRequest != null && Object.hasOwnProperty.call(message, "orderBookRequest"))
                    $root.feedbox.market_api.OrderBookRequest.encode(message.orderBookRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.chartBarRequest != null && Object.hasOwnProperty.call(message, "chartBarRequest"))
                    $root.feedbox.market_api.ChartBarRequest.encode(message.chartBarRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.symbolListRequest != null && Object.hasOwnProperty.call(message, "symbolListRequest"))
                    $root.feedbox.market_api.SymbolListRequest.encode(message.symbolListRequest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SubscriptionRequest message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.SubscriptionRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.SubscriptionRequest} SubscriptionRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubscriptionRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.SubscriptionRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subscriptionId = reader.uint64();
                        break;
                    case 2:
                        message.action = reader.int32();
                        break;
                    case 3:
                        message.quoteRequest = $root.feedbox.market_api.QuoteRequest.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.orderBookRequest = $root.feedbox.market_api.OrderBookRequest.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.chartBarRequest = $root.feedbox.market_api.ChartBarRequest.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.symbolListRequest = $root.feedbox.market_api.SymbolListRequest.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubscriptionRequest message.
             * @function verify
             * @memberof feedbox.market_api.SubscriptionRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubscriptionRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                    if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                        return "subscriptionId: integer|Long expected";
                if (message.action != null && message.hasOwnProperty("action"))
                    switch (message.action) {
                    default:
                        return "action: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.quoteRequest != null && message.hasOwnProperty("quoteRequest")) {
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.QuoteRequest.verify(message.quoteRequest);
                        if (error)
                            return "quoteRequest." + error;
                    }
                }
                if (message.orderBookRequest != null && message.hasOwnProperty("orderBookRequest")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.OrderBookRequest.verify(message.orderBookRequest);
                        if (error)
                            return "orderBookRequest." + error;
                    }
                }
                if (message.chartBarRequest != null && message.hasOwnProperty("chartBarRequest")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.ChartBarRequest.verify(message.chartBarRequest);
                        if (error)
                            return "chartBarRequest." + error;
                    }
                }
                if (message.symbolListRequest != null && message.hasOwnProperty("symbolListRequest")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.SymbolListRequest.verify(message.symbolListRequest);
                        if (error)
                            return "symbolListRequest." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SubscriptionRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.SubscriptionRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.SubscriptionRequest} SubscriptionRequest
             */
            SubscriptionRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.SubscriptionRequest)
                    return object;
                var message = new $root.feedbox.market_api.SubscriptionRequest();
                if (object.subscriptionId != null)
                    if ($util.Long)
                        (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = true;
                    else if (typeof object.subscriptionId === "string")
                        message.subscriptionId = parseInt(object.subscriptionId, 10);
                    else if (typeof object.subscriptionId === "number")
                        message.subscriptionId = object.subscriptionId;
                    else if (typeof object.subscriptionId === "object")
                        message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber(true);
                switch (object.action) {
                case "UNKNOWN":
                case 0:
                    message.action = 0;
                    break;
                case "SUBSCRIBE":
                case 1:
                    message.action = 1;
                    break;
                case "UNSUBSCRIBE":
                case 2:
                    message.action = 2;
                    break;
                }
                if (object.quoteRequest != null) {
                    if (typeof object.quoteRequest !== "object")
                        throw TypeError(".feedbox.market_api.SubscriptionRequest.quoteRequest: object expected");
                    message.quoteRequest = $root.feedbox.market_api.QuoteRequest.fromObject(object.quoteRequest);
                }
                if (object.orderBookRequest != null) {
                    if (typeof object.orderBookRequest !== "object")
                        throw TypeError(".feedbox.market_api.SubscriptionRequest.orderBookRequest: object expected");
                    message.orderBookRequest = $root.feedbox.market_api.OrderBookRequest.fromObject(object.orderBookRequest);
                }
                if (object.chartBarRequest != null) {
                    if (typeof object.chartBarRequest !== "object")
                        throw TypeError(".feedbox.market_api.SubscriptionRequest.chartBarRequest: object expected");
                    message.chartBarRequest = $root.feedbox.market_api.ChartBarRequest.fromObject(object.chartBarRequest);
                }
                if (object.symbolListRequest != null) {
                    if (typeof object.symbolListRequest !== "object")
                        throw TypeError(".feedbox.market_api.SubscriptionRequest.symbolListRequest: object expected");
                    message.symbolListRequest = $root.feedbox.market_api.SymbolListRequest.fromObject(object.symbolListRequest);
                }
                return message;
            };

            /**
             * Creates a plain object from a SubscriptionRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.SubscriptionRequest
             * @static
             * @param {feedbox.market_api.SubscriptionRequest} message SubscriptionRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubscriptionRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subscriptionId = options.longs === String ? "0" : 0;
                    object.action = options.enums === String ? "UNKNOWN" : 0;
                }
                if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                    if (typeof message.subscriptionId === "number")
                        object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                    else
                        object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber(true) : message.subscriptionId;
                if (message.action != null && message.hasOwnProperty("action"))
                    object.action = options.enums === String ? $root.feedbox.market_api.SubscriptionRequest.Action[message.action] : message.action;
                if (message.quoteRequest != null && message.hasOwnProperty("quoteRequest")) {
                    object.quoteRequest = $root.feedbox.market_api.QuoteRequest.toObject(message.quoteRequest, options);
                    if (options.oneofs)
                        object.body = "quoteRequest";
                }
                if (message.orderBookRequest != null && message.hasOwnProperty("orderBookRequest")) {
                    object.orderBookRequest = $root.feedbox.market_api.OrderBookRequest.toObject(message.orderBookRequest, options);
                    if (options.oneofs)
                        object.body = "orderBookRequest";
                }
                if (message.chartBarRequest != null && message.hasOwnProperty("chartBarRequest")) {
                    object.chartBarRequest = $root.feedbox.market_api.ChartBarRequest.toObject(message.chartBarRequest, options);
                    if (options.oneofs)
                        object.body = "chartBarRequest";
                }
                if (message.symbolListRequest != null && message.hasOwnProperty("symbolListRequest")) {
                    object.symbolListRequest = $root.feedbox.market_api.SymbolListRequest.toObject(message.symbolListRequest, options);
                    if (options.oneofs)
                        object.body = "symbolListRequest";
                }
                return object;
            };

            /**
             * Converts this SubscriptionRequest to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.SubscriptionRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubscriptionRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Action enum.
             * @name feedbox.market_api.SubscriptionRequest.Action
             * @enum {number}
             * @property {number} UNKNOWN=0 UNKNOWN value
             * @property {number} SUBSCRIBE=1 SUBSCRIBE value
             * @property {number} UNSUBSCRIBE=2 UNSUBSCRIBE value
             */
            SubscriptionRequest.Action = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNKNOWN"] = 0;
                values[valuesById[1] = "SUBSCRIBE"] = 1;
                values[valuesById[2] = "UNSUBSCRIBE"] = 2;
                return values;
            })();

            return SubscriptionRequest;
        })();

        market_api.SubscriptionResult = (function() {

            /**
             * Properties of a SubscriptionResult.
             * @memberof feedbox.market_api
             * @interface ISubscriptionResult
             * @property {number|Long|null} [subscriptionId] SubscriptionResult subscriptionId
             * @property {feedbox.market_api.IQuotes|null} [quotes] SubscriptionResult quotes
             * @property {feedbox.market_api.IOrderBookUpdates|null} [orderBookUpdates] SubscriptionResult orderBookUpdates
             * @property {feedbox.market_api.IChartBar|null} [chartBar] SubscriptionResult chartBar
             * @property {feedbox.market_api.ISymbolList|null} [symbolList] SubscriptionResult symbolList
             */

            /**
             * Constructs a new SubscriptionResult.
             * @memberof feedbox.market_api
             * @classdesc Represents a SubscriptionResult.
             * @implements ISubscriptionResult
             * @constructor
             * @param {feedbox.market_api.ISubscriptionResult=} [properties] Properties to set
             */
            function SubscriptionResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SubscriptionResult subscriptionId.
             * @member {number|Long} subscriptionId
             * @memberof feedbox.market_api.SubscriptionResult
             * @instance
             */
            SubscriptionResult.prototype.subscriptionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SubscriptionResult quotes.
             * @member {feedbox.market_api.IQuotes|null|undefined} quotes
             * @memberof feedbox.market_api.SubscriptionResult
             * @instance
             */
            SubscriptionResult.prototype.quotes = null;

            /**
             * SubscriptionResult orderBookUpdates.
             * @member {feedbox.market_api.IOrderBookUpdates|null|undefined} orderBookUpdates
             * @memberof feedbox.market_api.SubscriptionResult
             * @instance
             */
            SubscriptionResult.prototype.orderBookUpdates = null;

            /**
             * SubscriptionResult chartBar.
             * @member {feedbox.market_api.IChartBar|null|undefined} chartBar
             * @memberof feedbox.market_api.SubscriptionResult
             * @instance
             */
            SubscriptionResult.prototype.chartBar = null;

            /**
             * SubscriptionResult symbolList.
             * @member {feedbox.market_api.ISymbolList|null|undefined} symbolList
             * @memberof feedbox.market_api.SubscriptionResult
             * @instance
             */
            SubscriptionResult.prototype.symbolList = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * SubscriptionResult body.
             * @member {"quotes"|"orderBookUpdates"|"chartBar"|"symbolList"|undefined} body
             * @memberof feedbox.market_api.SubscriptionResult
             * @instance
             */
            Object.defineProperty(SubscriptionResult.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["quotes", "orderBookUpdates", "chartBar", "symbolList"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new SubscriptionResult instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.SubscriptionResult
             * @static
             * @param {feedbox.market_api.ISubscriptionResult=} [properties] Properties to set
             * @returns {feedbox.market_api.SubscriptionResult} SubscriptionResult instance
             */
            SubscriptionResult.create = function create(properties) {
                return new SubscriptionResult(properties);
            };

            /**
             * Encodes the specified SubscriptionResult message. Does not implicitly {@link feedbox.market_api.SubscriptionResult.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.SubscriptionResult
             * @static
             * @param {feedbox.market_api.ISubscriptionResult} message SubscriptionResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SubscriptionResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subscriptionId != null && Object.hasOwnProperty.call(message, "subscriptionId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.subscriptionId);
                if (message.quotes != null && Object.hasOwnProperty.call(message, "quotes"))
                    $root.feedbox.market_api.Quotes.encode(message.quotes, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.orderBookUpdates != null && Object.hasOwnProperty.call(message, "orderBookUpdates"))
                    $root.feedbox.market_api.OrderBookUpdates.encode(message.orderBookUpdates, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.chartBar != null && Object.hasOwnProperty.call(message, "chartBar"))
                    $root.feedbox.market_api.ChartBar.encode(message.chartBar, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.symbolList != null && Object.hasOwnProperty.call(message, "symbolList"))
                    $root.feedbox.market_api.SymbolList.encode(message.symbolList, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SubscriptionResult message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.SubscriptionResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.SubscriptionResult} SubscriptionResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SubscriptionResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.SubscriptionResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.subscriptionId = reader.uint64();
                        break;
                    case 2:
                        message.quotes = $root.feedbox.market_api.Quotes.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.orderBookUpdates = $root.feedbox.market_api.OrderBookUpdates.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.chartBar = $root.feedbox.market_api.ChartBar.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.symbolList = $root.feedbox.market_api.SymbolList.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SubscriptionResult message.
             * @function verify
             * @memberof feedbox.market_api.SubscriptionResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SubscriptionResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                    if (!$util.isInteger(message.subscriptionId) && !(message.subscriptionId && $util.isInteger(message.subscriptionId.low) && $util.isInteger(message.subscriptionId.high)))
                        return "subscriptionId: integer|Long expected";
                if (message.quotes != null && message.hasOwnProperty("quotes")) {
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.Quotes.verify(message.quotes);
                        if (error)
                            return "quotes." + error;
                    }
                }
                if (message.orderBookUpdates != null && message.hasOwnProperty("orderBookUpdates")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.OrderBookUpdates.verify(message.orderBookUpdates);
                        if (error)
                            return "orderBookUpdates." + error;
                    }
                }
                if (message.chartBar != null && message.hasOwnProperty("chartBar")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.ChartBar.verify(message.chartBar);
                        if (error)
                            return "chartBar." + error;
                    }
                }
                if (message.symbolList != null && message.hasOwnProperty("symbolList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.SymbolList.verify(message.symbolList);
                        if (error)
                            return "symbolList." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SubscriptionResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.SubscriptionResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.SubscriptionResult} SubscriptionResult
             */
            SubscriptionResult.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.SubscriptionResult)
                    return object;
                var message = new $root.feedbox.market_api.SubscriptionResult();
                if (object.subscriptionId != null)
                    if ($util.Long)
                        (message.subscriptionId = $util.Long.fromValue(object.subscriptionId)).unsigned = true;
                    else if (typeof object.subscriptionId === "string")
                        message.subscriptionId = parseInt(object.subscriptionId, 10);
                    else if (typeof object.subscriptionId === "number")
                        message.subscriptionId = object.subscriptionId;
                    else if (typeof object.subscriptionId === "object")
                        message.subscriptionId = new $util.LongBits(object.subscriptionId.low >>> 0, object.subscriptionId.high >>> 0).toNumber(true);
                if (object.quotes != null) {
                    if (typeof object.quotes !== "object")
                        throw TypeError(".feedbox.market_api.SubscriptionResult.quotes: object expected");
                    message.quotes = $root.feedbox.market_api.Quotes.fromObject(object.quotes);
                }
                if (object.orderBookUpdates != null) {
                    if (typeof object.orderBookUpdates !== "object")
                        throw TypeError(".feedbox.market_api.SubscriptionResult.orderBookUpdates: object expected");
                    message.orderBookUpdates = $root.feedbox.market_api.OrderBookUpdates.fromObject(object.orderBookUpdates);
                }
                if (object.chartBar != null) {
                    if (typeof object.chartBar !== "object")
                        throw TypeError(".feedbox.market_api.SubscriptionResult.chartBar: object expected");
                    message.chartBar = $root.feedbox.market_api.ChartBar.fromObject(object.chartBar);
                }
                if (object.symbolList != null) {
                    if (typeof object.symbolList !== "object")
                        throw TypeError(".feedbox.market_api.SubscriptionResult.symbolList: object expected");
                    message.symbolList = $root.feedbox.market_api.SymbolList.fromObject(object.symbolList);
                }
                return message;
            };

            /**
             * Creates a plain object from a SubscriptionResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.SubscriptionResult
             * @static
             * @param {feedbox.market_api.SubscriptionResult} message SubscriptionResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SubscriptionResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.subscriptionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.subscriptionId = options.longs === String ? "0" : 0;
                if (message.subscriptionId != null && message.hasOwnProperty("subscriptionId"))
                    if (typeof message.subscriptionId === "number")
                        object.subscriptionId = options.longs === String ? String(message.subscriptionId) : message.subscriptionId;
                    else
                        object.subscriptionId = options.longs === String ? $util.Long.prototype.toString.call(message.subscriptionId) : options.longs === Number ? new $util.LongBits(message.subscriptionId.low >>> 0, message.subscriptionId.high >>> 0).toNumber(true) : message.subscriptionId;
                if (message.quotes != null && message.hasOwnProperty("quotes")) {
                    object.quotes = $root.feedbox.market_api.Quotes.toObject(message.quotes, options);
                    if (options.oneofs)
                        object.body = "quotes";
                }
                if (message.orderBookUpdates != null && message.hasOwnProperty("orderBookUpdates")) {
                    object.orderBookUpdates = $root.feedbox.market_api.OrderBookUpdates.toObject(message.orderBookUpdates, options);
                    if (options.oneofs)
                        object.body = "orderBookUpdates";
                }
                if (message.chartBar != null && message.hasOwnProperty("chartBar")) {
                    object.chartBar = $root.feedbox.market_api.ChartBar.toObject(message.chartBar, options);
                    if (options.oneofs)
                        object.body = "chartBar";
                }
                if (message.symbolList != null && message.hasOwnProperty("symbolList")) {
                    object.symbolList = $root.feedbox.market_api.SymbolList.toObject(message.symbolList, options);
                    if (options.oneofs)
                        object.body = "symbolList";
                }
                return object;
            };

            /**
             * Converts this SubscriptionResult to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.SubscriptionResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SubscriptionResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SubscriptionResult;
        })();

        market_api.MarketDataRequest = (function() {

            /**
             * Properties of a MarketDataRequest.
             * @memberof feedbox.market_api
             * @interface IMarketDataRequest
             * @property {number|Long|null} [id] MarketDataRequest id
             * @property {feedbox.market_api.ISubscriptionRequest|null} [subscriptionRequest] MarketDataRequest subscriptionRequest
             * @property {feedbox.market_api.IChartBarListRequest|null} [chartBarListRequest] MarketDataRequest chartBarListRequest
             * @property {feedbox.ping.IPing|null} [ping] MarketDataRequest ping
             * @property {feedbox.server_time.IGetServerTime|null} [getServerTime] MarketDataRequest getServerTime
             */

            /**
             * Constructs a new MarketDataRequest.
             * @memberof feedbox.market_api
             * @classdesc Represents a MarketDataRequest.
             * @implements IMarketDataRequest
             * @constructor
             * @param {feedbox.market_api.IMarketDataRequest=} [properties] Properties to set
             */
            function MarketDataRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MarketDataRequest id.
             * @member {number|Long} id
             * @memberof feedbox.market_api.MarketDataRequest
             * @instance
             */
            MarketDataRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * MarketDataRequest subscriptionRequest.
             * @member {feedbox.market_api.ISubscriptionRequest|null|undefined} subscriptionRequest
             * @memberof feedbox.market_api.MarketDataRequest
             * @instance
             */
            MarketDataRequest.prototype.subscriptionRequest = null;

            /**
             * MarketDataRequest chartBarListRequest.
             * @member {feedbox.market_api.IChartBarListRequest|null|undefined} chartBarListRequest
             * @memberof feedbox.market_api.MarketDataRequest
             * @instance
             */
            MarketDataRequest.prototype.chartBarListRequest = null;

            /**
             * MarketDataRequest ping.
             * @member {feedbox.ping.IPing|null|undefined} ping
             * @memberof feedbox.market_api.MarketDataRequest
             * @instance
             */
            MarketDataRequest.prototype.ping = null;

            /**
             * MarketDataRequest getServerTime.
             * @member {feedbox.server_time.IGetServerTime|null|undefined} getServerTime
             * @memberof feedbox.market_api.MarketDataRequest
             * @instance
             */
            MarketDataRequest.prototype.getServerTime = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * MarketDataRequest body.
             * @member {"subscriptionRequest"|"chartBarListRequest"|"ping"|"getServerTime"|undefined} body
             * @memberof feedbox.market_api.MarketDataRequest
             * @instance
             */
            Object.defineProperty(MarketDataRequest.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["subscriptionRequest", "chartBarListRequest", "ping", "getServerTime"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new MarketDataRequest instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.MarketDataRequest
             * @static
             * @param {feedbox.market_api.IMarketDataRequest=} [properties] Properties to set
             * @returns {feedbox.market_api.MarketDataRequest} MarketDataRequest instance
             */
            MarketDataRequest.create = function create(properties) {
                return new MarketDataRequest(properties);
            };

            /**
             * Encodes the specified MarketDataRequest message. Does not implicitly {@link feedbox.market_api.MarketDataRequest.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.MarketDataRequest
             * @static
             * @param {feedbox.market_api.IMarketDataRequest} message MarketDataRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarketDataRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.subscriptionRequest != null && Object.hasOwnProperty.call(message, "subscriptionRequest"))
                    $root.feedbox.market_api.SubscriptionRequest.encode(message.subscriptionRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.chartBarListRequest != null && Object.hasOwnProperty.call(message, "chartBarListRequest"))
                    $root.feedbox.market_api.ChartBarListRequest.encode(message.chartBarListRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.ping != null && Object.hasOwnProperty.call(message, "ping"))
                    $root.feedbox.ping.Ping.encode(message.ping, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.getServerTime != null && Object.hasOwnProperty.call(message, "getServerTime"))
                    $root.feedbox.server_time.GetServerTime.encode(message.getServerTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a MarketDataRequest message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.MarketDataRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.MarketDataRequest} MarketDataRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarketDataRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.MarketDataRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.subscriptionRequest = $root.feedbox.market_api.SubscriptionRequest.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.chartBarListRequest = $root.feedbox.market_api.ChartBarListRequest.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.ping = $root.feedbox.ping.Ping.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.getServerTime = $root.feedbox.server_time.GetServerTime.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MarketDataRequest message.
             * @function verify
             * @memberof feedbox.market_api.MarketDataRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MarketDataRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.subscriptionRequest != null && message.hasOwnProperty("subscriptionRequest")) {
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.SubscriptionRequest.verify(message.subscriptionRequest);
                        if (error)
                            return "subscriptionRequest." + error;
                    }
                }
                if (message.chartBarListRequest != null && message.hasOwnProperty("chartBarListRequest")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.ChartBarListRequest.verify(message.chartBarListRequest);
                        if (error)
                            return "chartBarListRequest." + error;
                    }
                }
                if (message.ping != null && message.hasOwnProperty("ping")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.ping.Ping.verify(message.ping);
                        if (error)
                            return "ping." + error;
                    }
                }
                if (message.getServerTime != null && message.hasOwnProperty("getServerTime")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.server_time.GetServerTime.verify(message.getServerTime);
                        if (error)
                            return "getServerTime." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MarketDataRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.MarketDataRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.MarketDataRequest} MarketDataRequest
             */
            MarketDataRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.MarketDataRequest)
                    return object;
                var message = new $root.feedbox.market_api.MarketDataRequest();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.subscriptionRequest != null) {
                    if (typeof object.subscriptionRequest !== "object")
                        throw TypeError(".feedbox.market_api.MarketDataRequest.subscriptionRequest: object expected");
                    message.subscriptionRequest = $root.feedbox.market_api.SubscriptionRequest.fromObject(object.subscriptionRequest);
                }
                if (object.chartBarListRequest != null) {
                    if (typeof object.chartBarListRequest !== "object")
                        throw TypeError(".feedbox.market_api.MarketDataRequest.chartBarListRequest: object expected");
                    message.chartBarListRequest = $root.feedbox.market_api.ChartBarListRequest.fromObject(object.chartBarListRequest);
                }
                if (object.ping != null) {
                    if (typeof object.ping !== "object")
                        throw TypeError(".feedbox.market_api.MarketDataRequest.ping: object expected");
                    message.ping = $root.feedbox.ping.Ping.fromObject(object.ping);
                }
                if (object.getServerTime != null) {
                    if (typeof object.getServerTime !== "object")
                        throw TypeError(".feedbox.market_api.MarketDataRequest.getServerTime: object expected");
                    message.getServerTime = $root.feedbox.server_time.GetServerTime.fromObject(object.getServerTime);
                }
                return message;
            };

            /**
             * Creates a plain object from a MarketDataRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.MarketDataRequest
             * @static
             * @param {feedbox.market_api.MarketDataRequest} message MarketDataRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MarketDataRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.subscriptionRequest != null && message.hasOwnProperty("subscriptionRequest")) {
                    object.subscriptionRequest = $root.feedbox.market_api.SubscriptionRequest.toObject(message.subscriptionRequest, options);
                    if (options.oneofs)
                        object.body = "subscriptionRequest";
                }
                if (message.chartBarListRequest != null && message.hasOwnProperty("chartBarListRequest")) {
                    object.chartBarListRequest = $root.feedbox.market_api.ChartBarListRequest.toObject(message.chartBarListRequest, options);
                    if (options.oneofs)
                        object.body = "chartBarListRequest";
                }
                if (message.ping != null && message.hasOwnProperty("ping")) {
                    object.ping = $root.feedbox.ping.Ping.toObject(message.ping, options);
                    if (options.oneofs)
                        object.body = "ping";
                }
                if (message.getServerTime != null && message.hasOwnProperty("getServerTime")) {
                    object.getServerTime = $root.feedbox.server_time.GetServerTime.toObject(message.getServerTime, options);
                    if (options.oneofs)
                        object.body = "getServerTime";
                }
                return object;
            };

            /**
             * Converts this MarketDataRequest to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.MarketDataRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MarketDataRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MarketDataRequest;
        })();

        market_api.MarketData = (function() {

            /**
             * Properties of a MarketData.
             * @memberof feedbox.market_api
             * @interface IMarketData
             * @property {number|Long|null} [id] MarketData id
             * @property {feedbox.market_api.IResult|null} [result] MarketData result
             * @property {feedbox.market_api.ISubscriptionResult|null} [subscriptionResult] MarketData subscriptionResult
             * @property {feedbox.market_api.IChartBarList|null} [chartBarList] MarketData chartBarList
             * @property {feedbox.ping.IPong|null} [pong] MarketData pong
             * @property {feedbox.server_time.IServerTime|null} [serverTime] MarketData serverTime
             */

            /**
             * Constructs a new MarketData.
             * @memberof feedbox.market_api
             * @classdesc Represents a MarketData.
             * @implements IMarketData
             * @constructor
             * @param {feedbox.market_api.IMarketData=} [properties] Properties to set
             */
            function MarketData(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MarketData id.
             * @member {number|Long} id
             * @memberof feedbox.market_api.MarketData
             * @instance
             */
            MarketData.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * MarketData result.
             * @member {feedbox.market_api.IResult|null|undefined} result
             * @memberof feedbox.market_api.MarketData
             * @instance
             */
            MarketData.prototype.result = null;

            /**
             * MarketData subscriptionResult.
             * @member {feedbox.market_api.ISubscriptionResult|null|undefined} subscriptionResult
             * @memberof feedbox.market_api.MarketData
             * @instance
             */
            MarketData.prototype.subscriptionResult = null;

            /**
             * MarketData chartBarList.
             * @member {feedbox.market_api.IChartBarList|null|undefined} chartBarList
             * @memberof feedbox.market_api.MarketData
             * @instance
             */
            MarketData.prototype.chartBarList = null;

            /**
             * MarketData pong.
             * @member {feedbox.ping.IPong|null|undefined} pong
             * @memberof feedbox.market_api.MarketData
             * @instance
             */
            MarketData.prototype.pong = null;

            /**
             * MarketData serverTime.
             * @member {feedbox.server_time.IServerTime|null|undefined} serverTime
             * @memberof feedbox.market_api.MarketData
             * @instance
             */
            MarketData.prototype.serverTime = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * MarketData body.
             * @member {"result"|"subscriptionResult"|"chartBarList"|"pong"|"serverTime"|undefined} body
             * @memberof feedbox.market_api.MarketData
             * @instance
             */
            Object.defineProperty(MarketData.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["result", "subscriptionResult", "chartBarList", "pong", "serverTime"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new MarketData instance using the specified properties.
             * @function create
             * @memberof feedbox.market_api.MarketData
             * @static
             * @param {feedbox.market_api.IMarketData=} [properties] Properties to set
             * @returns {feedbox.market_api.MarketData} MarketData instance
             */
            MarketData.create = function create(properties) {
                return new MarketData(properties);
            };

            /**
             * Encodes the specified MarketData message. Does not implicitly {@link feedbox.market_api.MarketData.verify|verify} messages.
             * @function encode
             * @memberof feedbox.market_api.MarketData
             * @static
             * @param {feedbox.market_api.IMarketData} message MarketData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarketData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    $root.feedbox.market_api.Result.encode(message.result, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.subscriptionResult != null && Object.hasOwnProperty.call(message, "subscriptionResult"))
                    $root.feedbox.market_api.SubscriptionResult.encode(message.subscriptionResult, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.chartBarList != null && Object.hasOwnProperty.call(message, "chartBarList"))
                    $root.feedbox.market_api.ChartBarList.encode(message.chartBarList, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.pong != null && Object.hasOwnProperty.call(message, "pong"))
                    $root.feedbox.ping.Pong.encode(message.pong, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.serverTime != null && Object.hasOwnProperty.call(message, "serverTime"))
                    $root.feedbox.server_time.ServerTime.encode(message.serverTime, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a MarketData message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.market_api.MarketData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.market_api.MarketData} MarketData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarketData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.market_api.MarketData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.result = $root.feedbox.market_api.Result.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.subscriptionResult = $root.feedbox.market_api.SubscriptionResult.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.chartBarList = $root.feedbox.market_api.ChartBarList.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.pong = $root.feedbox.ping.Pong.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.serverTime = $root.feedbox.server_time.ServerTime.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MarketData message.
             * @function verify
             * @memberof feedbox.market_api.MarketData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MarketData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.result != null && message.hasOwnProperty("result")) {
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.Result.verify(message.result);
                        if (error)
                            return "result." + error;
                    }
                }
                if (message.subscriptionResult != null && message.hasOwnProperty("subscriptionResult")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.SubscriptionResult.verify(message.subscriptionResult);
                        if (error)
                            return "subscriptionResult." + error;
                    }
                }
                if (message.chartBarList != null && message.hasOwnProperty("chartBarList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.market_api.ChartBarList.verify(message.chartBarList);
                        if (error)
                            return "chartBarList." + error;
                    }
                }
                if (message.pong != null && message.hasOwnProperty("pong")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.ping.Pong.verify(message.pong);
                        if (error)
                            return "pong." + error;
                    }
                }
                if (message.serverTime != null && message.hasOwnProperty("serverTime")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.server_time.ServerTime.verify(message.serverTime);
                        if (error)
                            return "serverTime." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MarketData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.market_api.MarketData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.market_api.MarketData} MarketData
             */
            MarketData.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.market_api.MarketData)
                    return object;
                var message = new $root.feedbox.market_api.MarketData();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.result != null) {
                    if (typeof object.result !== "object")
                        throw TypeError(".feedbox.market_api.MarketData.result: object expected");
                    message.result = $root.feedbox.market_api.Result.fromObject(object.result);
                }
                if (object.subscriptionResult != null) {
                    if (typeof object.subscriptionResult !== "object")
                        throw TypeError(".feedbox.market_api.MarketData.subscriptionResult: object expected");
                    message.subscriptionResult = $root.feedbox.market_api.SubscriptionResult.fromObject(object.subscriptionResult);
                }
                if (object.chartBarList != null) {
                    if (typeof object.chartBarList !== "object")
                        throw TypeError(".feedbox.market_api.MarketData.chartBarList: object expected");
                    message.chartBarList = $root.feedbox.market_api.ChartBarList.fromObject(object.chartBarList);
                }
                if (object.pong != null) {
                    if (typeof object.pong !== "object")
                        throw TypeError(".feedbox.market_api.MarketData.pong: object expected");
                    message.pong = $root.feedbox.ping.Pong.fromObject(object.pong);
                }
                if (object.serverTime != null) {
                    if (typeof object.serverTime !== "object")
                        throw TypeError(".feedbox.market_api.MarketData.serverTime: object expected");
                    message.serverTime = $root.feedbox.server_time.ServerTime.fromObject(object.serverTime);
                }
                return message;
            };

            /**
             * Creates a plain object from a MarketData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.market_api.MarketData
             * @static
             * @param {feedbox.market_api.MarketData} message MarketData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MarketData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.result != null && message.hasOwnProperty("result")) {
                    object.result = $root.feedbox.market_api.Result.toObject(message.result, options);
                    if (options.oneofs)
                        object.body = "result";
                }
                if (message.subscriptionResult != null && message.hasOwnProperty("subscriptionResult")) {
                    object.subscriptionResult = $root.feedbox.market_api.SubscriptionResult.toObject(message.subscriptionResult, options);
                    if (options.oneofs)
                        object.body = "subscriptionResult";
                }
                if (message.chartBarList != null && message.hasOwnProperty("chartBarList")) {
                    object.chartBarList = $root.feedbox.market_api.ChartBarList.toObject(message.chartBarList, options);
                    if (options.oneofs)
                        object.body = "chartBarList";
                }
                if (message.pong != null && message.hasOwnProperty("pong")) {
                    object.pong = $root.feedbox.ping.Pong.toObject(message.pong, options);
                    if (options.oneofs)
                        object.body = "pong";
                }
                if (message.serverTime != null && message.hasOwnProperty("serverTime")) {
                    object.serverTime = $root.feedbox.server_time.ServerTime.toObject(message.serverTime, options);
                    if (options.oneofs)
                        object.body = "serverTime";
                }
                return object;
            };

            /**
             * Converts this MarketData to JSON.
             * @function toJSON
             * @memberof feedbox.market_api.MarketData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MarketData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MarketData;
        })();

        return market_api;
    })();

    feedbox.ping = (function() {

        /**
         * Namespace ping.
         * @memberof feedbox
         * @namespace
         */
        var ping = {};

        ping.Ping = (function() {

            /**
             * Properties of a Ping.
             * @memberof feedbox.ping
             * @interface IPing
             */

            /**
             * Constructs a new Ping.
             * @memberof feedbox.ping
             * @classdesc Represents a Ping.
             * @implements IPing
             * @constructor
             * @param {feedbox.ping.IPing=} [properties] Properties to set
             */
            function Ping(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Ping instance using the specified properties.
             * @function create
             * @memberof feedbox.ping.Ping
             * @static
             * @param {feedbox.ping.IPing=} [properties] Properties to set
             * @returns {feedbox.ping.Ping} Ping instance
             */
            Ping.create = function create(properties) {
                return new Ping(properties);
            };

            /**
             * Encodes the specified Ping message. Does not implicitly {@link feedbox.ping.Ping.verify|verify} messages.
             * @function encode
             * @memberof feedbox.ping.Ping
             * @static
             * @param {feedbox.ping.IPing} message Ping message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Ping.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a Ping message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.ping.Ping
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.ping.Ping} Ping
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Ping.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.ping.Ping();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Ping message.
             * @function verify
             * @memberof feedbox.ping.Ping
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Ping.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Ping message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.ping.Ping
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.ping.Ping} Ping
             */
            Ping.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.ping.Ping)
                    return object;
                return new $root.feedbox.ping.Ping();
            };

            /**
             * Creates a plain object from a Ping message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.ping.Ping
             * @static
             * @param {feedbox.ping.Ping} message Ping
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Ping.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Ping to JSON.
             * @function toJSON
             * @memberof feedbox.ping.Ping
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Ping.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Ping;
        })();

        ping.Pong = (function() {

            /**
             * Properties of a Pong.
             * @memberof feedbox.ping
             * @interface IPong
             */

            /**
             * Constructs a new Pong.
             * @memberof feedbox.ping
             * @classdesc Represents a Pong.
             * @implements IPong
             * @constructor
             * @param {feedbox.ping.IPong=} [properties] Properties to set
             */
            function Pong(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new Pong instance using the specified properties.
             * @function create
             * @memberof feedbox.ping.Pong
             * @static
             * @param {feedbox.ping.IPong=} [properties] Properties to set
             * @returns {feedbox.ping.Pong} Pong instance
             */
            Pong.create = function create(properties) {
                return new Pong(properties);
            };

            /**
             * Encodes the specified Pong message. Does not implicitly {@link feedbox.ping.Pong.verify|verify} messages.
             * @function encode
             * @memberof feedbox.ping.Pong
             * @static
             * @param {feedbox.ping.IPong} message Pong message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Pong.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a Pong message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.ping.Pong
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.ping.Pong} Pong
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Pong.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.ping.Pong();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Pong message.
             * @function verify
             * @memberof feedbox.ping.Pong
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Pong.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a Pong message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.ping.Pong
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.ping.Pong} Pong
             */
            Pong.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.ping.Pong)
                    return object;
                return new $root.feedbox.ping.Pong();
            };

            /**
             * Creates a plain object from a Pong message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.ping.Pong
             * @static
             * @param {feedbox.ping.Pong} message Pong
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Pong.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this Pong to JSON.
             * @function toJSON
             * @memberof feedbox.ping.Pong
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Pong.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Pong;
        })();

        return ping;
    })();

    feedbox.server_time = (function() {

        /**
         * Namespace server_time.
         * @memberof feedbox
         * @namespace
         */
        var server_time = {};

        server_time.GetServerTime = (function() {

            /**
             * Properties of a GetServerTime.
             * @memberof feedbox.server_time
             * @interface IGetServerTime
             */

            /**
             * Constructs a new GetServerTime.
             * @memberof feedbox.server_time
             * @classdesc Represents a GetServerTime.
             * @implements IGetServerTime
             * @constructor
             * @param {feedbox.server_time.IGetServerTime=} [properties] Properties to set
             */
            function GetServerTime(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetServerTime instance using the specified properties.
             * @function create
             * @memberof feedbox.server_time.GetServerTime
             * @static
             * @param {feedbox.server_time.IGetServerTime=} [properties] Properties to set
             * @returns {feedbox.server_time.GetServerTime} GetServerTime instance
             */
            GetServerTime.create = function create(properties) {
                return new GetServerTime(properties);
            };

            /**
             * Encodes the specified GetServerTime message. Does not implicitly {@link feedbox.server_time.GetServerTime.verify|verify} messages.
             * @function encode
             * @memberof feedbox.server_time.GetServerTime
             * @static
             * @param {feedbox.server_time.IGetServerTime} message GetServerTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetServerTime.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetServerTime message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.server_time.GetServerTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.server_time.GetServerTime} GetServerTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetServerTime.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.server_time.GetServerTime();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetServerTime message.
             * @function verify
             * @memberof feedbox.server_time.GetServerTime
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetServerTime.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetServerTime message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.server_time.GetServerTime
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.server_time.GetServerTime} GetServerTime
             */
            GetServerTime.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.server_time.GetServerTime)
                    return object;
                return new $root.feedbox.server_time.GetServerTime();
            };

            /**
             * Creates a plain object from a GetServerTime message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.server_time.GetServerTime
             * @static
             * @param {feedbox.server_time.GetServerTime} message GetServerTime
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetServerTime.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetServerTime to JSON.
             * @function toJSON
             * @memberof feedbox.server_time.GetServerTime
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetServerTime.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetServerTime;
        })();

        server_time.ServerTime = (function() {

            /**
             * Properties of a ServerTime.
             * @memberof feedbox.server_time
             * @interface IServerTime
             * @property {number|Long|null} [time] ServerTime time
             */

            /**
             * Constructs a new ServerTime.
             * @memberof feedbox.server_time
             * @classdesc Represents a ServerTime.
             * @implements IServerTime
             * @constructor
             * @param {feedbox.server_time.IServerTime=} [properties] Properties to set
             */
            function ServerTime(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServerTime time.
             * @member {number|Long} time
             * @memberof feedbox.server_time.ServerTime
             * @instance
             */
            ServerTime.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new ServerTime instance using the specified properties.
             * @function create
             * @memberof feedbox.server_time.ServerTime
             * @static
             * @param {feedbox.server_time.IServerTime=} [properties] Properties to set
             * @returns {feedbox.server_time.ServerTime} ServerTime instance
             */
            ServerTime.create = function create(properties) {
                return new ServerTime(properties);
            };

            /**
             * Encodes the specified ServerTime message. Does not implicitly {@link feedbox.server_time.ServerTime.verify|verify} messages.
             * @function encode
             * @memberof feedbox.server_time.ServerTime
             * @static
             * @param {feedbox.server_time.IServerTime} message ServerTime message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServerTime.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.time);
                return writer;
            };

            /**
             * Decodes a ServerTime message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.server_time.ServerTime
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.server_time.ServerTime} ServerTime
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServerTime.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.server_time.ServerTime();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.time = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServerTime message.
             * @function verify
             * @memberof feedbox.server_time.ServerTime
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServerTime.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.time != null && message.hasOwnProperty("time"))
                    if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                        return "time: integer|Long expected";
                return null;
            };

            /**
             * Creates a ServerTime message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.server_time.ServerTime
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.server_time.ServerTime} ServerTime
             */
            ServerTime.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.server_time.ServerTime)
                    return object;
                var message = new $root.feedbox.server_time.ServerTime();
                if (object.time != null)
                    if ($util.Long)
                        (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                    else if (typeof object.time === "string")
                        message.time = parseInt(object.time, 10);
                    else if (typeof object.time === "number")
                        message.time = object.time;
                    else if (typeof object.time === "object")
                        message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a ServerTime message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.server_time.ServerTime
             * @static
             * @param {feedbox.server_time.ServerTime} message ServerTime
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServerTime.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.time = options.longs === String ? "0" : 0;
                if (message.time != null && message.hasOwnProperty("time"))
                    if (typeof message.time === "number")
                        object.time = options.longs === String ? String(message.time) : message.time;
                    else
                        object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                return object;
            };

            /**
             * Converts this ServerTime to JSON.
             * @function toJSON
             * @memberof feedbox.server_time.ServerTime
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServerTime.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServerTime;
        })();

        return server_time;
    })();

    feedbox.technic_api = (function() {

        /**
         * Namespace technic_api.
         * @memberof feedbox
         * @namespace
         */
        var technic_api = {};

        /**
         * ProtocolVersion enum.
         * @name feedbox.technic_api.ProtocolVersion
         * @enum {number}
         * @property {number} __PLACEHOLDER1=0 __PLACEHOLDER1 value
         * @property {number} __PLACEHOLDER2=0 __PLACEHOLDER2 value
         * @property {number} MAJOR=1 MAJOR value
         * @property {number} MINOR=1 MINOR value
         * @property {number} PATCH=0 PATCH value
         */
        technic_api.ProtocolVersion = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "__PLACEHOLDER1"] = 0;
            values["__PLACEHOLDER2"] = 0;
            values[valuesById[1] = "MAJOR"] = 1;
            values["MINOR"] = 1;
            values["PATCH"] = 0;
            return values;
        })();

        technic_api.Result = (function() {

            /**
             * Properties of a Result.
             * @memberof feedbox.technic_api
             * @interface IResult
             * @property {feedbox.technic_api.Result.Code|null} [code] Result code
             * @property {string|null} [text] Result text
             */

            /**
             * Constructs a new Result.
             * @memberof feedbox.technic_api
             * @classdesc Represents a Result.
             * @implements IResult
             * @constructor
             * @param {feedbox.technic_api.IResult=} [properties] Properties to set
             */
            function Result(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Result code.
             * @member {feedbox.technic_api.Result.Code} code
             * @memberof feedbox.technic_api.Result
             * @instance
             */
            Result.prototype.code = 0;

            /**
             * Result text.
             * @member {string} text
             * @memberof feedbox.technic_api.Result
             * @instance
             */
            Result.prototype.text = "";

            /**
             * Creates a new Result instance using the specified properties.
             * @function create
             * @memberof feedbox.technic_api.Result
             * @static
             * @param {feedbox.technic_api.IResult=} [properties] Properties to set
             * @returns {feedbox.technic_api.Result} Result instance
             */
            Result.create = function create(properties) {
                return new Result(properties);
            };

            /**
             * Encodes the specified Result message. Does not implicitly {@link feedbox.technic_api.Result.verify|verify} messages.
             * @function encode
             * @memberof feedbox.technic_api.Result
             * @static
             * @param {feedbox.technic_api.IResult} message Result message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Result.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
                return writer;
            };

            /**
             * Decodes a Result message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.technic_api.Result
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.technic_api.Result} Result
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Result.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.technic_api.Result();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.text = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Result message.
             * @function verify
             * @memberof feedbox.technic_api.Result
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Result.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                return null;
            };

            /**
             * Creates a Result message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.technic_api.Result
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.technic_api.Result} Result
             */
            Result.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.technic_api.Result)
                    return object;
                var message = new $root.feedbox.technic_api.Result();
                switch (object.code) {
                case "SUCCESS":
                case 0:
                    message.code = 0;
                    break;
                case "COMMON_ERROR":
                case 1:
                    message.code = 1;
                    break;
                case "INVALID_FIELD_VALUE":
                case 2:
                    message.code = 2;
                    break;
                case "HANDLER_NOT_FOUND":
                case 3:
                    message.code = 3;
                    break;
                }
                if (object.text != null)
                    message.text = String(object.text);
                return message;
            };

            /**
             * Creates a plain object from a Result message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.technic_api.Result
             * @static
             * @param {feedbox.technic_api.Result} message Result
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Result.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "SUCCESS" : 0;
                    object.text = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.feedbox.technic_api.Result.Code[message.code] : message.code;
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                return object;
            };

            /**
             * Converts this Result to JSON.
             * @function toJSON
             * @memberof feedbox.technic_api.Result
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Result.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Code enum.
             * @name feedbox.technic_api.Result.Code
             * @enum {number}
             * @property {number} SUCCESS=0 SUCCESS value
             * @property {number} COMMON_ERROR=1 COMMON_ERROR value
             * @property {number} INVALID_FIELD_VALUE=2 INVALID_FIELD_VALUE value
             * @property {number} HANDLER_NOT_FOUND=3 HANDLER_NOT_FOUND value
             */
            Result.Code = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUCCESS"] = 0;
                values[valuesById[1] = "COMMON_ERROR"] = 1;
                values[valuesById[2] = "INVALID_FIELD_VALUE"] = 2;
                values[valuesById[3] = "HANDLER_NOT_FOUND"] = 3;
                return values;
            })();

            return Result;
        })();

        technic_api.Settings = (function() {

            /**
             * Properties of a Settings.
             * @memberof feedbox.technic_api
             * @interface ISettings
             * @property {Array.<string>|null} [favoriteSymbol] Settings favoriteSymbol
             * @property {feedbox.technic_api.Settings.IWebTerminal|null} [webTerminal] Settings webTerminal
             */

            /**
             * Constructs a new Settings.
             * @memberof feedbox.technic_api
             * @classdesc Represents a Settings.
             * @implements ISettings
             * @constructor
             * @param {feedbox.technic_api.ISettings=} [properties] Properties to set
             */
            function Settings(properties) {
                this.favoriteSymbol = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Settings favoriteSymbol.
             * @member {Array.<string>} favoriteSymbol
             * @memberof feedbox.technic_api.Settings
             * @instance
             */
            Settings.prototype.favoriteSymbol = $util.emptyArray;

            /**
             * Settings webTerminal.
             * @member {feedbox.technic_api.Settings.IWebTerminal|null|undefined} webTerminal
             * @memberof feedbox.technic_api.Settings
             * @instance
             */
            Settings.prototype.webTerminal = null;

            /**
             * Creates a new Settings instance using the specified properties.
             * @function create
             * @memberof feedbox.technic_api.Settings
             * @static
             * @param {feedbox.technic_api.ISettings=} [properties] Properties to set
             * @returns {feedbox.technic_api.Settings} Settings instance
             */
            Settings.create = function create(properties) {
                return new Settings(properties);
            };

            /**
             * Encodes the specified Settings message. Does not implicitly {@link feedbox.technic_api.Settings.verify|verify} messages.
             * @function encode
             * @memberof feedbox.technic_api.Settings
             * @static
             * @param {feedbox.technic_api.ISettings} message Settings message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Settings.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.favoriteSymbol != null && message.favoriteSymbol.length)
                    for (var i = 0; i < message.favoriteSymbol.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.favoriteSymbol[i]);
                if (message.webTerminal != null && Object.hasOwnProperty.call(message, "webTerminal"))
                    $root.feedbox.technic_api.Settings.WebTerminal.encode(message.webTerminal, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Settings message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.technic_api.Settings
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.technic_api.Settings} Settings
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Settings.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.technic_api.Settings();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.favoriteSymbol && message.favoriteSymbol.length))
                            message.favoriteSymbol = [];
                        message.favoriteSymbol.push(reader.string());
                        break;
                    case 2:
                        message.webTerminal = $root.feedbox.technic_api.Settings.WebTerminal.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Settings message.
             * @function verify
             * @memberof feedbox.technic_api.Settings
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Settings.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.favoriteSymbol != null && message.hasOwnProperty("favoriteSymbol")) {
                    if (!Array.isArray(message.favoriteSymbol))
                        return "favoriteSymbol: array expected";
                    for (var i = 0; i < message.favoriteSymbol.length; ++i)
                        if (!$util.isString(message.favoriteSymbol[i]))
                            return "favoriteSymbol: string[] expected";
                }
                if (message.webTerminal != null && message.hasOwnProperty("webTerminal")) {
                    var error = $root.feedbox.technic_api.Settings.WebTerminal.verify(message.webTerminal);
                    if (error)
                        return "webTerminal." + error;
                }
                return null;
            };

            /**
             * Creates a Settings message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.technic_api.Settings
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.technic_api.Settings} Settings
             */
            Settings.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.technic_api.Settings)
                    return object;
                var message = new $root.feedbox.technic_api.Settings();
                if (object.favoriteSymbol) {
                    if (!Array.isArray(object.favoriteSymbol))
                        throw TypeError(".feedbox.technic_api.Settings.favoriteSymbol: array expected");
                    message.favoriteSymbol = [];
                    for (var i = 0; i < object.favoriteSymbol.length; ++i)
                        message.favoriteSymbol[i] = String(object.favoriteSymbol[i]);
                }
                if (object.webTerminal != null) {
                    if (typeof object.webTerminal !== "object")
                        throw TypeError(".feedbox.technic_api.Settings.webTerminal: object expected");
                    message.webTerminal = $root.feedbox.technic_api.Settings.WebTerminal.fromObject(object.webTerminal);
                }
                return message;
            };

            /**
             * Creates a plain object from a Settings message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.technic_api.Settings
             * @static
             * @param {feedbox.technic_api.Settings} message Settings
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Settings.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.favoriteSymbol = [];
                if (options.defaults)
                    object.webTerminal = null;
                if (message.favoriteSymbol && message.favoriteSymbol.length) {
                    object.favoriteSymbol = [];
                    for (var j = 0; j < message.favoriteSymbol.length; ++j)
                        object.favoriteSymbol[j] = message.favoriteSymbol[j];
                }
                if (message.webTerminal != null && message.hasOwnProperty("webTerminal"))
                    object.webTerminal = $root.feedbox.technic_api.Settings.WebTerminal.toObject(message.webTerminal, options);
                return object;
            };

            /**
             * Converts this Settings to JSON.
             * @function toJSON
             * @memberof feedbox.technic_api.Settings
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Settings.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Settings.WebTerminal = (function() {

                /**
                 * Properties of a WebTerminal.
                 * @memberof feedbox.technic_api.Settings
                 * @interface IWebTerminal
                 * @property {string|null} [language] WebTerminal language
                 * @property {feedbox.technic_api.Settings.WebTerminal.WebTerminalTheme|null} [theme] WebTerminal theme
                 */

                /**
                 * Constructs a new WebTerminal.
                 * @memberof feedbox.technic_api.Settings
                 * @classdesc Represents a WebTerminal.
                 * @implements IWebTerminal
                 * @constructor
                 * @param {feedbox.technic_api.Settings.IWebTerminal=} [properties] Properties to set
                 */
                function WebTerminal(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WebTerminal language.
                 * @member {string} language
                 * @memberof feedbox.technic_api.Settings.WebTerminal
                 * @instance
                 */
                WebTerminal.prototype.language = "";

                /**
                 * WebTerminal theme.
                 * @member {feedbox.technic_api.Settings.WebTerminal.WebTerminalTheme} theme
                 * @memberof feedbox.technic_api.Settings.WebTerminal
                 * @instance
                 */
                WebTerminal.prototype.theme = 0;

                /**
                 * Creates a new WebTerminal instance using the specified properties.
                 * @function create
                 * @memberof feedbox.technic_api.Settings.WebTerminal
                 * @static
                 * @param {feedbox.technic_api.Settings.IWebTerminal=} [properties] Properties to set
                 * @returns {feedbox.technic_api.Settings.WebTerminal} WebTerminal instance
                 */
                WebTerminal.create = function create(properties) {
                    return new WebTerminal(properties);
                };

                /**
                 * Encodes the specified WebTerminal message. Does not implicitly {@link feedbox.technic_api.Settings.WebTerminal.verify|verify} messages.
                 * @function encode
                 * @memberof feedbox.technic_api.Settings.WebTerminal
                 * @static
                 * @param {feedbox.technic_api.Settings.IWebTerminal} message WebTerminal message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WebTerminal.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.language);
                    if (message.theme != null && Object.hasOwnProperty.call(message, "theme"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.theme);
                    return writer;
                };

                /**
                 * Decodes a WebTerminal message from the specified reader or buffer.
                 * @function decode
                 * @memberof feedbox.technic_api.Settings.WebTerminal
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {feedbox.technic_api.Settings.WebTerminal} WebTerminal
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WebTerminal.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.technic_api.Settings.WebTerminal();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.language = reader.string();
                            break;
                        case 2:
                            message.theme = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a WebTerminal message.
                 * @function verify
                 * @memberof feedbox.technic_api.Settings.WebTerminal
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WebTerminal.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.language != null && message.hasOwnProperty("language"))
                        if (!$util.isString(message.language))
                            return "language: string expected";
                    if (message.theme != null && message.hasOwnProperty("theme"))
                        switch (message.theme) {
                        default:
                            return "theme: enum value expected";
                        case 0:
                        case 1:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a WebTerminal message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof feedbox.technic_api.Settings.WebTerminal
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {feedbox.technic_api.Settings.WebTerminal} WebTerminal
                 */
                WebTerminal.fromObject = function fromObject(object) {
                    if (object instanceof $root.feedbox.technic_api.Settings.WebTerminal)
                        return object;
                    var message = new $root.feedbox.technic_api.Settings.WebTerminal();
                    if (object.language != null)
                        message.language = String(object.language);
                    switch (object.theme) {
                    case "LIGHT":
                    case 0:
                        message.theme = 0;
                        break;
                    case "DARK":
                    case 1:
                        message.theme = 1;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a WebTerminal message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof feedbox.technic_api.Settings.WebTerminal
                 * @static
                 * @param {feedbox.technic_api.Settings.WebTerminal} message WebTerminal
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WebTerminal.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.language = "";
                        object.theme = options.enums === String ? "LIGHT" : 0;
                    }
                    if (message.language != null && message.hasOwnProperty("language"))
                        object.language = message.language;
                    if (message.theme != null && message.hasOwnProperty("theme"))
                        object.theme = options.enums === String ? $root.feedbox.technic_api.Settings.WebTerminal.WebTerminalTheme[message.theme] : message.theme;
                    return object;
                };

                /**
                 * Converts this WebTerminal to JSON.
                 * @function toJSON
                 * @memberof feedbox.technic_api.Settings.WebTerminal
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WebTerminal.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * WebTerminalTheme enum.
                 * @name feedbox.technic_api.Settings.WebTerminal.WebTerminalTheme
                 * @enum {number}
                 * @property {number} LIGHT=0 LIGHT value
                 * @property {number} DARK=1 DARK value
                 */
                WebTerminal.WebTerminalTheme = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "LIGHT"] = 0;
                    values[valuesById[1] = "DARK"] = 1;
                    return values;
                })();

                return WebTerminal;
            })();

            return Settings;
        })();

        technic_api.GetSettings = (function() {

            /**
             * Properties of a GetSettings.
             * @memberof feedbox.technic_api
             * @interface IGetSettings
             */

            /**
             * Constructs a new GetSettings.
             * @memberof feedbox.technic_api
             * @classdesc Represents a GetSettings.
             * @implements IGetSettings
             * @constructor
             * @param {feedbox.technic_api.IGetSettings=} [properties] Properties to set
             */
            function GetSettings(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetSettings instance using the specified properties.
             * @function create
             * @memberof feedbox.technic_api.GetSettings
             * @static
             * @param {feedbox.technic_api.IGetSettings=} [properties] Properties to set
             * @returns {feedbox.technic_api.GetSettings} GetSettings instance
             */
            GetSettings.create = function create(properties) {
                return new GetSettings(properties);
            };

            /**
             * Encodes the specified GetSettings message. Does not implicitly {@link feedbox.technic_api.GetSettings.verify|verify} messages.
             * @function encode
             * @memberof feedbox.technic_api.GetSettings
             * @static
             * @param {feedbox.technic_api.IGetSettings} message GetSettings message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSettings.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetSettings message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.technic_api.GetSettings
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.technic_api.GetSettings} GetSettings
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSettings.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.technic_api.GetSettings();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetSettings message.
             * @function verify
             * @memberof feedbox.technic_api.GetSettings
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSettings.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetSettings message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.technic_api.GetSettings
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.technic_api.GetSettings} GetSettings
             */
            GetSettings.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.technic_api.GetSettings)
                    return object;
                return new $root.feedbox.technic_api.GetSettings();
            };

            /**
             * Creates a plain object from a GetSettings message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.technic_api.GetSettings
             * @static
             * @param {feedbox.technic_api.GetSettings} message GetSettings
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSettings.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetSettings to JSON.
             * @function toJSON
             * @memberof feedbox.technic_api.GetSettings
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSettings.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetSettings;
        })();

        technic_api.StoreSettings = (function() {

            /**
             * Properties of a StoreSettings.
             * @memberof feedbox.technic_api
             * @interface IStoreSettings
             * @property {feedbox.technic_api.ISettings|null} [settings] StoreSettings settings
             */

            /**
             * Constructs a new StoreSettings.
             * @memberof feedbox.technic_api
             * @classdesc Represents a StoreSettings.
             * @implements IStoreSettings
             * @constructor
             * @param {feedbox.technic_api.IStoreSettings=} [properties] Properties to set
             */
            function StoreSettings(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StoreSettings settings.
             * @member {feedbox.technic_api.ISettings|null|undefined} settings
             * @memberof feedbox.technic_api.StoreSettings
             * @instance
             */
            StoreSettings.prototype.settings = null;

            /**
             * Creates a new StoreSettings instance using the specified properties.
             * @function create
             * @memberof feedbox.technic_api.StoreSettings
             * @static
             * @param {feedbox.technic_api.IStoreSettings=} [properties] Properties to set
             * @returns {feedbox.technic_api.StoreSettings} StoreSettings instance
             */
            StoreSettings.create = function create(properties) {
                return new StoreSettings(properties);
            };

            /**
             * Encodes the specified StoreSettings message. Does not implicitly {@link feedbox.technic_api.StoreSettings.verify|verify} messages.
             * @function encode
             * @memberof feedbox.technic_api.StoreSettings
             * @static
             * @param {feedbox.technic_api.IStoreSettings} message StoreSettings message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StoreSettings.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                    $root.feedbox.technic_api.Settings.encode(message.settings, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a StoreSettings message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.technic_api.StoreSettings
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.technic_api.StoreSettings} StoreSettings
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StoreSettings.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.technic_api.StoreSettings();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.settings = $root.feedbox.technic_api.Settings.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a StoreSettings message.
             * @function verify
             * @memberof feedbox.technic_api.StoreSettings
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StoreSettings.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.settings != null && message.hasOwnProperty("settings")) {
                    var error = $root.feedbox.technic_api.Settings.verify(message.settings);
                    if (error)
                        return "settings." + error;
                }
                return null;
            };

            /**
             * Creates a StoreSettings message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.technic_api.StoreSettings
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.technic_api.StoreSettings} StoreSettings
             */
            StoreSettings.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.technic_api.StoreSettings)
                    return object;
                var message = new $root.feedbox.technic_api.StoreSettings();
                if (object.settings != null) {
                    if (typeof object.settings !== "object")
                        throw TypeError(".feedbox.technic_api.StoreSettings.settings: object expected");
                    message.settings = $root.feedbox.technic_api.Settings.fromObject(object.settings);
                }
                return message;
            };

            /**
             * Creates a plain object from a StoreSettings message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.technic_api.StoreSettings
             * @static
             * @param {feedbox.technic_api.StoreSettings} message StoreSettings
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StoreSettings.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.settings = null;
                if (message.settings != null && message.hasOwnProperty("settings"))
                    object.settings = $root.feedbox.technic_api.Settings.toObject(message.settings, options);
                return object;
            };

            /**
             * Converts this StoreSettings to JSON.
             * @function toJSON
             * @memberof feedbox.technic_api.StoreSettings
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StoreSettings.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return StoreSettings;
        })();

        technic_api.TechnicDataRequest = (function() {

            /**
             * Properties of a TechnicDataRequest.
             * @memberof feedbox.technic_api
             * @interface ITechnicDataRequest
             * @property {number|Long|null} [id] TechnicDataRequest id
             * @property {feedbox.technic_api.IGetSettings|null} [getSettings] TechnicDataRequest getSettings
             * @property {feedbox.technic_api.IStoreSettings|null} [storeSettings] TechnicDataRequest storeSettings
             * @property {feedbox.ping.IPing|null} [ping] TechnicDataRequest ping
             */

            /**
             * Constructs a new TechnicDataRequest.
             * @memberof feedbox.technic_api
             * @classdesc Represents a TechnicDataRequest.
             * @implements ITechnicDataRequest
             * @constructor
             * @param {feedbox.technic_api.ITechnicDataRequest=} [properties] Properties to set
             */
            function TechnicDataRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TechnicDataRequest id.
             * @member {number|Long} id
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @instance
             */
            TechnicDataRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * TechnicDataRequest getSettings.
             * @member {feedbox.technic_api.IGetSettings|null|undefined} getSettings
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @instance
             */
            TechnicDataRequest.prototype.getSettings = null;

            /**
             * TechnicDataRequest storeSettings.
             * @member {feedbox.technic_api.IStoreSettings|null|undefined} storeSettings
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @instance
             */
            TechnicDataRequest.prototype.storeSettings = null;

            /**
             * TechnicDataRequest ping.
             * @member {feedbox.ping.IPing|null|undefined} ping
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @instance
             */
            TechnicDataRequest.prototype.ping = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * TechnicDataRequest body.
             * @member {"getSettings"|"storeSettings"|"ping"|undefined} body
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @instance
             */
            Object.defineProperty(TechnicDataRequest.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["getSettings", "storeSettings", "ping"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new TechnicDataRequest instance using the specified properties.
             * @function create
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @static
             * @param {feedbox.technic_api.ITechnicDataRequest=} [properties] Properties to set
             * @returns {feedbox.technic_api.TechnicDataRequest} TechnicDataRequest instance
             */
            TechnicDataRequest.create = function create(properties) {
                return new TechnicDataRequest(properties);
            };

            /**
             * Encodes the specified TechnicDataRequest message. Does not implicitly {@link feedbox.technic_api.TechnicDataRequest.verify|verify} messages.
             * @function encode
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @static
             * @param {feedbox.technic_api.ITechnicDataRequest} message TechnicDataRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TechnicDataRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.getSettings != null && Object.hasOwnProperty.call(message, "getSettings"))
                    $root.feedbox.technic_api.GetSettings.encode(message.getSettings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.storeSettings != null && Object.hasOwnProperty.call(message, "storeSettings"))
                    $root.feedbox.technic_api.StoreSettings.encode(message.storeSettings, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.ping != null && Object.hasOwnProperty.call(message, "ping"))
                    $root.feedbox.ping.Ping.encode(message.ping, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a TechnicDataRequest message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.technic_api.TechnicDataRequest} TechnicDataRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TechnicDataRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.technic_api.TechnicDataRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.getSettings = $root.feedbox.technic_api.GetSettings.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.storeSettings = $root.feedbox.technic_api.StoreSettings.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.ping = $root.feedbox.ping.Ping.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a TechnicDataRequest message.
             * @function verify
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TechnicDataRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.getSettings != null && message.hasOwnProperty("getSettings")) {
                    properties.body = 1;
                    {
                        var error = $root.feedbox.technic_api.GetSettings.verify(message.getSettings);
                        if (error)
                            return "getSettings." + error;
                    }
                }
                if (message.storeSettings != null && message.hasOwnProperty("storeSettings")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.technic_api.StoreSettings.verify(message.storeSettings);
                        if (error)
                            return "storeSettings." + error;
                    }
                }
                if (message.ping != null && message.hasOwnProperty("ping")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.ping.Ping.verify(message.ping);
                        if (error)
                            return "ping." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a TechnicDataRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.technic_api.TechnicDataRequest} TechnicDataRequest
             */
            TechnicDataRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.technic_api.TechnicDataRequest)
                    return object;
                var message = new $root.feedbox.technic_api.TechnicDataRequest();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.getSettings != null) {
                    if (typeof object.getSettings !== "object")
                        throw TypeError(".feedbox.technic_api.TechnicDataRequest.getSettings: object expected");
                    message.getSettings = $root.feedbox.technic_api.GetSettings.fromObject(object.getSettings);
                }
                if (object.storeSettings != null) {
                    if (typeof object.storeSettings !== "object")
                        throw TypeError(".feedbox.technic_api.TechnicDataRequest.storeSettings: object expected");
                    message.storeSettings = $root.feedbox.technic_api.StoreSettings.fromObject(object.storeSettings);
                }
                if (object.ping != null) {
                    if (typeof object.ping !== "object")
                        throw TypeError(".feedbox.technic_api.TechnicDataRequest.ping: object expected");
                    message.ping = $root.feedbox.ping.Ping.fromObject(object.ping);
                }
                return message;
            };

            /**
             * Creates a plain object from a TechnicDataRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @static
             * @param {feedbox.technic_api.TechnicDataRequest} message TechnicDataRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TechnicDataRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.getSettings != null && message.hasOwnProperty("getSettings")) {
                    object.getSettings = $root.feedbox.technic_api.GetSettings.toObject(message.getSettings, options);
                    if (options.oneofs)
                        object.body = "getSettings";
                }
                if (message.storeSettings != null && message.hasOwnProperty("storeSettings")) {
                    object.storeSettings = $root.feedbox.technic_api.StoreSettings.toObject(message.storeSettings, options);
                    if (options.oneofs)
                        object.body = "storeSettings";
                }
                if (message.ping != null && message.hasOwnProperty("ping")) {
                    object.ping = $root.feedbox.ping.Ping.toObject(message.ping, options);
                    if (options.oneofs)
                        object.body = "ping";
                }
                return object;
            };

            /**
             * Converts this TechnicDataRequest to JSON.
             * @function toJSON
             * @memberof feedbox.technic_api.TechnicDataRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TechnicDataRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TechnicDataRequest;
        })();

        technic_api.TechnicData = (function() {

            /**
             * Properties of a TechnicData.
             * @memberof feedbox.technic_api
             * @interface ITechnicData
             * @property {number|Long|null} [id] TechnicData id
             * @property {feedbox.technic_api.IResult|null} [result] TechnicData result
             * @property {feedbox.technic_api.ISettings|null} [settings] TechnicData settings
             * @property {feedbox.ping.IPong|null} [pong] TechnicData pong
             */

            /**
             * Constructs a new TechnicData.
             * @memberof feedbox.technic_api
             * @classdesc Represents a TechnicData.
             * @implements ITechnicData
             * @constructor
             * @param {feedbox.technic_api.ITechnicData=} [properties] Properties to set
             */
            function TechnicData(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TechnicData id.
             * @member {number|Long} id
             * @memberof feedbox.technic_api.TechnicData
             * @instance
             */
            TechnicData.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * TechnicData result.
             * @member {feedbox.technic_api.IResult|null|undefined} result
             * @memberof feedbox.technic_api.TechnicData
             * @instance
             */
            TechnicData.prototype.result = null;

            /**
             * TechnicData settings.
             * @member {feedbox.technic_api.ISettings|null|undefined} settings
             * @memberof feedbox.technic_api.TechnicData
             * @instance
             */
            TechnicData.prototype.settings = null;

            /**
             * TechnicData pong.
             * @member {feedbox.ping.IPong|null|undefined} pong
             * @memberof feedbox.technic_api.TechnicData
             * @instance
             */
            TechnicData.prototype.pong = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * TechnicData body.
             * @member {"result"|"settings"|"pong"|undefined} body
             * @memberof feedbox.technic_api.TechnicData
             * @instance
             */
            Object.defineProperty(TechnicData.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["result", "settings", "pong"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new TechnicData instance using the specified properties.
             * @function create
             * @memberof feedbox.technic_api.TechnicData
             * @static
             * @param {feedbox.technic_api.ITechnicData=} [properties] Properties to set
             * @returns {feedbox.technic_api.TechnicData} TechnicData instance
             */
            TechnicData.create = function create(properties) {
                return new TechnicData(properties);
            };

            /**
             * Encodes the specified TechnicData message. Does not implicitly {@link feedbox.technic_api.TechnicData.verify|verify} messages.
             * @function encode
             * @memberof feedbox.technic_api.TechnicData
             * @static
             * @param {feedbox.technic_api.ITechnicData} message TechnicData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TechnicData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    $root.feedbox.technic_api.Result.encode(message.result, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                    $root.feedbox.technic_api.Settings.encode(message.settings, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.pong != null && Object.hasOwnProperty.call(message, "pong"))
                    $root.feedbox.ping.Pong.encode(message.pong, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a TechnicData message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.technic_api.TechnicData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.technic_api.TechnicData} TechnicData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TechnicData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.technic_api.TechnicData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 2:
                        message.result = $root.feedbox.technic_api.Result.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.settings = $root.feedbox.technic_api.Settings.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.pong = $root.feedbox.ping.Pong.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a TechnicData message.
             * @function verify
             * @memberof feedbox.technic_api.TechnicData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TechnicData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.result != null && message.hasOwnProperty("result")) {
                    properties.body = 1;
                    {
                        var error = $root.feedbox.technic_api.Result.verify(message.result);
                        if (error)
                            return "result." + error;
                    }
                }
                if (message.settings != null && message.hasOwnProperty("settings")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.technic_api.Settings.verify(message.settings);
                        if (error)
                            return "settings." + error;
                    }
                }
                if (message.pong != null && message.hasOwnProperty("pong")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.ping.Pong.verify(message.pong);
                        if (error)
                            return "pong." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a TechnicData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.technic_api.TechnicData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.technic_api.TechnicData} TechnicData
             */
            TechnicData.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.technic_api.TechnicData)
                    return object;
                var message = new $root.feedbox.technic_api.TechnicData();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.result != null) {
                    if (typeof object.result !== "object")
                        throw TypeError(".feedbox.technic_api.TechnicData.result: object expected");
                    message.result = $root.feedbox.technic_api.Result.fromObject(object.result);
                }
                if (object.settings != null) {
                    if (typeof object.settings !== "object")
                        throw TypeError(".feedbox.technic_api.TechnicData.settings: object expected");
                    message.settings = $root.feedbox.technic_api.Settings.fromObject(object.settings);
                }
                if (object.pong != null) {
                    if (typeof object.pong !== "object")
                        throw TypeError(".feedbox.technic_api.TechnicData.pong: object expected");
                    message.pong = $root.feedbox.ping.Pong.fromObject(object.pong);
                }
                return message;
            };

            /**
             * Creates a plain object from a TechnicData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.technic_api.TechnicData
             * @static
             * @param {feedbox.technic_api.TechnicData} message TechnicData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TechnicData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.result != null && message.hasOwnProperty("result")) {
                    object.result = $root.feedbox.technic_api.Result.toObject(message.result, options);
                    if (options.oneofs)
                        object.body = "result";
                }
                if (message.settings != null && message.hasOwnProperty("settings")) {
                    object.settings = $root.feedbox.technic_api.Settings.toObject(message.settings, options);
                    if (options.oneofs)
                        object.body = "settings";
                }
                if (message.pong != null && message.hasOwnProperty("pong")) {
                    object.pong = $root.feedbox.ping.Pong.toObject(message.pong, options);
                    if (options.oneofs)
                        object.body = "pong";
                }
                return object;
            };

            /**
             * Converts this TechnicData to JSON.
             * @function toJSON
             * @memberof feedbox.technic_api.TechnicData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TechnicData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TechnicData;
        })();

        return technic_api;
    })();

    feedbox.trade_api = (function() {

        /**
         * Namespace trade_api.
         * @memberof feedbox
         * @namespace
         */
        var trade_api = {};

        /**
         * ProtocolVersion enum.
         * @name feedbox.trade_api.ProtocolVersion
         * @enum {number}
         * @property {number} __PLACEHOLDER1=0 __PLACEHOLDER1 value
         * @property {number} __PLACEHOLDER2=0 __PLACEHOLDER2 value
         * @property {number} MAJOR=7 MAJOR value
         * @property {number} MINOR=0 MINOR value
         * @property {number} PATCH=0 PATCH value
         */
        trade_api.ProtocolVersion = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "__PLACEHOLDER1"] = 0;
            values["__PLACEHOLDER2"] = 0;
            values[valuesById[7] = "MAJOR"] = 7;
            values["MINOR"] = 0;
            values["PATCH"] = 0;
            return values;
        })();

        /**
         * Side enum.
         * @name feedbox.trade_api.Side
         * @enum {number}
         * @property {number} BUY=0 BUY value
         * @property {number} SELL=1 SELL value
         */
        trade_api.Side = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "BUY"] = 0;
            values[valuesById[1] = "SELL"] = 1;
            return values;
        })();

        /**
         * OrderStatus enum.
         * @name feedbox.trade_api.OrderStatus
         * @enum {number}
         * @property {number} O_NEW=0 O_NEW value
         * @property {number} O_PARTIALLY_FILLED=1 O_PARTIALLY_FILLED value
         * @property {number} O_FILLED=2 O_FILLED value
         * @property {number} O_CANCELED=3 O_CANCELED value
         * @property {number} O_REJECTED=4 O_REJECTED value
         * @property {number} O_EXPIRED=5 O_EXPIRED value
         * @property {number} O_PENDING_NEW=6 O_PENDING_NEW value
         */
        trade_api.OrderStatus = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "O_NEW"] = 0;
            values[valuesById[1] = "O_PARTIALLY_FILLED"] = 1;
            values[valuesById[2] = "O_FILLED"] = 2;
            values[valuesById[3] = "O_CANCELED"] = 3;
            values[valuesById[4] = "O_REJECTED"] = 4;
            values[valuesById[5] = "O_EXPIRED"] = 5;
            values[valuesById[6] = "O_PENDING_NEW"] = 6;
            return values;
        })();

        /**
         * OrderType enum.
         * @name feedbox.trade_api.OrderType
         * @enum {number}
         * @property {number} O_MARKET=0 O_MARKET value
         * @property {number} O_LIMIT=1 O_LIMIT value
         * @property {number} O_STOP=2 O_STOP value
         * @property {number} O_STOP_LIMIT=3 O_STOP_LIMIT value
         */
        trade_api.OrderType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "O_MARKET"] = 0;
            values[valuesById[1] = "O_LIMIT"] = 1;
            values[valuesById[2] = "O_STOP"] = 2;
            values[valuesById[3] = "O_STOP_LIMIT"] = 3;
            return values;
        })();

        /**
         * OrderTimeInForce enum.
         * @name feedbox.trade_api.OrderTimeInForce
         * @enum {number}
         * @property {number} FOK=0 FOK value
         * @property {number} DAY=1 DAY value
         * @property {number} GTD=2 GTD value
         * @property {number} GTC=3 GTC value
         * @property {number} IOC=4 IOC value
         */
        trade_api.OrderTimeInForce = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "FOK"] = 0;
            values[valuesById[1] = "DAY"] = 1;
            values[valuesById[2] = "GTD"] = 2;
            values[valuesById[3] = "GTC"] = 3;
            values[valuesById[4] = "IOC"] = 4;
            return values;
        })();

        /**
         * OrderReasonCode enum.
         * @name feedbox.trade_api.OrderReasonCode
         * @enum {number}
         * @property {number} ORC_NONE=0 ORC_NONE value
         * @property {number} ORC_SERVER_ERROR=1 ORC_SERVER_ERROR value
         * @property {number} ORC_NON_TRADING_TIME=2 ORC_NON_TRADING_TIME value
         * @property {number} ORC_ORDER_EXPIRED=3 ORC_ORDER_EXPIRED value
         * @property {number} ORC_NOT_ENOUGH_MARGIN=4 ORC_NOT_ENOUGH_MARGIN value
         * @property {number} ORC_NOT_ENOUGH_DEPTH=5 ORC_NOT_ENOUGH_DEPTH value
         * @property {number} ORC_INVALID_PRICE=6 ORC_INVALID_PRICE value
         * @property {number} ORC_INVALID_STOP_PRICE=7 ORC_INVALID_STOP_PRICE value
         * @property {number} ORC_INVALID_VOLUME=8 ORC_INVALID_VOLUME value
         * @property {number} ORC_REJECT=9 ORC_REJECT value
         * @property {number} ORC_NO_QUOTES=10 ORC_NO_QUOTES value
         * @property {number} ORC_INVALID_SYMBOL=11 ORC_INVALID_SYMBOL value
         * @property {number} ORC_CURRENCY_CONVERSION_ERROR=12 ORC_CURRENCY_CONVERSION_ERROR value
         * @property {number} ORC_INVALID_EXPIRATION_DATE=13 ORC_INVALID_EXPIRATION_DATE value
         * @property {number} ORC_INVALID_REQUEST=14 ORC_INVALID_REQUEST value
         * @property {number} ORC_CANCEL=15 ORC_CANCEL value
         * @property {number} ORC_SYMBOL_NOT_SUPPORT_TRADING_TYPE=16 ORC_SYMBOL_NOT_SUPPORT_TRADING_TYPE value
         */
        trade_api.OrderReasonCode = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ORC_NONE"] = 0;
            values[valuesById[1] = "ORC_SERVER_ERROR"] = 1;
            values[valuesById[2] = "ORC_NON_TRADING_TIME"] = 2;
            values[valuesById[3] = "ORC_ORDER_EXPIRED"] = 3;
            values[valuesById[4] = "ORC_NOT_ENOUGH_MARGIN"] = 4;
            values[valuesById[5] = "ORC_NOT_ENOUGH_DEPTH"] = 5;
            values[valuesById[6] = "ORC_INVALID_PRICE"] = 6;
            values[valuesById[7] = "ORC_INVALID_STOP_PRICE"] = 7;
            values[valuesById[8] = "ORC_INVALID_VOLUME"] = 8;
            values[valuesById[9] = "ORC_REJECT"] = 9;
            values[valuesById[10] = "ORC_NO_QUOTES"] = 10;
            values[valuesById[11] = "ORC_INVALID_SYMBOL"] = 11;
            values[valuesById[12] = "ORC_CURRENCY_CONVERSION_ERROR"] = 12;
            values[valuesById[13] = "ORC_INVALID_EXPIRATION_DATE"] = 13;
            values[valuesById[14] = "ORC_INVALID_REQUEST"] = 14;
            values[valuesById[15] = "ORC_CANCEL"] = 15;
            values[valuesById[16] = "ORC_SYMBOL_NOT_SUPPORT_TRADING_TYPE"] = 16;
            return values;
        })();

        trade_api.OrderRequest = (function() {

            /**
             * Properties of an OrderRequest.
             * @memberof feedbox.trade_api
             * @interface IOrderRequest
             * @property {string|null} [clientId] OrderRequest clientId
             * @property {string|null} [symbol] OrderRequest symbol
             * @property {feedbox.trade_api.Side|null} [side] OrderRequest side
             * @property {number|Long|null} [volume] OrderRequest volume
             * @property {number|Long|null} [price] OrderRequest price
             * @property {number|Long|null} [deviation] OrderRequest deviation
             * @property {number|Long|null} [stopPrice] OrderRequest stopPrice
             * @property {feedbox.trade_api.OrderType|null} [type] OrderRequest type
             * @property {feedbox.trade_api.OrderTimeInForce|null} [timeInForce] OrderRequest timeInForce
             * @property {number|Long|null} [expirationDatetime] OrderRequest expirationDatetime
             * @property {Array.<string>|null} [lpName] OrderRequest lpName
             * @property {number|Long|null} [positionId] OrderRequest positionId
             * @property {number|Long|null} [slPrice] OrderRequest slPrice
             * @property {number|Long|null} [tpPrice] OrderRequest tpPrice
             * @property {number|Long|null} [insuranceConditionId] OrderRequest insuranceConditionId
             */

            /**
             * Constructs a new OrderRequest.
             * @memberof feedbox.trade_api
             * @classdesc Represents an OrderRequest.
             * @implements IOrderRequest
             * @constructor
             * @param {feedbox.trade_api.IOrderRequest=} [properties] Properties to set
             */
            function OrderRequest(properties) {
                this.lpName = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderRequest clientId.
             * @member {string} clientId
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.clientId = "";

            /**
             * OrderRequest symbol.
             * @member {string} symbol
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.symbol = "";

            /**
             * OrderRequest side.
             * @member {feedbox.trade_api.Side} side
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.side = 0;

            /**
             * OrderRequest volume.
             * @member {number|Long} volume
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderRequest price.
             * @member {number|Long} price
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderRequest deviation.
             * @member {number|Long} deviation
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.deviation = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderRequest stopPrice.
             * @member {number|Long} stopPrice
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.stopPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderRequest type.
             * @member {feedbox.trade_api.OrderType} type
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.type = 0;

            /**
             * OrderRequest timeInForce.
             * @member {feedbox.trade_api.OrderTimeInForce} timeInForce
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.timeInForce = 0;

            /**
             * OrderRequest expirationDatetime.
             * @member {number|Long} expirationDatetime
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.expirationDatetime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderRequest lpName.
             * @member {Array.<string>} lpName
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.lpName = $util.emptyArray;

            /**
             * OrderRequest positionId.
             * @member {number|Long} positionId
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.positionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderRequest slPrice.
             * @member {number|Long} slPrice
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.slPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderRequest tpPrice.
             * @member {number|Long} tpPrice
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.tpPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderRequest insuranceConditionId.
             * @member {number|Long} insuranceConditionId
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             */
            OrderRequest.prototype.insuranceConditionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new OrderRequest instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.OrderRequest
             * @static
             * @param {feedbox.trade_api.IOrderRequest=} [properties] Properties to set
             * @returns {feedbox.trade_api.OrderRequest} OrderRequest instance
             */
            OrderRequest.create = function create(properties) {
                return new OrderRequest(properties);
            };

            /**
             * Encodes the specified OrderRequest message. Does not implicitly {@link feedbox.trade_api.OrderRequest.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.OrderRequest
             * @static
             * @param {feedbox.trade_api.IOrderRequest} message OrderRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientId);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.symbol);
                if (message.side != null && Object.hasOwnProperty.call(message, "side"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.side);
                if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.volume);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.price);
                if (message.deviation != null && Object.hasOwnProperty.call(message, "deviation"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.deviation);
                if (message.stopPrice != null && Object.hasOwnProperty.call(message, "stopPrice"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.stopPrice);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.type);
                if (message.timeInForce != null && Object.hasOwnProperty.call(message, "timeInForce"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.timeInForce);
                if (message.lpName != null && message.lpName.length)
                    for (var i = 0; i < message.lpName.length; ++i)
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.lpName[i]);
                if (message.positionId != null && Object.hasOwnProperty.call(message, "positionId"))
                    writer.uint32(/* id 12, wireType 0 =*/96).uint64(message.positionId);
                if (message.expirationDatetime != null && Object.hasOwnProperty.call(message, "expirationDatetime"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int64(message.expirationDatetime);
                if (message.slPrice != null && Object.hasOwnProperty.call(message, "slPrice"))
                    writer.uint32(/* id 14, wireType 0 =*/112).uint64(message.slPrice);
                if (message.tpPrice != null && Object.hasOwnProperty.call(message, "tpPrice"))
                    writer.uint32(/* id 15, wireType 0 =*/120).uint64(message.tpPrice);
                if (message.insuranceConditionId != null && Object.hasOwnProperty.call(message, "insuranceConditionId"))
                    writer.uint32(/* id 16, wireType 0 =*/128).uint64(message.insuranceConditionId);
                return writer;
            };

            /**
             * Decodes an OrderRequest message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.OrderRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.OrderRequest} OrderRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.OrderRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.clientId = reader.string();
                        break;
                    case 2:
                        message.symbol = reader.string();
                        break;
                    case 3:
                        message.side = reader.int32();
                        break;
                    case 4:
                        message.volume = reader.uint64();
                        break;
                    case 5:
                        message.price = reader.uint64();
                        break;
                    case 6:
                        message.deviation = reader.uint64();
                        break;
                    case 7:
                        message.stopPrice = reader.uint64();
                        break;
                    case 8:
                        message.type = reader.int32();
                        break;
                    case 9:
                        message.timeInForce = reader.int32();
                        break;
                    case 13:
                        message.expirationDatetime = reader.int64();
                        break;
                    case 11:
                        if (!(message.lpName && message.lpName.length))
                            message.lpName = [];
                        message.lpName.push(reader.string());
                        break;
                    case 12:
                        message.positionId = reader.uint64();
                        break;
                    case 14:
                        message.slPrice = reader.uint64();
                        break;
                    case 15:
                        message.tpPrice = reader.uint64();
                        break;
                    case 16:
                        message.insuranceConditionId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderRequest message.
             * @function verify
             * @memberof feedbox.trade_api.OrderRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    if (!$util.isString(message.clientId))
                        return "clientId: string expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.side != null && message.hasOwnProperty("side"))
                    switch (message.side) {
                    default:
                        return "side: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (!$util.isInteger(message.volume) && !(message.volume && $util.isInteger(message.volume.low) && $util.isInteger(message.volume.high)))
                        return "volume: integer|Long expected";
                if (message.price != null && message.hasOwnProperty("price"))
                    if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                        return "price: integer|Long expected";
                if (message.deviation != null && message.hasOwnProperty("deviation"))
                    if (!$util.isInteger(message.deviation) && !(message.deviation && $util.isInteger(message.deviation.low) && $util.isInteger(message.deviation.high)))
                        return "deviation: integer|Long expected";
                if (message.stopPrice != null && message.hasOwnProperty("stopPrice"))
                    if (!$util.isInteger(message.stopPrice) && !(message.stopPrice && $util.isInteger(message.stopPrice.low) && $util.isInteger(message.stopPrice.high)))
                        return "stopPrice: integer|Long expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.timeInForce != null && message.hasOwnProperty("timeInForce"))
                    switch (message.timeInForce) {
                    default:
                        return "timeInForce: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.expirationDatetime != null && message.hasOwnProperty("expirationDatetime"))
                    if (!$util.isInteger(message.expirationDatetime) && !(message.expirationDatetime && $util.isInteger(message.expirationDatetime.low) && $util.isInteger(message.expirationDatetime.high)))
                        return "expirationDatetime: integer|Long expected";
                if (message.lpName != null && message.hasOwnProperty("lpName")) {
                    if (!Array.isArray(message.lpName))
                        return "lpName: array expected";
                    for (var i = 0; i < message.lpName.length; ++i)
                        if (!$util.isString(message.lpName[i]))
                            return "lpName: string[] expected";
                }
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    if (!$util.isInteger(message.positionId) && !(message.positionId && $util.isInteger(message.positionId.low) && $util.isInteger(message.positionId.high)))
                        return "positionId: integer|Long expected";
                if (message.slPrice != null && message.hasOwnProperty("slPrice"))
                    if (!$util.isInteger(message.slPrice) && !(message.slPrice && $util.isInteger(message.slPrice.low) && $util.isInteger(message.slPrice.high)))
                        return "slPrice: integer|Long expected";
                if (message.tpPrice != null && message.hasOwnProperty("tpPrice"))
                    if (!$util.isInteger(message.tpPrice) && !(message.tpPrice && $util.isInteger(message.tpPrice.low) && $util.isInteger(message.tpPrice.high)))
                        return "tpPrice: integer|Long expected";
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (!$util.isInteger(message.insuranceConditionId) && !(message.insuranceConditionId && $util.isInteger(message.insuranceConditionId.low) && $util.isInteger(message.insuranceConditionId.high)))
                        return "insuranceConditionId: integer|Long expected";
                return null;
            };

            /**
             * Creates an OrderRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.OrderRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.OrderRequest} OrderRequest
             */
            OrderRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.OrderRequest)
                    return object;
                var message = new $root.feedbox.trade_api.OrderRequest();
                if (object.clientId != null)
                    message.clientId = String(object.clientId);
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                switch (object.side) {
                case "BUY":
                case 0:
                    message.side = 0;
                    break;
                case "SELL":
                case 1:
                    message.side = 1;
                    break;
                }
                if (object.volume != null)
                    if ($util.Long)
                        (message.volume = $util.Long.fromValue(object.volume)).unsigned = true;
                    else if (typeof object.volume === "string")
                        message.volume = parseInt(object.volume, 10);
                    else if (typeof object.volume === "number")
                        message.volume = object.volume;
                    else if (typeof object.volume === "object")
                        message.volume = new $util.LongBits(object.volume.low >>> 0, object.volume.high >>> 0).toNumber(true);
                if (object.price != null)
                    if ($util.Long)
                        (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                    else if (typeof object.price === "string")
                        message.price = parseInt(object.price, 10);
                    else if (typeof object.price === "number")
                        message.price = object.price;
                    else if (typeof object.price === "object")
                        message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                if (object.deviation != null)
                    if ($util.Long)
                        (message.deviation = $util.Long.fromValue(object.deviation)).unsigned = true;
                    else if (typeof object.deviation === "string")
                        message.deviation = parseInt(object.deviation, 10);
                    else if (typeof object.deviation === "number")
                        message.deviation = object.deviation;
                    else if (typeof object.deviation === "object")
                        message.deviation = new $util.LongBits(object.deviation.low >>> 0, object.deviation.high >>> 0).toNumber(true);
                if (object.stopPrice != null)
                    if ($util.Long)
                        (message.stopPrice = $util.Long.fromValue(object.stopPrice)).unsigned = true;
                    else if (typeof object.stopPrice === "string")
                        message.stopPrice = parseInt(object.stopPrice, 10);
                    else if (typeof object.stopPrice === "number")
                        message.stopPrice = object.stopPrice;
                    else if (typeof object.stopPrice === "object")
                        message.stopPrice = new $util.LongBits(object.stopPrice.low >>> 0, object.stopPrice.high >>> 0).toNumber(true);
                switch (object.type) {
                case "O_MARKET":
                case 0:
                    message.type = 0;
                    break;
                case "O_LIMIT":
                case 1:
                    message.type = 1;
                    break;
                case "O_STOP":
                case 2:
                    message.type = 2;
                    break;
                case "O_STOP_LIMIT":
                case 3:
                    message.type = 3;
                    break;
                }
                switch (object.timeInForce) {
                case "FOK":
                case 0:
                    message.timeInForce = 0;
                    break;
                case "DAY":
                case 1:
                    message.timeInForce = 1;
                    break;
                case "GTD":
                case 2:
                    message.timeInForce = 2;
                    break;
                case "GTC":
                case 3:
                    message.timeInForce = 3;
                    break;
                case "IOC":
                case 4:
                    message.timeInForce = 4;
                    break;
                }
                if (object.expirationDatetime != null)
                    if ($util.Long)
                        (message.expirationDatetime = $util.Long.fromValue(object.expirationDatetime)).unsigned = false;
                    else if (typeof object.expirationDatetime === "string")
                        message.expirationDatetime = parseInt(object.expirationDatetime, 10);
                    else if (typeof object.expirationDatetime === "number")
                        message.expirationDatetime = object.expirationDatetime;
                    else if (typeof object.expirationDatetime === "object")
                        message.expirationDatetime = new $util.LongBits(object.expirationDatetime.low >>> 0, object.expirationDatetime.high >>> 0).toNumber();
                if (object.lpName) {
                    if (!Array.isArray(object.lpName))
                        throw TypeError(".feedbox.trade_api.OrderRequest.lpName: array expected");
                    message.lpName = [];
                    for (var i = 0; i < object.lpName.length; ++i)
                        message.lpName[i] = String(object.lpName[i]);
                }
                if (object.positionId != null)
                    if ($util.Long)
                        (message.positionId = $util.Long.fromValue(object.positionId)).unsigned = true;
                    else if (typeof object.positionId === "string")
                        message.positionId = parseInt(object.positionId, 10);
                    else if (typeof object.positionId === "number")
                        message.positionId = object.positionId;
                    else if (typeof object.positionId === "object")
                        message.positionId = new $util.LongBits(object.positionId.low >>> 0, object.positionId.high >>> 0).toNumber(true);
                if (object.slPrice != null)
                    if ($util.Long)
                        (message.slPrice = $util.Long.fromValue(object.slPrice)).unsigned = true;
                    else if (typeof object.slPrice === "string")
                        message.slPrice = parseInt(object.slPrice, 10);
                    else if (typeof object.slPrice === "number")
                        message.slPrice = object.slPrice;
                    else if (typeof object.slPrice === "object")
                        message.slPrice = new $util.LongBits(object.slPrice.low >>> 0, object.slPrice.high >>> 0).toNumber(true);
                if (object.tpPrice != null)
                    if ($util.Long)
                        (message.tpPrice = $util.Long.fromValue(object.tpPrice)).unsigned = true;
                    else if (typeof object.tpPrice === "string")
                        message.tpPrice = parseInt(object.tpPrice, 10);
                    else if (typeof object.tpPrice === "number")
                        message.tpPrice = object.tpPrice;
                    else if (typeof object.tpPrice === "object")
                        message.tpPrice = new $util.LongBits(object.tpPrice.low >>> 0, object.tpPrice.high >>> 0).toNumber(true);
                if (object.insuranceConditionId != null)
                    if ($util.Long)
                        (message.insuranceConditionId = $util.Long.fromValue(object.insuranceConditionId)).unsigned = true;
                    else if (typeof object.insuranceConditionId === "string")
                        message.insuranceConditionId = parseInt(object.insuranceConditionId, 10);
                    else if (typeof object.insuranceConditionId === "number")
                        message.insuranceConditionId = object.insuranceConditionId;
                    else if (typeof object.insuranceConditionId === "object")
                        message.insuranceConditionId = new $util.LongBits(object.insuranceConditionId.low >>> 0, object.insuranceConditionId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an OrderRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.OrderRequest
             * @static
             * @param {feedbox.trade_api.OrderRequest} message OrderRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.lpName = [];
                if (options.defaults) {
                    object.clientId = "";
                    object.symbol = "";
                    object.side = options.enums === String ? "BUY" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.volume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.volume = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.price = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.deviation = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.deviation = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.stopPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.stopPrice = options.longs === String ? "0" : 0;
                    object.type = options.enums === String ? "O_MARKET" : 0;
                    object.timeInForce = options.enums === String ? "FOK" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.positionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positionId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.expirationDatetime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.expirationDatetime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.slPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.slPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.tpPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tpPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.insuranceConditionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insuranceConditionId = options.longs === String ? "0" : 0;
                }
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    object.clientId = message.clientId;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.side != null && message.hasOwnProperty("side"))
                    object.side = options.enums === String ? $root.feedbox.trade_api.Side[message.side] : message.side;
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (typeof message.volume === "number")
                        object.volume = options.longs === String ? String(message.volume) : message.volume;
                    else
                        object.volume = options.longs === String ? $util.Long.prototype.toString.call(message.volume) : options.longs === Number ? new $util.LongBits(message.volume.low >>> 0, message.volume.high >>> 0).toNumber(true) : message.volume;
                if (message.price != null && message.hasOwnProperty("price"))
                    if (typeof message.price === "number")
                        object.price = options.longs === String ? String(message.price) : message.price;
                    else
                        object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                if (message.deviation != null && message.hasOwnProperty("deviation"))
                    if (typeof message.deviation === "number")
                        object.deviation = options.longs === String ? String(message.deviation) : message.deviation;
                    else
                        object.deviation = options.longs === String ? $util.Long.prototype.toString.call(message.deviation) : options.longs === Number ? new $util.LongBits(message.deviation.low >>> 0, message.deviation.high >>> 0).toNumber(true) : message.deviation;
                if (message.stopPrice != null && message.hasOwnProperty("stopPrice"))
                    if (typeof message.stopPrice === "number")
                        object.stopPrice = options.longs === String ? String(message.stopPrice) : message.stopPrice;
                    else
                        object.stopPrice = options.longs === String ? $util.Long.prototype.toString.call(message.stopPrice) : options.longs === Number ? new $util.LongBits(message.stopPrice.low >>> 0, message.stopPrice.high >>> 0).toNumber(true) : message.stopPrice;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.feedbox.trade_api.OrderType[message.type] : message.type;
                if (message.timeInForce != null && message.hasOwnProperty("timeInForce"))
                    object.timeInForce = options.enums === String ? $root.feedbox.trade_api.OrderTimeInForce[message.timeInForce] : message.timeInForce;
                if (message.lpName && message.lpName.length) {
                    object.lpName = [];
                    for (var j = 0; j < message.lpName.length; ++j)
                        object.lpName[j] = message.lpName[j];
                }
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    if (typeof message.positionId === "number")
                        object.positionId = options.longs === String ? String(message.positionId) : message.positionId;
                    else
                        object.positionId = options.longs === String ? $util.Long.prototype.toString.call(message.positionId) : options.longs === Number ? new $util.LongBits(message.positionId.low >>> 0, message.positionId.high >>> 0).toNumber(true) : message.positionId;
                if (message.expirationDatetime != null && message.hasOwnProperty("expirationDatetime"))
                    if (typeof message.expirationDatetime === "number")
                        object.expirationDatetime = options.longs === String ? String(message.expirationDatetime) : message.expirationDatetime;
                    else
                        object.expirationDatetime = options.longs === String ? $util.Long.prototype.toString.call(message.expirationDatetime) : options.longs === Number ? new $util.LongBits(message.expirationDatetime.low >>> 0, message.expirationDatetime.high >>> 0).toNumber() : message.expirationDatetime;
                if (message.slPrice != null && message.hasOwnProperty("slPrice"))
                    if (typeof message.slPrice === "number")
                        object.slPrice = options.longs === String ? String(message.slPrice) : message.slPrice;
                    else
                        object.slPrice = options.longs === String ? $util.Long.prototype.toString.call(message.slPrice) : options.longs === Number ? new $util.LongBits(message.slPrice.low >>> 0, message.slPrice.high >>> 0).toNumber(true) : message.slPrice;
                if (message.tpPrice != null && message.hasOwnProperty("tpPrice"))
                    if (typeof message.tpPrice === "number")
                        object.tpPrice = options.longs === String ? String(message.tpPrice) : message.tpPrice;
                    else
                        object.tpPrice = options.longs === String ? $util.Long.prototype.toString.call(message.tpPrice) : options.longs === Number ? new $util.LongBits(message.tpPrice.low >>> 0, message.tpPrice.high >>> 0).toNumber(true) : message.tpPrice;
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (typeof message.insuranceConditionId === "number")
                        object.insuranceConditionId = options.longs === String ? String(message.insuranceConditionId) : message.insuranceConditionId;
                    else
                        object.insuranceConditionId = options.longs === String ? $util.Long.prototype.toString.call(message.insuranceConditionId) : options.longs === Number ? new $util.LongBits(message.insuranceConditionId.low >>> 0, message.insuranceConditionId.high >>> 0).toNumber(true) : message.insuranceConditionId;
                return object;
            };

            /**
             * Converts this OrderRequest to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.OrderRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderRequest;
        })();

        trade_api.OrderRequestResult = (function() {

            /**
             * Properties of an OrderRequestResult.
             * @memberof feedbox.trade_api
             * @interface IOrderRequestResult
             * @property {number|Long|null} [orderId] OrderRequestResult orderId
             */

            /**
             * Constructs a new OrderRequestResult.
             * @memberof feedbox.trade_api
             * @classdesc Represents an OrderRequestResult.
             * @implements IOrderRequestResult
             * @constructor
             * @param {feedbox.trade_api.IOrderRequestResult=} [properties] Properties to set
             */
            function OrderRequestResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderRequestResult orderId.
             * @member {number|Long} orderId
             * @memberof feedbox.trade_api.OrderRequestResult
             * @instance
             */
            OrderRequestResult.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new OrderRequestResult instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.OrderRequestResult
             * @static
             * @param {feedbox.trade_api.IOrderRequestResult=} [properties] Properties to set
             * @returns {feedbox.trade_api.OrderRequestResult} OrderRequestResult instance
             */
            OrderRequestResult.create = function create(properties) {
                return new OrderRequestResult(properties);
            };

            /**
             * Encodes the specified OrderRequestResult message. Does not implicitly {@link feedbox.trade_api.OrderRequestResult.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.OrderRequestResult
             * @static
             * @param {feedbox.trade_api.IOrderRequestResult} message OrderRequestResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderRequestResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.orderId);
                return writer;
            };

            /**
             * Decodes an OrderRequestResult message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.OrderRequestResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.OrderRequestResult} OrderRequestResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderRequestResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.OrderRequestResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderRequestResult message.
             * @function verify
             * @memberof feedbox.trade_api.OrderRequestResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderRequestResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                return null;
            };

            /**
             * Creates an OrderRequestResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.OrderRequestResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.OrderRequestResult} OrderRequestResult
             */
            OrderRequestResult.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.OrderRequestResult)
                    return object;
                var message = new $root.feedbox.trade_api.OrderRequestResult();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an OrderRequestResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.OrderRequestResult
             * @static
             * @param {feedbox.trade_api.OrderRequestResult} message OrderRequestResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderRequestResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                return object;
            };

            /**
             * Converts this OrderRequestResult to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.OrderRequestResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderRequestResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderRequestResult;
        })();

        trade_api.OrderExecution = (function() {

            /**
             * Properties of an OrderExecution.
             * @memberof feedbox.trade_api
             * @interface IOrderExecution
             * @property {string|null} [clientId] OrderExecution clientId
             * @property {number|Long|null} [orderId] OrderExecution orderId
             * @property {number|Long|null} [executionId] OrderExecution executionId
             * @property {feedbox.trade_api.OrderExecution.ExecutionType|null} [executionType] OrderExecution executionType
             * @property {string|null} [symbol] OrderExecution symbol
             * @property {feedbox.trade_api.Side|null} [side] OrderExecution side
             * @property {number|Long|null} [price] OrderExecution price
             * @property {number|Long|null} [volume] OrderExecution volume
             * @property {number|Long|null} [filledVolume] OrderExecution filledVolume
             * @property {number|Long|null} [averagePrice] OrderExecution averagePrice
             * @property {feedbox.trade_api.OrderStatus|null} [orderStatus] OrderExecution orderStatus
             * @property {number|Long|null} [profitLoss] OrderExecution profitLoss
             * @property {number|Long|null} [commission] OrderExecution commission
             * @property {number|Long|null} [swap] OrderExecution swap
             * @property {number|Long|null} [insuranceFee] OrderExecution insuranceFee
             * @property {number|Long|null} [insurancePayout] OrderExecution insurancePayout
             * @property {feedbox.trade_api.OrderReasonCode|null} [reasonCode] OrderExecution reasonCode
             * @property {number|Long|null} [orderProfitLoss] OrderExecution orderProfitLoss
             * @property {number|Long|null} [orderCommission] OrderExecution orderCommission
             * @property {number|Long|null} [orderSwap] OrderExecution orderSwap
             * @property {number|Long|null} [orderInsuranceFee] OrderExecution orderInsuranceFee
             * @property {number|Long|null} [orderInsurancePayout] OrderExecution orderInsurancePayout
             * @property {number|Long|null} [time] OrderExecution time
             */

            /**
             * Constructs a new OrderExecution.
             * @memberof feedbox.trade_api
             * @classdesc Represents an OrderExecution.
             * @implements IOrderExecution
             * @constructor
             * @param {feedbox.trade_api.IOrderExecution=} [properties] Properties to set
             */
            function OrderExecution(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderExecution clientId.
             * @member {string} clientId
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.clientId = "";

            /**
             * OrderExecution orderId.
             * @member {number|Long} orderId
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderExecution executionId.
             * @member {number|Long} executionId
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.executionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderExecution executionType.
             * @member {feedbox.trade_api.OrderExecution.ExecutionType} executionType
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.executionType = 0;

            /**
             * OrderExecution symbol.
             * @member {string} symbol
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.symbol = "";

            /**
             * OrderExecution side.
             * @member {feedbox.trade_api.Side} side
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.side = 0;

            /**
             * OrderExecution price.
             * @member {number|Long} price
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderExecution volume.
             * @member {number|Long} volume
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderExecution filledVolume.
             * @member {number|Long} filledVolume
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.filledVolume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderExecution averagePrice.
             * @member {number|Long} averagePrice
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.averagePrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderExecution orderStatus.
             * @member {feedbox.trade_api.OrderStatus} orderStatus
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.orderStatus = 0;

            /**
             * OrderExecution profitLoss.
             * @member {number|Long} profitLoss
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.profitLoss = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderExecution commission.
             * @member {number|Long} commission
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.commission = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderExecution swap.
             * @member {number|Long} swap
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.swap = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderExecution insuranceFee.
             * @member {number|Long} insuranceFee
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.insuranceFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderExecution insurancePayout.
             * @member {number|Long} insurancePayout
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.insurancePayout = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderExecution reasonCode.
             * @member {feedbox.trade_api.OrderReasonCode} reasonCode
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.reasonCode = 0;

            /**
             * OrderExecution orderProfitLoss.
             * @member {number|Long} orderProfitLoss
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.orderProfitLoss = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderExecution orderCommission.
             * @member {number|Long} orderCommission
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.orderCommission = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderExecution orderSwap.
             * @member {number|Long} orderSwap
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.orderSwap = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderExecution orderInsuranceFee.
             * @member {number|Long} orderInsuranceFee
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.orderInsuranceFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderExecution orderInsurancePayout.
             * @member {number|Long} orderInsurancePayout
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.orderInsurancePayout = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * OrderExecution time.
             * @member {number|Long} time
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             */
            OrderExecution.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new OrderExecution instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.OrderExecution
             * @static
             * @param {feedbox.trade_api.IOrderExecution=} [properties] Properties to set
             * @returns {feedbox.trade_api.OrderExecution} OrderExecution instance
             */
            OrderExecution.create = function create(properties) {
                return new OrderExecution(properties);
            };

            /**
             * Encodes the specified OrderExecution message. Does not implicitly {@link feedbox.trade_api.OrderExecution.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.OrderExecution
             * @static
             * @param {feedbox.trade_api.IOrderExecution} message OrderExecution message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderExecution.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.orderId);
                if (message.executionId != null && Object.hasOwnProperty.call(message, "executionId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.executionId);
                if (message.executionType != null && Object.hasOwnProperty.call(message, "executionType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.executionType);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.symbol);
                if (message.side != null && Object.hasOwnProperty.call(message, "side"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.side);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.price);
                if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.volume);
                if (message.filledVolume != null && Object.hasOwnProperty.call(message, "filledVolume"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.filledVolume);
                if (message.averagePrice != null && Object.hasOwnProperty.call(message, "averagePrice"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.averagePrice);
                if (message.orderStatus != null && Object.hasOwnProperty.call(message, "orderStatus"))
                    writer.uint32(/* id 14, wireType 0 =*/112).int32(message.orderStatus);
                if (message.profitLoss != null && Object.hasOwnProperty.call(message, "profitLoss"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int64(message.profitLoss);
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 16, wireType 2 =*/130).string(message.clientId);
                if (message.reasonCode != null && Object.hasOwnProperty.call(message, "reasonCode"))
                    writer.uint32(/* id 17, wireType 0 =*/136).int32(message.reasonCode);
                if (message.commission != null && Object.hasOwnProperty.call(message, "commission"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int64(message.commission);
                if (message.swap != null && Object.hasOwnProperty.call(message, "swap"))
                    writer.uint32(/* id 19, wireType 0 =*/152).int64(message.swap);
                if (message.orderProfitLoss != null && Object.hasOwnProperty.call(message, "orderProfitLoss"))
                    writer.uint32(/* id 20, wireType 0 =*/160).int64(message.orderProfitLoss);
                if (message.orderCommission != null && Object.hasOwnProperty.call(message, "orderCommission"))
                    writer.uint32(/* id 21, wireType 0 =*/168).int64(message.orderCommission);
                if (message.orderSwap != null && Object.hasOwnProperty.call(message, "orderSwap"))
                    writer.uint32(/* id 22, wireType 0 =*/176).int64(message.orderSwap);
                if (message.insuranceFee != null && Object.hasOwnProperty.call(message, "insuranceFee"))
                    writer.uint32(/* id 23, wireType 0 =*/184).int64(message.insuranceFee);
                if (message.insurancePayout != null && Object.hasOwnProperty.call(message, "insurancePayout"))
                    writer.uint32(/* id 24, wireType 0 =*/192).int64(message.insurancePayout);
                if (message.orderInsuranceFee != null && Object.hasOwnProperty.call(message, "orderInsuranceFee"))
                    writer.uint32(/* id 25, wireType 0 =*/200).int64(message.orderInsuranceFee);
                if (message.orderInsurancePayout != null && Object.hasOwnProperty.call(message, "orderInsurancePayout"))
                    writer.uint32(/* id 26, wireType 0 =*/208).int64(message.orderInsurancePayout);
                if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                    writer.uint32(/* id 27, wireType 0 =*/216).int64(message.time);
                return writer;
            };

            /**
             * Decodes an OrderExecution message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.OrderExecution
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.OrderExecution} OrderExecution
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderExecution.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.OrderExecution();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 16:
                        message.clientId = reader.string();
                        break;
                    case 1:
                        message.orderId = reader.uint64();
                        break;
                    case 2:
                        message.executionId = reader.uint64();
                        break;
                    case 3:
                        message.executionType = reader.int32();
                        break;
                    case 5:
                        message.symbol = reader.string();
                        break;
                    case 6:
                        message.side = reader.int32();
                        break;
                    case 7:
                        message.price = reader.uint64();
                        break;
                    case 8:
                        message.volume = reader.uint64();
                        break;
                    case 9:
                        message.filledVolume = reader.uint64();
                        break;
                    case 10:
                        message.averagePrice = reader.uint64();
                        break;
                    case 14:
                        message.orderStatus = reader.int32();
                        break;
                    case 15:
                        message.profitLoss = reader.int64();
                        break;
                    case 18:
                        message.commission = reader.int64();
                        break;
                    case 19:
                        message.swap = reader.int64();
                        break;
                    case 23:
                        message.insuranceFee = reader.int64();
                        break;
                    case 24:
                        message.insurancePayout = reader.int64();
                        break;
                    case 17:
                        message.reasonCode = reader.int32();
                        break;
                    case 20:
                        message.orderProfitLoss = reader.int64();
                        break;
                    case 21:
                        message.orderCommission = reader.int64();
                        break;
                    case 22:
                        message.orderSwap = reader.int64();
                        break;
                    case 25:
                        message.orderInsuranceFee = reader.int64();
                        break;
                    case 26:
                        message.orderInsurancePayout = reader.int64();
                        break;
                    case 27:
                        message.time = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderExecution message.
             * @function verify
             * @memberof feedbox.trade_api.OrderExecution
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderExecution.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    if (!$util.isString(message.clientId))
                        return "clientId: string expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.executionId != null && message.hasOwnProperty("executionId"))
                    if (!$util.isInteger(message.executionId) && !(message.executionId && $util.isInteger(message.executionId.low) && $util.isInteger(message.executionId.high)))
                        return "executionId: integer|Long expected";
                if (message.executionType != null && message.hasOwnProperty("executionType"))
                    switch (message.executionType) {
                    default:
                        return "executionType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.side != null && message.hasOwnProperty("side"))
                    switch (message.side) {
                    default:
                        return "side: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.price != null && message.hasOwnProperty("price"))
                    if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                        return "price: integer|Long expected";
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (!$util.isInteger(message.volume) && !(message.volume && $util.isInteger(message.volume.low) && $util.isInteger(message.volume.high)))
                        return "volume: integer|Long expected";
                if (message.filledVolume != null && message.hasOwnProperty("filledVolume"))
                    if (!$util.isInteger(message.filledVolume) && !(message.filledVolume && $util.isInteger(message.filledVolume.low) && $util.isInteger(message.filledVolume.high)))
                        return "filledVolume: integer|Long expected";
                if (message.averagePrice != null && message.hasOwnProperty("averagePrice"))
                    if (!$util.isInteger(message.averagePrice) && !(message.averagePrice && $util.isInteger(message.averagePrice.low) && $util.isInteger(message.averagePrice.high)))
                        return "averagePrice: integer|Long expected";
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus"))
                    switch (message.orderStatus) {
                    default:
                        return "orderStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.profitLoss != null && message.hasOwnProperty("profitLoss"))
                    if (!$util.isInteger(message.profitLoss) && !(message.profitLoss && $util.isInteger(message.profitLoss.low) && $util.isInteger(message.profitLoss.high)))
                        return "profitLoss: integer|Long expected";
                if (message.commission != null && message.hasOwnProperty("commission"))
                    if (!$util.isInteger(message.commission) && !(message.commission && $util.isInteger(message.commission.low) && $util.isInteger(message.commission.high)))
                        return "commission: integer|Long expected";
                if (message.swap != null && message.hasOwnProperty("swap"))
                    if (!$util.isInteger(message.swap) && !(message.swap && $util.isInteger(message.swap.low) && $util.isInteger(message.swap.high)))
                        return "swap: integer|Long expected";
                if (message.insuranceFee != null && message.hasOwnProperty("insuranceFee"))
                    if (!$util.isInteger(message.insuranceFee) && !(message.insuranceFee && $util.isInteger(message.insuranceFee.low) && $util.isInteger(message.insuranceFee.high)))
                        return "insuranceFee: integer|Long expected";
                if (message.insurancePayout != null && message.hasOwnProperty("insurancePayout"))
                    if (!$util.isInteger(message.insurancePayout) && !(message.insurancePayout && $util.isInteger(message.insurancePayout.low) && $util.isInteger(message.insurancePayout.high)))
                        return "insurancePayout: integer|Long expected";
                if (message.reasonCode != null && message.hasOwnProperty("reasonCode"))
                    switch (message.reasonCode) {
                    default:
                        return "reasonCode: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
                if (message.orderProfitLoss != null && message.hasOwnProperty("orderProfitLoss"))
                    if (!$util.isInteger(message.orderProfitLoss) && !(message.orderProfitLoss && $util.isInteger(message.orderProfitLoss.low) && $util.isInteger(message.orderProfitLoss.high)))
                        return "orderProfitLoss: integer|Long expected";
                if (message.orderCommission != null && message.hasOwnProperty("orderCommission"))
                    if (!$util.isInteger(message.orderCommission) && !(message.orderCommission && $util.isInteger(message.orderCommission.low) && $util.isInteger(message.orderCommission.high)))
                        return "orderCommission: integer|Long expected";
                if (message.orderSwap != null && message.hasOwnProperty("orderSwap"))
                    if (!$util.isInteger(message.orderSwap) && !(message.orderSwap && $util.isInteger(message.orderSwap.low) && $util.isInteger(message.orderSwap.high)))
                        return "orderSwap: integer|Long expected";
                if (message.orderInsuranceFee != null && message.hasOwnProperty("orderInsuranceFee"))
                    if (!$util.isInteger(message.orderInsuranceFee) && !(message.orderInsuranceFee && $util.isInteger(message.orderInsuranceFee.low) && $util.isInteger(message.orderInsuranceFee.high)))
                        return "orderInsuranceFee: integer|Long expected";
                if (message.orderInsurancePayout != null && message.hasOwnProperty("orderInsurancePayout"))
                    if (!$util.isInteger(message.orderInsurancePayout) && !(message.orderInsurancePayout && $util.isInteger(message.orderInsurancePayout.low) && $util.isInteger(message.orderInsurancePayout.high)))
                        return "orderInsurancePayout: integer|Long expected";
                if (message.time != null && message.hasOwnProperty("time"))
                    if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                        return "time: integer|Long expected";
                return null;
            };

            /**
             * Creates an OrderExecution message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.OrderExecution
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.OrderExecution} OrderExecution
             */
            OrderExecution.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.OrderExecution)
                    return object;
                var message = new $root.feedbox.trade_api.OrderExecution();
                if (object.clientId != null)
                    message.clientId = String(object.clientId);
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                if (object.executionId != null)
                    if ($util.Long)
                        (message.executionId = $util.Long.fromValue(object.executionId)).unsigned = true;
                    else if (typeof object.executionId === "string")
                        message.executionId = parseInt(object.executionId, 10);
                    else if (typeof object.executionId === "number")
                        message.executionId = object.executionId;
                    else if (typeof object.executionId === "object")
                        message.executionId = new $util.LongBits(object.executionId.low >>> 0, object.executionId.high >>> 0).toNumber(true);
                switch (object.executionType) {
                case "E_NEW":
                case 0:
                    message.executionType = 0;
                    break;
                case "E_CONFIRM":
                case 1:
                    message.executionType = 1;
                    break;
                case "E_REJECT":
                case 2:
                    message.executionType = 2;
                    break;
                }
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                switch (object.side) {
                case "BUY":
                case 0:
                    message.side = 0;
                    break;
                case "SELL":
                case 1:
                    message.side = 1;
                    break;
                }
                if (object.price != null)
                    if ($util.Long)
                        (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                    else if (typeof object.price === "string")
                        message.price = parseInt(object.price, 10);
                    else if (typeof object.price === "number")
                        message.price = object.price;
                    else if (typeof object.price === "object")
                        message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                if (object.volume != null)
                    if ($util.Long)
                        (message.volume = $util.Long.fromValue(object.volume)).unsigned = true;
                    else if (typeof object.volume === "string")
                        message.volume = parseInt(object.volume, 10);
                    else if (typeof object.volume === "number")
                        message.volume = object.volume;
                    else if (typeof object.volume === "object")
                        message.volume = new $util.LongBits(object.volume.low >>> 0, object.volume.high >>> 0).toNumber(true);
                if (object.filledVolume != null)
                    if ($util.Long)
                        (message.filledVolume = $util.Long.fromValue(object.filledVolume)).unsigned = true;
                    else if (typeof object.filledVolume === "string")
                        message.filledVolume = parseInt(object.filledVolume, 10);
                    else if (typeof object.filledVolume === "number")
                        message.filledVolume = object.filledVolume;
                    else if (typeof object.filledVolume === "object")
                        message.filledVolume = new $util.LongBits(object.filledVolume.low >>> 0, object.filledVolume.high >>> 0).toNumber(true);
                if (object.averagePrice != null)
                    if ($util.Long)
                        (message.averagePrice = $util.Long.fromValue(object.averagePrice)).unsigned = true;
                    else if (typeof object.averagePrice === "string")
                        message.averagePrice = parseInt(object.averagePrice, 10);
                    else if (typeof object.averagePrice === "number")
                        message.averagePrice = object.averagePrice;
                    else if (typeof object.averagePrice === "object")
                        message.averagePrice = new $util.LongBits(object.averagePrice.low >>> 0, object.averagePrice.high >>> 0).toNumber(true);
                switch (object.orderStatus) {
                case "O_NEW":
                case 0:
                    message.orderStatus = 0;
                    break;
                case "O_PARTIALLY_FILLED":
                case 1:
                    message.orderStatus = 1;
                    break;
                case "O_FILLED":
                case 2:
                    message.orderStatus = 2;
                    break;
                case "O_CANCELED":
                case 3:
                    message.orderStatus = 3;
                    break;
                case "O_REJECTED":
                case 4:
                    message.orderStatus = 4;
                    break;
                case "O_EXPIRED":
                case 5:
                    message.orderStatus = 5;
                    break;
                case "O_PENDING_NEW":
                case 6:
                    message.orderStatus = 6;
                    break;
                }
                if (object.profitLoss != null)
                    if ($util.Long)
                        (message.profitLoss = $util.Long.fromValue(object.profitLoss)).unsigned = false;
                    else if (typeof object.profitLoss === "string")
                        message.profitLoss = parseInt(object.profitLoss, 10);
                    else if (typeof object.profitLoss === "number")
                        message.profitLoss = object.profitLoss;
                    else if (typeof object.profitLoss === "object")
                        message.profitLoss = new $util.LongBits(object.profitLoss.low >>> 0, object.profitLoss.high >>> 0).toNumber();
                if (object.commission != null)
                    if ($util.Long)
                        (message.commission = $util.Long.fromValue(object.commission)).unsigned = false;
                    else if (typeof object.commission === "string")
                        message.commission = parseInt(object.commission, 10);
                    else if (typeof object.commission === "number")
                        message.commission = object.commission;
                    else if (typeof object.commission === "object")
                        message.commission = new $util.LongBits(object.commission.low >>> 0, object.commission.high >>> 0).toNumber();
                if (object.swap != null)
                    if ($util.Long)
                        (message.swap = $util.Long.fromValue(object.swap)).unsigned = false;
                    else if (typeof object.swap === "string")
                        message.swap = parseInt(object.swap, 10);
                    else if (typeof object.swap === "number")
                        message.swap = object.swap;
                    else if (typeof object.swap === "object")
                        message.swap = new $util.LongBits(object.swap.low >>> 0, object.swap.high >>> 0).toNumber();
                if (object.insuranceFee != null)
                    if ($util.Long)
                        (message.insuranceFee = $util.Long.fromValue(object.insuranceFee)).unsigned = false;
                    else if (typeof object.insuranceFee === "string")
                        message.insuranceFee = parseInt(object.insuranceFee, 10);
                    else if (typeof object.insuranceFee === "number")
                        message.insuranceFee = object.insuranceFee;
                    else if (typeof object.insuranceFee === "object")
                        message.insuranceFee = new $util.LongBits(object.insuranceFee.low >>> 0, object.insuranceFee.high >>> 0).toNumber();
                if (object.insurancePayout != null)
                    if ($util.Long)
                        (message.insurancePayout = $util.Long.fromValue(object.insurancePayout)).unsigned = false;
                    else if (typeof object.insurancePayout === "string")
                        message.insurancePayout = parseInt(object.insurancePayout, 10);
                    else if (typeof object.insurancePayout === "number")
                        message.insurancePayout = object.insurancePayout;
                    else if (typeof object.insurancePayout === "object")
                        message.insurancePayout = new $util.LongBits(object.insurancePayout.low >>> 0, object.insurancePayout.high >>> 0).toNumber();
                switch (object.reasonCode) {
                case "ORC_NONE":
                case 0:
                    message.reasonCode = 0;
                    break;
                case "ORC_SERVER_ERROR":
                case 1:
                    message.reasonCode = 1;
                    break;
                case "ORC_NON_TRADING_TIME":
                case 2:
                    message.reasonCode = 2;
                    break;
                case "ORC_ORDER_EXPIRED":
                case 3:
                    message.reasonCode = 3;
                    break;
                case "ORC_NOT_ENOUGH_MARGIN":
                case 4:
                    message.reasonCode = 4;
                    break;
                case "ORC_NOT_ENOUGH_DEPTH":
                case 5:
                    message.reasonCode = 5;
                    break;
                case "ORC_INVALID_PRICE":
                case 6:
                    message.reasonCode = 6;
                    break;
                case "ORC_INVALID_STOP_PRICE":
                case 7:
                    message.reasonCode = 7;
                    break;
                case "ORC_INVALID_VOLUME":
                case 8:
                    message.reasonCode = 8;
                    break;
                case "ORC_REJECT":
                case 9:
                    message.reasonCode = 9;
                    break;
                case "ORC_NO_QUOTES":
                case 10:
                    message.reasonCode = 10;
                    break;
                case "ORC_INVALID_SYMBOL":
                case 11:
                    message.reasonCode = 11;
                    break;
                case "ORC_CURRENCY_CONVERSION_ERROR":
                case 12:
                    message.reasonCode = 12;
                    break;
                case "ORC_INVALID_EXPIRATION_DATE":
                case 13:
                    message.reasonCode = 13;
                    break;
                case "ORC_INVALID_REQUEST":
                case 14:
                    message.reasonCode = 14;
                    break;
                case "ORC_CANCEL":
                case 15:
                    message.reasonCode = 15;
                    break;
                case "ORC_SYMBOL_NOT_SUPPORT_TRADING_TYPE":
                case 16:
                    message.reasonCode = 16;
                    break;
                }
                if (object.orderProfitLoss != null)
                    if ($util.Long)
                        (message.orderProfitLoss = $util.Long.fromValue(object.orderProfitLoss)).unsigned = false;
                    else if (typeof object.orderProfitLoss === "string")
                        message.orderProfitLoss = parseInt(object.orderProfitLoss, 10);
                    else if (typeof object.orderProfitLoss === "number")
                        message.orderProfitLoss = object.orderProfitLoss;
                    else if (typeof object.orderProfitLoss === "object")
                        message.orderProfitLoss = new $util.LongBits(object.orderProfitLoss.low >>> 0, object.orderProfitLoss.high >>> 0).toNumber();
                if (object.orderCommission != null)
                    if ($util.Long)
                        (message.orderCommission = $util.Long.fromValue(object.orderCommission)).unsigned = false;
                    else if (typeof object.orderCommission === "string")
                        message.orderCommission = parseInt(object.orderCommission, 10);
                    else if (typeof object.orderCommission === "number")
                        message.orderCommission = object.orderCommission;
                    else if (typeof object.orderCommission === "object")
                        message.orderCommission = new $util.LongBits(object.orderCommission.low >>> 0, object.orderCommission.high >>> 0).toNumber();
                if (object.orderSwap != null)
                    if ($util.Long)
                        (message.orderSwap = $util.Long.fromValue(object.orderSwap)).unsigned = false;
                    else if (typeof object.orderSwap === "string")
                        message.orderSwap = parseInt(object.orderSwap, 10);
                    else if (typeof object.orderSwap === "number")
                        message.orderSwap = object.orderSwap;
                    else if (typeof object.orderSwap === "object")
                        message.orderSwap = new $util.LongBits(object.orderSwap.low >>> 0, object.orderSwap.high >>> 0).toNumber();
                if (object.orderInsuranceFee != null)
                    if ($util.Long)
                        (message.orderInsuranceFee = $util.Long.fromValue(object.orderInsuranceFee)).unsigned = false;
                    else if (typeof object.orderInsuranceFee === "string")
                        message.orderInsuranceFee = parseInt(object.orderInsuranceFee, 10);
                    else if (typeof object.orderInsuranceFee === "number")
                        message.orderInsuranceFee = object.orderInsuranceFee;
                    else if (typeof object.orderInsuranceFee === "object")
                        message.orderInsuranceFee = new $util.LongBits(object.orderInsuranceFee.low >>> 0, object.orderInsuranceFee.high >>> 0).toNumber();
                if (object.orderInsurancePayout != null)
                    if ($util.Long)
                        (message.orderInsurancePayout = $util.Long.fromValue(object.orderInsurancePayout)).unsigned = false;
                    else if (typeof object.orderInsurancePayout === "string")
                        message.orderInsurancePayout = parseInt(object.orderInsurancePayout, 10);
                    else if (typeof object.orderInsurancePayout === "number")
                        message.orderInsurancePayout = object.orderInsurancePayout;
                    else if (typeof object.orderInsurancePayout === "object")
                        message.orderInsurancePayout = new $util.LongBits(object.orderInsurancePayout.low >>> 0, object.orderInsurancePayout.high >>> 0).toNumber();
                if (object.time != null)
                    if ($util.Long)
                        (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                    else if (typeof object.time === "string")
                        message.time = parseInt(object.time, 10);
                    else if (typeof object.time === "number")
                        message.time = object.time;
                    else if (typeof object.time === "object")
                        message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an OrderExecution message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.OrderExecution
             * @static
             * @param {feedbox.trade_api.OrderExecution} message OrderExecution
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderExecution.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.executionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.executionId = options.longs === String ? "0" : 0;
                    object.executionType = options.enums === String ? "E_NEW" : 0;
                    object.symbol = "";
                    object.side = options.enums === String ? "BUY" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.price = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.volume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.volume = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.filledVolume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.filledVolume = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.averagePrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.averagePrice = options.longs === String ? "0" : 0;
                    object.orderStatus = options.enums === String ? "O_NEW" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.profitLoss = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.profitLoss = options.longs === String ? "0" : 0;
                    object.clientId = "";
                    object.reasonCode = options.enums === String ? "ORC_NONE" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.commission = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.commission = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.swap = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.swap = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderProfitLoss = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderProfitLoss = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderCommission = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderCommission = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderSwap = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderSwap = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.insuranceFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insuranceFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.insurancePayout = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insurancePayout = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderInsuranceFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderInsuranceFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orderInsurancePayout = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderInsurancePayout = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.time = options.longs === String ? "0" : 0;
                }
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                if (message.executionId != null && message.hasOwnProperty("executionId"))
                    if (typeof message.executionId === "number")
                        object.executionId = options.longs === String ? String(message.executionId) : message.executionId;
                    else
                        object.executionId = options.longs === String ? $util.Long.prototype.toString.call(message.executionId) : options.longs === Number ? new $util.LongBits(message.executionId.low >>> 0, message.executionId.high >>> 0).toNumber(true) : message.executionId;
                if (message.executionType != null && message.hasOwnProperty("executionType"))
                    object.executionType = options.enums === String ? $root.feedbox.trade_api.OrderExecution.ExecutionType[message.executionType] : message.executionType;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.side != null && message.hasOwnProperty("side"))
                    object.side = options.enums === String ? $root.feedbox.trade_api.Side[message.side] : message.side;
                if (message.price != null && message.hasOwnProperty("price"))
                    if (typeof message.price === "number")
                        object.price = options.longs === String ? String(message.price) : message.price;
                    else
                        object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (typeof message.volume === "number")
                        object.volume = options.longs === String ? String(message.volume) : message.volume;
                    else
                        object.volume = options.longs === String ? $util.Long.prototype.toString.call(message.volume) : options.longs === Number ? new $util.LongBits(message.volume.low >>> 0, message.volume.high >>> 0).toNumber(true) : message.volume;
                if (message.filledVolume != null && message.hasOwnProperty("filledVolume"))
                    if (typeof message.filledVolume === "number")
                        object.filledVolume = options.longs === String ? String(message.filledVolume) : message.filledVolume;
                    else
                        object.filledVolume = options.longs === String ? $util.Long.prototype.toString.call(message.filledVolume) : options.longs === Number ? new $util.LongBits(message.filledVolume.low >>> 0, message.filledVolume.high >>> 0).toNumber(true) : message.filledVolume;
                if (message.averagePrice != null && message.hasOwnProperty("averagePrice"))
                    if (typeof message.averagePrice === "number")
                        object.averagePrice = options.longs === String ? String(message.averagePrice) : message.averagePrice;
                    else
                        object.averagePrice = options.longs === String ? $util.Long.prototype.toString.call(message.averagePrice) : options.longs === Number ? new $util.LongBits(message.averagePrice.low >>> 0, message.averagePrice.high >>> 0).toNumber(true) : message.averagePrice;
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus"))
                    object.orderStatus = options.enums === String ? $root.feedbox.trade_api.OrderStatus[message.orderStatus] : message.orderStatus;
                if (message.profitLoss != null && message.hasOwnProperty("profitLoss"))
                    if (typeof message.profitLoss === "number")
                        object.profitLoss = options.longs === String ? String(message.profitLoss) : message.profitLoss;
                    else
                        object.profitLoss = options.longs === String ? $util.Long.prototype.toString.call(message.profitLoss) : options.longs === Number ? new $util.LongBits(message.profitLoss.low >>> 0, message.profitLoss.high >>> 0).toNumber() : message.profitLoss;
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    object.clientId = message.clientId;
                if (message.reasonCode != null && message.hasOwnProperty("reasonCode"))
                    object.reasonCode = options.enums === String ? $root.feedbox.trade_api.OrderReasonCode[message.reasonCode] : message.reasonCode;
                if (message.commission != null && message.hasOwnProperty("commission"))
                    if (typeof message.commission === "number")
                        object.commission = options.longs === String ? String(message.commission) : message.commission;
                    else
                        object.commission = options.longs === String ? $util.Long.prototype.toString.call(message.commission) : options.longs === Number ? new $util.LongBits(message.commission.low >>> 0, message.commission.high >>> 0).toNumber() : message.commission;
                if (message.swap != null && message.hasOwnProperty("swap"))
                    if (typeof message.swap === "number")
                        object.swap = options.longs === String ? String(message.swap) : message.swap;
                    else
                        object.swap = options.longs === String ? $util.Long.prototype.toString.call(message.swap) : options.longs === Number ? new $util.LongBits(message.swap.low >>> 0, message.swap.high >>> 0).toNumber() : message.swap;
                if (message.orderProfitLoss != null && message.hasOwnProperty("orderProfitLoss"))
                    if (typeof message.orderProfitLoss === "number")
                        object.orderProfitLoss = options.longs === String ? String(message.orderProfitLoss) : message.orderProfitLoss;
                    else
                        object.orderProfitLoss = options.longs === String ? $util.Long.prototype.toString.call(message.orderProfitLoss) : options.longs === Number ? new $util.LongBits(message.orderProfitLoss.low >>> 0, message.orderProfitLoss.high >>> 0).toNumber() : message.orderProfitLoss;
                if (message.orderCommission != null && message.hasOwnProperty("orderCommission"))
                    if (typeof message.orderCommission === "number")
                        object.orderCommission = options.longs === String ? String(message.orderCommission) : message.orderCommission;
                    else
                        object.orderCommission = options.longs === String ? $util.Long.prototype.toString.call(message.orderCommission) : options.longs === Number ? new $util.LongBits(message.orderCommission.low >>> 0, message.orderCommission.high >>> 0).toNumber() : message.orderCommission;
                if (message.orderSwap != null && message.hasOwnProperty("orderSwap"))
                    if (typeof message.orderSwap === "number")
                        object.orderSwap = options.longs === String ? String(message.orderSwap) : message.orderSwap;
                    else
                        object.orderSwap = options.longs === String ? $util.Long.prototype.toString.call(message.orderSwap) : options.longs === Number ? new $util.LongBits(message.orderSwap.low >>> 0, message.orderSwap.high >>> 0).toNumber() : message.orderSwap;
                if (message.insuranceFee != null && message.hasOwnProperty("insuranceFee"))
                    if (typeof message.insuranceFee === "number")
                        object.insuranceFee = options.longs === String ? String(message.insuranceFee) : message.insuranceFee;
                    else
                        object.insuranceFee = options.longs === String ? $util.Long.prototype.toString.call(message.insuranceFee) : options.longs === Number ? new $util.LongBits(message.insuranceFee.low >>> 0, message.insuranceFee.high >>> 0).toNumber() : message.insuranceFee;
                if (message.insurancePayout != null && message.hasOwnProperty("insurancePayout"))
                    if (typeof message.insurancePayout === "number")
                        object.insurancePayout = options.longs === String ? String(message.insurancePayout) : message.insurancePayout;
                    else
                        object.insurancePayout = options.longs === String ? $util.Long.prototype.toString.call(message.insurancePayout) : options.longs === Number ? new $util.LongBits(message.insurancePayout.low >>> 0, message.insurancePayout.high >>> 0).toNumber() : message.insurancePayout;
                if (message.orderInsuranceFee != null && message.hasOwnProperty("orderInsuranceFee"))
                    if (typeof message.orderInsuranceFee === "number")
                        object.orderInsuranceFee = options.longs === String ? String(message.orderInsuranceFee) : message.orderInsuranceFee;
                    else
                        object.orderInsuranceFee = options.longs === String ? $util.Long.prototype.toString.call(message.orderInsuranceFee) : options.longs === Number ? new $util.LongBits(message.orderInsuranceFee.low >>> 0, message.orderInsuranceFee.high >>> 0).toNumber() : message.orderInsuranceFee;
                if (message.orderInsurancePayout != null && message.hasOwnProperty("orderInsurancePayout"))
                    if (typeof message.orderInsurancePayout === "number")
                        object.orderInsurancePayout = options.longs === String ? String(message.orderInsurancePayout) : message.orderInsurancePayout;
                    else
                        object.orderInsurancePayout = options.longs === String ? $util.Long.prototype.toString.call(message.orderInsurancePayout) : options.longs === Number ? new $util.LongBits(message.orderInsurancePayout.low >>> 0, message.orderInsurancePayout.high >>> 0).toNumber() : message.orderInsurancePayout;
                if (message.time != null && message.hasOwnProperty("time"))
                    if (typeof message.time === "number")
                        object.time = options.longs === String ? String(message.time) : message.time;
                    else
                        object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                return object;
            };

            /**
             * Converts this OrderExecution to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.OrderExecution
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderExecution.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * ExecutionType enum.
             * @name feedbox.trade_api.OrderExecution.ExecutionType
             * @enum {number}
             * @property {number} E_NEW=0 E_NEW value
             * @property {number} E_CONFIRM=1 E_CONFIRM value
             * @property {number} E_REJECT=2 E_REJECT value
             */
            OrderExecution.ExecutionType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "E_NEW"] = 0;
                values[valuesById[1] = "E_CONFIRM"] = 1;
                values[valuesById[2] = "E_REJECT"] = 2;
                return values;
            })();

            return OrderExecution;
        })();

        trade_api.OrderExecutionList = (function() {

            /**
             * Properties of an OrderExecutionList.
             * @memberof feedbox.trade_api
             * @interface IOrderExecutionList
             * @property {Array.<feedbox.trade_api.IOrderExecution>|null} [orderExecution] OrderExecutionList orderExecution
             */

            /**
             * Constructs a new OrderExecutionList.
             * @memberof feedbox.trade_api
             * @classdesc Represents an OrderExecutionList.
             * @implements IOrderExecutionList
             * @constructor
             * @param {feedbox.trade_api.IOrderExecutionList=} [properties] Properties to set
             */
            function OrderExecutionList(properties) {
                this.orderExecution = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderExecutionList orderExecution.
             * @member {Array.<feedbox.trade_api.IOrderExecution>} orderExecution
             * @memberof feedbox.trade_api.OrderExecutionList
             * @instance
             */
            OrderExecutionList.prototype.orderExecution = $util.emptyArray;

            /**
             * Creates a new OrderExecutionList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.OrderExecutionList
             * @static
             * @param {feedbox.trade_api.IOrderExecutionList=} [properties] Properties to set
             * @returns {feedbox.trade_api.OrderExecutionList} OrderExecutionList instance
             */
            OrderExecutionList.create = function create(properties) {
                return new OrderExecutionList(properties);
            };

            /**
             * Encodes the specified OrderExecutionList message. Does not implicitly {@link feedbox.trade_api.OrderExecutionList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.OrderExecutionList
             * @static
             * @param {feedbox.trade_api.IOrderExecutionList} message OrderExecutionList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderExecutionList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderExecution != null && message.orderExecution.length)
                    for (var i = 0; i < message.orderExecution.length; ++i)
                        $root.feedbox.trade_api.OrderExecution.encode(message.orderExecution[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OrderExecutionList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.OrderExecutionList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.OrderExecutionList} OrderExecutionList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderExecutionList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.OrderExecutionList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.orderExecution && message.orderExecution.length))
                            message.orderExecution = [];
                        message.orderExecution.push($root.feedbox.trade_api.OrderExecution.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderExecutionList message.
             * @function verify
             * @memberof feedbox.trade_api.OrderExecutionList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderExecutionList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderExecution != null && message.hasOwnProperty("orderExecution")) {
                    if (!Array.isArray(message.orderExecution))
                        return "orderExecution: array expected";
                    for (var i = 0; i < message.orderExecution.length; ++i) {
                        var error = $root.feedbox.trade_api.OrderExecution.verify(message.orderExecution[i]);
                        if (error)
                            return "orderExecution." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OrderExecutionList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.OrderExecutionList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.OrderExecutionList} OrderExecutionList
             */
            OrderExecutionList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.OrderExecutionList)
                    return object;
                var message = new $root.feedbox.trade_api.OrderExecutionList();
                if (object.orderExecution) {
                    if (!Array.isArray(object.orderExecution))
                        throw TypeError(".feedbox.trade_api.OrderExecutionList.orderExecution: array expected");
                    message.orderExecution = [];
                    for (var i = 0; i < object.orderExecution.length; ++i) {
                        if (typeof object.orderExecution[i] !== "object")
                            throw TypeError(".feedbox.trade_api.OrderExecutionList.orderExecution: object expected");
                        message.orderExecution[i] = $root.feedbox.trade_api.OrderExecution.fromObject(object.orderExecution[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderExecutionList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.OrderExecutionList
             * @static
             * @param {feedbox.trade_api.OrderExecutionList} message OrderExecutionList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderExecutionList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderExecution = [];
                if (message.orderExecution && message.orderExecution.length) {
                    object.orderExecution = [];
                    for (var j = 0; j < message.orderExecution.length; ++j)
                        object.orderExecution[j] = $root.feedbox.trade_api.OrderExecution.toObject(message.orderExecution[j], options);
                }
                return object;
            };

            /**
             * Converts this OrderExecutionList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.OrderExecutionList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderExecutionList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderExecutionList;
        })();

        trade_api.OrderCancel = (function() {

            /**
             * Properties of an OrderCancel.
             * @memberof feedbox.trade_api
             * @interface IOrderCancel
             * @property {number|Long|null} [orderId] OrderCancel orderId
             */

            /**
             * Constructs a new OrderCancel.
             * @memberof feedbox.trade_api
             * @classdesc Represents an OrderCancel.
             * @implements IOrderCancel
             * @constructor
             * @param {feedbox.trade_api.IOrderCancel=} [properties] Properties to set
             */
            function OrderCancel(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderCancel orderId.
             * @member {number|Long} orderId
             * @memberof feedbox.trade_api.OrderCancel
             * @instance
             */
            OrderCancel.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new OrderCancel instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.OrderCancel
             * @static
             * @param {feedbox.trade_api.IOrderCancel=} [properties] Properties to set
             * @returns {feedbox.trade_api.OrderCancel} OrderCancel instance
             */
            OrderCancel.create = function create(properties) {
                return new OrderCancel(properties);
            };

            /**
             * Encodes the specified OrderCancel message. Does not implicitly {@link feedbox.trade_api.OrderCancel.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.OrderCancel
             * @static
             * @param {feedbox.trade_api.IOrderCancel} message OrderCancel message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderCancel.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.orderId);
                return writer;
            };

            /**
             * Decodes an OrderCancel message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.OrderCancel
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.OrderCancel} OrderCancel
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderCancel.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.OrderCancel();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderCancel message.
             * @function verify
             * @memberof feedbox.trade_api.OrderCancel
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderCancel.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                return null;
            };

            /**
             * Creates an OrderCancel message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.OrderCancel
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.OrderCancel} OrderCancel
             */
            OrderCancel.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.OrderCancel)
                    return object;
                var message = new $root.feedbox.trade_api.OrderCancel();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an OrderCancel message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.OrderCancel
             * @static
             * @param {feedbox.trade_api.OrderCancel} message OrderCancel
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderCancel.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                return object;
            };

            /**
             * Converts this OrderCancel to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.OrderCancel
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderCancel.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderCancel;
        })();

        trade_api.OrderCancelResult = (function() {

            /**
             * Properties of an OrderCancelResult.
             * @memberof feedbox.trade_api
             * @interface IOrderCancelResult
             * @property {feedbox.trade_api.OrderStatus|null} [orderStatus] OrderCancelResult orderStatus
             */

            /**
             * Constructs a new OrderCancelResult.
             * @memberof feedbox.trade_api
             * @classdesc Represents an OrderCancelResult.
             * @implements IOrderCancelResult
             * @constructor
             * @param {feedbox.trade_api.IOrderCancelResult=} [properties] Properties to set
             */
            function OrderCancelResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderCancelResult orderStatus.
             * @member {feedbox.trade_api.OrderStatus} orderStatus
             * @memberof feedbox.trade_api.OrderCancelResult
             * @instance
             */
            OrderCancelResult.prototype.orderStatus = 0;

            /**
             * Creates a new OrderCancelResult instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.OrderCancelResult
             * @static
             * @param {feedbox.trade_api.IOrderCancelResult=} [properties] Properties to set
             * @returns {feedbox.trade_api.OrderCancelResult} OrderCancelResult instance
             */
            OrderCancelResult.create = function create(properties) {
                return new OrderCancelResult(properties);
            };

            /**
             * Encodes the specified OrderCancelResult message. Does not implicitly {@link feedbox.trade_api.OrderCancelResult.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.OrderCancelResult
             * @static
             * @param {feedbox.trade_api.IOrderCancelResult} message OrderCancelResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderCancelResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderStatus != null && Object.hasOwnProperty.call(message, "orderStatus"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.orderStatus);
                return writer;
            };

            /**
             * Decodes an OrderCancelResult message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.OrderCancelResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.OrderCancelResult} OrderCancelResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderCancelResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.OrderCancelResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderStatus = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderCancelResult message.
             * @function verify
             * @memberof feedbox.trade_api.OrderCancelResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderCancelResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus"))
                    switch (message.orderStatus) {
                    default:
                        return "orderStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                return null;
            };

            /**
             * Creates an OrderCancelResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.OrderCancelResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.OrderCancelResult} OrderCancelResult
             */
            OrderCancelResult.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.OrderCancelResult)
                    return object;
                var message = new $root.feedbox.trade_api.OrderCancelResult();
                switch (object.orderStatus) {
                case "O_NEW":
                case 0:
                    message.orderStatus = 0;
                    break;
                case "O_PARTIALLY_FILLED":
                case 1:
                    message.orderStatus = 1;
                    break;
                case "O_FILLED":
                case 2:
                    message.orderStatus = 2;
                    break;
                case "O_CANCELED":
                case 3:
                    message.orderStatus = 3;
                    break;
                case "O_REJECTED":
                case 4:
                    message.orderStatus = 4;
                    break;
                case "O_EXPIRED":
                case 5:
                    message.orderStatus = 5;
                    break;
                case "O_PENDING_NEW":
                case 6:
                    message.orderStatus = 6;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderCancelResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.OrderCancelResult
             * @static
             * @param {feedbox.trade_api.OrderCancelResult} message OrderCancelResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderCancelResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.orderStatus = options.enums === String ? "O_NEW" : 0;
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus"))
                    object.orderStatus = options.enums === String ? $root.feedbox.trade_api.OrderStatus[message.orderStatus] : message.orderStatus;
                return object;
            };

            /**
             * Converts this OrderCancelResult to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.OrderCancelResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderCancelResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderCancelResult;
        })();

        trade_api.OrderReplace = (function() {

            /**
             * Properties of an OrderReplace.
             * @memberof feedbox.trade_api
             * @interface IOrderReplace
             * @property {number|Long|null} [orderId] OrderReplace orderId
             * @property {feedbox.trade_api.IOrderRequest|null} [order] OrderReplace order
             */

            /**
             * Constructs a new OrderReplace.
             * @memberof feedbox.trade_api
             * @classdesc Represents an OrderReplace.
             * @implements IOrderReplace
             * @constructor
             * @param {feedbox.trade_api.IOrderReplace=} [properties] Properties to set
             */
            function OrderReplace(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderReplace orderId.
             * @member {number|Long} orderId
             * @memberof feedbox.trade_api.OrderReplace
             * @instance
             */
            OrderReplace.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * OrderReplace order.
             * @member {feedbox.trade_api.IOrderRequest|null|undefined} order
             * @memberof feedbox.trade_api.OrderReplace
             * @instance
             */
            OrderReplace.prototype.order = null;

            /**
             * Creates a new OrderReplace instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.OrderReplace
             * @static
             * @param {feedbox.trade_api.IOrderReplace=} [properties] Properties to set
             * @returns {feedbox.trade_api.OrderReplace} OrderReplace instance
             */
            OrderReplace.create = function create(properties) {
                return new OrderReplace(properties);
            };

            /**
             * Encodes the specified OrderReplace message. Does not implicitly {@link feedbox.trade_api.OrderReplace.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.OrderReplace
             * @static
             * @param {feedbox.trade_api.IOrderReplace} message OrderReplace message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderReplace.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.orderId);
                if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                    $root.feedbox.trade_api.OrderRequest.encode(message.order, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OrderReplace message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.OrderReplace
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.OrderReplace} OrderReplace
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderReplace.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.OrderReplace();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.uint64();
                        break;
                    case 2:
                        message.order = $root.feedbox.trade_api.OrderRequest.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderReplace message.
             * @function verify
             * @memberof feedbox.trade_api.OrderReplace
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderReplace.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                if (message.order != null && message.hasOwnProperty("order")) {
                    var error = $root.feedbox.trade_api.OrderRequest.verify(message.order);
                    if (error)
                        return "order." + error;
                }
                return null;
            };

            /**
             * Creates an OrderReplace message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.OrderReplace
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.OrderReplace} OrderReplace
             */
            OrderReplace.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.OrderReplace)
                    return object;
                var message = new $root.feedbox.trade_api.OrderReplace();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                if (object.order != null) {
                    if (typeof object.order !== "object")
                        throw TypeError(".feedbox.trade_api.OrderReplace.order: object expected");
                    message.order = $root.feedbox.trade_api.OrderRequest.fromObject(object.order);
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderReplace message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.OrderReplace
             * @static
             * @param {feedbox.trade_api.OrderReplace} message OrderReplace
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderReplace.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                    object.order = null;
                }
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                if (message.order != null && message.hasOwnProperty("order"))
                    object.order = $root.feedbox.trade_api.OrderRequest.toObject(message.order, options);
                return object;
            };

            /**
             * Converts this OrderReplace to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.OrderReplace
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderReplace.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderReplace;
        })();

        trade_api.OrderReplaceResult = (function() {

            /**
             * Properties of an OrderReplaceResult.
             * @memberof feedbox.trade_api
             * @interface IOrderReplaceResult
             * @property {number|Long|null} [orderId] OrderReplaceResult orderId
             */

            /**
             * Constructs a new OrderReplaceResult.
             * @memberof feedbox.trade_api
             * @classdesc Represents an OrderReplaceResult.
             * @implements IOrderReplaceResult
             * @constructor
             * @param {feedbox.trade_api.IOrderReplaceResult=} [properties] Properties to set
             */
            function OrderReplaceResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderReplaceResult orderId.
             * @member {number|Long} orderId
             * @memberof feedbox.trade_api.OrderReplaceResult
             * @instance
             */
            OrderReplaceResult.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new OrderReplaceResult instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.OrderReplaceResult
             * @static
             * @param {feedbox.trade_api.IOrderReplaceResult=} [properties] Properties to set
             * @returns {feedbox.trade_api.OrderReplaceResult} OrderReplaceResult instance
             */
            OrderReplaceResult.create = function create(properties) {
                return new OrderReplaceResult(properties);
            };

            /**
             * Encodes the specified OrderReplaceResult message. Does not implicitly {@link feedbox.trade_api.OrderReplaceResult.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.OrderReplaceResult
             * @static
             * @param {feedbox.trade_api.IOrderReplaceResult} message OrderReplaceResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderReplaceResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.orderId);
                return writer;
            };

            /**
             * Decodes an OrderReplaceResult message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.OrderReplaceResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.OrderReplaceResult} OrderReplaceResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderReplaceResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.OrderReplaceResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.orderId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderReplaceResult message.
             * @function verify
             * @memberof feedbox.trade_api.OrderReplaceResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderReplaceResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                return null;
            };

            /**
             * Creates an OrderReplaceResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.OrderReplaceResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.OrderReplaceResult} OrderReplaceResult
             */
            OrderReplaceResult.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.OrderReplaceResult)
                    return object;
                var message = new $root.feedbox.trade_api.OrderReplaceResult();
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an OrderReplaceResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.OrderReplaceResult
             * @static
             * @param {feedbox.trade_api.OrderReplaceResult} message OrderReplaceResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderReplaceResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                return object;
            };

            /**
             * Converts this OrderReplaceResult to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.OrderReplaceResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderReplaceResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderReplaceResult;
        })();

        trade_api.GetOrderExecutionList = (function() {

            /**
             * Properties of a GetOrderExecutionList.
             * @memberof feedbox.trade_api
             * @interface IGetOrderExecutionList
             * @property {feedbox.trade_api.GetOrderExecutionList.IByTime|null} [byTime] GetOrderExecutionList byTime
             * @property {feedbox.trade_api.GetOrderExecutionList.IById|null} [byId] GetOrderExecutionList byId
             * @property {number|null} [limit] GetOrderExecutionList limit
             * @property {number|Long|null} [orderId] GetOrderExecutionList orderId
             */

            /**
             * Constructs a new GetOrderExecutionList.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetOrderExecutionList.
             * @implements IGetOrderExecutionList
             * @constructor
             * @param {feedbox.trade_api.IGetOrderExecutionList=} [properties] Properties to set
             */
            function GetOrderExecutionList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrderExecutionList byTime.
             * @member {feedbox.trade_api.GetOrderExecutionList.IByTime|null|undefined} byTime
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @instance
             */
            GetOrderExecutionList.prototype.byTime = null;

            /**
             * GetOrderExecutionList byId.
             * @member {feedbox.trade_api.GetOrderExecutionList.IById|null|undefined} byId
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @instance
             */
            GetOrderExecutionList.prototype.byId = null;

            /**
             * GetOrderExecutionList limit.
             * @member {number} limit
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @instance
             */
            GetOrderExecutionList.prototype.limit = 0;

            /**
             * GetOrderExecutionList orderId.
             * @member {number|Long} orderId
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @instance
             */
            GetOrderExecutionList.prototype.orderId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetOrderExecutionList body.
             * @member {"byTime"|"byId"|undefined} body
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @instance
             */
            Object.defineProperty(GetOrderExecutionList.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["byTime", "byId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetOrderExecutionList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @static
             * @param {feedbox.trade_api.IGetOrderExecutionList=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetOrderExecutionList} GetOrderExecutionList instance
             */
            GetOrderExecutionList.create = function create(properties) {
                return new GetOrderExecutionList(properties);
            };

            /**
             * Encodes the specified GetOrderExecutionList message. Does not implicitly {@link feedbox.trade_api.GetOrderExecutionList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @static
             * @param {feedbox.trade_api.IGetOrderExecutionList} message GetOrderExecutionList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderExecutionList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.byTime != null && Object.hasOwnProperty.call(message, "byTime"))
                    $root.feedbox.trade_api.GetOrderExecutionList.ByTime.encode(message.byTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.byId != null && Object.hasOwnProperty.call(message, "byId"))
                    $root.feedbox.trade_api.GetOrderExecutionList.ById.encode(message.byId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.limit);
                if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.orderId);
                return writer;
            };

            /**
             * Decodes a GetOrderExecutionList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetOrderExecutionList} GetOrderExecutionList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderExecutionList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetOrderExecutionList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.byTime = $root.feedbox.trade_api.GetOrderExecutionList.ByTime.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.byId = $root.feedbox.trade_api.GetOrderExecutionList.ById.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.limit = reader.uint32();
                        break;
                    case 4:
                        message.orderId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOrderExecutionList message.
             * @function verify
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderExecutionList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.byTime != null && message.hasOwnProperty("byTime")) {
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetOrderExecutionList.ByTime.verify(message.byTime);
                        if (error)
                            return "byTime." + error;
                    }
                }
                if (message.byId != null && message.hasOwnProperty("byId")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetOrderExecutionList.ById.verify(message.byId);
                        if (error)
                            return "byId." + error;
                    }
                }
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (!$util.isInteger(message.orderId) && !(message.orderId && $util.isInteger(message.orderId.low) && $util.isInteger(message.orderId.high)))
                        return "orderId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetOrderExecutionList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetOrderExecutionList} GetOrderExecutionList
             */
            GetOrderExecutionList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetOrderExecutionList)
                    return object;
                var message = new $root.feedbox.trade_api.GetOrderExecutionList();
                if (object.byTime != null) {
                    if (typeof object.byTime !== "object")
                        throw TypeError(".feedbox.trade_api.GetOrderExecutionList.byTime: object expected");
                    message.byTime = $root.feedbox.trade_api.GetOrderExecutionList.ByTime.fromObject(object.byTime);
                }
                if (object.byId != null) {
                    if (typeof object.byId !== "object")
                        throw TypeError(".feedbox.trade_api.GetOrderExecutionList.byId: object expected");
                    message.byId = $root.feedbox.trade_api.GetOrderExecutionList.ById.fromObject(object.byId);
                }
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                if (object.orderId != null)
                    if ($util.Long)
                        (message.orderId = $util.Long.fromValue(object.orderId)).unsigned = true;
                    else if (typeof object.orderId === "string")
                        message.orderId = parseInt(object.orderId, 10);
                    else if (typeof object.orderId === "number")
                        message.orderId = object.orderId;
                    else if (typeof object.orderId === "object")
                        message.orderId = new $util.LongBits(object.orderId.low >>> 0, object.orderId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetOrderExecutionList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @static
             * @param {feedbox.trade_api.GetOrderExecutionList} message GetOrderExecutionList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderExecutionList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.limit = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.orderId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orderId = options.longs === String ? "0" : 0;
                }
                if (message.byTime != null && message.hasOwnProperty("byTime")) {
                    object.byTime = $root.feedbox.trade_api.GetOrderExecutionList.ByTime.toObject(message.byTime, options);
                    if (options.oneofs)
                        object.body = "byTime";
                }
                if (message.byId != null && message.hasOwnProperty("byId")) {
                    object.byId = $root.feedbox.trade_api.GetOrderExecutionList.ById.toObject(message.byId, options);
                    if (options.oneofs)
                        object.body = "byId";
                }
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.orderId != null && message.hasOwnProperty("orderId"))
                    if (typeof message.orderId === "number")
                        object.orderId = options.longs === String ? String(message.orderId) : message.orderId;
                    else
                        object.orderId = options.longs === String ? $util.Long.prototype.toString.call(message.orderId) : options.longs === Number ? new $util.LongBits(message.orderId.low >>> 0, message.orderId.high >>> 0).toNumber(true) : message.orderId;
                return object;
            };

            /**
             * Converts this GetOrderExecutionList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetOrderExecutionList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderExecutionList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetOrderExecutionList.ByTime = (function() {

                /**
                 * Properties of a ByTime.
                 * @memberof feedbox.trade_api.GetOrderExecutionList
                 * @interface IByTime
                 * @property {number|Long|null} [fromTime] ByTime fromTime
                 * @property {number|Long|null} [toTime] ByTime toTime
                 */

                /**
                 * Constructs a new ByTime.
                 * @memberof feedbox.trade_api.GetOrderExecutionList
                 * @classdesc Represents a ByTime.
                 * @implements IByTime
                 * @constructor
                 * @param {feedbox.trade_api.GetOrderExecutionList.IByTime=} [properties] Properties to set
                 */
                function ByTime(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ByTime fromTime.
                 * @member {number|Long} fromTime
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ByTime
                 * @instance
                 */
                ByTime.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ByTime toTime.
                 * @member {number|Long} toTime
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ByTime
                 * @instance
                 */
                ByTime.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new ByTime instance using the specified properties.
                 * @function create
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ByTime
                 * @static
                 * @param {feedbox.trade_api.GetOrderExecutionList.IByTime=} [properties] Properties to set
                 * @returns {feedbox.trade_api.GetOrderExecutionList.ByTime} ByTime instance
                 */
                ByTime.create = function create(properties) {
                    return new ByTime(properties);
                };

                /**
                 * Encodes the specified ByTime message. Does not implicitly {@link feedbox.trade_api.GetOrderExecutionList.ByTime.verify|verify} messages.
                 * @function encode
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ByTime
                 * @static
                 * @param {feedbox.trade_api.GetOrderExecutionList.IByTime} message ByTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ByTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fromTime);
                    if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.toTime);
                    return writer;
                };

                /**
                 * Decodes a ByTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ByTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {feedbox.trade_api.GetOrderExecutionList.ByTime} ByTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ByTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetOrderExecutionList.ByTime();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.fromTime = reader.int64();
                            break;
                        case 2:
                            message.toTime = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ByTime message.
                 * @function verify
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ByTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ByTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                            return "fromTime: integer|Long expected";
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                            return "toTime: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a ByTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ByTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {feedbox.trade_api.GetOrderExecutionList.ByTime} ByTime
                 */
                ByTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.feedbox.trade_api.GetOrderExecutionList.ByTime)
                        return object;
                    var message = new $root.feedbox.trade_api.GetOrderExecutionList.ByTime();
                    if (object.fromTime != null)
                        if ($util.Long)
                            (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                        else if (typeof object.fromTime === "string")
                            message.fromTime = parseInt(object.fromTime, 10);
                        else if (typeof object.fromTime === "number")
                            message.fromTime = object.fromTime;
                        else if (typeof object.fromTime === "object")
                            message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                    if (object.toTime != null)
                        if ($util.Long)
                            (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                        else if (typeof object.toTime === "string")
                            message.toTime = parseInt(object.toTime, 10);
                        else if (typeof object.toTime === "number")
                            message.toTime = object.toTime;
                        else if (typeof object.toTime === "object")
                            message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a ByTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ByTime
                 * @static
                 * @param {feedbox.trade_api.GetOrderExecutionList.ByTime} message ByTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ByTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.fromTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.toTime = options.longs === String ? "0" : 0;
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (typeof message.fromTime === "number")
                            object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                        else
                            object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (typeof message.toTime === "number")
                            object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                        else
                            object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                    return object;
                };

                /**
                 * Converts this ByTime to JSON.
                 * @function toJSON
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ByTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ByTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ByTime;
            })();

            GetOrderExecutionList.ById = (function() {

                /**
                 * Properties of a ById.
                 * @memberof feedbox.trade_api.GetOrderExecutionList
                 * @interface IById
                 * @property {number|Long|null} [upToId] ById upToId
                 */

                /**
                 * Constructs a new ById.
                 * @memberof feedbox.trade_api.GetOrderExecutionList
                 * @classdesc Represents a ById.
                 * @implements IById
                 * @constructor
                 * @param {feedbox.trade_api.GetOrderExecutionList.IById=} [properties] Properties to set
                 */
                function ById(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ById upToId.
                 * @member {number|Long} upToId
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ById
                 * @instance
                 */
                ById.prototype.upToId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new ById instance using the specified properties.
                 * @function create
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ById
                 * @static
                 * @param {feedbox.trade_api.GetOrderExecutionList.IById=} [properties] Properties to set
                 * @returns {feedbox.trade_api.GetOrderExecutionList.ById} ById instance
                 */
                ById.create = function create(properties) {
                    return new ById(properties);
                };

                /**
                 * Encodes the specified ById message. Does not implicitly {@link feedbox.trade_api.GetOrderExecutionList.ById.verify|verify} messages.
                 * @function encode
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ById
                 * @static
                 * @param {feedbox.trade_api.GetOrderExecutionList.IById} message ById message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ById.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.upToId != null && Object.hasOwnProperty.call(message, "upToId"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.upToId);
                    return writer;
                };

                /**
                 * Decodes a ById message from the specified reader or buffer.
                 * @function decode
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ById
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {feedbox.trade_api.GetOrderExecutionList.ById} ById
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ById.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetOrderExecutionList.ById();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 3:
                            message.upToId = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ById message.
                 * @function verify
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ById
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ById.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.upToId != null && message.hasOwnProperty("upToId"))
                        if (!$util.isInteger(message.upToId) && !(message.upToId && $util.isInteger(message.upToId.low) && $util.isInteger(message.upToId.high)))
                            return "upToId: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a ById message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ById
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {feedbox.trade_api.GetOrderExecutionList.ById} ById
                 */
                ById.fromObject = function fromObject(object) {
                    if (object instanceof $root.feedbox.trade_api.GetOrderExecutionList.ById)
                        return object;
                    var message = new $root.feedbox.trade_api.GetOrderExecutionList.ById();
                    if (object.upToId != null)
                        if ($util.Long)
                            (message.upToId = $util.Long.fromValue(object.upToId)).unsigned = true;
                        else if (typeof object.upToId === "string")
                            message.upToId = parseInt(object.upToId, 10);
                        else if (typeof object.upToId === "number")
                            message.upToId = object.upToId;
                        else if (typeof object.upToId === "object")
                            message.upToId = new $util.LongBits(object.upToId.low >>> 0, object.upToId.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a ById message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ById
                 * @static
                 * @param {feedbox.trade_api.GetOrderExecutionList.ById} message ById
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ById.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.upToId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.upToId = options.longs === String ? "0" : 0;
                    if (message.upToId != null && message.hasOwnProperty("upToId"))
                        if (typeof message.upToId === "number")
                            object.upToId = options.longs === String ? String(message.upToId) : message.upToId;
                        else
                            object.upToId = options.longs === String ? $util.Long.prototype.toString.call(message.upToId) : options.longs === Number ? new $util.LongBits(message.upToId.low >>> 0, message.upToId.high >>> 0).toNumber(true) : message.upToId;
                    return object;
                };

                /**
                 * Converts this ById to JSON.
                 * @function toJSON
                 * @memberof feedbox.trade_api.GetOrderExecutionList.ById
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ById.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ById;
            })();

            return GetOrderExecutionList;
        })();

        trade_api.GetOrderList = (function() {

            /**
             * Properties of a GetOrderList.
             * @memberof feedbox.trade_api
             * @interface IGetOrderList
             * @property {feedbox.trade_api.GetOrderList.IByTime|null} [byTime] GetOrderList byTime
             * @property {feedbox.trade_api.GetOrderList.IById|null} [byId] GetOrderList byId
             * @property {number|null} [limit] GetOrderList limit
             * @property {feedbox.trade_api.GetOrderList.OrderStatusType|null} [statusType] GetOrderList statusType
             */

            /**
             * Constructs a new GetOrderList.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetOrderList.
             * @implements IGetOrderList
             * @constructor
             * @param {feedbox.trade_api.IGetOrderList=} [properties] Properties to set
             */
            function GetOrderList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetOrderList byTime.
             * @member {feedbox.trade_api.GetOrderList.IByTime|null|undefined} byTime
             * @memberof feedbox.trade_api.GetOrderList
             * @instance
             */
            GetOrderList.prototype.byTime = null;

            /**
             * GetOrderList byId.
             * @member {feedbox.trade_api.GetOrderList.IById|null|undefined} byId
             * @memberof feedbox.trade_api.GetOrderList
             * @instance
             */
            GetOrderList.prototype.byId = null;

            /**
             * GetOrderList limit.
             * @member {number} limit
             * @memberof feedbox.trade_api.GetOrderList
             * @instance
             */
            GetOrderList.prototype.limit = 0;

            /**
             * GetOrderList statusType.
             * @member {feedbox.trade_api.GetOrderList.OrderStatusType} statusType
             * @memberof feedbox.trade_api.GetOrderList
             * @instance
             */
            GetOrderList.prototype.statusType = 0;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetOrderList body.
             * @member {"byTime"|"byId"|undefined} body
             * @memberof feedbox.trade_api.GetOrderList
             * @instance
             */
            Object.defineProperty(GetOrderList.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["byTime", "byId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetOrderList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetOrderList
             * @static
             * @param {feedbox.trade_api.IGetOrderList=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetOrderList} GetOrderList instance
             */
            GetOrderList.create = function create(properties) {
                return new GetOrderList(properties);
            };

            /**
             * Encodes the specified GetOrderList message. Does not implicitly {@link feedbox.trade_api.GetOrderList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetOrderList
             * @static
             * @param {feedbox.trade_api.IGetOrderList} message GetOrderList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.byTime != null && Object.hasOwnProperty.call(message, "byTime"))
                    $root.feedbox.trade_api.GetOrderList.ByTime.encode(message.byTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.byId != null && Object.hasOwnProperty.call(message, "byId"))
                    $root.feedbox.trade_api.GetOrderList.ById.encode(message.byId, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.limit);
                if (message.statusType != null && Object.hasOwnProperty.call(message, "statusType"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.statusType);
                return writer;
            };

            /**
             * Decodes a GetOrderList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetOrderList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetOrderList} GetOrderList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetOrderList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.byTime = $root.feedbox.trade_api.GetOrderList.ByTime.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.byId = $root.feedbox.trade_api.GetOrderList.ById.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.limit = reader.uint32();
                        break;
                    case 4:
                        message.statusType = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetOrderList message.
             * @function verify
             * @memberof feedbox.trade_api.GetOrderList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.byTime != null && message.hasOwnProperty("byTime")) {
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetOrderList.ByTime.verify(message.byTime);
                        if (error)
                            return "byTime." + error;
                    }
                }
                if (message.byId != null && message.hasOwnProperty("byId")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetOrderList.ById.verify(message.byId);
                        if (error)
                            return "byId." + error;
                    }
                }
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                if (message.statusType != null && message.hasOwnProperty("statusType"))
                    switch (message.statusType) {
                    default:
                        return "statusType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };

            /**
             * Creates a GetOrderList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetOrderList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetOrderList} GetOrderList
             */
            GetOrderList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetOrderList)
                    return object;
                var message = new $root.feedbox.trade_api.GetOrderList();
                if (object.byTime != null) {
                    if (typeof object.byTime !== "object")
                        throw TypeError(".feedbox.trade_api.GetOrderList.byTime: object expected");
                    message.byTime = $root.feedbox.trade_api.GetOrderList.ByTime.fromObject(object.byTime);
                }
                if (object.byId != null) {
                    if (typeof object.byId !== "object")
                        throw TypeError(".feedbox.trade_api.GetOrderList.byId: object expected");
                    message.byId = $root.feedbox.trade_api.GetOrderList.ById.fromObject(object.byId);
                }
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                switch (object.statusType) {
                case "ALL":
                case 0:
                    message.statusType = 0;
                    break;
                case "FINISHED":
                case 1:
                    message.statusType = 1;
                    break;
                case "UNFINISHED":
                case 2:
                    message.statusType = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a GetOrderList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetOrderList
             * @static
             * @param {feedbox.trade_api.GetOrderList} message GetOrderList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.limit = 0;
                    object.statusType = options.enums === String ? "ALL" : 0;
                }
                if (message.byTime != null && message.hasOwnProperty("byTime")) {
                    object.byTime = $root.feedbox.trade_api.GetOrderList.ByTime.toObject(message.byTime, options);
                    if (options.oneofs)
                        object.body = "byTime";
                }
                if (message.byId != null && message.hasOwnProperty("byId")) {
                    object.byId = $root.feedbox.trade_api.GetOrderList.ById.toObject(message.byId, options);
                    if (options.oneofs)
                        object.body = "byId";
                }
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                if (message.statusType != null && message.hasOwnProperty("statusType"))
                    object.statusType = options.enums === String ? $root.feedbox.trade_api.GetOrderList.OrderStatusType[message.statusType] : message.statusType;
                return object;
            };

            /**
             * Converts this GetOrderList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetOrderList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetOrderList.ByTime = (function() {

                /**
                 * Properties of a ByTime.
                 * @memberof feedbox.trade_api.GetOrderList
                 * @interface IByTime
                 * @property {number|Long|null} [fromTime] ByTime fromTime
                 * @property {number|Long|null} [toTime] ByTime toTime
                 */

                /**
                 * Constructs a new ByTime.
                 * @memberof feedbox.trade_api.GetOrderList
                 * @classdesc Represents a ByTime.
                 * @implements IByTime
                 * @constructor
                 * @param {feedbox.trade_api.GetOrderList.IByTime=} [properties] Properties to set
                 */
                function ByTime(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ByTime fromTime.
                 * @member {number|Long} fromTime
                 * @memberof feedbox.trade_api.GetOrderList.ByTime
                 * @instance
                 */
                ByTime.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ByTime toTime.
                 * @member {number|Long} toTime
                 * @memberof feedbox.trade_api.GetOrderList.ByTime
                 * @instance
                 */
                ByTime.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new ByTime instance using the specified properties.
                 * @function create
                 * @memberof feedbox.trade_api.GetOrderList.ByTime
                 * @static
                 * @param {feedbox.trade_api.GetOrderList.IByTime=} [properties] Properties to set
                 * @returns {feedbox.trade_api.GetOrderList.ByTime} ByTime instance
                 */
                ByTime.create = function create(properties) {
                    return new ByTime(properties);
                };

                /**
                 * Encodes the specified ByTime message. Does not implicitly {@link feedbox.trade_api.GetOrderList.ByTime.verify|verify} messages.
                 * @function encode
                 * @memberof feedbox.trade_api.GetOrderList.ByTime
                 * @static
                 * @param {feedbox.trade_api.GetOrderList.IByTime} message ByTime message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ByTime.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fromTime);
                    if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.toTime);
                    return writer;
                };

                /**
                 * Decodes a ByTime message from the specified reader or buffer.
                 * @function decode
                 * @memberof feedbox.trade_api.GetOrderList.ByTime
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {feedbox.trade_api.GetOrderList.ByTime} ByTime
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ByTime.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetOrderList.ByTime();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.fromTime = reader.int64();
                            break;
                        case 2:
                            message.toTime = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ByTime message.
                 * @function verify
                 * @memberof feedbox.trade_api.GetOrderList.ByTime
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ByTime.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                            return "fromTime: integer|Long expected";
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                            return "toTime: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a ByTime message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof feedbox.trade_api.GetOrderList.ByTime
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {feedbox.trade_api.GetOrderList.ByTime} ByTime
                 */
                ByTime.fromObject = function fromObject(object) {
                    if (object instanceof $root.feedbox.trade_api.GetOrderList.ByTime)
                        return object;
                    var message = new $root.feedbox.trade_api.GetOrderList.ByTime();
                    if (object.fromTime != null)
                        if ($util.Long)
                            (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                        else if (typeof object.fromTime === "string")
                            message.fromTime = parseInt(object.fromTime, 10);
                        else if (typeof object.fromTime === "number")
                            message.fromTime = object.fromTime;
                        else if (typeof object.fromTime === "object")
                            message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                    if (object.toTime != null)
                        if ($util.Long)
                            (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                        else if (typeof object.toTime === "string")
                            message.toTime = parseInt(object.toTime, 10);
                        else if (typeof object.toTime === "number")
                            message.toTime = object.toTime;
                        else if (typeof object.toTime === "object")
                            message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a ByTime message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof feedbox.trade_api.GetOrderList.ByTime
                 * @static
                 * @param {feedbox.trade_api.GetOrderList.ByTime} message ByTime
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ByTime.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.fromTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.toTime = options.longs === String ? "0" : 0;
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (typeof message.fromTime === "number")
                            object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                        else
                            object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (typeof message.toTime === "number")
                            object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                        else
                            object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                    return object;
                };

                /**
                 * Converts this ByTime to JSON.
                 * @function toJSON
                 * @memberof feedbox.trade_api.GetOrderList.ByTime
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ByTime.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ByTime;
            })();

            GetOrderList.ById = (function() {

                /**
                 * Properties of a ById.
                 * @memberof feedbox.trade_api.GetOrderList
                 * @interface IById
                 * @property {number|Long|null} [upToId] ById upToId
                 */

                /**
                 * Constructs a new ById.
                 * @memberof feedbox.trade_api.GetOrderList
                 * @classdesc Represents a ById.
                 * @implements IById
                 * @constructor
                 * @param {feedbox.trade_api.GetOrderList.IById=} [properties] Properties to set
                 */
                function ById(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ById upToId.
                 * @member {number|Long} upToId
                 * @memberof feedbox.trade_api.GetOrderList.ById
                 * @instance
                 */
                ById.prototype.upToId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                /**
                 * Creates a new ById instance using the specified properties.
                 * @function create
                 * @memberof feedbox.trade_api.GetOrderList.ById
                 * @static
                 * @param {feedbox.trade_api.GetOrderList.IById=} [properties] Properties to set
                 * @returns {feedbox.trade_api.GetOrderList.ById} ById instance
                 */
                ById.create = function create(properties) {
                    return new ById(properties);
                };

                /**
                 * Encodes the specified ById message. Does not implicitly {@link feedbox.trade_api.GetOrderList.ById.verify|verify} messages.
                 * @function encode
                 * @memberof feedbox.trade_api.GetOrderList.ById
                 * @static
                 * @param {feedbox.trade_api.GetOrderList.IById} message ById message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ById.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.upToId != null && Object.hasOwnProperty.call(message, "upToId"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.upToId);
                    return writer;
                };

                /**
                 * Decodes a ById message from the specified reader or buffer.
                 * @function decode
                 * @memberof feedbox.trade_api.GetOrderList.ById
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {feedbox.trade_api.GetOrderList.ById} ById
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ById.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetOrderList.ById();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 3:
                            message.upToId = reader.uint64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ById message.
                 * @function verify
                 * @memberof feedbox.trade_api.GetOrderList.ById
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ById.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.upToId != null && message.hasOwnProperty("upToId"))
                        if (!$util.isInteger(message.upToId) && !(message.upToId && $util.isInteger(message.upToId.low) && $util.isInteger(message.upToId.high)))
                            return "upToId: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a ById message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof feedbox.trade_api.GetOrderList.ById
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {feedbox.trade_api.GetOrderList.ById} ById
                 */
                ById.fromObject = function fromObject(object) {
                    if (object instanceof $root.feedbox.trade_api.GetOrderList.ById)
                        return object;
                    var message = new $root.feedbox.trade_api.GetOrderList.ById();
                    if (object.upToId != null)
                        if ($util.Long)
                            (message.upToId = $util.Long.fromValue(object.upToId)).unsigned = true;
                        else if (typeof object.upToId === "string")
                            message.upToId = parseInt(object.upToId, 10);
                        else if (typeof object.upToId === "number")
                            message.upToId = object.upToId;
                        else if (typeof object.upToId === "object")
                            message.upToId = new $util.LongBits(object.upToId.low >>> 0, object.upToId.high >>> 0).toNumber(true);
                    return message;
                };

                /**
                 * Creates a plain object from a ById message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof feedbox.trade_api.GetOrderList.ById
                 * @static
                 * @param {feedbox.trade_api.GetOrderList.ById} message ById
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ById.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, true);
                            object.upToId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.upToId = options.longs === String ? "0" : 0;
                    if (message.upToId != null && message.hasOwnProperty("upToId"))
                        if (typeof message.upToId === "number")
                            object.upToId = options.longs === String ? String(message.upToId) : message.upToId;
                        else
                            object.upToId = options.longs === String ? $util.Long.prototype.toString.call(message.upToId) : options.longs === Number ? new $util.LongBits(message.upToId.low >>> 0, message.upToId.high >>> 0).toNumber(true) : message.upToId;
                    return object;
                };

                /**
                 * Converts this ById to JSON.
                 * @function toJSON
                 * @memberof feedbox.trade_api.GetOrderList.ById
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ById.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ById;
            })();

            /**
             * OrderStatusType enum.
             * @name feedbox.trade_api.GetOrderList.OrderStatusType
             * @enum {number}
             * @property {number} ALL=0 ALL value
             * @property {number} FINISHED=1 FINISHED value
             * @property {number} UNFINISHED=2 UNFINISHED value
             */
            GetOrderList.OrderStatusType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ALL"] = 0;
                values[valuesById[1] = "FINISHED"] = 1;
                values[valuesById[2] = "UNFINISHED"] = 2;
                return values;
            })();

            return GetOrderList;
        })();

        trade_api.Order = (function() {

            /**
             * Properties of an Order.
             * @memberof feedbox.trade_api
             * @interface IOrder
             * @property {number|Long|null} [id] Order id
             * @property {string|null} [clientId] Order clientId
             * @property {string|null} [symbol] Order symbol
             * @property {feedbox.trade_api.Side|null} [side] Order side
             * @property {number|Long|null} [volume] Order volume
             * @property {number|Long|null} [price] Order price
             * @property {number|Long|null} [deviation] Order deviation
             * @property {number|Long|null} [stopPrice] Order stopPrice
             * @property {feedbox.trade_api.OrderType|null} [type] Order type
             * @property {feedbox.trade_api.OrderTimeInForce|null} [timeInForce] Order timeInForce
             * @property {number|Long|null} [expirationDatetime] Order expirationDatetime
             * @property {Array.<string>|null} [lpName] Order lpName
             * @property {number|Long|null} [positionId] Order positionId
             * @property {number|Long|null} [slPrice] Order slPrice
             * @property {number|Long|null} [tpPrice] Order tpPrice
             * @property {number|Long|null} [requestTime] Order requestTime
             * @property {feedbox.trade_api.OrderStatus|null} [orderStatus] Order orderStatus
             * @property {number|Long|null} [filledVolume] Order filledVolume
             * @property {number|Long|null} [averagePrice] Order averagePrice
             * @property {feedbox.trade_api.OrderReasonCode|null} [reasonCode] Order reasonCode
             * @property {number|Long|null} [profitLoss] Order profitLoss
             * @property {number|Long|null} [commission] Order commission
             * @property {number|Long|null} [swap] Order swap
             * @property {number|Long|null} [insuranceFee] Order insuranceFee
             * @property {number|Long|null} [insurancePayout] Order insurancePayout
             * @property {number|Long|null} [insuranceConditionId] Order insuranceConditionId
             */

            /**
             * Constructs a new Order.
             * @memberof feedbox.trade_api
             * @classdesc Represents an Order.
             * @implements IOrder
             * @constructor
             * @param {feedbox.trade_api.IOrder=} [properties] Properties to set
             */
            function Order(properties) {
                this.lpName = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Order id.
             * @member {number|Long} id
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order clientId.
             * @member {string} clientId
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.clientId = "";

            /**
             * Order symbol.
             * @member {string} symbol
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.symbol = "";

            /**
             * Order side.
             * @member {feedbox.trade_api.Side} side
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.side = 0;

            /**
             * Order volume.
             * @member {number|Long} volume
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order price.
             * @member {number|Long} price
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order deviation.
             * @member {number|Long} deviation
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.deviation = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order stopPrice.
             * @member {number|Long} stopPrice
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.stopPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order type.
             * @member {feedbox.trade_api.OrderType} type
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.type = 0;

            /**
             * Order timeInForce.
             * @member {feedbox.trade_api.OrderTimeInForce} timeInForce
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.timeInForce = 0;

            /**
             * Order expirationDatetime.
             * @member {number|Long} expirationDatetime
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.expirationDatetime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order lpName.
             * @member {Array.<string>} lpName
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.lpName = $util.emptyArray;

            /**
             * Order positionId.
             * @member {number|Long} positionId
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.positionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order slPrice.
             * @member {number|Long} slPrice
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.slPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order tpPrice.
             * @member {number|Long} tpPrice
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.tpPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order requestTime.
             * @member {number|Long} requestTime
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.requestTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order orderStatus.
             * @member {feedbox.trade_api.OrderStatus} orderStatus
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.orderStatus = 0;

            /**
             * Order filledVolume.
             * @member {number|Long} filledVolume
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.filledVolume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order averagePrice.
             * @member {number|Long} averagePrice
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.averagePrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Order reasonCode.
             * @member {feedbox.trade_api.OrderReasonCode} reasonCode
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.reasonCode = 0;

            /**
             * Order profitLoss.
             * @member {number|Long} profitLoss
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.profitLoss = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order commission.
             * @member {number|Long} commission
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.commission = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order swap.
             * @member {number|Long} swap
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.swap = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order insuranceFee.
             * @member {number|Long} insuranceFee
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.insuranceFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order insurancePayout.
             * @member {number|Long} insurancePayout
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.insurancePayout = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Order insuranceConditionId.
             * @member {number|Long} insuranceConditionId
             * @memberof feedbox.trade_api.Order
             * @instance
             */
            Order.prototype.insuranceConditionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new Order instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.Order
             * @static
             * @param {feedbox.trade_api.IOrder=} [properties] Properties to set
             * @returns {feedbox.trade_api.Order} Order instance
             */
            Order.create = function create(properties) {
                return new Order(properties);
            };

            /**
             * Encodes the specified Order message. Does not implicitly {@link feedbox.trade_api.Order.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.Order
             * @static
             * @param {feedbox.trade_api.IOrder} message Order message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Order.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.symbol);
                if (message.side != null && Object.hasOwnProperty.call(message, "side"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.side);
                if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.volume);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.price);
                if (message.deviation != null && Object.hasOwnProperty.call(message, "deviation"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.deviation);
                if (message.stopPrice != null && Object.hasOwnProperty.call(message, "stopPrice"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.stopPrice);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.type);
                if (message.timeInForce != null && Object.hasOwnProperty.call(message, "timeInForce"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.timeInForce);
                if (message.lpName != null && message.lpName.length)
                    for (var i = 0; i < message.lpName.length; ++i)
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.lpName[i]);
                if (message.requestTime != null && Object.hasOwnProperty.call(message, "requestTime"))
                    writer.uint32(/* id 12, wireType 0 =*/96).int64(message.requestTime);
                if (message.orderStatus != null && Object.hasOwnProperty.call(message, "orderStatus"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int32(message.orderStatus);
                if (message.filledVolume != null && Object.hasOwnProperty.call(message, "filledVolume"))
                    writer.uint32(/* id 14, wireType 0 =*/112).uint64(message.filledVolume);
                if (message.averagePrice != null && Object.hasOwnProperty.call(message, "averagePrice"))
                    writer.uint32(/* id 15, wireType 0 =*/120).uint64(message.averagePrice);
                if (message.reasonCode != null && Object.hasOwnProperty.call(message, "reasonCode"))
                    writer.uint32(/* id 16, wireType 0 =*/128).int32(message.reasonCode);
                if (message.profitLoss != null && Object.hasOwnProperty.call(message, "profitLoss"))
                    writer.uint32(/* id 17, wireType 0 =*/136).int64(message.profitLoss);
                if (message.commission != null && Object.hasOwnProperty.call(message, "commission"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int64(message.commission);
                if (message.swap != null && Object.hasOwnProperty.call(message, "swap"))
                    writer.uint32(/* id 19, wireType 0 =*/152).int64(message.swap);
                if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                    writer.uint32(/* id 20, wireType 2 =*/162).string(message.clientId);
                if (message.expirationDatetime != null && Object.hasOwnProperty.call(message, "expirationDatetime"))
                    writer.uint32(/* id 21, wireType 0 =*/168).int64(message.expirationDatetime);
                if (message.positionId != null && Object.hasOwnProperty.call(message, "positionId"))
                    writer.uint32(/* id 22, wireType 0 =*/176).uint64(message.positionId);
                if (message.slPrice != null && Object.hasOwnProperty.call(message, "slPrice"))
                    writer.uint32(/* id 23, wireType 0 =*/184).uint64(message.slPrice);
                if (message.tpPrice != null && Object.hasOwnProperty.call(message, "tpPrice"))
                    writer.uint32(/* id 24, wireType 0 =*/192).uint64(message.tpPrice);
                if (message.insuranceConditionId != null && Object.hasOwnProperty.call(message, "insuranceConditionId"))
                    writer.uint32(/* id 25, wireType 0 =*/200).uint64(message.insuranceConditionId);
                if (message.insuranceFee != null && Object.hasOwnProperty.call(message, "insuranceFee"))
                    writer.uint32(/* id 26, wireType 0 =*/208).int64(message.insuranceFee);
                if (message.insurancePayout != null && Object.hasOwnProperty.call(message, "insurancePayout"))
                    writer.uint32(/* id 27, wireType 0 =*/216).int64(message.insurancePayout);
                return writer;
            };

            /**
             * Decodes an Order message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.Order
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.Order} Order
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Order.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.Order();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 20:
                        message.clientId = reader.string();
                        break;
                    case 2:
                        message.symbol = reader.string();
                        break;
                    case 3:
                        message.side = reader.int32();
                        break;
                    case 4:
                        message.volume = reader.uint64();
                        break;
                    case 5:
                        message.price = reader.uint64();
                        break;
                    case 6:
                        message.deviation = reader.uint64();
                        break;
                    case 7:
                        message.stopPrice = reader.uint64();
                        break;
                    case 8:
                        message.type = reader.int32();
                        break;
                    case 9:
                        message.timeInForce = reader.int32();
                        break;
                    case 21:
                        message.expirationDatetime = reader.int64();
                        break;
                    case 11:
                        if (!(message.lpName && message.lpName.length))
                            message.lpName = [];
                        message.lpName.push(reader.string());
                        break;
                    case 22:
                        message.positionId = reader.uint64();
                        break;
                    case 23:
                        message.slPrice = reader.uint64();
                        break;
                    case 24:
                        message.tpPrice = reader.uint64();
                        break;
                    case 12:
                        message.requestTime = reader.int64();
                        break;
                    case 13:
                        message.orderStatus = reader.int32();
                        break;
                    case 14:
                        message.filledVolume = reader.uint64();
                        break;
                    case 15:
                        message.averagePrice = reader.uint64();
                        break;
                    case 16:
                        message.reasonCode = reader.int32();
                        break;
                    case 17:
                        message.profitLoss = reader.int64();
                        break;
                    case 18:
                        message.commission = reader.int64();
                        break;
                    case 19:
                        message.swap = reader.int64();
                        break;
                    case 26:
                        message.insuranceFee = reader.int64();
                        break;
                    case 27:
                        message.insurancePayout = reader.int64();
                        break;
                    case 25:
                        message.insuranceConditionId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an Order message.
             * @function verify
             * @memberof feedbox.trade_api.Order
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Order.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    if (!$util.isString(message.clientId))
                        return "clientId: string expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.side != null && message.hasOwnProperty("side"))
                    switch (message.side) {
                    default:
                        return "side: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (!$util.isInteger(message.volume) && !(message.volume && $util.isInteger(message.volume.low) && $util.isInteger(message.volume.high)))
                        return "volume: integer|Long expected";
                if (message.price != null && message.hasOwnProperty("price"))
                    if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                        return "price: integer|Long expected";
                if (message.deviation != null && message.hasOwnProperty("deviation"))
                    if (!$util.isInteger(message.deviation) && !(message.deviation && $util.isInteger(message.deviation.low) && $util.isInteger(message.deviation.high)))
                        return "deviation: integer|Long expected";
                if (message.stopPrice != null && message.hasOwnProperty("stopPrice"))
                    if (!$util.isInteger(message.stopPrice) && !(message.stopPrice && $util.isInteger(message.stopPrice.low) && $util.isInteger(message.stopPrice.high)))
                        return "stopPrice: integer|Long expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.timeInForce != null && message.hasOwnProperty("timeInForce"))
                    switch (message.timeInForce) {
                    default:
                        return "timeInForce: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.expirationDatetime != null && message.hasOwnProperty("expirationDatetime"))
                    if (!$util.isInteger(message.expirationDatetime) && !(message.expirationDatetime && $util.isInteger(message.expirationDatetime.low) && $util.isInteger(message.expirationDatetime.high)))
                        return "expirationDatetime: integer|Long expected";
                if (message.lpName != null && message.hasOwnProperty("lpName")) {
                    if (!Array.isArray(message.lpName))
                        return "lpName: array expected";
                    for (var i = 0; i < message.lpName.length; ++i)
                        if (!$util.isString(message.lpName[i]))
                            return "lpName: string[] expected";
                }
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    if (!$util.isInteger(message.positionId) && !(message.positionId && $util.isInteger(message.positionId.low) && $util.isInteger(message.positionId.high)))
                        return "positionId: integer|Long expected";
                if (message.slPrice != null && message.hasOwnProperty("slPrice"))
                    if (!$util.isInteger(message.slPrice) && !(message.slPrice && $util.isInteger(message.slPrice.low) && $util.isInteger(message.slPrice.high)))
                        return "slPrice: integer|Long expected";
                if (message.tpPrice != null && message.hasOwnProperty("tpPrice"))
                    if (!$util.isInteger(message.tpPrice) && !(message.tpPrice && $util.isInteger(message.tpPrice.low) && $util.isInteger(message.tpPrice.high)))
                        return "tpPrice: integer|Long expected";
                if (message.requestTime != null && message.hasOwnProperty("requestTime"))
                    if (!$util.isInteger(message.requestTime) && !(message.requestTime && $util.isInteger(message.requestTime.low) && $util.isInteger(message.requestTime.high)))
                        return "requestTime: integer|Long expected";
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus"))
                    switch (message.orderStatus) {
                    default:
                        return "orderStatus: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        break;
                    }
                if (message.filledVolume != null && message.hasOwnProperty("filledVolume"))
                    if (!$util.isInteger(message.filledVolume) && !(message.filledVolume && $util.isInteger(message.filledVolume.low) && $util.isInteger(message.filledVolume.high)))
                        return "filledVolume: integer|Long expected";
                if (message.averagePrice != null && message.hasOwnProperty("averagePrice"))
                    if (!$util.isInteger(message.averagePrice) && !(message.averagePrice && $util.isInteger(message.averagePrice.low) && $util.isInteger(message.averagePrice.high)))
                        return "averagePrice: integer|Long expected";
                if (message.reasonCode != null && message.hasOwnProperty("reasonCode"))
                    switch (message.reasonCode) {
                    default:
                        return "reasonCode: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
                if (message.profitLoss != null && message.hasOwnProperty("profitLoss"))
                    if (!$util.isInteger(message.profitLoss) && !(message.profitLoss && $util.isInteger(message.profitLoss.low) && $util.isInteger(message.profitLoss.high)))
                        return "profitLoss: integer|Long expected";
                if (message.commission != null && message.hasOwnProperty("commission"))
                    if (!$util.isInteger(message.commission) && !(message.commission && $util.isInteger(message.commission.low) && $util.isInteger(message.commission.high)))
                        return "commission: integer|Long expected";
                if (message.swap != null && message.hasOwnProperty("swap"))
                    if (!$util.isInteger(message.swap) && !(message.swap && $util.isInteger(message.swap.low) && $util.isInteger(message.swap.high)))
                        return "swap: integer|Long expected";
                if (message.insuranceFee != null && message.hasOwnProperty("insuranceFee"))
                    if (!$util.isInteger(message.insuranceFee) && !(message.insuranceFee && $util.isInteger(message.insuranceFee.low) && $util.isInteger(message.insuranceFee.high)))
                        return "insuranceFee: integer|Long expected";
                if (message.insurancePayout != null && message.hasOwnProperty("insurancePayout"))
                    if (!$util.isInteger(message.insurancePayout) && !(message.insurancePayout && $util.isInteger(message.insurancePayout.low) && $util.isInteger(message.insurancePayout.high)))
                        return "insurancePayout: integer|Long expected";
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (!$util.isInteger(message.insuranceConditionId) && !(message.insuranceConditionId && $util.isInteger(message.insuranceConditionId.low) && $util.isInteger(message.insuranceConditionId.high)))
                        return "insuranceConditionId: integer|Long expected";
                return null;
            };

            /**
             * Creates an Order message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.Order
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.Order} Order
             */
            Order.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.Order)
                    return object;
                var message = new $root.feedbox.trade_api.Order();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.clientId != null)
                    message.clientId = String(object.clientId);
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                switch (object.side) {
                case "BUY":
                case 0:
                    message.side = 0;
                    break;
                case "SELL":
                case 1:
                    message.side = 1;
                    break;
                }
                if (object.volume != null)
                    if ($util.Long)
                        (message.volume = $util.Long.fromValue(object.volume)).unsigned = true;
                    else if (typeof object.volume === "string")
                        message.volume = parseInt(object.volume, 10);
                    else if (typeof object.volume === "number")
                        message.volume = object.volume;
                    else if (typeof object.volume === "object")
                        message.volume = new $util.LongBits(object.volume.low >>> 0, object.volume.high >>> 0).toNumber(true);
                if (object.price != null)
                    if ($util.Long)
                        (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                    else if (typeof object.price === "string")
                        message.price = parseInt(object.price, 10);
                    else if (typeof object.price === "number")
                        message.price = object.price;
                    else if (typeof object.price === "object")
                        message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                if (object.deviation != null)
                    if ($util.Long)
                        (message.deviation = $util.Long.fromValue(object.deviation)).unsigned = true;
                    else if (typeof object.deviation === "string")
                        message.deviation = parseInt(object.deviation, 10);
                    else if (typeof object.deviation === "number")
                        message.deviation = object.deviation;
                    else if (typeof object.deviation === "object")
                        message.deviation = new $util.LongBits(object.deviation.low >>> 0, object.deviation.high >>> 0).toNumber(true);
                if (object.stopPrice != null)
                    if ($util.Long)
                        (message.stopPrice = $util.Long.fromValue(object.stopPrice)).unsigned = true;
                    else if (typeof object.stopPrice === "string")
                        message.stopPrice = parseInt(object.stopPrice, 10);
                    else if (typeof object.stopPrice === "number")
                        message.stopPrice = object.stopPrice;
                    else if (typeof object.stopPrice === "object")
                        message.stopPrice = new $util.LongBits(object.stopPrice.low >>> 0, object.stopPrice.high >>> 0).toNumber(true);
                switch (object.type) {
                case "O_MARKET":
                case 0:
                    message.type = 0;
                    break;
                case "O_LIMIT":
                case 1:
                    message.type = 1;
                    break;
                case "O_STOP":
                case 2:
                    message.type = 2;
                    break;
                case "O_STOP_LIMIT":
                case 3:
                    message.type = 3;
                    break;
                }
                switch (object.timeInForce) {
                case "FOK":
                case 0:
                    message.timeInForce = 0;
                    break;
                case "DAY":
                case 1:
                    message.timeInForce = 1;
                    break;
                case "GTD":
                case 2:
                    message.timeInForce = 2;
                    break;
                case "GTC":
                case 3:
                    message.timeInForce = 3;
                    break;
                case "IOC":
                case 4:
                    message.timeInForce = 4;
                    break;
                }
                if (object.expirationDatetime != null)
                    if ($util.Long)
                        (message.expirationDatetime = $util.Long.fromValue(object.expirationDatetime)).unsigned = false;
                    else if (typeof object.expirationDatetime === "string")
                        message.expirationDatetime = parseInt(object.expirationDatetime, 10);
                    else if (typeof object.expirationDatetime === "number")
                        message.expirationDatetime = object.expirationDatetime;
                    else if (typeof object.expirationDatetime === "object")
                        message.expirationDatetime = new $util.LongBits(object.expirationDatetime.low >>> 0, object.expirationDatetime.high >>> 0).toNumber();
                if (object.lpName) {
                    if (!Array.isArray(object.lpName))
                        throw TypeError(".feedbox.trade_api.Order.lpName: array expected");
                    message.lpName = [];
                    for (var i = 0; i < object.lpName.length; ++i)
                        message.lpName[i] = String(object.lpName[i]);
                }
                if (object.positionId != null)
                    if ($util.Long)
                        (message.positionId = $util.Long.fromValue(object.positionId)).unsigned = true;
                    else if (typeof object.positionId === "string")
                        message.positionId = parseInt(object.positionId, 10);
                    else if (typeof object.positionId === "number")
                        message.positionId = object.positionId;
                    else if (typeof object.positionId === "object")
                        message.positionId = new $util.LongBits(object.positionId.low >>> 0, object.positionId.high >>> 0).toNumber(true);
                if (object.slPrice != null)
                    if ($util.Long)
                        (message.slPrice = $util.Long.fromValue(object.slPrice)).unsigned = true;
                    else if (typeof object.slPrice === "string")
                        message.slPrice = parseInt(object.slPrice, 10);
                    else if (typeof object.slPrice === "number")
                        message.slPrice = object.slPrice;
                    else if (typeof object.slPrice === "object")
                        message.slPrice = new $util.LongBits(object.slPrice.low >>> 0, object.slPrice.high >>> 0).toNumber(true);
                if (object.tpPrice != null)
                    if ($util.Long)
                        (message.tpPrice = $util.Long.fromValue(object.tpPrice)).unsigned = true;
                    else if (typeof object.tpPrice === "string")
                        message.tpPrice = parseInt(object.tpPrice, 10);
                    else if (typeof object.tpPrice === "number")
                        message.tpPrice = object.tpPrice;
                    else if (typeof object.tpPrice === "object")
                        message.tpPrice = new $util.LongBits(object.tpPrice.low >>> 0, object.tpPrice.high >>> 0).toNumber(true);
                if (object.requestTime != null)
                    if ($util.Long)
                        (message.requestTime = $util.Long.fromValue(object.requestTime)).unsigned = false;
                    else if (typeof object.requestTime === "string")
                        message.requestTime = parseInt(object.requestTime, 10);
                    else if (typeof object.requestTime === "number")
                        message.requestTime = object.requestTime;
                    else if (typeof object.requestTime === "object")
                        message.requestTime = new $util.LongBits(object.requestTime.low >>> 0, object.requestTime.high >>> 0).toNumber();
                switch (object.orderStatus) {
                case "O_NEW":
                case 0:
                    message.orderStatus = 0;
                    break;
                case "O_PARTIALLY_FILLED":
                case 1:
                    message.orderStatus = 1;
                    break;
                case "O_FILLED":
                case 2:
                    message.orderStatus = 2;
                    break;
                case "O_CANCELED":
                case 3:
                    message.orderStatus = 3;
                    break;
                case "O_REJECTED":
                case 4:
                    message.orderStatus = 4;
                    break;
                case "O_EXPIRED":
                case 5:
                    message.orderStatus = 5;
                    break;
                case "O_PENDING_NEW":
                case 6:
                    message.orderStatus = 6;
                    break;
                }
                if (object.filledVolume != null)
                    if ($util.Long)
                        (message.filledVolume = $util.Long.fromValue(object.filledVolume)).unsigned = true;
                    else if (typeof object.filledVolume === "string")
                        message.filledVolume = parseInt(object.filledVolume, 10);
                    else if (typeof object.filledVolume === "number")
                        message.filledVolume = object.filledVolume;
                    else if (typeof object.filledVolume === "object")
                        message.filledVolume = new $util.LongBits(object.filledVolume.low >>> 0, object.filledVolume.high >>> 0).toNumber(true);
                if (object.averagePrice != null)
                    if ($util.Long)
                        (message.averagePrice = $util.Long.fromValue(object.averagePrice)).unsigned = true;
                    else if (typeof object.averagePrice === "string")
                        message.averagePrice = parseInt(object.averagePrice, 10);
                    else if (typeof object.averagePrice === "number")
                        message.averagePrice = object.averagePrice;
                    else if (typeof object.averagePrice === "object")
                        message.averagePrice = new $util.LongBits(object.averagePrice.low >>> 0, object.averagePrice.high >>> 0).toNumber(true);
                switch (object.reasonCode) {
                case "ORC_NONE":
                case 0:
                    message.reasonCode = 0;
                    break;
                case "ORC_SERVER_ERROR":
                case 1:
                    message.reasonCode = 1;
                    break;
                case "ORC_NON_TRADING_TIME":
                case 2:
                    message.reasonCode = 2;
                    break;
                case "ORC_ORDER_EXPIRED":
                case 3:
                    message.reasonCode = 3;
                    break;
                case "ORC_NOT_ENOUGH_MARGIN":
                case 4:
                    message.reasonCode = 4;
                    break;
                case "ORC_NOT_ENOUGH_DEPTH":
                case 5:
                    message.reasonCode = 5;
                    break;
                case "ORC_INVALID_PRICE":
                case 6:
                    message.reasonCode = 6;
                    break;
                case "ORC_INVALID_STOP_PRICE":
                case 7:
                    message.reasonCode = 7;
                    break;
                case "ORC_INVALID_VOLUME":
                case 8:
                    message.reasonCode = 8;
                    break;
                case "ORC_REJECT":
                case 9:
                    message.reasonCode = 9;
                    break;
                case "ORC_NO_QUOTES":
                case 10:
                    message.reasonCode = 10;
                    break;
                case "ORC_INVALID_SYMBOL":
                case 11:
                    message.reasonCode = 11;
                    break;
                case "ORC_CURRENCY_CONVERSION_ERROR":
                case 12:
                    message.reasonCode = 12;
                    break;
                case "ORC_INVALID_EXPIRATION_DATE":
                case 13:
                    message.reasonCode = 13;
                    break;
                case "ORC_INVALID_REQUEST":
                case 14:
                    message.reasonCode = 14;
                    break;
                case "ORC_CANCEL":
                case 15:
                    message.reasonCode = 15;
                    break;
                case "ORC_SYMBOL_NOT_SUPPORT_TRADING_TYPE":
                case 16:
                    message.reasonCode = 16;
                    break;
                }
                if (object.profitLoss != null)
                    if ($util.Long)
                        (message.profitLoss = $util.Long.fromValue(object.profitLoss)).unsigned = false;
                    else if (typeof object.profitLoss === "string")
                        message.profitLoss = parseInt(object.profitLoss, 10);
                    else if (typeof object.profitLoss === "number")
                        message.profitLoss = object.profitLoss;
                    else if (typeof object.profitLoss === "object")
                        message.profitLoss = new $util.LongBits(object.profitLoss.low >>> 0, object.profitLoss.high >>> 0).toNumber();
                if (object.commission != null)
                    if ($util.Long)
                        (message.commission = $util.Long.fromValue(object.commission)).unsigned = false;
                    else if (typeof object.commission === "string")
                        message.commission = parseInt(object.commission, 10);
                    else if (typeof object.commission === "number")
                        message.commission = object.commission;
                    else if (typeof object.commission === "object")
                        message.commission = new $util.LongBits(object.commission.low >>> 0, object.commission.high >>> 0).toNumber();
                if (object.swap != null)
                    if ($util.Long)
                        (message.swap = $util.Long.fromValue(object.swap)).unsigned = false;
                    else if (typeof object.swap === "string")
                        message.swap = parseInt(object.swap, 10);
                    else if (typeof object.swap === "number")
                        message.swap = object.swap;
                    else if (typeof object.swap === "object")
                        message.swap = new $util.LongBits(object.swap.low >>> 0, object.swap.high >>> 0).toNumber();
                if (object.insuranceFee != null)
                    if ($util.Long)
                        (message.insuranceFee = $util.Long.fromValue(object.insuranceFee)).unsigned = false;
                    else if (typeof object.insuranceFee === "string")
                        message.insuranceFee = parseInt(object.insuranceFee, 10);
                    else if (typeof object.insuranceFee === "number")
                        message.insuranceFee = object.insuranceFee;
                    else if (typeof object.insuranceFee === "object")
                        message.insuranceFee = new $util.LongBits(object.insuranceFee.low >>> 0, object.insuranceFee.high >>> 0).toNumber();
                if (object.insurancePayout != null)
                    if ($util.Long)
                        (message.insurancePayout = $util.Long.fromValue(object.insurancePayout)).unsigned = false;
                    else if (typeof object.insurancePayout === "string")
                        message.insurancePayout = parseInt(object.insurancePayout, 10);
                    else if (typeof object.insurancePayout === "number")
                        message.insurancePayout = object.insurancePayout;
                    else if (typeof object.insurancePayout === "object")
                        message.insurancePayout = new $util.LongBits(object.insurancePayout.low >>> 0, object.insurancePayout.high >>> 0).toNumber();
                if (object.insuranceConditionId != null)
                    if ($util.Long)
                        (message.insuranceConditionId = $util.Long.fromValue(object.insuranceConditionId)).unsigned = true;
                    else if (typeof object.insuranceConditionId === "string")
                        message.insuranceConditionId = parseInt(object.insuranceConditionId, 10);
                    else if (typeof object.insuranceConditionId === "number")
                        message.insuranceConditionId = object.insuranceConditionId;
                    else if (typeof object.insuranceConditionId === "object")
                        message.insuranceConditionId = new $util.LongBits(object.insuranceConditionId.low >>> 0, object.insuranceConditionId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an Order message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.Order
             * @static
             * @param {feedbox.trade_api.Order} message Order
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Order.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.lpName = [];
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    object.symbol = "";
                    object.side = options.enums === String ? "BUY" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.volume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.volume = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.price = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.deviation = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.deviation = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.stopPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.stopPrice = options.longs === String ? "0" : 0;
                    object.type = options.enums === String ? "O_MARKET" : 0;
                    object.timeInForce = options.enums === String ? "FOK" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.requestTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.requestTime = options.longs === String ? "0" : 0;
                    object.orderStatus = options.enums === String ? "O_NEW" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.filledVolume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.filledVolume = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.averagePrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.averagePrice = options.longs === String ? "0" : 0;
                    object.reasonCode = options.enums === String ? "ORC_NONE" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.profitLoss = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.profitLoss = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.commission = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.commission = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.swap = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.swap = options.longs === String ? "0" : 0;
                    object.clientId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.expirationDatetime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.expirationDatetime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.positionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positionId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.slPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.slPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.tpPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tpPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.insuranceConditionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insuranceConditionId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.insuranceFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insuranceFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.insurancePayout = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insurancePayout = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.side != null && message.hasOwnProperty("side"))
                    object.side = options.enums === String ? $root.feedbox.trade_api.Side[message.side] : message.side;
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (typeof message.volume === "number")
                        object.volume = options.longs === String ? String(message.volume) : message.volume;
                    else
                        object.volume = options.longs === String ? $util.Long.prototype.toString.call(message.volume) : options.longs === Number ? new $util.LongBits(message.volume.low >>> 0, message.volume.high >>> 0).toNumber(true) : message.volume;
                if (message.price != null && message.hasOwnProperty("price"))
                    if (typeof message.price === "number")
                        object.price = options.longs === String ? String(message.price) : message.price;
                    else
                        object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                if (message.deviation != null && message.hasOwnProperty("deviation"))
                    if (typeof message.deviation === "number")
                        object.deviation = options.longs === String ? String(message.deviation) : message.deviation;
                    else
                        object.deviation = options.longs === String ? $util.Long.prototype.toString.call(message.deviation) : options.longs === Number ? new $util.LongBits(message.deviation.low >>> 0, message.deviation.high >>> 0).toNumber(true) : message.deviation;
                if (message.stopPrice != null && message.hasOwnProperty("stopPrice"))
                    if (typeof message.stopPrice === "number")
                        object.stopPrice = options.longs === String ? String(message.stopPrice) : message.stopPrice;
                    else
                        object.stopPrice = options.longs === String ? $util.Long.prototype.toString.call(message.stopPrice) : options.longs === Number ? new $util.LongBits(message.stopPrice.low >>> 0, message.stopPrice.high >>> 0).toNumber(true) : message.stopPrice;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.feedbox.trade_api.OrderType[message.type] : message.type;
                if (message.timeInForce != null && message.hasOwnProperty("timeInForce"))
                    object.timeInForce = options.enums === String ? $root.feedbox.trade_api.OrderTimeInForce[message.timeInForce] : message.timeInForce;
                if (message.lpName && message.lpName.length) {
                    object.lpName = [];
                    for (var j = 0; j < message.lpName.length; ++j)
                        object.lpName[j] = message.lpName[j];
                }
                if (message.requestTime != null && message.hasOwnProperty("requestTime"))
                    if (typeof message.requestTime === "number")
                        object.requestTime = options.longs === String ? String(message.requestTime) : message.requestTime;
                    else
                        object.requestTime = options.longs === String ? $util.Long.prototype.toString.call(message.requestTime) : options.longs === Number ? new $util.LongBits(message.requestTime.low >>> 0, message.requestTime.high >>> 0).toNumber() : message.requestTime;
                if (message.orderStatus != null && message.hasOwnProperty("orderStatus"))
                    object.orderStatus = options.enums === String ? $root.feedbox.trade_api.OrderStatus[message.orderStatus] : message.orderStatus;
                if (message.filledVolume != null && message.hasOwnProperty("filledVolume"))
                    if (typeof message.filledVolume === "number")
                        object.filledVolume = options.longs === String ? String(message.filledVolume) : message.filledVolume;
                    else
                        object.filledVolume = options.longs === String ? $util.Long.prototype.toString.call(message.filledVolume) : options.longs === Number ? new $util.LongBits(message.filledVolume.low >>> 0, message.filledVolume.high >>> 0).toNumber(true) : message.filledVolume;
                if (message.averagePrice != null && message.hasOwnProperty("averagePrice"))
                    if (typeof message.averagePrice === "number")
                        object.averagePrice = options.longs === String ? String(message.averagePrice) : message.averagePrice;
                    else
                        object.averagePrice = options.longs === String ? $util.Long.prototype.toString.call(message.averagePrice) : options.longs === Number ? new $util.LongBits(message.averagePrice.low >>> 0, message.averagePrice.high >>> 0).toNumber(true) : message.averagePrice;
                if (message.reasonCode != null && message.hasOwnProperty("reasonCode"))
                    object.reasonCode = options.enums === String ? $root.feedbox.trade_api.OrderReasonCode[message.reasonCode] : message.reasonCode;
                if (message.profitLoss != null && message.hasOwnProperty("profitLoss"))
                    if (typeof message.profitLoss === "number")
                        object.profitLoss = options.longs === String ? String(message.profitLoss) : message.profitLoss;
                    else
                        object.profitLoss = options.longs === String ? $util.Long.prototype.toString.call(message.profitLoss) : options.longs === Number ? new $util.LongBits(message.profitLoss.low >>> 0, message.profitLoss.high >>> 0).toNumber() : message.profitLoss;
                if (message.commission != null && message.hasOwnProperty("commission"))
                    if (typeof message.commission === "number")
                        object.commission = options.longs === String ? String(message.commission) : message.commission;
                    else
                        object.commission = options.longs === String ? $util.Long.prototype.toString.call(message.commission) : options.longs === Number ? new $util.LongBits(message.commission.low >>> 0, message.commission.high >>> 0).toNumber() : message.commission;
                if (message.swap != null && message.hasOwnProperty("swap"))
                    if (typeof message.swap === "number")
                        object.swap = options.longs === String ? String(message.swap) : message.swap;
                    else
                        object.swap = options.longs === String ? $util.Long.prototype.toString.call(message.swap) : options.longs === Number ? new $util.LongBits(message.swap.low >>> 0, message.swap.high >>> 0).toNumber() : message.swap;
                if (message.clientId != null && message.hasOwnProperty("clientId"))
                    object.clientId = message.clientId;
                if (message.expirationDatetime != null && message.hasOwnProperty("expirationDatetime"))
                    if (typeof message.expirationDatetime === "number")
                        object.expirationDatetime = options.longs === String ? String(message.expirationDatetime) : message.expirationDatetime;
                    else
                        object.expirationDatetime = options.longs === String ? $util.Long.prototype.toString.call(message.expirationDatetime) : options.longs === Number ? new $util.LongBits(message.expirationDatetime.low >>> 0, message.expirationDatetime.high >>> 0).toNumber() : message.expirationDatetime;
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    if (typeof message.positionId === "number")
                        object.positionId = options.longs === String ? String(message.positionId) : message.positionId;
                    else
                        object.positionId = options.longs === String ? $util.Long.prototype.toString.call(message.positionId) : options.longs === Number ? new $util.LongBits(message.positionId.low >>> 0, message.positionId.high >>> 0).toNumber(true) : message.positionId;
                if (message.slPrice != null && message.hasOwnProperty("slPrice"))
                    if (typeof message.slPrice === "number")
                        object.slPrice = options.longs === String ? String(message.slPrice) : message.slPrice;
                    else
                        object.slPrice = options.longs === String ? $util.Long.prototype.toString.call(message.slPrice) : options.longs === Number ? new $util.LongBits(message.slPrice.low >>> 0, message.slPrice.high >>> 0).toNumber(true) : message.slPrice;
                if (message.tpPrice != null && message.hasOwnProperty("tpPrice"))
                    if (typeof message.tpPrice === "number")
                        object.tpPrice = options.longs === String ? String(message.tpPrice) : message.tpPrice;
                    else
                        object.tpPrice = options.longs === String ? $util.Long.prototype.toString.call(message.tpPrice) : options.longs === Number ? new $util.LongBits(message.tpPrice.low >>> 0, message.tpPrice.high >>> 0).toNumber(true) : message.tpPrice;
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (typeof message.insuranceConditionId === "number")
                        object.insuranceConditionId = options.longs === String ? String(message.insuranceConditionId) : message.insuranceConditionId;
                    else
                        object.insuranceConditionId = options.longs === String ? $util.Long.prototype.toString.call(message.insuranceConditionId) : options.longs === Number ? new $util.LongBits(message.insuranceConditionId.low >>> 0, message.insuranceConditionId.high >>> 0).toNumber(true) : message.insuranceConditionId;
                if (message.insuranceFee != null && message.hasOwnProperty("insuranceFee"))
                    if (typeof message.insuranceFee === "number")
                        object.insuranceFee = options.longs === String ? String(message.insuranceFee) : message.insuranceFee;
                    else
                        object.insuranceFee = options.longs === String ? $util.Long.prototype.toString.call(message.insuranceFee) : options.longs === Number ? new $util.LongBits(message.insuranceFee.low >>> 0, message.insuranceFee.high >>> 0).toNumber() : message.insuranceFee;
                if (message.insurancePayout != null && message.hasOwnProperty("insurancePayout"))
                    if (typeof message.insurancePayout === "number")
                        object.insurancePayout = options.longs === String ? String(message.insurancePayout) : message.insurancePayout;
                    else
                        object.insurancePayout = options.longs === String ? $util.Long.prototype.toString.call(message.insurancePayout) : options.longs === Number ? new $util.LongBits(message.insurancePayout.low >>> 0, message.insurancePayout.high >>> 0).toNumber() : message.insurancePayout;
                return object;
            };

            /**
             * Converts this Order to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.Order
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Order.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Order;
        })();

        trade_api.OrderList = (function() {

            /**
             * Properties of an OrderList.
             * @memberof feedbox.trade_api
             * @interface IOrderList
             * @property {Array.<feedbox.trade_api.IOrder>|null} [order] OrderList order
             */

            /**
             * Constructs a new OrderList.
             * @memberof feedbox.trade_api
             * @classdesc Represents an OrderList.
             * @implements IOrderList
             * @constructor
             * @param {feedbox.trade_api.IOrderList=} [properties] Properties to set
             */
            function OrderList(properties) {
                this.order = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderList order.
             * @member {Array.<feedbox.trade_api.IOrder>} order
             * @memberof feedbox.trade_api.OrderList
             * @instance
             */
            OrderList.prototype.order = $util.emptyArray;

            /**
             * Creates a new OrderList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.OrderList
             * @static
             * @param {feedbox.trade_api.IOrderList=} [properties] Properties to set
             * @returns {feedbox.trade_api.OrderList} OrderList instance
             */
            OrderList.create = function create(properties) {
                return new OrderList(properties);
            };

            /**
             * Encodes the specified OrderList message. Does not implicitly {@link feedbox.trade_api.OrderList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.OrderList
             * @static
             * @param {feedbox.trade_api.IOrderList} message OrderList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order != null && message.order.length)
                    for (var i = 0; i < message.order.length; ++i)
                        $root.feedbox.trade_api.Order.encode(message.order[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OrderList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.OrderList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.OrderList} OrderList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.OrderList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.order && message.order.length))
                            message.order = [];
                        message.order.push($root.feedbox.trade_api.Order.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OrderList message.
             * @function verify
             * @memberof feedbox.trade_api.OrderList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.order != null && message.hasOwnProperty("order")) {
                    if (!Array.isArray(message.order))
                        return "order: array expected";
                    for (var i = 0; i < message.order.length; ++i) {
                        var error = $root.feedbox.trade_api.Order.verify(message.order[i]);
                        if (error)
                            return "order." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OrderList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.OrderList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.OrderList} OrderList
             */
            OrderList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.OrderList)
                    return object;
                var message = new $root.feedbox.trade_api.OrderList();
                if (object.order) {
                    if (!Array.isArray(object.order))
                        throw TypeError(".feedbox.trade_api.OrderList.order: array expected");
                    message.order = [];
                    for (var i = 0; i < object.order.length; ++i) {
                        if (typeof object.order[i] !== "object")
                            throw TypeError(".feedbox.trade_api.OrderList.order: object expected");
                        message.order[i] = $root.feedbox.trade_api.Order.fromObject(object.order[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.OrderList
             * @static
             * @param {feedbox.trade_api.OrderList} message OrderList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.order = [];
                if (message.order && message.order.length) {
                    object.order = [];
                    for (var j = 0; j < message.order.length; ++j)
                        object.order[j] = $root.feedbox.trade_api.Order.toObject(message.order[j], options);
                }
                return object;
            };

            /**
             * Converts this OrderList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.OrderList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderList;
        })();

        trade_api.GetPositionList = (function() {

            /**
             * Properties of a GetPositionList.
             * @memberof feedbox.trade_api
             * @interface IGetPositionList
             */

            /**
             * Constructs a new GetPositionList.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetPositionList.
             * @implements IGetPositionList
             * @constructor
             * @param {feedbox.trade_api.IGetPositionList=} [properties] Properties to set
             */
            function GetPositionList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetPositionList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetPositionList
             * @static
             * @param {feedbox.trade_api.IGetPositionList=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetPositionList} GetPositionList instance
             */
            GetPositionList.create = function create(properties) {
                return new GetPositionList(properties);
            };

            /**
             * Encodes the specified GetPositionList message. Does not implicitly {@link feedbox.trade_api.GetPositionList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetPositionList
             * @static
             * @param {feedbox.trade_api.IGetPositionList} message GetPositionList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPositionList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetPositionList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetPositionList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetPositionList} GetPositionList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPositionList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetPositionList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetPositionList message.
             * @function verify
             * @memberof feedbox.trade_api.GetPositionList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetPositionList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetPositionList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetPositionList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetPositionList} GetPositionList
             */
            GetPositionList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetPositionList)
                    return object;
                return new $root.feedbox.trade_api.GetPositionList();
            };

            /**
             * Creates a plain object from a GetPositionList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetPositionList
             * @static
             * @param {feedbox.trade_api.GetPositionList} message GetPositionList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetPositionList.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetPositionList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetPositionList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetPositionList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetPositionList;
        })();

        trade_api.Position = (function() {

            /**
             * Properties of a Position.
             * @memberof feedbox.trade_api
             * @interface IPosition
             * @property {number|Long|null} [positionId] Position positionId
             * @property {string|null} [symbol] Position symbol
             * @property {feedbox.trade_api.Side|null} [side] Position side
             * @property {number|Long|null} [volume] Position volume
             * @property {number|Long|null} [price] Position price
             * @property {number|Long|null} [swap] Position swap
             * @property {number|Long|null} [slPrice] Position slPrice
             * @property {number|Long|null} [tpPrice] Position tpPrice
             * @property {number|null} [insurancePayoutPercent] Position insurancePayoutPercent
             * @property {number|Long|null} [insuranceConditionId] Position insuranceConditionId
             */

            /**
             * Constructs a new Position.
             * @memberof feedbox.trade_api
             * @classdesc Represents a Position.
             * @implements IPosition
             * @constructor
             * @param {feedbox.trade_api.IPosition=} [properties] Properties to set
             */
            function Position(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Position positionId.
             * @member {number|Long} positionId
             * @memberof feedbox.trade_api.Position
             * @instance
             */
            Position.prototype.positionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Position symbol.
             * @member {string} symbol
             * @memberof feedbox.trade_api.Position
             * @instance
             */
            Position.prototype.symbol = "";

            /**
             * Position side.
             * @member {feedbox.trade_api.Side} side
             * @memberof feedbox.trade_api.Position
             * @instance
             */
            Position.prototype.side = 0;

            /**
             * Position volume.
             * @member {number|Long} volume
             * @memberof feedbox.trade_api.Position
             * @instance
             */
            Position.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Position price.
             * @member {number|Long} price
             * @memberof feedbox.trade_api.Position
             * @instance
             */
            Position.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Position swap.
             * @member {number|Long} swap
             * @memberof feedbox.trade_api.Position
             * @instance
             */
            Position.prototype.swap = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Position slPrice.
             * @member {number|Long} slPrice
             * @memberof feedbox.trade_api.Position
             * @instance
             */
            Position.prototype.slPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Position tpPrice.
             * @member {number|Long} tpPrice
             * @memberof feedbox.trade_api.Position
             * @instance
             */
            Position.prototype.tpPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Position insurancePayoutPercent.
             * @member {number} insurancePayoutPercent
             * @memberof feedbox.trade_api.Position
             * @instance
             */
            Position.prototype.insurancePayoutPercent = 0;

            /**
             * Position insuranceConditionId.
             * @member {number|Long} insuranceConditionId
             * @memberof feedbox.trade_api.Position
             * @instance
             */
            Position.prototype.insuranceConditionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new Position instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.Position
             * @static
             * @param {feedbox.trade_api.IPosition=} [properties] Properties to set
             * @returns {feedbox.trade_api.Position} Position instance
             */
            Position.create = function create(properties) {
                return new Position(properties);
            };

            /**
             * Encodes the specified Position message. Does not implicitly {@link feedbox.trade_api.Position.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.Position
             * @static
             * @param {feedbox.trade_api.IPosition} message Position message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Position.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbol);
                if (message.side != null && Object.hasOwnProperty.call(message, "side"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.side);
                if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.volume);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.price);
                if (message.positionId != null && Object.hasOwnProperty.call(message, "positionId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.positionId);
                if (message.swap != null && Object.hasOwnProperty.call(message, "swap"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.swap);
                if (message.slPrice != null && Object.hasOwnProperty.call(message, "slPrice"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.slPrice);
                if (message.tpPrice != null && Object.hasOwnProperty.call(message, "tpPrice"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.tpPrice);
                if (message.insurancePayoutPercent != null && Object.hasOwnProperty.call(message, "insurancePayoutPercent"))
                    writer.uint32(/* id 9, wireType 1 =*/73).double(message.insurancePayoutPercent);
                if (message.insuranceConditionId != null && Object.hasOwnProperty.call(message, "insuranceConditionId"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.insuranceConditionId);
                return writer;
            };

            /**
             * Decodes a Position message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.Position
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.Position} Position
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Position.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.Position();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 5:
                        message.positionId = reader.uint64();
                        break;
                    case 1:
                        message.symbol = reader.string();
                        break;
                    case 2:
                        message.side = reader.int32();
                        break;
                    case 3:
                        message.volume = reader.uint64();
                        break;
                    case 4:
                        message.price = reader.uint64();
                        break;
                    case 6:
                        message.swap = reader.int64();
                        break;
                    case 7:
                        message.slPrice = reader.uint64();
                        break;
                    case 8:
                        message.tpPrice = reader.uint64();
                        break;
                    case 9:
                        message.insurancePayoutPercent = reader.double();
                        break;
                    case 10:
                        message.insuranceConditionId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Position message.
             * @function verify
             * @memberof feedbox.trade_api.Position
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Position.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    if (!$util.isInteger(message.positionId) && !(message.positionId && $util.isInteger(message.positionId.low) && $util.isInteger(message.positionId.high)))
                        return "positionId: integer|Long expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.side != null && message.hasOwnProperty("side"))
                    switch (message.side) {
                    default:
                        return "side: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (!$util.isInteger(message.volume) && !(message.volume && $util.isInteger(message.volume.low) && $util.isInteger(message.volume.high)))
                        return "volume: integer|Long expected";
                if (message.price != null && message.hasOwnProperty("price"))
                    if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                        return "price: integer|Long expected";
                if (message.swap != null && message.hasOwnProperty("swap"))
                    if (!$util.isInteger(message.swap) && !(message.swap && $util.isInteger(message.swap.low) && $util.isInteger(message.swap.high)))
                        return "swap: integer|Long expected";
                if (message.slPrice != null && message.hasOwnProperty("slPrice"))
                    if (!$util.isInteger(message.slPrice) && !(message.slPrice && $util.isInteger(message.slPrice.low) && $util.isInteger(message.slPrice.high)))
                        return "slPrice: integer|Long expected";
                if (message.tpPrice != null && message.hasOwnProperty("tpPrice"))
                    if (!$util.isInteger(message.tpPrice) && !(message.tpPrice && $util.isInteger(message.tpPrice.low) && $util.isInteger(message.tpPrice.high)))
                        return "tpPrice: integer|Long expected";
                if (message.insurancePayoutPercent != null && message.hasOwnProperty("insurancePayoutPercent"))
                    if (typeof message.insurancePayoutPercent !== "number")
                        return "insurancePayoutPercent: number expected";
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (!$util.isInteger(message.insuranceConditionId) && !(message.insuranceConditionId && $util.isInteger(message.insuranceConditionId.low) && $util.isInteger(message.insuranceConditionId.high)))
                        return "insuranceConditionId: integer|Long expected";
                return null;
            };

            /**
             * Creates a Position message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.Position
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.Position} Position
             */
            Position.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.Position)
                    return object;
                var message = new $root.feedbox.trade_api.Position();
                if (object.positionId != null)
                    if ($util.Long)
                        (message.positionId = $util.Long.fromValue(object.positionId)).unsigned = true;
                    else if (typeof object.positionId === "string")
                        message.positionId = parseInt(object.positionId, 10);
                    else if (typeof object.positionId === "number")
                        message.positionId = object.positionId;
                    else if (typeof object.positionId === "object")
                        message.positionId = new $util.LongBits(object.positionId.low >>> 0, object.positionId.high >>> 0).toNumber(true);
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                switch (object.side) {
                case "BUY":
                case 0:
                    message.side = 0;
                    break;
                case "SELL":
                case 1:
                    message.side = 1;
                    break;
                }
                if (object.volume != null)
                    if ($util.Long)
                        (message.volume = $util.Long.fromValue(object.volume)).unsigned = true;
                    else if (typeof object.volume === "string")
                        message.volume = parseInt(object.volume, 10);
                    else if (typeof object.volume === "number")
                        message.volume = object.volume;
                    else if (typeof object.volume === "object")
                        message.volume = new $util.LongBits(object.volume.low >>> 0, object.volume.high >>> 0).toNumber(true);
                if (object.price != null)
                    if ($util.Long)
                        (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                    else if (typeof object.price === "string")
                        message.price = parseInt(object.price, 10);
                    else if (typeof object.price === "number")
                        message.price = object.price;
                    else if (typeof object.price === "object")
                        message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                if (object.swap != null)
                    if ($util.Long)
                        (message.swap = $util.Long.fromValue(object.swap)).unsigned = false;
                    else if (typeof object.swap === "string")
                        message.swap = parseInt(object.swap, 10);
                    else if (typeof object.swap === "number")
                        message.swap = object.swap;
                    else if (typeof object.swap === "object")
                        message.swap = new $util.LongBits(object.swap.low >>> 0, object.swap.high >>> 0).toNumber();
                if (object.slPrice != null)
                    if ($util.Long)
                        (message.slPrice = $util.Long.fromValue(object.slPrice)).unsigned = true;
                    else if (typeof object.slPrice === "string")
                        message.slPrice = parseInt(object.slPrice, 10);
                    else if (typeof object.slPrice === "number")
                        message.slPrice = object.slPrice;
                    else if (typeof object.slPrice === "object")
                        message.slPrice = new $util.LongBits(object.slPrice.low >>> 0, object.slPrice.high >>> 0).toNumber(true);
                if (object.tpPrice != null)
                    if ($util.Long)
                        (message.tpPrice = $util.Long.fromValue(object.tpPrice)).unsigned = true;
                    else if (typeof object.tpPrice === "string")
                        message.tpPrice = parseInt(object.tpPrice, 10);
                    else if (typeof object.tpPrice === "number")
                        message.tpPrice = object.tpPrice;
                    else if (typeof object.tpPrice === "object")
                        message.tpPrice = new $util.LongBits(object.tpPrice.low >>> 0, object.tpPrice.high >>> 0).toNumber(true);
                if (object.insurancePayoutPercent != null)
                    message.insurancePayoutPercent = Number(object.insurancePayoutPercent);
                if (object.insuranceConditionId != null)
                    if ($util.Long)
                        (message.insuranceConditionId = $util.Long.fromValue(object.insuranceConditionId)).unsigned = true;
                    else if (typeof object.insuranceConditionId === "string")
                        message.insuranceConditionId = parseInt(object.insuranceConditionId, 10);
                    else if (typeof object.insuranceConditionId === "number")
                        message.insuranceConditionId = object.insuranceConditionId;
                    else if (typeof object.insuranceConditionId === "object")
                        message.insuranceConditionId = new $util.LongBits(object.insuranceConditionId.low >>> 0, object.insuranceConditionId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a Position message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.Position
             * @static
             * @param {feedbox.trade_api.Position} message Position
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Position.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.symbol = "";
                    object.side = options.enums === String ? "BUY" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.volume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.volume = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.price = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.positionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positionId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.swap = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.swap = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.slPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.slPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.tpPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tpPrice = options.longs === String ? "0" : 0;
                    object.insurancePayoutPercent = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.insuranceConditionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insuranceConditionId = options.longs === String ? "0" : 0;
                }
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.side != null && message.hasOwnProperty("side"))
                    object.side = options.enums === String ? $root.feedbox.trade_api.Side[message.side] : message.side;
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (typeof message.volume === "number")
                        object.volume = options.longs === String ? String(message.volume) : message.volume;
                    else
                        object.volume = options.longs === String ? $util.Long.prototype.toString.call(message.volume) : options.longs === Number ? new $util.LongBits(message.volume.low >>> 0, message.volume.high >>> 0).toNumber(true) : message.volume;
                if (message.price != null && message.hasOwnProperty("price"))
                    if (typeof message.price === "number")
                        object.price = options.longs === String ? String(message.price) : message.price;
                    else
                        object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    if (typeof message.positionId === "number")
                        object.positionId = options.longs === String ? String(message.positionId) : message.positionId;
                    else
                        object.positionId = options.longs === String ? $util.Long.prototype.toString.call(message.positionId) : options.longs === Number ? new $util.LongBits(message.positionId.low >>> 0, message.positionId.high >>> 0).toNumber(true) : message.positionId;
                if (message.swap != null && message.hasOwnProperty("swap"))
                    if (typeof message.swap === "number")
                        object.swap = options.longs === String ? String(message.swap) : message.swap;
                    else
                        object.swap = options.longs === String ? $util.Long.prototype.toString.call(message.swap) : options.longs === Number ? new $util.LongBits(message.swap.low >>> 0, message.swap.high >>> 0).toNumber() : message.swap;
                if (message.slPrice != null && message.hasOwnProperty("slPrice"))
                    if (typeof message.slPrice === "number")
                        object.slPrice = options.longs === String ? String(message.slPrice) : message.slPrice;
                    else
                        object.slPrice = options.longs === String ? $util.Long.prototype.toString.call(message.slPrice) : options.longs === Number ? new $util.LongBits(message.slPrice.low >>> 0, message.slPrice.high >>> 0).toNumber(true) : message.slPrice;
                if (message.tpPrice != null && message.hasOwnProperty("tpPrice"))
                    if (typeof message.tpPrice === "number")
                        object.tpPrice = options.longs === String ? String(message.tpPrice) : message.tpPrice;
                    else
                        object.tpPrice = options.longs === String ? $util.Long.prototype.toString.call(message.tpPrice) : options.longs === Number ? new $util.LongBits(message.tpPrice.low >>> 0, message.tpPrice.high >>> 0).toNumber(true) : message.tpPrice;
                if (message.insurancePayoutPercent != null && message.hasOwnProperty("insurancePayoutPercent"))
                    object.insurancePayoutPercent = options.json && !isFinite(message.insurancePayoutPercent) ? String(message.insurancePayoutPercent) : message.insurancePayoutPercent;
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (typeof message.insuranceConditionId === "number")
                        object.insuranceConditionId = options.longs === String ? String(message.insuranceConditionId) : message.insuranceConditionId;
                    else
                        object.insuranceConditionId = options.longs === String ? $util.Long.prototype.toString.call(message.insuranceConditionId) : options.longs === Number ? new $util.LongBits(message.insuranceConditionId.low >>> 0, message.insuranceConditionId.high >>> 0).toNumber(true) : message.insuranceConditionId;
                return object;
            };

            /**
             * Converts this Position to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.Position
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Position.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Position;
        })();

        trade_api.PositionList = (function() {

            /**
             * Properties of a PositionList.
             * @memberof feedbox.trade_api
             * @interface IPositionList
             * @property {Array.<feedbox.trade_api.IPosition>|null} [position] PositionList position
             */

            /**
             * Constructs a new PositionList.
             * @memberof feedbox.trade_api
             * @classdesc Represents a PositionList.
             * @implements IPositionList
             * @constructor
             * @param {feedbox.trade_api.IPositionList=} [properties] Properties to set
             */
            function PositionList(properties) {
                this.position = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PositionList position.
             * @member {Array.<feedbox.trade_api.IPosition>} position
             * @memberof feedbox.trade_api.PositionList
             * @instance
             */
            PositionList.prototype.position = $util.emptyArray;

            /**
             * Creates a new PositionList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.PositionList
             * @static
             * @param {feedbox.trade_api.IPositionList=} [properties] Properties to set
             * @returns {feedbox.trade_api.PositionList} PositionList instance
             */
            PositionList.create = function create(properties) {
                return new PositionList(properties);
            };

            /**
             * Encodes the specified PositionList message. Does not implicitly {@link feedbox.trade_api.PositionList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.PositionList
             * @static
             * @param {feedbox.trade_api.IPositionList} message PositionList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PositionList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.position != null && message.position.length)
                    for (var i = 0; i < message.position.length; ++i)
                        $root.feedbox.trade_api.Position.encode(message.position[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a PositionList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.PositionList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.PositionList} PositionList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PositionList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.PositionList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.position && message.position.length))
                            message.position = [];
                        message.position.push($root.feedbox.trade_api.Position.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a PositionList message.
             * @function verify
             * @memberof feedbox.trade_api.PositionList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PositionList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.position != null && message.hasOwnProperty("position")) {
                    if (!Array.isArray(message.position))
                        return "position: array expected";
                    for (var i = 0; i < message.position.length; ++i) {
                        var error = $root.feedbox.trade_api.Position.verify(message.position[i]);
                        if (error)
                            return "position." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PositionList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.PositionList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.PositionList} PositionList
             */
            PositionList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.PositionList)
                    return object;
                var message = new $root.feedbox.trade_api.PositionList();
                if (object.position) {
                    if (!Array.isArray(object.position))
                        throw TypeError(".feedbox.trade_api.PositionList.position: array expected");
                    message.position = [];
                    for (var i = 0; i < object.position.length; ++i) {
                        if (typeof object.position[i] !== "object")
                            throw TypeError(".feedbox.trade_api.PositionList.position: object expected");
                        message.position[i] = $root.feedbox.trade_api.Position.fromObject(object.position[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PositionList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.PositionList
             * @static
             * @param {feedbox.trade_api.PositionList} message PositionList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PositionList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.position = [];
                if (message.position && message.position.length) {
                    object.position = [];
                    for (var j = 0; j < message.position.length; ++j)
                        object.position[j] = $root.feedbox.trade_api.Position.toObject(message.position[j], options);
                }
                return object;
            };

            /**
             * Converts this PositionList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.PositionList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PositionList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PositionList;
        })();

        trade_api.UpdatePosition = (function() {

            /**
             * Properties of an UpdatePosition.
             * @memberof feedbox.trade_api
             * @interface IUpdatePosition
             * @property {string|null} [symbol] UpdatePosition symbol
             * @property {number|Long|null} [positionId] UpdatePosition positionId
             * @property {number|Long|null} [slPrice] UpdatePosition slPrice
             * @property {number|Long|null} [tpPrice] UpdatePosition tpPrice
             */

            /**
             * Constructs a new UpdatePosition.
             * @memberof feedbox.trade_api
             * @classdesc Represents an UpdatePosition.
             * @implements IUpdatePosition
             * @constructor
             * @param {feedbox.trade_api.IUpdatePosition=} [properties] Properties to set
             */
            function UpdatePosition(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UpdatePosition symbol.
             * @member {string} symbol
             * @memberof feedbox.trade_api.UpdatePosition
             * @instance
             */
            UpdatePosition.prototype.symbol = "";

            /**
             * UpdatePosition positionId.
             * @member {number|Long} positionId
             * @memberof feedbox.trade_api.UpdatePosition
             * @instance
             */
            UpdatePosition.prototype.positionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdatePosition slPrice.
             * @member {number|Long} slPrice
             * @memberof feedbox.trade_api.UpdatePosition
             * @instance
             */
            UpdatePosition.prototype.slPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UpdatePosition tpPrice.
             * @member {number|Long} tpPrice
             * @memberof feedbox.trade_api.UpdatePosition
             * @instance
             */
            UpdatePosition.prototype.tpPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new UpdatePosition instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.UpdatePosition
             * @static
             * @param {feedbox.trade_api.IUpdatePosition=} [properties] Properties to set
             * @returns {feedbox.trade_api.UpdatePosition} UpdatePosition instance
             */
            UpdatePosition.create = function create(properties) {
                return new UpdatePosition(properties);
            };

            /**
             * Encodes the specified UpdatePosition message. Does not implicitly {@link feedbox.trade_api.UpdatePosition.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.UpdatePosition
             * @static
             * @param {feedbox.trade_api.IUpdatePosition} message UpdatePosition message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdatePosition.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbol);
                if (message.positionId != null && Object.hasOwnProperty.call(message, "positionId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.positionId);
                if (message.slPrice != null && Object.hasOwnProperty.call(message, "slPrice"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.slPrice);
                if (message.tpPrice != null && Object.hasOwnProperty.call(message, "tpPrice"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.tpPrice);
                return writer;
            };

            /**
             * Decodes an UpdatePosition message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.UpdatePosition
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.UpdatePosition} UpdatePosition
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdatePosition.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.UpdatePosition();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.symbol = reader.string();
                        break;
                    case 2:
                        message.positionId = reader.uint64();
                        break;
                    case 3:
                        message.slPrice = reader.uint64();
                        break;
                    case 4:
                        message.tpPrice = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UpdatePosition message.
             * @function verify
             * @memberof feedbox.trade_api.UpdatePosition
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdatePosition.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    if (!$util.isInteger(message.positionId) && !(message.positionId && $util.isInteger(message.positionId.low) && $util.isInteger(message.positionId.high)))
                        return "positionId: integer|Long expected";
                if (message.slPrice != null && message.hasOwnProperty("slPrice"))
                    if (!$util.isInteger(message.slPrice) && !(message.slPrice && $util.isInteger(message.slPrice.low) && $util.isInteger(message.slPrice.high)))
                        return "slPrice: integer|Long expected";
                if (message.tpPrice != null && message.hasOwnProperty("tpPrice"))
                    if (!$util.isInteger(message.tpPrice) && !(message.tpPrice && $util.isInteger(message.tpPrice.low) && $util.isInteger(message.tpPrice.high)))
                        return "tpPrice: integer|Long expected";
                return null;
            };

            /**
             * Creates an UpdatePosition message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.UpdatePosition
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.UpdatePosition} UpdatePosition
             */
            UpdatePosition.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.UpdatePosition)
                    return object;
                var message = new $root.feedbox.trade_api.UpdatePosition();
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                if (object.positionId != null)
                    if ($util.Long)
                        (message.positionId = $util.Long.fromValue(object.positionId)).unsigned = true;
                    else if (typeof object.positionId === "string")
                        message.positionId = parseInt(object.positionId, 10);
                    else if (typeof object.positionId === "number")
                        message.positionId = object.positionId;
                    else if (typeof object.positionId === "object")
                        message.positionId = new $util.LongBits(object.positionId.low >>> 0, object.positionId.high >>> 0).toNumber(true);
                if (object.slPrice != null)
                    if ($util.Long)
                        (message.slPrice = $util.Long.fromValue(object.slPrice)).unsigned = true;
                    else if (typeof object.slPrice === "string")
                        message.slPrice = parseInt(object.slPrice, 10);
                    else if (typeof object.slPrice === "number")
                        message.slPrice = object.slPrice;
                    else if (typeof object.slPrice === "object")
                        message.slPrice = new $util.LongBits(object.slPrice.low >>> 0, object.slPrice.high >>> 0).toNumber(true);
                if (object.tpPrice != null)
                    if ($util.Long)
                        (message.tpPrice = $util.Long.fromValue(object.tpPrice)).unsigned = true;
                    else if (typeof object.tpPrice === "string")
                        message.tpPrice = parseInt(object.tpPrice, 10);
                    else if (typeof object.tpPrice === "number")
                        message.tpPrice = object.tpPrice;
                    else if (typeof object.tpPrice === "object")
                        message.tpPrice = new $util.LongBits(object.tpPrice.low >>> 0, object.tpPrice.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from an UpdatePosition message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.UpdatePosition
             * @static
             * @param {feedbox.trade_api.UpdatePosition} message UpdatePosition
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdatePosition.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.symbol = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.positionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positionId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.slPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.slPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.tpPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.tpPrice = options.longs === String ? "0" : 0;
                }
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    if (typeof message.positionId === "number")
                        object.positionId = options.longs === String ? String(message.positionId) : message.positionId;
                    else
                        object.positionId = options.longs === String ? $util.Long.prototype.toString.call(message.positionId) : options.longs === Number ? new $util.LongBits(message.positionId.low >>> 0, message.positionId.high >>> 0).toNumber(true) : message.positionId;
                if (message.slPrice != null && message.hasOwnProperty("slPrice"))
                    if (typeof message.slPrice === "number")
                        object.slPrice = options.longs === String ? String(message.slPrice) : message.slPrice;
                    else
                        object.slPrice = options.longs === String ? $util.Long.prototype.toString.call(message.slPrice) : options.longs === Number ? new $util.LongBits(message.slPrice.low >>> 0, message.slPrice.high >>> 0).toNumber(true) : message.slPrice;
                if (message.tpPrice != null && message.hasOwnProperty("tpPrice"))
                    if (typeof message.tpPrice === "number")
                        object.tpPrice = options.longs === String ? String(message.tpPrice) : message.tpPrice;
                    else
                        object.tpPrice = options.longs === String ? $util.Long.prototype.toString.call(message.tpPrice) : options.longs === Number ? new $util.LongBits(message.tpPrice.low >>> 0, message.tpPrice.high >>> 0).toNumber(true) : message.tpPrice;
                return object;
            };

            /**
             * Converts this UpdatePosition to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.UpdatePosition
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdatePosition.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UpdatePosition;
        })();

        trade_api.Result = (function() {

            /**
             * Properties of a Result.
             * @memberof feedbox.trade_api
             * @interface IResult
             * @property {feedbox.trade_api.OrderReasonCode|null} [code] Result code
             * @property {string|null} [text] Result text
             */

            /**
             * Constructs a new Result.
             * @memberof feedbox.trade_api
             * @classdesc Represents a Result.
             * @implements IResult
             * @constructor
             * @param {feedbox.trade_api.IResult=} [properties] Properties to set
             */
            function Result(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Result code.
             * @member {feedbox.trade_api.OrderReasonCode} code
             * @memberof feedbox.trade_api.Result
             * @instance
             */
            Result.prototype.code = 0;

            /**
             * Result text.
             * @member {string} text
             * @memberof feedbox.trade_api.Result
             * @instance
             */
            Result.prototype.text = "";

            /**
             * Creates a new Result instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.Result
             * @static
             * @param {feedbox.trade_api.IResult=} [properties] Properties to set
             * @returns {feedbox.trade_api.Result} Result instance
             */
            Result.create = function create(properties) {
                return new Result(properties);
            };

            /**
             * Encodes the specified Result message. Does not implicitly {@link feedbox.trade_api.Result.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.Result
             * @static
             * @param {feedbox.trade_api.IResult} message Result message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Result.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.code);
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.text);
                return writer;
            };

            /**
             * Decodes a Result message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.Result
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.Result} Result
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Result.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.Result();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.code = reader.int32();
                        break;
                    case 2:
                        message.text = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Result message.
             * @function verify
             * @memberof feedbox.trade_api.Result
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Result.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    switch (message.code) {
                    default:
                        return "code: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                        break;
                    }
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                return null;
            };

            /**
             * Creates a Result message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.Result
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.Result} Result
             */
            Result.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.Result)
                    return object;
                var message = new $root.feedbox.trade_api.Result();
                switch (object.code) {
                case "ORC_NONE":
                case 0:
                    message.code = 0;
                    break;
                case "ORC_SERVER_ERROR":
                case 1:
                    message.code = 1;
                    break;
                case "ORC_NON_TRADING_TIME":
                case 2:
                    message.code = 2;
                    break;
                case "ORC_ORDER_EXPIRED":
                case 3:
                    message.code = 3;
                    break;
                case "ORC_NOT_ENOUGH_MARGIN":
                case 4:
                    message.code = 4;
                    break;
                case "ORC_NOT_ENOUGH_DEPTH":
                case 5:
                    message.code = 5;
                    break;
                case "ORC_INVALID_PRICE":
                case 6:
                    message.code = 6;
                    break;
                case "ORC_INVALID_STOP_PRICE":
                case 7:
                    message.code = 7;
                    break;
                case "ORC_INVALID_VOLUME":
                case 8:
                    message.code = 8;
                    break;
                case "ORC_REJECT":
                case 9:
                    message.code = 9;
                    break;
                case "ORC_NO_QUOTES":
                case 10:
                    message.code = 10;
                    break;
                case "ORC_INVALID_SYMBOL":
                case 11:
                    message.code = 11;
                    break;
                case "ORC_CURRENCY_CONVERSION_ERROR":
                case 12:
                    message.code = 12;
                    break;
                case "ORC_INVALID_EXPIRATION_DATE":
                case 13:
                    message.code = 13;
                    break;
                case "ORC_INVALID_REQUEST":
                case 14:
                    message.code = 14;
                    break;
                case "ORC_CANCEL":
                case 15:
                    message.code = 15;
                    break;
                case "ORC_SYMBOL_NOT_SUPPORT_TRADING_TYPE":
                case 16:
                    message.code = 16;
                    break;
                }
                if (object.text != null)
                    message.text = String(object.text);
                return message;
            };

            /**
             * Creates a plain object from a Result message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.Result
             * @static
             * @param {feedbox.trade_api.Result} message Result
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Result.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = options.enums === String ? "ORC_NONE" : 0;
                    object.text = "";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = options.enums === String ? $root.feedbox.trade_api.OrderReasonCode[message.code] : message.code;
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                return object;
            };

            /**
             * Converts this Result to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.Result
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Result.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Result;
        })();

        trade_api.GetCustomerInfo = (function() {

            /**
             * Properties of a GetCustomerInfo.
             * @memberof feedbox.trade_api
             * @interface IGetCustomerInfo
             */

            /**
             * Constructs a new GetCustomerInfo.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetCustomerInfo.
             * @implements IGetCustomerInfo
             * @constructor
             * @param {feedbox.trade_api.IGetCustomerInfo=} [properties] Properties to set
             */
            function GetCustomerInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCustomerInfo instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetCustomerInfo
             * @static
             * @param {feedbox.trade_api.IGetCustomerInfo=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetCustomerInfo} GetCustomerInfo instance
             */
            GetCustomerInfo.create = function create(properties) {
                return new GetCustomerInfo(properties);
            };

            /**
             * Encodes the specified GetCustomerInfo message. Does not implicitly {@link feedbox.trade_api.GetCustomerInfo.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetCustomerInfo
             * @static
             * @param {feedbox.trade_api.IGetCustomerInfo} message GetCustomerInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCustomerInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetCustomerInfo message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetCustomerInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetCustomerInfo} GetCustomerInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCustomerInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetCustomerInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCustomerInfo message.
             * @function verify
             * @memberof feedbox.trade_api.GetCustomerInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCustomerInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCustomerInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetCustomerInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetCustomerInfo} GetCustomerInfo
             */
            GetCustomerInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetCustomerInfo)
                    return object;
                return new $root.feedbox.trade_api.GetCustomerInfo();
            };

            /**
             * Creates a plain object from a GetCustomerInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetCustomerInfo
             * @static
             * @param {feedbox.trade_api.GetCustomerInfo} message GetCustomerInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCustomerInfo.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCustomerInfo to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetCustomerInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCustomerInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCustomerInfo;
        })();

        trade_api.CustomerInfo = (function() {

            /**
             * Properties of a CustomerInfo.
             * @memberof feedbox.trade_api
             * @interface ICustomerInfo
             * @property {string|null} [name] CustomerInfo name
             * @property {string|null} [depositCurrency] CustomerInfo depositCurrency
             * @property {number|null} [leverage] CustomerInfo leverage
             * @property {string|null} [parentName] CustomerInfo parentName
             * @property {number|null} [loginId] CustomerInfo loginId
             * @property {number|Long|null} [lastInsuranceConditionId] CustomerInfo lastInsuranceConditionId
             */

            /**
             * Constructs a new CustomerInfo.
             * @memberof feedbox.trade_api
             * @classdesc Represents a CustomerInfo.
             * @implements ICustomerInfo
             * @constructor
             * @param {feedbox.trade_api.ICustomerInfo=} [properties] Properties to set
             */
            function CustomerInfo(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CustomerInfo name.
             * @member {string} name
             * @memberof feedbox.trade_api.CustomerInfo
             * @instance
             */
            CustomerInfo.prototype.name = "";

            /**
             * CustomerInfo depositCurrency.
             * @member {string} depositCurrency
             * @memberof feedbox.trade_api.CustomerInfo
             * @instance
             */
            CustomerInfo.prototype.depositCurrency = "";

            /**
             * CustomerInfo leverage.
             * @member {number} leverage
             * @memberof feedbox.trade_api.CustomerInfo
             * @instance
             */
            CustomerInfo.prototype.leverage = 0;

            /**
             * CustomerInfo parentName.
             * @member {string} parentName
             * @memberof feedbox.trade_api.CustomerInfo
             * @instance
             */
            CustomerInfo.prototype.parentName = "";

            /**
             * CustomerInfo loginId.
             * @member {number} loginId
             * @memberof feedbox.trade_api.CustomerInfo
             * @instance
             */
            CustomerInfo.prototype.loginId = 0;

            /**
             * CustomerInfo lastInsuranceConditionId.
             * @member {number|Long} lastInsuranceConditionId
             * @memberof feedbox.trade_api.CustomerInfo
             * @instance
             */
            CustomerInfo.prototype.lastInsuranceConditionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new CustomerInfo instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.CustomerInfo
             * @static
             * @param {feedbox.trade_api.ICustomerInfo=} [properties] Properties to set
             * @returns {feedbox.trade_api.CustomerInfo} CustomerInfo instance
             */
            CustomerInfo.create = function create(properties) {
                return new CustomerInfo(properties);
            };

            /**
             * Encodes the specified CustomerInfo message. Does not implicitly {@link feedbox.trade_api.CustomerInfo.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.CustomerInfo
             * @static
             * @param {feedbox.trade_api.ICustomerInfo} message CustomerInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CustomerInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.depositCurrency != null && Object.hasOwnProperty.call(message, "depositCurrency"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.depositCurrency);
                if (message.leverage != null && Object.hasOwnProperty.call(message, "leverage"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.leverage);
                if (message.parentName != null && Object.hasOwnProperty.call(message, "parentName"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.parentName);
                if (message.loginId != null && Object.hasOwnProperty.call(message, "loginId"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.loginId);
                if (message.lastInsuranceConditionId != null && Object.hasOwnProperty.call(message, "lastInsuranceConditionId"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.lastInsuranceConditionId);
                return writer;
            };

            /**
             * Decodes a CustomerInfo message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.CustomerInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.CustomerInfo} CustomerInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CustomerInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.CustomerInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.name = reader.string();
                        break;
                    case 5:
                        message.depositCurrency = reader.string();
                        break;
                    case 6:
                        message.leverage = reader.uint32();
                        break;
                    case 8:
                        message.parentName = reader.string();
                        break;
                    case 9:
                        message.loginId = reader.uint32();
                        break;
                    case 10:
                        message.lastInsuranceConditionId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CustomerInfo message.
             * @function verify
             * @memberof feedbox.trade_api.CustomerInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CustomerInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.depositCurrency != null && message.hasOwnProperty("depositCurrency"))
                    if (!$util.isString(message.depositCurrency))
                        return "depositCurrency: string expected";
                if (message.leverage != null && message.hasOwnProperty("leverage"))
                    if (!$util.isInteger(message.leverage))
                        return "leverage: integer expected";
                if (message.parentName != null && message.hasOwnProperty("parentName"))
                    if (!$util.isString(message.parentName))
                        return "parentName: string expected";
                if (message.loginId != null && message.hasOwnProperty("loginId"))
                    if (!$util.isInteger(message.loginId))
                        return "loginId: integer expected";
                if (message.lastInsuranceConditionId != null && message.hasOwnProperty("lastInsuranceConditionId"))
                    if (!$util.isInteger(message.lastInsuranceConditionId) && !(message.lastInsuranceConditionId && $util.isInteger(message.lastInsuranceConditionId.low) && $util.isInteger(message.lastInsuranceConditionId.high)))
                        return "lastInsuranceConditionId: integer|Long expected";
                return null;
            };

            /**
             * Creates a CustomerInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.CustomerInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.CustomerInfo} CustomerInfo
             */
            CustomerInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.CustomerInfo)
                    return object;
                var message = new $root.feedbox.trade_api.CustomerInfo();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.depositCurrency != null)
                    message.depositCurrency = String(object.depositCurrency);
                if (object.leverage != null)
                    message.leverage = object.leverage >>> 0;
                if (object.parentName != null)
                    message.parentName = String(object.parentName);
                if (object.loginId != null)
                    message.loginId = object.loginId >>> 0;
                if (object.lastInsuranceConditionId != null)
                    if ($util.Long)
                        (message.lastInsuranceConditionId = $util.Long.fromValue(object.lastInsuranceConditionId)).unsigned = true;
                    else if (typeof object.lastInsuranceConditionId === "string")
                        message.lastInsuranceConditionId = parseInt(object.lastInsuranceConditionId, 10);
                    else if (typeof object.lastInsuranceConditionId === "number")
                        message.lastInsuranceConditionId = object.lastInsuranceConditionId;
                    else if (typeof object.lastInsuranceConditionId === "object")
                        message.lastInsuranceConditionId = new $util.LongBits(object.lastInsuranceConditionId.low >>> 0, object.lastInsuranceConditionId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a CustomerInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.CustomerInfo
             * @static
             * @param {feedbox.trade_api.CustomerInfo} message CustomerInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CustomerInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.depositCurrency = "";
                    object.leverage = 0;
                    object.parentName = "";
                    object.loginId = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.lastInsuranceConditionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.lastInsuranceConditionId = options.longs === String ? "0" : 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.depositCurrency != null && message.hasOwnProperty("depositCurrency"))
                    object.depositCurrency = message.depositCurrency;
                if (message.leverage != null && message.hasOwnProperty("leverage"))
                    object.leverage = message.leverage;
                if (message.parentName != null && message.hasOwnProperty("parentName"))
                    object.parentName = message.parentName;
                if (message.loginId != null && message.hasOwnProperty("loginId"))
                    object.loginId = message.loginId;
                if (message.lastInsuranceConditionId != null && message.hasOwnProperty("lastInsuranceConditionId"))
                    if (typeof message.lastInsuranceConditionId === "number")
                        object.lastInsuranceConditionId = options.longs === String ? String(message.lastInsuranceConditionId) : message.lastInsuranceConditionId;
                    else
                        object.lastInsuranceConditionId = options.longs === String ? $util.Long.prototype.toString.call(message.lastInsuranceConditionId) : options.longs === Number ? new $util.LongBits(message.lastInsuranceConditionId.low >>> 0, message.lastInsuranceConditionId.high >>> 0).toNumber(true) : message.lastInsuranceConditionId;
                return object;
            };

            /**
             * Converts this CustomerInfo to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.CustomerInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CustomerInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CustomerInfo;
        })();

        trade_api.GetBalancesByDate = (function() {

            /**
             * Properties of a GetBalancesByDate.
             * @memberof feedbox.trade_api
             * @interface IGetBalancesByDate
             * @property {number|Long|null} [fromTime] GetBalancesByDate fromTime
             * @property {number|Long|null} [toTime] GetBalancesByDate toTime
             * @property {number|null} [limit] GetBalancesByDate limit
             */

            /**
             * Constructs a new GetBalancesByDate.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetBalancesByDate.
             * @implements IGetBalancesByDate
             * @constructor
             * @param {feedbox.trade_api.IGetBalancesByDate=} [properties] Properties to set
             */
            function GetBalancesByDate(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetBalancesByDate fromTime.
             * @member {number|Long} fromTime
             * @memberof feedbox.trade_api.GetBalancesByDate
             * @instance
             */
            GetBalancesByDate.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetBalancesByDate toTime.
             * @member {number|Long} toTime
             * @memberof feedbox.trade_api.GetBalancesByDate
             * @instance
             */
            GetBalancesByDate.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * GetBalancesByDate limit.
             * @member {number} limit
             * @memberof feedbox.trade_api.GetBalancesByDate
             * @instance
             */
            GetBalancesByDate.prototype.limit = 0;

            /**
             * Creates a new GetBalancesByDate instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetBalancesByDate
             * @static
             * @param {feedbox.trade_api.IGetBalancesByDate=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetBalancesByDate} GetBalancesByDate instance
             */
            GetBalancesByDate.create = function create(properties) {
                return new GetBalancesByDate(properties);
            };

            /**
             * Encodes the specified GetBalancesByDate message. Does not implicitly {@link feedbox.trade_api.GetBalancesByDate.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetBalancesByDate
             * @static
             * @param {feedbox.trade_api.IGetBalancesByDate} message GetBalancesByDate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBalancesByDate.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fromTime);
                if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.toTime);
                if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.limit);
                return writer;
            };

            /**
             * Decodes a GetBalancesByDate message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetBalancesByDate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetBalancesByDate} GetBalancesByDate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBalancesByDate.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetBalancesByDate();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.fromTime = reader.int64();
                        break;
                    case 2:
                        message.toTime = reader.int64();
                        break;
                    case 3:
                        message.limit = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetBalancesByDate message.
             * @function verify
             * @memberof feedbox.trade_api.GetBalancesByDate
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetBalancesByDate.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                        return "fromTime: integer|Long expected";
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                        return "toTime: integer|Long expected";
                if (message.limit != null && message.hasOwnProperty("limit"))
                    if (!$util.isInteger(message.limit))
                        return "limit: integer expected";
                return null;
            };

            /**
             * Creates a GetBalancesByDate message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetBalancesByDate
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetBalancesByDate} GetBalancesByDate
             */
            GetBalancesByDate.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetBalancesByDate)
                    return object;
                var message = new $root.feedbox.trade_api.GetBalancesByDate();
                if (object.fromTime != null)
                    if ($util.Long)
                        (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                    else if (typeof object.fromTime === "string")
                        message.fromTime = parseInt(object.fromTime, 10);
                    else if (typeof object.fromTime === "number")
                        message.fromTime = object.fromTime;
                    else if (typeof object.fromTime === "object")
                        message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                if (object.toTime != null)
                    if ($util.Long)
                        (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                    else if (typeof object.toTime === "string")
                        message.toTime = parseInt(object.toTime, 10);
                    else if (typeof object.toTime === "number")
                        message.toTime = object.toTime;
                    else if (typeof object.toTime === "object")
                        message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                if (object.limit != null)
                    message.limit = object.limit >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a GetBalancesByDate message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetBalancesByDate
             * @static
             * @param {feedbox.trade_api.GetBalancesByDate} message GetBalancesByDate
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetBalancesByDate.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.fromTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.toTime = options.longs === String ? "0" : 0;
                    object.limit = 0;
                }
                if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                    if (typeof message.fromTime === "number")
                        object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                    else
                        object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                if (message.toTime != null && message.hasOwnProperty("toTime"))
                    if (typeof message.toTime === "number")
                        object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                    else
                        object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                if (message.limit != null && message.hasOwnProperty("limit"))
                    object.limit = message.limit;
                return object;
            };

            /**
             * Converts this GetBalancesByDate to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetBalancesByDate
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetBalancesByDate.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetBalancesByDate;
        })();

        trade_api.Balance = (function() {

            /**
             * Properties of a Balance.
             * @memberof feedbox.trade_api
             * @interface IBalance
             * @property {number|Long|null} [id] Balance id
             * @property {number|Long|null} [time] Balance time
             * @property {feedbox.trade_api.Balance.BalanceType|null} [type] Balance type
             * @property {number|Long|null} [amount] Balance amount
             * @property {string|null} [description] Balance description
             * @property {string|null} [currency] Balance currency
             * @property {number|Long|null} [executionId] Balance executionId
             */

            /**
             * Constructs a new Balance.
             * @memberof feedbox.trade_api
             * @classdesc Represents a Balance.
             * @implements IBalance
             * @constructor
             * @param {feedbox.trade_api.IBalance=} [properties] Properties to set
             */
            function Balance(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Balance id.
             * @member {number|Long} id
             * @memberof feedbox.trade_api.Balance
             * @instance
             */
            Balance.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Balance time.
             * @member {number|Long} time
             * @memberof feedbox.trade_api.Balance
             * @instance
             */
            Balance.prototype.time = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Balance type.
             * @member {feedbox.trade_api.Balance.BalanceType} type
             * @memberof feedbox.trade_api.Balance
             * @instance
             */
            Balance.prototype.type = 0;

            /**
             * Balance amount.
             * @member {number|Long} amount
             * @memberof feedbox.trade_api.Balance
             * @instance
             */
            Balance.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Balance description.
             * @member {string} description
             * @memberof feedbox.trade_api.Balance
             * @instance
             */
            Balance.prototype.description = "";

            /**
             * Balance currency.
             * @member {string} currency
             * @memberof feedbox.trade_api.Balance
             * @instance
             */
            Balance.prototype.currency = "";

            /**
             * Balance executionId.
             * @member {number|Long} executionId
             * @memberof feedbox.trade_api.Balance
             * @instance
             */
            Balance.prototype.executionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new Balance instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.Balance
             * @static
             * @param {feedbox.trade_api.IBalance=} [properties] Properties to set
             * @returns {feedbox.trade_api.Balance} Balance instance
             */
            Balance.create = function create(properties) {
                return new Balance(properties);
            };

            /**
             * Encodes the specified Balance message. Does not implicitly {@link feedbox.trade_api.Balance.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.Balance
             * @static
             * @param {feedbox.trade_api.IBalance} message Balance message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Balance.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.time);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.amount);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.description);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.currency);
                if (message.executionId != null && Object.hasOwnProperty.call(message, "executionId"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.executionId);
                return writer;
            };

            /**
             * Decodes a Balance message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.Balance
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.Balance} Balance
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Balance.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.Balance();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 3:
                        message.time = reader.int64();
                        break;
                    case 4:
                        message.type = reader.int32();
                        break;
                    case 5:
                        message.amount = reader.int64();
                        break;
                    case 6:
                        message.description = reader.string();
                        break;
                    case 7:
                        message.currency = reader.string();
                        break;
                    case 8:
                        message.executionId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Balance message.
             * @function verify
             * @memberof feedbox.trade_api.Balance
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Balance.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.time != null && message.hasOwnProperty("time"))
                    if (!$util.isInteger(message.time) && !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high)))
                        return "time: integer|Long expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        break;
                    }
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                        return "amount: integer|Long expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    if (!$util.isString(message.currency))
                        return "currency: string expected";
                if (message.executionId != null && message.hasOwnProperty("executionId"))
                    if (!$util.isInteger(message.executionId) && !(message.executionId && $util.isInteger(message.executionId.low) && $util.isInteger(message.executionId.high)))
                        return "executionId: integer|Long expected";
                return null;
            };

            /**
             * Creates a Balance message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.Balance
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.Balance} Balance
             */
            Balance.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.Balance)
                    return object;
                var message = new $root.feedbox.trade_api.Balance();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.time != null)
                    if ($util.Long)
                        (message.time = $util.Long.fromValue(object.time)).unsigned = false;
                    else if (typeof object.time === "string")
                        message.time = parseInt(object.time, 10);
                    else if (typeof object.time === "number")
                        message.time = object.time;
                    else if (typeof object.time === "object")
                        message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
                switch (object.type) {
                case "BALANCE":
                case 0:
                    message.type = 0;
                    break;
                case "COMMISSION_FROM_DEALS":
                case 1:
                    message.type = 1;
                    break;
                case "COMMISSION_FROM_ADMIN":
                case 2:
                    message.type = 2;
                    break;
                case "TRADE_RESULT":
                case 3:
                    message.type = 3;
                    break;
                case "CREDIT":
                case 4:
                    message.type = 4;
                    break;
                case "DIVIDEND":
                case 5:
                    message.type = 5;
                    break;
                case "SWAP":
                case 6:
                    message.type = 6;
                    break;
                case "INSURANCE_FEE":
                case 7:
                    message.type = 7;
                    break;
                case "INSURANCE_PAYOUT":
                case 8:
                    message.type = 8;
                    break;
                case "BONUS":
                case 9:
                    message.type = 9;
                    break;
                }
                if (object.amount != null)
                    if ($util.Long)
                        (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
                    else if (typeof object.amount === "string")
                        message.amount = parseInt(object.amount, 10);
                    else if (typeof object.amount === "number")
                        message.amount = object.amount;
                    else if (typeof object.amount === "object")
                        message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
                if (object.description != null)
                    message.description = String(object.description);
                if (object.currency != null)
                    message.currency = String(object.currency);
                if (object.executionId != null)
                    if ($util.Long)
                        (message.executionId = $util.Long.fromValue(object.executionId)).unsigned = true;
                    else if (typeof object.executionId === "string")
                        message.executionId = parseInt(object.executionId, 10);
                    else if (typeof object.executionId === "number")
                        message.executionId = object.executionId;
                    else if (typeof object.executionId === "object")
                        message.executionId = new $util.LongBits(object.executionId.low >>> 0, object.executionId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a Balance message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.Balance
             * @static
             * @param {feedbox.trade_api.Balance} message Balance
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Balance.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.time = options.longs === String ? "0" : 0;
                    object.type = options.enums === String ? "BALANCE" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.amount = options.longs === String ? "0" : 0;
                    object.description = "";
                    object.currency = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.executionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.executionId = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.time != null && message.hasOwnProperty("time"))
                    if (typeof message.time === "number")
                        object.time = options.longs === String ? String(message.time) : message.time;
                    else
                        object.time = options.longs === String ? $util.Long.prototype.toString.call(message.time) : options.longs === Number ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber() : message.time;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.feedbox.trade_api.Balance.BalanceType[message.type] : message.type;
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (typeof message.amount === "number")
                        object.amount = options.longs === String ? String(message.amount) : message.amount;
                    else
                        object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = message.currency;
                if (message.executionId != null && message.hasOwnProperty("executionId"))
                    if (typeof message.executionId === "number")
                        object.executionId = options.longs === String ? String(message.executionId) : message.executionId;
                    else
                        object.executionId = options.longs === String ? $util.Long.prototype.toString.call(message.executionId) : options.longs === Number ? new $util.LongBits(message.executionId.low >>> 0, message.executionId.high >>> 0).toNumber(true) : message.executionId;
                return object;
            };

            /**
             * Converts this Balance to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.Balance
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Balance.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * BalanceType enum.
             * @name feedbox.trade_api.Balance.BalanceType
             * @enum {number}
             * @property {number} BALANCE=0 BALANCE value
             * @property {number} COMMISSION_FROM_DEALS=1 COMMISSION_FROM_DEALS value
             * @property {number} COMMISSION_FROM_ADMIN=2 COMMISSION_FROM_ADMIN value
             * @property {number} TRADE_RESULT=3 TRADE_RESULT value
             * @property {number} CREDIT=4 CREDIT value
             * @property {number} DIVIDEND=5 DIVIDEND value
             * @property {number} SWAP=6 SWAP value
             * @property {number} INSURANCE_FEE=7 INSURANCE_FEE value
             * @property {number} INSURANCE_PAYOUT=8 INSURANCE_PAYOUT value
             * @property {number} BONUS=9 BONUS value
             */
            Balance.BalanceType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "BALANCE"] = 0;
                values[valuesById[1] = "COMMISSION_FROM_DEALS"] = 1;
                values[valuesById[2] = "COMMISSION_FROM_ADMIN"] = 2;
                values[valuesById[3] = "TRADE_RESULT"] = 3;
                values[valuesById[4] = "CREDIT"] = 4;
                values[valuesById[5] = "DIVIDEND"] = 5;
                values[valuesById[6] = "SWAP"] = 6;
                values[valuesById[7] = "INSURANCE_FEE"] = 7;
                values[valuesById[8] = "INSURANCE_PAYOUT"] = 8;
                values[valuesById[9] = "BONUS"] = 9;
                return values;
            })();

            return Balance;
        })();

        trade_api.Balances = (function() {

            /**
             * Properties of a Balances.
             * @memberof feedbox.trade_api
             * @interface IBalances
             * @property {Array.<feedbox.trade_api.IBalance>|null} [balances] Balances balances
             */

            /**
             * Constructs a new Balances.
             * @memberof feedbox.trade_api
             * @classdesc Represents a Balances.
             * @implements IBalances
             * @constructor
             * @param {feedbox.trade_api.IBalances=} [properties] Properties to set
             */
            function Balances(properties) {
                this.balances = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Balances balances.
             * @member {Array.<feedbox.trade_api.IBalance>} balances
             * @memberof feedbox.trade_api.Balances
             * @instance
             */
            Balances.prototype.balances = $util.emptyArray;

            /**
             * Creates a new Balances instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.Balances
             * @static
             * @param {feedbox.trade_api.IBalances=} [properties] Properties to set
             * @returns {feedbox.trade_api.Balances} Balances instance
             */
            Balances.create = function create(properties) {
                return new Balances(properties);
            };

            /**
             * Encodes the specified Balances message. Does not implicitly {@link feedbox.trade_api.Balances.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.Balances
             * @static
             * @param {feedbox.trade_api.IBalances} message Balances message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Balances.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.balances != null && message.balances.length)
                    for (var i = 0; i < message.balances.length; ++i)
                        $root.feedbox.trade_api.Balance.encode(message.balances[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Balances message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.Balances
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.Balances} Balances
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Balances.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.Balances();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.balances && message.balances.length))
                            message.balances = [];
                        message.balances.push($root.feedbox.trade_api.Balance.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Balances message.
             * @function verify
             * @memberof feedbox.trade_api.Balances
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Balances.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.balances != null && message.hasOwnProperty("balances")) {
                    if (!Array.isArray(message.balances))
                        return "balances: array expected";
                    for (var i = 0; i < message.balances.length; ++i) {
                        var error = $root.feedbox.trade_api.Balance.verify(message.balances[i]);
                        if (error)
                            return "balances." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a Balances message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.Balances
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.Balances} Balances
             */
            Balances.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.Balances)
                    return object;
                var message = new $root.feedbox.trade_api.Balances();
                if (object.balances) {
                    if (!Array.isArray(object.balances))
                        throw TypeError(".feedbox.trade_api.Balances.balances: array expected");
                    message.balances = [];
                    for (var i = 0; i < object.balances.length; ++i) {
                        if (typeof object.balances[i] !== "object")
                            throw TypeError(".feedbox.trade_api.Balances.balances: object expected");
                        message.balances[i] = $root.feedbox.trade_api.Balance.fromObject(object.balances[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a Balances message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.Balances
             * @static
             * @param {feedbox.trade_api.Balances} message Balances
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Balances.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.balances = [];
                if (message.balances && message.balances.length) {
                    object.balances = [];
                    for (var j = 0; j < message.balances.length; ++j)
                        object.balances[j] = $root.feedbox.trade_api.Balance.toObject(message.balances[j], options);
                }
                return object;
            };

            /**
             * Converts this Balances to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.Balances
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Balances.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Balances;
        })();

        trade_api.GetTotalAmounts = (function() {

            /**
             * Properties of a GetTotalAmounts.
             * @memberof feedbox.trade_api
             * @interface IGetTotalAmounts
             */

            /**
             * Constructs a new GetTotalAmounts.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetTotalAmounts.
             * @implements IGetTotalAmounts
             * @constructor
             * @param {feedbox.trade_api.IGetTotalAmounts=} [properties] Properties to set
             */
            function GetTotalAmounts(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetTotalAmounts instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetTotalAmounts
             * @static
             * @param {feedbox.trade_api.IGetTotalAmounts=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetTotalAmounts} GetTotalAmounts instance
             */
            GetTotalAmounts.create = function create(properties) {
                return new GetTotalAmounts(properties);
            };

            /**
             * Encodes the specified GetTotalAmounts message. Does not implicitly {@link feedbox.trade_api.GetTotalAmounts.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetTotalAmounts
             * @static
             * @param {feedbox.trade_api.IGetTotalAmounts} message GetTotalAmounts message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetTotalAmounts.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetTotalAmounts message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetTotalAmounts
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetTotalAmounts} GetTotalAmounts
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetTotalAmounts.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetTotalAmounts();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetTotalAmounts message.
             * @function verify
             * @memberof feedbox.trade_api.GetTotalAmounts
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetTotalAmounts.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetTotalAmounts message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetTotalAmounts
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetTotalAmounts} GetTotalAmounts
             */
            GetTotalAmounts.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetTotalAmounts)
                    return object;
                return new $root.feedbox.trade_api.GetTotalAmounts();
            };

            /**
             * Creates a plain object from a GetTotalAmounts message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetTotalAmounts
             * @static
             * @param {feedbox.trade_api.GetTotalAmounts} message GetTotalAmounts
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetTotalAmounts.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetTotalAmounts to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetTotalAmounts
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetTotalAmounts.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetTotalAmounts;
        })();

        trade_api.TotalAmounts = (function() {

            /**
             * Properties of a TotalAmounts.
             * @memberof feedbox.trade_api
             * @interface ITotalAmounts
             * @property {number|Long|null} [balance] TotalAmounts balance
             * @property {number|Long|null} [commission] TotalAmounts commission
             * @property {number|Long|null} [reservedMargin] TotalAmounts reservedMargin
             * @property {number|Long|null} [credit] TotalAmounts credit
             * @property {string|null} [currency] TotalAmounts currency
             * @property {number|Long|null} [bonus] TotalAmounts bonus
             */

            /**
             * Constructs a new TotalAmounts.
             * @memberof feedbox.trade_api
             * @classdesc Represents a TotalAmounts.
             * @implements ITotalAmounts
             * @constructor
             * @param {feedbox.trade_api.ITotalAmounts=} [properties] Properties to set
             */
            function TotalAmounts(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TotalAmounts balance.
             * @member {number|Long} balance
             * @memberof feedbox.trade_api.TotalAmounts
             * @instance
             */
            TotalAmounts.prototype.balance = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * TotalAmounts commission.
             * @member {number|Long} commission
             * @memberof feedbox.trade_api.TotalAmounts
             * @instance
             */
            TotalAmounts.prototype.commission = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * TotalAmounts reservedMargin.
             * @member {number|Long} reservedMargin
             * @memberof feedbox.trade_api.TotalAmounts
             * @instance
             */
            TotalAmounts.prototype.reservedMargin = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * TotalAmounts credit.
             * @member {number|Long} credit
             * @memberof feedbox.trade_api.TotalAmounts
             * @instance
             */
            TotalAmounts.prototype.credit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * TotalAmounts currency.
             * @member {string} currency
             * @memberof feedbox.trade_api.TotalAmounts
             * @instance
             */
            TotalAmounts.prototype.currency = "";

            /**
             * TotalAmounts bonus.
             * @member {number|Long} bonus
             * @memberof feedbox.trade_api.TotalAmounts
             * @instance
             */
            TotalAmounts.prototype.bonus = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new TotalAmounts instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.TotalAmounts
             * @static
             * @param {feedbox.trade_api.ITotalAmounts=} [properties] Properties to set
             * @returns {feedbox.trade_api.TotalAmounts} TotalAmounts instance
             */
            TotalAmounts.create = function create(properties) {
                return new TotalAmounts(properties);
            };

            /**
             * Encodes the specified TotalAmounts message. Does not implicitly {@link feedbox.trade_api.TotalAmounts.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.TotalAmounts
             * @static
             * @param {feedbox.trade_api.ITotalAmounts} message TotalAmounts message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TotalAmounts.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.balance);
                if (message.commission != null && Object.hasOwnProperty.call(message, "commission"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.commission);
                if (message.reservedMargin != null && Object.hasOwnProperty.call(message, "reservedMargin"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.reservedMargin);
                if (message.credit != null && Object.hasOwnProperty.call(message, "credit"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.credit);
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.currency);
                if (message.bonus != null && Object.hasOwnProperty.call(message, "bonus"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.bonus);
                return writer;
            };

            /**
             * Decodes a TotalAmounts message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.TotalAmounts
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.TotalAmounts} TotalAmounts
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TotalAmounts.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.TotalAmounts();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.balance = reader.int64();
                        break;
                    case 2:
                        message.commission = reader.int64();
                        break;
                    case 3:
                        message.reservedMargin = reader.int64();
                        break;
                    case 4:
                        message.credit = reader.int64();
                        break;
                    case 5:
                        message.currency = reader.string();
                        break;
                    case 6:
                        message.bonus = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a TotalAmounts message.
             * @function verify
             * @memberof feedbox.trade_api.TotalAmounts
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TotalAmounts.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.balance != null && message.hasOwnProperty("balance"))
                    if (!$util.isInteger(message.balance) && !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high)))
                        return "balance: integer|Long expected";
                if (message.commission != null && message.hasOwnProperty("commission"))
                    if (!$util.isInteger(message.commission) && !(message.commission && $util.isInteger(message.commission.low) && $util.isInteger(message.commission.high)))
                        return "commission: integer|Long expected";
                if (message.reservedMargin != null && message.hasOwnProperty("reservedMargin"))
                    if (!$util.isInteger(message.reservedMargin) && !(message.reservedMargin && $util.isInteger(message.reservedMargin.low) && $util.isInteger(message.reservedMargin.high)))
                        return "reservedMargin: integer|Long expected";
                if (message.credit != null && message.hasOwnProperty("credit"))
                    if (!$util.isInteger(message.credit) && !(message.credit && $util.isInteger(message.credit.low) && $util.isInteger(message.credit.high)))
                        return "credit: integer|Long expected";
                if (message.currency != null && message.hasOwnProperty("currency"))
                    if (!$util.isString(message.currency))
                        return "currency: string expected";
                if (message.bonus != null && message.hasOwnProperty("bonus"))
                    if (!$util.isInteger(message.bonus) && !(message.bonus && $util.isInteger(message.bonus.low) && $util.isInteger(message.bonus.high)))
                        return "bonus: integer|Long expected";
                return null;
            };

            /**
             * Creates a TotalAmounts message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.TotalAmounts
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.TotalAmounts} TotalAmounts
             */
            TotalAmounts.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.TotalAmounts)
                    return object;
                var message = new $root.feedbox.trade_api.TotalAmounts();
                if (object.balance != null)
                    if ($util.Long)
                        (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
                    else if (typeof object.balance === "string")
                        message.balance = parseInt(object.balance, 10);
                    else if (typeof object.balance === "number")
                        message.balance = object.balance;
                    else if (typeof object.balance === "object")
                        message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
                if (object.commission != null)
                    if ($util.Long)
                        (message.commission = $util.Long.fromValue(object.commission)).unsigned = false;
                    else if (typeof object.commission === "string")
                        message.commission = parseInt(object.commission, 10);
                    else if (typeof object.commission === "number")
                        message.commission = object.commission;
                    else if (typeof object.commission === "object")
                        message.commission = new $util.LongBits(object.commission.low >>> 0, object.commission.high >>> 0).toNumber();
                if (object.reservedMargin != null)
                    if ($util.Long)
                        (message.reservedMargin = $util.Long.fromValue(object.reservedMargin)).unsigned = false;
                    else if (typeof object.reservedMargin === "string")
                        message.reservedMargin = parseInt(object.reservedMargin, 10);
                    else if (typeof object.reservedMargin === "number")
                        message.reservedMargin = object.reservedMargin;
                    else if (typeof object.reservedMargin === "object")
                        message.reservedMargin = new $util.LongBits(object.reservedMargin.low >>> 0, object.reservedMargin.high >>> 0).toNumber();
                if (object.credit != null)
                    if ($util.Long)
                        (message.credit = $util.Long.fromValue(object.credit)).unsigned = false;
                    else if (typeof object.credit === "string")
                        message.credit = parseInt(object.credit, 10);
                    else if (typeof object.credit === "number")
                        message.credit = object.credit;
                    else if (typeof object.credit === "object")
                        message.credit = new $util.LongBits(object.credit.low >>> 0, object.credit.high >>> 0).toNumber();
                if (object.currency != null)
                    message.currency = String(object.currency);
                if (object.bonus != null)
                    if ($util.Long)
                        (message.bonus = $util.Long.fromValue(object.bonus)).unsigned = false;
                    else if (typeof object.bonus === "string")
                        message.bonus = parseInt(object.bonus, 10);
                    else if (typeof object.bonus === "number")
                        message.bonus = object.bonus;
                    else if (typeof object.bonus === "object")
                        message.bonus = new $util.LongBits(object.bonus.low >>> 0, object.bonus.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a TotalAmounts message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.TotalAmounts
             * @static
             * @param {feedbox.trade_api.TotalAmounts} message TotalAmounts
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TotalAmounts.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.balance = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.balance = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.commission = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.commission = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.reservedMargin = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.reservedMargin = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.credit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.credit = options.longs === String ? "0" : 0;
                    object.currency = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.bonus = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.bonus = options.longs === String ? "0" : 0;
                }
                if (message.balance != null && message.hasOwnProperty("balance"))
                    if (typeof message.balance === "number")
                        object.balance = options.longs === String ? String(message.balance) : message.balance;
                    else
                        object.balance = options.longs === String ? $util.Long.prototype.toString.call(message.balance) : options.longs === Number ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber() : message.balance;
                if (message.commission != null && message.hasOwnProperty("commission"))
                    if (typeof message.commission === "number")
                        object.commission = options.longs === String ? String(message.commission) : message.commission;
                    else
                        object.commission = options.longs === String ? $util.Long.prototype.toString.call(message.commission) : options.longs === Number ? new $util.LongBits(message.commission.low >>> 0, message.commission.high >>> 0).toNumber() : message.commission;
                if (message.reservedMargin != null && message.hasOwnProperty("reservedMargin"))
                    if (typeof message.reservedMargin === "number")
                        object.reservedMargin = options.longs === String ? String(message.reservedMargin) : message.reservedMargin;
                    else
                        object.reservedMargin = options.longs === String ? $util.Long.prototype.toString.call(message.reservedMargin) : options.longs === Number ? new $util.LongBits(message.reservedMargin.low >>> 0, message.reservedMargin.high >>> 0).toNumber() : message.reservedMargin;
                if (message.credit != null && message.hasOwnProperty("credit"))
                    if (typeof message.credit === "number")
                        object.credit = options.longs === String ? String(message.credit) : message.credit;
                    else
                        object.credit = options.longs === String ? $util.Long.prototype.toString.call(message.credit) : options.longs === Number ? new $util.LongBits(message.credit.low >>> 0, message.credit.high >>> 0).toNumber() : message.credit;
                if (message.currency != null && message.hasOwnProperty("currency"))
                    object.currency = message.currency;
                if (message.bonus != null && message.hasOwnProperty("bonus"))
                    if (typeof message.bonus === "number")
                        object.bonus = options.longs === String ? String(message.bonus) : message.bonus;
                    else
                        object.bonus = options.longs === String ? $util.Long.prototype.toString.call(message.bonus) : options.longs === Number ? new $util.LongBits(message.bonus.low >>> 0, message.bonus.high >>> 0).toNumber() : message.bonus;
                return object;
            };

            /**
             * Converts this TotalAmounts to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.TotalAmounts
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TotalAmounts.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TotalAmounts;
        })();

        trade_api.SLTP = (function() {

            /**
             * Properties of a SLTP.
             * @memberof feedbox.trade_api
             * @interface ISLTP
             * @property {number|Long|null} [byVolume] SLTP byVolume
             * @property {number|Long|null} [byPrice] SLTP byPrice
             */

            /**
             * Constructs a new SLTP.
             * @memberof feedbox.trade_api
             * @classdesc Represents a SLTP.
             * @implements ISLTP
             * @constructor
             * @param {feedbox.trade_api.ISLTP=} [properties] Properties to set
             */
            function SLTP(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SLTP byVolume.
             * @member {number|Long} byVolume
             * @memberof feedbox.trade_api.SLTP
             * @instance
             */
            SLTP.prototype.byVolume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * SLTP byPrice.
             * @member {number|Long} byPrice
             * @memberof feedbox.trade_api.SLTP
             * @instance
             */
            SLTP.prototype.byPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * SLTP by.
             * @member {"byVolume"|"byPrice"|undefined} by
             * @memberof feedbox.trade_api.SLTP
             * @instance
             */
            Object.defineProperty(SLTP.prototype, "by", {
                get: $util.oneOfGetter($oneOfFields = ["byVolume", "byPrice"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new SLTP instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.SLTP
             * @static
             * @param {feedbox.trade_api.ISLTP=} [properties] Properties to set
             * @returns {feedbox.trade_api.SLTP} SLTP instance
             */
            SLTP.create = function create(properties) {
                return new SLTP(properties);
            };

            /**
             * Encodes the specified SLTP message. Does not implicitly {@link feedbox.trade_api.SLTP.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.SLTP
             * @static
             * @param {feedbox.trade_api.ISLTP} message SLTP message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SLTP.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.byVolume != null && Object.hasOwnProperty.call(message, "byVolume"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.byVolume);
                if (message.byPrice != null && Object.hasOwnProperty.call(message, "byPrice"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.byPrice);
                return writer;
            };

            /**
             * Decodes a SLTP message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.SLTP
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.SLTP} SLTP
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SLTP.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.SLTP();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.byVolume = reader.uint64();
                        break;
                    case 2:
                        message.byPrice = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SLTP message.
             * @function verify
             * @memberof feedbox.trade_api.SLTP
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SLTP.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.byVolume != null && message.hasOwnProperty("byVolume")) {
                    properties.by = 1;
                    if (!$util.isInteger(message.byVolume) && !(message.byVolume && $util.isInteger(message.byVolume.low) && $util.isInteger(message.byVolume.high)))
                        return "byVolume: integer|Long expected";
                }
                if (message.byPrice != null && message.hasOwnProperty("byPrice")) {
                    if (properties.by === 1)
                        return "by: multiple values";
                    properties.by = 1;
                    if (!$util.isInteger(message.byPrice) && !(message.byPrice && $util.isInteger(message.byPrice.low) && $util.isInteger(message.byPrice.high)))
                        return "byPrice: integer|Long expected";
                }
                return null;
            };

            /**
             * Creates a SLTP message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.SLTP
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.SLTP} SLTP
             */
            SLTP.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.SLTP)
                    return object;
                var message = new $root.feedbox.trade_api.SLTP();
                if (object.byVolume != null)
                    if ($util.Long)
                        (message.byVolume = $util.Long.fromValue(object.byVolume)).unsigned = true;
                    else if (typeof object.byVolume === "string")
                        message.byVolume = parseInt(object.byVolume, 10);
                    else if (typeof object.byVolume === "number")
                        message.byVolume = object.byVolume;
                    else if (typeof object.byVolume === "object")
                        message.byVolume = new $util.LongBits(object.byVolume.low >>> 0, object.byVolume.high >>> 0).toNumber(true);
                if (object.byPrice != null)
                    if ($util.Long)
                        (message.byPrice = $util.Long.fromValue(object.byPrice)).unsigned = true;
                    else if (typeof object.byPrice === "string")
                        message.byPrice = parseInt(object.byPrice, 10);
                    else if (typeof object.byPrice === "number")
                        message.byPrice = object.byPrice;
                    else if (typeof object.byPrice === "object")
                        message.byPrice = new $util.LongBits(object.byPrice.low >>> 0, object.byPrice.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a SLTP message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.SLTP
             * @static
             * @param {feedbox.trade_api.SLTP} message SLTP
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SLTP.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (message.byVolume != null && message.hasOwnProperty("byVolume")) {
                    if (typeof message.byVolume === "number")
                        object.byVolume = options.longs === String ? String(message.byVolume) : message.byVolume;
                    else
                        object.byVolume = options.longs === String ? $util.Long.prototype.toString.call(message.byVolume) : options.longs === Number ? new $util.LongBits(message.byVolume.low >>> 0, message.byVolume.high >>> 0).toNumber(true) : message.byVolume;
                    if (options.oneofs)
                        object.by = "byVolume";
                }
                if (message.byPrice != null && message.hasOwnProperty("byPrice")) {
                    if (typeof message.byPrice === "number")
                        object.byPrice = options.longs === String ? String(message.byPrice) : message.byPrice;
                    else
                        object.byPrice = options.longs === String ? $util.Long.prototype.toString.call(message.byPrice) : options.longs === Number ? new $util.LongBits(message.byPrice.low >>> 0, message.byPrice.high >>> 0).toNumber(true) : message.byPrice;
                    if (options.oneofs)
                        object.by = "byPrice";
                }
                return object;
            };

            /**
             * Converts this SLTP to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.SLTP
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SLTP.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SLTP;
        })();

        trade_api.PlaceBet = (function() {

            /**
             * Properties of a PlaceBet.
             * @memberof feedbox.trade_api
             * @interface IPlaceBet
             * @property {string|null} [clientBetId] PlaceBet clientBetId
             * @property {number|null} [customerAccountId] PlaceBet customerAccountId
             * @property {string|null} [symbol] PlaceBet symbol
             * @property {feedbox.trade_api.Side|null} [side] PlaceBet side
             * @property {number|Long|null} [volume] PlaceBet volume
             * @property {number|null} [multiplier] PlaceBet multiplier
             * @property {number|Long|null} [price] PlaceBet price
             * @property {feedbox.trade_api.ISLTP|null} [sl] PlaceBet sl
             * @property {feedbox.trade_api.ISLTP|null} [tp] PlaceBet tp
             * @property {number|Long|null} [insuranceConditionId] PlaceBet insuranceConditionId
             */

            /**
             * Constructs a new PlaceBet.
             * @memberof feedbox.trade_api
             * @classdesc Represents a PlaceBet.
             * @implements IPlaceBet
             * @constructor
             * @param {feedbox.trade_api.IPlaceBet=} [properties] Properties to set
             */
            function PlaceBet(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PlaceBet clientBetId.
             * @member {string} clientBetId
             * @memberof feedbox.trade_api.PlaceBet
             * @instance
             */
            PlaceBet.prototype.clientBetId = "";

            /**
             * PlaceBet customerAccountId.
             * @member {number} customerAccountId
             * @memberof feedbox.trade_api.PlaceBet
             * @instance
             */
            PlaceBet.prototype.customerAccountId = 0;

            /**
             * PlaceBet symbol.
             * @member {string} symbol
             * @memberof feedbox.trade_api.PlaceBet
             * @instance
             */
            PlaceBet.prototype.symbol = "";

            /**
             * PlaceBet side.
             * @member {feedbox.trade_api.Side} side
             * @memberof feedbox.trade_api.PlaceBet
             * @instance
             */
            PlaceBet.prototype.side = 0;

            /**
             * PlaceBet volume.
             * @member {number|Long} volume
             * @memberof feedbox.trade_api.PlaceBet
             * @instance
             */
            PlaceBet.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * PlaceBet multiplier.
             * @member {number} multiplier
             * @memberof feedbox.trade_api.PlaceBet
             * @instance
             */
            PlaceBet.prototype.multiplier = 0;

            /**
             * PlaceBet price.
             * @member {number|Long} price
             * @memberof feedbox.trade_api.PlaceBet
             * @instance
             */
            PlaceBet.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * PlaceBet sl.
             * @member {feedbox.trade_api.ISLTP|null|undefined} sl
             * @memberof feedbox.trade_api.PlaceBet
             * @instance
             */
            PlaceBet.prototype.sl = null;

            /**
             * PlaceBet tp.
             * @member {feedbox.trade_api.ISLTP|null|undefined} tp
             * @memberof feedbox.trade_api.PlaceBet
             * @instance
             */
            PlaceBet.prototype.tp = null;

            /**
             * PlaceBet insuranceConditionId.
             * @member {number|Long} insuranceConditionId
             * @memberof feedbox.trade_api.PlaceBet
             * @instance
             */
            PlaceBet.prototype.insuranceConditionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new PlaceBet instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.PlaceBet
             * @static
             * @param {feedbox.trade_api.IPlaceBet=} [properties] Properties to set
             * @returns {feedbox.trade_api.PlaceBet} PlaceBet instance
             */
            PlaceBet.create = function create(properties) {
                return new PlaceBet(properties);
            };

            /**
             * Encodes the specified PlaceBet message. Does not implicitly {@link feedbox.trade_api.PlaceBet.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.PlaceBet
             * @static
             * @param {feedbox.trade_api.IPlaceBet} message PlaceBet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlaceBet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.clientBetId != null && Object.hasOwnProperty.call(message, "clientBetId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientBetId);
                if (message.customerAccountId != null && Object.hasOwnProperty.call(message, "customerAccountId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.customerAccountId);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.symbol);
                if (message.side != null && Object.hasOwnProperty.call(message, "side"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.side);
                if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.volume);
                if (message.multiplier != null && Object.hasOwnProperty.call(message, "multiplier"))
                    writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.multiplier);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.price);
                if (message.sl != null && Object.hasOwnProperty.call(message, "sl"))
                    $root.feedbox.trade_api.SLTP.encode(message.sl, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.tp != null && Object.hasOwnProperty.call(message, "tp"))
                    $root.feedbox.trade_api.SLTP.encode(message.tp, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.insuranceConditionId != null && Object.hasOwnProperty.call(message, "insuranceConditionId"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.insuranceConditionId);
                return writer;
            };

            /**
             * Decodes a PlaceBet message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.PlaceBet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.PlaceBet} PlaceBet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlaceBet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.PlaceBet();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.clientBetId = reader.string();
                        break;
                    case 2:
                        message.customerAccountId = reader.uint32();
                        break;
                    case 3:
                        message.symbol = reader.string();
                        break;
                    case 4:
                        message.side = reader.int32();
                        break;
                    case 5:
                        message.volume = reader.uint64();
                        break;
                    case 6:
                        message.multiplier = reader.uint32();
                        break;
                    case 7:
                        message.price = reader.uint64();
                        break;
                    case 8:
                        message.sl = $root.feedbox.trade_api.SLTP.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.tp = $root.feedbox.trade_api.SLTP.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.insuranceConditionId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a PlaceBet message.
             * @function verify
             * @memberof feedbox.trade_api.PlaceBet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PlaceBet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clientBetId != null && message.hasOwnProperty("clientBetId"))
                    if (!$util.isString(message.clientBetId))
                        return "clientBetId: string expected";
                if (message.customerAccountId != null && message.hasOwnProperty("customerAccountId"))
                    if (!$util.isInteger(message.customerAccountId))
                        return "customerAccountId: integer expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.side != null && message.hasOwnProperty("side"))
                    switch (message.side) {
                    default:
                        return "side: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (!$util.isInteger(message.volume) && !(message.volume && $util.isInteger(message.volume.low) && $util.isInteger(message.volume.high)))
                        return "volume: integer|Long expected";
                if (message.multiplier != null && message.hasOwnProperty("multiplier"))
                    if (!$util.isInteger(message.multiplier))
                        return "multiplier: integer expected";
                if (message.price != null && message.hasOwnProperty("price"))
                    if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                        return "price: integer|Long expected";
                if (message.sl != null && message.hasOwnProperty("sl")) {
                    var error = $root.feedbox.trade_api.SLTP.verify(message.sl);
                    if (error)
                        return "sl." + error;
                }
                if (message.tp != null && message.hasOwnProperty("tp")) {
                    var error = $root.feedbox.trade_api.SLTP.verify(message.tp);
                    if (error)
                        return "tp." + error;
                }
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (!$util.isInteger(message.insuranceConditionId) && !(message.insuranceConditionId && $util.isInteger(message.insuranceConditionId.low) && $util.isInteger(message.insuranceConditionId.high)))
                        return "insuranceConditionId: integer|Long expected";
                return null;
            };

            /**
             * Creates a PlaceBet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.PlaceBet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.PlaceBet} PlaceBet
             */
            PlaceBet.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.PlaceBet)
                    return object;
                var message = new $root.feedbox.trade_api.PlaceBet();
                if (object.clientBetId != null)
                    message.clientBetId = String(object.clientBetId);
                if (object.customerAccountId != null)
                    message.customerAccountId = object.customerAccountId >>> 0;
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                switch (object.side) {
                case "BUY":
                case 0:
                    message.side = 0;
                    break;
                case "SELL":
                case 1:
                    message.side = 1;
                    break;
                }
                if (object.volume != null)
                    if ($util.Long)
                        (message.volume = $util.Long.fromValue(object.volume)).unsigned = true;
                    else if (typeof object.volume === "string")
                        message.volume = parseInt(object.volume, 10);
                    else if (typeof object.volume === "number")
                        message.volume = object.volume;
                    else if (typeof object.volume === "object")
                        message.volume = new $util.LongBits(object.volume.low >>> 0, object.volume.high >>> 0).toNumber(true);
                if (object.multiplier != null)
                    message.multiplier = object.multiplier >>> 0;
                if (object.price != null)
                    if ($util.Long)
                        (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                    else if (typeof object.price === "string")
                        message.price = parseInt(object.price, 10);
                    else if (typeof object.price === "number")
                        message.price = object.price;
                    else if (typeof object.price === "object")
                        message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                if (object.sl != null) {
                    if (typeof object.sl !== "object")
                        throw TypeError(".feedbox.trade_api.PlaceBet.sl: object expected");
                    message.sl = $root.feedbox.trade_api.SLTP.fromObject(object.sl);
                }
                if (object.tp != null) {
                    if (typeof object.tp !== "object")
                        throw TypeError(".feedbox.trade_api.PlaceBet.tp: object expected");
                    message.tp = $root.feedbox.trade_api.SLTP.fromObject(object.tp);
                }
                if (object.insuranceConditionId != null)
                    if ($util.Long)
                        (message.insuranceConditionId = $util.Long.fromValue(object.insuranceConditionId)).unsigned = true;
                    else if (typeof object.insuranceConditionId === "string")
                        message.insuranceConditionId = parseInt(object.insuranceConditionId, 10);
                    else if (typeof object.insuranceConditionId === "number")
                        message.insuranceConditionId = object.insuranceConditionId;
                    else if (typeof object.insuranceConditionId === "object")
                        message.insuranceConditionId = new $util.LongBits(object.insuranceConditionId.low >>> 0, object.insuranceConditionId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a PlaceBet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.PlaceBet
             * @static
             * @param {feedbox.trade_api.PlaceBet} message PlaceBet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PlaceBet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.clientBetId = "";
                    object.customerAccountId = 0;
                    object.symbol = "";
                    object.side = options.enums === String ? "BUY" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.volume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.volume = options.longs === String ? "0" : 0;
                    object.multiplier = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.price = options.longs === String ? "0" : 0;
                    object.sl = null;
                    object.tp = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.insuranceConditionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insuranceConditionId = options.longs === String ? "0" : 0;
                }
                if (message.clientBetId != null && message.hasOwnProperty("clientBetId"))
                    object.clientBetId = message.clientBetId;
                if (message.customerAccountId != null && message.hasOwnProperty("customerAccountId"))
                    object.customerAccountId = message.customerAccountId;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.side != null && message.hasOwnProperty("side"))
                    object.side = options.enums === String ? $root.feedbox.trade_api.Side[message.side] : message.side;
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (typeof message.volume === "number")
                        object.volume = options.longs === String ? String(message.volume) : message.volume;
                    else
                        object.volume = options.longs === String ? $util.Long.prototype.toString.call(message.volume) : options.longs === Number ? new $util.LongBits(message.volume.low >>> 0, message.volume.high >>> 0).toNumber(true) : message.volume;
                if (message.multiplier != null && message.hasOwnProperty("multiplier"))
                    object.multiplier = message.multiplier;
                if (message.price != null && message.hasOwnProperty("price"))
                    if (typeof message.price === "number")
                        object.price = options.longs === String ? String(message.price) : message.price;
                    else
                        object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                if (message.sl != null && message.hasOwnProperty("sl"))
                    object.sl = $root.feedbox.trade_api.SLTP.toObject(message.sl, options);
                if (message.tp != null && message.hasOwnProperty("tp"))
                    object.tp = $root.feedbox.trade_api.SLTP.toObject(message.tp, options);
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (typeof message.insuranceConditionId === "number")
                        object.insuranceConditionId = options.longs === String ? String(message.insuranceConditionId) : message.insuranceConditionId;
                    else
                        object.insuranceConditionId = options.longs === String ? $util.Long.prototype.toString.call(message.insuranceConditionId) : options.longs === Number ? new $util.LongBits(message.insuranceConditionId.low >>> 0, message.insuranceConditionId.high >>> 0).toNumber(true) : message.insuranceConditionId;
                return object;
            };

            /**
             * Converts this PlaceBet to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.PlaceBet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PlaceBet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PlaceBet;
        })();

        trade_api.PlaceBetResult = (function() {

            /**
             * Properties of a PlaceBetResult.
             * @memberof feedbox.trade_api
             * @interface IPlaceBetResult
             * @property {number|Long|null} [betId] PlaceBetResult betId
             */

            /**
             * Constructs a new PlaceBetResult.
             * @memberof feedbox.trade_api
             * @classdesc Represents a PlaceBetResult.
             * @implements IPlaceBetResult
             * @constructor
             * @param {feedbox.trade_api.IPlaceBetResult=} [properties] Properties to set
             */
            function PlaceBetResult(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PlaceBetResult betId.
             * @member {number|Long} betId
             * @memberof feedbox.trade_api.PlaceBetResult
             * @instance
             */
            PlaceBetResult.prototype.betId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new PlaceBetResult instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.PlaceBetResult
             * @static
             * @param {feedbox.trade_api.IPlaceBetResult=} [properties] Properties to set
             * @returns {feedbox.trade_api.PlaceBetResult} PlaceBetResult instance
             */
            PlaceBetResult.create = function create(properties) {
                return new PlaceBetResult(properties);
            };

            /**
             * Encodes the specified PlaceBetResult message. Does not implicitly {@link feedbox.trade_api.PlaceBetResult.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.PlaceBetResult
             * @static
             * @param {feedbox.trade_api.IPlaceBetResult} message PlaceBetResult message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlaceBetResult.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.betId != null && Object.hasOwnProperty.call(message, "betId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.betId);
                return writer;
            };

            /**
             * Decodes a PlaceBetResult message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.PlaceBetResult
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.PlaceBetResult} PlaceBetResult
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlaceBetResult.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.PlaceBetResult();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.betId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a PlaceBetResult message.
             * @function verify
             * @memberof feedbox.trade_api.PlaceBetResult
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PlaceBetResult.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (!$util.isInteger(message.betId) && !(message.betId && $util.isInteger(message.betId.low) && $util.isInteger(message.betId.high)))
                        return "betId: integer|Long expected";
                return null;
            };

            /**
             * Creates a PlaceBetResult message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.PlaceBetResult
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.PlaceBetResult} PlaceBetResult
             */
            PlaceBetResult.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.PlaceBetResult)
                    return object;
                var message = new $root.feedbox.trade_api.PlaceBetResult();
                if (object.betId != null)
                    if ($util.Long)
                        (message.betId = $util.Long.fromValue(object.betId)).unsigned = true;
                    else if (typeof object.betId === "string")
                        message.betId = parseInt(object.betId, 10);
                    else if (typeof object.betId === "number")
                        message.betId = object.betId;
                    else if (typeof object.betId === "object")
                        message.betId = new $util.LongBits(object.betId.low >>> 0, object.betId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a PlaceBetResult message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.PlaceBetResult
             * @static
             * @param {feedbox.trade_api.PlaceBetResult} message PlaceBetResult
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PlaceBetResult.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.betId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.betId = options.longs === String ? "0" : 0;
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (typeof message.betId === "number")
                        object.betId = options.longs === String ? String(message.betId) : message.betId;
                    else
                        object.betId = options.longs === String ? $util.Long.prototype.toString.call(message.betId) : options.longs === Number ? new $util.LongBits(message.betId.low >>> 0, message.betId.high >>> 0).toNumber(true) : message.betId;
                return object;
            };

            /**
             * Converts this PlaceBetResult to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.PlaceBetResult
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PlaceBetResult.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PlaceBetResult;
        })();

        trade_api.Bet = (function() {

            /**
             * Properties of a Bet.
             * @memberof feedbox.trade_api
             * @interface IBet
             * @property {string|null} [clientBetId] Bet clientBetId
             * @property {number|Long|null} [betId] Bet betId
             * @property {number|null} [customerId] Bet customerId
             * @property {number|null} [customerAccountId] Bet customerAccountId
             * @property {number|null} [customerLoginId] Bet customerLoginId
             * @property {string|null} [symbol] Bet symbol
             * @property {feedbox.trade_api.Side|null} [side] Bet side
             * @property {number|Long|null} [volume] Bet volume
             * @property {number|null} [multiplier] Bet multiplier
             * @property {number|Long|null} [placePrice] Bet placePrice
             * @property {number|Long|null} [openPrice] Bet openPrice
             * @property {number|Long|null} [closePrice] Bet closePrice
             * @property {feedbox.trade_api.ISLTP|null} [sl] Bet sl
             * @property {feedbox.trade_api.ISLTP|null} [tp] Bet tp
             * @property {feedbox.trade_api.Bet.Status|null} [status] Bet status
             * @property {number|Long|null} [commission] Bet commission
             * @property {number|Long|null} [swap] Bet swap
             * @property {number|Long|null} [profitLoss] Bet profitLoss
             * @property {number|Long|null} [insuranceFee] Bet insuranceFee
             * @property {number|Long|null} [insurancePayout] Bet insurancePayout
             * @property {number|Long|null} [placeTime] Bet placeTime
             * @property {number|Long|null} [openTime] Bet openTime
             * @property {number|Long|null} [closeTime] Bet closeTime
             * @property {number|Long|null} [insuranceConditionId] Bet insuranceConditionId
             */

            /**
             * Constructs a new Bet.
             * @memberof feedbox.trade_api
             * @classdesc Represents a Bet.
             * @implements IBet
             * @constructor
             * @param {feedbox.trade_api.IBet=} [properties] Properties to set
             */
            function Bet(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Bet clientBetId.
             * @member {string} clientBetId
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.clientBetId = "";

            /**
             * Bet betId.
             * @member {number|Long} betId
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.betId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Bet customerId.
             * @member {number} customerId
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.customerId = 0;

            /**
             * Bet customerAccountId.
             * @member {number} customerAccountId
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.customerAccountId = 0;

            /**
             * Bet customerLoginId.
             * @member {number} customerLoginId
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.customerLoginId = 0;

            /**
             * Bet symbol.
             * @member {string} symbol
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.symbol = "";

            /**
             * Bet side.
             * @member {feedbox.trade_api.Side} side
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.side = 0;

            /**
             * Bet volume.
             * @member {number|Long} volume
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Bet multiplier.
             * @member {number} multiplier
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.multiplier = 0;

            /**
             * Bet placePrice.
             * @member {number|Long} placePrice
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.placePrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Bet openPrice.
             * @member {number|Long} openPrice
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.openPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Bet closePrice.
             * @member {number|Long} closePrice
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.closePrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Bet sl.
             * @member {feedbox.trade_api.ISLTP|null|undefined} sl
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.sl = null;

            /**
             * Bet tp.
             * @member {feedbox.trade_api.ISLTP|null|undefined} tp
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.tp = null;

            /**
             * Bet status.
             * @member {feedbox.trade_api.Bet.Status} status
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.status = 0;

            /**
             * Bet commission.
             * @member {number|Long} commission
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.commission = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Bet swap.
             * @member {number|Long} swap
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.swap = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Bet profitLoss.
             * @member {number|Long} profitLoss
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.profitLoss = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Bet insuranceFee.
             * @member {number|Long} insuranceFee
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.insuranceFee = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Bet insurancePayout.
             * @member {number|Long} insurancePayout
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.insurancePayout = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Bet placeTime.
             * @member {number|Long} placeTime
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.placeTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Bet openTime.
             * @member {number|Long} openTime
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.openTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Bet closeTime.
             * @member {number|Long} closeTime
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.closeTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Bet insuranceConditionId.
             * @member {number|Long} insuranceConditionId
             * @memberof feedbox.trade_api.Bet
             * @instance
             */
            Bet.prototype.insuranceConditionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new Bet instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.Bet
             * @static
             * @param {feedbox.trade_api.IBet=} [properties] Properties to set
             * @returns {feedbox.trade_api.Bet} Bet instance
             */
            Bet.create = function create(properties) {
                return new Bet(properties);
            };

            /**
             * Encodes the specified Bet message. Does not implicitly {@link feedbox.trade_api.Bet.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.Bet
             * @static
             * @param {feedbox.trade_api.IBet} message Bet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Bet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.clientBetId != null && Object.hasOwnProperty.call(message, "clientBetId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientBetId);
                if (message.betId != null && Object.hasOwnProperty.call(message, "betId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.betId);
                if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.customerId);
                if (message.customerAccountId != null && Object.hasOwnProperty.call(message, "customerAccountId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.customerAccountId);
                if (message.customerLoginId != null && Object.hasOwnProperty.call(message, "customerLoginId"))
                    writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.customerLoginId);
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.symbol);
                if (message.side != null && Object.hasOwnProperty.call(message, "side"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.side);
                if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.volume);
                if (message.multiplier != null && Object.hasOwnProperty.call(message, "multiplier"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint32(message.multiplier);
                if (message.placePrice != null && Object.hasOwnProperty.call(message, "placePrice"))
                    writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.placePrice);
                if (message.openPrice != null && Object.hasOwnProperty.call(message, "openPrice"))
                    writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.openPrice);
                if (message.closePrice != null && Object.hasOwnProperty.call(message, "closePrice"))
                    writer.uint32(/* id 12, wireType 0 =*/96).uint64(message.closePrice);
                if (message.sl != null && Object.hasOwnProperty.call(message, "sl"))
                    $root.feedbox.trade_api.SLTP.encode(message.sl, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.tp != null && Object.hasOwnProperty.call(message, "tp"))
                    $root.feedbox.trade_api.SLTP.encode(message.tp, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int32(message.status);
                if (message.commission != null && Object.hasOwnProperty.call(message, "commission"))
                    writer.uint32(/* id 16, wireType 0 =*/128).sint64(message.commission);
                if (message.profitLoss != null && Object.hasOwnProperty.call(message, "profitLoss"))
                    writer.uint32(/* id 17, wireType 0 =*/136).sint64(message.profitLoss);
                if (message.placeTime != null && Object.hasOwnProperty.call(message, "placeTime"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int64(message.placeTime);
                if (message.openTime != null && Object.hasOwnProperty.call(message, "openTime"))
                    writer.uint32(/* id 19, wireType 0 =*/152).int64(message.openTime);
                if (message.closeTime != null && Object.hasOwnProperty.call(message, "closeTime"))
                    writer.uint32(/* id 20, wireType 0 =*/160).int64(message.closeTime);
                if (message.swap != null && Object.hasOwnProperty.call(message, "swap"))
                    writer.uint32(/* id 21, wireType 0 =*/168).sint64(message.swap);
                if (message.insuranceConditionId != null && Object.hasOwnProperty.call(message, "insuranceConditionId"))
                    writer.uint32(/* id 22, wireType 0 =*/176).uint64(message.insuranceConditionId);
                if (message.insuranceFee != null && Object.hasOwnProperty.call(message, "insuranceFee"))
                    writer.uint32(/* id 23, wireType 0 =*/184).sint64(message.insuranceFee);
                if (message.insurancePayout != null && Object.hasOwnProperty.call(message, "insurancePayout"))
                    writer.uint32(/* id 24, wireType 0 =*/192).sint64(message.insurancePayout);
                return writer;
            };

            /**
             * Decodes a Bet message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.Bet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.Bet} Bet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Bet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.Bet();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.clientBetId = reader.string();
                        break;
                    case 2:
                        message.betId = reader.uint64();
                        break;
                    case 3:
                        message.customerId = reader.uint32();
                        break;
                    case 4:
                        message.customerAccountId = reader.uint32();
                        break;
                    case 5:
                        message.customerLoginId = reader.uint32();
                        break;
                    case 6:
                        message.symbol = reader.string();
                        break;
                    case 7:
                        message.side = reader.int32();
                        break;
                    case 8:
                        message.volume = reader.uint64();
                        break;
                    case 9:
                        message.multiplier = reader.uint32();
                        break;
                    case 10:
                        message.placePrice = reader.uint64();
                        break;
                    case 11:
                        message.openPrice = reader.uint64();
                        break;
                    case 12:
                        message.closePrice = reader.uint64();
                        break;
                    case 13:
                        message.sl = $root.feedbox.trade_api.SLTP.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.tp = $root.feedbox.trade_api.SLTP.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.status = reader.int32();
                        break;
                    case 16:
                        message.commission = reader.sint64();
                        break;
                    case 21:
                        message.swap = reader.sint64();
                        break;
                    case 17:
                        message.profitLoss = reader.sint64();
                        break;
                    case 23:
                        message.insuranceFee = reader.sint64();
                        break;
                    case 24:
                        message.insurancePayout = reader.sint64();
                        break;
                    case 18:
                        message.placeTime = reader.int64();
                        break;
                    case 19:
                        message.openTime = reader.int64();
                        break;
                    case 20:
                        message.closeTime = reader.int64();
                        break;
                    case 22:
                        message.insuranceConditionId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Bet message.
             * @function verify
             * @memberof feedbox.trade_api.Bet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Bet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clientBetId != null && message.hasOwnProperty("clientBetId"))
                    if (!$util.isString(message.clientBetId))
                        return "clientBetId: string expected";
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (!$util.isInteger(message.betId) && !(message.betId && $util.isInteger(message.betId.low) && $util.isInteger(message.betId.high)))
                        return "betId: integer|Long expected";
                if (message.customerId != null && message.hasOwnProperty("customerId"))
                    if (!$util.isInteger(message.customerId))
                        return "customerId: integer expected";
                if (message.customerAccountId != null && message.hasOwnProperty("customerAccountId"))
                    if (!$util.isInteger(message.customerAccountId))
                        return "customerAccountId: integer expected";
                if (message.customerLoginId != null && message.hasOwnProperty("customerLoginId"))
                    if (!$util.isInteger(message.customerLoginId))
                        return "customerLoginId: integer expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.side != null && message.hasOwnProperty("side"))
                    switch (message.side) {
                    default:
                        return "side: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (!$util.isInteger(message.volume) && !(message.volume && $util.isInteger(message.volume.low) && $util.isInteger(message.volume.high)))
                        return "volume: integer|Long expected";
                if (message.multiplier != null && message.hasOwnProperty("multiplier"))
                    if (!$util.isInteger(message.multiplier))
                        return "multiplier: integer expected";
                if (message.placePrice != null && message.hasOwnProperty("placePrice"))
                    if (!$util.isInteger(message.placePrice) && !(message.placePrice && $util.isInteger(message.placePrice.low) && $util.isInteger(message.placePrice.high)))
                        return "placePrice: integer|Long expected";
                if (message.openPrice != null && message.hasOwnProperty("openPrice"))
                    if (!$util.isInteger(message.openPrice) && !(message.openPrice && $util.isInteger(message.openPrice.low) && $util.isInteger(message.openPrice.high)))
                        return "openPrice: integer|Long expected";
                if (message.closePrice != null && message.hasOwnProperty("closePrice"))
                    if (!$util.isInteger(message.closePrice) && !(message.closePrice && $util.isInteger(message.closePrice.low) && $util.isInteger(message.closePrice.high)))
                        return "closePrice: integer|Long expected";
                if (message.sl != null && message.hasOwnProperty("sl")) {
                    var error = $root.feedbox.trade_api.SLTP.verify(message.sl);
                    if (error)
                        return "sl." + error;
                }
                if (message.tp != null && message.hasOwnProperty("tp")) {
                    var error = $root.feedbox.trade_api.SLTP.verify(message.tp);
                    if (error)
                        return "tp." + error;
                }
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.commission != null && message.hasOwnProperty("commission"))
                    if (!$util.isInteger(message.commission) && !(message.commission && $util.isInteger(message.commission.low) && $util.isInteger(message.commission.high)))
                        return "commission: integer|Long expected";
                if (message.swap != null && message.hasOwnProperty("swap"))
                    if (!$util.isInteger(message.swap) && !(message.swap && $util.isInteger(message.swap.low) && $util.isInteger(message.swap.high)))
                        return "swap: integer|Long expected";
                if (message.profitLoss != null && message.hasOwnProperty("profitLoss"))
                    if (!$util.isInteger(message.profitLoss) && !(message.profitLoss && $util.isInteger(message.profitLoss.low) && $util.isInteger(message.profitLoss.high)))
                        return "profitLoss: integer|Long expected";
                if (message.insuranceFee != null && message.hasOwnProperty("insuranceFee"))
                    if (!$util.isInteger(message.insuranceFee) && !(message.insuranceFee && $util.isInteger(message.insuranceFee.low) && $util.isInteger(message.insuranceFee.high)))
                        return "insuranceFee: integer|Long expected";
                if (message.insurancePayout != null && message.hasOwnProperty("insurancePayout"))
                    if (!$util.isInteger(message.insurancePayout) && !(message.insurancePayout && $util.isInteger(message.insurancePayout.low) && $util.isInteger(message.insurancePayout.high)))
                        return "insurancePayout: integer|Long expected";
                if (message.placeTime != null && message.hasOwnProperty("placeTime"))
                    if (!$util.isInteger(message.placeTime) && !(message.placeTime && $util.isInteger(message.placeTime.low) && $util.isInteger(message.placeTime.high)))
                        return "placeTime: integer|Long expected";
                if (message.openTime != null && message.hasOwnProperty("openTime"))
                    if (!$util.isInteger(message.openTime) && !(message.openTime && $util.isInteger(message.openTime.low) && $util.isInteger(message.openTime.high)))
                        return "openTime: integer|Long expected";
                if (message.closeTime != null && message.hasOwnProperty("closeTime"))
                    if (!$util.isInteger(message.closeTime) && !(message.closeTime && $util.isInteger(message.closeTime.low) && $util.isInteger(message.closeTime.high)))
                        return "closeTime: integer|Long expected";
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (!$util.isInteger(message.insuranceConditionId) && !(message.insuranceConditionId && $util.isInteger(message.insuranceConditionId.low) && $util.isInteger(message.insuranceConditionId.high)))
                        return "insuranceConditionId: integer|Long expected";
                return null;
            };

            /**
             * Creates a Bet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.Bet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.Bet} Bet
             */
            Bet.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.Bet)
                    return object;
                var message = new $root.feedbox.trade_api.Bet();
                if (object.clientBetId != null)
                    message.clientBetId = String(object.clientBetId);
                if (object.betId != null)
                    if ($util.Long)
                        (message.betId = $util.Long.fromValue(object.betId)).unsigned = true;
                    else if (typeof object.betId === "string")
                        message.betId = parseInt(object.betId, 10);
                    else if (typeof object.betId === "number")
                        message.betId = object.betId;
                    else if (typeof object.betId === "object")
                        message.betId = new $util.LongBits(object.betId.low >>> 0, object.betId.high >>> 0).toNumber(true);
                if (object.customerId != null)
                    message.customerId = object.customerId >>> 0;
                if (object.customerAccountId != null)
                    message.customerAccountId = object.customerAccountId >>> 0;
                if (object.customerLoginId != null)
                    message.customerLoginId = object.customerLoginId >>> 0;
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                switch (object.side) {
                case "BUY":
                case 0:
                    message.side = 0;
                    break;
                case "SELL":
                case 1:
                    message.side = 1;
                    break;
                }
                if (object.volume != null)
                    if ($util.Long)
                        (message.volume = $util.Long.fromValue(object.volume)).unsigned = true;
                    else if (typeof object.volume === "string")
                        message.volume = parseInt(object.volume, 10);
                    else if (typeof object.volume === "number")
                        message.volume = object.volume;
                    else if (typeof object.volume === "object")
                        message.volume = new $util.LongBits(object.volume.low >>> 0, object.volume.high >>> 0).toNumber(true);
                if (object.multiplier != null)
                    message.multiplier = object.multiplier >>> 0;
                if (object.placePrice != null)
                    if ($util.Long)
                        (message.placePrice = $util.Long.fromValue(object.placePrice)).unsigned = true;
                    else if (typeof object.placePrice === "string")
                        message.placePrice = parseInt(object.placePrice, 10);
                    else if (typeof object.placePrice === "number")
                        message.placePrice = object.placePrice;
                    else if (typeof object.placePrice === "object")
                        message.placePrice = new $util.LongBits(object.placePrice.low >>> 0, object.placePrice.high >>> 0).toNumber(true);
                if (object.openPrice != null)
                    if ($util.Long)
                        (message.openPrice = $util.Long.fromValue(object.openPrice)).unsigned = true;
                    else if (typeof object.openPrice === "string")
                        message.openPrice = parseInt(object.openPrice, 10);
                    else if (typeof object.openPrice === "number")
                        message.openPrice = object.openPrice;
                    else if (typeof object.openPrice === "object")
                        message.openPrice = new $util.LongBits(object.openPrice.low >>> 0, object.openPrice.high >>> 0).toNumber(true);
                if (object.closePrice != null)
                    if ($util.Long)
                        (message.closePrice = $util.Long.fromValue(object.closePrice)).unsigned = true;
                    else if (typeof object.closePrice === "string")
                        message.closePrice = parseInt(object.closePrice, 10);
                    else if (typeof object.closePrice === "number")
                        message.closePrice = object.closePrice;
                    else if (typeof object.closePrice === "object")
                        message.closePrice = new $util.LongBits(object.closePrice.low >>> 0, object.closePrice.high >>> 0).toNumber(true);
                if (object.sl != null) {
                    if (typeof object.sl !== "object")
                        throw TypeError(".feedbox.trade_api.Bet.sl: object expected");
                    message.sl = $root.feedbox.trade_api.SLTP.fromObject(object.sl);
                }
                if (object.tp != null) {
                    if (typeof object.tp !== "object")
                        throw TypeError(".feedbox.trade_api.Bet.tp: object expected");
                    message.tp = $root.feedbox.trade_api.SLTP.fromObject(object.tp);
                }
                switch (object.status) {
                case "PLACED":
                case 0:
                    message.status = 0;
                    break;
                case "OPENED":
                case 1:
                    message.status = 1;
                    break;
                case "CLOSED":
                case 2:
                    message.status = 2;
                    break;
                case "CANCELED":
                case 3:
                    message.status = 3;
                    break;
                }
                if (object.commission != null)
                    if ($util.Long)
                        (message.commission = $util.Long.fromValue(object.commission)).unsigned = false;
                    else if (typeof object.commission === "string")
                        message.commission = parseInt(object.commission, 10);
                    else if (typeof object.commission === "number")
                        message.commission = object.commission;
                    else if (typeof object.commission === "object")
                        message.commission = new $util.LongBits(object.commission.low >>> 0, object.commission.high >>> 0).toNumber();
                if (object.swap != null)
                    if ($util.Long)
                        (message.swap = $util.Long.fromValue(object.swap)).unsigned = false;
                    else if (typeof object.swap === "string")
                        message.swap = parseInt(object.swap, 10);
                    else if (typeof object.swap === "number")
                        message.swap = object.swap;
                    else if (typeof object.swap === "object")
                        message.swap = new $util.LongBits(object.swap.low >>> 0, object.swap.high >>> 0).toNumber();
                if (object.profitLoss != null)
                    if ($util.Long)
                        (message.profitLoss = $util.Long.fromValue(object.profitLoss)).unsigned = false;
                    else if (typeof object.profitLoss === "string")
                        message.profitLoss = parseInt(object.profitLoss, 10);
                    else if (typeof object.profitLoss === "number")
                        message.profitLoss = object.profitLoss;
                    else if (typeof object.profitLoss === "object")
                        message.profitLoss = new $util.LongBits(object.profitLoss.low >>> 0, object.profitLoss.high >>> 0).toNumber();
                if (object.insuranceFee != null)
                    if ($util.Long)
                        (message.insuranceFee = $util.Long.fromValue(object.insuranceFee)).unsigned = false;
                    else if (typeof object.insuranceFee === "string")
                        message.insuranceFee = parseInt(object.insuranceFee, 10);
                    else if (typeof object.insuranceFee === "number")
                        message.insuranceFee = object.insuranceFee;
                    else if (typeof object.insuranceFee === "object")
                        message.insuranceFee = new $util.LongBits(object.insuranceFee.low >>> 0, object.insuranceFee.high >>> 0).toNumber();
                if (object.insurancePayout != null)
                    if ($util.Long)
                        (message.insurancePayout = $util.Long.fromValue(object.insurancePayout)).unsigned = false;
                    else if (typeof object.insurancePayout === "string")
                        message.insurancePayout = parseInt(object.insurancePayout, 10);
                    else if (typeof object.insurancePayout === "number")
                        message.insurancePayout = object.insurancePayout;
                    else if (typeof object.insurancePayout === "object")
                        message.insurancePayout = new $util.LongBits(object.insurancePayout.low >>> 0, object.insurancePayout.high >>> 0).toNumber();
                if (object.placeTime != null)
                    if ($util.Long)
                        (message.placeTime = $util.Long.fromValue(object.placeTime)).unsigned = false;
                    else if (typeof object.placeTime === "string")
                        message.placeTime = parseInt(object.placeTime, 10);
                    else if (typeof object.placeTime === "number")
                        message.placeTime = object.placeTime;
                    else if (typeof object.placeTime === "object")
                        message.placeTime = new $util.LongBits(object.placeTime.low >>> 0, object.placeTime.high >>> 0).toNumber();
                if (object.openTime != null)
                    if ($util.Long)
                        (message.openTime = $util.Long.fromValue(object.openTime)).unsigned = false;
                    else if (typeof object.openTime === "string")
                        message.openTime = parseInt(object.openTime, 10);
                    else if (typeof object.openTime === "number")
                        message.openTime = object.openTime;
                    else if (typeof object.openTime === "object")
                        message.openTime = new $util.LongBits(object.openTime.low >>> 0, object.openTime.high >>> 0).toNumber();
                if (object.closeTime != null)
                    if ($util.Long)
                        (message.closeTime = $util.Long.fromValue(object.closeTime)).unsigned = false;
                    else if (typeof object.closeTime === "string")
                        message.closeTime = parseInt(object.closeTime, 10);
                    else if (typeof object.closeTime === "number")
                        message.closeTime = object.closeTime;
                    else if (typeof object.closeTime === "object")
                        message.closeTime = new $util.LongBits(object.closeTime.low >>> 0, object.closeTime.high >>> 0).toNumber();
                if (object.insuranceConditionId != null)
                    if ($util.Long)
                        (message.insuranceConditionId = $util.Long.fromValue(object.insuranceConditionId)).unsigned = true;
                    else if (typeof object.insuranceConditionId === "string")
                        message.insuranceConditionId = parseInt(object.insuranceConditionId, 10);
                    else if (typeof object.insuranceConditionId === "number")
                        message.insuranceConditionId = object.insuranceConditionId;
                    else if (typeof object.insuranceConditionId === "object")
                        message.insuranceConditionId = new $util.LongBits(object.insuranceConditionId.low >>> 0, object.insuranceConditionId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a Bet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.Bet
             * @static
             * @param {feedbox.trade_api.Bet} message Bet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Bet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.clientBetId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.betId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.betId = options.longs === String ? "0" : 0;
                    object.customerId = 0;
                    object.customerAccountId = 0;
                    object.customerLoginId = 0;
                    object.symbol = "";
                    object.side = options.enums === String ? "BUY" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.volume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.volume = options.longs === String ? "0" : 0;
                    object.multiplier = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.placePrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.placePrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.openPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.openPrice = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.closePrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.closePrice = options.longs === String ? "0" : 0;
                    object.sl = null;
                    object.tp = null;
                    object.status = options.enums === String ? "PLACED" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.commission = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.commission = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.profitLoss = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.profitLoss = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.placeTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.placeTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.openTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.openTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.closeTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.closeTime = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.swap = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.swap = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.insuranceConditionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insuranceConditionId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.insuranceFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insuranceFee = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.insurancePayout = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insurancePayout = options.longs === String ? "0" : 0;
                }
                if (message.clientBetId != null && message.hasOwnProperty("clientBetId"))
                    object.clientBetId = message.clientBetId;
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (typeof message.betId === "number")
                        object.betId = options.longs === String ? String(message.betId) : message.betId;
                    else
                        object.betId = options.longs === String ? $util.Long.prototype.toString.call(message.betId) : options.longs === Number ? new $util.LongBits(message.betId.low >>> 0, message.betId.high >>> 0).toNumber(true) : message.betId;
                if (message.customerId != null && message.hasOwnProperty("customerId"))
                    object.customerId = message.customerId;
                if (message.customerAccountId != null && message.hasOwnProperty("customerAccountId"))
                    object.customerAccountId = message.customerAccountId;
                if (message.customerLoginId != null && message.hasOwnProperty("customerLoginId"))
                    object.customerLoginId = message.customerLoginId;
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.side != null && message.hasOwnProperty("side"))
                    object.side = options.enums === String ? $root.feedbox.trade_api.Side[message.side] : message.side;
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (typeof message.volume === "number")
                        object.volume = options.longs === String ? String(message.volume) : message.volume;
                    else
                        object.volume = options.longs === String ? $util.Long.prototype.toString.call(message.volume) : options.longs === Number ? new $util.LongBits(message.volume.low >>> 0, message.volume.high >>> 0).toNumber(true) : message.volume;
                if (message.multiplier != null && message.hasOwnProperty("multiplier"))
                    object.multiplier = message.multiplier;
                if (message.placePrice != null && message.hasOwnProperty("placePrice"))
                    if (typeof message.placePrice === "number")
                        object.placePrice = options.longs === String ? String(message.placePrice) : message.placePrice;
                    else
                        object.placePrice = options.longs === String ? $util.Long.prototype.toString.call(message.placePrice) : options.longs === Number ? new $util.LongBits(message.placePrice.low >>> 0, message.placePrice.high >>> 0).toNumber(true) : message.placePrice;
                if (message.openPrice != null && message.hasOwnProperty("openPrice"))
                    if (typeof message.openPrice === "number")
                        object.openPrice = options.longs === String ? String(message.openPrice) : message.openPrice;
                    else
                        object.openPrice = options.longs === String ? $util.Long.prototype.toString.call(message.openPrice) : options.longs === Number ? new $util.LongBits(message.openPrice.low >>> 0, message.openPrice.high >>> 0).toNumber(true) : message.openPrice;
                if (message.closePrice != null && message.hasOwnProperty("closePrice"))
                    if (typeof message.closePrice === "number")
                        object.closePrice = options.longs === String ? String(message.closePrice) : message.closePrice;
                    else
                        object.closePrice = options.longs === String ? $util.Long.prototype.toString.call(message.closePrice) : options.longs === Number ? new $util.LongBits(message.closePrice.low >>> 0, message.closePrice.high >>> 0).toNumber(true) : message.closePrice;
                if (message.sl != null && message.hasOwnProperty("sl"))
                    object.sl = $root.feedbox.trade_api.SLTP.toObject(message.sl, options);
                if (message.tp != null && message.hasOwnProperty("tp"))
                    object.tp = $root.feedbox.trade_api.SLTP.toObject(message.tp, options);
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.feedbox.trade_api.Bet.Status[message.status] : message.status;
                if (message.commission != null && message.hasOwnProperty("commission"))
                    if (typeof message.commission === "number")
                        object.commission = options.longs === String ? String(message.commission) : message.commission;
                    else
                        object.commission = options.longs === String ? $util.Long.prototype.toString.call(message.commission) : options.longs === Number ? new $util.LongBits(message.commission.low >>> 0, message.commission.high >>> 0).toNumber() : message.commission;
                if (message.profitLoss != null && message.hasOwnProperty("profitLoss"))
                    if (typeof message.profitLoss === "number")
                        object.profitLoss = options.longs === String ? String(message.profitLoss) : message.profitLoss;
                    else
                        object.profitLoss = options.longs === String ? $util.Long.prototype.toString.call(message.profitLoss) : options.longs === Number ? new $util.LongBits(message.profitLoss.low >>> 0, message.profitLoss.high >>> 0).toNumber() : message.profitLoss;
                if (message.placeTime != null && message.hasOwnProperty("placeTime"))
                    if (typeof message.placeTime === "number")
                        object.placeTime = options.longs === String ? String(message.placeTime) : message.placeTime;
                    else
                        object.placeTime = options.longs === String ? $util.Long.prototype.toString.call(message.placeTime) : options.longs === Number ? new $util.LongBits(message.placeTime.low >>> 0, message.placeTime.high >>> 0).toNumber() : message.placeTime;
                if (message.openTime != null && message.hasOwnProperty("openTime"))
                    if (typeof message.openTime === "number")
                        object.openTime = options.longs === String ? String(message.openTime) : message.openTime;
                    else
                        object.openTime = options.longs === String ? $util.Long.prototype.toString.call(message.openTime) : options.longs === Number ? new $util.LongBits(message.openTime.low >>> 0, message.openTime.high >>> 0).toNumber() : message.openTime;
                if (message.closeTime != null && message.hasOwnProperty("closeTime"))
                    if (typeof message.closeTime === "number")
                        object.closeTime = options.longs === String ? String(message.closeTime) : message.closeTime;
                    else
                        object.closeTime = options.longs === String ? $util.Long.prototype.toString.call(message.closeTime) : options.longs === Number ? new $util.LongBits(message.closeTime.low >>> 0, message.closeTime.high >>> 0).toNumber() : message.closeTime;
                if (message.swap != null && message.hasOwnProperty("swap"))
                    if (typeof message.swap === "number")
                        object.swap = options.longs === String ? String(message.swap) : message.swap;
                    else
                        object.swap = options.longs === String ? $util.Long.prototype.toString.call(message.swap) : options.longs === Number ? new $util.LongBits(message.swap.low >>> 0, message.swap.high >>> 0).toNumber() : message.swap;
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (typeof message.insuranceConditionId === "number")
                        object.insuranceConditionId = options.longs === String ? String(message.insuranceConditionId) : message.insuranceConditionId;
                    else
                        object.insuranceConditionId = options.longs === String ? $util.Long.prototype.toString.call(message.insuranceConditionId) : options.longs === Number ? new $util.LongBits(message.insuranceConditionId.low >>> 0, message.insuranceConditionId.high >>> 0).toNumber(true) : message.insuranceConditionId;
                if (message.insuranceFee != null && message.hasOwnProperty("insuranceFee"))
                    if (typeof message.insuranceFee === "number")
                        object.insuranceFee = options.longs === String ? String(message.insuranceFee) : message.insuranceFee;
                    else
                        object.insuranceFee = options.longs === String ? $util.Long.prototype.toString.call(message.insuranceFee) : options.longs === Number ? new $util.LongBits(message.insuranceFee.low >>> 0, message.insuranceFee.high >>> 0).toNumber() : message.insuranceFee;
                if (message.insurancePayout != null && message.hasOwnProperty("insurancePayout"))
                    if (typeof message.insurancePayout === "number")
                        object.insurancePayout = options.longs === String ? String(message.insurancePayout) : message.insurancePayout;
                    else
                        object.insurancePayout = options.longs === String ? $util.Long.prototype.toString.call(message.insurancePayout) : options.longs === Number ? new $util.LongBits(message.insurancePayout.low >>> 0, message.insurancePayout.high >>> 0).toNumber() : message.insurancePayout;
                return object;
            };

            /**
             * Converts this Bet to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.Bet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Bet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Status enum.
             * @name feedbox.trade_api.Bet.Status
             * @enum {number}
             * @property {number} PLACED=0 PLACED value
             * @property {number} OPENED=1 OPENED value
             * @property {number} CLOSED=2 CLOSED value
             * @property {number} CANCELED=3 CANCELED value
             */
            Bet.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PLACED"] = 0;
                values[valuesById[1] = "OPENED"] = 1;
                values[valuesById[2] = "CLOSED"] = 2;
                values[valuesById[3] = "CANCELED"] = 3;
                return values;
            })();

            return Bet;
        })();

        trade_api.BetList = (function() {

            /**
             * Properties of a BetList.
             * @memberof feedbox.trade_api
             * @interface IBetList
             * @property {Array.<feedbox.trade_api.IBet>|null} [bet] BetList bet
             */

            /**
             * Constructs a new BetList.
             * @memberof feedbox.trade_api
             * @classdesc Represents a BetList.
             * @implements IBetList
             * @constructor
             * @param {feedbox.trade_api.IBetList=} [properties] Properties to set
             */
            function BetList(properties) {
                this.bet = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BetList bet.
             * @member {Array.<feedbox.trade_api.IBet>} bet
             * @memberof feedbox.trade_api.BetList
             * @instance
             */
            BetList.prototype.bet = $util.emptyArray;

            /**
             * Creates a new BetList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.BetList
             * @static
             * @param {feedbox.trade_api.IBetList=} [properties] Properties to set
             * @returns {feedbox.trade_api.BetList} BetList instance
             */
            BetList.create = function create(properties) {
                return new BetList(properties);
            };

            /**
             * Encodes the specified BetList message. Does not implicitly {@link feedbox.trade_api.BetList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.BetList
             * @static
             * @param {feedbox.trade_api.IBetList} message BetList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BetList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.bet != null && message.bet.length)
                    for (var i = 0; i < message.bet.length; ++i)
                        $root.feedbox.trade_api.Bet.encode(message.bet[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a BetList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.BetList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.BetList} BetList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BetList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.BetList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.bet && message.bet.length))
                            message.bet = [];
                        message.bet.push($root.feedbox.trade_api.Bet.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a BetList message.
             * @function verify
             * @memberof feedbox.trade_api.BetList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BetList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.bet != null && message.hasOwnProperty("bet")) {
                    if (!Array.isArray(message.bet))
                        return "bet: array expected";
                    for (var i = 0; i < message.bet.length; ++i) {
                        var error = $root.feedbox.trade_api.Bet.verify(message.bet[i]);
                        if (error)
                            return "bet." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a BetList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.BetList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.BetList} BetList
             */
            BetList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.BetList)
                    return object;
                var message = new $root.feedbox.trade_api.BetList();
                if (object.bet) {
                    if (!Array.isArray(object.bet))
                        throw TypeError(".feedbox.trade_api.BetList.bet: array expected");
                    message.bet = [];
                    for (var i = 0; i < object.bet.length; ++i) {
                        if (typeof object.bet[i] !== "object")
                            throw TypeError(".feedbox.trade_api.BetList.bet: object expected");
                        message.bet[i] = $root.feedbox.trade_api.Bet.fromObject(object.bet[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a BetList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.BetList
             * @static
             * @param {feedbox.trade_api.BetList} message BetList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BetList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.bet = [];
                if (message.bet && message.bet.length) {
                    object.bet = [];
                    for (var j = 0; j < message.bet.length; ++j)
                        object.bet[j] = $root.feedbox.trade_api.Bet.toObject(message.bet[j], options);
                }
                return object;
            };

            /**
             * Converts this BetList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.BetList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BetList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BetList;
        })();

        trade_api.GetBetList = (function() {

            /**
             * Properties of a GetBetList.
             * @memberof feedbox.trade_api
             * @interface IGetBetList
             * @property {number|null} [customerAccountId] GetBetList customerAccountId
             * @property {feedbox.trade_api.GetBetList.IByDate|null} [byDate] GetBetList byDate
             */

            /**
             * Constructs a new GetBetList.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetBetList.
             * @implements IGetBetList
             * @constructor
             * @param {feedbox.trade_api.IGetBetList=} [properties] Properties to set
             */
            function GetBetList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetBetList customerAccountId.
             * @member {number} customerAccountId
             * @memberof feedbox.trade_api.GetBetList
             * @instance
             */
            GetBetList.prototype.customerAccountId = 0;

            /**
             * GetBetList byDate.
             * @member {feedbox.trade_api.GetBetList.IByDate|null|undefined} byDate
             * @memberof feedbox.trade_api.GetBetList
             * @instance
             */
            GetBetList.prototype.byDate = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * GetBetList by.
             * @member {"byDate"|undefined} by
             * @memberof feedbox.trade_api.GetBetList
             * @instance
             */
            Object.defineProperty(GetBetList.prototype, "by", {
                get: $util.oneOfGetter($oneOfFields = ["byDate"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new GetBetList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetBetList
             * @static
             * @param {feedbox.trade_api.IGetBetList=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetBetList} GetBetList instance
             */
            GetBetList.create = function create(properties) {
                return new GetBetList(properties);
            };

            /**
             * Encodes the specified GetBetList message. Does not implicitly {@link feedbox.trade_api.GetBetList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetBetList
             * @static
             * @param {feedbox.trade_api.IGetBetList} message GetBetList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBetList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.customerAccountId != null && Object.hasOwnProperty.call(message, "customerAccountId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.customerAccountId);
                if (message.byDate != null && Object.hasOwnProperty.call(message, "byDate"))
                    $root.feedbox.trade_api.GetBetList.ByDate.encode(message.byDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GetBetList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetBetList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetBetList} GetBetList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBetList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetBetList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.customerAccountId = reader.uint32();
                        break;
                    case 2:
                        message.byDate = $root.feedbox.trade_api.GetBetList.ByDate.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetBetList message.
             * @function verify
             * @memberof feedbox.trade_api.GetBetList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetBetList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.customerAccountId != null && message.hasOwnProperty("customerAccountId"))
                    if (!$util.isInteger(message.customerAccountId))
                        return "customerAccountId: integer expected";
                if (message.byDate != null && message.hasOwnProperty("byDate")) {
                    properties.by = 1;
                    {
                        var error = $root.feedbox.trade_api.GetBetList.ByDate.verify(message.byDate);
                        if (error)
                            return "byDate." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GetBetList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetBetList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetBetList} GetBetList
             */
            GetBetList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetBetList)
                    return object;
                var message = new $root.feedbox.trade_api.GetBetList();
                if (object.customerAccountId != null)
                    message.customerAccountId = object.customerAccountId >>> 0;
                if (object.byDate != null) {
                    if (typeof object.byDate !== "object")
                        throw TypeError(".feedbox.trade_api.GetBetList.byDate: object expected");
                    message.byDate = $root.feedbox.trade_api.GetBetList.ByDate.fromObject(object.byDate);
                }
                return message;
            };

            /**
             * Creates a plain object from a GetBetList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetBetList
             * @static
             * @param {feedbox.trade_api.GetBetList} message GetBetList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetBetList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.customerAccountId = 0;
                if (message.customerAccountId != null && message.hasOwnProperty("customerAccountId"))
                    object.customerAccountId = message.customerAccountId;
                if (message.byDate != null && message.hasOwnProperty("byDate")) {
                    object.byDate = $root.feedbox.trade_api.GetBetList.ByDate.toObject(message.byDate, options);
                    if (options.oneofs)
                        object.by = "byDate";
                }
                return object;
            };

            /**
             * Converts this GetBetList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetBetList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetBetList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GetBetList.ByDate = (function() {

                /**
                 * Properties of a ByDate.
                 * @memberof feedbox.trade_api.GetBetList
                 * @interface IByDate
                 * @property {number|Long|null} [fromTime] ByDate fromTime
                 * @property {number|Long|null} [toTime] ByDate toTime
                 * @property {number|null} [offset] ByDate offset
                 * @property {number|null} [limit] ByDate limit
                 * @property {feedbox.trade_api.GetBetList.ByDate.StatusFilter|null} [statusFilter] ByDate statusFilter
                 */

                /**
                 * Constructs a new ByDate.
                 * @memberof feedbox.trade_api.GetBetList
                 * @classdesc Represents a ByDate.
                 * @implements IByDate
                 * @constructor
                 * @param {feedbox.trade_api.GetBetList.IByDate=} [properties] Properties to set
                 */
                function ByDate(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ByDate fromTime.
                 * @member {number|Long} fromTime
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @instance
                 */
                ByDate.prototype.fromTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ByDate toTime.
                 * @member {number|Long} toTime
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @instance
                 */
                ByDate.prototype.toTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * ByDate offset.
                 * @member {number} offset
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @instance
                 */
                ByDate.prototype.offset = 0;

                /**
                 * ByDate limit.
                 * @member {number} limit
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @instance
                 */
                ByDate.prototype.limit = 0;

                /**
                 * ByDate statusFilter.
                 * @member {feedbox.trade_api.GetBetList.ByDate.StatusFilter} statusFilter
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @instance
                 */
                ByDate.prototype.statusFilter = 0;

                /**
                 * Creates a new ByDate instance using the specified properties.
                 * @function create
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @static
                 * @param {feedbox.trade_api.GetBetList.IByDate=} [properties] Properties to set
                 * @returns {feedbox.trade_api.GetBetList.ByDate} ByDate instance
                 */
                ByDate.create = function create(properties) {
                    return new ByDate(properties);
                };

                /**
                 * Encodes the specified ByDate message. Does not implicitly {@link feedbox.trade_api.GetBetList.ByDate.verify|verify} messages.
                 * @function encode
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @static
                 * @param {feedbox.trade_api.GetBetList.IByDate} message ByDate message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ByDate.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.fromTime != null && Object.hasOwnProperty.call(message, "fromTime"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.fromTime);
                    if (message.toTime != null && Object.hasOwnProperty.call(message, "toTime"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.toTime);
                    if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                        writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.offset);
                    if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                        writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.limit);
                    if (message.statusFilter != null && Object.hasOwnProperty.call(message, "statusFilter"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.statusFilter);
                    return writer;
                };

                /**
                 * Decodes a ByDate message from the specified reader or buffer.
                 * @function decode
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {feedbox.trade_api.GetBetList.ByDate} ByDate
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ByDate.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetBetList.ByDate();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.fromTime = reader.int64();
                            break;
                        case 2:
                            message.toTime = reader.int64();
                            break;
                        case 3:
                            message.offset = reader.uint32();
                            break;
                        case 4:
                            message.limit = reader.uint32();
                            break;
                        case 5:
                            message.statusFilter = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ByDate message.
                 * @function verify
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ByDate.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (!$util.isInteger(message.fromTime) && !(message.fromTime && $util.isInteger(message.fromTime.low) && $util.isInteger(message.fromTime.high)))
                            return "fromTime: integer|Long expected";
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (!$util.isInteger(message.toTime) && !(message.toTime && $util.isInteger(message.toTime.low) && $util.isInteger(message.toTime.high)))
                            return "toTime: integer|Long expected";
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        if (!$util.isInteger(message.offset))
                            return "offset: integer expected";
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        if (!$util.isInteger(message.limit))
                            return "limit: integer expected";
                    if (message.statusFilter != null && message.hasOwnProperty("statusFilter"))
                        switch (message.statusFilter) {
                        default:
                            return "statusFilter: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a ByDate message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {feedbox.trade_api.GetBetList.ByDate} ByDate
                 */
                ByDate.fromObject = function fromObject(object) {
                    if (object instanceof $root.feedbox.trade_api.GetBetList.ByDate)
                        return object;
                    var message = new $root.feedbox.trade_api.GetBetList.ByDate();
                    if (object.fromTime != null)
                        if ($util.Long)
                            (message.fromTime = $util.Long.fromValue(object.fromTime)).unsigned = false;
                        else if (typeof object.fromTime === "string")
                            message.fromTime = parseInt(object.fromTime, 10);
                        else if (typeof object.fromTime === "number")
                            message.fromTime = object.fromTime;
                        else if (typeof object.fromTime === "object")
                            message.fromTime = new $util.LongBits(object.fromTime.low >>> 0, object.fromTime.high >>> 0).toNumber();
                    if (object.toTime != null)
                        if ($util.Long)
                            (message.toTime = $util.Long.fromValue(object.toTime)).unsigned = false;
                        else if (typeof object.toTime === "string")
                            message.toTime = parseInt(object.toTime, 10);
                        else if (typeof object.toTime === "number")
                            message.toTime = object.toTime;
                        else if (typeof object.toTime === "object")
                            message.toTime = new $util.LongBits(object.toTime.low >>> 0, object.toTime.high >>> 0).toNumber();
                    if (object.offset != null)
                        message.offset = object.offset >>> 0;
                    if (object.limit != null)
                        message.limit = object.limit >>> 0;
                    switch (object.statusFilter) {
                    case "ALL":
                    case 0:
                        message.statusFilter = 0;
                        break;
                    case "UNFINISHED":
                    case 1:
                        message.statusFilter = 1;
                        break;
                    case "FINISHED":
                    case 2:
                        message.statusFilter = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ByDate message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @static
                 * @param {feedbox.trade_api.GetBetList.ByDate} message ByDate
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ByDate.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.fromTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.fromTime = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.toTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.toTime = options.longs === String ? "0" : 0;
                        object.offset = 0;
                        object.limit = 0;
                        object.statusFilter = options.enums === String ? "ALL" : 0;
                    }
                    if (message.fromTime != null && message.hasOwnProperty("fromTime"))
                        if (typeof message.fromTime === "number")
                            object.fromTime = options.longs === String ? String(message.fromTime) : message.fromTime;
                        else
                            object.fromTime = options.longs === String ? $util.Long.prototype.toString.call(message.fromTime) : options.longs === Number ? new $util.LongBits(message.fromTime.low >>> 0, message.fromTime.high >>> 0).toNumber() : message.fromTime;
                    if (message.toTime != null && message.hasOwnProperty("toTime"))
                        if (typeof message.toTime === "number")
                            object.toTime = options.longs === String ? String(message.toTime) : message.toTime;
                        else
                            object.toTime = options.longs === String ? $util.Long.prototype.toString.call(message.toTime) : options.longs === Number ? new $util.LongBits(message.toTime.low >>> 0, message.toTime.high >>> 0).toNumber() : message.toTime;
                    if (message.offset != null && message.hasOwnProperty("offset"))
                        object.offset = message.offset;
                    if (message.limit != null && message.hasOwnProperty("limit"))
                        object.limit = message.limit;
                    if (message.statusFilter != null && message.hasOwnProperty("statusFilter"))
                        object.statusFilter = options.enums === String ? $root.feedbox.trade_api.GetBetList.ByDate.StatusFilter[message.statusFilter] : message.statusFilter;
                    return object;
                };

                /**
                 * Converts this ByDate to JSON.
                 * @function toJSON
                 * @memberof feedbox.trade_api.GetBetList.ByDate
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ByDate.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * StatusFilter enum.
                 * @name feedbox.trade_api.GetBetList.ByDate.StatusFilter
                 * @enum {number}
                 * @property {number} ALL=0 ALL value
                 * @property {number} UNFINISHED=1 UNFINISHED value
                 * @property {number} FINISHED=2 FINISHED value
                 */
                ByDate.StatusFilter = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "ALL"] = 0;
                    values[valuesById[1] = "UNFINISHED"] = 1;
                    values[valuesById[2] = "FINISHED"] = 2;
                    return values;
                })();

                return ByDate;
            })();

            return GetBetList;
        })();

        trade_api.EditBet = (function() {

            /**
             * Properties of an EditBet.
             * @memberof feedbox.trade_api
             * @interface IEditBet
             * @property {number|Long|null} [betId] EditBet betId
             * @property {number|Long|null} [price] EditBet price
             * @property {feedbox.trade_api.ISLTP|null} [sl] EditBet sl
             * @property {feedbox.trade_api.ISLTP|null} [tp] EditBet tp
             */

            /**
             * Constructs a new EditBet.
             * @memberof feedbox.trade_api
             * @classdesc Represents an EditBet.
             * @implements IEditBet
             * @constructor
             * @param {feedbox.trade_api.IEditBet=} [properties] Properties to set
             */
            function EditBet(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EditBet betId.
             * @member {number|Long} betId
             * @memberof feedbox.trade_api.EditBet
             * @instance
             */
            EditBet.prototype.betId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * EditBet price.
             * @member {number|Long} price
             * @memberof feedbox.trade_api.EditBet
             * @instance
             */
            EditBet.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * EditBet sl.
             * @member {feedbox.trade_api.ISLTP|null|undefined} sl
             * @memberof feedbox.trade_api.EditBet
             * @instance
             */
            EditBet.prototype.sl = null;

            /**
             * EditBet tp.
             * @member {feedbox.trade_api.ISLTP|null|undefined} tp
             * @memberof feedbox.trade_api.EditBet
             * @instance
             */
            EditBet.prototype.tp = null;

            /**
             * Creates a new EditBet instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.EditBet
             * @static
             * @param {feedbox.trade_api.IEditBet=} [properties] Properties to set
             * @returns {feedbox.trade_api.EditBet} EditBet instance
             */
            EditBet.create = function create(properties) {
                return new EditBet(properties);
            };

            /**
             * Encodes the specified EditBet message. Does not implicitly {@link feedbox.trade_api.EditBet.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.EditBet
             * @static
             * @param {feedbox.trade_api.IEditBet} message EditBet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EditBet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.betId != null && Object.hasOwnProperty.call(message, "betId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.betId);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.price);
                if (message.sl != null && Object.hasOwnProperty.call(message, "sl"))
                    $root.feedbox.trade_api.SLTP.encode(message.sl, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.tp != null && Object.hasOwnProperty.call(message, "tp"))
                    $root.feedbox.trade_api.SLTP.encode(message.tp, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EditBet message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.EditBet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.EditBet} EditBet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EditBet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.EditBet();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.betId = reader.uint64();
                        break;
                    case 2:
                        message.price = reader.uint64();
                        break;
                    case 3:
                        message.sl = $root.feedbox.trade_api.SLTP.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.tp = $root.feedbox.trade_api.SLTP.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EditBet message.
             * @function verify
             * @memberof feedbox.trade_api.EditBet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EditBet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (!$util.isInteger(message.betId) && !(message.betId && $util.isInteger(message.betId.low) && $util.isInteger(message.betId.high)))
                        return "betId: integer|Long expected";
                if (message.price != null && message.hasOwnProperty("price"))
                    if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                        return "price: integer|Long expected";
                if (message.sl != null && message.hasOwnProperty("sl")) {
                    var error = $root.feedbox.trade_api.SLTP.verify(message.sl);
                    if (error)
                        return "sl." + error;
                }
                if (message.tp != null && message.hasOwnProperty("tp")) {
                    var error = $root.feedbox.trade_api.SLTP.verify(message.tp);
                    if (error)
                        return "tp." + error;
                }
                return null;
            };

            /**
             * Creates an EditBet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.EditBet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.EditBet} EditBet
             */
            EditBet.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.EditBet)
                    return object;
                var message = new $root.feedbox.trade_api.EditBet();
                if (object.betId != null)
                    if ($util.Long)
                        (message.betId = $util.Long.fromValue(object.betId)).unsigned = true;
                    else if (typeof object.betId === "string")
                        message.betId = parseInt(object.betId, 10);
                    else if (typeof object.betId === "number")
                        message.betId = object.betId;
                    else if (typeof object.betId === "object")
                        message.betId = new $util.LongBits(object.betId.low >>> 0, object.betId.high >>> 0).toNumber(true);
                if (object.price != null)
                    if ($util.Long)
                        (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                    else if (typeof object.price === "string")
                        message.price = parseInt(object.price, 10);
                    else if (typeof object.price === "number")
                        message.price = object.price;
                    else if (typeof object.price === "object")
                        message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                if (object.sl != null) {
                    if (typeof object.sl !== "object")
                        throw TypeError(".feedbox.trade_api.EditBet.sl: object expected");
                    message.sl = $root.feedbox.trade_api.SLTP.fromObject(object.sl);
                }
                if (object.tp != null) {
                    if (typeof object.tp !== "object")
                        throw TypeError(".feedbox.trade_api.EditBet.tp: object expected");
                    message.tp = $root.feedbox.trade_api.SLTP.fromObject(object.tp);
                }
                return message;
            };

            /**
             * Creates a plain object from an EditBet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.EditBet
             * @static
             * @param {feedbox.trade_api.EditBet} message EditBet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EditBet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.betId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.betId = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.price = options.longs === String ? "0" : 0;
                    object.sl = null;
                    object.tp = null;
                }
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (typeof message.betId === "number")
                        object.betId = options.longs === String ? String(message.betId) : message.betId;
                    else
                        object.betId = options.longs === String ? $util.Long.prototype.toString.call(message.betId) : options.longs === Number ? new $util.LongBits(message.betId.low >>> 0, message.betId.high >>> 0).toNumber(true) : message.betId;
                if (message.price != null && message.hasOwnProperty("price"))
                    if (typeof message.price === "number")
                        object.price = options.longs === String ? String(message.price) : message.price;
                    else
                        object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                if (message.sl != null && message.hasOwnProperty("sl"))
                    object.sl = $root.feedbox.trade_api.SLTP.toObject(message.sl, options);
                if (message.tp != null && message.hasOwnProperty("tp"))
                    object.tp = $root.feedbox.trade_api.SLTP.toObject(message.tp, options);
                return object;
            };

            /**
             * Converts this EditBet to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.EditBet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EditBet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EditBet;
        })();

        trade_api.CancelBet = (function() {

            /**
             * Properties of a CancelBet.
             * @memberof feedbox.trade_api
             * @interface ICancelBet
             * @property {string|null} [clientBetId] CancelBet clientBetId
             * @property {number|Long|null} [betId] CancelBet betId
             */

            /**
             * Constructs a new CancelBet.
             * @memberof feedbox.trade_api
             * @classdesc Represents a CancelBet.
             * @implements ICancelBet
             * @constructor
             * @param {feedbox.trade_api.ICancelBet=} [properties] Properties to set
             */
            function CancelBet(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CancelBet clientBetId.
             * @member {string} clientBetId
             * @memberof feedbox.trade_api.CancelBet
             * @instance
             */
            CancelBet.prototype.clientBetId = "";

            /**
             * CancelBet betId.
             * @member {number|Long} betId
             * @memberof feedbox.trade_api.CancelBet
             * @instance
             */
            CancelBet.prototype.betId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new CancelBet instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.CancelBet
             * @static
             * @param {feedbox.trade_api.ICancelBet=} [properties] Properties to set
             * @returns {feedbox.trade_api.CancelBet} CancelBet instance
             */
            CancelBet.create = function create(properties) {
                return new CancelBet(properties);
            };

            /**
             * Encodes the specified CancelBet message. Does not implicitly {@link feedbox.trade_api.CancelBet.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.CancelBet
             * @static
             * @param {feedbox.trade_api.ICancelBet} message CancelBet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CancelBet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.clientBetId != null && Object.hasOwnProperty.call(message, "clientBetId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientBetId);
                if (message.betId != null && Object.hasOwnProperty.call(message, "betId"))
                    writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.betId);
                return writer;
            };

            /**
             * Decodes a CancelBet message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.CancelBet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.CancelBet} CancelBet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CancelBet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.CancelBet();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.clientBetId = reader.string();
                        break;
                    case 2:
                        message.betId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CancelBet message.
             * @function verify
             * @memberof feedbox.trade_api.CancelBet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CancelBet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.clientBetId != null && message.hasOwnProperty("clientBetId"))
                    if (!$util.isString(message.clientBetId))
                        return "clientBetId: string expected";
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (!$util.isInteger(message.betId) && !(message.betId && $util.isInteger(message.betId.low) && $util.isInteger(message.betId.high)))
                        return "betId: integer|Long expected";
                return null;
            };

            /**
             * Creates a CancelBet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.CancelBet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.CancelBet} CancelBet
             */
            CancelBet.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.CancelBet)
                    return object;
                var message = new $root.feedbox.trade_api.CancelBet();
                if (object.clientBetId != null)
                    message.clientBetId = String(object.clientBetId);
                if (object.betId != null)
                    if ($util.Long)
                        (message.betId = $util.Long.fromValue(object.betId)).unsigned = true;
                    else if (typeof object.betId === "string")
                        message.betId = parseInt(object.betId, 10);
                    else if (typeof object.betId === "number")
                        message.betId = object.betId;
                    else if (typeof object.betId === "object")
                        message.betId = new $util.LongBits(object.betId.low >>> 0, object.betId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a CancelBet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.CancelBet
             * @static
             * @param {feedbox.trade_api.CancelBet} message CancelBet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CancelBet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.clientBetId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.betId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.betId = options.longs === String ? "0" : 0;
                }
                if (message.clientBetId != null && message.hasOwnProperty("clientBetId"))
                    object.clientBetId = message.clientBetId;
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (typeof message.betId === "number")
                        object.betId = options.longs === String ? String(message.betId) : message.betId;
                    else
                        object.betId = options.longs === String ? $util.Long.prototype.toString.call(message.betId) : options.longs === Number ? new $util.LongBits(message.betId.low >>> 0, message.betId.high >>> 0).toNumber(true) : message.betId;
                return object;
            };

            /**
             * Converts this CancelBet to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.CancelBet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CancelBet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CancelBet;
        })();

        trade_api.CloseBet = (function() {

            /**
             * Properties of a CloseBet.
             * @memberof feedbox.trade_api
             * @interface ICloseBet
             * @property {number|Long|null} [betId] CloseBet betId
             */

            /**
             * Constructs a new CloseBet.
             * @memberof feedbox.trade_api
             * @classdesc Represents a CloseBet.
             * @implements ICloseBet
             * @constructor
             * @param {feedbox.trade_api.ICloseBet=} [properties] Properties to set
             */
            function CloseBet(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CloseBet betId.
             * @member {number|Long} betId
             * @memberof feedbox.trade_api.CloseBet
             * @instance
             */
            CloseBet.prototype.betId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new CloseBet instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.CloseBet
             * @static
             * @param {feedbox.trade_api.ICloseBet=} [properties] Properties to set
             * @returns {feedbox.trade_api.CloseBet} CloseBet instance
             */
            CloseBet.create = function create(properties) {
                return new CloseBet(properties);
            };

            /**
             * Encodes the specified CloseBet message. Does not implicitly {@link feedbox.trade_api.CloseBet.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.CloseBet
             * @static
             * @param {feedbox.trade_api.ICloseBet} message CloseBet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CloseBet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.betId != null && Object.hasOwnProperty.call(message, "betId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.betId);
                return writer;
            };

            /**
             * Decodes a CloseBet message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.CloseBet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.CloseBet} CloseBet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CloseBet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.CloseBet();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.betId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CloseBet message.
             * @function verify
             * @memberof feedbox.trade_api.CloseBet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CloseBet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (!$util.isInteger(message.betId) && !(message.betId && $util.isInteger(message.betId.low) && $util.isInteger(message.betId.high)))
                        return "betId: integer|Long expected";
                return null;
            };

            /**
             * Creates a CloseBet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.CloseBet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.CloseBet} CloseBet
             */
            CloseBet.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.CloseBet)
                    return object;
                var message = new $root.feedbox.trade_api.CloseBet();
                if (object.betId != null)
                    if ($util.Long)
                        (message.betId = $util.Long.fromValue(object.betId)).unsigned = true;
                    else if (typeof object.betId === "string")
                        message.betId = parseInt(object.betId, 10);
                    else if (typeof object.betId === "number")
                        message.betId = object.betId;
                    else if (typeof object.betId === "object")
                        message.betId = new $util.LongBits(object.betId.low >>> 0, object.betId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a CloseBet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.CloseBet
             * @static
             * @param {feedbox.trade_api.CloseBet} message CloseBet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CloseBet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.betId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.betId = options.longs === String ? "0" : 0;
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (typeof message.betId === "number")
                        object.betId = options.longs === String ? String(message.betId) : message.betId;
                    else
                        object.betId = options.longs === String ? $util.Long.prototype.toString.call(message.betId) : options.longs === Number ? new $util.LongBits(message.betId.low >>> 0, message.betId.high >>> 0).toNumber(true) : message.betId;
                return object;
            };

            /**
             * Converts this CloseBet to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.CloseBet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CloseBet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CloseBet;
        })();

        trade_api.GetCommission = (function() {

            /**
             * Properties of a GetCommission.
             * @memberof feedbox.trade_api
             * @interface IGetCommission
             * @property {string|null} [symbol] GetCommission symbol
             * @property {feedbox.trade_api.Side|null} [side] GetCommission side
             * @property {number|Long|null} [volume] GetCommission volume
             * @property {number|Long|null} [positionId] GetCommission positionId
             */

            /**
             * Constructs a new GetCommission.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetCommission.
             * @implements IGetCommission
             * @constructor
             * @param {feedbox.trade_api.IGetCommission=} [properties] Properties to set
             */
            function GetCommission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetCommission symbol.
             * @member {string} symbol
             * @memberof feedbox.trade_api.GetCommission
             * @instance
             */
            GetCommission.prototype.symbol = "";

            /**
             * GetCommission side.
             * @member {feedbox.trade_api.Side} side
             * @memberof feedbox.trade_api.GetCommission
             * @instance
             */
            GetCommission.prototype.side = 0;

            /**
             * GetCommission volume.
             * @member {number|Long} volume
             * @memberof feedbox.trade_api.GetCommission
             * @instance
             */
            GetCommission.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetCommission positionId.
             * @member {number|Long} positionId
             * @memberof feedbox.trade_api.GetCommission
             * @instance
             */
            GetCommission.prototype.positionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetCommission instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetCommission
             * @static
             * @param {feedbox.trade_api.IGetCommission=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetCommission} GetCommission instance
             */
            GetCommission.create = function create(properties) {
                return new GetCommission(properties);
            };

            /**
             * Encodes the specified GetCommission message. Does not implicitly {@link feedbox.trade_api.GetCommission.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetCommission
             * @static
             * @param {feedbox.trade_api.IGetCommission} message GetCommission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCommission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbol);
                if (message.side != null && Object.hasOwnProperty.call(message, "side"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.side);
                if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.volume);
                if (message.positionId != null && Object.hasOwnProperty.call(message, "positionId"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positionId);
                return writer;
            };

            /**
             * Decodes a GetCommission message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetCommission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetCommission} GetCommission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCommission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetCommission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.symbol = reader.string();
                        break;
                    case 2:
                        message.side = reader.int32();
                        break;
                    case 3:
                        message.volume = reader.uint64();
                        break;
                    case 4:
                        message.positionId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCommission message.
             * @function verify
             * @memberof feedbox.trade_api.GetCommission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCommission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.side != null && message.hasOwnProperty("side"))
                    switch (message.side) {
                    default:
                        return "side: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (!$util.isInteger(message.volume) && !(message.volume && $util.isInteger(message.volume.low) && $util.isInteger(message.volume.high)))
                        return "volume: integer|Long expected";
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    if (!$util.isInteger(message.positionId) && !(message.positionId && $util.isInteger(message.positionId.low) && $util.isInteger(message.positionId.high)))
                        return "positionId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetCommission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetCommission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetCommission} GetCommission
             */
            GetCommission.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetCommission)
                    return object;
                var message = new $root.feedbox.trade_api.GetCommission();
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                switch (object.side) {
                case "BUY":
                case 0:
                    message.side = 0;
                    break;
                case "SELL":
                case 1:
                    message.side = 1;
                    break;
                }
                if (object.volume != null)
                    if ($util.Long)
                        (message.volume = $util.Long.fromValue(object.volume)).unsigned = true;
                    else if (typeof object.volume === "string")
                        message.volume = parseInt(object.volume, 10);
                    else if (typeof object.volume === "number")
                        message.volume = object.volume;
                    else if (typeof object.volume === "object")
                        message.volume = new $util.LongBits(object.volume.low >>> 0, object.volume.high >>> 0).toNumber(true);
                if (object.positionId != null)
                    if ($util.Long)
                        (message.positionId = $util.Long.fromValue(object.positionId)).unsigned = true;
                    else if (typeof object.positionId === "string")
                        message.positionId = parseInt(object.positionId, 10);
                    else if (typeof object.positionId === "number")
                        message.positionId = object.positionId;
                    else if (typeof object.positionId === "object")
                        message.positionId = new $util.LongBits(object.positionId.low >>> 0, object.positionId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetCommission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetCommission
             * @static
             * @param {feedbox.trade_api.GetCommission} message GetCommission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCommission.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.symbol = "";
                    object.side = options.enums === String ? "BUY" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.volume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.volume = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.positionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positionId = options.longs === String ? "0" : 0;
                }
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.side != null && message.hasOwnProperty("side"))
                    object.side = options.enums === String ? $root.feedbox.trade_api.Side[message.side] : message.side;
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (typeof message.volume === "number")
                        object.volume = options.longs === String ? String(message.volume) : message.volume;
                    else
                        object.volume = options.longs === String ? $util.Long.prototype.toString.call(message.volume) : options.longs === Number ? new $util.LongBits(message.volume.low >>> 0, message.volume.high >>> 0).toNumber(true) : message.volume;
                if (message.positionId != null && message.hasOwnProperty("positionId"))
                    if (typeof message.positionId === "number")
                        object.positionId = options.longs === String ? String(message.positionId) : message.positionId;
                    else
                        object.positionId = options.longs === String ? $util.Long.prototype.toString.call(message.positionId) : options.longs === Number ? new $util.LongBits(message.positionId.low >>> 0, message.positionId.high >>> 0).toNumber(true) : message.positionId;
                return object;
            };

            /**
             * Converts this GetCommission to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetCommission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCommission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCommission;
        })();

        trade_api.GetBetCommission = (function() {

            /**
             * Properties of a GetBetCommission.
             * @memberof feedbox.trade_api
             * @interface IGetBetCommission
             * @property {string|null} [symbol] GetBetCommission symbol
             * @property {feedbox.trade_api.Side|null} [side] GetBetCommission side
             * @property {number|Long|null} [volume] GetBetCommission volume
             * @property {number|null} [multiplier] GetBetCommission multiplier
             */

            /**
             * Constructs a new GetBetCommission.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetBetCommission.
             * @implements IGetBetCommission
             * @constructor
             * @param {feedbox.trade_api.IGetBetCommission=} [properties] Properties to set
             */
            function GetBetCommission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetBetCommission symbol.
             * @member {string} symbol
             * @memberof feedbox.trade_api.GetBetCommission
             * @instance
             */
            GetBetCommission.prototype.symbol = "";

            /**
             * GetBetCommission side.
             * @member {feedbox.trade_api.Side} side
             * @memberof feedbox.trade_api.GetBetCommission
             * @instance
             */
            GetBetCommission.prototype.side = 0;

            /**
             * GetBetCommission volume.
             * @member {number|Long} volume
             * @memberof feedbox.trade_api.GetBetCommission
             * @instance
             */
            GetBetCommission.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * GetBetCommission multiplier.
             * @member {number} multiplier
             * @memberof feedbox.trade_api.GetBetCommission
             * @instance
             */
            GetBetCommission.prototype.multiplier = 0;

            /**
             * Creates a new GetBetCommission instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetBetCommission
             * @static
             * @param {feedbox.trade_api.IGetBetCommission=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetBetCommission} GetBetCommission instance
             */
            GetBetCommission.create = function create(properties) {
                return new GetBetCommission(properties);
            };

            /**
             * Encodes the specified GetBetCommission message. Does not implicitly {@link feedbox.trade_api.GetBetCommission.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetBetCommission
             * @static
             * @param {feedbox.trade_api.IGetBetCommission} message GetBetCommission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBetCommission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.symbol != null && Object.hasOwnProperty.call(message, "symbol"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.symbol);
                if (message.side != null && Object.hasOwnProperty.call(message, "side"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.side);
                if (message.volume != null && Object.hasOwnProperty.call(message, "volume"))
                    writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.volume);
                if (message.multiplier != null && Object.hasOwnProperty.call(message, "multiplier"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint32(message.multiplier);
                return writer;
            };

            /**
             * Decodes a GetBetCommission message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetBetCommission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetBetCommission} GetBetCommission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBetCommission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetBetCommission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.symbol = reader.string();
                        break;
                    case 2:
                        message.side = reader.int32();
                        break;
                    case 3:
                        message.volume = reader.uint64();
                        break;
                    case 4:
                        message.multiplier = reader.uint32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetBetCommission message.
             * @function verify
             * @memberof feedbox.trade_api.GetBetCommission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetBetCommission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    if (!$util.isString(message.symbol))
                        return "symbol: string expected";
                if (message.side != null && message.hasOwnProperty("side"))
                    switch (message.side) {
                    default:
                        return "side: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (!$util.isInteger(message.volume) && !(message.volume && $util.isInteger(message.volume.low) && $util.isInteger(message.volume.high)))
                        return "volume: integer|Long expected";
                if (message.multiplier != null && message.hasOwnProperty("multiplier"))
                    if (!$util.isInteger(message.multiplier))
                        return "multiplier: integer expected";
                return null;
            };

            /**
             * Creates a GetBetCommission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetBetCommission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetBetCommission} GetBetCommission
             */
            GetBetCommission.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetBetCommission)
                    return object;
                var message = new $root.feedbox.trade_api.GetBetCommission();
                if (object.symbol != null)
                    message.symbol = String(object.symbol);
                switch (object.side) {
                case "BUY":
                case 0:
                    message.side = 0;
                    break;
                case "SELL":
                case 1:
                    message.side = 1;
                    break;
                }
                if (object.volume != null)
                    if ($util.Long)
                        (message.volume = $util.Long.fromValue(object.volume)).unsigned = true;
                    else if (typeof object.volume === "string")
                        message.volume = parseInt(object.volume, 10);
                    else if (typeof object.volume === "number")
                        message.volume = object.volume;
                    else if (typeof object.volume === "object")
                        message.volume = new $util.LongBits(object.volume.low >>> 0, object.volume.high >>> 0).toNumber(true);
                if (object.multiplier != null)
                    message.multiplier = object.multiplier >>> 0;
                return message;
            };

            /**
             * Creates a plain object from a GetBetCommission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetBetCommission
             * @static
             * @param {feedbox.trade_api.GetBetCommission} message GetBetCommission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetBetCommission.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.symbol = "";
                    object.side = options.enums === String ? "BUY" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.volume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.volume = options.longs === String ? "0" : 0;
                    object.multiplier = 0;
                }
                if (message.symbol != null && message.hasOwnProperty("symbol"))
                    object.symbol = message.symbol;
                if (message.side != null && message.hasOwnProperty("side"))
                    object.side = options.enums === String ? $root.feedbox.trade_api.Side[message.side] : message.side;
                if (message.volume != null && message.hasOwnProperty("volume"))
                    if (typeof message.volume === "number")
                        object.volume = options.longs === String ? String(message.volume) : message.volume;
                    else
                        object.volume = options.longs === String ? $util.Long.prototype.toString.call(message.volume) : options.longs === Number ? new $util.LongBits(message.volume.low >>> 0, message.volume.high >>> 0).toNumber(true) : message.volume;
                if (message.multiplier != null && message.hasOwnProperty("multiplier"))
                    object.multiplier = message.multiplier;
                return object;
            };

            /**
             * Converts this GetBetCommission to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetBetCommission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetBetCommission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetBetCommission;
        })();

        trade_api.GetCloseBetCommission = (function() {

            /**
             * Properties of a GetCloseBetCommission.
             * @memberof feedbox.trade_api
             * @interface IGetCloseBetCommission
             * @property {number|Long|null} [betId] GetCloseBetCommission betId
             */

            /**
             * Constructs a new GetCloseBetCommission.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetCloseBetCommission.
             * @implements IGetCloseBetCommission
             * @constructor
             * @param {feedbox.trade_api.IGetCloseBetCommission=} [properties] Properties to set
             */
            function GetCloseBetCommission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GetCloseBetCommission betId.
             * @member {number|Long} betId
             * @memberof feedbox.trade_api.GetCloseBetCommission
             * @instance
             */
            GetCloseBetCommission.prototype.betId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * Creates a new GetCloseBetCommission instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetCloseBetCommission
             * @static
             * @param {feedbox.trade_api.IGetCloseBetCommission=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetCloseBetCommission} GetCloseBetCommission instance
             */
            GetCloseBetCommission.create = function create(properties) {
                return new GetCloseBetCommission(properties);
            };

            /**
             * Encodes the specified GetCloseBetCommission message. Does not implicitly {@link feedbox.trade_api.GetCloseBetCommission.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetCloseBetCommission
             * @static
             * @param {feedbox.trade_api.IGetCloseBetCommission} message GetCloseBetCommission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCloseBetCommission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.betId != null && Object.hasOwnProperty.call(message, "betId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.betId);
                return writer;
            };

            /**
             * Decodes a GetCloseBetCommission message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetCloseBetCommission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetCloseBetCommission} GetCloseBetCommission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCloseBetCommission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetCloseBetCommission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.betId = reader.uint64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCloseBetCommission message.
             * @function verify
             * @memberof feedbox.trade_api.GetCloseBetCommission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCloseBetCommission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (!$util.isInteger(message.betId) && !(message.betId && $util.isInteger(message.betId.low) && $util.isInteger(message.betId.high)))
                        return "betId: integer|Long expected";
                return null;
            };

            /**
             * Creates a GetCloseBetCommission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetCloseBetCommission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetCloseBetCommission} GetCloseBetCommission
             */
            GetCloseBetCommission.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetCloseBetCommission)
                    return object;
                var message = new $root.feedbox.trade_api.GetCloseBetCommission();
                if (object.betId != null)
                    if ($util.Long)
                        (message.betId = $util.Long.fromValue(object.betId)).unsigned = true;
                    else if (typeof object.betId === "string")
                        message.betId = parseInt(object.betId, 10);
                    else if (typeof object.betId === "number")
                        message.betId = object.betId;
                    else if (typeof object.betId === "object")
                        message.betId = new $util.LongBits(object.betId.low >>> 0, object.betId.high >>> 0).toNumber(true);
                return message;
            };

            /**
             * Creates a plain object from a GetCloseBetCommission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetCloseBetCommission
             * @static
             * @param {feedbox.trade_api.GetCloseBetCommission} message GetCloseBetCommission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCloseBetCommission.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.betId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.betId = options.longs === String ? "0" : 0;
                if (message.betId != null && message.hasOwnProperty("betId"))
                    if (typeof message.betId === "number")
                        object.betId = options.longs === String ? String(message.betId) : message.betId;
                    else
                        object.betId = options.longs === String ? $util.Long.prototype.toString.call(message.betId) : options.longs === Number ? new $util.LongBits(message.betId.low >>> 0, message.betId.high >>> 0).toNumber(true) : message.betId;
                return object;
            };

            /**
             * Converts this GetCloseBetCommission to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetCloseBetCommission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCloseBetCommission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCloseBetCommission;
        })();

        trade_api.Commission = (function() {

            /**
             * Properties of a Commission.
             * @memberof feedbox.trade_api
             * @interface ICommission
             * @property {number|Long|null} [amount] Commission amount
             * @property {feedbox.trade_api.ICommissionConfig|null} [commissionConfig] Commission commissionConfig
             */

            /**
             * Constructs a new Commission.
             * @memberof feedbox.trade_api
             * @classdesc Represents a Commission.
             * @implements ICommission
             * @constructor
             * @param {feedbox.trade_api.ICommission=} [properties] Properties to set
             */
            function Commission(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Commission amount.
             * @member {number|Long} amount
             * @memberof feedbox.trade_api.Commission
             * @instance
             */
            Commission.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Commission commissionConfig.
             * @member {feedbox.trade_api.ICommissionConfig|null|undefined} commissionConfig
             * @memberof feedbox.trade_api.Commission
             * @instance
             */
            Commission.prototype.commissionConfig = null;

            /**
             * Creates a new Commission instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.Commission
             * @static
             * @param {feedbox.trade_api.ICommission=} [properties] Properties to set
             * @returns {feedbox.trade_api.Commission} Commission instance
             */
            Commission.create = function create(properties) {
                return new Commission(properties);
            };

            /**
             * Encodes the specified Commission message. Does not implicitly {@link feedbox.trade_api.Commission.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.Commission
             * @static
             * @param {feedbox.trade_api.ICommission} message Commission message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Commission.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.amount);
                if (message.commissionConfig != null && Object.hasOwnProperty.call(message, "commissionConfig"))
                    $root.feedbox.trade_api.CommissionConfig.encode(message.commissionConfig, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Commission message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.Commission
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.Commission} Commission
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Commission.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.Commission();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.amount = reader.int64();
                        break;
                    case 2:
                        message.commissionConfig = $root.feedbox.trade_api.CommissionConfig.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Commission message.
             * @function verify
             * @memberof feedbox.trade_api.Commission
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Commission.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                        return "amount: integer|Long expected";
                if (message.commissionConfig != null && message.hasOwnProperty("commissionConfig")) {
                    var error = $root.feedbox.trade_api.CommissionConfig.verify(message.commissionConfig);
                    if (error)
                        return "commissionConfig." + error;
                }
                return null;
            };

            /**
             * Creates a Commission message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.Commission
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.Commission} Commission
             */
            Commission.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.Commission)
                    return object;
                var message = new $root.feedbox.trade_api.Commission();
                if (object.amount != null)
                    if ($util.Long)
                        (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
                    else if (typeof object.amount === "string")
                        message.amount = parseInt(object.amount, 10);
                    else if (typeof object.amount === "number")
                        message.amount = object.amount;
                    else if (typeof object.amount === "object")
                        message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
                if (object.commissionConfig != null) {
                    if (typeof object.commissionConfig !== "object")
                        throw TypeError(".feedbox.trade_api.Commission.commissionConfig: object expected");
                    message.commissionConfig = $root.feedbox.trade_api.CommissionConfig.fromObject(object.commissionConfig);
                }
                return message;
            };

            /**
             * Creates a plain object from a Commission message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.Commission
             * @static
             * @param {feedbox.trade_api.Commission} message Commission
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Commission.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.amount = options.longs === String ? "0" : 0;
                    object.commissionConfig = null;
                }
                if (message.amount != null && message.hasOwnProperty("amount"))
                    if (typeof message.amount === "number")
                        object.amount = options.longs === String ? String(message.amount) : message.amount;
                    else
                        object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
                if (message.commissionConfig != null && message.hasOwnProperty("commissionConfig"))
                    object.commissionConfig = $root.feedbox.trade_api.CommissionConfig.toObject(message.commissionConfig, options);
                return object;
            };

            /**
             * Converts this Commission to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.Commission
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Commission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Commission;
        })();

        trade_api.CommissionConfig = (function() {

            /**
             * Properties of a CommissionConfig.
             * @memberof feedbox.trade_api
             * @interface ICommissionConfig
             * @property {feedbox.trade_api.CommissionConfig.PeriodType|null} [periodType] CommissionConfig periodType
             * @property {number|null} [percent] CommissionConfig percent
             * @property {number|null} [point] CommissionConfig point
             * @property {number|Long|null} [baseCurrency] CommissionConfig baseCurrency
             * @property {number|Long|null} [usd] CommissionConfig usd
             * @property {number|Long|null} [lot] CommissionConfig lot
             * @property {number|null} [execution] CommissionConfig execution
             * @property {number|null} [order] CommissionConfig order
             * @property {number|Long|null} [usdVolume] CommissionConfig usdVolume
             * @property {number|Long|null} [baseCurrencyVolume] CommissionConfig baseCurrencyVolume
             * @property {feedbox.trade_api.CommissionConfig.ChargeCondition|null} [chargeCondition] CommissionConfig chargeCondition
             */

            /**
             * Constructs a new CommissionConfig.
             * @memberof feedbox.trade_api
             * @classdesc Represents a CommissionConfig.
             * @implements ICommissionConfig
             * @constructor
             * @param {feedbox.trade_api.ICommissionConfig=} [properties] Properties to set
             */
            function CommissionConfig(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CommissionConfig periodType.
             * @member {feedbox.trade_api.CommissionConfig.PeriodType} periodType
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            CommissionConfig.prototype.periodType = 0;

            /**
             * CommissionConfig percent.
             * @member {number} percent
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            CommissionConfig.prototype.percent = 0;

            /**
             * CommissionConfig point.
             * @member {number} point
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            CommissionConfig.prototype.point = 0;

            /**
             * CommissionConfig baseCurrency.
             * @member {number|Long} baseCurrency
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            CommissionConfig.prototype.baseCurrency = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * CommissionConfig usd.
             * @member {number|Long} usd
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            CommissionConfig.prototype.usd = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * CommissionConfig lot.
             * @member {number|Long} lot
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            CommissionConfig.prototype.lot = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CommissionConfig execution.
             * @member {number} execution
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            CommissionConfig.prototype.execution = 0;

            /**
             * CommissionConfig order.
             * @member {number} order
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            CommissionConfig.prototype.order = 0;

            /**
             * CommissionConfig usdVolume.
             * @member {number|Long} usdVolume
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            CommissionConfig.prototype.usdVolume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CommissionConfig baseCurrencyVolume.
             * @member {number|Long} baseCurrencyVolume
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            CommissionConfig.prototype.baseCurrencyVolume = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * CommissionConfig chargeCondition.
             * @member {feedbox.trade_api.CommissionConfig.ChargeCondition} chargeCondition
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            CommissionConfig.prototype.chargeCondition = 0;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * CommissionConfig commissionIn.
             * @member {"percent"|"point"|"baseCurrency"|"usd"|undefined} commissionIn
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            Object.defineProperty(CommissionConfig.prototype, "commissionIn", {
                get: $util.oneOfGetter($oneOfFields = ["percent", "point", "baseCurrency", "usd"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * CommissionConfig commissionFor.
             * @member {"lot"|"execution"|"order"|"usdVolume"|"baseCurrencyVolume"|undefined} commissionFor
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             */
            Object.defineProperty(CommissionConfig.prototype, "commissionFor", {
                get: $util.oneOfGetter($oneOfFields = ["lot", "execution", "order", "usdVolume", "baseCurrencyVolume"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new CommissionConfig instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.CommissionConfig
             * @static
             * @param {feedbox.trade_api.ICommissionConfig=} [properties] Properties to set
             * @returns {feedbox.trade_api.CommissionConfig} CommissionConfig instance
             */
            CommissionConfig.create = function create(properties) {
                return new CommissionConfig(properties);
            };

            /**
             * Encodes the specified CommissionConfig message. Does not implicitly {@link feedbox.trade_api.CommissionConfig.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.CommissionConfig
             * @static
             * @param {feedbox.trade_api.ICommissionConfig} message CommissionConfig message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CommissionConfig.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.periodType != null && Object.hasOwnProperty.call(message, "periodType"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.periodType);
                if (message.percent != null && Object.hasOwnProperty.call(message, "percent"))
                    writer.uint32(/* id 7, wireType 1 =*/57).double(message.percent);
                if (message.point != null && Object.hasOwnProperty.call(message, "point"))
                    writer.uint32(/* id 8, wireType 1 =*/65).double(message.point);
                if (message.baseCurrency != null && Object.hasOwnProperty.call(message, "baseCurrency"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.baseCurrency);
                if (message.usd != null && Object.hasOwnProperty.call(message, "usd"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int64(message.usd);
                if (message.lot != null && Object.hasOwnProperty.call(message, "lot"))
                    writer.uint32(/* id 11, wireType 0 =*/88).uint64(message.lot);
                if (message.execution != null && Object.hasOwnProperty.call(message, "execution"))
                    writer.uint32(/* id 12, wireType 0 =*/96).uint32(message.execution);
                if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                    writer.uint32(/* id 13, wireType 0 =*/104).uint32(message.order);
                if (message.usdVolume != null && Object.hasOwnProperty.call(message, "usdVolume"))
                    writer.uint32(/* id 14, wireType 0 =*/112).uint64(message.usdVolume);
                if (message.baseCurrencyVolume != null && Object.hasOwnProperty.call(message, "baseCurrencyVolume"))
                    writer.uint32(/* id 15, wireType 0 =*/120).uint64(message.baseCurrencyVolume);
                if (message.chargeCondition != null && Object.hasOwnProperty.call(message, "chargeCondition"))
                    writer.uint32(/* id 16, wireType 0 =*/128).int32(message.chargeCondition);
                return writer;
            };

            /**
             * Decodes a CommissionConfig message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.CommissionConfig
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.CommissionConfig} CommissionConfig
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CommissionConfig.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.CommissionConfig();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.periodType = reader.int32();
                        break;
                    case 7:
                        message.percent = reader.double();
                        break;
                    case 8:
                        message.point = reader.double();
                        break;
                    case 9:
                        message.baseCurrency = reader.int64();
                        break;
                    case 10:
                        message.usd = reader.int64();
                        break;
                    case 11:
                        message.lot = reader.uint64();
                        break;
                    case 12:
                        message.execution = reader.uint32();
                        break;
                    case 13:
                        message.order = reader.uint32();
                        break;
                    case 14:
                        message.usdVolume = reader.uint64();
                        break;
                    case 15:
                        message.baseCurrencyVolume = reader.uint64();
                        break;
                    case 16:
                        message.chargeCondition = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CommissionConfig message.
             * @function verify
             * @memberof feedbox.trade_api.CommissionConfig
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CommissionConfig.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.periodType != null && message.hasOwnProperty("periodType"))
                    switch (message.periodType) {
                    default:
                        return "periodType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.percent != null && message.hasOwnProperty("percent")) {
                    properties.commissionIn = 1;
                    if (typeof message.percent !== "number")
                        return "percent: number expected";
                }
                if (message.point != null && message.hasOwnProperty("point")) {
                    if (properties.commissionIn === 1)
                        return "commissionIn: multiple values";
                    properties.commissionIn = 1;
                    if (typeof message.point !== "number")
                        return "point: number expected";
                }
                if (message.baseCurrency != null && message.hasOwnProperty("baseCurrency")) {
                    if (properties.commissionIn === 1)
                        return "commissionIn: multiple values";
                    properties.commissionIn = 1;
                    if (!$util.isInteger(message.baseCurrency) && !(message.baseCurrency && $util.isInteger(message.baseCurrency.low) && $util.isInteger(message.baseCurrency.high)))
                        return "baseCurrency: integer|Long expected";
                }
                if (message.usd != null && message.hasOwnProperty("usd")) {
                    if (properties.commissionIn === 1)
                        return "commissionIn: multiple values";
                    properties.commissionIn = 1;
                    if (!$util.isInteger(message.usd) && !(message.usd && $util.isInteger(message.usd.low) && $util.isInteger(message.usd.high)))
                        return "usd: integer|Long expected";
                }
                if (message.lot != null && message.hasOwnProperty("lot")) {
                    properties.commissionFor = 1;
                    if (!$util.isInteger(message.lot) && !(message.lot && $util.isInteger(message.lot.low) && $util.isInteger(message.lot.high)))
                        return "lot: integer|Long expected";
                }
                if (message.execution != null && message.hasOwnProperty("execution")) {
                    if (properties.commissionFor === 1)
                        return "commissionFor: multiple values";
                    properties.commissionFor = 1;
                    if (!$util.isInteger(message.execution))
                        return "execution: integer expected";
                }
                if (message.order != null && message.hasOwnProperty("order")) {
                    if (properties.commissionFor === 1)
                        return "commissionFor: multiple values";
                    properties.commissionFor = 1;
                    if (!$util.isInteger(message.order))
                        return "order: integer expected";
                }
                if (message.usdVolume != null && message.hasOwnProperty("usdVolume")) {
                    if (properties.commissionFor === 1)
                        return "commissionFor: multiple values";
                    properties.commissionFor = 1;
                    if (!$util.isInteger(message.usdVolume) && !(message.usdVolume && $util.isInteger(message.usdVolume.low) && $util.isInteger(message.usdVolume.high)))
                        return "usdVolume: integer|Long expected";
                }
                if (message.baseCurrencyVolume != null && message.hasOwnProperty("baseCurrencyVolume")) {
                    if (properties.commissionFor === 1)
                        return "commissionFor: multiple values";
                    properties.commissionFor = 1;
                    if (!$util.isInteger(message.baseCurrencyVolume) && !(message.baseCurrencyVolume && $util.isInteger(message.baseCurrencyVolume.low) && $util.isInteger(message.baseCurrencyVolume.high)))
                        return "baseCurrencyVolume: integer|Long expected";
                }
                if (message.chargeCondition != null && message.hasOwnProperty("chargeCondition"))
                    switch (message.chargeCondition) {
                    default:
                        return "chargeCondition: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };

            /**
             * Creates a CommissionConfig message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.CommissionConfig
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.CommissionConfig} CommissionConfig
             */
            CommissionConfig.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.CommissionConfig)
                    return object;
                var message = new $root.feedbox.trade_api.CommissionConfig();
                switch (object.periodType) {
                case "NONE":
                case 0:
                    message.periodType = 0;
                    break;
                case "DAILY":
                case 1:
                    message.periodType = 1;
                    break;
                case "MONTHLY":
                case 2:
                    message.periodType = 2;
                    break;
                case "INSTANT":
                case 3:
                    message.periodType = 3;
                    break;
                }
                if (object.percent != null)
                    message.percent = Number(object.percent);
                if (object.point != null)
                    message.point = Number(object.point);
                if (object.baseCurrency != null)
                    if ($util.Long)
                        (message.baseCurrency = $util.Long.fromValue(object.baseCurrency)).unsigned = false;
                    else if (typeof object.baseCurrency === "string")
                        message.baseCurrency = parseInt(object.baseCurrency, 10);
                    else if (typeof object.baseCurrency === "number")
                        message.baseCurrency = object.baseCurrency;
                    else if (typeof object.baseCurrency === "object")
                        message.baseCurrency = new $util.LongBits(object.baseCurrency.low >>> 0, object.baseCurrency.high >>> 0).toNumber();
                if (object.usd != null)
                    if ($util.Long)
                        (message.usd = $util.Long.fromValue(object.usd)).unsigned = false;
                    else if (typeof object.usd === "string")
                        message.usd = parseInt(object.usd, 10);
                    else if (typeof object.usd === "number")
                        message.usd = object.usd;
                    else if (typeof object.usd === "object")
                        message.usd = new $util.LongBits(object.usd.low >>> 0, object.usd.high >>> 0).toNumber();
                if (object.lot != null)
                    if ($util.Long)
                        (message.lot = $util.Long.fromValue(object.lot)).unsigned = true;
                    else if (typeof object.lot === "string")
                        message.lot = parseInt(object.lot, 10);
                    else if (typeof object.lot === "number")
                        message.lot = object.lot;
                    else if (typeof object.lot === "object")
                        message.lot = new $util.LongBits(object.lot.low >>> 0, object.lot.high >>> 0).toNumber(true);
                if (object.execution != null)
                    message.execution = object.execution >>> 0;
                if (object.order != null)
                    message.order = object.order >>> 0;
                if (object.usdVolume != null)
                    if ($util.Long)
                        (message.usdVolume = $util.Long.fromValue(object.usdVolume)).unsigned = true;
                    else if (typeof object.usdVolume === "string")
                        message.usdVolume = parseInt(object.usdVolume, 10);
                    else if (typeof object.usdVolume === "number")
                        message.usdVolume = object.usdVolume;
                    else if (typeof object.usdVolume === "object")
                        message.usdVolume = new $util.LongBits(object.usdVolume.low >>> 0, object.usdVolume.high >>> 0).toNumber(true);
                if (object.baseCurrencyVolume != null)
                    if ($util.Long)
                        (message.baseCurrencyVolume = $util.Long.fromValue(object.baseCurrencyVolume)).unsigned = true;
                    else if (typeof object.baseCurrencyVolume === "string")
                        message.baseCurrencyVolume = parseInt(object.baseCurrencyVolume, 10);
                    else if (typeof object.baseCurrencyVolume === "number")
                        message.baseCurrencyVolume = object.baseCurrencyVolume;
                    else if (typeof object.baseCurrencyVolume === "object")
                        message.baseCurrencyVolume = new $util.LongBits(object.baseCurrencyVolume.low >>> 0, object.baseCurrencyVolume.high >>> 0).toNumber(true);
                switch (object.chargeCondition) {
                case "OPENING_OR_CLOSING_POSITION":
                case 0:
                    message.chargeCondition = 0;
                    break;
                case "OPENING_POSITION":
                case 1:
                    message.chargeCondition = 1;
                    break;
                case "CLOSING_POSITION":
                case 2:
                    message.chargeCondition = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a CommissionConfig message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.CommissionConfig
             * @static
             * @param {feedbox.trade_api.CommissionConfig} message CommissionConfig
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CommissionConfig.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.periodType = options.enums === String ? "NONE" : 0;
                    object.chargeCondition = options.enums === String ? "OPENING_OR_CLOSING_POSITION" : 0;
                }
                if (message.periodType != null && message.hasOwnProperty("periodType"))
                    object.periodType = options.enums === String ? $root.feedbox.trade_api.CommissionConfig.PeriodType[message.periodType] : message.periodType;
                if (message.percent != null && message.hasOwnProperty("percent")) {
                    object.percent = options.json && !isFinite(message.percent) ? String(message.percent) : message.percent;
                    if (options.oneofs)
                        object.commissionIn = "percent";
                }
                if (message.point != null && message.hasOwnProperty("point")) {
                    object.point = options.json && !isFinite(message.point) ? String(message.point) : message.point;
                    if (options.oneofs)
                        object.commissionIn = "point";
                }
                if (message.baseCurrency != null && message.hasOwnProperty("baseCurrency")) {
                    if (typeof message.baseCurrency === "number")
                        object.baseCurrency = options.longs === String ? String(message.baseCurrency) : message.baseCurrency;
                    else
                        object.baseCurrency = options.longs === String ? $util.Long.prototype.toString.call(message.baseCurrency) : options.longs === Number ? new $util.LongBits(message.baseCurrency.low >>> 0, message.baseCurrency.high >>> 0).toNumber() : message.baseCurrency;
                    if (options.oneofs)
                        object.commissionIn = "baseCurrency";
                }
                if (message.usd != null && message.hasOwnProperty("usd")) {
                    if (typeof message.usd === "number")
                        object.usd = options.longs === String ? String(message.usd) : message.usd;
                    else
                        object.usd = options.longs === String ? $util.Long.prototype.toString.call(message.usd) : options.longs === Number ? new $util.LongBits(message.usd.low >>> 0, message.usd.high >>> 0).toNumber() : message.usd;
                    if (options.oneofs)
                        object.commissionIn = "usd";
                }
                if (message.lot != null && message.hasOwnProperty("lot")) {
                    if (typeof message.lot === "number")
                        object.lot = options.longs === String ? String(message.lot) : message.lot;
                    else
                        object.lot = options.longs === String ? $util.Long.prototype.toString.call(message.lot) : options.longs === Number ? new $util.LongBits(message.lot.low >>> 0, message.lot.high >>> 0).toNumber(true) : message.lot;
                    if (options.oneofs)
                        object.commissionFor = "lot";
                }
                if (message.execution != null && message.hasOwnProperty("execution")) {
                    object.execution = message.execution;
                    if (options.oneofs)
                        object.commissionFor = "execution";
                }
                if (message.order != null && message.hasOwnProperty("order")) {
                    object.order = message.order;
                    if (options.oneofs)
                        object.commissionFor = "order";
                }
                if (message.usdVolume != null && message.hasOwnProperty("usdVolume")) {
                    if (typeof message.usdVolume === "number")
                        object.usdVolume = options.longs === String ? String(message.usdVolume) : message.usdVolume;
                    else
                        object.usdVolume = options.longs === String ? $util.Long.prototype.toString.call(message.usdVolume) : options.longs === Number ? new $util.LongBits(message.usdVolume.low >>> 0, message.usdVolume.high >>> 0).toNumber(true) : message.usdVolume;
                    if (options.oneofs)
                        object.commissionFor = "usdVolume";
                }
                if (message.baseCurrencyVolume != null && message.hasOwnProperty("baseCurrencyVolume")) {
                    if (typeof message.baseCurrencyVolume === "number")
                        object.baseCurrencyVolume = options.longs === String ? String(message.baseCurrencyVolume) : message.baseCurrencyVolume;
                    else
                        object.baseCurrencyVolume = options.longs === String ? $util.Long.prototype.toString.call(message.baseCurrencyVolume) : options.longs === Number ? new $util.LongBits(message.baseCurrencyVolume.low >>> 0, message.baseCurrencyVolume.high >>> 0).toNumber(true) : message.baseCurrencyVolume;
                    if (options.oneofs)
                        object.commissionFor = "baseCurrencyVolume";
                }
                if (message.chargeCondition != null && message.hasOwnProperty("chargeCondition"))
                    object.chargeCondition = options.enums === String ? $root.feedbox.trade_api.CommissionConfig.ChargeCondition[message.chargeCondition] : message.chargeCondition;
                return object;
            };

            /**
             * Converts this CommissionConfig to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.CommissionConfig
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CommissionConfig.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * PeriodType enum.
             * @name feedbox.trade_api.CommissionConfig.PeriodType
             * @enum {number}
             * @property {number} NONE=0 NONE value
             * @property {number} DAILY=1 DAILY value
             * @property {number} MONTHLY=2 MONTHLY value
             * @property {number} INSTANT=3 INSTANT value
             */
            CommissionConfig.PeriodType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NONE"] = 0;
                values[valuesById[1] = "DAILY"] = 1;
                values[valuesById[2] = "MONTHLY"] = 2;
                values[valuesById[3] = "INSTANT"] = 3;
                return values;
            })();

            /**
             * ChargeCondition enum.
             * @name feedbox.trade_api.CommissionConfig.ChargeCondition
             * @enum {number}
             * @property {number} OPENING_OR_CLOSING_POSITION=0 OPENING_OR_CLOSING_POSITION value
             * @property {number} OPENING_POSITION=1 OPENING_POSITION value
             * @property {number} CLOSING_POSITION=2 CLOSING_POSITION value
             */
            CommissionConfig.ChargeCondition = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "OPENING_OR_CLOSING_POSITION"] = 0;
                values[valuesById[1] = "OPENING_POSITION"] = 1;
                values[valuesById[2] = "CLOSING_POSITION"] = 2;
                return values;
            })();

            return CommissionConfig;
        })();

        trade_api.GetCurrencyList = (function() {

            /**
             * Properties of a GetCurrencyList.
             * @memberof feedbox.trade_api
             * @interface IGetCurrencyList
             */

            /**
             * Constructs a new GetCurrencyList.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetCurrencyList.
             * @implements IGetCurrencyList
             * @constructor
             * @param {feedbox.trade_api.IGetCurrencyList=} [properties] Properties to set
             */
            function GetCurrencyList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCurrencyList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetCurrencyList
             * @static
             * @param {feedbox.trade_api.IGetCurrencyList=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetCurrencyList} GetCurrencyList instance
             */
            GetCurrencyList.create = function create(properties) {
                return new GetCurrencyList(properties);
            };

            /**
             * Encodes the specified GetCurrencyList message. Does not implicitly {@link feedbox.trade_api.GetCurrencyList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetCurrencyList
             * @static
             * @param {feedbox.trade_api.IGetCurrencyList} message GetCurrencyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCurrencyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetCurrencyList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetCurrencyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetCurrencyList} GetCurrencyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCurrencyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetCurrencyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetCurrencyList message.
             * @function verify
             * @memberof feedbox.trade_api.GetCurrencyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCurrencyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCurrencyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetCurrencyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetCurrencyList} GetCurrencyList
             */
            GetCurrencyList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetCurrencyList)
                    return object;
                return new $root.feedbox.trade_api.GetCurrencyList();
            };

            /**
             * Creates a plain object from a GetCurrencyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetCurrencyList
             * @static
             * @param {feedbox.trade_api.GetCurrencyList} message GetCurrencyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCurrencyList.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCurrencyList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetCurrencyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCurrencyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetCurrencyList;
        })();

        trade_api.Currency = (function() {

            /**
             * Properties of a Currency.
             * @memberof feedbox.trade_api
             * @interface ICurrency
             * @property {string|null} [code] Currency code
             * @property {number|null} [digits] Currency digits
             */

            /**
             * Constructs a new Currency.
             * @memberof feedbox.trade_api
             * @classdesc Represents a Currency.
             * @implements ICurrency
             * @constructor
             * @param {feedbox.trade_api.ICurrency=} [properties] Properties to set
             */
            function Currency(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Currency code.
             * @member {string} code
             * @memberof feedbox.trade_api.Currency
             * @instance
             */
            Currency.prototype.code = "";

            /**
             * Currency digits.
             * @member {number} digits
             * @memberof feedbox.trade_api.Currency
             * @instance
             */
            Currency.prototype.digits = 0;

            /**
             * Creates a new Currency instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.Currency
             * @static
             * @param {feedbox.trade_api.ICurrency=} [properties] Properties to set
             * @returns {feedbox.trade_api.Currency} Currency instance
             */
            Currency.create = function create(properties) {
                return new Currency(properties);
            };

            /**
             * Encodes the specified Currency message. Does not implicitly {@link feedbox.trade_api.Currency.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.Currency
             * @static
             * @param {feedbox.trade_api.ICurrency} message Currency message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Currency.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                if (message.digits != null && Object.hasOwnProperty.call(message, "digits"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.digits);
                return writer;
            };

            /**
             * Decodes a Currency message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.Currency
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.Currency} Currency
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Currency.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.Currency();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.code = reader.string();
                        break;
                    case 3:
                        message.digits = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a Currency message.
             * @function verify
             * @memberof feedbox.trade_api.Currency
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Currency.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.digits != null && message.hasOwnProperty("digits"))
                    if (!$util.isInteger(message.digits))
                        return "digits: integer expected";
                return null;
            };

            /**
             * Creates a Currency message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.Currency
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.Currency} Currency
             */
            Currency.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.Currency)
                    return object;
                var message = new $root.feedbox.trade_api.Currency();
                if (object.code != null)
                    message.code = String(object.code);
                if (object.digits != null)
                    message.digits = object.digits | 0;
                return message;
            };

            /**
             * Creates a plain object from a Currency message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.Currency
             * @static
             * @param {feedbox.trade_api.Currency} message Currency
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Currency.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.code = "";
                    object.digits = 0;
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.digits != null && message.hasOwnProperty("digits"))
                    object.digits = message.digits;
                return object;
            };

            /**
             * Converts this Currency to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.Currency
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Currency.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Currency;
        })();

        trade_api.CurrencyList = (function() {

            /**
             * Properties of a CurrencyList.
             * @memberof feedbox.trade_api
             * @interface ICurrencyList
             * @property {Array.<feedbox.trade_api.ICurrency>|null} [currencyList] CurrencyList currencyList
             */

            /**
             * Constructs a new CurrencyList.
             * @memberof feedbox.trade_api
             * @classdesc Represents a CurrencyList.
             * @implements ICurrencyList
             * @constructor
             * @param {feedbox.trade_api.ICurrencyList=} [properties] Properties to set
             */
            function CurrencyList(properties) {
                this.currencyList = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CurrencyList currencyList.
             * @member {Array.<feedbox.trade_api.ICurrency>} currencyList
             * @memberof feedbox.trade_api.CurrencyList
             * @instance
             */
            CurrencyList.prototype.currencyList = $util.emptyArray;

            /**
             * Creates a new CurrencyList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.CurrencyList
             * @static
             * @param {feedbox.trade_api.ICurrencyList=} [properties] Properties to set
             * @returns {feedbox.trade_api.CurrencyList} CurrencyList instance
             */
            CurrencyList.create = function create(properties) {
                return new CurrencyList(properties);
            };

            /**
             * Encodes the specified CurrencyList message. Does not implicitly {@link feedbox.trade_api.CurrencyList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.CurrencyList
             * @static
             * @param {feedbox.trade_api.ICurrencyList} message CurrencyList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CurrencyList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currencyList != null && message.currencyList.length)
                    for (var i = 0; i < message.currencyList.length; ++i)
                        $root.feedbox.trade_api.Currency.encode(message.currencyList[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a CurrencyList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.CurrencyList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.CurrencyList} CurrencyList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CurrencyList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.CurrencyList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.currencyList && message.currencyList.length))
                            message.currencyList = [];
                        message.currencyList.push($root.feedbox.trade_api.Currency.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a CurrencyList message.
             * @function verify
             * @memberof feedbox.trade_api.CurrencyList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CurrencyList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currencyList != null && message.hasOwnProperty("currencyList")) {
                    if (!Array.isArray(message.currencyList))
                        return "currencyList: array expected";
                    for (var i = 0; i < message.currencyList.length; ++i) {
                        var error = $root.feedbox.trade_api.Currency.verify(message.currencyList[i]);
                        if (error)
                            return "currencyList." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a CurrencyList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.CurrencyList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.CurrencyList} CurrencyList
             */
            CurrencyList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.CurrencyList)
                    return object;
                var message = new $root.feedbox.trade_api.CurrencyList();
                if (object.currencyList) {
                    if (!Array.isArray(object.currencyList))
                        throw TypeError(".feedbox.trade_api.CurrencyList.currencyList: array expected");
                    message.currencyList = [];
                    for (var i = 0; i < object.currencyList.length; ++i) {
                        if (typeof object.currencyList[i] !== "object")
                            throw TypeError(".feedbox.trade_api.CurrencyList.currencyList: object expected");
                        message.currencyList[i] = $root.feedbox.trade_api.Currency.fromObject(object.currencyList[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a CurrencyList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.CurrencyList
             * @static
             * @param {feedbox.trade_api.CurrencyList} message CurrencyList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CurrencyList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.currencyList = [];
                if (message.currencyList && message.currencyList.length) {
                    object.currencyList = [];
                    for (var j = 0; j < message.currencyList.length; ++j)
                        object.currencyList[j] = $root.feedbox.trade_api.Currency.toObject(message.currencyList[j], options);
                }
                return object;
            };

            /**
             * Converts this CurrencyList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.CurrencyList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CurrencyList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CurrencyList;
        })();

        trade_api.InsuranceCondition = (function() {

            /**
             * Properties of an InsuranceCondition.
             * @memberof feedbox.trade_api
             * @interface IInsuranceCondition
             * @property {number|Long|null} [insuranceConditionId] InsuranceCondition insuranceConditionId
             * @property {number|null} [payoutPercent] InsuranceCondition payoutPercent
             * @property {number|null} [feePercent] InsuranceCondition feePercent
             * @property {boolean|null} [deleted] InsuranceCondition deleted
             */

            /**
             * Constructs a new InsuranceCondition.
             * @memberof feedbox.trade_api
             * @classdesc Represents an InsuranceCondition.
             * @implements IInsuranceCondition
             * @constructor
             * @param {feedbox.trade_api.IInsuranceCondition=} [properties] Properties to set
             */
            function InsuranceCondition(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * InsuranceCondition insuranceConditionId.
             * @member {number|Long} insuranceConditionId
             * @memberof feedbox.trade_api.InsuranceCondition
             * @instance
             */
            InsuranceCondition.prototype.insuranceConditionId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * InsuranceCondition payoutPercent.
             * @member {number} payoutPercent
             * @memberof feedbox.trade_api.InsuranceCondition
             * @instance
             */
            InsuranceCondition.prototype.payoutPercent = 0;

            /**
             * InsuranceCondition feePercent.
             * @member {number} feePercent
             * @memberof feedbox.trade_api.InsuranceCondition
             * @instance
             */
            InsuranceCondition.prototype.feePercent = 0;

            /**
             * InsuranceCondition deleted.
             * @member {boolean} deleted
             * @memberof feedbox.trade_api.InsuranceCondition
             * @instance
             */
            InsuranceCondition.prototype.deleted = false;

            /**
             * Creates a new InsuranceCondition instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.InsuranceCondition
             * @static
             * @param {feedbox.trade_api.IInsuranceCondition=} [properties] Properties to set
             * @returns {feedbox.trade_api.InsuranceCondition} InsuranceCondition instance
             */
            InsuranceCondition.create = function create(properties) {
                return new InsuranceCondition(properties);
            };

            /**
             * Encodes the specified InsuranceCondition message. Does not implicitly {@link feedbox.trade_api.InsuranceCondition.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.InsuranceCondition
             * @static
             * @param {feedbox.trade_api.IInsuranceCondition} message InsuranceCondition message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InsuranceCondition.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.insuranceConditionId != null && Object.hasOwnProperty.call(message, "insuranceConditionId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.insuranceConditionId);
                if (message.payoutPercent != null && Object.hasOwnProperty.call(message, "payoutPercent"))
                    writer.uint32(/* id 2, wireType 1 =*/17).double(message.payoutPercent);
                if (message.feePercent != null && Object.hasOwnProperty.call(message, "feePercent"))
                    writer.uint32(/* id 3, wireType 1 =*/25).double(message.feePercent);
                if (message.deleted != null && Object.hasOwnProperty.call(message, "deleted"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.deleted);
                return writer;
            };

            /**
             * Decodes an InsuranceCondition message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.InsuranceCondition
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.InsuranceCondition} InsuranceCondition
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InsuranceCondition.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.InsuranceCondition();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.insuranceConditionId = reader.uint64();
                        break;
                    case 2:
                        message.payoutPercent = reader.double();
                        break;
                    case 3:
                        message.feePercent = reader.double();
                        break;
                    case 4:
                        message.deleted = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an InsuranceCondition message.
             * @function verify
             * @memberof feedbox.trade_api.InsuranceCondition
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InsuranceCondition.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (!$util.isInteger(message.insuranceConditionId) && !(message.insuranceConditionId && $util.isInteger(message.insuranceConditionId.low) && $util.isInteger(message.insuranceConditionId.high)))
                        return "insuranceConditionId: integer|Long expected";
                if (message.payoutPercent != null && message.hasOwnProperty("payoutPercent"))
                    if (typeof message.payoutPercent !== "number")
                        return "payoutPercent: number expected";
                if (message.feePercent != null && message.hasOwnProperty("feePercent"))
                    if (typeof message.feePercent !== "number")
                        return "feePercent: number expected";
                if (message.deleted != null && message.hasOwnProperty("deleted"))
                    if (typeof message.deleted !== "boolean")
                        return "deleted: boolean expected";
                return null;
            };

            /**
             * Creates an InsuranceCondition message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.InsuranceCondition
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.InsuranceCondition} InsuranceCondition
             */
            InsuranceCondition.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.InsuranceCondition)
                    return object;
                var message = new $root.feedbox.trade_api.InsuranceCondition();
                if (object.insuranceConditionId != null)
                    if ($util.Long)
                        (message.insuranceConditionId = $util.Long.fromValue(object.insuranceConditionId)).unsigned = true;
                    else if (typeof object.insuranceConditionId === "string")
                        message.insuranceConditionId = parseInt(object.insuranceConditionId, 10);
                    else if (typeof object.insuranceConditionId === "number")
                        message.insuranceConditionId = object.insuranceConditionId;
                    else if (typeof object.insuranceConditionId === "object")
                        message.insuranceConditionId = new $util.LongBits(object.insuranceConditionId.low >>> 0, object.insuranceConditionId.high >>> 0).toNumber(true);
                if (object.payoutPercent != null)
                    message.payoutPercent = Number(object.payoutPercent);
                if (object.feePercent != null)
                    message.feePercent = Number(object.feePercent);
                if (object.deleted != null)
                    message.deleted = Boolean(object.deleted);
                return message;
            };

            /**
             * Creates a plain object from an InsuranceCondition message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.InsuranceCondition
             * @static
             * @param {feedbox.trade_api.InsuranceCondition} message InsuranceCondition
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InsuranceCondition.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.insuranceConditionId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.insuranceConditionId = options.longs === String ? "0" : 0;
                    object.payoutPercent = 0;
                    object.feePercent = 0;
                    object.deleted = false;
                }
                if (message.insuranceConditionId != null && message.hasOwnProperty("insuranceConditionId"))
                    if (typeof message.insuranceConditionId === "number")
                        object.insuranceConditionId = options.longs === String ? String(message.insuranceConditionId) : message.insuranceConditionId;
                    else
                        object.insuranceConditionId = options.longs === String ? $util.Long.prototype.toString.call(message.insuranceConditionId) : options.longs === Number ? new $util.LongBits(message.insuranceConditionId.low >>> 0, message.insuranceConditionId.high >>> 0).toNumber(true) : message.insuranceConditionId;
                if (message.payoutPercent != null && message.hasOwnProperty("payoutPercent"))
                    object.payoutPercent = options.json && !isFinite(message.payoutPercent) ? String(message.payoutPercent) : message.payoutPercent;
                if (message.feePercent != null && message.hasOwnProperty("feePercent"))
                    object.feePercent = options.json && !isFinite(message.feePercent) ? String(message.feePercent) : message.feePercent;
                if (message.deleted != null && message.hasOwnProperty("deleted"))
                    object.deleted = message.deleted;
                return object;
            };

            /**
             * Converts this InsuranceCondition to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.InsuranceCondition
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InsuranceCondition.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return InsuranceCondition;
        })();

        trade_api.GetInsuranceConditionList = (function() {

            /**
             * Properties of a GetInsuranceConditionList.
             * @memberof feedbox.trade_api
             * @interface IGetInsuranceConditionList
             */

            /**
             * Constructs a new GetInsuranceConditionList.
             * @memberof feedbox.trade_api
             * @classdesc Represents a GetInsuranceConditionList.
             * @implements IGetInsuranceConditionList
             * @constructor
             * @param {feedbox.trade_api.IGetInsuranceConditionList=} [properties] Properties to set
             */
            function GetInsuranceConditionList(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetInsuranceConditionList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.GetInsuranceConditionList
             * @static
             * @param {feedbox.trade_api.IGetInsuranceConditionList=} [properties] Properties to set
             * @returns {feedbox.trade_api.GetInsuranceConditionList} GetInsuranceConditionList instance
             */
            GetInsuranceConditionList.create = function create(properties) {
                return new GetInsuranceConditionList(properties);
            };

            /**
             * Encodes the specified GetInsuranceConditionList message. Does not implicitly {@link feedbox.trade_api.GetInsuranceConditionList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.GetInsuranceConditionList
             * @static
             * @param {feedbox.trade_api.IGetInsuranceConditionList} message GetInsuranceConditionList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetInsuranceConditionList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Decodes a GetInsuranceConditionList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.GetInsuranceConditionList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.GetInsuranceConditionList} GetInsuranceConditionList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetInsuranceConditionList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.GetInsuranceConditionList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GetInsuranceConditionList message.
             * @function verify
             * @memberof feedbox.trade_api.GetInsuranceConditionList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetInsuranceConditionList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetInsuranceConditionList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.GetInsuranceConditionList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.GetInsuranceConditionList} GetInsuranceConditionList
             */
            GetInsuranceConditionList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.GetInsuranceConditionList)
                    return object;
                return new $root.feedbox.trade_api.GetInsuranceConditionList();
            };

            /**
             * Creates a plain object from a GetInsuranceConditionList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.GetInsuranceConditionList
             * @static
             * @param {feedbox.trade_api.GetInsuranceConditionList} message GetInsuranceConditionList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetInsuranceConditionList.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetInsuranceConditionList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.GetInsuranceConditionList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetInsuranceConditionList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return GetInsuranceConditionList;
        })();

        trade_api.InsuranceConditionList = (function() {

            /**
             * Properties of an InsuranceConditionList.
             * @memberof feedbox.trade_api
             * @interface IInsuranceConditionList
             * @property {Array.<feedbox.trade_api.IInsuranceCondition>|null} [insuranceCondition] InsuranceConditionList insuranceCondition
             */

            /**
             * Constructs a new InsuranceConditionList.
             * @memberof feedbox.trade_api
             * @classdesc Represents an InsuranceConditionList.
             * @implements IInsuranceConditionList
             * @constructor
             * @param {feedbox.trade_api.IInsuranceConditionList=} [properties] Properties to set
             */
            function InsuranceConditionList(properties) {
                this.insuranceCondition = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * InsuranceConditionList insuranceCondition.
             * @member {Array.<feedbox.trade_api.IInsuranceCondition>} insuranceCondition
             * @memberof feedbox.trade_api.InsuranceConditionList
             * @instance
             */
            InsuranceConditionList.prototype.insuranceCondition = $util.emptyArray;

            /**
             * Creates a new InsuranceConditionList instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.InsuranceConditionList
             * @static
             * @param {feedbox.trade_api.IInsuranceConditionList=} [properties] Properties to set
             * @returns {feedbox.trade_api.InsuranceConditionList} InsuranceConditionList instance
             */
            InsuranceConditionList.create = function create(properties) {
                return new InsuranceConditionList(properties);
            };

            /**
             * Encodes the specified InsuranceConditionList message. Does not implicitly {@link feedbox.trade_api.InsuranceConditionList.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.InsuranceConditionList
             * @static
             * @param {feedbox.trade_api.IInsuranceConditionList} message InsuranceConditionList message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InsuranceConditionList.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.insuranceCondition != null && message.insuranceCondition.length)
                    for (var i = 0; i < message.insuranceCondition.length; ++i)
                        $root.feedbox.trade_api.InsuranceCondition.encode(message.insuranceCondition[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an InsuranceConditionList message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.InsuranceConditionList
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.InsuranceConditionList} InsuranceConditionList
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InsuranceConditionList.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.InsuranceConditionList();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.insuranceCondition && message.insuranceCondition.length))
                            message.insuranceCondition = [];
                        message.insuranceCondition.push($root.feedbox.trade_api.InsuranceCondition.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an InsuranceConditionList message.
             * @function verify
             * @memberof feedbox.trade_api.InsuranceConditionList
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InsuranceConditionList.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.insuranceCondition != null && message.hasOwnProperty("insuranceCondition")) {
                    if (!Array.isArray(message.insuranceCondition))
                        return "insuranceCondition: array expected";
                    for (var i = 0; i < message.insuranceCondition.length; ++i) {
                        var error = $root.feedbox.trade_api.InsuranceCondition.verify(message.insuranceCondition[i]);
                        if (error)
                            return "insuranceCondition." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an InsuranceConditionList message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.InsuranceConditionList
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.InsuranceConditionList} InsuranceConditionList
             */
            InsuranceConditionList.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.InsuranceConditionList)
                    return object;
                var message = new $root.feedbox.trade_api.InsuranceConditionList();
                if (object.insuranceCondition) {
                    if (!Array.isArray(object.insuranceCondition))
                        throw TypeError(".feedbox.trade_api.InsuranceConditionList.insuranceCondition: array expected");
                    message.insuranceCondition = [];
                    for (var i = 0; i < object.insuranceCondition.length; ++i) {
                        if (typeof object.insuranceCondition[i] !== "object")
                            throw TypeError(".feedbox.trade_api.InsuranceConditionList.insuranceCondition: object expected");
                        message.insuranceCondition[i] = $root.feedbox.trade_api.InsuranceCondition.fromObject(object.insuranceCondition[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an InsuranceConditionList message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.InsuranceConditionList
             * @static
             * @param {feedbox.trade_api.InsuranceConditionList} message InsuranceConditionList
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InsuranceConditionList.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.insuranceCondition = [];
                if (message.insuranceCondition && message.insuranceCondition.length) {
                    object.insuranceCondition = [];
                    for (var j = 0; j < message.insuranceCondition.length; ++j)
                        object.insuranceCondition[j] = $root.feedbox.trade_api.InsuranceCondition.toObject(message.insuranceCondition[j], options);
                }
                return object;
            };

            /**
             * Converts this InsuranceConditionList to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.InsuranceConditionList
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InsuranceConditionList.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return InsuranceConditionList;
        })();

        trade_api.TradeDataRequest = (function() {

            /**
             * Properties of a TradeDataRequest.
             * @memberof feedbox.trade_api
             * @interface ITradeDataRequest
             * @property {number|Long|null} [id] TradeDataRequest id
             * @property {feedbox.trade_api.IOrderRequest|null} [orderRequest] TradeDataRequest orderRequest
             * @property {feedbox.trade_api.IOrderCancel|null} [orderCancel] TradeDataRequest orderCancel
             * @property {feedbox.trade_api.IOrderReplace|null} [orderReplace] TradeDataRequest orderReplace
             * @property {feedbox.trade_api.IGetOrderList|null} [getOrderList] TradeDataRequest getOrderList
             * @property {feedbox.trade_api.IGetOrderExecutionList|null} [getOrderExecutionList] TradeDataRequest getOrderExecutionList
             * @property {feedbox.trade_api.IGetPositionList|null} [getPositionList] TradeDataRequest getPositionList
             * @property {feedbox.trade_api.IUpdatePosition|null} [updatePosition] TradeDataRequest updatePosition
             * @property {feedbox.trade_api.IGetCustomerInfo|null} [getCustomerInfo] TradeDataRequest getCustomerInfo
             * @property {feedbox.trade_api.IGetBalancesByDate|null} [getBalancesByDate] TradeDataRequest getBalancesByDate
             * @property {feedbox.trade_api.IGetTotalAmounts|null} [getTotalAmounts] TradeDataRequest getTotalAmounts
             * @property {feedbox.trade_api.IPlaceBet|null} [placeBet] TradeDataRequest placeBet
             * @property {feedbox.trade_api.IEditBet|null} [editBet] TradeDataRequest editBet
             * @property {feedbox.trade_api.ICancelBet|null} [cancelBet] TradeDataRequest cancelBet
             * @property {feedbox.trade_api.IGetBetList|null} [getBetList] TradeDataRequest getBetList
             * @property {feedbox.trade_api.ICloseBet|null} [closeBet] TradeDataRequest closeBet
             * @property {feedbox.ping.IPing|null} [ping] TradeDataRequest ping
             * @property {feedbox.server_time.IGetServerTime|null} [getServerTime] TradeDataRequest getServerTime
             * @property {feedbox.trade_api.IGetCommission|null} [getCommission] TradeDataRequest getCommission
             * @property {feedbox.trade_api.IGetBetCommission|null} [getBetCommission] TradeDataRequest getBetCommission
             * @property {feedbox.trade_api.IGetCloseBetCommission|null} [getCloseBetCommission] TradeDataRequest getCloseBetCommission
             * @property {feedbox.trade_api.IGetCurrencyList|null} [getCurrencyList] TradeDataRequest getCurrencyList
             * @property {feedbox.trade_api.IGetInsuranceConditionList|null} [getInsuranceConditionList] TradeDataRequest getInsuranceConditionList
             */

            /**
             * Constructs a new TradeDataRequest.
             * @memberof feedbox.trade_api
             * @classdesc Represents a TradeDataRequest.
             * @implements ITradeDataRequest
             * @constructor
             * @param {feedbox.trade_api.ITradeDataRequest=} [properties] Properties to set
             */
            function TradeDataRequest(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TradeDataRequest id.
             * @member {number|Long} id
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * TradeDataRequest orderRequest.
             * @member {feedbox.trade_api.IOrderRequest|null|undefined} orderRequest
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.orderRequest = null;

            /**
             * TradeDataRequest orderCancel.
             * @member {feedbox.trade_api.IOrderCancel|null|undefined} orderCancel
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.orderCancel = null;

            /**
             * TradeDataRequest orderReplace.
             * @member {feedbox.trade_api.IOrderReplace|null|undefined} orderReplace
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.orderReplace = null;

            /**
             * TradeDataRequest getOrderList.
             * @member {feedbox.trade_api.IGetOrderList|null|undefined} getOrderList
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getOrderList = null;

            /**
             * TradeDataRequest getOrderExecutionList.
             * @member {feedbox.trade_api.IGetOrderExecutionList|null|undefined} getOrderExecutionList
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getOrderExecutionList = null;

            /**
             * TradeDataRequest getPositionList.
             * @member {feedbox.trade_api.IGetPositionList|null|undefined} getPositionList
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getPositionList = null;

            /**
             * TradeDataRequest updatePosition.
             * @member {feedbox.trade_api.IUpdatePosition|null|undefined} updatePosition
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.updatePosition = null;

            /**
             * TradeDataRequest getCustomerInfo.
             * @member {feedbox.trade_api.IGetCustomerInfo|null|undefined} getCustomerInfo
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getCustomerInfo = null;

            /**
             * TradeDataRequest getBalancesByDate.
             * @member {feedbox.trade_api.IGetBalancesByDate|null|undefined} getBalancesByDate
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getBalancesByDate = null;

            /**
             * TradeDataRequest getTotalAmounts.
             * @member {feedbox.trade_api.IGetTotalAmounts|null|undefined} getTotalAmounts
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getTotalAmounts = null;

            /**
             * TradeDataRequest placeBet.
             * @member {feedbox.trade_api.IPlaceBet|null|undefined} placeBet
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.placeBet = null;

            /**
             * TradeDataRequest editBet.
             * @member {feedbox.trade_api.IEditBet|null|undefined} editBet
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.editBet = null;

            /**
             * TradeDataRequest cancelBet.
             * @member {feedbox.trade_api.ICancelBet|null|undefined} cancelBet
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.cancelBet = null;

            /**
             * TradeDataRequest getBetList.
             * @member {feedbox.trade_api.IGetBetList|null|undefined} getBetList
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getBetList = null;

            /**
             * TradeDataRequest closeBet.
             * @member {feedbox.trade_api.ICloseBet|null|undefined} closeBet
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.closeBet = null;

            /**
             * TradeDataRequest ping.
             * @member {feedbox.ping.IPing|null|undefined} ping
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.ping = null;

            /**
             * TradeDataRequest getServerTime.
             * @member {feedbox.server_time.IGetServerTime|null|undefined} getServerTime
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getServerTime = null;

            /**
             * TradeDataRequest getCommission.
             * @member {feedbox.trade_api.IGetCommission|null|undefined} getCommission
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getCommission = null;

            /**
             * TradeDataRequest getBetCommission.
             * @member {feedbox.trade_api.IGetBetCommission|null|undefined} getBetCommission
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getBetCommission = null;

            /**
             * TradeDataRequest getCloseBetCommission.
             * @member {feedbox.trade_api.IGetCloseBetCommission|null|undefined} getCloseBetCommission
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getCloseBetCommission = null;

            /**
             * TradeDataRequest getCurrencyList.
             * @member {feedbox.trade_api.IGetCurrencyList|null|undefined} getCurrencyList
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getCurrencyList = null;

            /**
             * TradeDataRequest getInsuranceConditionList.
             * @member {feedbox.trade_api.IGetInsuranceConditionList|null|undefined} getInsuranceConditionList
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            TradeDataRequest.prototype.getInsuranceConditionList = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * TradeDataRequest body.
             * @member {"orderRequest"|"orderCancel"|"orderReplace"|"getOrderList"|"getOrderExecutionList"|"getPositionList"|"updatePosition"|"getCustomerInfo"|"getBalancesByDate"|"getTotalAmounts"|"placeBet"|"editBet"|"cancelBet"|"getBetList"|"closeBet"|"ping"|"getServerTime"|"getCommission"|"getBetCommission"|"getCloseBetCommission"|"getCurrencyList"|"getInsuranceConditionList"|undefined} body
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             */
            Object.defineProperty(TradeDataRequest.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["orderRequest", "orderCancel", "orderReplace", "getOrderList", "getOrderExecutionList", "getPositionList", "updatePosition", "getCustomerInfo", "getBalancesByDate", "getTotalAmounts", "placeBet", "editBet", "cancelBet", "getBetList", "closeBet", "ping", "getServerTime", "getCommission", "getBetCommission", "getCloseBetCommission", "getCurrencyList", "getInsuranceConditionList"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new TradeDataRequest instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.TradeDataRequest
             * @static
             * @param {feedbox.trade_api.ITradeDataRequest=} [properties] Properties to set
             * @returns {feedbox.trade_api.TradeDataRequest} TradeDataRequest instance
             */
            TradeDataRequest.create = function create(properties) {
                return new TradeDataRequest(properties);
            };

            /**
             * Encodes the specified TradeDataRequest message. Does not implicitly {@link feedbox.trade_api.TradeDataRequest.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.TradeDataRequest
             * @static
             * @param {feedbox.trade_api.ITradeDataRequest} message TradeDataRequest message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TradeDataRequest.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.orderRequest != null && Object.hasOwnProperty.call(message, "orderRequest"))
                    $root.feedbox.trade_api.OrderRequest.encode(message.orderRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.orderCancel != null && Object.hasOwnProperty.call(message, "orderCancel"))
                    $root.feedbox.trade_api.OrderCancel.encode(message.orderCancel, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.getOrderList != null && Object.hasOwnProperty.call(message, "getOrderList"))
                    $root.feedbox.trade_api.GetOrderList.encode(message.getOrderList, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.getOrderExecutionList != null && Object.hasOwnProperty.call(message, "getOrderExecutionList"))
                    $root.feedbox.trade_api.GetOrderExecutionList.encode(message.getOrderExecutionList, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.getPositionList != null && Object.hasOwnProperty.call(message, "getPositionList"))
                    $root.feedbox.trade_api.GetPositionList.encode(message.getPositionList, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.getCustomerInfo != null && Object.hasOwnProperty.call(message, "getCustomerInfo"))
                    $root.feedbox.trade_api.GetCustomerInfo.encode(message.getCustomerInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.getBalancesByDate != null && Object.hasOwnProperty.call(message, "getBalancesByDate"))
                    $root.feedbox.trade_api.GetBalancesByDate.encode(message.getBalancesByDate, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.getTotalAmounts != null && Object.hasOwnProperty.call(message, "getTotalAmounts"))
                    $root.feedbox.trade_api.GetTotalAmounts.encode(message.getTotalAmounts, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.placeBet != null && Object.hasOwnProperty.call(message, "placeBet"))
                    $root.feedbox.trade_api.PlaceBet.encode(message.placeBet, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.editBet != null && Object.hasOwnProperty.call(message, "editBet"))
                    $root.feedbox.trade_api.EditBet.encode(message.editBet, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.cancelBet != null && Object.hasOwnProperty.call(message, "cancelBet"))
                    $root.feedbox.trade_api.CancelBet.encode(message.cancelBet, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.getBetList != null && Object.hasOwnProperty.call(message, "getBetList"))
                    $root.feedbox.trade_api.GetBetList.encode(message.getBetList, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.closeBet != null && Object.hasOwnProperty.call(message, "closeBet"))
                    $root.feedbox.trade_api.CloseBet.encode(message.closeBet, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.ping != null && Object.hasOwnProperty.call(message, "ping"))
                    $root.feedbox.ping.Ping.encode(message.ping, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.getServerTime != null && Object.hasOwnProperty.call(message, "getServerTime"))
                    $root.feedbox.server_time.GetServerTime.encode(message.getServerTime, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.getCommission != null && Object.hasOwnProperty.call(message, "getCommission"))
                    $root.feedbox.trade_api.GetCommission.encode(message.getCommission, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.getBetCommission != null && Object.hasOwnProperty.call(message, "getBetCommission"))
                    $root.feedbox.trade_api.GetBetCommission.encode(message.getBetCommission, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.getCloseBetCommission != null && Object.hasOwnProperty.call(message, "getCloseBetCommission"))
                    $root.feedbox.trade_api.GetCloseBetCommission.encode(message.getCloseBetCommission, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.updatePosition != null && Object.hasOwnProperty.call(message, "updatePosition"))
                    $root.feedbox.trade_api.UpdatePosition.encode(message.updatePosition, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.getCurrencyList != null && Object.hasOwnProperty.call(message, "getCurrencyList"))
                    $root.feedbox.trade_api.GetCurrencyList.encode(message.getCurrencyList, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.getInsuranceConditionList != null && Object.hasOwnProperty.call(message, "getInsuranceConditionList"))
                    $root.feedbox.trade_api.GetInsuranceConditionList.encode(message.getInsuranceConditionList, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.orderReplace != null && Object.hasOwnProperty.call(message, "orderReplace"))
                    $root.feedbox.trade_api.OrderReplace.encode(message.orderReplace, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a TradeDataRequest message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.TradeDataRequest
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.TradeDataRequest} TradeDataRequest
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TradeDataRequest.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.TradeDataRequest();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 3:
                        message.orderRequest = $root.feedbox.trade_api.OrderRequest.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.orderCancel = $root.feedbox.trade_api.OrderCancel.decode(reader, reader.uint32());
                        break;
                    case 24:
                        message.orderReplace = $root.feedbox.trade_api.OrderReplace.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.getOrderList = $root.feedbox.trade_api.GetOrderList.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.getOrderExecutionList = $root.feedbox.trade_api.GetOrderExecutionList.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.getPositionList = $root.feedbox.trade_api.GetPositionList.decode(reader, reader.uint32());
                        break;
                    case 21:
                        message.updatePosition = $root.feedbox.trade_api.UpdatePosition.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.getCustomerInfo = $root.feedbox.trade_api.GetCustomerInfo.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.getBalancesByDate = $root.feedbox.trade_api.GetBalancesByDate.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.getTotalAmounts = $root.feedbox.trade_api.GetTotalAmounts.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.placeBet = $root.feedbox.trade_api.PlaceBet.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.editBet = $root.feedbox.trade_api.EditBet.decode(reader, reader.uint32());
                        break;
                    case 13:
                        message.cancelBet = $root.feedbox.trade_api.CancelBet.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.getBetList = $root.feedbox.trade_api.GetBetList.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.closeBet = $root.feedbox.trade_api.CloseBet.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.ping = $root.feedbox.ping.Ping.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.getServerTime = $root.feedbox.server_time.GetServerTime.decode(reader, reader.uint32());
                        break;
                    case 18:
                        message.getCommission = $root.feedbox.trade_api.GetCommission.decode(reader, reader.uint32());
                        break;
                    case 19:
                        message.getBetCommission = $root.feedbox.trade_api.GetBetCommission.decode(reader, reader.uint32());
                        break;
                    case 20:
                        message.getCloseBetCommission = $root.feedbox.trade_api.GetCloseBetCommission.decode(reader, reader.uint32());
                        break;
                    case 22:
                        message.getCurrencyList = $root.feedbox.trade_api.GetCurrencyList.decode(reader, reader.uint32());
                        break;
                    case 23:
                        message.getInsuranceConditionList = $root.feedbox.trade_api.GetInsuranceConditionList.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a TradeDataRequest message.
             * @function verify
             * @memberof feedbox.trade_api.TradeDataRequest
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TradeDataRequest.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.orderRequest != null && message.hasOwnProperty("orderRequest")) {
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.OrderRequest.verify(message.orderRequest);
                        if (error)
                            return "orderRequest." + error;
                    }
                }
                if (message.orderCancel != null && message.hasOwnProperty("orderCancel")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.OrderCancel.verify(message.orderCancel);
                        if (error)
                            return "orderCancel." + error;
                    }
                }
                if (message.orderReplace != null && message.hasOwnProperty("orderReplace")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.OrderReplace.verify(message.orderReplace);
                        if (error)
                            return "orderReplace." + error;
                    }
                }
                if (message.getOrderList != null && message.hasOwnProperty("getOrderList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetOrderList.verify(message.getOrderList);
                        if (error)
                            return "getOrderList." + error;
                    }
                }
                if (message.getOrderExecutionList != null && message.hasOwnProperty("getOrderExecutionList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetOrderExecutionList.verify(message.getOrderExecutionList);
                        if (error)
                            return "getOrderExecutionList." + error;
                    }
                }
                if (message.getPositionList != null && message.hasOwnProperty("getPositionList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetPositionList.verify(message.getPositionList);
                        if (error)
                            return "getPositionList." + error;
                    }
                }
                if (message.updatePosition != null && message.hasOwnProperty("updatePosition")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.UpdatePosition.verify(message.updatePosition);
                        if (error)
                            return "updatePosition." + error;
                    }
                }
                if (message.getCustomerInfo != null && message.hasOwnProperty("getCustomerInfo")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetCustomerInfo.verify(message.getCustomerInfo);
                        if (error)
                            return "getCustomerInfo." + error;
                    }
                }
                if (message.getBalancesByDate != null && message.hasOwnProperty("getBalancesByDate")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetBalancesByDate.verify(message.getBalancesByDate);
                        if (error)
                            return "getBalancesByDate." + error;
                    }
                }
                if (message.getTotalAmounts != null && message.hasOwnProperty("getTotalAmounts")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetTotalAmounts.verify(message.getTotalAmounts);
                        if (error)
                            return "getTotalAmounts." + error;
                    }
                }
                if (message.placeBet != null && message.hasOwnProperty("placeBet")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.PlaceBet.verify(message.placeBet);
                        if (error)
                            return "placeBet." + error;
                    }
                }
                if (message.editBet != null && message.hasOwnProperty("editBet")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.EditBet.verify(message.editBet);
                        if (error)
                            return "editBet." + error;
                    }
                }
                if (message.cancelBet != null && message.hasOwnProperty("cancelBet")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.CancelBet.verify(message.cancelBet);
                        if (error)
                            return "cancelBet." + error;
                    }
                }
                if (message.getBetList != null && message.hasOwnProperty("getBetList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetBetList.verify(message.getBetList);
                        if (error)
                            return "getBetList." + error;
                    }
                }
                if (message.closeBet != null && message.hasOwnProperty("closeBet")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.CloseBet.verify(message.closeBet);
                        if (error)
                            return "closeBet." + error;
                    }
                }
                if (message.ping != null && message.hasOwnProperty("ping")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.ping.Ping.verify(message.ping);
                        if (error)
                            return "ping." + error;
                    }
                }
                if (message.getServerTime != null && message.hasOwnProperty("getServerTime")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.server_time.GetServerTime.verify(message.getServerTime);
                        if (error)
                            return "getServerTime." + error;
                    }
                }
                if (message.getCommission != null && message.hasOwnProperty("getCommission")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetCommission.verify(message.getCommission);
                        if (error)
                            return "getCommission." + error;
                    }
                }
                if (message.getBetCommission != null && message.hasOwnProperty("getBetCommission")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetBetCommission.verify(message.getBetCommission);
                        if (error)
                            return "getBetCommission." + error;
                    }
                }
                if (message.getCloseBetCommission != null && message.hasOwnProperty("getCloseBetCommission")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetCloseBetCommission.verify(message.getCloseBetCommission);
                        if (error)
                            return "getCloseBetCommission." + error;
                    }
                }
                if (message.getCurrencyList != null && message.hasOwnProperty("getCurrencyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetCurrencyList.verify(message.getCurrencyList);
                        if (error)
                            return "getCurrencyList." + error;
                    }
                }
                if (message.getInsuranceConditionList != null && message.hasOwnProperty("getInsuranceConditionList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.GetInsuranceConditionList.verify(message.getInsuranceConditionList);
                        if (error)
                            return "getInsuranceConditionList." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a TradeDataRequest message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.TradeDataRequest
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.TradeDataRequest} TradeDataRequest
             */
            TradeDataRequest.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.TradeDataRequest)
                    return object;
                var message = new $root.feedbox.trade_api.TradeDataRequest();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.orderRequest != null) {
                    if (typeof object.orderRequest !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.orderRequest: object expected");
                    message.orderRequest = $root.feedbox.trade_api.OrderRequest.fromObject(object.orderRequest);
                }
                if (object.orderCancel != null) {
                    if (typeof object.orderCancel !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.orderCancel: object expected");
                    message.orderCancel = $root.feedbox.trade_api.OrderCancel.fromObject(object.orderCancel);
                }
                if (object.orderReplace != null) {
                    if (typeof object.orderReplace !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.orderReplace: object expected");
                    message.orderReplace = $root.feedbox.trade_api.OrderReplace.fromObject(object.orderReplace);
                }
                if (object.getOrderList != null) {
                    if (typeof object.getOrderList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getOrderList: object expected");
                    message.getOrderList = $root.feedbox.trade_api.GetOrderList.fromObject(object.getOrderList);
                }
                if (object.getOrderExecutionList != null) {
                    if (typeof object.getOrderExecutionList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getOrderExecutionList: object expected");
                    message.getOrderExecutionList = $root.feedbox.trade_api.GetOrderExecutionList.fromObject(object.getOrderExecutionList);
                }
                if (object.getPositionList != null) {
                    if (typeof object.getPositionList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getPositionList: object expected");
                    message.getPositionList = $root.feedbox.trade_api.GetPositionList.fromObject(object.getPositionList);
                }
                if (object.updatePosition != null) {
                    if (typeof object.updatePosition !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.updatePosition: object expected");
                    message.updatePosition = $root.feedbox.trade_api.UpdatePosition.fromObject(object.updatePosition);
                }
                if (object.getCustomerInfo != null) {
                    if (typeof object.getCustomerInfo !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getCustomerInfo: object expected");
                    message.getCustomerInfo = $root.feedbox.trade_api.GetCustomerInfo.fromObject(object.getCustomerInfo);
                }
                if (object.getBalancesByDate != null) {
                    if (typeof object.getBalancesByDate !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getBalancesByDate: object expected");
                    message.getBalancesByDate = $root.feedbox.trade_api.GetBalancesByDate.fromObject(object.getBalancesByDate);
                }
                if (object.getTotalAmounts != null) {
                    if (typeof object.getTotalAmounts !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getTotalAmounts: object expected");
                    message.getTotalAmounts = $root.feedbox.trade_api.GetTotalAmounts.fromObject(object.getTotalAmounts);
                }
                if (object.placeBet != null) {
                    if (typeof object.placeBet !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.placeBet: object expected");
                    message.placeBet = $root.feedbox.trade_api.PlaceBet.fromObject(object.placeBet);
                }
                if (object.editBet != null) {
                    if (typeof object.editBet !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.editBet: object expected");
                    message.editBet = $root.feedbox.trade_api.EditBet.fromObject(object.editBet);
                }
                if (object.cancelBet != null) {
                    if (typeof object.cancelBet !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.cancelBet: object expected");
                    message.cancelBet = $root.feedbox.trade_api.CancelBet.fromObject(object.cancelBet);
                }
                if (object.getBetList != null) {
                    if (typeof object.getBetList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getBetList: object expected");
                    message.getBetList = $root.feedbox.trade_api.GetBetList.fromObject(object.getBetList);
                }
                if (object.closeBet != null) {
                    if (typeof object.closeBet !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.closeBet: object expected");
                    message.closeBet = $root.feedbox.trade_api.CloseBet.fromObject(object.closeBet);
                }
                if (object.ping != null) {
                    if (typeof object.ping !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.ping: object expected");
                    message.ping = $root.feedbox.ping.Ping.fromObject(object.ping);
                }
                if (object.getServerTime != null) {
                    if (typeof object.getServerTime !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getServerTime: object expected");
                    message.getServerTime = $root.feedbox.server_time.GetServerTime.fromObject(object.getServerTime);
                }
                if (object.getCommission != null) {
                    if (typeof object.getCommission !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getCommission: object expected");
                    message.getCommission = $root.feedbox.trade_api.GetCommission.fromObject(object.getCommission);
                }
                if (object.getBetCommission != null) {
                    if (typeof object.getBetCommission !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getBetCommission: object expected");
                    message.getBetCommission = $root.feedbox.trade_api.GetBetCommission.fromObject(object.getBetCommission);
                }
                if (object.getCloseBetCommission != null) {
                    if (typeof object.getCloseBetCommission !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getCloseBetCommission: object expected");
                    message.getCloseBetCommission = $root.feedbox.trade_api.GetCloseBetCommission.fromObject(object.getCloseBetCommission);
                }
                if (object.getCurrencyList != null) {
                    if (typeof object.getCurrencyList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getCurrencyList: object expected");
                    message.getCurrencyList = $root.feedbox.trade_api.GetCurrencyList.fromObject(object.getCurrencyList);
                }
                if (object.getInsuranceConditionList != null) {
                    if (typeof object.getInsuranceConditionList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeDataRequest.getInsuranceConditionList: object expected");
                    message.getInsuranceConditionList = $root.feedbox.trade_api.GetInsuranceConditionList.fromObject(object.getInsuranceConditionList);
                }
                return message;
            };

            /**
             * Creates a plain object from a TradeDataRequest message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.TradeDataRequest
             * @static
             * @param {feedbox.trade_api.TradeDataRequest} message TradeDataRequest
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TradeDataRequest.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.orderRequest != null && message.hasOwnProperty("orderRequest")) {
                    object.orderRequest = $root.feedbox.trade_api.OrderRequest.toObject(message.orderRequest, options);
                    if (options.oneofs)
                        object.body = "orderRequest";
                }
                if (message.orderCancel != null && message.hasOwnProperty("orderCancel")) {
                    object.orderCancel = $root.feedbox.trade_api.OrderCancel.toObject(message.orderCancel, options);
                    if (options.oneofs)
                        object.body = "orderCancel";
                }
                if (message.getOrderList != null && message.hasOwnProperty("getOrderList")) {
                    object.getOrderList = $root.feedbox.trade_api.GetOrderList.toObject(message.getOrderList, options);
                    if (options.oneofs)
                        object.body = "getOrderList";
                }
                if (message.getOrderExecutionList != null && message.hasOwnProperty("getOrderExecutionList")) {
                    object.getOrderExecutionList = $root.feedbox.trade_api.GetOrderExecutionList.toObject(message.getOrderExecutionList, options);
                    if (options.oneofs)
                        object.body = "getOrderExecutionList";
                }
                if (message.getPositionList != null && message.hasOwnProperty("getPositionList")) {
                    object.getPositionList = $root.feedbox.trade_api.GetPositionList.toObject(message.getPositionList, options);
                    if (options.oneofs)
                        object.body = "getPositionList";
                }
                if (message.getCustomerInfo != null && message.hasOwnProperty("getCustomerInfo")) {
                    object.getCustomerInfo = $root.feedbox.trade_api.GetCustomerInfo.toObject(message.getCustomerInfo, options);
                    if (options.oneofs)
                        object.body = "getCustomerInfo";
                }
                if (message.getBalancesByDate != null && message.hasOwnProperty("getBalancesByDate")) {
                    object.getBalancesByDate = $root.feedbox.trade_api.GetBalancesByDate.toObject(message.getBalancesByDate, options);
                    if (options.oneofs)
                        object.body = "getBalancesByDate";
                }
                if (message.getTotalAmounts != null && message.hasOwnProperty("getTotalAmounts")) {
                    object.getTotalAmounts = $root.feedbox.trade_api.GetTotalAmounts.toObject(message.getTotalAmounts, options);
                    if (options.oneofs)
                        object.body = "getTotalAmounts";
                }
                if (message.placeBet != null && message.hasOwnProperty("placeBet")) {
                    object.placeBet = $root.feedbox.trade_api.PlaceBet.toObject(message.placeBet, options);
                    if (options.oneofs)
                        object.body = "placeBet";
                }
                if (message.editBet != null && message.hasOwnProperty("editBet")) {
                    object.editBet = $root.feedbox.trade_api.EditBet.toObject(message.editBet, options);
                    if (options.oneofs)
                        object.body = "editBet";
                }
                if (message.cancelBet != null && message.hasOwnProperty("cancelBet")) {
                    object.cancelBet = $root.feedbox.trade_api.CancelBet.toObject(message.cancelBet, options);
                    if (options.oneofs)
                        object.body = "cancelBet";
                }
                if (message.getBetList != null && message.hasOwnProperty("getBetList")) {
                    object.getBetList = $root.feedbox.trade_api.GetBetList.toObject(message.getBetList, options);
                    if (options.oneofs)
                        object.body = "getBetList";
                }
                if (message.closeBet != null && message.hasOwnProperty("closeBet")) {
                    object.closeBet = $root.feedbox.trade_api.CloseBet.toObject(message.closeBet, options);
                    if (options.oneofs)
                        object.body = "closeBet";
                }
                if (message.ping != null && message.hasOwnProperty("ping")) {
                    object.ping = $root.feedbox.ping.Ping.toObject(message.ping, options);
                    if (options.oneofs)
                        object.body = "ping";
                }
                if (message.getServerTime != null && message.hasOwnProperty("getServerTime")) {
                    object.getServerTime = $root.feedbox.server_time.GetServerTime.toObject(message.getServerTime, options);
                    if (options.oneofs)
                        object.body = "getServerTime";
                }
                if (message.getCommission != null && message.hasOwnProperty("getCommission")) {
                    object.getCommission = $root.feedbox.trade_api.GetCommission.toObject(message.getCommission, options);
                    if (options.oneofs)
                        object.body = "getCommission";
                }
                if (message.getBetCommission != null && message.hasOwnProperty("getBetCommission")) {
                    object.getBetCommission = $root.feedbox.trade_api.GetBetCommission.toObject(message.getBetCommission, options);
                    if (options.oneofs)
                        object.body = "getBetCommission";
                }
                if (message.getCloseBetCommission != null && message.hasOwnProperty("getCloseBetCommission")) {
                    object.getCloseBetCommission = $root.feedbox.trade_api.GetCloseBetCommission.toObject(message.getCloseBetCommission, options);
                    if (options.oneofs)
                        object.body = "getCloseBetCommission";
                }
                if (message.updatePosition != null && message.hasOwnProperty("updatePosition")) {
                    object.updatePosition = $root.feedbox.trade_api.UpdatePosition.toObject(message.updatePosition, options);
                    if (options.oneofs)
                        object.body = "updatePosition";
                }
                if (message.getCurrencyList != null && message.hasOwnProperty("getCurrencyList")) {
                    object.getCurrencyList = $root.feedbox.trade_api.GetCurrencyList.toObject(message.getCurrencyList, options);
                    if (options.oneofs)
                        object.body = "getCurrencyList";
                }
                if (message.getInsuranceConditionList != null && message.hasOwnProperty("getInsuranceConditionList")) {
                    object.getInsuranceConditionList = $root.feedbox.trade_api.GetInsuranceConditionList.toObject(message.getInsuranceConditionList, options);
                    if (options.oneofs)
                        object.body = "getInsuranceConditionList";
                }
                if (message.orderReplace != null && message.hasOwnProperty("orderReplace")) {
                    object.orderReplace = $root.feedbox.trade_api.OrderReplace.toObject(message.orderReplace, options);
                    if (options.oneofs)
                        object.body = "orderReplace";
                }
                return object;
            };

            /**
             * Converts this TradeDataRequest to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.TradeDataRequest
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TradeDataRequest.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TradeDataRequest;
        })();

        trade_api.TradeData = (function() {

            /**
             * Properties of a TradeData.
             * @memberof feedbox.trade_api
             * @interface ITradeData
             * @property {number|Long|null} [id] TradeData id
             * @property {feedbox.trade_api.IOrderRequestResult|null} [orderRequestResult] TradeData orderRequestResult
             * @property {feedbox.trade_api.IOrder|null} [order] TradeData order
             * @property {feedbox.trade_api.IOrderExecution|null} [orderExecution] TradeData orderExecution
             * @property {feedbox.trade_api.IOrderCancelResult|null} [orderCancelResult] TradeData orderCancelResult
             * @property {feedbox.trade_api.IOrderReplaceResult|null} [orderReplaceResult] TradeData orderReplaceResult
             * @property {feedbox.trade_api.IOrderList|null} [orderList] TradeData orderList
             * @property {feedbox.trade_api.IOrderExecutionList|null} [orderExecutionList] TradeData orderExecutionList
             * @property {feedbox.trade_api.IPositionList|null} [positionList] TradeData positionList
             * @property {feedbox.trade_api.IPosition|null} [position] TradeData position
             * @property {feedbox.trade_api.IResult|null} [result] TradeData result
             * @property {feedbox.trade_api.ICustomerInfo|null} [customerInfo] TradeData customerInfo
             * @property {feedbox.trade_api.IBalances|null} [balances] TradeData balances
             * @property {feedbox.trade_api.IBalance|null} [balance] TradeData balance
             * @property {feedbox.trade_api.ITotalAmounts|null} [totalAmounts] TradeData totalAmounts
             * @property {feedbox.trade_api.IBet|null} [bet] TradeData bet
             * @property {feedbox.trade_api.IBetList|null} [betList] TradeData betList
             * @property {feedbox.trade_api.IPlaceBetResult|null} [placeBetResult] TradeData placeBetResult
             * @property {feedbox.ping.IPong|null} [pong] TradeData pong
             * @property {feedbox.server_time.IServerTime|null} [serverTime] TradeData serverTime
             * @property {feedbox.trade_api.ICommission|null} [commission] TradeData commission
             * @property {feedbox.trade_api.ICurrencyList|null} [currencyList] TradeData currencyList
             * @property {feedbox.trade_api.ICurrency|null} [currency] TradeData currency
             * @property {feedbox.trade_api.IInsuranceConditionList|null} [insuranceConditionList] TradeData insuranceConditionList
             * @property {feedbox.trade_api.IInsuranceCondition|null} [insuranceCondition] TradeData insuranceCondition
             */

            /**
             * Constructs a new TradeData.
             * @memberof feedbox.trade_api
             * @classdesc Represents a TradeData.
             * @implements ITradeData
             * @constructor
             * @param {feedbox.trade_api.ITradeData=} [properties] Properties to set
             */
            function TradeData(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TradeData id.
             * @member {number|Long} id
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * TradeData orderRequestResult.
             * @member {feedbox.trade_api.IOrderRequestResult|null|undefined} orderRequestResult
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.orderRequestResult = null;

            /**
             * TradeData order.
             * @member {feedbox.trade_api.IOrder|null|undefined} order
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.order = null;

            /**
             * TradeData orderExecution.
             * @member {feedbox.trade_api.IOrderExecution|null|undefined} orderExecution
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.orderExecution = null;

            /**
             * TradeData orderCancelResult.
             * @member {feedbox.trade_api.IOrderCancelResult|null|undefined} orderCancelResult
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.orderCancelResult = null;

            /**
             * TradeData orderReplaceResult.
             * @member {feedbox.trade_api.IOrderReplaceResult|null|undefined} orderReplaceResult
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.orderReplaceResult = null;

            /**
             * TradeData orderList.
             * @member {feedbox.trade_api.IOrderList|null|undefined} orderList
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.orderList = null;

            /**
             * TradeData orderExecutionList.
             * @member {feedbox.trade_api.IOrderExecutionList|null|undefined} orderExecutionList
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.orderExecutionList = null;

            /**
             * TradeData positionList.
             * @member {feedbox.trade_api.IPositionList|null|undefined} positionList
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.positionList = null;

            /**
             * TradeData position.
             * @member {feedbox.trade_api.IPosition|null|undefined} position
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.position = null;

            /**
             * TradeData result.
             * @member {feedbox.trade_api.IResult|null|undefined} result
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.result = null;

            /**
             * TradeData customerInfo.
             * @member {feedbox.trade_api.ICustomerInfo|null|undefined} customerInfo
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.customerInfo = null;

            /**
             * TradeData balances.
             * @member {feedbox.trade_api.IBalances|null|undefined} balances
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.balances = null;

            /**
             * TradeData balance.
             * @member {feedbox.trade_api.IBalance|null|undefined} balance
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.balance = null;

            /**
             * TradeData totalAmounts.
             * @member {feedbox.trade_api.ITotalAmounts|null|undefined} totalAmounts
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.totalAmounts = null;

            /**
             * TradeData bet.
             * @member {feedbox.trade_api.IBet|null|undefined} bet
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.bet = null;

            /**
             * TradeData betList.
             * @member {feedbox.trade_api.IBetList|null|undefined} betList
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.betList = null;

            /**
             * TradeData placeBetResult.
             * @member {feedbox.trade_api.IPlaceBetResult|null|undefined} placeBetResult
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.placeBetResult = null;

            /**
             * TradeData pong.
             * @member {feedbox.ping.IPong|null|undefined} pong
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.pong = null;

            /**
             * TradeData serverTime.
             * @member {feedbox.server_time.IServerTime|null|undefined} serverTime
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.serverTime = null;

            /**
             * TradeData commission.
             * @member {feedbox.trade_api.ICommission|null|undefined} commission
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.commission = null;

            /**
             * TradeData currencyList.
             * @member {feedbox.trade_api.ICurrencyList|null|undefined} currencyList
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.currencyList = null;

            /**
             * TradeData currency.
             * @member {feedbox.trade_api.ICurrency|null|undefined} currency
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.currency = null;

            /**
             * TradeData insuranceConditionList.
             * @member {feedbox.trade_api.IInsuranceConditionList|null|undefined} insuranceConditionList
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.insuranceConditionList = null;

            /**
             * TradeData insuranceCondition.
             * @member {feedbox.trade_api.IInsuranceCondition|null|undefined} insuranceCondition
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            TradeData.prototype.insuranceCondition = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * TradeData body.
             * @member {"orderRequestResult"|"order"|"orderExecution"|"orderCancelResult"|"orderReplaceResult"|"orderList"|"orderExecutionList"|"positionList"|"position"|"result"|"customerInfo"|"balances"|"balance"|"totalAmounts"|"bet"|"betList"|"placeBetResult"|"pong"|"serverTime"|"commission"|"currencyList"|"currency"|"insuranceConditionList"|"insuranceCondition"|undefined} body
             * @memberof feedbox.trade_api.TradeData
             * @instance
             */
            Object.defineProperty(TradeData.prototype, "body", {
                get: $util.oneOfGetter($oneOfFields = ["orderRequestResult", "order", "orderExecution", "orderCancelResult", "orderReplaceResult", "orderList", "orderExecutionList", "positionList", "position", "result", "customerInfo", "balances", "balance", "totalAmounts", "bet", "betList", "placeBetResult", "pong", "serverTime", "commission", "currencyList", "currency", "insuranceConditionList", "insuranceCondition"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new TradeData instance using the specified properties.
             * @function create
             * @memberof feedbox.trade_api.TradeData
             * @static
             * @param {feedbox.trade_api.ITradeData=} [properties] Properties to set
             * @returns {feedbox.trade_api.TradeData} TradeData instance
             */
            TradeData.create = function create(properties) {
                return new TradeData(properties);
            };

            /**
             * Encodes the specified TradeData message. Does not implicitly {@link feedbox.trade_api.TradeData.verify|verify} messages.
             * @function encode
             * @memberof feedbox.trade_api.TradeData
             * @static
             * @param {feedbox.trade_api.ITradeData} message TradeData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TradeData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                    $root.feedbox.trade_api.Order.encode(message.order, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.orderExecution != null && Object.hasOwnProperty.call(message, "orderExecution"))
                    $root.feedbox.trade_api.OrderExecution.encode(message.orderExecution, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.orderCancelResult != null && Object.hasOwnProperty.call(message, "orderCancelResult"))
                    $root.feedbox.trade_api.OrderCancelResult.encode(message.orderCancelResult, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.orderList != null && Object.hasOwnProperty.call(message, "orderList"))
                    $root.feedbox.trade_api.OrderList.encode(message.orderList, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.orderExecutionList != null && Object.hasOwnProperty.call(message, "orderExecutionList"))
                    $root.feedbox.trade_api.OrderExecutionList.encode(message.orderExecutionList, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.positionList != null && Object.hasOwnProperty.call(message, "positionList"))
                    $root.feedbox.trade_api.PositionList.encode(message.positionList, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                    $root.feedbox.trade_api.Position.encode(message.position, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                    $root.feedbox.trade_api.Result.encode(message.result, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.customerInfo != null && Object.hasOwnProperty.call(message, "customerInfo"))
                    $root.feedbox.trade_api.CustomerInfo.encode(message.customerInfo, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.balances != null && Object.hasOwnProperty.call(message, "balances"))
                    $root.feedbox.trade_api.Balances.encode(message.balances, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.totalAmounts != null && Object.hasOwnProperty.call(message, "totalAmounts"))
                    $root.feedbox.trade_api.TotalAmounts.encode(message.totalAmounts, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                    $root.feedbox.trade_api.Balance.encode(message.balance, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.bet != null && Object.hasOwnProperty.call(message, "bet"))
                    $root.feedbox.trade_api.Bet.encode(message.bet, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.betList != null && Object.hasOwnProperty.call(message, "betList"))
                    $root.feedbox.trade_api.BetList.encode(message.betList, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.pong != null && Object.hasOwnProperty.call(message, "pong"))
                    $root.feedbox.ping.Pong.encode(message.pong, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.serverTime != null && Object.hasOwnProperty.call(message, "serverTime"))
                    $root.feedbox.server_time.ServerTime.encode(message.serverTime, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.commission != null && Object.hasOwnProperty.call(message, "commission"))
                    $root.feedbox.trade_api.Commission.encode(message.commission, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.currencyList != null && Object.hasOwnProperty.call(message, "currencyList"))
                    $root.feedbox.trade_api.CurrencyList.encode(message.currencyList, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                    $root.feedbox.trade_api.Currency.encode(message.currency, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.insuranceConditionList != null && Object.hasOwnProperty.call(message, "insuranceConditionList"))
                    $root.feedbox.trade_api.InsuranceConditionList.encode(message.insuranceConditionList, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.insuranceCondition != null && Object.hasOwnProperty.call(message, "insuranceCondition"))
                    $root.feedbox.trade_api.InsuranceCondition.encode(message.insuranceCondition, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.orderRequestResult != null && Object.hasOwnProperty.call(message, "orderRequestResult"))
                    $root.feedbox.trade_api.OrderRequestResult.encode(message.orderRequestResult, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.placeBetResult != null && Object.hasOwnProperty.call(message, "placeBetResult"))
                    $root.feedbox.trade_api.PlaceBetResult.encode(message.placeBetResult, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                if (message.orderReplaceResult != null && Object.hasOwnProperty.call(message, "orderReplaceResult"))
                    $root.feedbox.trade_api.OrderReplaceResult.encode(message.orderReplaceResult, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a TradeData message from the specified reader or buffer.
             * @function decode
             * @memberof feedbox.trade_api.TradeData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {feedbox.trade_api.TradeData} TradeData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TradeData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.feedbox.trade_api.TradeData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.uint64();
                        break;
                    case 24:
                        message.orderRequestResult = $root.feedbox.trade_api.OrderRequestResult.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.order = $root.feedbox.trade_api.Order.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.orderExecution = $root.feedbox.trade_api.OrderExecution.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.orderCancelResult = $root.feedbox.trade_api.OrderCancelResult.decode(reader, reader.uint32());
                        break;
                    case 26:
                        message.orderReplaceResult = $root.feedbox.trade_api.OrderReplaceResult.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.orderList = $root.feedbox.trade_api.OrderList.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.orderExecutionList = $root.feedbox.trade_api.OrderExecutionList.decode(reader, reader.uint32());
                        break;
                    case 7:
                        message.positionList = $root.feedbox.trade_api.PositionList.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.position = $root.feedbox.trade_api.Position.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.result = $root.feedbox.trade_api.Result.decode(reader, reader.uint32());
                        break;
                    case 10:
                        message.customerInfo = $root.feedbox.trade_api.CustomerInfo.decode(reader, reader.uint32());
                        break;
                    case 11:
                        message.balances = $root.feedbox.trade_api.Balances.decode(reader, reader.uint32());
                        break;
                    case 14:
                        message.balance = $root.feedbox.trade_api.Balance.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.totalAmounts = $root.feedbox.trade_api.TotalAmounts.decode(reader, reader.uint32());
                        break;
                    case 15:
                        message.bet = $root.feedbox.trade_api.Bet.decode(reader, reader.uint32());
                        break;
                    case 16:
                        message.betList = $root.feedbox.trade_api.BetList.decode(reader, reader.uint32());
                        break;
                    case 25:
                        message.placeBetResult = $root.feedbox.trade_api.PlaceBetResult.decode(reader, reader.uint32());
                        break;
                    case 17:
                        message.pong = $root.feedbox.ping.Pong.decode(reader, reader.uint32());
                        break;
                    case 18:
                        message.serverTime = $root.feedbox.server_time.ServerTime.decode(reader, reader.uint32());
                        break;
                    case 19:
                        message.commission = $root.feedbox.trade_api.Commission.decode(reader, reader.uint32());
                        break;
                    case 20:
                        message.currencyList = $root.feedbox.trade_api.CurrencyList.decode(reader, reader.uint32());
                        break;
                    case 21:
                        message.currency = $root.feedbox.trade_api.Currency.decode(reader, reader.uint32());
                        break;
                    case 22:
                        message.insuranceConditionList = $root.feedbox.trade_api.InsuranceConditionList.decode(reader, reader.uint32());
                        break;
                    case 23:
                        message.insuranceCondition = $root.feedbox.trade_api.InsuranceCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a TradeData message.
             * @function verify
             * @memberof feedbox.trade_api.TradeData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TradeData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                        return "id: integer|Long expected";
                if (message.orderRequestResult != null && message.hasOwnProperty("orderRequestResult")) {
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.OrderRequestResult.verify(message.orderRequestResult);
                        if (error)
                            return "orderRequestResult." + error;
                    }
                }
                if (message.order != null && message.hasOwnProperty("order")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.Order.verify(message.order);
                        if (error)
                            return "order." + error;
                    }
                }
                if (message.orderExecution != null && message.hasOwnProperty("orderExecution")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.OrderExecution.verify(message.orderExecution);
                        if (error)
                            return "orderExecution." + error;
                    }
                }
                if (message.orderCancelResult != null && message.hasOwnProperty("orderCancelResult")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.OrderCancelResult.verify(message.orderCancelResult);
                        if (error)
                            return "orderCancelResult." + error;
                    }
                }
                if (message.orderReplaceResult != null && message.hasOwnProperty("orderReplaceResult")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.OrderReplaceResult.verify(message.orderReplaceResult);
                        if (error)
                            return "orderReplaceResult." + error;
                    }
                }
                if (message.orderList != null && message.hasOwnProperty("orderList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.OrderList.verify(message.orderList);
                        if (error)
                            return "orderList." + error;
                    }
                }
                if (message.orderExecutionList != null && message.hasOwnProperty("orderExecutionList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.OrderExecutionList.verify(message.orderExecutionList);
                        if (error)
                            return "orderExecutionList." + error;
                    }
                }
                if (message.positionList != null && message.hasOwnProperty("positionList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.PositionList.verify(message.positionList);
                        if (error)
                            return "positionList." + error;
                    }
                }
                if (message.position != null && message.hasOwnProperty("position")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.Position.verify(message.position);
                        if (error)
                            return "position." + error;
                    }
                }
                if (message.result != null && message.hasOwnProperty("result")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.Result.verify(message.result);
                        if (error)
                            return "result." + error;
                    }
                }
                if (message.customerInfo != null && message.hasOwnProperty("customerInfo")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.CustomerInfo.verify(message.customerInfo);
                        if (error)
                            return "customerInfo." + error;
                    }
                }
                if (message.balances != null && message.hasOwnProperty("balances")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.Balances.verify(message.balances);
                        if (error)
                            return "balances." + error;
                    }
                }
                if (message.balance != null && message.hasOwnProperty("balance")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.Balance.verify(message.balance);
                        if (error)
                            return "balance." + error;
                    }
                }
                if (message.totalAmounts != null && message.hasOwnProperty("totalAmounts")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.TotalAmounts.verify(message.totalAmounts);
                        if (error)
                            return "totalAmounts." + error;
                    }
                }
                if (message.bet != null && message.hasOwnProperty("bet")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.Bet.verify(message.bet);
                        if (error)
                            return "bet." + error;
                    }
                }
                if (message.betList != null && message.hasOwnProperty("betList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.BetList.verify(message.betList);
                        if (error)
                            return "betList." + error;
                    }
                }
                if (message.placeBetResult != null && message.hasOwnProperty("placeBetResult")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.PlaceBetResult.verify(message.placeBetResult);
                        if (error)
                            return "placeBetResult." + error;
                    }
                }
                if (message.pong != null && message.hasOwnProperty("pong")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.ping.Pong.verify(message.pong);
                        if (error)
                            return "pong." + error;
                    }
                }
                if (message.serverTime != null && message.hasOwnProperty("serverTime")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.server_time.ServerTime.verify(message.serverTime);
                        if (error)
                            return "serverTime." + error;
                    }
                }
                if (message.commission != null && message.hasOwnProperty("commission")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.Commission.verify(message.commission);
                        if (error)
                            return "commission." + error;
                    }
                }
                if (message.currencyList != null && message.hasOwnProperty("currencyList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.CurrencyList.verify(message.currencyList);
                        if (error)
                            return "currencyList." + error;
                    }
                }
                if (message.currency != null && message.hasOwnProperty("currency")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.Currency.verify(message.currency);
                        if (error)
                            return "currency." + error;
                    }
                }
                if (message.insuranceConditionList != null && message.hasOwnProperty("insuranceConditionList")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.InsuranceConditionList.verify(message.insuranceConditionList);
                        if (error)
                            return "insuranceConditionList." + error;
                    }
                }
                if (message.insuranceCondition != null && message.hasOwnProperty("insuranceCondition")) {
                    if (properties.body === 1)
                        return "body: multiple values";
                    properties.body = 1;
                    {
                        var error = $root.feedbox.trade_api.InsuranceCondition.verify(message.insuranceCondition);
                        if (error)
                            return "insuranceCondition." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a TradeData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof feedbox.trade_api.TradeData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {feedbox.trade_api.TradeData} TradeData
             */
            TradeData.fromObject = function fromObject(object) {
                if (object instanceof $root.feedbox.trade_api.TradeData)
                    return object;
                var message = new $root.feedbox.trade_api.TradeData();
                if (object.id != null)
                    if ($util.Long)
                        (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                    else if (typeof object.id === "string")
                        message.id = parseInt(object.id, 10);
                    else if (typeof object.id === "number")
                        message.id = object.id;
                    else if (typeof object.id === "object")
                        message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                if (object.orderRequestResult != null) {
                    if (typeof object.orderRequestResult !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.orderRequestResult: object expected");
                    message.orderRequestResult = $root.feedbox.trade_api.OrderRequestResult.fromObject(object.orderRequestResult);
                }
                if (object.order != null) {
                    if (typeof object.order !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.order: object expected");
                    message.order = $root.feedbox.trade_api.Order.fromObject(object.order);
                }
                if (object.orderExecution != null) {
                    if (typeof object.orderExecution !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.orderExecution: object expected");
                    message.orderExecution = $root.feedbox.trade_api.OrderExecution.fromObject(object.orderExecution);
                }
                if (object.orderCancelResult != null) {
                    if (typeof object.orderCancelResult !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.orderCancelResult: object expected");
                    message.orderCancelResult = $root.feedbox.trade_api.OrderCancelResult.fromObject(object.orderCancelResult);
                }
                if (object.orderReplaceResult != null) {
                    if (typeof object.orderReplaceResult !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.orderReplaceResult: object expected");
                    message.orderReplaceResult = $root.feedbox.trade_api.OrderReplaceResult.fromObject(object.orderReplaceResult);
                }
                if (object.orderList != null) {
                    if (typeof object.orderList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.orderList: object expected");
                    message.orderList = $root.feedbox.trade_api.OrderList.fromObject(object.orderList);
                }
                if (object.orderExecutionList != null) {
                    if (typeof object.orderExecutionList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.orderExecutionList: object expected");
                    message.orderExecutionList = $root.feedbox.trade_api.OrderExecutionList.fromObject(object.orderExecutionList);
                }
                if (object.positionList != null) {
                    if (typeof object.positionList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.positionList: object expected");
                    message.positionList = $root.feedbox.trade_api.PositionList.fromObject(object.positionList);
                }
                if (object.position != null) {
                    if (typeof object.position !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.position: object expected");
                    message.position = $root.feedbox.trade_api.Position.fromObject(object.position);
                }
                if (object.result != null) {
                    if (typeof object.result !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.result: object expected");
                    message.result = $root.feedbox.trade_api.Result.fromObject(object.result);
                }
                if (object.customerInfo != null) {
                    if (typeof object.customerInfo !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.customerInfo: object expected");
                    message.customerInfo = $root.feedbox.trade_api.CustomerInfo.fromObject(object.customerInfo);
                }
                if (object.balances != null) {
                    if (typeof object.balances !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.balances: object expected");
                    message.balances = $root.feedbox.trade_api.Balances.fromObject(object.balances);
                }
                if (object.balance != null) {
                    if (typeof object.balance !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.balance: object expected");
                    message.balance = $root.feedbox.trade_api.Balance.fromObject(object.balance);
                }
                if (object.totalAmounts != null) {
                    if (typeof object.totalAmounts !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.totalAmounts: object expected");
                    message.totalAmounts = $root.feedbox.trade_api.TotalAmounts.fromObject(object.totalAmounts);
                }
                if (object.bet != null) {
                    if (typeof object.bet !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.bet: object expected");
                    message.bet = $root.feedbox.trade_api.Bet.fromObject(object.bet);
                }
                if (object.betList != null) {
                    if (typeof object.betList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.betList: object expected");
                    message.betList = $root.feedbox.trade_api.BetList.fromObject(object.betList);
                }
                if (object.placeBetResult != null) {
                    if (typeof object.placeBetResult !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.placeBetResult: object expected");
                    message.placeBetResult = $root.feedbox.trade_api.PlaceBetResult.fromObject(object.placeBetResult);
                }
                if (object.pong != null) {
                    if (typeof object.pong !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.pong: object expected");
                    message.pong = $root.feedbox.ping.Pong.fromObject(object.pong);
                }
                if (object.serverTime != null) {
                    if (typeof object.serverTime !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.serverTime: object expected");
                    message.serverTime = $root.feedbox.server_time.ServerTime.fromObject(object.serverTime);
                }
                if (object.commission != null) {
                    if (typeof object.commission !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.commission: object expected");
                    message.commission = $root.feedbox.trade_api.Commission.fromObject(object.commission);
                }
                if (object.currencyList != null) {
                    if (typeof object.currencyList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.currencyList: object expected");
                    message.currencyList = $root.feedbox.trade_api.CurrencyList.fromObject(object.currencyList);
                }
                if (object.currency != null) {
                    if (typeof object.currency !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.currency: object expected");
                    message.currency = $root.feedbox.trade_api.Currency.fromObject(object.currency);
                }
                if (object.insuranceConditionList != null) {
                    if (typeof object.insuranceConditionList !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.insuranceConditionList: object expected");
                    message.insuranceConditionList = $root.feedbox.trade_api.InsuranceConditionList.fromObject(object.insuranceConditionList);
                }
                if (object.insuranceCondition != null) {
                    if (typeof object.insuranceCondition !== "object")
                        throw TypeError(".feedbox.trade_api.TradeData.insuranceCondition: object expected");
                    message.insuranceCondition = $root.feedbox.trade_api.InsuranceCondition.fromObject(object.insuranceCondition);
                }
                return message;
            };

            /**
             * Creates a plain object from a TradeData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof feedbox.trade_api.TradeData
             * @static
             * @param {feedbox.trade_api.TradeData} message TradeData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TradeData.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.id = options.longs === String ? "0" : 0;
                if (message.id != null && message.hasOwnProperty("id"))
                    if (typeof message.id === "number")
                        object.id = options.longs === String ? String(message.id) : message.id;
                    else
                        object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                if (message.order != null && message.hasOwnProperty("order")) {
                    object.order = $root.feedbox.trade_api.Order.toObject(message.order, options);
                    if (options.oneofs)
                        object.body = "order";
                }
                if (message.orderExecution != null && message.hasOwnProperty("orderExecution")) {
                    object.orderExecution = $root.feedbox.trade_api.OrderExecution.toObject(message.orderExecution, options);
                    if (options.oneofs)
                        object.body = "orderExecution";
                }
                if (message.orderCancelResult != null && message.hasOwnProperty("orderCancelResult")) {
                    object.orderCancelResult = $root.feedbox.trade_api.OrderCancelResult.toObject(message.orderCancelResult, options);
                    if (options.oneofs)
                        object.body = "orderCancelResult";
                }
                if (message.orderList != null && message.hasOwnProperty("orderList")) {
                    object.orderList = $root.feedbox.trade_api.OrderList.toObject(message.orderList, options);
                    if (options.oneofs)
                        object.body = "orderList";
                }
                if (message.orderExecutionList != null && message.hasOwnProperty("orderExecutionList")) {
                    object.orderExecutionList = $root.feedbox.trade_api.OrderExecutionList.toObject(message.orderExecutionList, options);
                    if (options.oneofs)
                        object.body = "orderExecutionList";
                }
                if (message.positionList != null && message.hasOwnProperty("positionList")) {
                    object.positionList = $root.feedbox.trade_api.PositionList.toObject(message.positionList, options);
                    if (options.oneofs)
                        object.body = "positionList";
                }
                if (message.position != null && message.hasOwnProperty("position")) {
                    object.position = $root.feedbox.trade_api.Position.toObject(message.position, options);
                    if (options.oneofs)
                        object.body = "position";
                }
                if (message.result != null && message.hasOwnProperty("result")) {
                    object.result = $root.feedbox.trade_api.Result.toObject(message.result, options);
                    if (options.oneofs)
                        object.body = "result";
                }
                if (message.customerInfo != null && message.hasOwnProperty("customerInfo")) {
                    object.customerInfo = $root.feedbox.trade_api.CustomerInfo.toObject(message.customerInfo, options);
                    if (options.oneofs)
                        object.body = "customerInfo";
                }
                if (message.balances != null && message.hasOwnProperty("balances")) {
                    object.balances = $root.feedbox.trade_api.Balances.toObject(message.balances, options);
                    if (options.oneofs)
                        object.body = "balances";
                }
                if (message.totalAmounts != null && message.hasOwnProperty("totalAmounts")) {
                    object.totalAmounts = $root.feedbox.trade_api.TotalAmounts.toObject(message.totalAmounts, options);
                    if (options.oneofs)
                        object.body = "totalAmounts";
                }
                if (message.balance != null && message.hasOwnProperty("balance")) {
                    object.balance = $root.feedbox.trade_api.Balance.toObject(message.balance, options);
                    if (options.oneofs)
                        object.body = "balance";
                }
                if (message.bet != null && message.hasOwnProperty("bet")) {
                    object.bet = $root.feedbox.trade_api.Bet.toObject(message.bet, options);
                    if (options.oneofs)
                        object.body = "bet";
                }
                if (message.betList != null && message.hasOwnProperty("betList")) {
                    object.betList = $root.feedbox.trade_api.BetList.toObject(message.betList, options);
                    if (options.oneofs)
                        object.body = "betList";
                }
                if (message.pong != null && message.hasOwnProperty("pong")) {
                    object.pong = $root.feedbox.ping.Pong.toObject(message.pong, options);
                    if (options.oneofs)
                        object.body = "pong";
                }
                if (message.serverTime != null && message.hasOwnProperty("serverTime")) {
                    object.serverTime = $root.feedbox.server_time.ServerTime.toObject(message.serverTime, options);
                    if (options.oneofs)
                        object.body = "serverTime";
                }
                if (message.commission != null && message.hasOwnProperty("commission")) {
                    object.commission = $root.feedbox.trade_api.Commission.toObject(message.commission, options);
                    if (options.oneofs)
                        object.body = "commission";
                }
                if (message.currencyList != null && message.hasOwnProperty("currencyList")) {
                    object.currencyList = $root.feedbox.trade_api.CurrencyList.toObject(message.currencyList, options);
                    if (options.oneofs)
                        object.body = "currencyList";
                }
                if (message.currency != null && message.hasOwnProperty("currency")) {
                    object.currency = $root.feedbox.trade_api.Currency.toObject(message.currency, options);
                    if (options.oneofs)
                        object.body = "currency";
                }
                if (message.insuranceConditionList != null && message.hasOwnProperty("insuranceConditionList")) {
                    object.insuranceConditionList = $root.feedbox.trade_api.InsuranceConditionList.toObject(message.insuranceConditionList, options);
                    if (options.oneofs)
                        object.body = "insuranceConditionList";
                }
                if (message.insuranceCondition != null && message.hasOwnProperty("insuranceCondition")) {
                    object.insuranceCondition = $root.feedbox.trade_api.InsuranceCondition.toObject(message.insuranceCondition, options);
                    if (options.oneofs)
                        object.body = "insuranceCondition";
                }
                if (message.orderRequestResult != null && message.hasOwnProperty("orderRequestResult")) {
                    object.orderRequestResult = $root.feedbox.trade_api.OrderRequestResult.toObject(message.orderRequestResult, options);
                    if (options.oneofs)
                        object.body = "orderRequestResult";
                }
                if (message.placeBetResult != null && message.hasOwnProperty("placeBetResult")) {
                    object.placeBetResult = $root.feedbox.trade_api.PlaceBetResult.toObject(message.placeBetResult, options);
                    if (options.oneofs)
                        object.body = "placeBetResult";
                }
                if (message.orderReplaceResult != null && message.hasOwnProperty("orderReplaceResult")) {
                    object.orderReplaceResult = $root.feedbox.trade_api.OrderReplaceResult.toObject(message.orderReplaceResult, options);
                    if (options.oneofs)
                        object.body = "orderReplaceResult";
                }
                return object;
            };

            /**
             * Converts this TradeData to JSON.
             * @function toJSON
             * @memberof feedbox.trade_api.TradeData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TradeData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TradeData;
        })();

        return trade_api;
    })();

    return feedbox;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.FileDescriptorSet = (function() {

            /**
             * Properties of a FileDescriptorSet.
             * @memberof google.protobuf
             * @interface IFileDescriptorSet
             * @property {Array.<google.protobuf.IFileDescriptorProto>|null} [file] FileDescriptorSet file
             */

            /**
             * Constructs a new FileDescriptorSet.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorSet.
             * @implements IFileDescriptorSet
             * @constructor
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             */
            function FileDescriptorSet(properties) {
                this.file = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorSet file.
             * @member {Array.<google.protobuf.IFileDescriptorProto>} file
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             */
            FileDescriptorSet.prototype.file = $util.emptyArray;

            /**
             * Creates a new FileDescriptorSet instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet instance
             */
            FileDescriptorSet.create = function create(properties) {
                return new FileDescriptorSet(properties);
            };

            /**
             * Encodes the specified FileDescriptorSet message. Does not implicitly {@link google.protobuf.FileDescriptorSet.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.IFileDescriptorSet} message FileDescriptorSet message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorSet.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.file != null && message.file.length)
                    for (var i = 0; i < message.file.length; ++i)
                        $root.google.protobuf.FileDescriptorProto.encode(message.file[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a FileDescriptorSet message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorSet.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorSet();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.file && message.file.length))
                            message.file = [];
                        message.file.push($root.google.protobuf.FileDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FileDescriptorSet message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorSet.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.file != null && message.hasOwnProperty("file")) {
                    if (!Array.isArray(message.file))
                        return "file: array expected";
                    for (var i = 0; i < message.file.length; ++i) {
                        var error = $root.google.protobuf.FileDescriptorProto.verify(message.file[i]);
                        if (error)
                            return "file." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileDescriptorSet message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorSet} FileDescriptorSet
             */
            FileDescriptorSet.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorSet)
                    return object;
                var message = new $root.google.protobuf.FileDescriptorSet();
                if (object.file) {
                    if (!Array.isArray(object.file))
                        throw TypeError(".google.protobuf.FileDescriptorSet.file: array expected");
                    message.file = [];
                    for (var i = 0; i < object.file.length; ++i) {
                        if (typeof object.file[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorSet.file: object expected");
                        message.file[i] = $root.google.protobuf.FileDescriptorProto.fromObject(object.file[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorSet message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorSet
             * @static
             * @param {google.protobuf.FileDescriptorSet} message FileDescriptorSet
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorSet.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.file = [];
                if (message.file && message.file.length) {
                    object.file = [];
                    for (var j = 0; j < message.file.length; ++j)
                        object.file[j] = $root.google.protobuf.FileDescriptorProto.toObject(message.file[j], options);
                }
                return object;
            };

            /**
             * Converts this FileDescriptorSet to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorSet
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorSet.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorSet;
        })();

        protobuf.FileDescriptorProto = (function() {

            /**
             * Properties of a FileDescriptorProto.
             * @memberof google.protobuf
             * @interface IFileDescriptorProto
             * @property {string|null} [name] FileDescriptorProto name
             * @property {string|null} ["package"] FileDescriptorProto package
             * @property {Array.<string>|null} [dependency] FileDescriptorProto dependency
             * @property {Array.<number>|null} [publicDependency] FileDescriptorProto publicDependency
             * @property {Array.<number>|null} [weakDependency] FileDescriptorProto weakDependency
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [messageType] FileDescriptorProto messageType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] FileDescriptorProto enumType
             * @property {Array.<google.protobuf.IServiceDescriptorProto>|null} [service] FileDescriptorProto service
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] FileDescriptorProto extension
             * @property {google.protobuf.IFileOptions|null} [options] FileDescriptorProto options
             * @property {google.protobuf.ISourceCodeInfo|null} [sourceCodeInfo] FileDescriptorProto sourceCodeInfo
             * @property {string|null} [syntax] FileDescriptorProto syntax
             */

            /**
             * Constructs a new FileDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FileDescriptorProto.
             * @implements IFileDescriptorProto
             * @constructor
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             */
            function FileDescriptorProto(properties) {
                this.dependency = [];
                this.publicDependency = [];
                this.weakDependency = [];
                this.messageType = [];
                this.enumType = [];
                this.service = [];
                this.extension = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.name = "";

            /**
             * FileDescriptorProto package.
             * @member {string} package
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype["package"] = "";

            /**
             * FileDescriptorProto dependency.
             * @member {Array.<string>} dependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.dependency = $util.emptyArray;

            /**
             * FileDescriptorProto publicDependency.
             * @member {Array.<number>} publicDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.publicDependency = $util.emptyArray;

            /**
             * FileDescriptorProto weakDependency.
             * @member {Array.<number>} weakDependency
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.weakDependency = $util.emptyArray;

            /**
             * FileDescriptorProto messageType.
             * @member {Array.<google.protobuf.IDescriptorProto>} messageType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.messageType = $util.emptyArray;

            /**
             * FileDescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * FileDescriptorProto service.
             * @member {Array.<google.protobuf.IServiceDescriptorProto>} service
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.service = $util.emptyArray;

            /**
             * FileDescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * FileDescriptorProto options.
             * @member {google.protobuf.IFileOptions|null|undefined} options
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.options = null;

            /**
             * FileDescriptorProto sourceCodeInfo.
             * @member {google.protobuf.ISourceCodeInfo|null|undefined} sourceCodeInfo
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.sourceCodeInfo = null;

            /**
             * FileDescriptorProto syntax.
             * @member {string} syntax
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             */
            FileDescriptorProto.prototype.syntax = "";

            /**
             * Creates a new FileDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto instance
             */
            FileDescriptorProto.create = function create(properties) {
                return new FileDescriptorProto(properties);
            };

            /**
             * Encodes the specified FileDescriptorProto message. Does not implicitly {@link google.protobuf.FileDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.IFileDescriptorProto} message FileDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message["package"] != null && Object.hasOwnProperty.call(message, "package"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message["package"]);
                if (message.dependency != null && message.dependency.length)
                    for (var i = 0; i < message.dependency.length; ++i)
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.dependency[i]);
                if (message.messageType != null && message.messageType.length)
                    for (var i = 0; i < message.messageType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.messageType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (var i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.service != null && message.service.length)
                    for (var i = 0; i < message.service.length; ++i)
                        $root.google.protobuf.ServiceDescriptorProto.encode(message.service[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (var i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FileOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.sourceCodeInfo != null && Object.hasOwnProperty.call(message, "sourceCodeInfo"))
                    $root.google.protobuf.SourceCodeInfo.encode(message.sourceCodeInfo, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.publicDependency != null && message.publicDependency.length)
                    for (var i = 0; i < message.publicDependency.length; ++i)
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.publicDependency[i]);
                if (message.weakDependency != null && message.weakDependency.length)
                    for (var i = 0; i < message.weakDependency.length; ++i)
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.weakDependency[i]);
                if (message.syntax != null && Object.hasOwnProperty.call(message, "syntax"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.syntax);
                return writer;
            };

            /**
             * Decodes a FileDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message["package"] = reader.string();
                        break;
                    case 3:
                        if (!(message.dependency && message.dependency.length))
                            message.dependency = [];
                        message.dependency.push(reader.string());
                        break;
                    case 10:
                        if (!(message.publicDependency && message.publicDependency.length))
                            message.publicDependency = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.publicDependency.push(reader.int32());
                        } else
                            message.publicDependency.push(reader.int32());
                        break;
                    case 11:
                        if (!(message.weakDependency && message.weakDependency.length))
                            message.weakDependency = [];
                        if ((tag & 7) === 2) {
                            var end2 = reader.uint32() + reader.pos;
                            while (reader.pos < end2)
                                message.weakDependency.push(reader.int32());
                        } else
                            message.weakDependency.push(reader.int32());
                        break;
                    case 4:
                        if (!(message.messageType && message.messageType.length))
                            message.messageType = [];
                        message.messageType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 5:
                        if (!(message.enumType && message.enumType.length))
                            message.enumType = [];
                        message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 6:
                        if (!(message.service && message.service.length))
                            message.service = [];
                        message.service.push($root.google.protobuf.ServiceDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 7:
                        if (!(message.extension && message.extension.length))
                            message.extension = [];
                        message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 8:
                        message.options = $root.google.protobuf.FileOptions.decode(reader, reader.uint32());
                        break;
                    case 9:
                        message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.decode(reader, reader.uint32());
                        break;
                    case 12:
                        message.syntax = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FileDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message["package"] != null && message.hasOwnProperty("package"))
                    if (!$util.isString(message["package"]))
                        return "package: string expected";
                if (message.dependency != null && message.hasOwnProperty("dependency")) {
                    if (!Array.isArray(message.dependency))
                        return "dependency: array expected";
                    for (var i = 0; i < message.dependency.length; ++i)
                        if (!$util.isString(message.dependency[i]))
                            return "dependency: string[] expected";
                }
                if (message.publicDependency != null && message.hasOwnProperty("publicDependency")) {
                    if (!Array.isArray(message.publicDependency))
                        return "publicDependency: array expected";
                    for (var i = 0; i < message.publicDependency.length; ++i)
                        if (!$util.isInteger(message.publicDependency[i]))
                            return "publicDependency: integer[] expected";
                }
                if (message.weakDependency != null && message.hasOwnProperty("weakDependency")) {
                    if (!Array.isArray(message.weakDependency))
                        return "weakDependency: array expected";
                    for (var i = 0; i < message.weakDependency.length; ++i)
                        if (!$util.isInteger(message.weakDependency[i]))
                            return "weakDependency: integer[] expected";
                }
                if (message.messageType != null && message.hasOwnProperty("messageType")) {
                    if (!Array.isArray(message.messageType))
                        return "messageType: array expected";
                    for (var i = 0; i < message.messageType.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.verify(message.messageType[i]);
                        if (error)
                            return "messageType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (var i = 0; i < message.enumType.length; ++i) {
                        var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.service != null && message.hasOwnProperty("service")) {
                    if (!Array.isArray(message.service))
                        return "service: array expected";
                    for (var i = 0; i < message.service.length; ++i) {
                        var error = $root.google.protobuf.ServiceDescriptorProto.verify(message.service[i]);
                        if (error)
                            return "service." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (var i = 0; i < message.extension.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.FileOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo")) {
                    var error = $root.google.protobuf.SourceCodeInfo.verify(message.sourceCodeInfo);
                    if (error)
                        return "sourceCodeInfo." + error;
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    if (!$util.isString(message.syntax))
                        return "syntax: string expected";
                return null;
            };

            /**
             * Creates a FileDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileDescriptorProto} FileDescriptorProto
             */
            FileDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.FileDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object["package"] != null)
                    message["package"] = String(object["package"]);
                if (object.dependency) {
                    if (!Array.isArray(object.dependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.dependency: array expected");
                    message.dependency = [];
                    for (var i = 0; i < object.dependency.length; ++i)
                        message.dependency[i] = String(object.dependency[i]);
                }
                if (object.publicDependency) {
                    if (!Array.isArray(object.publicDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.publicDependency: array expected");
                    message.publicDependency = [];
                    for (var i = 0; i < object.publicDependency.length; ++i)
                        message.publicDependency[i] = object.publicDependency[i] | 0;
                }
                if (object.weakDependency) {
                    if (!Array.isArray(object.weakDependency))
                        throw TypeError(".google.protobuf.FileDescriptorProto.weakDependency: array expected");
                    message.weakDependency = [];
                    for (var i = 0; i < object.weakDependency.length; ++i)
                        message.weakDependency[i] = object.weakDependency[i] | 0;
                }
                if (object.messageType) {
                    if (!Array.isArray(object.messageType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.messageType: array expected");
                    message.messageType = [];
                    for (var i = 0; i < object.messageType.length; ++i) {
                        if (typeof object.messageType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.messageType: object expected");
                        message.messageType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.messageType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.FileDescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (var i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.service) {
                    if (!Array.isArray(object.service))
                        throw TypeError(".google.protobuf.FileDescriptorProto.service: array expected");
                    message.service = [];
                    for (var i = 0; i < object.service.length; ++i) {
                        if (typeof object.service[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.service: object expected");
                        message.service[i] = $root.google.protobuf.ServiceDescriptorProto.fromObject(object.service[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.FileDescriptorProto.extension: array expected");
                    message.extension = [];
                    for (var i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.FileDescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FileOptions.fromObject(object.options);
                }
                if (object.sourceCodeInfo != null) {
                    if (typeof object.sourceCodeInfo !== "object")
                        throw TypeError(".google.protobuf.FileDescriptorProto.sourceCodeInfo: object expected");
                    message.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.fromObject(object.sourceCodeInfo);
                }
                if (object.syntax != null)
                    message.syntax = String(object.syntax);
                return message;
            };

            /**
             * Creates a plain object from a FileDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileDescriptorProto
             * @static
             * @param {google.protobuf.FileDescriptorProto} message FileDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.dependency = [];
                    object.messageType = [];
                    object.enumType = [];
                    object.service = [];
                    object.extension = [];
                    object.publicDependency = [];
                    object.weakDependency = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object["package"] = "";
                    object.options = null;
                    object.sourceCodeInfo = null;
                    object.syntax = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message["package"] != null && message.hasOwnProperty("package"))
                    object["package"] = message["package"];
                if (message.dependency && message.dependency.length) {
                    object.dependency = [];
                    for (var j = 0; j < message.dependency.length; ++j)
                        object.dependency[j] = message.dependency[j];
                }
                if (message.messageType && message.messageType.length) {
                    object.messageType = [];
                    for (var j = 0; j < message.messageType.length; ++j)
                        object.messageType[j] = $root.google.protobuf.DescriptorProto.toObject(message.messageType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (var j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.service && message.service.length) {
                    object.service = [];
                    for (var j = 0; j < message.service.length; ++j)
                        object.service[j] = $root.google.protobuf.ServiceDescriptorProto.toObject(message.service[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (var j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FileOptions.toObject(message.options, options);
                if (message.sourceCodeInfo != null && message.hasOwnProperty("sourceCodeInfo"))
                    object.sourceCodeInfo = $root.google.protobuf.SourceCodeInfo.toObject(message.sourceCodeInfo, options);
                if (message.publicDependency && message.publicDependency.length) {
                    object.publicDependency = [];
                    for (var j = 0; j < message.publicDependency.length; ++j)
                        object.publicDependency[j] = message.publicDependency[j];
                }
                if (message.weakDependency && message.weakDependency.length) {
                    object.weakDependency = [];
                    for (var j = 0; j < message.weakDependency.length; ++j)
                        object.weakDependency[j] = message.weakDependency[j];
                }
                if (message.syntax != null && message.hasOwnProperty("syntax"))
                    object.syntax = message.syntax;
                return object;
            };

            /**
             * Converts this FileDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FileDescriptorProto;
        })();

        protobuf.DescriptorProto = (function() {

            /**
             * Properties of a DescriptorProto.
             * @memberof google.protobuf
             * @interface IDescriptorProto
             * @property {string|null} [name] DescriptorProto name
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [field] DescriptorProto field
             * @property {Array.<google.protobuf.IFieldDescriptorProto>|null} [extension] DescriptorProto extension
             * @property {Array.<google.protobuf.IDescriptorProto>|null} [nestedType] DescriptorProto nestedType
             * @property {Array.<google.protobuf.IEnumDescriptorProto>|null} [enumType] DescriptorProto enumType
             * @property {Array.<google.protobuf.DescriptorProto.IExtensionRange>|null} [extensionRange] DescriptorProto extensionRange
             * @property {Array.<google.protobuf.IOneofDescriptorProto>|null} [oneofDecl] DescriptorProto oneofDecl
             * @property {google.protobuf.IMessageOptions|null} [options] DescriptorProto options
             * @property {Array.<google.protobuf.DescriptorProto.IReservedRange>|null} [reservedRange] DescriptorProto reservedRange
             * @property {Array.<string>|null} [reservedName] DescriptorProto reservedName
             */

            /**
             * Constructs a new DescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a DescriptorProto.
             * @implements IDescriptorProto
             * @constructor
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             */
            function DescriptorProto(properties) {
                this.field = [];
                this.extension = [];
                this.nestedType = [];
                this.enumType = [];
                this.extensionRange = [];
                this.oneofDecl = [];
                this.reservedRange = [];
                this.reservedName = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.name = "";

            /**
             * DescriptorProto field.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} field
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.field = $util.emptyArray;

            /**
             * DescriptorProto extension.
             * @member {Array.<google.protobuf.IFieldDescriptorProto>} extension
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extension = $util.emptyArray;

            /**
             * DescriptorProto nestedType.
             * @member {Array.<google.protobuf.IDescriptorProto>} nestedType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.nestedType = $util.emptyArray;

            /**
             * DescriptorProto enumType.
             * @member {Array.<google.protobuf.IEnumDescriptorProto>} enumType
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.enumType = $util.emptyArray;

            /**
             * DescriptorProto extensionRange.
             * @member {Array.<google.protobuf.DescriptorProto.IExtensionRange>} extensionRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.extensionRange = $util.emptyArray;

            /**
             * DescriptorProto oneofDecl.
             * @member {Array.<google.protobuf.IOneofDescriptorProto>} oneofDecl
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.oneofDecl = $util.emptyArray;

            /**
             * DescriptorProto options.
             * @member {google.protobuf.IMessageOptions|null|undefined} options
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.options = null;

            /**
             * DescriptorProto reservedRange.
             * @member {Array.<google.protobuf.DescriptorProto.IReservedRange>} reservedRange
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedRange = $util.emptyArray;

            /**
             * DescriptorProto reservedName.
             * @member {Array.<string>} reservedName
             * @memberof google.protobuf.DescriptorProto
             * @instance
             */
            DescriptorProto.prototype.reservedName = $util.emptyArray;

            /**
             * Creates a new DescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.DescriptorProto} DescriptorProto instance
             */
            DescriptorProto.create = function create(properties) {
                return new DescriptorProto(properties);
            };

            /**
             * Encodes the specified DescriptorProto message. Does not implicitly {@link google.protobuf.DescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.IDescriptorProto} message DescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.field != null && message.field.length)
                    for (var i = 0; i < message.field.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.field[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.nestedType != null && message.nestedType.length)
                    for (var i = 0; i < message.nestedType.length; ++i)
                        $root.google.protobuf.DescriptorProto.encode(message.nestedType[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.enumType != null && message.enumType.length)
                    for (var i = 0; i < message.enumType.length; ++i)
                        $root.google.protobuf.EnumDescriptorProto.encode(message.enumType[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.extensionRange != null && message.extensionRange.length)
                    for (var i = 0; i < message.extensionRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ExtensionRange.encode(message.extensionRange[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.extension != null && message.extension.length)
                    for (var i = 0; i < message.extension.length; ++i)
                        $root.google.protobuf.FieldDescriptorProto.encode(message.extension[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MessageOptions.encode(message.options, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.oneofDecl != null && message.oneofDecl.length)
                    for (var i = 0; i < message.oneofDecl.length; ++i)
                        $root.google.protobuf.OneofDescriptorProto.encode(message.oneofDecl[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.reservedRange != null && message.reservedRange.length)
                    for (var i = 0; i < message.reservedRange.length; ++i)
                        $root.google.protobuf.DescriptorProto.ReservedRange.encode(message.reservedRange[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.reservedName != null && message.reservedName.length)
                    for (var i = 0; i < message.reservedName.length; ++i)
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.reservedName[i]);
                return writer;
            };

            /**
             * Decodes a DescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.field && message.field.length))
                            message.field = [];
                        message.field.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 6:
                        if (!(message.extension && message.extension.length))
                            message.extension = [];
                        message.extension.push($root.google.protobuf.FieldDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        if (!(message.nestedType && message.nestedType.length))
                            message.nestedType = [];
                        message.nestedType.push($root.google.protobuf.DescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 4:
                        if (!(message.enumType && message.enumType.length))
                            message.enumType = [];
                        message.enumType.push($root.google.protobuf.EnumDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 5:
                        if (!(message.extensionRange && message.extensionRange.length))
                            message.extensionRange = [];
                        message.extensionRange.push($root.google.protobuf.DescriptorProto.ExtensionRange.decode(reader, reader.uint32()));
                        break;
                    case 8:
                        if (!(message.oneofDecl && message.oneofDecl.length))
                            message.oneofDecl = [];
                        message.oneofDecl.push($root.google.protobuf.OneofDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 7:
                        message.options = $root.google.protobuf.MessageOptions.decode(reader, reader.uint32());
                        break;
                    case 9:
                        if (!(message.reservedRange && message.reservedRange.length))
                            message.reservedRange = [];
                        message.reservedRange.push($root.google.protobuf.DescriptorProto.ReservedRange.decode(reader, reader.uint32()));
                        break;
                    case 10:
                        if (!(message.reservedName && message.reservedName.length))
                            message.reservedName = [];
                        message.reservedName.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a DescriptorProto message.
             * @function verify
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.field != null && message.hasOwnProperty("field")) {
                    if (!Array.isArray(message.field))
                        return "field: array expected";
                    for (var i = 0; i < message.field.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.field[i]);
                        if (error)
                            return "field." + error;
                    }
                }
                if (message.extension != null && message.hasOwnProperty("extension")) {
                    if (!Array.isArray(message.extension))
                        return "extension: array expected";
                    for (var i = 0; i < message.extension.length; ++i) {
                        var error = $root.google.protobuf.FieldDescriptorProto.verify(message.extension[i]);
                        if (error)
                            return "extension." + error;
                    }
                }
                if (message.nestedType != null && message.hasOwnProperty("nestedType")) {
                    if (!Array.isArray(message.nestedType))
                        return "nestedType: array expected";
                    for (var i = 0; i < message.nestedType.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.verify(message.nestedType[i]);
                        if (error)
                            return "nestedType." + error;
                    }
                }
                if (message.enumType != null && message.hasOwnProperty("enumType")) {
                    if (!Array.isArray(message.enumType))
                        return "enumType: array expected";
                    for (var i = 0; i < message.enumType.length; ++i) {
                        var error = $root.google.protobuf.EnumDescriptorProto.verify(message.enumType[i]);
                        if (error)
                            return "enumType." + error;
                    }
                }
                if (message.extensionRange != null && message.hasOwnProperty("extensionRange")) {
                    if (!Array.isArray(message.extensionRange))
                        return "extensionRange: array expected";
                    for (var i = 0; i < message.extensionRange.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.ExtensionRange.verify(message.extensionRange[i]);
                        if (error)
                            return "extensionRange." + error;
                    }
                }
                if (message.oneofDecl != null && message.hasOwnProperty("oneofDecl")) {
                    if (!Array.isArray(message.oneofDecl))
                        return "oneofDecl: array expected";
                    for (var i = 0; i < message.oneofDecl.length; ++i) {
                        var error = $root.google.protobuf.OneofDescriptorProto.verify(message.oneofDecl[i]);
                        if (error)
                            return "oneofDecl." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.MessageOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.reservedRange != null && message.hasOwnProperty("reservedRange")) {
                    if (!Array.isArray(message.reservedRange))
                        return "reservedRange: array expected";
                    for (var i = 0; i < message.reservedRange.length; ++i) {
                        var error = $root.google.protobuf.DescriptorProto.ReservedRange.verify(message.reservedRange[i]);
                        if (error)
                            return "reservedRange." + error;
                    }
                }
                if (message.reservedName != null && message.hasOwnProperty("reservedName")) {
                    if (!Array.isArray(message.reservedName))
                        return "reservedName: array expected";
                    for (var i = 0; i < message.reservedName.length; ++i)
                        if (!$util.isString(message.reservedName[i]))
                            return "reservedName: string[] expected";
                }
                return null;
            };

            /**
             * Creates a DescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.DescriptorProto} DescriptorProto
             */
            DescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.DescriptorProto)
                    return object;
                var message = new $root.google.protobuf.DescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.field) {
                    if (!Array.isArray(object.field))
                        throw TypeError(".google.protobuf.DescriptorProto.field: array expected");
                    message.field = [];
                    for (var i = 0; i < object.field.length; ++i) {
                        if (typeof object.field[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.field: object expected");
                        message.field[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.field[i]);
                    }
                }
                if (object.extension) {
                    if (!Array.isArray(object.extension))
                        throw TypeError(".google.protobuf.DescriptorProto.extension: array expected");
                    message.extension = [];
                    for (var i = 0; i < object.extension.length; ++i) {
                        if (typeof object.extension[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extension: object expected");
                        message.extension[i] = $root.google.protobuf.FieldDescriptorProto.fromObject(object.extension[i]);
                    }
                }
                if (object.nestedType) {
                    if (!Array.isArray(object.nestedType))
                        throw TypeError(".google.protobuf.DescriptorProto.nestedType: array expected");
                    message.nestedType = [];
                    for (var i = 0; i < object.nestedType.length; ++i) {
                        if (typeof object.nestedType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.nestedType: object expected");
                        message.nestedType[i] = $root.google.protobuf.DescriptorProto.fromObject(object.nestedType[i]);
                    }
                }
                if (object.enumType) {
                    if (!Array.isArray(object.enumType))
                        throw TypeError(".google.protobuf.DescriptorProto.enumType: array expected");
                    message.enumType = [];
                    for (var i = 0; i < object.enumType.length; ++i) {
                        if (typeof object.enumType[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.enumType: object expected");
                        message.enumType[i] = $root.google.protobuf.EnumDescriptorProto.fromObject(object.enumType[i]);
                    }
                }
                if (object.extensionRange) {
                    if (!Array.isArray(object.extensionRange))
                        throw TypeError(".google.protobuf.DescriptorProto.extensionRange: array expected");
                    message.extensionRange = [];
                    for (var i = 0; i < object.extensionRange.length; ++i) {
                        if (typeof object.extensionRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.extensionRange: object expected");
                        message.extensionRange[i] = $root.google.protobuf.DescriptorProto.ExtensionRange.fromObject(object.extensionRange[i]);
                    }
                }
                if (object.oneofDecl) {
                    if (!Array.isArray(object.oneofDecl))
                        throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: array expected");
                    message.oneofDecl = [];
                    for (var i = 0; i < object.oneofDecl.length; ++i) {
                        if (typeof object.oneofDecl[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.oneofDecl: object expected");
                        message.oneofDecl[i] = $root.google.protobuf.OneofDescriptorProto.fromObject(object.oneofDecl[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.DescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MessageOptions.fromObject(object.options);
                }
                if (object.reservedRange) {
                    if (!Array.isArray(object.reservedRange))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedRange: array expected");
                    message.reservedRange = [];
                    for (var i = 0; i < object.reservedRange.length; ++i) {
                        if (typeof object.reservedRange[i] !== "object")
                            throw TypeError(".google.protobuf.DescriptorProto.reservedRange: object expected");
                        message.reservedRange[i] = $root.google.protobuf.DescriptorProto.ReservedRange.fromObject(object.reservedRange[i]);
                    }
                }
                if (object.reservedName) {
                    if (!Array.isArray(object.reservedName))
                        throw TypeError(".google.protobuf.DescriptorProto.reservedName: array expected");
                    message.reservedName = [];
                    for (var i = 0; i < object.reservedName.length; ++i)
                        message.reservedName[i] = String(object.reservedName[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a DescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.DescriptorProto
             * @static
             * @param {google.protobuf.DescriptorProto} message DescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.field = [];
                    object.nestedType = [];
                    object.enumType = [];
                    object.extensionRange = [];
                    object.extension = [];
                    object.oneofDecl = [];
                    object.reservedRange = [];
                    object.reservedName = [];
                }
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.field && message.field.length) {
                    object.field = [];
                    for (var j = 0; j < message.field.length; ++j)
                        object.field[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.field[j], options);
                }
                if (message.nestedType && message.nestedType.length) {
                    object.nestedType = [];
                    for (var j = 0; j < message.nestedType.length; ++j)
                        object.nestedType[j] = $root.google.protobuf.DescriptorProto.toObject(message.nestedType[j], options);
                }
                if (message.enumType && message.enumType.length) {
                    object.enumType = [];
                    for (var j = 0; j < message.enumType.length; ++j)
                        object.enumType[j] = $root.google.protobuf.EnumDescriptorProto.toObject(message.enumType[j], options);
                }
                if (message.extensionRange && message.extensionRange.length) {
                    object.extensionRange = [];
                    for (var j = 0; j < message.extensionRange.length; ++j)
                        object.extensionRange[j] = $root.google.protobuf.DescriptorProto.ExtensionRange.toObject(message.extensionRange[j], options);
                }
                if (message.extension && message.extension.length) {
                    object.extension = [];
                    for (var j = 0; j < message.extension.length; ++j)
                        object.extension[j] = $root.google.protobuf.FieldDescriptorProto.toObject(message.extension[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MessageOptions.toObject(message.options, options);
                if (message.oneofDecl && message.oneofDecl.length) {
                    object.oneofDecl = [];
                    for (var j = 0; j < message.oneofDecl.length; ++j)
                        object.oneofDecl[j] = $root.google.protobuf.OneofDescriptorProto.toObject(message.oneofDecl[j], options);
                }
                if (message.reservedRange && message.reservedRange.length) {
                    object.reservedRange = [];
                    for (var j = 0; j < message.reservedRange.length; ++j)
                        object.reservedRange[j] = $root.google.protobuf.DescriptorProto.ReservedRange.toObject(message.reservedRange[j], options);
                }
                if (message.reservedName && message.reservedName.length) {
                    object.reservedName = [];
                    for (var j = 0; j < message.reservedName.length; ++j)
                        object.reservedName[j] = message.reservedName[j];
                }
                return object;
            };

            /**
             * Converts this DescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.DescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            DescriptorProto.ExtensionRange = (function() {

                /**
                 * Properties of an ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IExtensionRange
                 * @property {number|null} [start] ExtensionRange start
                 * @property {number|null} [end] ExtensionRange end
                 */

                /**
                 * Constructs a new ExtensionRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents an ExtensionRange.
                 * @implements IExtensionRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 */
                function ExtensionRange(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ExtensionRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.start = 0;

                /**
                 * ExtensionRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 */
                ExtensionRange.prototype.end = 0;

                /**
                 * Creates a new ExtensionRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange instance
                 */
                ExtensionRange.create = function create(properties) {
                    return new ExtensionRange(properties);
                };

                /**
                 * Encodes the specified ExtensionRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ExtensionRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IExtensionRange} message ExtensionRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ExtensionRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Decodes an ExtensionRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ExtensionRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.start = reader.int32();
                            break;
                        case 2:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an ExtensionRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ExtensionRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an ExtensionRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ExtensionRange} ExtensionRange
                 */
                ExtensionRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ExtensionRange)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto.ExtensionRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an ExtensionRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ExtensionRange} message ExtensionRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ExtensionRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ExtensionRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ExtensionRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ExtensionRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ExtensionRange;
            })();

            DescriptorProto.ReservedRange = (function() {

                /**
                 * Properties of a ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @interface IReservedRange
                 * @property {number|null} [start] ReservedRange start
                 * @property {number|null} [end] ReservedRange end
                 */

                /**
                 * Constructs a new ReservedRange.
                 * @memberof google.protobuf.DescriptorProto
                 * @classdesc Represents a ReservedRange.
                 * @implements IReservedRange
                 * @constructor
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 */
                function ReservedRange(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ReservedRange start.
                 * @member {number} start
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.start = 0;

                /**
                 * ReservedRange end.
                 * @member {number} end
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 */
                ReservedRange.prototype.end = 0;

                /**
                 * Creates a new ReservedRange instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange=} [properties] Properties to set
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange instance
                 */
                ReservedRange.create = function create(properties) {
                    return new ReservedRange(properties);
                };

                /**
                 * Encodes the specified ReservedRange message. Does not implicitly {@link google.protobuf.DescriptorProto.ReservedRange.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.IReservedRange} message ReservedRange message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ReservedRange.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.start != null && Object.hasOwnProperty.call(message, "start"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.start);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.end);
                    return writer;
                };

                /**
                 * Decodes a ReservedRange message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ReservedRange.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.start = reader.int32();
                            break;
                        case 2:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a ReservedRange message.
                 * @function verify
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ReservedRange.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.start != null && message.hasOwnProperty("start"))
                        if (!$util.isInteger(message.start))
                            return "start: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates a ReservedRange message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.DescriptorProto.ReservedRange} ReservedRange
                 */
                ReservedRange.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.DescriptorProto.ReservedRange)
                        return object;
                    var message = new $root.google.protobuf.DescriptorProto.ReservedRange();
                    if (object.start != null)
                        message.start = object.start | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a ReservedRange message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @static
                 * @param {google.protobuf.DescriptorProto.ReservedRange} message ReservedRange
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ReservedRange.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.start = 0;
                        object.end = 0;
                    }
                    if (message.start != null && message.hasOwnProperty("start"))
                        object.start = message.start;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this ReservedRange to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.DescriptorProto.ReservedRange
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ReservedRange.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ReservedRange;
            })();

            return DescriptorProto;
        })();

        protobuf.FieldDescriptorProto = (function() {

            /**
             * Properties of a FieldDescriptorProto.
             * @memberof google.protobuf
             * @interface IFieldDescriptorProto
             * @property {string|null} [name] FieldDescriptorProto name
             * @property {number|null} [number] FieldDescriptorProto number
             * @property {google.protobuf.FieldDescriptorProto.Label|null} [label] FieldDescriptorProto label
             * @property {google.protobuf.FieldDescriptorProto.Type|null} [type] FieldDescriptorProto type
             * @property {string|null} [typeName] FieldDescriptorProto typeName
             * @property {string|null} [extendee] FieldDescriptorProto extendee
             * @property {string|null} [defaultValue] FieldDescriptorProto defaultValue
             * @property {number|null} [oneofIndex] FieldDescriptorProto oneofIndex
             * @property {string|null} [jsonName] FieldDescriptorProto jsonName
             * @property {google.protobuf.IFieldOptions|null} [options] FieldDescriptorProto options
             */

            /**
             * Constructs a new FieldDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a FieldDescriptorProto.
             * @implements IFieldDescriptorProto
             * @constructor
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             */
            function FieldDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.name = "";

            /**
             * FieldDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.number = 0;

            /**
             * FieldDescriptorProto label.
             * @member {google.protobuf.FieldDescriptorProto.Label} label
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.label = 1;

            /**
             * FieldDescriptorProto type.
             * @member {google.protobuf.FieldDescriptorProto.Type} type
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.type = 1;

            /**
             * FieldDescriptorProto typeName.
             * @member {string} typeName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.typeName = "";

            /**
             * FieldDescriptorProto extendee.
             * @member {string} extendee
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.extendee = "";

            /**
             * FieldDescriptorProto defaultValue.
             * @member {string} defaultValue
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.defaultValue = "";

            /**
             * FieldDescriptorProto oneofIndex.
             * @member {number} oneofIndex
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.oneofIndex = 0;

            /**
             * FieldDescriptorProto jsonName.
             * @member {string} jsonName
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.jsonName = "";

            /**
             * FieldDescriptorProto options.
             * @member {google.protobuf.IFieldOptions|null|undefined} options
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             */
            FieldDescriptorProto.prototype.options = null;

            /**
             * Creates a new FieldDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto instance
             */
            FieldDescriptorProto.create = function create(properties) {
                return new FieldDescriptorProto(properties);
            };

            /**
             * Encodes the specified FieldDescriptorProto message. Does not implicitly {@link google.protobuf.FieldDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.IFieldDescriptorProto} message FieldDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.extendee != null && Object.hasOwnProperty.call(message, "extendee"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.extendee);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.number);
                if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.label);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.type);
                if (message.typeName != null && Object.hasOwnProperty.call(message, "typeName"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.typeName);
                if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.defaultValue);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.FieldOptions.encode(message.options, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.oneofIndex != null && Object.hasOwnProperty.call(message, "oneofIndex"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.oneofIndex);
                if (message.jsonName != null && Object.hasOwnProperty.call(message, "jsonName"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.jsonName);
                return writer;
            };

            /**
             * Decodes a FieldDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.number = reader.int32();
                        break;
                    case 4:
                        message.label = reader.int32();
                        break;
                    case 5:
                        message.type = reader.int32();
                        break;
                    case 6:
                        message.typeName = reader.string();
                        break;
                    case 2:
                        message.extendee = reader.string();
                        break;
                    case 7:
                        message.defaultValue = reader.string();
                        break;
                    case 9:
                        message.oneofIndex = reader.int32();
                        break;
                    case 10:
                        message.jsonName = reader.string();
                        break;
                    case 8:
                        message.options = $root.google.protobuf.FieldOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FieldDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    switch (message.label) {
                    default:
                        return "label: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                        break;
                    }
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    if (!$util.isString(message.typeName))
                        return "typeName: string expected";
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    if (!$util.isString(message.extendee))
                        return "extendee: string expected";
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    if (!$util.isString(message.defaultValue))
                        return "defaultValue: string expected";
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    if (!$util.isInteger(message.oneofIndex))
                        return "oneofIndex: integer expected";
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    if (!$util.isString(message.jsonName))
                        return "jsonName: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.FieldOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a FieldDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldDescriptorProto} FieldDescriptorProto
             */
            FieldDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.FieldDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                switch (object.label) {
                case "LABEL_OPTIONAL":
                case 1:
                    message.label = 1;
                    break;
                case "LABEL_REQUIRED":
                case 2:
                    message.label = 2;
                    break;
                case "LABEL_REPEATED":
                case 3:
                    message.label = 3;
                    break;
                }
                switch (object.type) {
                case "TYPE_DOUBLE":
                case 1:
                    message.type = 1;
                    break;
                case "TYPE_FLOAT":
                case 2:
                    message.type = 2;
                    break;
                case "TYPE_INT64":
                case 3:
                    message.type = 3;
                    break;
                case "TYPE_UINT64":
                case 4:
                    message.type = 4;
                    break;
                case "TYPE_INT32":
                case 5:
                    message.type = 5;
                    break;
                case "TYPE_FIXED64":
                case 6:
                    message.type = 6;
                    break;
                case "TYPE_FIXED32":
                case 7:
                    message.type = 7;
                    break;
                case "TYPE_BOOL":
                case 8:
                    message.type = 8;
                    break;
                case "TYPE_STRING":
                case 9:
                    message.type = 9;
                    break;
                case "TYPE_GROUP":
                case 10:
                    message.type = 10;
                    break;
                case "TYPE_MESSAGE":
                case 11:
                    message.type = 11;
                    break;
                case "TYPE_BYTES":
                case 12:
                    message.type = 12;
                    break;
                case "TYPE_UINT32":
                case 13:
                    message.type = 13;
                    break;
                case "TYPE_ENUM":
                case 14:
                    message.type = 14;
                    break;
                case "TYPE_SFIXED32":
                case 15:
                    message.type = 15;
                    break;
                case "TYPE_SFIXED64":
                case 16:
                    message.type = 16;
                    break;
                case "TYPE_SINT32":
                case 17:
                    message.type = 17;
                    break;
                case "TYPE_SINT64":
                case 18:
                    message.type = 18;
                    break;
                }
                if (object.typeName != null)
                    message.typeName = String(object.typeName);
                if (object.extendee != null)
                    message.extendee = String(object.extendee);
                if (object.defaultValue != null)
                    message.defaultValue = String(object.defaultValue);
                if (object.oneofIndex != null)
                    message.oneofIndex = object.oneofIndex | 0;
                if (object.jsonName != null)
                    message.jsonName = String(object.jsonName);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.FieldDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.FieldOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldDescriptorProto
             * @static
             * @param {google.protobuf.FieldDescriptorProto} message FieldDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.extendee = "";
                    object.number = 0;
                    object.label = options.enums === String ? "LABEL_OPTIONAL" : 1;
                    object.type = options.enums === String ? "TYPE_DOUBLE" : 1;
                    object.typeName = "";
                    object.defaultValue = "";
                    object.options = null;
                    object.oneofIndex = 0;
                    object.jsonName = "";
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.extendee != null && message.hasOwnProperty("extendee"))
                    object.extendee = message.extendee;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Label[message.label] : message.label;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.google.protobuf.FieldDescriptorProto.Type[message.type] : message.type;
                if (message.typeName != null && message.hasOwnProperty("typeName"))
                    object.typeName = message.typeName;
                if (message.defaultValue != null && message.hasOwnProperty("defaultValue"))
                    object.defaultValue = message.defaultValue;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.FieldOptions.toObject(message.options, options);
                if (message.oneofIndex != null && message.hasOwnProperty("oneofIndex"))
                    object.oneofIndex = message.oneofIndex;
                if (message.jsonName != null && message.hasOwnProperty("jsonName"))
                    object.jsonName = message.jsonName;
                return object;
            };

            /**
             * Converts this FieldDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Type enum.
             * @name google.protobuf.FieldDescriptorProto.Type
             * @enum {number}
             * @property {number} TYPE_DOUBLE=1 TYPE_DOUBLE value
             * @property {number} TYPE_FLOAT=2 TYPE_FLOAT value
             * @property {number} TYPE_INT64=3 TYPE_INT64 value
             * @property {number} TYPE_UINT64=4 TYPE_UINT64 value
             * @property {number} TYPE_INT32=5 TYPE_INT32 value
             * @property {number} TYPE_FIXED64=6 TYPE_FIXED64 value
             * @property {number} TYPE_FIXED32=7 TYPE_FIXED32 value
             * @property {number} TYPE_BOOL=8 TYPE_BOOL value
             * @property {number} TYPE_STRING=9 TYPE_STRING value
             * @property {number} TYPE_GROUP=10 TYPE_GROUP value
             * @property {number} TYPE_MESSAGE=11 TYPE_MESSAGE value
             * @property {number} TYPE_BYTES=12 TYPE_BYTES value
             * @property {number} TYPE_UINT32=13 TYPE_UINT32 value
             * @property {number} TYPE_ENUM=14 TYPE_ENUM value
             * @property {number} TYPE_SFIXED32=15 TYPE_SFIXED32 value
             * @property {number} TYPE_SFIXED64=16 TYPE_SFIXED64 value
             * @property {number} TYPE_SINT32=17 TYPE_SINT32 value
             * @property {number} TYPE_SINT64=18 TYPE_SINT64 value
             */
            FieldDescriptorProto.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "TYPE_DOUBLE"] = 1;
                values[valuesById[2] = "TYPE_FLOAT"] = 2;
                values[valuesById[3] = "TYPE_INT64"] = 3;
                values[valuesById[4] = "TYPE_UINT64"] = 4;
                values[valuesById[5] = "TYPE_INT32"] = 5;
                values[valuesById[6] = "TYPE_FIXED64"] = 6;
                values[valuesById[7] = "TYPE_FIXED32"] = 7;
                values[valuesById[8] = "TYPE_BOOL"] = 8;
                values[valuesById[9] = "TYPE_STRING"] = 9;
                values[valuesById[10] = "TYPE_GROUP"] = 10;
                values[valuesById[11] = "TYPE_MESSAGE"] = 11;
                values[valuesById[12] = "TYPE_BYTES"] = 12;
                values[valuesById[13] = "TYPE_UINT32"] = 13;
                values[valuesById[14] = "TYPE_ENUM"] = 14;
                values[valuesById[15] = "TYPE_SFIXED32"] = 15;
                values[valuesById[16] = "TYPE_SFIXED64"] = 16;
                values[valuesById[17] = "TYPE_SINT32"] = 17;
                values[valuesById[18] = "TYPE_SINT64"] = 18;
                return values;
            })();

            /**
             * Label enum.
             * @name google.protobuf.FieldDescriptorProto.Label
             * @enum {number}
             * @property {number} LABEL_OPTIONAL=1 LABEL_OPTIONAL value
             * @property {number} LABEL_REQUIRED=2 LABEL_REQUIRED value
             * @property {number} LABEL_REPEATED=3 LABEL_REPEATED value
             */
            FieldDescriptorProto.Label = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "LABEL_OPTIONAL"] = 1;
                values[valuesById[2] = "LABEL_REQUIRED"] = 2;
                values[valuesById[3] = "LABEL_REPEATED"] = 3;
                return values;
            })();

            return FieldDescriptorProto;
        })();

        protobuf.OneofDescriptorProto = (function() {

            /**
             * Properties of an OneofDescriptorProto.
             * @memberof google.protobuf
             * @interface IOneofDescriptorProto
             * @property {string|null} [name] OneofDescriptorProto name
             * @property {google.protobuf.IOneofOptions|null} [options] OneofDescriptorProto options
             */

            /**
             * Constructs a new OneofDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an OneofDescriptorProto.
             * @implements IOneofDescriptorProto
             * @constructor
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             */
            function OneofDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.name = "";

            /**
             * OneofDescriptorProto options.
             * @member {google.protobuf.IOneofOptions|null|undefined} options
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             */
            OneofDescriptorProto.prototype.options = null;

            /**
             * Creates a new OneofDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto instance
             */
            OneofDescriptorProto.create = function create(properties) {
                return new OneofDescriptorProto(properties);
            };

            /**
             * Encodes the specified OneofDescriptorProto message. Does not implicitly {@link google.protobuf.OneofDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.IOneofDescriptorProto} message OneofDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.OneofOptions.encode(message.options, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OneofDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.options = $root.google.protobuf.OneofOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OneofDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.OneofOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an OneofDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofDescriptorProto} OneofDescriptorProto
             */
            OneofDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.OneofDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.OneofDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.OneofOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofDescriptorProto
             * @static
             * @param {google.protobuf.OneofDescriptorProto} message OneofDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.OneofOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this OneofDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofDescriptorProto;
        })();

        protobuf.EnumDescriptorProto = (function() {

            /**
             * Properties of an EnumDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumDescriptorProto
             * @property {string|null} [name] EnumDescriptorProto name
             * @property {Array.<google.protobuf.IEnumValueDescriptorProto>|null} [value] EnumDescriptorProto value
             * @property {google.protobuf.IEnumOptions|null} [options] EnumDescriptorProto options
             */

            /**
             * Constructs a new EnumDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumDescriptorProto.
             * @implements IEnumDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             */
            function EnumDescriptorProto(properties) {
                this.value = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.name = "";

            /**
             * EnumDescriptorProto value.
             * @member {Array.<google.protobuf.IEnumValueDescriptorProto>} value
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.value = $util.emptyArray;

            /**
             * EnumDescriptorProto options.
             * @member {google.protobuf.IEnumOptions|null|undefined} options
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             */
            EnumDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto instance
             */
            EnumDescriptorProto.create = function create(properties) {
                return new EnumDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumDescriptorProto message. Does not implicitly {@link google.protobuf.EnumDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.IEnumDescriptorProto} message EnumDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.value != null && message.value.length)
                    for (var i = 0; i < message.value.length; ++i)
                        $root.google.protobuf.EnumValueDescriptorProto.encode(message.value[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.value && message.value.length))
                            message.value = [];
                        message.value.push($root.google.protobuf.EnumValueDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.options = $root.google.protobuf.EnumOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    if (!Array.isArray(message.value))
                        return "value: array expected";
                    for (var i = 0; i < message.value.length; ++i) {
                        var error = $root.google.protobuf.EnumValueDescriptorProto.verify(message.value[i]);
                        if (error)
                            return "value." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.EnumOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumDescriptorProto} EnumDescriptorProto
             */
            EnumDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.EnumDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.value) {
                    if (!Array.isArray(object.value))
                        throw TypeError(".google.protobuf.EnumDescriptorProto.value: array expected");
                    message.value = [];
                    for (var i = 0; i < object.value.length; ++i) {
                        if (typeof object.value[i] !== "object")
                            throw TypeError(".google.protobuf.EnumDescriptorProto.value: object expected");
                        message.value[i] = $root.google.protobuf.EnumValueDescriptorProto.fromObject(object.value[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumDescriptorProto
             * @static
             * @param {google.protobuf.EnumDescriptorProto} message EnumDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.value = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.value && message.value.length) {
                    object.value = [];
                    for (var j = 0; j < message.value.length; ++j)
                        object.value[j] = $root.google.protobuf.EnumValueDescriptorProto.toObject(message.value[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumDescriptorProto;
        })();

        protobuf.EnumValueDescriptorProto = (function() {

            /**
             * Properties of an EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @interface IEnumValueDescriptorProto
             * @property {string|null} [name] EnumValueDescriptorProto name
             * @property {number|null} [number] EnumValueDescriptorProto number
             * @property {google.protobuf.IEnumValueOptions|null} [options] EnumValueDescriptorProto options
             */

            /**
             * Constructs a new EnumValueDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueDescriptorProto.
             * @implements IEnumValueDescriptorProto
             * @constructor
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             */
            function EnumValueDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.name = "";

            /**
             * EnumValueDescriptorProto number.
             * @member {number} number
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.number = 0;

            /**
             * EnumValueDescriptorProto options.
             * @member {google.protobuf.IEnumValueOptions|null|undefined} options
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             */
            EnumValueDescriptorProto.prototype.options = null;

            /**
             * Creates a new EnumValueDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto instance
             */
            EnumValueDescriptorProto.create = function create(properties) {
                return new EnumValueDescriptorProto(properties);
            };

            /**
             * Encodes the specified EnumValueDescriptorProto message. Does not implicitly {@link google.protobuf.EnumValueDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.IEnumValueDescriptorProto} message EnumValueDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.number);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.EnumValueOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumValueDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.number = reader.int32();
                        break;
                    case 3:
                        message.options = $root.google.protobuf.EnumValueOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumValueDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number))
                        return "number: integer expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.EnumValueOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates an EnumValueDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueDescriptorProto} EnumValueDescriptorProto
             */
            EnumValueDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.EnumValueDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.number != null)
                    message.number = object.number | 0;
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.EnumValueDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.EnumValueOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @static
             * @param {google.protobuf.EnumValueDescriptorProto} message EnumValueDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.number = 0;
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.number != null && message.hasOwnProperty("number"))
                    object.number = message.number;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.EnumValueOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this EnumValueDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueDescriptorProto;
        })();

        protobuf.ServiceDescriptorProto = (function() {

            /**
             * Properties of a ServiceDescriptorProto.
             * @memberof google.protobuf
             * @interface IServiceDescriptorProto
             * @property {string|null} [name] ServiceDescriptorProto name
             * @property {Array.<google.protobuf.IMethodDescriptorProto>|null} [method] ServiceDescriptorProto method
             * @property {google.protobuf.IServiceOptions|null} [options] ServiceDescriptorProto options
             */

            /**
             * Constructs a new ServiceDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceDescriptorProto.
             * @implements IServiceDescriptorProto
             * @constructor
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             */
            function ServiceDescriptorProto(properties) {
                this.method = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.name = "";

            /**
             * ServiceDescriptorProto method.
             * @member {Array.<google.protobuf.IMethodDescriptorProto>} method
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.method = $util.emptyArray;

            /**
             * ServiceDescriptorProto options.
             * @member {google.protobuf.IServiceOptions|null|undefined} options
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             */
            ServiceDescriptorProto.prototype.options = null;

            /**
             * Creates a new ServiceDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto instance
             */
            ServiceDescriptorProto.create = function create(properties) {
                return new ServiceDescriptorProto(properties);
            };

            /**
             * Encodes the specified ServiceDescriptorProto message. Does not implicitly {@link google.protobuf.ServiceDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.IServiceDescriptorProto} message ServiceDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.method != null && message.method.length)
                    for (var i = 0; i < message.method.length; ++i)
                        $root.google.protobuf.MethodDescriptorProto.encode(message.method[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.ServiceOptions.encode(message.options, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServiceDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        if (!(message.method && message.method.length))
                            message.method = [];
                        message.method.push($root.google.protobuf.MethodDescriptorProto.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.options = $root.google.protobuf.ServiceOptions.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServiceDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.method != null && message.hasOwnProperty("method")) {
                    if (!Array.isArray(message.method))
                        return "method: array expected";
                    for (var i = 0; i < message.method.length; ++i) {
                        var error = $root.google.protobuf.MethodDescriptorProto.verify(message.method[i]);
                        if (error)
                            return "method." + error;
                    }
                }
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.ServiceOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                return null;
            };

            /**
             * Creates a ServiceDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceDescriptorProto} ServiceDescriptorProto
             */
            ServiceDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.ServiceDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.method) {
                    if (!Array.isArray(object.method))
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.method: array expected");
                    message.method = [];
                    for (var i = 0; i < object.method.length; ++i) {
                        if (typeof object.method[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceDescriptorProto.method: object expected");
                        message.method[i] = $root.google.protobuf.MethodDescriptorProto.fromObject(object.method[i]);
                    }
                }
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.ServiceDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.ServiceOptions.fromObject(object.options);
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceDescriptorProto
             * @static
             * @param {google.protobuf.ServiceDescriptorProto} message ServiceDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.method = [];
                if (options.defaults) {
                    object.name = "";
                    object.options = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.method && message.method.length) {
                    object.method = [];
                    for (var j = 0; j < message.method.length; ++j)
                        object.method[j] = $root.google.protobuf.MethodDescriptorProto.toObject(message.method[j], options);
                }
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.ServiceOptions.toObject(message.options, options);
                return object;
            };

            /**
             * Converts this ServiceDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceDescriptorProto;
        })();

        protobuf.MethodDescriptorProto = (function() {

            /**
             * Properties of a MethodDescriptorProto.
             * @memberof google.protobuf
             * @interface IMethodDescriptorProto
             * @property {string|null} [name] MethodDescriptorProto name
             * @property {string|null} [inputType] MethodDescriptorProto inputType
             * @property {string|null} [outputType] MethodDescriptorProto outputType
             * @property {google.protobuf.IMethodOptions|null} [options] MethodDescriptorProto options
             * @property {boolean|null} [clientStreaming] MethodDescriptorProto clientStreaming
             * @property {boolean|null} [serverStreaming] MethodDescriptorProto serverStreaming
             */

            /**
             * Constructs a new MethodDescriptorProto.
             * @memberof google.protobuf
             * @classdesc Represents a MethodDescriptorProto.
             * @implements IMethodDescriptorProto
             * @constructor
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             */
            function MethodDescriptorProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodDescriptorProto name.
             * @member {string} name
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.name = "";

            /**
             * MethodDescriptorProto inputType.
             * @member {string} inputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.inputType = "";

            /**
             * MethodDescriptorProto outputType.
             * @member {string} outputType
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.outputType = "";

            /**
             * MethodDescriptorProto options.
             * @member {google.protobuf.IMethodOptions|null|undefined} options
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.options = null;

            /**
             * MethodDescriptorProto clientStreaming.
             * @member {boolean} clientStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.clientStreaming = false;

            /**
             * MethodDescriptorProto serverStreaming.
             * @member {boolean} serverStreaming
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             */
            MethodDescriptorProto.prototype.serverStreaming = false;

            /**
             * Creates a new MethodDescriptorProto instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto=} [properties] Properties to set
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto instance
             */
            MethodDescriptorProto.create = function create(properties) {
                return new MethodDescriptorProto(properties);
            };

            /**
             * Encodes the specified MethodDescriptorProto message. Does not implicitly {@link google.protobuf.MethodDescriptorProto.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.IMethodDescriptorProto} message MethodDescriptorProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodDescriptorProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.inputType != null && Object.hasOwnProperty.call(message, "inputType"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.inputType);
                if (message.outputType != null && Object.hasOwnProperty.call(message, "outputType"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.outputType);
                if (message.options != null && Object.hasOwnProperty.call(message, "options"))
                    $root.google.protobuf.MethodOptions.encode(message.options, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.clientStreaming != null && Object.hasOwnProperty.call(message, "clientStreaming"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.clientStreaming);
                if (message.serverStreaming != null && Object.hasOwnProperty.call(message, "serverStreaming"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.serverStreaming);
                return writer;
            };

            /**
             * Decodes a MethodDescriptorProto message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodDescriptorProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodDescriptorProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.inputType = reader.string();
                        break;
                    case 3:
                        message.outputType = reader.string();
                        break;
                    case 4:
                        message.options = $root.google.protobuf.MethodOptions.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.clientStreaming = reader.bool();
                        break;
                    case 6:
                        message.serverStreaming = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MethodDescriptorProto message.
             * @function verify
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodDescriptorProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    if (!$util.isString(message.inputType))
                        return "inputType: string expected";
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    if (!$util.isString(message.outputType))
                        return "outputType: string expected";
                if (message.options != null && message.hasOwnProperty("options")) {
                    var error = $root.google.protobuf.MethodOptions.verify(message.options);
                    if (error)
                        return "options." + error;
                }
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    if (typeof message.clientStreaming !== "boolean")
                        return "clientStreaming: boolean expected";
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    if (typeof message.serverStreaming !== "boolean")
                        return "serverStreaming: boolean expected";
                return null;
            };

            /**
             * Creates a MethodDescriptorProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodDescriptorProto} MethodDescriptorProto
             */
            MethodDescriptorProto.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodDescriptorProto)
                    return object;
                var message = new $root.google.protobuf.MethodDescriptorProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.inputType != null)
                    message.inputType = String(object.inputType);
                if (object.outputType != null)
                    message.outputType = String(object.outputType);
                if (object.options != null) {
                    if (typeof object.options !== "object")
                        throw TypeError(".google.protobuf.MethodDescriptorProto.options: object expected");
                    message.options = $root.google.protobuf.MethodOptions.fromObject(object.options);
                }
                if (object.clientStreaming != null)
                    message.clientStreaming = Boolean(object.clientStreaming);
                if (object.serverStreaming != null)
                    message.serverStreaming = Boolean(object.serverStreaming);
                return message;
            };

            /**
             * Creates a plain object from a MethodDescriptorProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodDescriptorProto
             * @static
             * @param {google.protobuf.MethodDescriptorProto} message MethodDescriptorProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodDescriptorProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.inputType = "";
                    object.outputType = "";
                    object.options = null;
                    object.clientStreaming = false;
                    object.serverStreaming = false;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.inputType != null && message.hasOwnProperty("inputType"))
                    object.inputType = message.inputType;
                if (message.outputType != null && message.hasOwnProperty("outputType"))
                    object.outputType = message.outputType;
                if (message.options != null && message.hasOwnProperty("options"))
                    object.options = $root.google.protobuf.MethodOptions.toObject(message.options, options);
                if (message.clientStreaming != null && message.hasOwnProperty("clientStreaming"))
                    object.clientStreaming = message.clientStreaming;
                if (message.serverStreaming != null && message.hasOwnProperty("serverStreaming"))
                    object.serverStreaming = message.serverStreaming;
                return object;
            };

            /**
             * Converts this MethodDescriptorProto to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodDescriptorProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodDescriptorProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodDescriptorProto;
        })();

        protobuf.FileOptions = (function() {

            /**
             * Properties of a FileOptions.
             * @memberof google.protobuf
             * @interface IFileOptions
             * @property {string|null} [javaPackage] FileOptions javaPackage
             * @property {string|null} [javaOuterClassname] FileOptions javaOuterClassname
             * @property {boolean|null} [javaMultipleFiles] FileOptions javaMultipleFiles
             * @property {boolean|null} [javaGenerateEqualsAndHash] FileOptions javaGenerateEqualsAndHash
             * @property {boolean|null} [javaStringCheckUtf8] FileOptions javaStringCheckUtf8
             * @property {google.protobuf.FileOptions.OptimizeMode|null} [optimizeFor] FileOptions optimizeFor
             * @property {string|null} [goPackage] FileOptions goPackage
             * @property {boolean|null} [ccGenericServices] FileOptions ccGenericServices
             * @property {boolean|null} [javaGenericServices] FileOptions javaGenericServices
             * @property {boolean|null} [pyGenericServices] FileOptions pyGenericServices
             * @property {boolean|null} [deprecated] FileOptions deprecated
             * @property {boolean|null} [ccEnableArenas] FileOptions ccEnableArenas
             * @property {string|null} [objcClassPrefix] FileOptions objcClassPrefix
             * @property {string|null} [csharpNamespace] FileOptions csharpNamespace
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FileOptions uninterpretedOption
             */

            /**
             * Constructs a new FileOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FileOptions.
             * @implements IFileOptions
             * @constructor
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             */
            function FileOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FileOptions javaPackage.
             * @member {string} javaPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaPackage = "";

            /**
             * FileOptions javaOuterClassname.
             * @member {string} javaOuterClassname
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaOuterClassname = "";

            /**
             * FileOptions javaMultipleFiles.
             * @member {boolean} javaMultipleFiles
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaMultipleFiles = false;

            /**
             * FileOptions javaGenerateEqualsAndHash.
             * @member {boolean} javaGenerateEqualsAndHash
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenerateEqualsAndHash = false;

            /**
             * FileOptions javaStringCheckUtf8.
             * @member {boolean} javaStringCheckUtf8
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaStringCheckUtf8 = false;

            /**
             * FileOptions optimizeFor.
             * @member {google.protobuf.FileOptions.OptimizeMode} optimizeFor
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.optimizeFor = 1;

            /**
             * FileOptions goPackage.
             * @member {string} goPackage
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.goPackage = "";

            /**
             * FileOptions ccGenericServices.
             * @member {boolean} ccGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccGenericServices = false;

            /**
             * FileOptions javaGenericServices.
             * @member {boolean} javaGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.javaGenericServices = false;

            /**
             * FileOptions pyGenericServices.
             * @member {boolean} pyGenericServices
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.pyGenericServices = false;

            /**
             * FileOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.deprecated = false;

            /**
             * FileOptions ccEnableArenas.
             * @member {boolean} ccEnableArenas
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.ccEnableArenas = false;

            /**
             * FileOptions objcClassPrefix.
             * @member {string} objcClassPrefix
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.objcClassPrefix = "";

            /**
             * FileOptions csharpNamespace.
             * @member {string} csharpNamespace
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.csharpNamespace = "";

            /**
             * FileOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FileOptions
             * @instance
             */
            FileOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new FileOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions=} [properties] Properties to set
             * @returns {google.protobuf.FileOptions} FileOptions instance
             */
            FileOptions.create = function create(properties) {
                return new FileOptions(properties);
            };

            /**
             * Encodes the specified FileOptions message. Does not implicitly {@link google.protobuf.FileOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.IFileOptions} message FileOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FileOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.javaPackage != null && Object.hasOwnProperty.call(message, "javaPackage"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.javaPackage);
                if (message.javaOuterClassname != null && Object.hasOwnProperty.call(message, "javaOuterClassname"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.javaOuterClassname);
                if (message.optimizeFor != null && Object.hasOwnProperty.call(message, "optimizeFor"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.optimizeFor);
                if (message.javaMultipleFiles != null && Object.hasOwnProperty.call(message, "javaMultipleFiles"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.javaMultipleFiles);
                if (message.goPackage != null && Object.hasOwnProperty.call(message, "goPackage"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.goPackage);
                if (message.ccGenericServices != null && Object.hasOwnProperty.call(message, "ccGenericServices"))
                    writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ccGenericServices);
                if (message.javaGenericServices != null && Object.hasOwnProperty.call(message, "javaGenericServices"))
                    writer.uint32(/* id 17, wireType 0 =*/136).bool(message.javaGenericServices);
                if (message.pyGenericServices != null && Object.hasOwnProperty.call(message, "pyGenericServices"))
                    writer.uint32(/* id 18, wireType 0 =*/144).bool(message.pyGenericServices);
                if (message.javaGenerateEqualsAndHash != null && Object.hasOwnProperty.call(message, "javaGenerateEqualsAndHash"))
                    writer.uint32(/* id 20, wireType 0 =*/160).bool(message.javaGenerateEqualsAndHash);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 23, wireType 0 =*/184).bool(message.deprecated);
                if (message.javaStringCheckUtf8 != null && Object.hasOwnProperty.call(message, "javaStringCheckUtf8"))
                    writer.uint32(/* id 27, wireType 0 =*/216).bool(message.javaStringCheckUtf8);
                if (message.ccEnableArenas != null && Object.hasOwnProperty.call(message, "ccEnableArenas"))
                    writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ccEnableArenas);
                if (message.objcClassPrefix != null && Object.hasOwnProperty.call(message, "objcClassPrefix"))
                    writer.uint32(/* id 36, wireType 2 =*/290).string(message.objcClassPrefix);
                if (message.csharpNamespace != null && Object.hasOwnProperty.call(message, "csharpNamespace"))
                    writer.uint32(/* id 37, wireType 2 =*/298).string(message.csharpNamespace);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a FileOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FileOptions} FileOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FileOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FileOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.javaPackage = reader.string();
                        break;
                    case 8:
                        message.javaOuterClassname = reader.string();
                        break;
                    case 10:
                        message.javaMultipleFiles = reader.bool();
                        break;
                    case 20:
                        message.javaGenerateEqualsAndHash = reader.bool();
                        break;
                    case 27:
                        message.javaStringCheckUtf8 = reader.bool();
                        break;
                    case 9:
                        message.optimizeFor = reader.int32();
                        break;
                    case 11:
                        message.goPackage = reader.string();
                        break;
                    case 16:
                        message.ccGenericServices = reader.bool();
                        break;
                    case 17:
                        message.javaGenericServices = reader.bool();
                        break;
                    case 18:
                        message.pyGenericServices = reader.bool();
                        break;
                    case 23:
                        message.deprecated = reader.bool();
                        break;
                    case 31:
                        message.ccEnableArenas = reader.bool();
                        break;
                    case 36:
                        message.objcClassPrefix = reader.string();
                        break;
                    case 37:
                        message.csharpNamespace = reader.string();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FileOptions message.
             * @function verify
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FileOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    if (!$util.isString(message.javaPackage))
                        return "javaPackage: string expected";
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    if (!$util.isString(message.javaOuterClassname))
                        return "javaOuterClassname: string expected";
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    if (typeof message.javaMultipleFiles !== "boolean")
                        return "javaMultipleFiles: boolean expected";
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    if (typeof message.javaGenerateEqualsAndHash !== "boolean")
                        return "javaGenerateEqualsAndHash: boolean expected";
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    if (typeof message.javaStringCheckUtf8 !== "boolean")
                        return "javaStringCheckUtf8: boolean expected";
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    switch (message.optimizeFor) {
                    default:
                        return "optimizeFor: enum value expected";
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    if (!$util.isString(message.goPackage))
                        return "goPackage: string expected";
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    if (typeof message.ccGenericServices !== "boolean")
                        return "ccGenericServices: boolean expected";
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    if (typeof message.javaGenericServices !== "boolean")
                        return "javaGenericServices: boolean expected";
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    if (typeof message.pyGenericServices !== "boolean")
                        return "pyGenericServices: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    if (typeof message.ccEnableArenas !== "boolean")
                        return "ccEnableArenas: boolean expected";
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    if (!$util.isString(message.objcClassPrefix))
                        return "objcClassPrefix: string expected";
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    if (!$util.isString(message.csharpNamespace))
                        return "csharpNamespace: string expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FileOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FileOptions} FileOptions
             */
            FileOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FileOptions)
                    return object;
                var message = new $root.google.protobuf.FileOptions();
                if (object.javaPackage != null)
                    message.javaPackage = String(object.javaPackage);
                if (object.javaOuterClassname != null)
                    message.javaOuterClassname = String(object.javaOuterClassname);
                if (object.javaMultipleFiles != null)
                    message.javaMultipleFiles = Boolean(object.javaMultipleFiles);
                if (object.javaGenerateEqualsAndHash != null)
                    message.javaGenerateEqualsAndHash = Boolean(object.javaGenerateEqualsAndHash);
                if (object.javaStringCheckUtf8 != null)
                    message.javaStringCheckUtf8 = Boolean(object.javaStringCheckUtf8);
                switch (object.optimizeFor) {
                case "SPEED":
                case 1:
                    message.optimizeFor = 1;
                    break;
                case "CODE_SIZE":
                case 2:
                    message.optimizeFor = 2;
                    break;
                case "LITE_RUNTIME":
                case 3:
                    message.optimizeFor = 3;
                    break;
                }
                if (object.goPackage != null)
                    message.goPackage = String(object.goPackage);
                if (object.ccGenericServices != null)
                    message.ccGenericServices = Boolean(object.ccGenericServices);
                if (object.javaGenericServices != null)
                    message.javaGenericServices = Boolean(object.javaGenericServices);
                if (object.pyGenericServices != null)
                    message.pyGenericServices = Boolean(object.pyGenericServices);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.ccEnableArenas != null)
                    message.ccEnableArenas = Boolean(object.ccEnableArenas);
                if (object.objcClassPrefix != null)
                    message.objcClassPrefix = String(object.objcClassPrefix);
                if (object.csharpNamespace != null)
                    message.csharpNamespace = String(object.csharpNamespace);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FileOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FileOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FileOptions
             * @static
             * @param {google.protobuf.FileOptions} message FileOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FileOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.javaPackage = "";
                    object.javaOuterClassname = "";
                    object.optimizeFor = options.enums === String ? "SPEED" : 1;
                    object.javaMultipleFiles = false;
                    object.goPackage = "";
                    object.ccGenericServices = false;
                    object.javaGenericServices = false;
                    object.pyGenericServices = false;
                    object.javaGenerateEqualsAndHash = false;
                    object.deprecated = false;
                    object.javaStringCheckUtf8 = false;
                    object.ccEnableArenas = false;
                    object.objcClassPrefix = "";
                    object.csharpNamespace = "";
                }
                if (message.javaPackage != null && message.hasOwnProperty("javaPackage"))
                    object.javaPackage = message.javaPackage;
                if (message.javaOuterClassname != null && message.hasOwnProperty("javaOuterClassname"))
                    object.javaOuterClassname = message.javaOuterClassname;
                if (message.optimizeFor != null && message.hasOwnProperty("optimizeFor"))
                    object.optimizeFor = options.enums === String ? $root.google.protobuf.FileOptions.OptimizeMode[message.optimizeFor] : message.optimizeFor;
                if (message.javaMultipleFiles != null && message.hasOwnProperty("javaMultipleFiles"))
                    object.javaMultipleFiles = message.javaMultipleFiles;
                if (message.goPackage != null && message.hasOwnProperty("goPackage"))
                    object.goPackage = message.goPackage;
                if (message.ccGenericServices != null && message.hasOwnProperty("ccGenericServices"))
                    object.ccGenericServices = message.ccGenericServices;
                if (message.javaGenericServices != null && message.hasOwnProperty("javaGenericServices"))
                    object.javaGenericServices = message.javaGenericServices;
                if (message.pyGenericServices != null && message.hasOwnProperty("pyGenericServices"))
                    object.pyGenericServices = message.pyGenericServices;
                if (message.javaGenerateEqualsAndHash != null && message.hasOwnProperty("javaGenerateEqualsAndHash"))
                    object.javaGenerateEqualsAndHash = message.javaGenerateEqualsAndHash;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.javaStringCheckUtf8 != null && message.hasOwnProperty("javaStringCheckUtf8"))
                    object.javaStringCheckUtf8 = message.javaStringCheckUtf8;
                if (message.ccEnableArenas != null && message.hasOwnProperty("ccEnableArenas"))
                    object.ccEnableArenas = message.ccEnableArenas;
                if (message.objcClassPrefix != null && message.hasOwnProperty("objcClassPrefix"))
                    object.objcClassPrefix = message.objcClassPrefix;
                if (message.csharpNamespace != null && message.hasOwnProperty("csharpNamespace"))
                    object.csharpNamespace = message.csharpNamespace;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this FileOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FileOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FileOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * OptimizeMode enum.
             * @name google.protobuf.FileOptions.OptimizeMode
             * @enum {number}
             * @property {number} SPEED=1 SPEED value
             * @property {number} CODE_SIZE=2 CODE_SIZE value
             * @property {number} LITE_RUNTIME=3 LITE_RUNTIME value
             */
            FileOptions.OptimizeMode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[1] = "SPEED"] = 1;
                values[valuesById[2] = "CODE_SIZE"] = 2;
                values[valuesById[3] = "LITE_RUNTIME"] = 3;
                return values;
            })();

            return FileOptions;
        })();

        protobuf.MessageOptions = (function() {

            /**
             * Properties of a MessageOptions.
             * @memberof google.protobuf
             * @interface IMessageOptions
             * @property {boolean|null} [messageSetWireFormat] MessageOptions messageSetWireFormat
             * @property {boolean|null} [noStandardDescriptorAccessor] MessageOptions noStandardDescriptorAccessor
             * @property {boolean|null} [deprecated] MessageOptions deprecated
             * @property {boolean|null} [mapEntry] MessageOptions mapEntry
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MessageOptions uninterpretedOption
             */

            /**
             * Constructs a new MessageOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MessageOptions.
             * @implements IMessageOptions
             * @constructor
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             */
            function MessageOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MessageOptions messageSetWireFormat.
             * @member {boolean} messageSetWireFormat
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.messageSetWireFormat = false;

            /**
             * MessageOptions noStandardDescriptorAccessor.
             * @member {boolean} noStandardDescriptorAccessor
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.noStandardDescriptorAccessor = false;

            /**
             * MessageOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.deprecated = false;

            /**
             * MessageOptions mapEntry.
             * @member {boolean} mapEntry
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.mapEntry = false;

            /**
             * MessageOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MessageOptions
             * @instance
             */
            MessageOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MessageOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions=} [properties] Properties to set
             * @returns {google.protobuf.MessageOptions} MessageOptions instance
             */
            MessageOptions.create = function create(properties) {
                return new MessageOptions(properties);
            };

            /**
             * Encodes the specified MessageOptions message. Does not implicitly {@link google.protobuf.MessageOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.IMessageOptions} message MessageOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MessageOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.messageSetWireFormat != null && Object.hasOwnProperty.call(message, "messageSetWireFormat"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.messageSetWireFormat);
                if (message.noStandardDescriptorAccessor != null && Object.hasOwnProperty.call(message, "noStandardDescriptorAccessor"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.noStandardDescriptorAccessor);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.mapEntry != null && Object.hasOwnProperty.call(message, "mapEntry"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.mapEntry);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a MessageOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MessageOptions} MessageOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MessageOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MessageOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.messageSetWireFormat = reader.bool();
                        break;
                    case 2:
                        message.noStandardDescriptorAccessor = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 7:
                        message.mapEntry = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MessageOptions message.
             * @function verify
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MessageOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    if (typeof message.messageSetWireFormat !== "boolean")
                        return "messageSetWireFormat: boolean expected";
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    if (typeof message.noStandardDescriptorAccessor !== "boolean")
                        return "noStandardDescriptorAccessor: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    if (typeof message.mapEntry !== "boolean")
                        return "mapEntry: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MessageOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MessageOptions} MessageOptions
             */
            MessageOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MessageOptions)
                    return object;
                var message = new $root.google.protobuf.MessageOptions();
                if (object.messageSetWireFormat != null)
                    message.messageSetWireFormat = Boolean(object.messageSetWireFormat);
                if (object.noStandardDescriptorAccessor != null)
                    message.noStandardDescriptorAccessor = Boolean(object.noStandardDescriptorAccessor);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.mapEntry != null)
                    message.mapEntry = Boolean(object.mapEntry);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MessageOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MessageOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MessageOptions
             * @static
             * @param {google.protobuf.MessageOptions} message MessageOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MessageOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.messageSetWireFormat = false;
                    object.noStandardDescriptorAccessor = false;
                    object.deprecated = false;
                    object.mapEntry = false;
                }
                if (message.messageSetWireFormat != null && message.hasOwnProperty("messageSetWireFormat"))
                    object.messageSetWireFormat = message.messageSetWireFormat;
                if (message.noStandardDescriptorAccessor != null && message.hasOwnProperty("noStandardDescriptorAccessor"))
                    object.noStandardDescriptorAccessor = message.noStandardDescriptorAccessor;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.mapEntry != null && message.hasOwnProperty("mapEntry"))
                    object.mapEntry = message.mapEntry;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this MessageOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MessageOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MessageOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MessageOptions;
        })();

        protobuf.FieldOptions = (function() {

            /**
             * Properties of a FieldOptions.
             * @memberof google.protobuf
             * @interface IFieldOptions
             * @property {google.protobuf.FieldOptions.CType|null} [ctype] FieldOptions ctype
             * @property {boolean|null} [packed] FieldOptions packed
             * @property {google.protobuf.FieldOptions.JSType|null} [jstype] FieldOptions jstype
             * @property {boolean|null} [lazy] FieldOptions lazy
             * @property {boolean|null} [deprecated] FieldOptions deprecated
             * @property {boolean|null} [weak] FieldOptions weak
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] FieldOptions uninterpretedOption
             * @property {string|null} [".feedbox.market_api.gmrpcResponse"] FieldOptions .feedbox.market_api.gmrpcResponse
             * @property {boolean|null} [".feedbox.market_api.gmrpcIsBroadcast"] FieldOptions .feedbox.market_api.gmrpcIsBroadcast
             * @property {string|null} [".feedbox.technic_api.gmrpcResponse"] FieldOptions .feedbox.technic_api.gmrpcResponse
             * @property {boolean|null} [".feedbox.technic_api.gmrpcIsBroadcast"] FieldOptions .feedbox.technic_api.gmrpcIsBroadcast
             * @property {string|null} [".feedbox.trade_api.gmrpcResponse"] FieldOptions .feedbox.trade_api.gmrpcResponse
             * @property {boolean|null} [".feedbox.trade_api.gmrpcIsBroadcast"] FieldOptions .feedbox.trade_api.gmrpcIsBroadcast
             */

            /**
             * Constructs a new FieldOptions.
             * @memberof google.protobuf
             * @classdesc Represents a FieldOptions.
             * @implements IFieldOptions
             * @constructor
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             */
            function FieldOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldOptions ctype.
             * @member {google.protobuf.FieldOptions.CType} ctype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.ctype = 0;

            /**
             * FieldOptions packed.
             * @member {boolean} packed
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.packed = false;

            /**
             * FieldOptions jstype.
             * @member {google.protobuf.FieldOptions.JSType} jstype
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.jstype = 0;

            /**
             * FieldOptions lazy.
             * @member {boolean} lazy
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.lazy = false;

            /**
             * FieldOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.deprecated = false;

            /**
             * FieldOptions weak.
             * @member {boolean} weak
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.weak = false;

            /**
             * FieldOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * FieldOptions .feedbox.market_api.gmrpcResponse.
             * @member {string} .feedbox.market_api.gmrpcResponse
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".feedbox.market_api.gmrpcResponse"] = "";

            /**
             * FieldOptions .feedbox.market_api.gmrpcIsBroadcast.
             * @member {boolean} .feedbox.market_api.gmrpcIsBroadcast
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".feedbox.market_api.gmrpcIsBroadcast"] = false;

            /**
             * FieldOptions .feedbox.technic_api.gmrpcResponse.
             * @member {string} .feedbox.technic_api.gmrpcResponse
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".feedbox.technic_api.gmrpcResponse"] = "";

            /**
             * FieldOptions .feedbox.technic_api.gmrpcIsBroadcast.
             * @member {boolean} .feedbox.technic_api.gmrpcIsBroadcast
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".feedbox.technic_api.gmrpcIsBroadcast"] = false;

            /**
             * FieldOptions .feedbox.trade_api.gmrpcResponse.
             * @member {string} .feedbox.trade_api.gmrpcResponse
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".feedbox.trade_api.gmrpcResponse"] = "";

            /**
             * FieldOptions .feedbox.trade_api.gmrpcIsBroadcast.
             * @member {boolean} .feedbox.trade_api.gmrpcIsBroadcast
             * @memberof google.protobuf.FieldOptions
             * @instance
             */
            FieldOptions.prototype[".feedbox.trade_api.gmrpcIsBroadcast"] = false;

            /**
             * Creates a new FieldOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions=} [properties] Properties to set
             * @returns {google.protobuf.FieldOptions} FieldOptions instance
             */
            FieldOptions.create = function create(properties) {
                return new FieldOptions(properties);
            };

            /**
             * Encodes the specified FieldOptions message. Does not implicitly {@link google.protobuf.FieldOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.IFieldOptions} message FieldOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.ctype != null && Object.hasOwnProperty.call(message, "ctype"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ctype);
                if (message.packed != null && Object.hasOwnProperty.call(message, "packed"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.packed);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.lazy != null && Object.hasOwnProperty.call(message, "lazy"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.lazy);
                if (message.jstype != null && Object.hasOwnProperty.call(message, "jstype"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.jstype);
                if (message.weak != null && Object.hasOwnProperty.call(message, "weak"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.weak);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                if (message[".feedbox.technic_api.gmrpcResponse"] != null && Object.hasOwnProperty.call(message, ".feedbox.technic_api.gmrpcResponse"))
                    writer.uint32(/* id 10101, wireType 2 =*/80810).string(message[".feedbox.technic_api.gmrpcResponse"]);
                if (message[".feedbox.market_api.gmrpcResponse"] != null && Object.hasOwnProperty.call(message, ".feedbox.market_api.gmrpcResponse"))
                    writer.uint32(/* id 10102, wireType 2 =*/80818).string(message[".feedbox.market_api.gmrpcResponse"]);
                if (message[".feedbox.trade_api.gmrpcResponse"] != null && Object.hasOwnProperty.call(message, ".feedbox.trade_api.gmrpcResponse"))
                    writer.uint32(/* id 10103, wireType 2 =*/80826).string(message[".feedbox.trade_api.gmrpcResponse"]);
                if (message[".feedbox.technic_api.gmrpcIsBroadcast"] != null && Object.hasOwnProperty.call(message, ".feedbox.technic_api.gmrpcIsBroadcast"))
                    writer.uint32(/* id 10111, wireType 0 =*/80888).bool(message[".feedbox.technic_api.gmrpcIsBroadcast"]);
                if (message[".feedbox.market_api.gmrpcIsBroadcast"] != null && Object.hasOwnProperty.call(message, ".feedbox.market_api.gmrpcIsBroadcast"))
                    writer.uint32(/* id 10112, wireType 0 =*/80896).bool(message[".feedbox.market_api.gmrpcIsBroadcast"]);
                if (message[".feedbox.trade_api.gmrpcIsBroadcast"] != null && Object.hasOwnProperty.call(message, ".feedbox.trade_api.gmrpcIsBroadcast"))
                    writer.uint32(/* id 10113, wireType 0 =*/80904).bool(message[".feedbox.trade_api.gmrpcIsBroadcast"]);
                return writer;
            };

            /**
             * Decodes a FieldOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.FieldOptions} FieldOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.FieldOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.ctype = reader.int32();
                        break;
                    case 2:
                        message.packed = reader.bool();
                        break;
                    case 6:
                        message.jstype = reader.int32();
                        break;
                    case 5:
                        message.lazy = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 10:
                        message.weak = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    case 10102:
                        message[".feedbox.market_api.gmrpcResponse"] = reader.string();
                        break;
                    case 10112:
                        message[".feedbox.market_api.gmrpcIsBroadcast"] = reader.bool();
                        break;
                    case 10101:
                        message[".feedbox.technic_api.gmrpcResponse"] = reader.string();
                        break;
                    case 10111:
                        message[".feedbox.technic_api.gmrpcIsBroadcast"] = reader.bool();
                        break;
                    case 10103:
                        message[".feedbox.trade_api.gmrpcResponse"] = reader.string();
                        break;
                    case 10113:
                        message[".feedbox.trade_api.gmrpcIsBroadcast"] = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a FieldOptions message.
             * @function verify
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    switch (message.ctype) {
                    default:
                        return "ctype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.packed != null && message.hasOwnProperty("packed"))
                    if (typeof message.packed !== "boolean")
                        return "packed: boolean expected";
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    switch (message.jstype) {
                    default:
                        return "jstype: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    if (typeof message.lazy !== "boolean")
                        return "lazy: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.weak != null && message.hasOwnProperty("weak"))
                    if (typeof message.weak !== "boolean")
                        return "weak: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                if (message[".feedbox.market_api.gmrpcResponse"] != null && message.hasOwnProperty(".feedbox.market_api.gmrpcResponse"))
                    if (!$util.isString(message[".feedbox.market_api.gmrpcResponse"]))
                        return ".feedbox.market_api.gmrpcResponse: string expected";
                if (message[".feedbox.market_api.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".feedbox.market_api.gmrpcIsBroadcast"))
                    if (typeof message[".feedbox.market_api.gmrpcIsBroadcast"] !== "boolean")
                        return ".feedbox.market_api.gmrpcIsBroadcast: boolean expected";
                if (message[".feedbox.technic_api.gmrpcResponse"] != null && message.hasOwnProperty(".feedbox.technic_api.gmrpcResponse"))
                    if (!$util.isString(message[".feedbox.technic_api.gmrpcResponse"]))
                        return ".feedbox.technic_api.gmrpcResponse: string expected";
                if (message[".feedbox.technic_api.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".feedbox.technic_api.gmrpcIsBroadcast"))
                    if (typeof message[".feedbox.technic_api.gmrpcIsBroadcast"] !== "boolean")
                        return ".feedbox.technic_api.gmrpcIsBroadcast: boolean expected";
                if (message[".feedbox.trade_api.gmrpcResponse"] != null && message.hasOwnProperty(".feedbox.trade_api.gmrpcResponse"))
                    if (!$util.isString(message[".feedbox.trade_api.gmrpcResponse"]))
                        return ".feedbox.trade_api.gmrpcResponse: string expected";
                if (message[".feedbox.trade_api.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".feedbox.trade_api.gmrpcIsBroadcast"))
                    if (typeof message[".feedbox.trade_api.gmrpcIsBroadcast"] !== "boolean")
                        return ".feedbox.trade_api.gmrpcIsBroadcast: boolean expected";
                return null;
            };

            /**
             * Creates a FieldOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.FieldOptions} FieldOptions
             */
            FieldOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.FieldOptions)
                    return object;
                var message = new $root.google.protobuf.FieldOptions();
                switch (object.ctype) {
                case "STRING":
                case 0:
                    message.ctype = 0;
                    break;
                case "CORD":
                case 1:
                    message.ctype = 1;
                    break;
                case "STRING_PIECE":
                case 2:
                    message.ctype = 2;
                    break;
                }
                if (object.packed != null)
                    message.packed = Boolean(object.packed);
                switch (object.jstype) {
                case "JS_NORMAL":
                case 0:
                    message.jstype = 0;
                    break;
                case "JS_STRING":
                case 1:
                    message.jstype = 1;
                    break;
                case "JS_NUMBER":
                case 2:
                    message.jstype = 2;
                    break;
                }
                if (object.lazy != null)
                    message.lazy = Boolean(object.lazy);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.weak != null)
                    message.weak = Boolean(object.weak);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.FieldOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                if (object[".feedbox.market_api.gmrpcResponse"] != null)
                    message[".feedbox.market_api.gmrpcResponse"] = String(object[".feedbox.market_api.gmrpcResponse"]);
                if (object[".feedbox.market_api.gmrpcIsBroadcast"] != null)
                    message[".feedbox.market_api.gmrpcIsBroadcast"] = Boolean(object[".feedbox.market_api.gmrpcIsBroadcast"]);
                if (object[".feedbox.technic_api.gmrpcResponse"] != null)
                    message[".feedbox.technic_api.gmrpcResponse"] = String(object[".feedbox.technic_api.gmrpcResponse"]);
                if (object[".feedbox.technic_api.gmrpcIsBroadcast"] != null)
                    message[".feedbox.technic_api.gmrpcIsBroadcast"] = Boolean(object[".feedbox.technic_api.gmrpcIsBroadcast"]);
                if (object[".feedbox.trade_api.gmrpcResponse"] != null)
                    message[".feedbox.trade_api.gmrpcResponse"] = String(object[".feedbox.trade_api.gmrpcResponse"]);
                if (object[".feedbox.trade_api.gmrpcIsBroadcast"] != null)
                    message[".feedbox.trade_api.gmrpcIsBroadcast"] = Boolean(object[".feedbox.trade_api.gmrpcIsBroadcast"]);
                return message;
            };

            /**
             * Creates a plain object from a FieldOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.FieldOptions
             * @static
             * @param {google.protobuf.FieldOptions} message FieldOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.ctype = options.enums === String ? "STRING" : 0;
                    object.packed = false;
                    object.deprecated = false;
                    object.lazy = false;
                    object.jstype = options.enums === String ? "JS_NORMAL" : 0;
                    object.weak = false;
                    object[".feedbox.technic_api.gmrpcResponse"] = "";
                    object[".feedbox.market_api.gmrpcResponse"] = "";
                    object[".feedbox.trade_api.gmrpcResponse"] = "";
                    object[".feedbox.technic_api.gmrpcIsBroadcast"] = false;
                    object[".feedbox.market_api.gmrpcIsBroadcast"] = false;
                    object[".feedbox.trade_api.gmrpcIsBroadcast"] = false;
                }
                if (message.ctype != null && message.hasOwnProperty("ctype"))
                    object.ctype = options.enums === String ? $root.google.protobuf.FieldOptions.CType[message.ctype] : message.ctype;
                if (message.packed != null && message.hasOwnProperty("packed"))
                    object.packed = message.packed;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.lazy != null && message.hasOwnProperty("lazy"))
                    object.lazy = message.lazy;
                if (message.jstype != null && message.hasOwnProperty("jstype"))
                    object.jstype = options.enums === String ? $root.google.protobuf.FieldOptions.JSType[message.jstype] : message.jstype;
                if (message.weak != null && message.hasOwnProperty("weak"))
                    object.weak = message.weak;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                if (message[".feedbox.technic_api.gmrpcResponse"] != null && message.hasOwnProperty(".feedbox.technic_api.gmrpcResponse"))
                    object[".feedbox.technic_api.gmrpcResponse"] = message[".feedbox.technic_api.gmrpcResponse"];
                if (message[".feedbox.market_api.gmrpcResponse"] != null && message.hasOwnProperty(".feedbox.market_api.gmrpcResponse"))
                    object[".feedbox.market_api.gmrpcResponse"] = message[".feedbox.market_api.gmrpcResponse"];
                if (message[".feedbox.trade_api.gmrpcResponse"] != null && message.hasOwnProperty(".feedbox.trade_api.gmrpcResponse"))
                    object[".feedbox.trade_api.gmrpcResponse"] = message[".feedbox.trade_api.gmrpcResponse"];
                if (message[".feedbox.technic_api.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".feedbox.technic_api.gmrpcIsBroadcast"))
                    object[".feedbox.technic_api.gmrpcIsBroadcast"] = message[".feedbox.technic_api.gmrpcIsBroadcast"];
                if (message[".feedbox.market_api.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".feedbox.market_api.gmrpcIsBroadcast"))
                    object[".feedbox.market_api.gmrpcIsBroadcast"] = message[".feedbox.market_api.gmrpcIsBroadcast"];
                if (message[".feedbox.trade_api.gmrpcIsBroadcast"] != null && message.hasOwnProperty(".feedbox.trade_api.gmrpcIsBroadcast"))
                    object[".feedbox.trade_api.gmrpcIsBroadcast"] = message[".feedbox.trade_api.gmrpcIsBroadcast"];
                return object;
            };

            /**
             * Converts this FieldOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.FieldOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * CType enum.
             * @name google.protobuf.FieldOptions.CType
             * @enum {number}
             * @property {number} STRING=0 STRING value
             * @property {number} CORD=1 CORD value
             * @property {number} STRING_PIECE=2 STRING_PIECE value
             */
            FieldOptions.CType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STRING"] = 0;
                values[valuesById[1] = "CORD"] = 1;
                values[valuesById[2] = "STRING_PIECE"] = 2;
                return values;
            })();

            /**
             * JSType enum.
             * @name google.protobuf.FieldOptions.JSType
             * @enum {number}
             * @property {number} JS_NORMAL=0 JS_NORMAL value
             * @property {number} JS_STRING=1 JS_STRING value
             * @property {number} JS_NUMBER=2 JS_NUMBER value
             */
            FieldOptions.JSType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "JS_NORMAL"] = 0;
                values[valuesById[1] = "JS_STRING"] = 1;
                values[valuesById[2] = "JS_NUMBER"] = 2;
                return values;
            })();

            return FieldOptions;
        })();

        protobuf.OneofOptions = (function() {

            /**
             * Properties of an OneofOptions.
             * @memberof google.protobuf
             * @interface IOneofOptions
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] OneofOptions uninterpretedOption
             */

            /**
             * Constructs a new OneofOptions.
             * @memberof google.protobuf
             * @classdesc Represents an OneofOptions.
             * @implements IOneofOptions
             * @constructor
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             */
            function OneofOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OneofOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.OneofOptions
             * @instance
             */
            OneofOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new OneofOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions=} [properties] Properties to set
             * @returns {google.protobuf.OneofOptions} OneofOptions instance
             */
            OneofOptions.create = function create(properties) {
                return new OneofOptions(properties);
            };

            /**
             * Encodes the specified OneofOptions message. Does not implicitly {@link google.protobuf.OneofOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.IOneofOptions} message OneofOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OneofOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an OneofOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.OneofOptions} OneofOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OneofOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.OneofOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an OneofOptions message.
             * @function verify
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OneofOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OneofOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.OneofOptions} OneofOptions
             */
            OneofOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.OneofOptions)
                    return object;
                var message = new $root.google.protobuf.OneofOptions();
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.OneofOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OneofOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.OneofOptions
             * @static
             * @param {google.protobuf.OneofOptions} message OneofOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OneofOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this OneofOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.OneofOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OneofOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OneofOptions;
        })();

        protobuf.EnumOptions = (function() {

            /**
             * Properties of an EnumOptions.
             * @memberof google.protobuf
             * @interface IEnumOptions
             * @property {boolean|null} [allowAlias] EnumOptions allowAlias
             * @property {boolean|null} [deprecated] EnumOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumOptions.
             * @implements IEnumOptions
             * @constructor
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             */
            function EnumOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumOptions allowAlias.
             * @member {boolean} allowAlias
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.allowAlias = false;

            /**
             * EnumOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.deprecated = false;

            /**
             * EnumOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumOptions
             * @instance
             */
            EnumOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumOptions} EnumOptions instance
             */
            EnumOptions.create = function create(properties) {
                return new EnumOptions(properties);
            };

            /**
             * Encodes the specified EnumOptions message. Does not implicitly {@link google.protobuf.EnumOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.IEnumOptions} message EnumOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.allowAlias != null && Object.hasOwnProperty.call(message, "allowAlias"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.allowAlias);
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumOptions} EnumOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        message.allowAlias = reader.bool();
                        break;
                    case 3:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumOptions message.
             * @function verify
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    if (typeof message.allowAlias !== "boolean")
                        return "allowAlias: boolean expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumOptions} EnumOptions
             */
            EnumOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumOptions)
                    return object;
                var message = new $root.google.protobuf.EnumOptions();
                if (object.allowAlias != null)
                    message.allowAlias = Boolean(object.allowAlias);
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumOptions
             * @static
             * @param {google.protobuf.EnumOptions} message EnumOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults) {
                    object.allowAlias = false;
                    object.deprecated = false;
                }
                if (message.allowAlias != null && message.hasOwnProperty("allowAlias"))
                    object.allowAlias = message.allowAlias;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumOptions;
        })();

        protobuf.EnumValueOptions = (function() {

            /**
             * Properties of an EnumValueOptions.
             * @memberof google.protobuf
             * @interface IEnumValueOptions
             * @property {boolean|null} [deprecated] EnumValueOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] EnumValueOptions uninterpretedOption
             */

            /**
             * Constructs a new EnumValueOptions.
             * @memberof google.protobuf
             * @classdesc Represents an EnumValueOptions.
             * @implements IEnumValueOptions
             * @constructor
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             */
            function EnumValueOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EnumValueOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.deprecated = false;

            /**
             * EnumValueOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             */
            EnumValueOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new EnumValueOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions=} [properties] Properties to set
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions instance
             */
            EnumValueOptions.create = function create(properties) {
                return new EnumValueOptions(properties);
            };

            /**
             * Encodes the specified EnumValueOptions message. Does not implicitly {@link google.protobuf.EnumValueOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.IEnumValueOptions} message EnumValueOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnumValueOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes an EnumValueOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnumValueOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.EnumValueOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an EnumValueOptions message.
             * @function verify
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnumValueOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an EnumValueOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.EnumValueOptions} EnumValueOptions
             */
            EnumValueOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.EnumValueOptions)
                    return object;
                var message = new $root.google.protobuf.EnumValueOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.EnumValueOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an EnumValueOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.EnumValueOptions
             * @static
             * @param {google.protobuf.EnumValueOptions} message EnumValueOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnumValueOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this EnumValueOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.EnumValueOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnumValueOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return EnumValueOptions;
        })();

        protobuf.ServiceOptions = (function() {

            /**
             * Properties of a ServiceOptions.
             * @memberof google.protobuf
             * @interface IServiceOptions
             * @property {boolean|null} [deprecated] ServiceOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] ServiceOptions uninterpretedOption
             */

            /**
             * Constructs a new ServiceOptions.
             * @memberof google.protobuf
             * @classdesc Represents a ServiceOptions.
             * @implements IServiceOptions
             * @constructor
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             */
            function ServiceOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ServiceOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.deprecated = false;

            /**
             * ServiceOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.ServiceOptions
             * @instance
             */
            ServiceOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new ServiceOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions=} [properties] Properties to set
             * @returns {google.protobuf.ServiceOptions} ServiceOptions instance
             */
            ServiceOptions.create = function create(properties) {
                return new ServiceOptions(properties);
            };

            /**
             * Encodes the specified ServiceOptions message. Does not implicitly {@link google.protobuf.ServiceOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.IServiceOptions} message ServiceOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ServiceOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a ServiceOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ServiceOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.ServiceOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a ServiceOptions message.
             * @function verify
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ServiceOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ServiceOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.ServiceOptions} ServiceOptions
             */
            ServiceOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.ServiceOptions)
                    return object;
                var message = new $root.google.protobuf.ServiceOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.ServiceOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a ServiceOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.ServiceOptions
             * @static
             * @param {google.protobuf.ServiceOptions} message ServiceOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ServiceOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this ServiceOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.ServiceOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ServiceOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ServiceOptions;
        })();

        protobuf.MethodOptions = (function() {

            /**
             * Properties of a MethodOptions.
             * @memberof google.protobuf
             * @interface IMethodOptions
             * @property {boolean|null} [deprecated] MethodOptions deprecated
             * @property {Array.<google.protobuf.IUninterpretedOption>|null} [uninterpretedOption] MethodOptions uninterpretedOption
             */

            /**
             * Constructs a new MethodOptions.
             * @memberof google.protobuf
             * @classdesc Represents a MethodOptions.
             * @implements IMethodOptions
             * @constructor
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             */
            function MethodOptions(properties) {
                this.uninterpretedOption = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MethodOptions deprecated.
             * @member {boolean} deprecated
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.deprecated = false;

            /**
             * MethodOptions uninterpretedOption.
             * @member {Array.<google.protobuf.IUninterpretedOption>} uninterpretedOption
             * @memberof google.protobuf.MethodOptions
             * @instance
             */
            MethodOptions.prototype.uninterpretedOption = $util.emptyArray;

            /**
             * Creates a new MethodOptions instance using the specified properties.
             * @function create
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions=} [properties] Properties to set
             * @returns {google.protobuf.MethodOptions} MethodOptions instance
             */
            MethodOptions.create = function create(properties) {
                return new MethodOptions(properties);
            };

            /**
             * Encodes the specified MethodOptions message. Does not implicitly {@link google.protobuf.MethodOptions.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.IMethodOptions} message MethodOptions message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MethodOptions.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deprecated != null && Object.hasOwnProperty.call(message, "deprecated"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.deprecated);
                if (message.uninterpretedOption != null && message.uninterpretedOption.length)
                    for (var i = 0; i < message.uninterpretedOption.length; ++i)
                        $root.google.protobuf.UninterpretedOption.encode(message.uninterpretedOption[i], writer.uint32(/* id 999, wireType 2 =*/7994).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a MethodOptions message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.MethodOptions} MethodOptions
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MethodOptions.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.MethodOptions();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 33:
                        message.deprecated = reader.bool();
                        break;
                    case 999:
                        if (!(message.uninterpretedOption && message.uninterpretedOption.length))
                            message.uninterpretedOption = [];
                        message.uninterpretedOption.push($root.google.protobuf.UninterpretedOption.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a MethodOptions message.
             * @function verify
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MethodOptions.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    if (typeof message.deprecated !== "boolean")
                        return "deprecated: boolean expected";
                if (message.uninterpretedOption != null && message.hasOwnProperty("uninterpretedOption")) {
                    if (!Array.isArray(message.uninterpretedOption))
                        return "uninterpretedOption: array expected";
                    for (var i = 0; i < message.uninterpretedOption.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.verify(message.uninterpretedOption[i]);
                        if (error)
                            return "uninterpretedOption." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MethodOptions message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.MethodOptions} MethodOptions
             */
            MethodOptions.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.MethodOptions)
                    return object;
                var message = new $root.google.protobuf.MethodOptions();
                if (object.deprecated != null)
                    message.deprecated = Boolean(object.deprecated);
                if (object.uninterpretedOption) {
                    if (!Array.isArray(object.uninterpretedOption))
                        throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: array expected");
                    message.uninterpretedOption = [];
                    for (var i = 0; i < object.uninterpretedOption.length; ++i) {
                        if (typeof object.uninterpretedOption[i] !== "object")
                            throw TypeError(".google.protobuf.MethodOptions.uninterpretedOption: object expected");
                        message.uninterpretedOption[i] = $root.google.protobuf.UninterpretedOption.fromObject(object.uninterpretedOption[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MethodOptions message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.MethodOptions
             * @static
             * @param {google.protobuf.MethodOptions} message MethodOptions
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MethodOptions.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.uninterpretedOption = [];
                if (options.defaults)
                    object.deprecated = false;
                if (message.deprecated != null && message.hasOwnProperty("deprecated"))
                    object.deprecated = message.deprecated;
                if (message.uninterpretedOption && message.uninterpretedOption.length) {
                    object.uninterpretedOption = [];
                    for (var j = 0; j < message.uninterpretedOption.length; ++j)
                        object.uninterpretedOption[j] = $root.google.protobuf.UninterpretedOption.toObject(message.uninterpretedOption[j], options);
                }
                return object;
            };

            /**
             * Converts this MethodOptions to JSON.
             * @function toJSON
             * @memberof google.protobuf.MethodOptions
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MethodOptions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MethodOptions;
        })();

        protobuf.UninterpretedOption = (function() {

            /**
             * Properties of an UninterpretedOption.
             * @memberof google.protobuf
             * @interface IUninterpretedOption
             * @property {Array.<google.protobuf.UninterpretedOption.INamePart>|null} [name] UninterpretedOption name
             * @property {string|null} [identifierValue] UninterpretedOption identifierValue
             * @property {number|Long|null} [positiveIntValue] UninterpretedOption positiveIntValue
             * @property {number|Long|null} [negativeIntValue] UninterpretedOption negativeIntValue
             * @property {number|null} [doubleValue] UninterpretedOption doubleValue
             * @property {Uint8Array|null} [stringValue] UninterpretedOption stringValue
             * @property {string|null} [aggregateValue] UninterpretedOption aggregateValue
             */

            /**
             * Constructs a new UninterpretedOption.
             * @memberof google.protobuf
             * @classdesc Represents an UninterpretedOption.
             * @implements IUninterpretedOption
             * @constructor
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             */
            function UninterpretedOption(properties) {
                this.name = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UninterpretedOption name.
             * @member {Array.<google.protobuf.UninterpretedOption.INamePart>} name
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.name = $util.emptyArray;

            /**
             * UninterpretedOption identifierValue.
             * @member {string} identifierValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.identifierValue = "";

            /**
             * UninterpretedOption positiveIntValue.
             * @member {number|Long} positiveIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.positiveIntValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

            /**
             * UninterpretedOption negativeIntValue.
             * @member {number|Long} negativeIntValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.negativeIntValue = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * UninterpretedOption doubleValue.
             * @member {number} doubleValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.doubleValue = 0;

            /**
             * UninterpretedOption stringValue.
             * @member {Uint8Array} stringValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.stringValue = $util.newBuffer([]);

            /**
             * UninterpretedOption aggregateValue.
             * @member {string} aggregateValue
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             */
            UninterpretedOption.prototype.aggregateValue = "";

            /**
             * Creates a new UninterpretedOption instance using the specified properties.
             * @function create
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption=} [properties] Properties to set
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption instance
             */
            UninterpretedOption.create = function create(properties) {
                return new UninterpretedOption(properties);
            };

            /**
             * Encodes the specified UninterpretedOption message. Does not implicitly {@link google.protobuf.UninterpretedOption.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.IUninterpretedOption} message UninterpretedOption message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UninterpretedOption.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && message.name.length)
                    for (var i = 0; i < message.name.length; ++i)
                        $root.google.protobuf.UninterpretedOption.NamePart.encode(message.name[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.identifierValue != null && Object.hasOwnProperty.call(message, "identifierValue"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.identifierValue);
                if (message.positiveIntValue != null && Object.hasOwnProperty.call(message, "positiveIntValue"))
                    writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.positiveIntValue);
                if (message.negativeIntValue != null && Object.hasOwnProperty.call(message, "negativeIntValue"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.negativeIntValue);
                if (message.doubleValue != null && Object.hasOwnProperty.call(message, "doubleValue"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.doubleValue);
                if (message.stringValue != null && Object.hasOwnProperty.call(message, "stringValue"))
                    writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.stringValue);
                if (message.aggregateValue != null && Object.hasOwnProperty.call(message, "aggregateValue"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.aggregateValue);
                return writer;
            };

            /**
             * Decodes an UninterpretedOption message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UninterpretedOption.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2:
                        if (!(message.name && message.name.length))
                            message.name = [];
                        message.name.push($root.google.protobuf.UninterpretedOption.NamePart.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.identifierValue = reader.string();
                        break;
                    case 4:
                        message.positiveIntValue = reader.uint64();
                        break;
                    case 5:
                        message.negativeIntValue = reader.int64();
                        break;
                    case 6:
                        message.doubleValue = reader.double();
                        break;
                    case 7:
                        message.stringValue = reader.bytes();
                        break;
                    case 8:
                        message.aggregateValue = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies an UninterpretedOption message.
             * @function verify
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UninterpretedOption.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name")) {
                    if (!Array.isArray(message.name))
                        return "name: array expected";
                    for (var i = 0; i < message.name.length; ++i) {
                        var error = $root.google.protobuf.UninterpretedOption.NamePart.verify(message.name[i]);
                        if (error)
                            return "name." + error;
                    }
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    if (!$util.isString(message.identifierValue))
                        return "identifierValue: string expected";
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (!$util.isInteger(message.positiveIntValue) && !(message.positiveIntValue && $util.isInteger(message.positiveIntValue.low) && $util.isInteger(message.positiveIntValue.high)))
                        return "positiveIntValue: integer|Long expected";
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (!$util.isInteger(message.negativeIntValue) && !(message.negativeIntValue && $util.isInteger(message.negativeIntValue.low) && $util.isInteger(message.negativeIntValue.high)))
                        return "negativeIntValue: integer|Long expected";
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    if (typeof message.doubleValue !== "number")
                        return "doubleValue: number expected";
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    if (!(message.stringValue && typeof message.stringValue.length === "number" || $util.isString(message.stringValue)))
                        return "stringValue: buffer expected";
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    if (!$util.isString(message.aggregateValue))
                        return "aggregateValue: string expected";
                return null;
            };

            /**
             * Creates an UninterpretedOption message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.UninterpretedOption} UninterpretedOption
             */
            UninterpretedOption.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.UninterpretedOption)
                    return object;
                var message = new $root.google.protobuf.UninterpretedOption();
                if (object.name) {
                    if (!Array.isArray(object.name))
                        throw TypeError(".google.protobuf.UninterpretedOption.name: array expected");
                    message.name = [];
                    for (var i = 0; i < object.name.length; ++i) {
                        if (typeof object.name[i] !== "object")
                            throw TypeError(".google.protobuf.UninterpretedOption.name: object expected");
                        message.name[i] = $root.google.protobuf.UninterpretedOption.NamePart.fromObject(object.name[i]);
                    }
                }
                if (object.identifierValue != null)
                    message.identifierValue = String(object.identifierValue);
                if (object.positiveIntValue != null)
                    if ($util.Long)
                        (message.positiveIntValue = $util.Long.fromValue(object.positiveIntValue)).unsigned = true;
                    else if (typeof object.positiveIntValue === "string")
                        message.positiveIntValue = parseInt(object.positiveIntValue, 10);
                    else if (typeof object.positiveIntValue === "number")
                        message.positiveIntValue = object.positiveIntValue;
                    else if (typeof object.positiveIntValue === "object")
                        message.positiveIntValue = new $util.LongBits(object.positiveIntValue.low >>> 0, object.positiveIntValue.high >>> 0).toNumber(true);
                if (object.negativeIntValue != null)
                    if ($util.Long)
                        (message.negativeIntValue = $util.Long.fromValue(object.negativeIntValue)).unsigned = false;
                    else if (typeof object.negativeIntValue === "string")
                        message.negativeIntValue = parseInt(object.negativeIntValue, 10);
                    else if (typeof object.negativeIntValue === "number")
                        message.negativeIntValue = object.negativeIntValue;
                    else if (typeof object.negativeIntValue === "object")
                        message.negativeIntValue = new $util.LongBits(object.negativeIntValue.low >>> 0, object.negativeIntValue.high >>> 0).toNumber();
                if (object.doubleValue != null)
                    message.doubleValue = Number(object.doubleValue);
                if (object.stringValue != null)
                    if (typeof object.stringValue === "string")
                        $util.base64.decode(object.stringValue, message.stringValue = $util.newBuffer($util.base64.length(object.stringValue)), 0);
                    else if (object.stringValue.length)
                        message.stringValue = object.stringValue;
                if (object.aggregateValue != null)
                    message.aggregateValue = String(object.aggregateValue);
                return message;
            };

            /**
             * Creates a plain object from an UninterpretedOption message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.UninterpretedOption
             * @static
             * @param {google.protobuf.UninterpretedOption} message UninterpretedOption
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UninterpretedOption.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.name = [];
                if (options.defaults) {
                    object.identifierValue = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.positiveIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.positiveIntValue = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.negativeIntValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.negativeIntValue = options.longs === String ? "0" : 0;
                    object.doubleValue = 0;
                    if (options.bytes === String)
                        object.stringValue = "";
                    else {
                        object.stringValue = [];
                        if (options.bytes !== Array)
                            object.stringValue = $util.newBuffer(object.stringValue);
                    }
                    object.aggregateValue = "";
                }
                if (message.name && message.name.length) {
                    object.name = [];
                    for (var j = 0; j < message.name.length; ++j)
                        object.name[j] = $root.google.protobuf.UninterpretedOption.NamePart.toObject(message.name[j], options);
                }
                if (message.identifierValue != null && message.hasOwnProperty("identifierValue"))
                    object.identifierValue = message.identifierValue;
                if (message.positiveIntValue != null && message.hasOwnProperty("positiveIntValue"))
                    if (typeof message.positiveIntValue === "number")
                        object.positiveIntValue = options.longs === String ? String(message.positiveIntValue) : message.positiveIntValue;
                    else
                        object.positiveIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.positiveIntValue) : options.longs === Number ? new $util.LongBits(message.positiveIntValue.low >>> 0, message.positiveIntValue.high >>> 0).toNumber(true) : message.positiveIntValue;
                if (message.negativeIntValue != null && message.hasOwnProperty("negativeIntValue"))
                    if (typeof message.negativeIntValue === "number")
                        object.negativeIntValue = options.longs === String ? String(message.negativeIntValue) : message.negativeIntValue;
                    else
                        object.negativeIntValue = options.longs === String ? $util.Long.prototype.toString.call(message.negativeIntValue) : options.longs === Number ? new $util.LongBits(message.negativeIntValue.low >>> 0, message.negativeIntValue.high >>> 0).toNumber() : message.negativeIntValue;
                if (message.doubleValue != null && message.hasOwnProperty("doubleValue"))
                    object.doubleValue = options.json && !isFinite(message.doubleValue) ? String(message.doubleValue) : message.doubleValue;
                if (message.stringValue != null && message.hasOwnProperty("stringValue"))
                    object.stringValue = options.bytes === String ? $util.base64.encode(message.stringValue, 0, message.stringValue.length) : options.bytes === Array ? Array.prototype.slice.call(message.stringValue) : message.stringValue;
                if (message.aggregateValue != null && message.hasOwnProperty("aggregateValue"))
                    object.aggregateValue = message.aggregateValue;
                return object;
            };

            /**
             * Converts this UninterpretedOption to JSON.
             * @function toJSON
             * @memberof google.protobuf.UninterpretedOption
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UninterpretedOption.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UninterpretedOption.NamePart = (function() {

                /**
                 * Properties of a NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @interface INamePart
                 * @property {string} namePart NamePart namePart
                 * @property {boolean} isExtension NamePart isExtension
                 */

                /**
                 * Constructs a new NamePart.
                 * @memberof google.protobuf.UninterpretedOption
                 * @classdesc Represents a NamePart.
                 * @implements INamePart
                 * @constructor
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 */
                function NamePart(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NamePart namePart.
                 * @member {string} namePart
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.namePart = "";

                /**
                 * NamePart isExtension.
                 * @member {boolean} isExtension
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 */
                NamePart.prototype.isExtension = false;

                /**
                 * Creates a new NamePart instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart=} [properties] Properties to set
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart instance
                 */
                NamePart.create = function create(properties) {
                    return new NamePart(properties);
                };

                /**
                 * Encodes the specified NamePart message. Does not implicitly {@link google.protobuf.UninterpretedOption.NamePart.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.INamePart} message NamePart message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NamePart.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.namePart);
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isExtension);
                    return writer;
                };

                /**
                 * Decodes a NamePart message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NamePart.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.namePart = reader.string();
                            break;
                        case 2:
                            message.isExtension = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    if (!message.hasOwnProperty("namePart"))
                        throw $util.ProtocolError("missing required 'namePart'", { instance: message });
                    if (!message.hasOwnProperty("isExtension"))
                        throw $util.ProtocolError("missing required 'isExtension'", { instance: message });
                    return message;
                };

                /**
                 * Verifies a NamePart message.
                 * @function verify
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NamePart.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (!$util.isString(message.namePart))
                        return "namePart: string expected";
                    if (typeof message.isExtension !== "boolean")
                        return "isExtension: boolean expected";
                    return null;
                };

                /**
                 * Creates a NamePart message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.UninterpretedOption.NamePart} NamePart
                 */
                NamePart.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.UninterpretedOption.NamePart)
                        return object;
                    var message = new $root.google.protobuf.UninterpretedOption.NamePart();
                    if (object.namePart != null)
                        message.namePart = String(object.namePart);
                    if (object.isExtension != null)
                        message.isExtension = Boolean(object.isExtension);
                    return message;
                };

                /**
                 * Creates a plain object from a NamePart message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @static
                 * @param {google.protobuf.UninterpretedOption.NamePart} message NamePart
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NamePart.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.namePart = "";
                        object.isExtension = false;
                    }
                    if (message.namePart != null && message.hasOwnProperty("namePart"))
                        object.namePart = message.namePart;
                    if (message.isExtension != null && message.hasOwnProperty("isExtension"))
                        object.isExtension = message.isExtension;
                    return object;
                };

                /**
                 * Converts this NamePart to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.UninterpretedOption.NamePart
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NamePart.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return NamePart;
            })();

            return UninterpretedOption;
        })();

        protobuf.SourceCodeInfo = (function() {

            /**
             * Properties of a SourceCodeInfo.
             * @memberof google.protobuf
             * @interface ISourceCodeInfo
             * @property {Array.<google.protobuf.SourceCodeInfo.ILocation>|null} [location] SourceCodeInfo location
             */

            /**
             * Constructs a new SourceCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a SourceCodeInfo.
             * @implements ISourceCodeInfo
             * @constructor
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             */
            function SourceCodeInfo(properties) {
                this.location = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SourceCodeInfo location.
             * @member {Array.<google.protobuf.SourceCodeInfo.ILocation>} location
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             */
            SourceCodeInfo.prototype.location = $util.emptyArray;

            /**
             * Creates a new SourceCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo instance
             */
            SourceCodeInfo.create = function create(properties) {
                return new SourceCodeInfo(properties);
            };

            /**
             * Encodes the specified SourceCodeInfo message. Does not implicitly {@link google.protobuf.SourceCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.ISourceCodeInfo} message SourceCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SourceCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.location != null && message.location.length)
                    for (var i = 0; i < message.location.length; ++i)
                        $root.google.protobuf.SourceCodeInfo.Location.encode(message.location[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a SourceCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SourceCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.location && message.location.length))
                            message.location = [];
                        message.location.push($root.google.protobuf.SourceCodeInfo.Location.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a SourceCodeInfo message.
             * @function verify
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SourceCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    if (!Array.isArray(message.location))
                        return "location: array expected";
                    for (var i = 0; i < message.location.length; ++i) {
                        var error = $root.google.protobuf.SourceCodeInfo.Location.verify(message.location[i]);
                        if (error)
                            return "location." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a SourceCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.SourceCodeInfo} SourceCodeInfo
             */
            SourceCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.SourceCodeInfo)
                    return object;
                var message = new $root.google.protobuf.SourceCodeInfo();
                if (object.location) {
                    if (!Array.isArray(object.location))
                        throw TypeError(".google.protobuf.SourceCodeInfo.location: array expected");
                    message.location = [];
                    for (var i = 0; i < object.location.length; ++i) {
                        if (typeof object.location[i] !== "object")
                            throw TypeError(".google.protobuf.SourceCodeInfo.location: object expected");
                        message.location[i] = $root.google.protobuf.SourceCodeInfo.Location.fromObject(object.location[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a SourceCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.SourceCodeInfo
             * @static
             * @param {google.protobuf.SourceCodeInfo} message SourceCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SourceCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.location = [];
                if (message.location && message.location.length) {
                    object.location = [];
                    for (var j = 0; j < message.location.length; ++j)
                        object.location[j] = $root.google.protobuf.SourceCodeInfo.Location.toObject(message.location[j], options);
                }
                return object;
            };

            /**
             * Converts this SourceCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.SourceCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SourceCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            SourceCodeInfo.Location = (function() {

                /**
                 * Properties of a Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @interface ILocation
                 * @property {Array.<number>|null} [path] Location path
                 * @property {Array.<number>|null} [span] Location span
                 * @property {string|null} [leadingComments] Location leadingComments
                 * @property {string|null} [trailingComments] Location trailingComments
                 * @property {Array.<string>|null} [leadingDetachedComments] Location leadingDetachedComments
                 */

                /**
                 * Constructs a new Location.
                 * @memberof google.protobuf.SourceCodeInfo
                 * @classdesc Represents a Location.
                 * @implements ILocation
                 * @constructor
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 */
                function Location(properties) {
                    this.path = [];
                    this.span = [];
                    this.leadingDetachedComments = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Location path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.path = $util.emptyArray;

                /**
                 * Location span.
                 * @member {Array.<number>} span
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.span = $util.emptyArray;

                /**
                 * Location leadingComments.
                 * @member {string} leadingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingComments = "";

                /**
                 * Location trailingComments.
                 * @member {string} trailingComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.trailingComments = "";

                /**
                 * Location leadingDetachedComments.
                 * @member {Array.<string>} leadingDetachedComments
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 */
                Location.prototype.leadingDetachedComments = $util.emptyArray;

                /**
                 * Creates a new Location instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation=} [properties] Properties to set
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location instance
                 */
                Location.create = function create(properties) {
                    return new Location(properties);
                };

                /**
                 * Encodes the specified Location message. Does not implicitly {@link google.protobuf.SourceCodeInfo.Location.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.ILocation} message Location message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Location.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.span != null && message.span.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.span.length; ++i)
                            writer.int32(message.span[i]);
                        writer.ldelim();
                    }
                    if (message.leadingComments != null && Object.hasOwnProperty.call(message, "leadingComments"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.leadingComments);
                    if (message.trailingComments != null && Object.hasOwnProperty.call(message, "trailingComments"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.trailingComments);
                    if (message.leadingDetachedComments != null && message.leadingDetachedComments.length)
                        for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.leadingDetachedComments[i]);
                    return writer;
                };

                /**
                 * Decodes a Location message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Location.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.SourceCodeInfo.Location();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.path && message.path.length))
                                message.path = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.path.push(reader.int32());
                            } else
                                message.path.push(reader.int32());
                            break;
                        case 2:
                            if (!(message.span && message.span.length))
                                message.span = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.span.push(reader.int32());
                            } else
                                message.span.push(reader.int32());
                            break;
                        case 3:
                            message.leadingComments = reader.string();
                            break;
                        case 4:
                            message.trailingComments = reader.string();
                            break;
                        case 6:
                            if (!(message.leadingDetachedComments && message.leadingDetachedComments.length))
                                message.leadingDetachedComments = [];
                            message.leadingDetachedComments.push(reader.string());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies a Location message.
                 * @function verify
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Location.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (var i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.span != null && message.hasOwnProperty("span")) {
                        if (!Array.isArray(message.span))
                            return "span: array expected";
                        for (var i = 0; i < message.span.length; ++i)
                            if (!$util.isInteger(message.span[i]))
                                return "span: integer[] expected";
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        if (!$util.isString(message.leadingComments))
                            return "leadingComments: string expected";
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        if (!$util.isString(message.trailingComments))
                            return "trailingComments: string expected";
                    if (message.leadingDetachedComments != null && message.hasOwnProperty("leadingDetachedComments")) {
                        if (!Array.isArray(message.leadingDetachedComments))
                            return "leadingDetachedComments: array expected";
                        for (var i = 0; i < message.leadingDetachedComments.length; ++i)
                            if (!$util.isString(message.leadingDetachedComments[i]))
                                return "leadingDetachedComments: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a Location message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.SourceCodeInfo.Location} Location
                 */
                Location.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.SourceCodeInfo.Location)
                        return object;
                    var message = new $root.google.protobuf.SourceCodeInfo.Location();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.path: array expected");
                        message.path = [];
                        for (var i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.span) {
                        if (!Array.isArray(object.span))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.span: array expected");
                        message.span = [];
                        for (var i = 0; i < object.span.length; ++i)
                            message.span[i] = object.span[i] | 0;
                    }
                    if (object.leadingComments != null)
                        message.leadingComments = String(object.leadingComments);
                    if (object.trailingComments != null)
                        message.trailingComments = String(object.trailingComments);
                    if (object.leadingDetachedComments) {
                        if (!Array.isArray(object.leadingDetachedComments))
                            throw TypeError(".google.protobuf.SourceCodeInfo.Location.leadingDetachedComments: array expected");
                        message.leadingDetachedComments = [];
                        for (var i = 0; i < object.leadingDetachedComments.length; ++i)
                            message.leadingDetachedComments[i] = String(object.leadingDetachedComments[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Location message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @static
                 * @param {google.protobuf.SourceCodeInfo.Location} message Location
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Location.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.path = [];
                        object.span = [];
                        object.leadingDetachedComments = [];
                    }
                    if (options.defaults) {
                        object.leadingComments = "";
                        object.trailingComments = "";
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (var j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.span && message.span.length) {
                        object.span = [];
                        for (var j = 0; j < message.span.length; ++j)
                            object.span[j] = message.span[j];
                    }
                    if (message.leadingComments != null && message.hasOwnProperty("leadingComments"))
                        object.leadingComments = message.leadingComments;
                    if (message.trailingComments != null && message.hasOwnProperty("trailingComments"))
                        object.trailingComments = message.trailingComments;
                    if (message.leadingDetachedComments && message.leadingDetachedComments.length) {
                        object.leadingDetachedComments = [];
                        for (var j = 0; j < message.leadingDetachedComments.length; ++j)
                            object.leadingDetachedComments[j] = message.leadingDetachedComments[j];
                    }
                    return object;
                };

                /**
                 * Converts this Location to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.SourceCodeInfo.Location
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Location.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Location;
            })();

            return SourceCodeInfo;
        })();

        protobuf.GeneratedCodeInfo = (function() {

            /**
             * Properties of a GeneratedCodeInfo.
             * @memberof google.protobuf
             * @interface IGeneratedCodeInfo
             * @property {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>|null} [annotation] GeneratedCodeInfo annotation
             */

            /**
             * Constructs a new GeneratedCodeInfo.
             * @memberof google.protobuf
             * @classdesc Represents a GeneratedCodeInfo.
             * @implements IGeneratedCodeInfo
             * @constructor
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             */
            function GeneratedCodeInfo(properties) {
                this.annotation = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GeneratedCodeInfo annotation.
             * @member {Array.<google.protobuf.GeneratedCodeInfo.IAnnotation>} annotation
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             */
            GeneratedCodeInfo.prototype.annotation = $util.emptyArray;

            /**
             * Creates a new GeneratedCodeInfo instance using the specified properties.
             * @function create
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo=} [properties] Properties to set
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo instance
             */
            GeneratedCodeInfo.create = function create(properties) {
                return new GeneratedCodeInfo(properties);
            };

            /**
             * Encodes the specified GeneratedCodeInfo message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.IGeneratedCodeInfo} message GeneratedCodeInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeneratedCodeInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.annotation != null && message.annotation.length)
                    for (var i = 0; i < message.annotation.length; ++i)
                        $root.google.protobuf.GeneratedCodeInfo.Annotation.encode(message.annotation[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a GeneratedCodeInfo message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeneratedCodeInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.annotation && message.annotation.length))
                            message.annotation = [];
                        message.annotation.push($root.google.protobuf.GeneratedCodeInfo.Annotation.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Verifies a GeneratedCodeInfo message.
             * @function verify
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GeneratedCodeInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.annotation != null && message.hasOwnProperty("annotation")) {
                    if (!Array.isArray(message.annotation))
                        return "annotation: array expected";
                    for (var i = 0; i < message.annotation.length; ++i) {
                        var error = $root.google.protobuf.GeneratedCodeInfo.Annotation.verify(message.annotation[i]);
                        if (error)
                            return "annotation." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a GeneratedCodeInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.GeneratedCodeInfo} GeneratedCodeInfo
             */
            GeneratedCodeInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.GeneratedCodeInfo)
                    return object;
                var message = new $root.google.protobuf.GeneratedCodeInfo();
                if (object.annotation) {
                    if (!Array.isArray(object.annotation))
                        throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: array expected");
                    message.annotation = [];
                    for (var i = 0; i < object.annotation.length; ++i) {
                        if (typeof object.annotation[i] !== "object")
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.annotation: object expected");
                        message.annotation[i] = $root.google.protobuf.GeneratedCodeInfo.Annotation.fromObject(object.annotation[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a GeneratedCodeInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.GeneratedCodeInfo
             * @static
             * @param {google.protobuf.GeneratedCodeInfo} message GeneratedCodeInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeneratedCodeInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.annotation = [];
                if (message.annotation && message.annotation.length) {
                    object.annotation = [];
                    for (var j = 0; j < message.annotation.length; ++j)
                        object.annotation[j] = $root.google.protobuf.GeneratedCodeInfo.Annotation.toObject(message.annotation[j], options);
                }
                return object;
            };

            /**
             * Converts this GeneratedCodeInfo to JSON.
             * @function toJSON
             * @memberof google.protobuf.GeneratedCodeInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeneratedCodeInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            GeneratedCodeInfo.Annotation = (function() {

                /**
                 * Properties of an Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @interface IAnnotation
                 * @property {Array.<number>|null} [path] Annotation path
                 * @property {string|null} [sourceFile] Annotation sourceFile
                 * @property {number|null} [begin] Annotation begin
                 * @property {number|null} [end] Annotation end
                 */

                /**
                 * Constructs a new Annotation.
                 * @memberof google.protobuf.GeneratedCodeInfo
                 * @classdesc Represents an Annotation.
                 * @implements IAnnotation
                 * @constructor
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 */
                function Annotation(properties) {
                    this.path = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Annotation path.
                 * @member {Array.<number>} path
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.path = $util.emptyArray;

                /**
                 * Annotation sourceFile.
                 * @member {string} sourceFile
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.sourceFile = "";

                /**
                 * Annotation begin.
                 * @member {number} begin
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.begin = 0;

                /**
                 * Annotation end.
                 * @member {number} end
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 */
                Annotation.prototype.end = 0;

                /**
                 * Creates a new Annotation instance using the specified properties.
                 * @function create
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation=} [properties] Properties to set
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation instance
                 */
                Annotation.create = function create(properties) {
                    return new Annotation(properties);
                };

                /**
                 * Encodes the specified Annotation message. Does not implicitly {@link google.protobuf.GeneratedCodeInfo.Annotation.verify|verify} messages.
                 * @function encode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.IAnnotation} message Annotation message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Annotation.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.path != null && message.path.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.path.length; ++i)
                            writer.int32(message.path[i]);
                        writer.ldelim();
                    }
                    if (message.sourceFile != null && Object.hasOwnProperty.call(message, "sourceFile"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.sourceFile);
                    if (message.begin != null && Object.hasOwnProperty.call(message, "begin"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.begin);
                    if (message.end != null && Object.hasOwnProperty.call(message, "end"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.end);
                    return writer;
                };

                /**
                 * Decodes an Annotation message from the specified reader or buffer.
                 * @function decode
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Annotation.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.path && message.path.length))
                                message.path = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.path.push(reader.int32());
                            } else
                                message.path.push(reader.int32());
                            break;
                        case 2:
                            message.sourceFile = reader.string();
                            break;
                        case 3:
                            message.begin = reader.int32();
                            break;
                        case 4:
                            message.end = reader.int32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Verifies an Annotation message.
                 * @function verify
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Annotation.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.path != null && message.hasOwnProperty("path")) {
                        if (!Array.isArray(message.path))
                            return "path: array expected";
                        for (var i = 0; i < message.path.length; ++i)
                            if (!$util.isInteger(message.path[i]))
                                return "path: integer[] expected";
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        if (!$util.isString(message.sourceFile))
                            return "sourceFile: string expected";
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        if (!$util.isInteger(message.begin))
                            return "begin: integer expected";
                    if (message.end != null && message.hasOwnProperty("end"))
                        if (!$util.isInteger(message.end))
                            return "end: integer expected";
                    return null;
                };

                /**
                 * Creates an Annotation message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {google.protobuf.GeneratedCodeInfo.Annotation} Annotation
                 */
                Annotation.fromObject = function fromObject(object) {
                    if (object instanceof $root.google.protobuf.GeneratedCodeInfo.Annotation)
                        return object;
                    var message = new $root.google.protobuf.GeneratedCodeInfo.Annotation();
                    if (object.path) {
                        if (!Array.isArray(object.path))
                            throw TypeError(".google.protobuf.GeneratedCodeInfo.Annotation.path: array expected");
                        message.path = [];
                        for (var i = 0; i < object.path.length; ++i)
                            message.path[i] = object.path[i] | 0;
                    }
                    if (object.sourceFile != null)
                        message.sourceFile = String(object.sourceFile);
                    if (object.begin != null)
                        message.begin = object.begin | 0;
                    if (object.end != null)
                        message.end = object.end | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an Annotation message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @static
                 * @param {google.protobuf.GeneratedCodeInfo.Annotation} message Annotation
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Annotation.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.path = [];
                    if (options.defaults) {
                        object.sourceFile = "";
                        object.begin = 0;
                        object.end = 0;
                    }
                    if (message.path && message.path.length) {
                        object.path = [];
                        for (var j = 0; j < message.path.length; ++j)
                            object.path[j] = message.path[j];
                    }
                    if (message.sourceFile != null && message.hasOwnProperty("sourceFile"))
                        object.sourceFile = message.sourceFile;
                    if (message.begin != null && message.hasOwnProperty("begin"))
                        object.begin = message.begin;
                    if (message.end != null && message.hasOwnProperty("end"))
                        object.end = message.end;
                    return object;
                };

                /**
                 * Converts this Annotation to JSON.
                 * @function toJSON
                 * @memberof google.protobuf.GeneratedCodeInfo.Annotation
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Annotation.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Annotation;
            })();

            return GeneratedCodeInfo;
        })();

        return protobuf;
    })();

    return google;
})();

module.exports = $root;
