import { put, call, takeEvery, all } from 'redux-saga/effects';
import logger from 'Utils/logger';

const log = logger('WSEventsToActionsDispatchSaga:');
const logError = logger('Error:WSEventsToActionsDispatchSaga:');

export default function* WSEventsToActionsDispatch(createEventChannel, eventsToActionsMap) {
    const callers = eventsToActionsMap.map(({ event, actionCreator }) =>
        call(runDispatch, createEventChannel, event, actionCreator)
    );
    yield all(callers);
}

function* runDispatch(createEventChannel, event, actionCreator) {
    const action = actionCreator.toString();
    try {
        const eventChannel = yield call(createEventChannel, event);

        yield takeEvery(eventChannel, function* (...args) {
            yield put(actionCreator(...args));
        });

        log('Dispatcher for WS-event: %o to redux-action: %o run', event, action);
    } catch (error) {
        logError('WS events (event: %o) to actions (action: %o) dispatcher error: %O', event, action, error);
    }
}
