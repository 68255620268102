import { put, call, select, getContext } from 'redux-saga/effects';
import { isNil, values, includes } from 'lodash';
import * as actions from 'Store/actions';
import { TECHNIC_TYPES, LOCAL_STORAGE_KEYS, SERVICE_NAMES } from 'Constants';
import initialAppState from 'Store/initialState';

const availableThemeValues = values(TECHNIC_TYPES.THEME);

export { themeInitialSettings, handleSetTheme, handleGotTheme };

function* themeInitialSettings() {
    const storageService = yield getContext(SERVICE_NAMES.STORAGE);

    if (yield call([storageService, storageService.has], LOCAL_STORAGE_KEYS.THEME)) {
        const currentTheme = yield call([storageService, storageService.get], LOCAL_STORAGE_KEYS.THEME);

        if (isNil(currentTheme)) {
            // default
            yield handleGotTheme({ payload: initialAppState.theme });
            return;
        }

        if (includes(availableThemeValues, currentTheme)) {
            yield handleGotTheme({ payload: currentTheme });
            return;
        }
    }

    // default
    yield handleGotTheme({ payload: initialAppState.theme });
}

function* handleSetTheme({ payload: newTheme }) {
    const isDisabledServerSyncTheme = yield select((state) => state.isDisabledServerSyncTheme);

    if (isDisabledServerSyncTheme) {
        return;
    }

    yield put(actions.setThemeAndSendIt(newTheme));
    yield updateThemeInApp(newTheme);
}

function* handleGotTheme({ payload: newTheme }) {
    if (isNil(newTheme) || !includes(availableThemeValues, newTheme)) {
        return;
    }

    const { currentTheme, isDisabledServerSyncTheme } = yield select(({ theme, isDisabledServerSyncTheme }) => ({
        currentTheme: theme,
        isDisabledServerSyncTheme
    }));

    if (isDisabledServerSyncTheme || currentTheme === newTheme) {
        return;
    }

    yield put(actions.setThemeInStore(newTheme));
    yield updateThemeInApp(newTheme);
}

function* updateThemeInApp(theme) {
    const themeService = yield getContext(SERVICE_NAMES.THEME);
    if (themeService) {
        yield call([themeService, themeService.setCurrentTheme], theme);
    }

    const storageService = yield getContext(SERVICE_NAMES.STORAGE);
    yield call([storageService, storageService.set], LOCAL_STORAGE_KEYS.THEME, theme);
}
