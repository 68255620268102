import { createReducer } from '@reduxjs/toolkit';
import { keyBy, includes, get, omit, uniq } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';
import { isFinishedBetStatus } from 'Utils/statusFilters';

const sortBetsIdsByBetsPlaceTime = (betsIds, betsById) =>
    betsIds.sort((betId1, betId2) => get(betsById, `${betId2}.placeTime`, 0) - get(betsById, `${betId1}.placeTime`, 0));

const bets = createReducer(initialState.bets, {
    [actions.trade.resetActiveBets]: () => initialState.bets,
    [actions.trade.getBetListSuccess]: ({ byId, allIds }, { payload: bets }) => {
        const incomingBetsById = keyBy(bets, (b) => b.betId);
        const incomingBetsIds = bets.map((bet) => bet.betId);
        const updatedBetsById = { ...byId, ...incomingBetsById };
        const unsortedIds = uniq([...allIds, ...incomingBetsIds]);
        const updatedBetsAllIds = sortBetsIdsByBetsPlaceTime(unsortedIds, updatedBetsById);
        return { byId: updatedBetsById, allIds: updatedBetsAllIds };
    },
    [actions.trade.gotBet]: ({ byId, allIds }, { payload: bet }) => {
        const { betId } = bet;

        if (isFinishedBetStatus(bet.status)) {
            const updatedBetsAllIds = allIds.filter((id) => id !== betId);
            const updatedBetsById = omit(byId, betId);
            return { byId: updatedBetsById, allIds: updatedBetsAllIds };
        }

        const updatedBet = { ...byId[betId], ...bet };
        const updatedBetsById = { ...byId, [betId]: updatedBet };

        if (includes(allIds, betId)) {
            return { byId: updatedBetsById, allIds };
        }

        const updatedBetsAllIds = sortBetsIdsByBetsPlaceTime([...allIds, betId], updatedBetsById);
        return { byId: updatedBetsById, allIds: updatedBetsAllIds };
    },
    [actions.trade.setOpenBetsProfitLoss]: ({ byId, allIds }, { payload: betsParts }) => {
        const updatedBets = betsParts.reduce((acc, betPart) => {
            const { betId } = betPart;
            if (includes(allIds, betId)) {
                const updatedBet = { ...acc[betId], ...betPart };
                return { ...acc, [betId]: updatedBet };
            }
            return acc;
        }, byId);
        return { byId: updatedBets, allIds };
    },
    [actions.oneOfMarketOrTradeWSConnectionsClose]: () => initialState.bets
});

export default bets;
