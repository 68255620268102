// Import all necessary svg-sprite icons here
// import exampleIcon from 'Media/icons/svg-sprite/example.svg'
import arrowLeftIcon from 'Media/icons/svg-sprite/arrow-left.svg';
import arrowRightIcon from 'Media/icons/svg-sprite/arrow-right.svg';
import arrowDownIcon from 'Media/icons/svg-sprite/arrow-down.svg';
import longArrowUpIcon from 'Media/icons/svg-sprite/long-arrow-up.svg';
import searchIcon from 'Media/icons/svg-sprite/search.svg';
import smallArrowDownIcon from 'Media/icons/svg-sprite/small-arrow-down.svg';
import arrowRefreshIcon from 'Media/icons/svg-sprite/arrow-refresh.svg';
import eyeIcon from 'Media/icons/svg-sprite/eye.svg';
import crossedEyeIcon from 'Media/icons/svg-sprite/crossed-eye.svg';
import languageIcon from 'Media/icons/svg-sprite/language.svg';
import settingsIcon from 'Media/icons/svg-sprite/settings.svg';
import signOutIcon from 'Media/icons/svg-sprite/sign-out.svg';
import closeCrossIcon from 'Media/icons/svg-sprite/close-cross.svg';
import roundCloseCrossIcon from 'Media/icons/svg-sprite/round-close-cross.svg';
import plusIcon from 'Media/icons/svg-sprite/plus.svg';
import minusIcon from 'Media/icons/svg-sprite/minus.svg';
import dotsIcon from 'Media/icons/svg-sprite/dots.svg';
import chartIcon from 'Media/icons/svg-sprite/chart.svg';
import chartColumnsIcon from 'Media/icons/svg-sprite/chart-columns.svg';
import boxOpenIcon from 'Media/icons/svg-sprite/box-open.svg';
import favoriteIcon from 'Media/icons/svg-sprite/favorite.svg';
import depositIcon from 'Media/icons/svg-sprite/deposit.svg';
import withdrawIcon from 'Media/icons/svg-sprite/withdraw.svg';
import pointArrowLeftIcon from 'Media/icons/svg-sprite/point-arrow-left.svg';
import arrowToDownIcon from 'Media/icons/svg-sprite/arrow-to-down.svg';
import arrowToUpIcon from 'Media/icons/svg-sprite/arrow-to-up.svg';
import informationInCircleIcon from 'Media/icons/svg-sprite/information-in-circle.svg';
import arrowSellIcon from 'Media/icons/svg-sprite/arrow-sell.svg';
import arrowBuyIconIcon from 'Media/icons/svg-sprite/arrow-buy.svg';
import questionIcon from 'Media/icons/svg-sprite/question.svg';
import calendarIcon from 'Media/icons/svg-sprite/calendar.svg';
import fullscreenIcon from 'Media/icons/svg-sprite/fullscreen.svg';
import fullscreenOpenIcon from 'Media/icons/svg-sprite/fullscreen-open.svg';
import editIcon from 'Media/icons/svg-sprite/edit.svg';
import crossBoldIcon from 'Media/icons/svg-sprite/cross-bold.svg';

export const NAMES = {
    ARROW_LEFT: 'arrow-left',
    ARROW_RIGHT: 'arrow-right',
    ARROW_DOWN: 'arrow-down',
    ARROW_SELL: 'arrow-sell',
    ARROW_BUY: 'arrow-buy',
    ARROW_REFRESH: 'arrow-refresh',
    ARROW_TO_DOWN: 'arrow-to-down',
    ARROW_TO_UP: 'arrow-to-up',
    LONG_ARROW_UP: 'long-arrow-up',
    SMALL_ARROW_DOWN: 'small-arrow-down',
    POINT_ARROW_LEFT: 'point-arrow-left',
    SEARCH: 'search',
    EYE: 'eye',
    EDIT: 'edit',
    CROSSED_EYE: 'crossed-eye',
    CROSS_BOLD: 'cross-bold',
    LANGUAGE: 'language',
    SETTINGS: 'settings',
    SIGN_OUT: 'sign-out',
    CLOSE_CROSS: 'close-cross',
    ROUND_CLOSE_CROSS: 'round-close-cross',
    PLUS: 'plus',
    MINUS: 'minus',
    DOTS: 'dots',
    CHART: 'chart',
    CHART_COLUMNS: 'chart-columns',
    BOX_OPEN: 'box-open',
    FAVORITE: 'favorite',
    DEPOSIT: 'deposit',
    WITHDRAW: 'withdraw',
    INFORMATION_IN_CIRCLE: 'information-in-circle',
    QUESTION: 'question',
    CALENDAR: 'calendar',
    FULLSCREEN: 'fullscreen',
    FULLSCREEN_OPEN: 'fullscreen-open'
};

const ICONS = {
    // [NAMES.EXAMPLE]: exampleIcon
    [NAMES.ARROW_LEFT]: arrowLeftIcon,
    [NAMES.ARROW_RIGHT]: arrowRightIcon,
    [NAMES.ARROW_DOWN]: arrowDownIcon,
    [NAMES.LONG_ARROW_UP]: longArrowUpIcon,
    [NAMES.SEARCH]: searchIcon,
    [NAMES.SMALL_ARROW_DOWN]: smallArrowDownIcon,
    [NAMES.ARROW_REFRESH]: arrowRefreshIcon,
    [NAMES.EYE]: eyeIcon,
    [NAMES.EDIT]: editIcon,
    [NAMES.CROSSED_EYE]: crossedEyeIcon,
    [NAMES.CROSS_BOLD]: crossBoldIcon,
    [NAMES.LANGUAGE]: languageIcon,
    [NAMES.LANGUAGE]: languageIcon,
    [NAMES.SETTINGS]: settingsIcon,
    [NAMES.SIGN_OUT]: signOutIcon,
    [NAMES.CLOSE_CROSS]: closeCrossIcon,
    [NAMES.ROUND_CLOSE_CROSS]: roundCloseCrossIcon,
    [NAMES.PLUS]: plusIcon,
    [NAMES.MINUS]: minusIcon,
    [NAMES.DOTS]: dotsIcon,
    [NAMES.CHART]: chartIcon,
    [NAMES.CHART_COLUMNS]: chartColumnsIcon,
    [NAMES.BOX_OPEN]: boxOpenIcon,
    [NAMES.FAVORITE]: favoriteIcon,
    [NAMES.DEPOSIT]: depositIcon,
    [NAMES.WITHDRAW]: withdrawIcon,
    [NAMES.POINT_ARROW_LEFT]: pointArrowLeftIcon,
    [NAMES.ARROW_TO_DOWN]: arrowToDownIcon,
    [NAMES.ARROW_TO_UP]: arrowToUpIcon,
    [NAMES.INFORMATION_IN_CIRCLEr]: informationInCircleIcon,
    [NAMES.ARROW_SELL]: arrowSellIcon,
    [NAMES.ARROW_BUY]: arrowBuyIconIcon,
    [NAMES.QUESTION]: questionIcon,
    [NAMES.CALENDAR]: calendarIcon,
    [NAMES.FULLSCREEN]: fullscreenIcon,
    [NAMES.FULLSCREEN_OPEN]: fullscreenOpenIcon
};

export default ICONS;
