import { put, delay, getContext } from 'redux-saga/effects';
import { get } from 'lodash';
import * as actions from 'Store/actions';
import { MARKET_REQUESTS, SERVICE_NAMES } from 'Constants';
import moment from 'moment';
import logger from 'Utils/logger';

const logError = logger('Error:serverTimeSaga:');
const log = logger('serverTimeSaga:');

const UPDATE_TIME_TIMEOUT = 60000; // 1 min
const UPDATE_TIME_START_DELAY = 10000; // 10 sec

export default function* serverTimePeriodicUpdate() {
    try {
        const marketService = yield getContext(SERVICE_NAMES.MARKET);
        yield delay(UPDATE_TIME_START_DELAY);
        while (true) {
            yield serverTimeRequest(marketService);
            yield delay(UPDATE_TIME_TIMEOUT);
        }
    } catch (err) {
        logError(err.message);
    }
}

function* serverTimeRequest(marketService) {
    const t0 = Date.now();
    const { body } = yield marketService.request(MARKET_REQUESTS.GET_SERVER_TIME, {});
    const t1 = Date.now();

    const tSend = Math.round((t1 - t0) / 2);

    const time = get(body, 'time');

    if (!time) {
        return;
    }

    const diff = time - t0;
    const delta = tSend / Math.abs(diff) > 0.2 && t1 > t0 ? diff - tSend : diff;

    const timeCorrection = delta > 3000 ? delta : 0;

    yield put(actions.setTimeCorrection(timeCorrection));

    log(
        'Time: local: %s, server: %s, prepared diff: %s (ms), request time: %s (ms)',
        moment(t0).format('MM/DD, HH:mm:ss'),
        moment(time).format('MM/DD, HH:mm:ss'),
        timeCorrection,
        tSend
    );
}
