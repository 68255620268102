import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import values from 'lodash/values';
import logger from 'Utils/logger';
import { LOCALES, TECHNIC_TYPES } from 'Constants';

const log = logger('Embedded');

const ALLOWED_PARAMS = {
    LANGUAGE: 'language',
    THEME: 'theme',
    EMBEDDED: 'embedded',
    OAUTH: 'oauth'
};

const allowedParams = new Set(Object.keys(ALLOWED_PARAMS).map((key) => ALLOWED_PARAMS[key]));

const getValueFromQueryParam = (name) => {
    if (!allowedParams.has(name)) {
        return null;
    }

    const search = window?.location?.search;

    const regexp = `[?&]${name}=([^&]+)`;
    const stateMatch = search.match(new RegExp(regexp, 'i'));

    if (isNil(stateMatch)) {
        return null;
    }

    return stateMatch[1];
};

export const getLanguageSettings = async (languageService) => {
    const savedLocale = await languageService.init();

    const languageParam = getValueFromQueryParam(ALLOWED_PARAMS.LANGUAGE);

    const hasEmbeddedLanguageParam = !isNil(languageParam);

    const embeddedLanguage = includes(values(LOCALES), languageParam) ? languageParam : LOCALES.EN;

    const locale = hasEmbeddedLanguageParam ? embeddedLanguage : savedLocale;

    log('Got locale settings, hasEmbeddedLanguageParam: %o, locale: %o', hasEmbeddedLanguageParam, embeddedLanguage);

    if (savedLocale !== locale) {
        log('Sync locales: set locale: %o', locale);

        await languageService.setCurrentLang(locale);
    }

    return { hasEmbeddedLanguageParam, locale };
};

export const getThemeSettings = () => {
    const themes = {
        light: 0,
        dark: 1
    };

    const themeParam = getValueFromQueryParam(ALLOWED_PARAMS.THEME);

    const hasEmbeddedThemeParam = !isNil(themeParam);

    const theme = includes(values(TECHNIC_TYPES.THEME), themes[themeParam])
        ? themes[themeParam]
        : TECHNIC_TYPES.THEME.LIGHT;

    log('Got theme settings, hasEmbeddedThemeParam: %o, theme: %o', hasEmbeddedThemeParam, theme);

    return { hasEmbeddedThemeParam, theme };
};

export const getIsEmbeddedSetting = () => {
    const embeddedParam = getValueFromQueryParam(ALLOWED_PARAMS.EMBEDDED);

    const hasEmbeddedParam = !isNil(embeddedParam);

    log('Got hasEmbeddedParam: %o', hasEmbeddedParam);

    return hasEmbeddedParam;
};

export const requestToken = async () => {
    log('referer: %s', document.referrer);

    const referrerOriginMatches = (document.referrer || '').match(/http(s)?:\/\/[^/]+/);
    const referrerOrigin = referrerOriginMatches && referrerOriginMatches[0];

    const waitToken = new Promise((resolve, reject) => {
        const handler = (event) => {
            if (event.origin === referrerOrigin) {
                resolve(event.data);
            }

            reject(`Unexpected src origin: ${event.origin}`);
        };

        window.addEventListener('message', handler);

        setTimeout(() => {
            window.removeEventListener('message', handler);
            reject('Timeout');
        }, 200);
    });

    try {
        const data = await waitToken;
        log('Data: %O', data);

        return data?.token;
    } catch (error) {
        log('Error: %O', error);
    }
};
