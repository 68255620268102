import { select, call, takeEvery, all, delay, fork, getContext } from 'redux-saga/effects';
import { values, isEmpty } from 'lodash';
import { EVENTS, SERVICE_NAMES } from 'Constants';
import { handlePositionList, requestPositionList, handlePosition } from '../positions.saga';
import { handleOrderExecution } from '../orderExecutions.saga';
import { handleOrder, handleResult } from '../orders.saga';
import runWhenRetriedConditionSagaNotThrow from '../../utils/runWhenRetriedConditionSagaNotThrow';
import getEventChannelCreator from 'Store/sagas/utils/getEventChannelCreator';
import { updatePositionProfitLoss } from '../positions.saga';
import logger from 'Utils/logger';

const UPDATE_POSITIONS_PROFIT_LOSS_DELAY = 800;

const log = logger('MarginTradeWorkerSaga:');
const logError = logger('Error:MarginTradeWorkerSaga:');

export default function* betTradeWorker() {
    log('Start main margin trading saga');
    const tradeService = yield getContext(SERVICE_NAMES.TRADE);
    const createEventChannel = getEventChannelCreator(tradeService, EVENTS.TRADE.CLOSE);

    const [orderChannel, orderExecutionChannel, positionListChannel, positionChannel, resultChannel] = yield all([
        call(createEventChannel, EVENTS.TRADE.ORDER),
        call(createEventChannel, EVENTS.TRADE.ORDER_EXECUTION),
        call(createEventChannel, EVENTS.TRADE.POSITION_LIST),
        call(createEventChannel, EVENTS.TRADE.POSITION),
        call(createEventChannel, EVENTS.TRADE.RESULT)
    ]);

    yield takeEvery(orderChannel, handleOrder);

    yield takeEvery(orderExecutionChannel, handleOrderExecution);

    yield takeEvery(positionListChannel, handlePositionList);
    yield takeEvery(positionChannel, handlePosition);

    yield takeEvery(resultChannel, handleResult);

    yield fork(runProfitLossUpdate);

    yield runWhenRetriedConditionSagaNotThrow(0, 1000, checkInitialValues, requestPositionList);
}

function* checkInitialValues() {
    const { customerInfo, symbolList } = yield select(({ customerInfo, symbols }) => ({
        customerInfo,
        symbolList: values(symbols.byId)
    }));

    if (isEmpty(customerInfo) || isEmpty(symbolList)) {
        logError(
            'One of necessary data for position list request is empty: customerInfo: %o, symbolList: %o',
            customerInfo,
            symbolList
        );
        throw new Error('Not enough values');
    }
}

function* runProfitLossUpdate() {
    log('Start profit loss update process');
    try {
        while (true) {
            yield updatePositionProfitLoss();
            yield delay(UPDATE_POSITIONS_PROFIT_LOSS_DELAY);
        }
    } catch (err) {
        logError('Profit loss update process error: %O', err);
        // TODO fix this propblem compexly
        yield call(console.warn, err);
        yield fork(runProfitLossUpdate);
    }
}
