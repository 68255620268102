import { put, getContext } from 'redux-saga/effects';
import { get, isEmpty } from 'lodash';
import { TRADE_REQUESTS, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';

export function* requestCurrencyList() {
    try {
        const tradeService = yield getContext(SERVICE_NAMES.TRADE);

        yield put(actions.trade.getCurrencyListPending());
        yield tradeService.request(TRADE_REQUESTS.GET_CURRENCY_LIST, {});
    } catch (err) {
        yield put(actions.trade.getCurrencyListFailure(err.message));
    }
}

export function* handleCurrencyList({ body }) {
    try {
        const currencies = get(body, 'currencyList', []);
        yield put(actions.trade.getCurrencyListSuccess(currencies));
    } catch (err) {
        yield put(actions.trade.getCurrencyListFailure(err.message));
    }
}

export function* handleCurrency({ body }) {
    try {
        if (isEmpty(body)) {
            return;
        }

        yield put(actions.trade.gotCurrency(body));
    } catch (err) {
        yield put(actions.trade.getCurrencyListFailure(err.message));
    }
}
