import { createReducer } from '@reduxjs/toolkit';
import { keys } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';

const symbolGroups = createReducer(initialState.symbolGroups, {
    [actions.market.setSymbolGroups]: (state, { payload }) => {
        const allIds = keys(payload);
        return { allIds, byId: payload };
    },
    [actions.market.WSConnectionClose]: () => initialState.symbolGroups
});

export default symbolGroups;
