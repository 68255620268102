import { all } from 'redux-saga/effects';

// root sagas
import authSaga from './auth';
import marketSaga from './market';
import tradeSaga from './trade';
import newsSaga from './news';
import appSaga from './app';
import technicSaga from './technic';

export const rootSaga = function* () {
    yield all([authSaga(), marketSaga(), tradeSaga(), technicSaga(), newsSaga(), appSaga()]);
};

export default rootSaga;
