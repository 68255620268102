import { KEY_CODES, EVENTS } from 'Constants';

const STATE_CLASS = 'is-user-tabbing';

const handleFirstTab = (evt) => {
    if (evt.keyCode === KEY_CODES.TAB) {
        document.body.classList.add(STATE_CLASS);

        window.removeEventListener(EVENTS.KEYDOWN, handleFirstTab);
        window.addEventListener(EVENTS.MOUSEDOWN, handleMouseDownOnce);
    }
};

const handleMouseDownOnce = () => {
    document.body.classList.remove(STATE_CLASS);

    window.removeEventListener(EVENTS.MOUSEDOWN, handleMouseDownOnce);
    window.addEventListener(EVENTS.KEYDOWN, handleFirstTab);
};

const initTabbingControl = () => window.addEventListener(EVENTS.KEYDOWN, handleFirstTab);

export default initTabbingControl;
