export const LOCALES = {
    EN: 'en',
    RU: 'ru'
};

export const LOCALE_NAMESPACES = {
    COMMON: 'common',
    AUTH: 'auth',
    APP: 'app'
};
