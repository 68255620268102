import { ReactComponent as Logo } from 'Media/icons/logo.svg';

const AppPreloader = () => {
    return (
        <div className='app-preloader'>
            <Logo className='app-preloader__icon' />
        </div>
    );
};

export default AppPreloader;
