import { call, takeEvery, takeLatest, fork, delay, take, cancel, getContext } from 'redux-saga/effects';
import { EVENTS, SERVICE_NAMES } from 'Constants';
import * as actions from 'Store/actions';
import WSEventsToActionsDispatch from 'Store/sagas/utils/WSEventsToActionsDispatch.saga';
import getEventChannelCreator from 'Store/sagas/utils/getEventChannelCreator';
import { serverHealthCheck } from 'Store/sagas/health';
import { getSnapshotAndSubscribeToSettings, storeFavorites, storeLocale, storeTheme } from './settings.saga';
import logger from 'Utils/logger';

const log = logger('TechnicMainSaga:');
const logError = logger('Error:TechnicMainSaga:');

const eventsToActionsMap = [
    { event: EVENTS.TECHNIC.OPEN, actionCreator: actions.technic.WSConnectionOpen },
    { event: EVENTS.TECHNIC.CLOSE, actionCreator: actions.technic.WSConnectionClose },
    { event: EVENTS.TECHNIC.ERROR, actionCreator: actions.technic.WSConnectionOnError },
    { event: EVENTS.TECHNIC.PROBLEM, actionCreator: actions.technic.WSConnectionProblem },
    { event: EVENTS.TECHNIC.NORMAL, actionCreator: actions.technic.WSConnectionNormal }
];

export default function* technicSaga() {
    const technicService = yield getContext(SERVICE_NAMES.TECHNIC);
    const createDefaultEventChannel = getEventChannelCreator(technicService);

    yield fork(WSEventsToActionsDispatch, createDefaultEventChannel, eventsToActionsMap);

    yield takeEvery(actions.setIsAuthenticated, openWSConnection, technicService);
    yield takeLatest(actions.auth.requestLogoutPending, closeWSConnection, technicService);

    yield takeLatest(actions.technic.WSConnectionOpen, runTechnicTasks, technicService);
    yield takeLatest(actions.technic.WSConnectionClose, serverHealthCheck);

    yield takeLatest(actions.setFavoritesAndSendIt, storeFavorites);
    yield takeLatest(actions.setLocaleAndSendIt, storeLocale);
    yield takeLatest(actions.setThemeAndSendIt, storeTheme);
}

function* openWSConnection(technicService, { payload }) {
    if (payload !== true) {
        return;
    }

    if (technicService.isOpening || technicService.isOpened) {
        return;
    }

    try {
        yield call([technicService, technicService.connect]);
    } catch (err) {
        logError('runTechnicService error: %O', err);
        // TODO add logic to up on error
        yield call([technicService, technicService.disconnect], 1000, 'News service error');
        yield delay(3000);
        yield call([technicService, technicService.connect]);
    }
}

function* closeWSConnection(technicService) {
    try {
        yield call([technicService, technicService.disconnect], 1000, 'News service error');
    } catch (err) {
        logError('Close Technic WS connection error: %O', err);
    }
}

function* runTechnicTasks(technicService) {
    log('Technic WS: OPEN. Run tasks');

    try {
        const settingsSagaTask = yield fork(getSnapshotAndSubscribeToSettings);

        yield fork(function* () {
            yield take(actions.technic.WSConnectionClose);
            log('Technic WS: CLOSE. Stop tasks');

            yield cancel(settingsSagaTask);
        });
    } catch (err) {
        logError('runTechnicTasks error: %O', err);
        // TODO add logic to up on error
        yield call([technicService, technicService.disconnect], 1000, 'News service error');
        yield delay(3000);
        yield call([technicService, technicService.connect]);
    }
}
