import { isArray, includes } from 'lodash';
import { TRADING_MODES, MARKET_TYPES } from 'Constants';

const expectedSymbolTradingTypeByTradingMode = {
    [TRADING_MODES.BETS]: MARKET_TYPES.SYMBOL_TRADING_TYPE.BET_TRADING,
    [TRADING_MODES.MARGIN]: MARKET_TYPES.SYMBOL_TRADING_TYPE.MARGIN_TRADING
};

const filterSymbolListBySymbolTradingMode = (symbolList, tradingMode) => {
    const symbolTradingType = expectedSymbolTradingTypeByTradingMode[tradingMode];
    return symbolList.filter((s) => isArray(s.tradingType) && includes(s.tradingType, symbolTradingType));
};

export default filterSymbolListBySymbolTradingMode;
