import { put, call, fork, take, getContext } from 'redux-saga/effects';
import { uniqueId, get } from 'lodash';
import * as actions from 'Store/actions';
import { NEWS_REQUESTS, NEWS_TYPES, EVENTS, SERVICE_NAMES } from 'Constants';
import getEventChannelCreator from 'Store/sagas/utils/getEventChannelCreator';

function* newsUnsubscribeRequest(subscriptionId) {
    try {
        const newsService = yield getContext(SERVICE_NAMES.NEWS);
        yield put(actions.news.newsUnsubscribePending());

        const { result } = yield newsService.request(NEWS_REQUESTS.UNSUBSCRIBE, { subscriptionId });

        const resultCode = get(result, 'code', NEWS_TYPES.RESULT_CODES.SUCCESS);
        if (resultCode !== NEWS_TYPES.RESULT_CODES.SUCCESS) {
            throw new Error('Got news unsubscribe bad result');
        }

        yield put(actions.news.newsUnsubscribeSuccess());
    } catch (err) {
        yield put(actions.news.newsUnsubscribeFailure({ error: err.message }));
    }
}

export function* handleSubscribeToNews() {
    try {
        const newsService = yield getContext(SERVICE_NAMES.NEWS);
        yield put(actions.news.newsSubscribePending());

        const id = uniqueId();

        const { result } = yield newsService.request(NEWS_REQUESTS.SUBSCRIBE, {
            subscriptionId: id,
            newsSubscribe: {}
        });

        const resultCode = get(result, 'code', NEWS_TYPES.RESULT_CODES.SUCCESS);
        if (resultCode !== NEWS_TYPES.RESULT_CODES.SUCCESS) {
            throw new Error('Got news subscribe bad result');
        }

        yield put(actions.news.newsSubscribeSuccess());

        const createEventChannel = getEventChannelCreator(newsService, EVENTS.NEWS.CLOSE);
        const channel = yield call(createEventChannel, EVENTS.NEWS.NEWS);

        yield fork(function* () {
            while (true) {
                const { responseId, subscriptionId: currentId, news } = yield take(channel);
                if (currentId === id || responseId === id) {
                    yield put(actions.news.gotNews(news));
                }
            }
        });

        yield take(actions.news.unsubscribeFromNews);
        channel.close();
        yield fork(newsUnsubscribeRequest, id);
    } catch (err) {
        yield put(actions.news.newsSubscribeFailure({ error: err.message }));
    }
}
