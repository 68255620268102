import defaultsDeep from 'lodash/defaultsDeep';
import values from 'lodash/values';
import includes from 'lodash/includes';
import { LOCAL_STORAGE_KEYS, TECHNIC_TYPES } from 'Constants';
import { isString } from 'formik';

const noop = () => null;

const defaults = {
    name: 'Theme',
    logger: () => noop,
    storageService: null,
    storageKey: LOCAL_STORAGE_KEYS.THEME,
    defaultTheme: TECHNIC_TYPES.THEME.LIGHT,
    allowedThemes: values(TECHNIC_TYPES.THEME),
    themeToClass: {
        [TECHNIC_TYPES.THEME.LIGHT]: 'page--light',
        [TECHNIC_TYPES.THEME.DARK]: 'page--dark'
    }
};

class ThemeService {
    constructor(options) {
        const config = defaultsDeep(options, defaults);

        this._name = config.name;
        this._log = config.logger(`Service:${this._name}`);
        this._logError = config.logger(`Error:Service:${this._name}`);
        this._storageService = config.storageService;
        this._storageKey = config.storageKey;
        this._defaultTheme = config.defaultTheme;
        this._allowedThemes = config.allowedThemes;
        this._themeToClass = config.themeToClass;
        this._themeClasses = values(this._themeToClass);

        this._currentTheme = this._defaultTheme;

        if (this._storageService?.has(this._storageKey)) {
            const savedTheme = this._storageService.get(this._storageKey);

            if (this._isValidTheme(savedTheme)) {
                this._currentTheme = savedTheme;
            }
        }

        this.setCurrentTheme(this._currentTheme);
    }

    getCurrentTheme = () => this._currentTheme;

    setCurrentTheme = (theme) => {
        if (!this._isValidTheme(theme)) {
            return;
        }

        this._setThemeClass(theme);
        this._storageService?.set(this._storageKey, theme);
        this._log('Theme %s have been applied to body', theme);
    };

    _isValidTheme = (theme) => {
        if (!includes(this._allowedThemes, theme)) {
            this._logError('Value %s is not allowed to set as theme', theme);
            return false;
        }

        if (!isString(this._themeToClass[theme])) {
            this._logError('There is no class for theme %s', theme);
            return false;
        }

        return true;
    };

    _setThemeClass = (theme) => {
        this._removeBodyClasses(...this._themeClasses);
        this._addBodyClasses(this._themeToClass[theme]);
    };

    _addBodyClasses = (...classNames) => {
        const body = document.body;
        body.classList.add(...classNames);

        return body.className;
    };

    _removeBodyClasses = (...classNames) => {
        const body = document.body;
        body.classList.remove(...classNames);

        return body.className;
    };
}

export default ThemeService;
