import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const currentLocale = createReducer(initialState.currentLocale, {
    [actions.setLocaleInStore]: (state, { payload }) => payload,
    [actions.setLocaleAndSendIt]: (state, { payload }) => payload
});

export default currentLocale;
