import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
import bothMarketAndTradingServiceStatuses from './middleware/bothMarketAndTradingServiceStatuses';
import toastMiddleware from 'App/toasts/toastMiddleware';
import { ENV_VARIABLES } from 'Constants';

const initAppStore = (services = {}, preloadedState = {}) => {
    const sagaMiddleware = createSagaMiddleware({ context: services });

    const store = configureStore({
        reducer: rootReducer,
        middleware: [
            ...getDefaultMiddleware({
                thunk: {
                    extraArgument: services
                },
                serializableCheck: false,
                immutableCheck: false
            }),
            bothMarketAndTradingServiceStatuses,
            toastMiddleware,
            sagaMiddleware
        ],
        devTools: process.env.NODE_ENV !== ENV_VARIABLES.PRODUCTION,
        preloadedState
    });

    if (process.env.NODE_ENV !== ENV_VARIABLES.PRODUCTION && module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
    }

    store.runSaga = (saga) => sagaMiddleware.run(saga);
    store.runSaga(rootSaga);

    return store;
};

export default initAppStore;
