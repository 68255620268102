import { createReducer } from '@reduxjs/toolkit';
import { get } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';

const commission = createReducer(initialState.commission, {
    [actions.trade.getCommissionSuccess]: (state, { payload }) => {
        const commissionRequestId = get(payload, 'commissionRequestId', 'none');
        const commissionRequestType = get(payload, 'commissionRequestType', 'none');

        const commissionByRequestId = state[commissionRequestId] || {};

        return { ...state, [commissionRequestId]: { ...commissionByRequestId, [commissionRequestType]: payload } };
    }
});

export default commission;
