import { isNil } from 'lodash';
import WebSocketService from './WebSocketService';
import { news } from 'news-protocol';
import { EVENTS, NEWS_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL } from 'Constants';

const SERVICE_NAME = 'NEWS';

const HANDLEABLE_EVENT_NAMES = [EVENTS.NEWS.RESULT, EVENTS.NEWS.NEWS, EVENTS.NEWS.NEWS_LIST];

const getNewsEventName = (data) =>
    HANDLEABLE_EVENT_NAMES.find((eventName) => (data.hasOwnProperty(eventName) ? eventName : false));

class NewsWebSocketService extends WebSocketService {
    constructor(customOptions = {}) {
        super({
            url: '',
            name: SERVICE_NAME,
            protocolVersion: NEWS_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL,
            protocolEncoder: news.ClientRequest,
            protocolDecoder: news.ServerResponse,
            shouldUseWSCutout: true,
            ...customOptions
        });
    }

    _onmessage = (evt) => {
        try {
            const message = this._decodeIncomingData(evt.data);
            const id = !isNil(message.requestId) ? message.requestId : message.subscriptionId;

            const messageHandled = this._decoder.toObject(message);

            this._log('MESSAGE:#%o %O', id, messageHandled);

            this.emit(EVENTS[this._name].MESSAGE, messageHandled);

            const eventName = getNewsEventName(messageHandled);

            if (eventName) {
                this.emit(eventName, messageHandled);
            }

            const currentMessagePromise = this._promisesMap.get(id);
            if (!currentMessagePromise) {
                return;
            }

            if (eventName === EVENTS.NEWS.RESULT && messageHandled.result.code) {
                currentMessagePromise.reject(new Error(messageHandled.result.description)); // TODO: check
            } else {
                currentMessagePromise.resolve(messageHandled);
            }

            this._promisesMap.delete(id);
        } catch (err) {
            this._onerror(err);
        }
    };
}

export default NewsWebSocketService;
