import { TRADING_MODES, TRADING_MODE_DEFAULT } from 'Constants';
import includes from 'lodash/includes';
import get from 'lodash/get';
import isString from 'lodash/isString';

const NAME = 'trmd';

const saveTradingMode = (mode) => localStorage.setItem(NAME, mode);
const restoreTradingMode = () => localStorage.getItem(NAME);

const getTradingMode = (config) => {
    const mode = get(config, 'TRADING_MODE');
    const isAllowedSwitching = get(config, 'ALLOW_TRADING_MODE_SWITCH', false);

    const modeFromConfig = includes(TRADING_MODES, mode) ? mode : TRADING_MODE_DEFAULT;

    if (!isAllowedSwitching) {
        return modeFromConfig;
    }

    const modeFromUrl = window.location.pathname ? `${window.location.pathname}`.replace('/', '') : '';

    if (includes(TRADING_MODES, modeFromUrl)) {
        saveTradingMode(modeFromUrl);
        window.location = '/';
        return modeFromUrl;
    }

    const restoredMode = restoreTradingMode();

    if (isString(restoredMode) && includes(TRADING_MODES, restoredMode)) {
        return restoredMode;
    }

    return modeFromConfig;
};

export default getTradingMode;
