import PropTypes from 'prop-types';
import AppError from './AppError';

const CLASSNAME = 'unauthenticated-layout__app-error';

const UnauthenticatedAppError = (props) => {
    return (
        <div className={CLASSNAME}>
            <AppError {...props} />
        </div>
    );
};

UnauthenticatedAppError.propTypes = {
    name: PropTypes.string
};

export default UnauthenticatedAppError;
