import { createReducer } from '@reduxjs/toolkit';
import { keyBy, includes, get, uniq } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';
import { isFinishedBetStatus } from 'Utils/statusFilters';

const sortBetsIdsByBetsPlaceTime = (betsIds, betsById) =>
    betsIds.sort((betId1, betId2) => get(betsById, `${betId2}.placeTime`, 0) - get(betsById, `${betId1}.placeTime`, 0));

const finishedBets = createReducer(initialState.finishedBets, {
    [actions.trade.resetFinishedBets]: () => initialState.finishedBets,
    [actions.trade.getFinishedBetListSuccess]: ({ byId, allIds }, { payload: bets }) => {
        const incomingBetsById = keyBy(bets, (b) => b.betId);
        const incomingBetsIds = bets.map((bet) => bet.betId);
        const updatedBetsById = { ...byId, ...incomingBetsById };
        const unsortedIds = uniq([...allIds, ...incomingBetsIds]);
        const updatedBetsAllIds = sortBetsIdsByBetsPlaceTime(unsortedIds, updatedBetsById);
        return { byId: updatedBetsById, allIds: updatedBetsAllIds };
    },
    [actions.trade.gotBet]: (state, { payload: bet }) => {
        if (!isFinishedBetStatus(bet.status)) {
            return state;
        }

        const { byId, allIds } = state;
        const updatedBetsById = { ...byId, [bet.betId]: bet };

        if (includes(allIds, bet.betId)) {
            return { byId: updatedBetsById, allIds };
        }

        const updatedBetsAllIds = sortBetsIdsByBetsPlaceTime([...allIds, bet.betId], updatedBetsById);
        return { byId: updatedBetsById, allIds: updatedBetsAllIds };
    },
    [actions.trade.WSConnectionClose]: () => initialState.finishedBets,
    [actions.oneOfMarketOrTradeWSConnectionsClose]: () => initialState.finishedBets
});

export default finishedBets;
