import { call } from 'redux-saga/effects';
import retry from './retry';

// saga for try to check some condition (in conditionSaga) and run saga (sagaToRun)
export default function* runWhenRetriedConditionSagaNotThrow(attempts, timeout, conditionSaga, sagaToRun) {
    function* tryToRun() {
        yield call(conditionSaga);
        return yield call(sagaToRun);
    }

    yield retry(attempts, timeout, tryToRun);
}
