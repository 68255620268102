import { createReducer, createEntityAdapter } from '@reduxjs/toolkit';
import min from 'lodash/min';
import * as actions from '../actions';

export const orderExecutionsAdapter = createEntityAdapter({
    selectId: (orderExecution) => orderExecution.executionId,
    sortComparer: (a, b) => b.time - a.time
});

const initialState = orderExecutionsAdapter.getInitialState({
    minValidTime: null
});

const orderExecutions = createReducer(initialState, {
    [actions.trade.resetOrderExecutions]: () => initialState,
    [actions.trade.getOrderExecutionListSuccess]: (state, action) => {
        const minTime = min(action.payload.map((item) => item.time));
        state.minValidTime = minTime || null;
        orderExecutionsAdapter.upsertMany(state, action);
    },
    [actions.trade.gotOrderExecution]: (state, action) => {
        if (action.payload?.filledVolume) {
            orderExecutionsAdapter.upsertOne(state, action);
        }
    }
});

export default orderExecutions;
