import { put, takeLatest } from 'redux-saga/effects';
import * as actions from 'Store/actions';

// WATCHER
export default function* authSaga() {
    yield takeLatest(actions.auth.requestLoginSuccess, handleIsAuthenticated);
    yield takeLatest(actions.auth.requestRefreshSuccess, handleIsAuthenticated);
}

// WORKERS
function* handleIsAuthenticated(action) {
    if (action.payload) {
        const { isAuthenticated } = action.payload;
        yield put(actions.setIsAuthenticated(isAuthenticated));
    }
}
