import {
    LOADING_STATUSES,
    CONNECTION_STATUSES,
    TECHNIC_TYPES,
    ERRORS,
    DEFAULT_CURRENCY,
    TRADING_MODE_DEFAULT,
    TRADING_FORM_MODE_DEFAULT
} from 'Constants';

const initialState = {
    isAuthenticated: false,
    isMainMenuOpened: false,
    isProVersion: false,
    isDisabledServerSyncLanguage: false,
    isDisabledServerSyncTheme: false,
    isEmbedded: false,
    appError: ERRORS.NONE,
    tradingMode: TRADING_MODE_DEFAULT,
    theme: TECHNIC_TYPES.THEME.LIGHT,
    currentLocale: '',
    tradingFormMode: TRADING_FORM_MODE_DEFAULT,
    timeCorrection: 0,
    recaptchaKey: '',
    connectionsStatuses: {
        market: CONNECTION_STATUSES.INIT,
        trade: CONNECTION_STATUSES.INIT,
        news: CONNECTION_STATUSES.INIT,
        technic: CONNECTION_STATUSES.INIT
    },
    connectionsHealth: {
        market: false,
        trade: false,
        technic: false
    },
    loadingStatuses: {
        balances: LOADING_STATUSES.INIT,
        orders: LOADING_STATUSES.INIT,
        orderExecutions: LOADING_STATUSES.INIT,
        positions: LOADING_STATUSES.INIT,
        news: LOADING_STATUSES.INIT,
        newsItem: LOADING_STATUSES.INIT,
        bets: LOADING_STATUSES.INIT,
        finishedBets: LOADING_STATUSES.INIT,
        chartBars: LOADING_STATUSES.INIT,
        storeSettings: LOADING_STATUSES.INIT,
        commission: LOADING_STATUSES.INIT
    },
    customerInfo: {},
    totalAmounts: {
        balance: 0,
        commission: 0,
        reservedMargin: 0,
        credit: 0,
        bonus: 0,
        currency: DEFAULT_CURRENCY
    },
    currencies: { byId: {}, allIds: [] },
    favorites: [],
    chartBars: {
        byId: {},
        allIds: []
    },
    chartBar: {
        domain: {},
        zoomedXDomain: []
    },
    chartTabs: {
        currentTabId: '',
        tabs: []
    },
    symbols: { byId: {}, allIds: [], tradableIds: [] },
    symbolGroups: { byId: {}, allIds: [] },
    quotes: {},
    orders: { byId: {}, allIds: [] },
    orderExecutions: {}, // actual init state is defined in orderExecutions reducer
    positions: { byKey: {}, allKeys: [] },
    balances: { byId: {}, allIds: [] },
    news: { byId: {}, allIds: [] },
    bets: { byId: {}, allIds: [] },
    finishedBets: { byId: {}, allIds: [] },
    operationsTabs: {
        currentTabName: '',
        tabs: [],
        changedTabItems: {}
    },
    commission: {},
    insuranceConditions: { byId: {}, allIds: [] }
};

export default initialState;
