import { createReducer } from '@reduxjs/toolkit';
import { includes, keyBy, uniq, concat } from 'lodash';
import * as actions from '../actions';
import initialState from '../initialState';

const chartBars = createReducer(initialState.chartBars, {
    [actions.market.getChartBarListSuccess]: (state, { payload: { chartBars } }) => {
        const newAllIds = chartBars.map((cb) => cb.openTime);
        const newById = keyBy(chartBars, (cb) => cb.openTime);
        const allIds = uniq(concat(state.allIds, newAllIds)).sort((a, b) => a - b);
        const byId = { ...state.byId, ...newById };
        return { byId, allIds };
    },
    [actions.market.chartBarUpdate]: ({ byId, allIds }, { payload: chartBar }) => {
        const { openTime } = chartBar;
        const newById = { ...byId, [openTime]: chartBar };
        const newAllIds = includes(allIds, openTime) ? allIds : [...allIds, openTime].sort((a, b) => a - b);
        return { byId: newById, allIds: newAllIds };
    },
    [actions.market.resetCurrentChartBars]: () => initialState.chartBars,
    [actions.market.WSConnectionClose]: () => initialState.chartBars
});

export default chartBars;
