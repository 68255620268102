import { takeLatest, takeEvery } from 'redux-saga/effects';
import * as actions from 'Store/actions';
import { closePosition, requestPositionUpdate } from '../positions.saga';
import {
    loadActiveOrders,
    loadFinishedOrders,
    loadMoreFinishedOrders,
    sendOrderCancel,
    sendOrderRequest
} from '../orders.saga';
import { loadOrderExecutions, loadMoreOrderExecutions } from '../orderExecutions.saga';
import { requestCommission } from '../commission.saga';

import logger from 'Utils/logger';

const log = logger('BetsActionListenersSaga:');

// RUN ON APP START ONLY, AS PART OF MAIN TRADE SAGA
export default function* marginTradeListeners() {
    log('Start margin trading specific listeners saga');

    yield takeLatest(actions.trade.orderRequest, sendOrderRequest);
    yield takeEvery(actions.trade.cancelOrder, sendOrderCancel);
    yield takeEvery(actions.trade.closePosition, closePosition);
    yield takeEvery(actions.trade.loadActiveOrders, loadActiveOrders);
    yield takeEvery(actions.trade.loadFinishedOrders, loadFinishedOrders);
    yield takeEvery(actions.trade.loadMoreFinishedOrders, loadMoreFinishedOrders);
    yield takeEvery(actions.trade.loadOrderExecutions, loadOrderExecutions);
    yield takeEvery(actions.trade.loadMoreOrderExecutions, loadMoreOrderExecutions);
    yield takeEvery(actions.trade.getCommission, requestCommission);
    yield takeEvery(actions.trade.updatePositionRequest, requestPositionUpdate);
}
