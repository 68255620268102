import { createAction } from '@reduxjs/toolkit';

// Trade WS Connection
export const WSConnectionConnecting = createAction('TRADE_WS_CONNECTION_CONNECTING');
export const WSConnectionOpen = createAction('TRADE_WS_CONNECTION_OPEN');
export const WSConnectionOnError = createAction('TRADE_WS_CONNECTION_ON_ERROR');
export const WSConnectionClose = createAction('TRADE_WS_CONNECTION_CLOSE');
export const WSConnectionProblem = createAction('TRADE_WS_CONNECTION_PROBLEM');
export const WSConnectionNormal = createAction('TRADE_WS_CONNECTION_NORMAL');

export const getCustomerInfoPending = createAction('GET_CUSTOMER_INFO_PENDING');
export const getCustomerInfoSuccess = createAction('GET_CUSTOMER_INFO_SUCCESS');
export const getCustomerInfoFailure = createAction('GET_CUSTOMER_INFO_FAILURE');

export const orderRequest = createAction('ORDER_REQUEST');
export const orderRequestWasSent = createAction('ORDER_REQUEST_WAS_SENT');
export const orderRequestFailure = createAction('ORDER_REQUEST_FAILURE');

export const cancelOrder = createAction('CANCEL_ORDER');
export const cancelOrderPending = createAction('CANCEL_ORDER_PENDING');
export const cancelOrderSuccess = createAction('CANCEL_ORDER_SUCCESS');
export const cancelOrderFailure = createAction('CANCEL_ORDER_FAILURE');

export const resetOrders = createAction('RESET_ORDERS');
export const loadActiveOrders = createAction('LOAD_ACTIVE_ORDERS');
export const loadFinishedOrders = createAction('LOAD_FINISHED_ORDERS');
export const loadMoreFinishedOrders = createAction('LOAD_MORE_FINISHED_ORDERS');
export const ordersIsEmpty = createAction('ORDERS_IS_EMPTY');
export const getOrderListPending = createAction('GET_ORDER_LIST_PENDING');
export const getOrderListSuccess = createAction('GET_ORDER_LIST_SUCCESS');
export const getOrderListFailure = createAction('GET_ORDER_LIST_FAILURE');

export const resetOrderExecutions = createAction('RESET_EXECUTIONS');
export const loadOrderExecutions = createAction('LOAD_EXECUTIONS');
export const loadMoreOrderExecutions = createAction('LOAD_MORE_EXECUTIONS');
export const orderExecutionsIsEmpty = createAction('EXECUTIONS_IS_EMPTY');
export const getOrderExecutionListPending = createAction('GET_EXECUTION_LIST_PENDING');
export const getOrderExecutionListSuccess = createAction('GET_EXECUTION_LIST_SUCCESS');
export const getOrderExecutionListFailure = createAction('GET_EXECUTION_LIST_FAILURE');

export const gotOrder = createAction('GOT_ORDER');
export const gotOrderExecution = createAction('GOT_ORDER_EXECUTION');
export const gotOrderExecutionList = createAction('GOT_ORDER_EXECUTION_LIST');

export const getTotalAmountsPending = createAction('GET_TOTAL_AMOUNTS_PENDING');
export const getTotalAmountsSuccess = createAction('GET_TOTAL_AMOUNTS_SUCCESS');
export const getTotalAmountsFailure = createAction('GET_TOTAL_AMOUNTS_FAILURE');

export const getPositionListPending = createAction('GET_POSITION_LIST_PENDING');
export const getPositionListSuccess = createAction('GET_POSITION_LIST_SUCCESS');
export const getPositionListFailure = createAction('GET_POSITION_LIST_FAILURE');

export const handlePositionStart = createAction('HANDLE_POSITION_START');
export const removePosition = createAction('POSITION_REMOVE');
export const updatePosition = createAction('POSITION_UPDATE');
export const addPosition = createAction('POSITION_ADD');
export const handlePositionFailure = createAction('HANDLE_POSITION_FAILURE');
export const setPositionProfitLoss = createAction('SET_POSITION_PROFIT_LOSS');
export const closePosition = createAction('CLOSE_POSITION');

export const updatePositionRequest = createAction('UPDATE_POSITION_REQUEST');
export const updatePositionRequestSuccess = createAction('UPDATE_POSITION_REQUEST_SUCCESS');
export const updatePositionRequestFailure = createAction('UPDATE_POSITION_REQUEST_FAILURE');

export const resetBalances = createAction('RESET_BALANCES');
export const loadBalances = createAction('LOAD_BALANCES');
export const loadMoreBalances = createAction('LOAD_MORE_BALANCES');
export const balancesIsEmpty = createAction('BALANCES_IS_EMPTY');
export const getBalancesPending = createAction('GET_BALANCES_PENDING');
export const getBalancesSuccess = createAction('GET_BALANCES_SUCCESS');
export const getBalancesFailure = createAction('GET_BALANCES_FAILURE');
export const gotBalance = createAction('GOT_BALANCE');

export const placeBetRequest = createAction('PLACE_BET_REQUEST');
export const placeBetRequestWasSent = createAction('PLACE_BET_REQUEST_WAS_SENT');
export const placeBetRequestFailure = createAction('PLACE_BET_REQUEST_FAILURE');

export const gotBet = createAction('GOT_BET');
export const setOpenBetsProfitLoss = createAction('SET_OPEN_BETS_PROFIT_LOSS');
export const handleBetFailure = createAction('HANDLE_BET_FAILURE');

export const resetActiveBets = createAction('RESET_ACTIVE_BETS');
export const loadActiveBets = createAction('LOAD_ACTIVE_BETS');
export const getBetListPending = createAction('GET_BET_LIST_PENDING');
export const getBetListSuccess = createAction('GET_BET_LIST_SUCCESS');
export const getBetListFailure = createAction('GET_BET_LIST_FAILURE');
export const resetFinishedBets = createAction('RESET_FINISHED_BETS');
export const loadFinishedBets = createAction('LOAD_FINISHED_BETS');
export const loadMoreFinishedBets = createAction('LOAD_MORE_FINISHED_BETS');
export const finishedBetsIsEmpty = createAction('FINISHED_BETS_IS_EMPTY');
export const getFinishedBetListPending = createAction('GET_FINISHED_BET_LIST_PENDING');
export const getFinishedBetListSuccess = createAction('GET_FINISHED_BET_LIST_SUCCESS');
export const getFinishedBetListFailure = createAction('GET_FINISHED_BET_LIST_FAILURE');

export const closeBet = createAction('BET_CLOSE_INITIALIZE');
export const closeBetPending = createAction('BET_CLOSE_PENDING');
export const closeBetSuccess = createAction('BET_CLOSE_SUCCESS');
export const closeBetFailure = createAction('BET_CLOSE_FAILURE');

export const cancelBet = createAction('BET_CANCEL_INITIALIZE');
export const cancelBetPending = createAction('BET_CANCEL_PENDING');
export const cancelBetSuccess = createAction('BET_CANCEL_SUCCESS');
export const cancelBetFailure = createAction('BET_CANCEL_FAILURE');

export const getCommission = createAction('GET_COMMISSION');
export const getCommissionPending = createAction('GET_COMMISSION_PENDING');
export const getCommissionSuccess = createAction('GET_COMMISSION_SUCCESS');
export const getCommissionFailure = createAction('GET_COMMISSION_FAILURE');
export const getCloseBetCommission = createAction('GET_CLOSE_BET_COMMISSION');

export const gotInsuranceCondition = createAction('GOT_INSURANCE_CONDITION');
export const getInsuranceConditionListPending = createAction('GET_INSURANCE_CONDITION_LIST_PENDING');
export const getInsuranceConditionListSuccess = createAction('GET_INSURANCE_CONDITION_LIST_SUCCESS');
export const getInsuranceConditionListFailure = createAction('GET_INSURANCE_CONDITION_LIST_FAILURE');

export const gotCurrency = createAction('GOT_CURRENCY');
export const getCurrencyListPending = createAction('GET_CURRENCY_LIST_PENDING');
export const getCurrencyListSuccess = createAction('GET_CURRENCY_LIST_SUCCESS');
export const getCurrencyListFailure = createAction('GET_CURRENCY_LIST_FAILURE');

export const gotMarginTradingResult = createAction('GOT_MARGIN_TRADING_RESULT');
export const gotBetsResult = createAction('GOT_BETS_RESULT');
