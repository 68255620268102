import { put, call, all, fork, take, cancel, delay, getContext, select } from 'redux-saga/effects';
import { chain, isNil, get, has } from 'lodash';
import isEmptyString from 'Utils/isEmptyString';
import * as actions from 'Store/actions';
import { MARKET_REQUESTS, MARKET_TYPES, EVENTS, SERVICE_NAMES } from 'Constants';
import { prepareInputUint64Value } from 'Utils/uint64';
import getEventChannelCreator from 'Store/sagas/utils/getEventChannelCreator';
import logger from 'Utils/logger';
import filterSymbolListBySymbolTradingType from 'Utils/filterSymbolListBySymbolTradingType';

const logError = logger('Error:SymbolListSaga:');

const UPDATE_SYMBOL_PRICE_DELAY = 7200000; // 2 hours
const DEFAULT_SYMBOL_GROUP = 'Unknown';

const WEEKDAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const DEFAULT_PERIOD = { from: 0, to: 0 };

const prepareSymbolData = (data) => {
    const lotSize = prepareInputUint64Value(get(data, 'lotSize', 0));
    const volumeMin = prepareInputUint64Value(get(data, 'volumeMin', 0));
    const volumeStep = prepareInputUint64Value(get(data, 'volumeStep', 0));
    const volumeMax = prepareInputUint64Value(get(data, 'volumeMax', 0));
    const digits = get(data, 'digits', 0);
    const buyCorrectionFactor = get(data, 'buyCorrectionFactor', 0);
    const sellCorrectionFactor = get(data, 'sellCorrectionFactor', 0);

    const tradingTime = WEEKDAYS.reduce((acc, day) => {
        const periods = get(data, `tradingTime.${day}`, [DEFAULT_PERIOD]);
        const preparedPeriods = periods.map((p) => ({ ...DEFAULT_PERIOD, ...p }));
        return { ...acc, [day]: preparedPeriods };
    }, {});

    return {
        ...data,
        lotSize,
        digits,
        buyCorrectionFactor,
        sellCorrectionFactor,
        tradingTime,
        volumeMin,
        volumeStep,
        volumeMax
    };
};

export default function* initSymbolListSubscription() {
    try {
        const marketService = yield getContext(SERVICE_NAMES.MARKET);
        const createEventChannel = getEventChannelCreator(marketService, EVENTS.MARKET.CLOSE);

        const symbolListChannel = yield call(createEventChannel, EVENTS.MARKET.SYMBOL_LIST);
        yield fork(watchSymbolList, symbolListChannel);
        yield marketService.requestSubscribe(MARKET_REQUESTS.SYMBOL_LIST_REQUEST, {});
    } catch (err) {
        logError(err.message);
    }
}

function* watchSymbolList(symbolListChannel) {
    const tradingMode = yield select((state) => state.tradingMode);

    let updateOpenPriceTask;

    while (true) {
        const symbolListData = yield take(symbolListChannel);
        const symbolListRaw = get(symbolListData, 'symbolList.symbol', []);

        const symbolList = symbolListRaw.map((s) => prepareSymbolData(s));
        const tradableSymbolList = filterSymbolListBySymbolTradingType(symbolList, tradingMode);
        const tradableSymbolNames = tradableSymbolList.map((s) => s.symbol);

        yield put(actions.market.setSymbolList({ symbols: symbolList, tradableIds: tradableSymbolNames }));
        yield updateSymbolGroups(tradableSymbolList);

        if (!!updateOpenPriceTask) {
            yield cancel(updateOpenPriceTask);
        }

        const symbolNames = symbolList.map((s) => s.symbol);
        updateOpenPriceTask = yield fork(runSymbolListOpenPriceUpdating, symbolNames);
    }
}

function* updateSymbolGroups(symbols) {
    const groups = symbols.reduce((acc, s) => {
        const groupWord = s.path ? s.path.match(/\w*/)[0] : '';
        const group = isEmptyString(groupWord) ? DEFAULT_SYMBOL_GROUP : groupWord;

        if (!acc[group]) {
            return { ...acc, [group]: [s.symbol] };
        }
        return { ...acc, [group]: [...acc[group], s.symbol] };
    }, {});

    yield put(actions.market.setSymbolGroups(groups));
}

function* runSymbolListOpenPriceUpdating(symbols) {
    try {
        while (true) {
            const requests = symbols.map((symbol) => call(getSymbolOpenPrice, symbol));
            const openPriceEntries = yield all(requests);

            const preparedPrices = chain(openPriceEntries)
                .filter((pair) => !isNil(pair[1]))
                .fromPairs()
                .mapValues((v) => ({ openPrice: prepareInputUint64Value(v) }))
                .value();

            yield put(actions.market.updateSymbolList(preparedPrices));

            yield delay(UPDATE_SYMBOL_PRICE_DELAY);
        }
    } catch (err) {
        logError(err.message);
        yield delay(3000);
        return yield fork(runSymbolListOpenPriceUpdating, symbols);
    }
}

function* getSymbolOpenPrice(symbol) {
    const openPricePath = 'chartBar[0].openPrice';
    try {
        const marketService = yield getContext(SERVICE_NAMES.MARKET);
        const { body } = yield marketService.request(MARKET_REQUESTS.CHART_BAR_LIST_REQUEST, {
            periodType: MARKET_TYPES.PERIOD_TYPE.D1,
            symbol,
            limit: 1
        });

        if (!has(body, openPricePath)) {
            return [symbol];
        }

        const openPrice = get(body, openPricePath);
        return [symbol, openPrice];
    } catch (err) {
        logError(err.message);
        return [symbol];
    }
}
