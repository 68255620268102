import WebSocketService from './WebSocketService';
import { feedbox } from 'protocol';
import { TECHNIC_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL, TECHNIC_REQUESTS, EVENTS } from 'Constants';

const SERVICE_NAME = 'TECHNIC';

class TechnicWebSocketService extends WebSocketService {
    constructor(customOptions = {}) {
        super({
            url: '',
            name: SERVICE_NAME,
            protocolVersion: TECHNIC_PROTOCOL_VERSION_WEBSOCKET_SUBPROTOCOL,
            protocolEncoder: feedbox.technic_api.TechnicDataRequest,
            protocolDecoder: feedbox.technic_api.TechnicData,
            shouldCheckHealthStatus: true,
            checkHealthRequestType: TECHNIC_REQUESTS.PING,
            checkHealthRequestData: {},
            checkHealthEventOnProblem: EVENTS.TECHNIC.PROBLEM,
            checkHealthEventOnNormal: EVENTS.TECHNIC.NORMAL,
            shouldUseWSCutout: true,
            ...customOptions
        });
    }
}

export default TechnicWebSocketService;
