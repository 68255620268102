import { createReducer } from '@reduxjs/toolkit';
import * as actions from '../actions';
import initialState from '../initialState';

const totalAmounts = createReducer(initialState.totalAmounts, {
    [actions.trade.getTotalAmountsSuccess]: (state, { payload }) => payload,
    [actions.trade.WSConnectionClose]: () => initialState.totalAmounts
});

export default totalAmounts;
