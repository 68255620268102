import { createReducer, combineReducers } from '@reduxjs/toolkit';
import initialState from '../initialState';
import * as actions from '../actions';

const connectionsHealth = combineReducers({
    market: createReducer(initialState.connectionsHealth.market, {
        [actions.market.WSConnectionProblem]: () => false,
        [actions.market.WSConnectionNormal]: () => true,
        [actions.market.WSConnectionOpen]: () => initialState.connectionsHealth.market
    }),
    trade: createReducer(initialState.connectionsHealth.trade, {
        [actions.trade.WSConnectionProblem]: () => false,
        [actions.trade.WSConnectionNormal]: () => true,
        [actions.trade.WSConnectionOpen]: () => initialState.connectionsHealth.trade
    }),
    technic: createReducer(initialState.connectionsHealth.technic, {
        [actions.technic.WSConnectionProblem]: () => false,
        [actions.technic.WSConnectionNormal]: () => true,
        [actions.technic.WSConnectionOpen]: () => initialState.connectionsHealth.technic
    })
});

export default connectionsHealth;
